/**
 * Created by milan on 2019/7/18.
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/styles/withStyles';
import T from 'i18n-react';
import { Event, Mode, DataManager } from '../models/';
import Image from '../common/Image';
import ImagesDialog from '../dialogs/ImagesDialog';
import { OrderItemBadge } from '../common/StateBadge';
import Seller from '../common/Seller';
import Cell from './ItemCell';
import { FlowTexts } from '../common/Constants';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Box from '@material-ui/core/Box';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListSubheader from '@material-ui/core/ListSubheader';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import IconButton from '@material-ui/core/IconButton';
import BackIcon from '@material-ui/icons/ArrowBack';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import { Link } from 'react-router-dom';

//import ViewIcon from '@material-ui/icons/MoreVert';
//import Menu from '@material-ui/core/Menu';
//import ButtonBase from '@material-ui/core/ButtonBase';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Popover from '@material-ui/core/Popover';
import AlertDialog from '../dialogs/AlertDialog';
import Chatter from '../common/Chatter';


const styles = theme => ({
  root: {
    //position: 'relative'
    marginBottom: 100
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  header: {
    position: 'fixed',
    padding: theme.spacing(0, 2, 0, 2),
    height: 64,
    bottom: 24,
    zIndex: 101,
    width: '100%',
    maxWidth: 'inherit',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  paper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0)
  },
  list: {
    width: '100%'
    //marginTop: theme.spacing(4),
    //backgroundColor: theme.palette.background.paper
  },
  trackerBlock: {
    padding: theme.spacing(0, 0, 0, 4),
    //height: 110,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'space-between'
  },
  leading: {
    width: 54,
    minWidth: 54,
    maxWidth: 54,
    margin: theme.spacing(1)
  },
  label: {
    padding: theme.spacing(1, 0, 1, 0),
    fontSize: 14,
    [theme.breakpoints.down('xs')]: {
      maxWidth: 130
    }
  },
  importantInfo: {
    fontSize: 18
  },
  tracker_cell: {
    backgroundColor: 'white',
    padding: theme.spacing(0, 0, 0, 1)
  },
  stepper_red: {
    width: 1,
    height: '50%',
    backgroundColor: 'orange'
  },
  stepper_blue: {
    width: 1,
    height: '50%',
    backgroundColor: 'DeepSkyBlue'
  },
  hidden_stepper: {
    width: 1,
    height: '50%',
    backgroundColor: 'transparent'
  },
  subheader: {
    fontSize: 12,
    lineHeight: 2,
    padding: theme.spacing(0, 2, 0, 2),
    backgroundColor: 'white',

    width: '100%',
    minWidth: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
});


const placeholders = [
  'https://aifa.oss-cn-qingdao.aliyuncs.com/project/p01.jpg',
  'https://aifa.oss-cn-qingdao.aliyuncs.com/project/p02.jpg',
  'https://aifa.oss-cn-qingdao.aliyuncs.com/project/p03.jpg'
];


class ItemTrackers extends Component {
  order_id = -1;
  item_id = -1;
  item = null;
  editor_path = null;
  keys = ['total', 'paid'];
  desc = ['miscellaneous', 'service', 'tax'];

  constructor(props) {
    super(props);

    this.state = {
      show: false,
      requesting: false,
      alert: -1,

      item_images: false,
      selected: -1,
      item_state: 0
    };
    let order_id = props.match.params.order_id;
    if (order_id) this.order_id = parseInt(order_id);

    let item_id = props.match.params.item_id;
    if (item_id) this.item_id = parseInt(item_id);
    if (this.order_id > 0) {
      this.show();
    }

    const pid = this.props.match.params.id;
    if (pid > 0) {
      this.editor_path = `/projects/s/${pid}/${this.props.match.params.track}/tracker`;
    }else{
      this.editor_path = '/orders/tracker';
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const order_id = parseInt(nextProps.match.params.order_id);
    const item_id = parseInt(nextProps.match.params.item_id);

    if (this.order_id !== order_id || this.item_id !== item_id) {
      this.order_id = order_id;
      this.item_id = item_id;
      if (this.order_id > 0) {
        this.show();
      }

      const pid = nextProps.match.params.id;
      if (pid > 0) {
        this.editor_path = `/projects/s/${pid}/${this.props.match.params.track}/tracker`;
      }else{
        this.editor_path = '/orders/tracker';
      }
    }
    return true;
  }

  show = () => {
    let self = this;
    DataManager.getInstance().list_trackers(this.item_id, null).then(function (res) {
      if (res.c === 0) {
        self.item = res.d.item;
        self.setState({item_state: self.item.state, show: true});
      }
    });
  };

  accept_tracker = (tracker) => {
    const self = this;
    const params = {t: 1, accept: true};
    self.setState({requesting: true});
    return DataManager.getInstance().update_tracker(this.item_id, tracker.id, params, null).then(function (res) {
      if (res.c !== 0) {
        DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'error', m: T.translate('notification.failed')});
        return;
      }
      self.item.state = res.d.oi_state;
      tracker.state = res.d.state;
      tracker.sender_state = res.d.ss;
      tracker.receiver_state = res.d.rs;
      self.setState({requesting: false});
      DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'success', m: T.translate('notification.success')});
    });
  };

  update_tracker = (tracker, memo) => {
    const self = this;
    const params = {t: 1, memo: memo};
    self.setState({requesting: true});
    return DataManager.getInstance().update_tracker(this.item_id, tracker.id, params, null).then(function (res) {
      if (res.c !== 0) {
        DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'error', m: T.translate('notification.failed')});
        return;
      }
      tracker.receiver_memo = memo;
      DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'success', m: T.translate('notification.success')});
      self.setState({requesting: false});
    });
  };

  go_back = (e) => {
    const pid = this.props.match.params.id;
    if (pid > 0) {
      DataManager.getInstance().pub(Event.REDIRECT, `/projects/s/${pid}/${this.props.match.params.track}`);
    }else{
      DataManager.getInstance().pub(Event.REDIRECT, `/orders/s/${this.order_id}`);
      //const is_customer = DataManager.getInstance().mode <= Mode.BUSINESS;
      //DataManager.getInstance().pub(Event.REDIRECT, is_customer ? `/orders/s/${this.order_id}` : `/orders/se/${this.order_id}`);
    }
  };

  new_tracker = (e) => {
    DataManager.getInstance().pub(Event.REDIRECT, `${this.editor_path}/${this.order_id}/${this.item_id}`);
  };

  selected = (tracker, index) => {
    if (tracker.m && tracker.state <= 1) {
      DataManager.getInstance().pub(Event.REDIRECT, `${this.editor_path}/${this.order_id}/${this.item_id}/${tracker.id}`);
    } else {
      if (tracker.state > 1)
      this.setState({selected: index});
    }
  };

  show_item_images = () => {
    this.setState({item_images: true});
  };

  deselect = () => {
    this.setState({selected: -1, item_images: false});
  };

  close_alert = (e) => {
    if (e.currentTarget.dataset.tag === '1') {
      if (this.state.alert === 0) {
        this.new_tracker();
      }
    }
    this.setState({alert: -1});
  };

  handle_state = (e) => {
    const state = parseInt(e.target.value);
    const OrderState = DataManager.getInstance().states.items;
    if (state >= OrderState.Bid) {
      let i;
      let ov = 0;
      let agreed = false;
      for (i = 0; i < this.item.trackers.length; ++i) {
        const tracker = this.item.trackers[i];
        if (tracker.stage === OrderState.Blueprinting) {
          ++ov;
          if (tracker.state >= 10) {
            agreed = true;
          }
        }
      }
      if (!agreed) {
        this.setState({alert: ov});
        return;
      }
    }
    const self = this;
    let params = {iid: this.item.item_id, state: state};
    self.setState({requesting: true});
    const prom = function (res) {
      if (res.c !== 0) {
        return;
      }
      self.setState({item_state: state, requesting: false});
    };
    if (DataManager.getInstance().mode < Mode.BUSINESS)
      return DataManager.getInstance().user_order(this.order_id, params).then(prom);
    else
      return DataManager.getInstance().seller_order(this.order_id, params).then(prom);
  };

  show_state = (item) => {
    const is_customer = DataManager.getInstance().mode < Mode.BUSINESS;
    let editable = false;
    const OrderState = DataManager.getInstance().states.items;
    const states = [];
    let k;
    if (is_customer && item.state >= OrderState.Shipping) {
      for (k in OrderState) {
        if (OrderState.hasOwnProperty(k) && k >= OrderState.Shipping && k <= OrderState.Rated && k % 10 === 0) {
          states.push({title: OrderState[k], state: k});
          if (parseInt(k) === item.state) editable = true;
        }
      }
    }else if (!is_customer && item.state <= OrderState.Shipping) {
      if (item.state === OrderState.Prepared) {
        states.push({title: OrderState[OrderState.Prepared], state: OrderState.Prepared});
        editable = true;
      }
      for (k in OrderState) {
        if (OrderState.hasOwnProperty(k) && k > OrderState.Prepared && k <= OrderState.Shipping) {  // && k % 10 === 0
          states.push({title: OrderState[k], state: k});
          if (parseInt(k) === item.state) editable = true;
        }
      }
    }
    if (!editable) {
      return OrderItemBadge(item.state, DataManager.getInstance().states.orders);
    }
    return (
      <Select
        value={this.state.item_state}
        onChange={this.handle_state}
        disableUnderline={false}
        variant="standard"
      >
        {states.map((order_state, index) => {
          return <MenuItem key={index} value={order_state.state}>{order_state.title}</MenuItem>
        })}
      </Select>
    );
  };

  render() {
    if (!this.state.show) return <React.Fragment/>;
    //const { classes } = this.props;
    let i;
    let ov = 0;
    for (i = 0; i < this.item.trackers.length; ++i) {
      const tracker = this.item.trackers[i];
      if (tracker.stage === 1) {
        ++ov;
      }
    }
    const is_customer = DataManager.getInstance().mode < Mode.BUSINESS;
    const remote = is_customer ? this.item.seller_id : this.item.unit_id;
    let badge = 0;
    const ui = this.item.ui;
    if (ui) {
      badge = is_customer ? ui.uc : ui.sc;
      if (!(badge > 0)) badge = 0;
    }
    const self = this;

    return (
      <React.Fragment>
        {this.table()}

        <Chatter type={110} about={this.item.id} remote={remote} badge={badge} onClick={(e) => {
          self.item.ui.uc = 0;
          self.item.ui.sc = 0;
          self.setState({show: true});
        }} />

        <AlertDialog
          open={this.state.alert >= 0}
          onClose={this.close_alert}
          title={T.translate('dialogs.sorry')}
          desc={T.translate('trackers.orthographic_views_needed')}
          left={ov === 0 ? T.translate('buttons.cancel') : null}
          right={ov === 0 ? T.translate('trackers.ov_now') : T.translate('buttons.ok')}
        />
      </React.Fragment>
    )
  }

  tracker_cell(tracker, index) {
    const { classes } = this.props;
    const is_customer = DataManager.getInstance().mode < Mode.BUSINESS;
    const hasMedia = tracker.state > 1 && tracker.media.length > 0;
    let status = '';
    const text = [];
    if (tracker.m) {
      if (tracker.state > 1 && tracker.state < 10) text.push(T.translate('trackers.to_be_accept'));
      if (tracker.sender_state >= 2) text.push(T.translate('trackers.accepted'));
      if (tracker.sender_state % 2 === 1) text.push(T.translate('trackers.new_feedback'));
    }else{
      if (tracker.state > 1 && tracker.state < 10) text.push(T.translate('trackers.to_accept'));
      if (tracker.receiver_state > 0) text.push(T.translate('trackers.new_feedback'));
    }
    if (text.length > 0) status = text.join(', ');

    const TrackerState = DataManager.getInstance().states.trackers;
    //const memo = tracker.m ? tracker.receiver_memo : tracker.sender_memo;
    const stepper_class = tracker.state < 10 ? classes.stepper_red : classes.stepper_blue;
    const media = hasMedia ? tracker.media[0] : placeholders[tracker.id % placeholders.length];
    const self = this;
    return (
      <ListItem className={classes.tracker_cell} key={tracker.id} divider={tracker.key === 'csp'} alignItems="center" button
                onClick={() => {self.selected(tracker, index)}} >
        {tracker.state <= 1 && tracker.m ? <Box
          color="primary"
          style={{margin: 15, width: 100, height: 100, border: '1px solid grey', display: 'flex', alignItems: 'center', justifyContent: 'center'}}
        >
          <AddIcon color="primary" style={{width: 32, height: 32}} />
        </Box>
          :
        <Image
          style={{ height: 130, width: 130, maxWidth: 130, maxHeight: 130, minWidth: 130, minHeight: 130 }}
          alt={media}
          src={media}
          title={hasMedia ? T.translate('trackers.n_images', {c: tracker.media.length}) : undefined}
        />
        }
        <Box style={{position: 'absolute', top: 0, bottom: 0, left: 138}}>
          <Box
          style={{
            width: 1,
            height: '100%',
            marginLeft: 16,
            //marginRight: 16,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
            //justifyContent: 'center'
          }}>
            <Box className={this.item.trackers.length > 1 && index !== 0 ? stepper_class : classes.hidden_stepper} />
            <Box style={{width: 8, height: 8, borderRadius: 45, backgroundColor: tracker.state < 10 ? 'orange' : 'DeepSkyBlue'}} />
            <Box className={this.item.trackers.length > 1 && index !== this.item.trackers.length - 1 ? stepper_class : classes.hidden_stepper} />
          </Box>
        </Box>
        <Box className={classes.trackerBlock} style={{paddingRight: tracker.m ? 64 : 16}}>
          <Box height={tracker.state > 1 ? 24 : 64}/>
          <Typography component="p" className={classes.importantInfo}>
            {FlowTexts[tracker.key] ? T.translate(`products.${FlowTexts[tracker.key]}`) : TrackerState[tracker.stage]}{status.length > 0 ?
            <Typography component="span" className={classes.label} color="primary">
              &nbsp; ({status})
            </Typography>
            : null}
          </Typography>
          {tracker.state > 1 ?
            <React.Fragment>
              {is_customer ?
                <Typography component="p" className={classes.label}>{T.translate('trackers.seller_memo')}: {tracker.m ? tracker.receiver_memo : tracker.sender_memo}</Typography>
                :
                <Typography component="p" className={classes.label}>{T.translate('trackers.user_memo')}: {tracker.m ? tracker.receiver_memo : tracker.sender_memo}</Typography>
              }
              <Typography component="p" className={classes.label}>{T.translate('trackers.my_memo')}: {tracker.m ? tracker.sender_memo : tracker.receiver_memo}</Typography>
              <Typography component="p" className={classes.label}>{T.translate('orders.update_at')}: {tracker.update_at}</Typography>
              {!tracker.m && <Box style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                {tracker.state >= 10 ?
                  <Button disabled={true} variant="outlined" color="primary" size="small" >
                    {T.translate('trackers.accepted')}
                  </Button>
                  :
                  <React.Fragment>
                    <AcceptDialog trackerKey={tracker.key} onSave={(memo) => self.accept_tracker(tracker)} />
                    <FeedbackDialog value={tracker.receiver_memo} onSave={(memo) => self.update_tracker(tracker, memo)} />
                  </React.Fragment>
                }
              </Box>
              }
              <Box height={12}/>
            </React.Fragment>
            :
            <Box height={64}/>
          }

        </Box>
        {tracker.state >= 1 && tracker.m &&
        <ListItemSecondaryAction>
          {tracker.state < 10 ?
            <IconButton component={Link} to={{pathname: `${this.editor_path}/${this.order_id}/${this.item_id}/${tracker.id}`}} >
              <EditIcon />
            </IconButton>
            :
            <AgreedButton/>
          }
        </ListItemSecondaryAction>
        }
      </ListItem>
    )

  }

  table() {
    const { classes } = this.props;
    const item = this.item;
    const self = this;
    const is_customer = DataManager.getInstance().mode < Mode.BUSINESS;
    const OrderState = DataManager.getInstance().states.items;
    const TrackerState = DataManager.getInstance().states.trackers;

    const selected = this.state.selected >= 0 ? item.trackers[this.state.selected] : {};
    return (
      <Grid alignItems="center" justify="center" container>
        <Grid item xs={12} sm={8} md={8} lg={6} xl={6} className={classes.root}>
          <React.Fragment>
            <Box style={{paddingBottom: 130}}>
              <Box className={classes.buttons}>
                <IconButton aria-label="close" color="inherit" onClick={this.go_back}>
                  <BackIcon style={{width: 32, height: 32}} />
                </IconButton>
                <Typography component="p" variant="h5">{T.translate('orders.following')}</Typography>
                <Box>
                {((is_customer && this.item.state >= OrderState.Shipping) || !is_customer) ?
                  <IconButton aria-label="close" color="inherit" onClick={this.new_tracker}>
                    <AddIcon style={{width: 32, height: 32}} />
                  </IconButton>
                  :
                  <Box width={56} />
                }
                </Box>
              </Box>
              <List className={classes.list}>
                <ListSubheader className={classes.subheader}>
                  <Seller seller_id={item.seller_id} title={item.seller} />
                </ListSubheader>
                <Cell
                  key={item.id}
                  item={item}
                  showEdit={!is_customer}
                  hidePrice={true}
                  tracking={true}
                  onImageClick={() => self.show_item_images()}
                >
                  {this.show_state(item)}
                </Cell>
              </List>

              <Box height={32} />
              {item.trackers && item.trackers.length > 0 ?
              <List className={classes.list}>
                {item.trackers.map((tracker, index) =>
                  self.tracker_cell(tracker, index)
                )}
              </List>
                :
              (is_customer ?
                <Typography component="p" variant="body1" align="center">{T.translate('trackers.no_tracker')}</Typography>
                  :
                <Link to={`${this.editor_path}/${this.order_id}/${this.item_id}`}>
                  <Typography component="p" variant="body1" align="center">{T.translate('trackers.new_tracker_now')}</Typography>
                </Link>
              )
              }
            </Box>

            {this.state.selected >= 0 &&
            <ImagesDialog
              maxWidth="xl"
              images={selected.media}
              title={TrackerState[selected.stage]}
              desc={is_customer ? selected.sender_memo : selected.receiver_memo}
              open={this.state.selected >= 0}
              onClose={this.deselect}
            />
            }
            {this.state.item_images &&
            <ImagesDialog
              maxWidth="xl"
              images={this.item.media}
              open={this.state.item_images}
              title=" "
              onClose={this.deselect}
            />
            }
          </React.Fragment>
        </Grid>
      </Grid>
    )
  }
}


function AgreedButton() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  let timer;
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
    if (timer) clearTimeout(timer);
  };

  const handleClose = () => {
    setAnchorEl(null);
    if (timer) clearTimeout(timer);
  };

  const timeout = () => {
    if (timer) clearTimeout(timer);
    timer = setTimeout(handleClose, 3000);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <IconButton onClick={handleClick} >
        <CheckIcon />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        onEntered={timeout}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
      >
        <Typography style={{padding: 16}}>{T.translate('trackers.accepted')}</Typography>
      </Popover>
    </div>
  );
}


function AcceptDialog(props) {
  const [open, setOpen] = React.useState(false);

  function handleClickOpen(e) {
    setOpen(true);
  }

  function handleClose(e) {
    setOpen(false);
    if (e.currentTarget.dataset.tag === '1') {
      props.onSave();
    }
  }
  //<MenuItem onClick={handleClickOpen} >{T.translate('trackers.feedback')}</MenuItem>

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Button variant="outlined" color="primary" size="small" onClick={handleClickOpen} >
        {T.translate('trackers.accept')}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth='xs'
        fullWidth
      >
        <DialogTitle id="form-dialog-title">{T.translate(props.trackerKey === 'cs' ? 'trackers.accept_specs' : 'trackers.accept_alert')}</DialogTitle>
        <DialogContent>
          {T.translate(props.trackerKey === 'cs' ? 'trackers.accept_confirm_specs' : 'trackers.accept_confirm')}
        </DialogContent>
        <DialogActions>
          <Button data-tag={0} onClick={handleClose}>
            {T.translate('buttons.cancel')}
          </Button>
          <Button data-tag={1} onClick={handleClose} color="primary">
            {T.translate('buttons.yes')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


function FeedbackDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(props.value);

  function handleClickOpen(e) {
    setOpen(true);
  }

  function handleClose(e) {
    setOpen(false);
    if (e.currentTarget.dataset.tag === '1') {
      props.onSave(value);
    }else{
      setValue(props.value);
    }
  }

  const handleChange = e => {
    setValue(e.target.value);
  };
  //<MenuItem onClick={handleClickOpen} >{T.translate('trackers.feedback')}</MenuItem>

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Button variant="outlined" color="primary" size="small" onClick={handleClickOpen} >
        {T.translate('trackers.feedback')}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth='xs'
        fullWidth
      >
        <DialogTitle id="form-dialog-title">{T.translate('trackers.feedback')}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            multiline={true}
            inputProps={{
              maxLength: 256
            }}
            //defaultValue={props.value}
            value={value}
            onChange={handleChange}
            id="feedback"
            label={T.translate('trackers.my_memo')}
            type="text"
            fullWidth
            required
          />
        </DialogContent>
        <DialogActions>
          <Button data-tag={0} onClick={handleClose}>
            {T.translate('buttons.cancel')}
          </Button>
          <Button data-tag={1} onClick={handleClose} color="primary" disabled={!(value && value.length > 0)}>
            {T.translate('buttons.submit')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


//function DropdownButton(props) {
//  const [anchorEl, setAnchorEl] = React.useState(null);
//
//  const handleClick = event => {
//    setAnchorEl(event.currentTarget);
//  };
//
//  const handleClose = () => {
//    setAnchorEl(null);
//  };
//
//  const id = `action-menu-${props.id}`;
//  return (
//    <React.Fragment>
//      <IconButton
//        aria-controls={id}
//        aria-haspopup="true"
//        aria-label={props.id}
//        data-tag={props.id}
//        onClick={handleClick}
//      >
//        <ViewIcon />
//      </IconButton>
//      <Menu
//        id={id}
//        anchorEl={anchorEl}
//        keepMounted
//        open={Boolean(anchorEl)}
//        onClose={handleClose}
//      >
//        <ButtonBase style={{display: 'flex', flexDirection: 'column'}} onClick={handleClose}>{props.children}</ButtonBase>
//      </Menu>
//    </React.Fragment>
//  );
//}


export default withRouter(withStyles(styles)(ItemTrackers));