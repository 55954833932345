/**
 * Created by milan on 2019/7/18.
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/styles/withStyles';
import Button from '@material-ui/core/Button';
//import TextField from '@material-ui/core/TextField';
import { TextField, Checkbox } from 'formik-material-ui';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import { Formik, Form, Field } from 'formik';
import T from 'i18n-react';
import { Event, DataManager } from '../models/';
import TitleBar from '../common/TitleBar';
import Hidden from '@material-ui/core/Hidden';


const styles = theme => ({
  paper: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2)
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    paddingTop: theme.spacing(4)
  },
  input: {
    //marginTop: theme.spacing(6)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
});

class Create extends Component {
  //constructor(props) {
  //  super(props);
  //}

  create_order = (values, actions) => {
    let path = '/' + this.props.match.path.split('/')[1];

    return DataManager.getInstance().create_order(values).then(function (res) {
      let errors = {};
      if (res.c !== 0) {
        errors.name = T.translate('errors.orders.' + res.c);
        actions.setSubmitting(false);
        if (Object.keys(errors).length) {
          actions.setErrors(errors);
          //actions.setStatus(errors);
        }
        return;
      }
      DataManager.getInstance().pub(Event.REDIRECT, path);
    });
  };

  validate = values => {
    let errors = {};
    if (!values.name) {
      errors.name = T.translate('errors.required');
    } else if (!/^[1]([3-9])[0-9]{9}$/.test(values.name)) {
      errors.name = T.translate('errors.invalid_phone');
    }
    if (!values.address) {
      errors.address = T.translate('errors.required');
    }
    if (Object.keys(errors).length)
      return errors;

  };

  render() {
    const { classes } = this.props;

    return (
      <div>
        <TitleBar title={{title: T.translate('orders.create')}}>
        </TitleBar>
        <Container component="main" maxWidth="xs" className={classes.root}>
          <Paper className={classes.paper}>
            <Formik
              initialValues={{ name: '', address: '', notification: false }}
              validate={this.validate}
              validateOnChange={true}
              onSubmit={this.create_order}
            >
              {props => {
                return (
                  <Form className={classes.form} noValidate>

                    <Field
                      component={TextField}
                      error={!!props.errors.name}
                      helperText={props.errors.name}
                      className={classes.input}
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="name"
                      label={T.translate('form.phone')}
                      name="name"
                      autoComplete="name"
                      autoFocus
                    />

                    <Field
                      component={TextField}
                      error={!!props.errors.address}
                      helperText={props.errors.address}
                      className={classes.input}
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="address"
                      label={T.translate('orders.address')}
                      name="address"
                      autoComplete="address"
                      autoFocus
                    />

                    <Hidden xsUp>
                      <FormControlLabel
                        control={
                          <Field
                            component={Checkbox}
                            id="notification"
                            name="notification"
                          />
                        }
                        className={classes.formControl}
                        label={T.translate("form.notification")}
                      />
                    </Hidden>

                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      disabled={props.isSubmitting}
                    >
                      {T.translate('buttons.submit')}
                    </Button>
                  </Form>
                );
              }}
            </Formik>
          </Paper>
        </Container>
      </div>
    )
  }
}

export default withRouter(withStyles(styles)(Create));