import React,  { Component } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import T from 'i18n-react';
import Paper from '@material-ui/core/Paper';

const styles = theme => ({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
    marginTop: theme.spacing(2),
    padding: theme.spacing(0)
  },
  paper: {
    padding: theme.spacing(3),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  text: {
    margin: theme.spacing(2)
  }
});

class Contact extends Component {
  render() {
    const { classes } = this.props;

    return (
      <Container component="main" className={classes.root} maxWidth="md">

        <Paper className={classes.paper}>
          <Typography className={classes.text} component="h6" color='secondary'>
            {T.translate('title.contact')}
          </Typography>
          <Typography className={classes.text}>
            邮箱: contact at zhuchaox.com
          </Typography>
          <Typography className={classes.text}>
            电话: 010 - 59439605
          </Typography>
          <Typography className={classes.text}>
            微信: zhuchaox
          </Typography>
        </Paper>

      </Container>
    )
  }
}

export default withStyles(styles)(Contact);