/**
 * Created by milan on 2019/7/18.
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/styles/withStyles';
import { DataManager } from '../models/';
import T from 'i18n-react';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';

import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';

import Button from '@material-ui/core/Button';
//import { TextField } from 'formik-material-ui';
import { Formik, Form } from 'formik';
import InputDialog from '../dialogs/InputDialog';
import Suggestion from './Sugguestion';
import DropdownButton from '../common/DropdownButton';


const styles = theme => ({
  root: {
    //backgroundColor: theme.palette.background.paper,
    minHeight: 200,
    margin: theme.spacing(2),
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden'
  },
  gridList: {
    flexWrap: 'nowrap',
    //width: '100%',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)'
  },
  buttons: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    height: 200,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'space-between',
    justifyContent: 'space-between'
  },
  button: {
    width: '100%',
    height: 50
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    //padding: theme.spacing(2),
    maxWidth: '100%',
    maxHeight: '100%',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  img: {
    //width: '100%',
    maxWidth: '100%',
    maxHeight: '100%',
    verticalAlign: 'middle'
  },
  img1: {
    maxWidth: '100%',
    maxHeight: '100%'
  },
  leftButton: {
    position: 'absolute',
    left: 0,
    top: 0
  },
  rightButton: {
    position: 'absolute',
    right: 0,
    top: 0
  }
});


class Uploader extends Component {
  id = -1;
  state = {
    media: [],
    url_upload: false,
    suggestion: false
  };

  file_ref = null;
  insert_point = -1;

  constructor(props) {
    super(props);
    this.state.media = props.product.media;
    //let id = 1;
    const id = props.match.params.id;
    if (id) this.id = parseInt(id);
    //this.show();
  }

  onUploaded = (res) => {
    let self = this;
    if (res.c !== 0) {
      return;
    }
    const media = self.state.media.slice();

    const files = res.d.files;
    const new_media = [];
    for (var i = 0; i < files.length; ++i) {
      const file = files[i];
      if (media.indexOf(file.url) < 0)
        new_media.push(file.url);
    }
    media.splice(self.insert_point, 0, ...new_media);
    self.setState({media: media});
    this.props.product.media = media;
  };

  upload_media = (values, _) => {
    const id = this.id > 0 ? this.id : 0;

    let self = this;
    return DataManager.getInstance().upload_product_media(id, values, this.onProgress).then(function (res) {
      self.onUploaded(res);
    });
  };

  onProgress = (e) => {
    //let p = e.loaded / e.total;
  };

  upload_with_url = (urls) => {
    this.setState({url_upload: false, suggestion: false});
    //this.insert_point = this.state.media.length;
    let self = this;
    return DataManager.getInstance().upload_with_url({urls: urls}).then(function (res) {
      self.onUploaded(res);
    });
  };

  add = (e) => {
    const tag = parseInt(e.currentTarget.dataset.tag);
    this.insert_point = tag + 1;
    this.file_ref.click();
  };

  remove = (e) => {
    const tag = parseInt(e.currentTarget.dataset.tag);
    const media = this.state.media.slice();
    media.splice(tag, 1);
    this.setState({media: media});
    this.props.product.media = media;
  };

  render() {
    const { classes } = this.props;
    const images = this.state.media;
    const self = this;
    const cols = isWidthUp('sm', this.props.width) ? 6 : 3;

    return (
      <React.Fragment>
        <InputDialog open={this.state.url_upload} onClose={this.closeUrlUpload} onSave={this.upload_with_url} />
        <Suggestion text={this.props.search} open={this.state.suggestion} onClose={this.closeSuggestion} onSave={this.upload_with_url} />
        <div className={classes.root}>
          <GridList className={classes.gridList} spacing={10} cellHeight={200} rows={1} cols={Math.min(cols, (1 + this.state.media.length))}>
            <GridListTile rows={1} cols={1}>
              <Paper className={classes.buttons}>
                <Button className={classes.button} color="primary" variant="outlined" aria-label="Add new media" data-tag={-1} onClick={this.showSuggestion}>
                  {T.translate('buttons.sugguest')}
                </Button>
                <Button className={classes.button} color="primary" variant="outlined" aria-label="Add new media" data-tag={-1} onClick={this.showUrlUpload}>
                  {T.translate('buttons.url')}
                </Button>
                <Button className={classes.button} color="primary" variant="outlined" aria-label="Add new media" data-tag={-1} onClick={this.add}>
                  {T.translate('buttons.upload')}
                </Button>
              </Paper>
            </GridListTile>
            {images.map((image, index) => (
              <GridListTile key={index} rows={1} cols={1}>
                <Paper className={classes.paper}>
                  <img className={classes.img} src={image} alt={image} />
                </Paper>
                <DropdownButton className={classes.rightButton} index={index} >
                  <MenuItem aria-label={index} data-tag={index} onClick={this.showSuggestion}>{T.translate('buttons.sugguest')}</MenuItem>
                  <MenuItem aria-label={index} data-tag={index} onClick={this.showUrlUpload}>{T.translate('buttons.url')}</MenuItem>
                  <MenuItem aria-label={index} data-tag={index} onClick={this.add}>{T.translate('buttons.upload')}</MenuItem>
                  <MenuItem aria-label={index} data-tag={index} onClick={this.remove}>{T.translate('buttons.delete')}</MenuItem>
                </DropdownButton>
              </GridListTile>
            ))}
          </GridList>
        </div>
        <Formik
          initialValues={{id: -1, file: ''}}
          //validate={this.validate}
          //validateOnChange={false}
          //validateOnBlur={true}
          onSubmit={this.upload_media}
        >
          {props => {
            return (
              <Form style={{display: 'none'}} noValidate>
                <input
                  ref={fileInput => self.file_ref = fileInput}
                  style={{display: 'none'}}
                  accept="image/*"
                  type="file"
                  name="file"
                  multiple="multiple"
                  onChange={(e) => {
                    props.setFieldValue('file', e.currentTarget.files, false);
                    props.submitForm();
                  }}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  disabled={props.isSubmitting}
                >
                  Submit
                </Button>
              </Form>
            );
          }}
        </Formik>
      </React.Fragment>
    )
  }

  showUrlUpload = (e) => {
    const tag = parseInt(e.currentTarget.dataset.tag);
    this.insert_point = tag + 1;
    this.setState({url_upload: true});
  };

  closeUrlUpload = () => {
    this.setState({url_upload: false});
  };

  showSuggestion = (e) => {
    const tag = parseInt(e.currentTarget.dataset.tag);
    this.insert_point = tag + 1;
    this.setState({suggestion: true});
  };

  closeSuggestion = () => {
    this.setState({suggestion: false});
  };
}


export default withWidth()(withRouter(withStyles(styles)(Uploader)));