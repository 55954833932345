/**
 * Created by milan on 2019/7/18.
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/styles/withStyles';
import T from 'i18n-react';
import { Event, DataManager } from '../models/';
import { tableIcons } from '../common/Constants';
import ImagesDialog from '../dialogs/ImagesDialog';

import MaterialTable from 'material-table';
import IconButton from '@material-ui/core/IconButton';

import BaseDialog from '../dialogs/BaseDialog';
import ProductsTable from './Products';
import { priceDisplay, QualityLookup } from '../common/Constants';
import FlowDisplay from '../common/FlowDisplay';
import TextField from '@material-ui/core/TextField';


const styles = theme => ({
});


class Items extends Component {
  productColumns = [
    { title: T.translate('products.media'), field: 'media', editable: 'never' },
    { title: T.translate('products.title'), field: 'title', editable: 'never' },
    { title: T.translate('products.brand'), field: 'brand', editable: 'never' },
    { title: T.translate('products.type'), field: 'item_type', editable: 'never' },
    { title: T.translate('products.quality'), field: 'quality', editable: 'never', lookup: QualityLookup },
    { title: T.translate('products.size'), field: 'size', editable: 'never' },
    { title: T.translate('products.material'), field: 'material', editable: 'never' },
    { title: T.translate('products.flow'), field: 'flow', editable: 'never' },
    { title: T.translate('products.item_space'), field: 'category', editable: 'always' },
    { title: T.translate('orders.memo'), field: 'memo', editable: 'always', editComponent: props => (
      <TextField
        margin="dense"
        multiline
        style={{width: 200, minWidth: 200, maxWidth: 200}}
        value={props.value}
        onChange={e => props.onChange(e.target.value)}
      />
    ), render: product => {
      return <p style={{width: 100, maxWidth: 100, display: '-webkit-box', WebkitLineClamp: 5, WebkitBoxOrient: 'vertical', overflow: 'hidden', textOverflow: 'ellipsis'}}>{product.memo}</p>;
    } },
    { title: T.translate('orders.shipping'), field: 'shipping', editable: 'always', type: 'numeric' },
    { title: T.translate('orders.install'), field: 'install', editable: 'always', type: 'numeric' },
    { title: T.translate('orders.miscellaneous'), field: 'miscellaneous', editable: 'always', type: 'numeric' },
    { title: T.translate('products.quantity'), field: 'quantity', editable: 'always', type: 'numeric' },
    { title: T.translate('products.price1'), field: 'price', editable: 'always' }
  ];
  order = null;
  tableRef = React.createRef();

  constructor(props) {
    super(props);
    this.order = props.order;
    this.state = {carousel: null, c: this.order.quantity, data: this.order.items, showProducts: false};
  }

  static getDerivedStateFromProps(props, curr_state) {
    return {
      data: props.order.items
    };
  }

  update_order_items = (newData, oldData) => {
    const self = this;
    let order_id = this.order.id;
    if (order_id > 0) {
      let params = {id: order_id, iid: oldData.item_id, p: oldData.id};
      if (newData) {
        params.q = parseInt(newData.quantity);
        params.memo = newData.memo;
        params.shipping = newData.shipping;
        params.install = newData.install;
        params.miscellaneous = newData.miscellaneous;
        params.category = newData.category;
        params.price = newData.price;
      }else{
        params.q = -1;
      }
      return DataManager.getInstance().update_order_items(params).then(function (res) {
        if (res.c === 0) {
          const d = res.d;
          const costs = d.costs;
          for (var k in costs) {
            if (costs.hasOwnProperty(k)) {
              self.order[k] = costs[k];
            }
          }

          //self.order.subtotal = d.t;
          //self.props.updater({subtotal: d.t});
          self.props.updater(self.order);
          const data = self.state.data.slice();
          if (d.new < 0){
            data.splice(data.indexOf(oldData), 1);
          }else {
            newData.quantity = d.new;
            data[data.indexOf(oldData)] = newData;
          }
          self.order.items = data;
          self.setState({data: data, c: d.c});
        }
      });
    }else{
      return new Promise((resolve, reject) => {
        DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'error', m: T.translate('errors.products.order_error')});
        setTimeout(() => {
          reject();
        }, 1000);
      })
    }
  };

  update_order_item = (newData, oldData) => {
    return this.update_order_items(newData, oldData);
  };

  delete_from_order = (oldData) => {
    return this.update_order_items(null, oldData);
  };

  showCarousel = (rowData) => {
    this.setState({carousel: rowData});
  };

  closeCarousel = (e) => {
    this.setState({carousel: null});
  };

  media_render = (rowData) => {
    const self = this;
    return (
      <IconButton color="primary" align="center" size="small" onClick={(e) => {
        self.showCarousel(rowData);
      }}>
        <img
          style={{ height: 36, maxWidth: 100 }}
          alt={rowData.media[0]}
          src={rowData.media[0]}
        />
      </IconButton>
    )
  };

  flow_render = (product) => {
    return (
      <FlowDisplay product_id={product.product_id} item_id={product.item_id} flow={product.flow} />
    )
  };

  render() {
    const self = this;
    this.productColumns[0].render = this.media_render;
    this.productColumns[6].render = this.flow_render;

    let images = [];
    let text = {};
    let i;
    if (this.state.carousel != null) {
      const product = this.state.carousel;
      text.title = product.title;
      for (i = 0; i < product.media.length; ++i) {
        const url = product.media[i];
        const image = {label: i, description: url, imgPath: url};
        images.push(image);
      }
    }
    for (i = 0; i < this.state.data.length; ++i) {
      const item = this.state.data[i];
      item['display'] = priceDisplay(item.price, item.currency);
    }
    const OrderState = DataManager.getInstance().states.orders;

    return (
      <React.Fragment>
        <MaterialTable
          tableRef={this.tableRef}
          icons={tableIcons}
          title={T.translate('products.items', {c: this.state.c})}
          options={{search: false, paging: false, pageSize: 20, pageSizeOptions: [20], detailPanelType: 'single'}}
          columns={self.productColumns}
          data={self.state.data}
          editable={this.order.state === OrderState.Bidding ? null : {
            onRowUpdate: self.update_order_item,
            onRowDelete: self.delete_from_order
          }}
          actions={this.order.state >= OrderState.Bidding ? null :[{
              icon: () => <tableIcons.Sync />,
              tooltip: T.translate('labels.sync_price'),
              isFreeAction: true,
              onClick: this.props.sync
            },
            {
              icon: () => <tableIcons.Add />,
              tooltip: T.translate('quotation.add_product'),
              isFreeAction: true,
              onClick: this.showSelector
            }
          ]}
        />

        <ImagesDialog
          images={images}
          open={this.state.carousel != null}
          onClose={this.closeCarousel}
          {...text}
        />

        <BaseDialog
          maxWidth="xl"
          open={this.state.showProducts}
          onClose={this.closeSelector}
          title={T.translate('products.select_items')}
        >
          <ProductsTable order={this.order} />
        </BaseDialog>
      </React.Fragment>
    )
  }

  showSelector = (e) => {
    this.setState({showProducts: true});
  };

  closeSelector = (e) => {
    this.tableRef.current.dataManager.setData(this.order.items);
    this.tableRef.current.setState(this.tableRef.current.dataManager.getRenderState());
    this.props.updater(this.order);
    this.setState({data: this.order.items, showProducts: false});
  };
}


export default withRouter(withStyles(styles)(Items));