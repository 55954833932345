/**
 * Created by milan on 2019/7/18.
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/styles/withStyles';
import T from 'i18n-react';
import { Event, DataManager } from '../models/';
import { Link } from 'react-router-dom';

import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import StarIcon from '@material-ui/icons/Star';
import StarHalfIcon from '@material-ui/icons/StarHalf';
import GoIcon from '@material-ui/icons/ChevronRight';

import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import CircularProgress from '@material-ui/core/CircularProgress';
import { priceDisplay } from '../common/Constants';
import Pagination from '../common/Pagination';
import Cell from '../Order/Cell';

const styles = theme => ({
  unit: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(1)
  },
  block: {
    width: '100%',
    maxWidth: '100%',
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  stats: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  value: {
    fontSize: 24,
    textAlign: 'center',
    verticalAlign: 'middle',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },

  root: {
    flexGrow: 1,
    overflow: 'hidden',
    marginTop: theme.spacing(0),
    padding: theme.spacing(1)
  },
  paper: {
    width: '100%',
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  list: {
    width: '100%'
  },
  content: {
    fontSize: 24,
    margin: theme.spacing(2)
  },
  pagination: {
    marginTop: theme.spacing(5)
  }
});


class Dashboard extends Component {
  id = 0;
  unit = null;
  orders = null;
  quotes = null;
  state = {editable: false, desc: null, requesting: true, total: 1, page: 1, per: 0, filter: 0};

  constructor(props) {
    super(props);
    //const search = props.location.search.substring(1).split('.');
    //if (search.length > 1) {
    //  const p = search[0];
    //  const page = parseInt(p);
    //  if (page > 0) this.state.page = page;
    //  const f = search[1];
    //  const filter = parseInt(f);
    //  if (filter > 0) this.state.filter = filter;
    //}
    let id = props.match.params.id;
    if (id) this.id = parseInt(id);
    this.show(this.state.page);
  }

  //shouldComponentUpdate(nextProps, nextState) {
  //  if (this.props.location.search !== nextProps.location.search) {
  //    const search = nextProps.location.search.substring(1).split('.');
  //    if (search.length > 1) {
  //      const p = search[0];
  //      const page = parseInt(p);
  //      if (page > 0) nextState.page = page;
  //      const f = search[1];
  //      const filter = parseInt(f);
  //      if (filter >= 0) nextState.filter = filter;
  //    }
  //    this.show(nextState.page);
  //  }
  //  return true;
  //}

  show = (page) => {
    const self = this;
    const params = {page: page, id: this.props.id || -1};
    return DataManager.getInstance().show_dashboard(this.id, params).then(function (res) {
      if (res.c === 0) {
        const d = res.d;
        if (d.unit) self.unit = d.unit;
        if (d.quotes) self.quotes = d.quotes;
        if (d.stats) {
          const storage = DataManager.getInstance().storage;
          if (storage.mc && storage.mc.orders) {
            storage.mc.orders = d.stats;
          }
        }
        if (d.orders) {
          const orders = d.orders;
          for (var i = 0; i < orders.length; ++i) {
            const order = orders[i];
            order.subtotal = priceDisplay(order.subtotal, order.currency);
            order.total = priceDisplay(order.total, order.currency);
            order.paid = priceDisplay(order.paid, order.currency);
            order.received = priceDisplay(order.received, order.currency);
            order.mc = d.stats.mc[order.id];
          }
          self.orders = orders;
        }

        self.setState({page: page, total: d.t, per: d.p, requesting: false, stats: d.stats});
        window.scrollTo(0, 0);
      }
    })
  };

  order_selected = (order) => {
    DataManager.getInstance().pub(Event.REDIRECT, `/orders/s/${order.id}`);
  };

  handle_pagination = (offset) => {
    const page = 1 + offset / this.state.per;
    this.show(page);
    //DataManager.getInstance().pub(Event.REDIRECT, {pathname: this.props.location.pathname, search: `${page}.${this.state.filter}`});
  };

  render() {
    if (this.state.requesting) return <div></div>;
    const { classes } = this.props;

    return (
      <React.Fragment>
        <UnitDisplay unit={this.unit} classes={classes} />

        {this.quotes &&
        <React.Fragment>
          <div style={{height: 32}}></div>
          <Grid alignItems="center" justify="center" container style={{textDecoration: 'none'}} component={Link} to={{pathname: '/quotes/', search: '1.1'}}>
            <Grid item xs={12} sm={8} md={8} lg={6} xl={5}>
              <Paper className={classes.block} color="primary">
                <Box className={classes.stats}>
                  <Typography variant="h6">
                    {T.translate('quotation.dashboard')}
                  </Typography>
                </Box>
                <Box className={classes.value}>
                  <Typography variant="h5" style={{color: 'red'}}>
                    {this.quotes}
                  </Typography>
                  <GoIcon style={{color: 'red'}}/>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </React.Fragment>
        }

        <Container component="main" maxWidth="sm" className={classes.unit}>
          <Typography variant="h5">
            {T.translate('orders.working_on_orders')}
          </Typography>
        </Container>
        {this.order_list()}
      </React.Fragment>
    );
  }

  order_list = () => {
    const orders = this.orders;
    if (!orders) return <React.Fragment />;

    const { classes } = this.props;
    const self = this;

    return (
      <Grid alignItems="center" justify="center" container>
        <Grid item xs={12} sm={8} md={8} lg={6} xl={5}>
          <Box className={classes.paper}>
            {this.state.requesting ?
              <CircularProgress size={48} />
              :
              (orders.length === 0 ?
                  <React.Fragment>
                    <Typography className={classes.content}>{T.translate('orders.empty_list')}</Typography>
                  </React.Fragment>
                  :
                  <List className={classes.list}>
                    {orders.map((order, index) =>
                      <Cell key={order.id} order={order} index={index} onClick={self.order_selected} onExpand={this.on_expanded} />
                    )}
                  </List>
              )
            }
          </Box>
          {!this.state.requesting && this.state.per > 0 && this.state.total > this.state.per &&
          <Pagination
            offset={this.state.per * (this.state.page - 1)}
            limit={this.state.per}
            total={this.state.total}
            onClick={this.handle_pagination}
          />
          }
        </Grid>
      </Grid>
    );
  };
}


function UnitDisplay(props) {
  const {classes, unit} = props;
  if (!unit) return <React.Fragment />;

  const stars = (rank) => {
    if (!(rank > 0 && rank <= 5)) return T.translate('units.rank_none');
    const stars = [];
    let i;
    for (i = 1; i <= 5; ++i) {
      if (rank >= i) {
        stars.push(<StarIcon key={i} />);
      }else if (rank >= i - 0.5) {
        stars.push(<StarHalfIcon key={i} />);
      }else{
        break;
      }
    }
    return stars;
  };
  return (
    <Grid alignItems="center" justify="center" container>
      <Grid item xs={12} sm={8} md={8} lg={6} xl={5}>
        <Paper className={classes.unit} color="primary">
          <Typography variant="h5">
            {unit.display}
          </Typography>
          <Box className={classes.block}>
            <Box className={classes.stats}>
              <Typography component="p" variant="caption" align="center">
                {T.translate('units.orders')}
              </Typography>
              <Typography component="p" align="center" className={classes.value}>
                {unit.stats.orders}
              </Typography>
            </Box>
            <Box className={classes.stats}>
              <Typography component="p" variant="caption" align="center">
                {T.translate('units.rate')}
              </Typography>
              <Typography component="p" align="center" className={classes.value}>
                {unit.stats.rate}
              </Typography>
            </Box>
            <Box className={classes.stats}>
              <Typography component="p" variant="caption" align="center">
                {T.translate('units.rank', {app: T.translate('appName')})}
              </Typography>
              <Typography component="p" align="center" className={classes.value}>
                {stars(unit.stats.rank)}
              </Typography>
            </Box>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
}


export default withRouter(withStyles(styles)(Dashboard));