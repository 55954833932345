/**
 * Created by milan on 2019/7/18.
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/styles/withStyles';
import T from 'i18n-react';
import { Event, DataManager } from '../models';

import Container from '@material-ui/core/Container';
import { M_VISIBLE, M_ITEMS } from '../common/Constants';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';


const styles = theme => ({
  root: {

  }
});


class Export extends Component {
  state = {
    keys: ['identifier', 'title'], requesting: false
  };
  keys = [];
  titles = [];

  constructor(props) {
    super(props);
    let i;
    for (i = 0; i < M_VISIBLE.length; ++i) {
      const k = M_VISIBLE[i];
      const t = T.translate(`materials.${k}`);
      this.keys.push(k);
      this.titles.push(t);
    }
    for (i = 0; i < M_ITEMS.length; ++i) {
      const k = M_ITEMS[i];
      const t = T.translate(`materials.${k}`);
      this.keys.push(k);
      this.titles.push(t);
    }
    // this.state.keys = this.keys;
  }

  downloadString = (text, fileType, fileName) => {
    var blob = new Blob([text], {type: fileType, encoding: 'UTF-8'});
  
    var a = document.createElement('a');
    a.download = fileName;
    a.href = URL.createObjectURL(blob);
    a.dataset.downloadurl = [fileType, a.download, a.href].join(':');
    a.style.display = "none";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    setTimeout(function() { URL.revokeObjectURL(a.href); }, 1500);
  };

  submit = (e) => {
    this.setState({requesting: true});
    const keys = [];
    let i;
    for (i = 0; i < this.keys.length; ++i) {
      const k = this.keys[i];
      if (this.state.keys.indexOf(k) >= 0) {
        keys.push([k, this.titles[i]]);
      }
    }
    const self = this;
    return DataManager.getInstance().export_materials({keys: keys}).then(function (res) {
      self.setState({requesting: false});
      if (res.c !== 0) {
        DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'error', m: T.translate('notification.failed')});
        return;
      }
      self.downloadString(res.d.csv, 'text/csv;charset=UTF-8', 'mats.csv');
    })
  };

  onChange = (e) => {
    const name = e.target.name;
    // const checked = e.target.checked;
    const keys = [...this.state.keys];
    const index = keys.indexOf(name);
    if (index >= 0) {
      keys.splice(index, 1);
    }else{
      keys.push(name);
    }
    this.setState({keys: keys});
  }

  render() {
    const self = this;
    return (
      <React.Fragment>
        <Container component="main" maxWidth="md">
          {this.keys.map((key, index) => {
            return <FormControlLabel
              key={key}
              control={
                <Checkbox
                  key={key}
                  name={key}
                  color="primary"
                  checked={self.state.keys.indexOf(key) >= 0}
                  onChange={self.onChange}
                />
              }
              label={self.titles[index]}
            />;
          })}
          <Button color="primary" variant="outlined" onClick={this.submit} disabled={this.state.requesting}>
            {T.translate('buttons.download')}
          </Button>
        </Container>
      </React.Fragment>
    )
  }
}


export default withRouter(withStyles(styles)(Export));