import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';


class ProgressDialog extends Component {

  render() {
    return (
      <Dialog
        {...this.props}
        aria-describedby="progress-dialog"
      >
        <DialogContent style={{alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column'}}>
          {this.props.progress ? 
            <CircularProgress value={this.props.progress} variant="determinate" />
            :
            <CircularProgress />
          }
          {this.props.text &&
            <Typography variant="subtitle1">{this.props.text}</Typography>
          }
        </DialogContent>
      </Dialog>
    );
  }
}


export default ProgressDialog;