/**
 * Created by milan on 2019/7/18.
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/styles/withStyles';
import T from 'i18n-react';
import { DataManager } from '../models/';
import { priceDisplay, QualityLookup } from '../common/Constants';

import MaterialTable from 'material-table';
import { tableIcons } from '../common/Constants';
import Uploader from '../Inquiry/Upload';
import Container from '@material-ui/core/Container';

import CheckedIcon from '@material-ui/icons/CheckCircleOutline';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import IconButton from '@material-ui/core/IconButton';


const styles = theme => ({
  root: {
  }
});


class ProductsTable extends Component {
  productColumns = [
    { title: T.translate('products.inquiry'), field: 'checked', editable: 'never' },
    { title: T.translate('products.media'), field: 'media', editable: 'never', render: (rowData) => {
      return <img
        style={{ height: 64, maxWidth: 100 }}
        alt={rowData.media[0]}
        src={rowData.media[0]}
      />
    } },
    { title: T.translate('products.title'), field: 'title', editable: 'never' },
    { title: T.translate('products.brand'), field: 'brand', editable: 'never' },
    { title: T.translate('products.type'), field: 'item_type', editable: 'never' },
    { title: T.translate('products.quality'), field: 'quality', editable: 'never', lookup: QualityLookup },
    { title: T.translate('products.size'), field: 'size', editable: 'never' },
    { title: T.translate('products.material'), field: 'material', editable: 'never' },
    { title: T.translate('products.item_space'), field: 'category', editable: 'always' },
    //{ title: T.translate('orders.shipping'), field: 'shipping', editable: 'always', type: 'numeric' },
    //{ title: T.translate('orders.install'), field: 'install', editable: 'always', type: 'numeric' },
    //{ title: T.translate('orders.miscellaneous'), field: 'miscellaneous', editable: 'always', type: 'numeric' },
    { title: T.translate('products.quantity'), field: 'quantity', editable: 'always', type: 'numeric' },
    { title: T.translate('products.price1'), field: 'p1', editable: 'never' }
  ];
  state = {
    isLoading: false, cart: 0, carousel: null, total: 1, page: 1, per: 0, data: []
  };
  order_id = -1;
  order = null;

  constructor(props) {
    super(props);
    if (props.order) {
      this.order = props.order;
      this.order_id = this.order.id;
    }
  }

  update_order_items = (item) => {
    const order_id = this.order_id;
    const self = this;
    DataManager.getInstance().update_order_items({id: order_id, did: item.deal_id, vid: item.id, iid: item.item_id, q: 1, d: 1}).then(function (res) {
      if (res.c === 0) {
        if (self.order) {
          const pro = JSON.parse(JSON.stringify(item));
          self.order.items.push(pro);

          const costs = res.d.costs;
          for (var k in costs) {
            if (costs.hasOwnProperty(k)) {
              self.order[k] = costs[k];
            }
          }
        }
        self.setState({cart: res.d.c});
      }
    });
  };

  list = (query) => {
    if (this.state.isLoading) return;
    const self = this;
    let p = query.page > 0 ? query.page + 1 : (parseInt(this.props.location.search.substring(1)) || 1);
    if (p < 0) p = 0;
    const params = {page: p};
    if (query.search) params.tags = query.search;
    for (var i = 0; i < query.filters.length; ++i) {
      const col = query.filters[i];
      params[col.column.field] = col.value;
    }
    params.uid = this.order.unit_id;
    params.full = true;
    return DataManager.getInstance().list_user_products(params).then(function (res) {
    //return DataManager.getInstance().list_products(params).then(function (res) {
      if (res.c === 0) {
        let d = res.d;
        if (!(self.order_id > 0)) self.setState({cart: d.cc});
        const total = d.t;
        const max_page = Math.ceil(total / d.p);
        if (p > max_page) p = max_page;
        if (p < 1) p = 1;
        const products = d.ps;
        let i;
        for (i = 0; i < products.length; ++i) {
          const item = products[i];
          item.p1 = priceDisplay(item.price1, item.currency);
          item.p2 = priceDisplay(item.price2, item.currency);
        }
        return {
          data: products,
          page: p - 1,
          totalCount: total
        };
      }
      return {data: [], page: 0, totalCount: 0};
    })
  };

  media_render = (product) => {
    product.checked = false;
    let i;
    for (i = 0; i < this.order.items.length; ++i) {
      let item = this.order.items[i];
      if (product.item_id === item.item_id) {
        product.checked = true;
        break;
      }
    }
    const self = this;
    return (
      product.checked ?
        <IconButton color="inherit" align="center" size="small">
          <CheckedIcon />
        </IconButton>
        :
        <IconButton color="primary" align="center" size="small" onClick={(e) => {
          e.preventDefault();
          self.update_order_items(product);
        }}>
          <AddShoppingCartIcon />
        </IconButton>
    )
  };

  render() {
    this.productColumns[0].render = this.media_render;

    const self = this;
    return (
      <React.Fragment>
        <MaterialTable
          icons={tableIcons}
          title={T.translate('products.all')}
          options={{search: false, filtering: false, isLoading: this.state.isLoading, debounceInterval: 1000, pageSize: 20, pageSizeOptions: [20], detailPanelType: 'single'}}
          columns={self.productColumns}
          //onRowClick={(event, rowData, togglePanel) => togglePanel()}
          detailPanel={rowData => {
            return (
              <Container style={{maxWidth: '85vw'}}>
                <Uploader files={rowData.media} />
              </Container>
            )
          }}
          data={query => self.list(query)}
        />
      </React.Fragment>
    )
  }
}


export default withRouter(withStyles(styles)(ProductsTable));