/**
 * Created by milan on 2019/7/18.
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/styles/withStyles';
import { Typography } from '@material-ui/core';
import T from 'i18n-react';
import { Event, DataManager } from '../models/';
import Editor from './Editor';
import EditorDialog from './EditorDialog';
import { tableIcons, ProductModel, ItemModel, QualityLookup, randomString } from '../common/Constants';
//import clsx from 'clsx';
import { green } from '@material-ui/core/colors';
import AlertDialog from '../dialogs/AlertDialog';

import MaterialTable from 'material-table';
import SpecsDisplay from './SpecsDisplay';
import MaterialsEditor from './MaterialsEditor';
import ValueWanted from './ValueWanted';


const styles = theme => ({
  root: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  fabGreen: {
    color: theme.palette.common.white,
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[600]
    }
  },
  hide: {
    display: 'none'
  },
  fabs: {
    display: 'none',
    flexDirection: 'column',
    alignItems: 'center',

    position: 'fixed',
    zIndex: 100,
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  //},
  //fab: {
  //  position: 'fixed',
  //  zIndex: 10000,
  //  bottom: theme.spacing(2),
  //  right: theme.spacing(2)
  }
});

class Edit extends Component {
  id = -1;
  product = {};
  item = null;
  itemColumns = null;
  tableRef = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      show: false,

      index: -1,
      open: false,
      specs: false,
    };

    const itemColumns = [
      { title: T.translate('products.media'), field: 'media', editable: 'never', render: rowData => (
        rowData && <img
          style={{ height: 72, maxWidth: 100 }}
          alt={rowData.media ? rowData.media[0] : ''}
          src={rowData.media ? rowData.media[0] : ''}
        />
      ) }
    ];
    ItemModel.forEach(function (property, _) {
      const v = {title: T.translate(`products.${property.k}`), field: property.k};
      if (property.k === 'quality') {
        v.lookup = QualityLookup;
      }
      if (property.k === 'model') {
        v.render = (item) => {
          return (
            <SpecsDisplay item={item} />
          )
        };
      }
      itemColumns.push(v);
    });
    this.itemColumns = itemColumns;

    let id = props.match.params.id;
    if (id) this.id = parseInt(id);
    if (this.id <= 0) {
      const product = {};
      ProductModel.forEach(function (property, _) {
        const key = property.k;
        product[key] = property.d || '';
      });
      if (!product.identifier) product.identifier = randomString(8);
      product.items = [];
      product.media = [];
      this.product = product;
      //this.make_item();
      // this.state.show = true;
      // return;
    }
    this.show();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.location.search !== nextProps.location.search) {
      return true;
    }
    return true;
  }

  show = () => {
    let self = this;
    const params = {e: 1, l: DataManager.getInstance().storage.labels ? 0 : 1};
    DataManager.getInstance().show_product(this.id > 0 ? this.id : 0, params).then(function (res) {
      if (res.c === 0) {
        if (res.d.labels) DataManager.getInstance().storage.labels = res.d.labels;
        const product = res.d.p;
        if (product) {
          product.product_id = product.id;
          product.item_id = 0;
          self.product = product;
          self.product.items.sort((l, r) => (l.update_at > r.update_at) ? -1 : 1);
        }
        self.setState({show: true});
      }
    });
  };

  make_item = (sample) => {
    const item = {};
    ItemModel.forEach(function (property, _) {
      const key = property.k;
      item[key] = property.d || '';
    });
    const product = this.product;
    //item.id = `item_${product.items.length}`;
    item.identifier = product.identifier + '.' + randomString(3);
    item.media = [];
    if (sample){
      item.material1 = sample.material1;
      item.material2 = sample.material2;
      item.material = sample.material;
      item.size = sample.size;
      item.quality = sample.quality;
    }else if (product.items.length > 0) {
      // const last = product.items[product.items.length - 1];
      const last = product.items[0];
      // item.material1 = last.material1;
      // item.material2 = last.material2;
      item.material = last.material;
      item.size = last.size;
      item.quality = last.quality;
    }
    return item;
  };

  delete_item = (index) => {
    if (index < 0) return;
    const item_id = this.product.items[index].id;

    const self = this;
    return DataManager.getInstance().delete_item(this.id, item_id).then(function (res) {
      if (res.c !== 0) {
        return;
      }
      self.product.items.splice(index, 1);
      self.tableRef.current.dataManager.setData(self.product.items);
      self.tableRef.current.setState(self.tableRef.current.dataManager.getRenderState());
      self.setState({show: true});
    });
  };

  render() {
    if (!this.state.show) return <React.Fragment/>;
    let text = '';
    if (this.product.brand) text += this.product.brand;
    if (this.product.title) text += ' ' + this.product.title;

    const title = <div style={{display: 'flex', flexDirection: 'row'}}>
      <Typography>{T.translate('products.items', {c: this.product.items.length})}</Typography>
      {this.id > 0 &&
      <ValueWanted
        key={this.props.location.search}
        product={this.product}
        open={this.props.location.search === '?wanted'}
        onClick={this.showWanted} 
      />
      }
    </div>;

    return (
      <React.Fragment>
        {!this.state.open &&
        <React.Fragment>
          <Editor product={this.product} index={-1} search={text} />
          {this.product.id > 0 &&
          <div style={{padding: 16, minHeight: 100, width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            <MaterialsEditor product={this.product} labels={this.product.ctmz ? this.product.ctmz : []} />
            <div style={{height: 16}}></div>
            <SpecsDisplay item={this.product} />
          </div>
          }
          <MaterialTable
            tableRef={this.tableRef}
            // style={{marginTop: 16}}
            icons={tableIcons}
            title={title}
            options={{search: false, filtering: false, paging: false}}
            columns={this.itemColumns}
            actions={[{
                icon: () => <tableIcons.Add />,
                tooltip: '',
                isFreeAction: true,
                onClick: this.showDialog
              }, {
                icon: () => <tableIcons.Edit />,
                tooltip: '',
                onClick: this.showDialog
              }, {
                icon: () => <tableIcons.Delete />,
                tooltip: '',
                onClick: (event, rowData) => this.delete_item(this.product.items.indexOf(rowData))
              }
            ]}
            data={this.product.items}
          />
        </React.Fragment>
        }

        <EditorDialog
          key={1}
          search={text}
          product_id={this.product.id}
          item={this.item}
          product={this.product}
          open={this.state.open}
          onClose={this.closeDialog}
          index={this.state.index}
        />

        {this.state.please_save &&
        <AlertDialog
          open={this.state.please_save}
          onClose={this.closeSave}
          title={T.translate('products.please_save')}
          right={T.translate('buttons.ok')}
        />
        }
      </React.Fragment>
    );
  }

  closeSave = () => {
    this.setState({please_save: false});
  };

  showWanted = (wanted, index) => {
    if (this.id <= 0) {
      this.setState({please_save: true});
      return;
    }
    this.item = this.make_item(wanted[index]);
    this.setState({index: -1, open: true});
  };

  showDialog = (e, rowData) => {
    const product = this.product;
    let index = product.items.indexOf(rowData);
    let item;
    if (index < 0) {
      if (this.id <= 0) {
        this.setState({please_save: true});
        return;
      }
      item = this.make_item();
    }else{
      item = {...rowData};
      item.media = rowData.media.slice();
    }
    this.item = item;
    this.setState({index: index, open: true});
  };

  closeDialog = (item) => {
    this.item = null;
    if (item) {
      if (this.state.index >= 0) {
        this.product.items[this.state.index] = item;
      }else{
        this.product.items.push(item);
      }
      this.product.items.sort((l, r) => (l.update_at > r.update_at) ? -1 : 1);
    }
    //this.tableRef.current.dataManager.setData(this.product.items);
    //this.tableRef.current.setState(this.tableRef.current.dataManager.getRenderState());
    if (this.props.location.search === '?wanted')
      DataManager.getInstance().pub(Event.REDIRECT, {pathname: this.props.location.pathname, search: ''});
    this.setState({index: null, show: true, open: false});
  };
}


export default withRouter(withStyles(styles)(Edit));