/**
 * Created by milan on 2019/7/18.
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/styles/withStyles';
import T from 'i18n-react';
import { Event, DataManager } from '../models/';
import ProductDialog from '../Product/ProductDialog';
import { priceDisplay } from '../common/Constants';
import ImagesDialog from '../dialogs/ImagesDialog';
import ItemCell from './ItemCell';
import { QuoteBadge } from '../common/StateBadge';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

//import Image from '../common/Image';
//import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ListSubheader from '@material-ui/core/ListSubheader';
import Divider from '@material-ui/core/Divider';

import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';


const styles = theme => ({
  root: {
    //position: 'relative'
    marginBottom: 100
  },
  box: {
    paddingBottom: 160
  },
  header: {
  //border: `2px solid ${
  //  theme.palette.primary.main
  //}`,
    backgroundColor: '#fafafa',
    position: 'fixed',
    padding: theme.spacing(0, 2, 0, 2),
    height: 50,
    bottom: 0,
    zIndex: 101,
    width: '100%',
    maxWidth: 'inherit',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  paper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0)
  },
  list: {
    width: '100%'
    //marginTop: theme.spacing(4),
    //backgroundColor: theme.palette.background.paper
  },
  costBlock: {
    padding: theme.spacing(0, 0, 0, 2)
  },
  detailsBlock: {
  },
  headerTitle: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: 64,
    flexShrink: 0
  },
  headerDetails: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  },
  label: {
    lineHeight: 2,
    fontSize: 14,
    marginLeft: theme.spacing(1)
  },
  costLabel: {
    lineHeight: 1.5,
    fontSize: 14,
    whiteSpace: 'pre-line',
    margin: theme.spacing(0.5)
  },
  info: {
    lineHeight: 1.5,
    fontSize: 14
  },
  importantInfo: {
    lineHeight: 2,
    fontSize: 18
  },
  cell: {
    backgroundColor: 'white',
    paddingLeft: 0,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  subheader: {
    fontSize: 12,
    lineHeight: 2,
    paddingLeft: theme.spacing(2.5),
    color: '#4A90E2',
    backgroundColor: '#ebf3fd'
  },
  textBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
});


class Show extends Component {
  id = -1;
  quote = null;
  keys = ['subtotal', 'valid_before'];
  desc = ['miscellaneous', 'service', 'tax'];
  histories = {};

  constructor(props) {
    super(props);

    this.state = {
      show: false,
      expanded: true,
      requesting: false,

      selected: -1,
      editable: false,
      promote: false
    };

    let id = props.match.params.id;
    if (id) this.id = parseInt(id);
    if (this.id > 0) {
      this.show();
    }
  }

  show = () => {
    let self = this;
    DataManager.getInstance().show_quote(this.id, null).then(function (res) {
      if (res.c === 0) {
        self.quote = res.d.q;
        self.quote.items.sort((l, r) => (l.category >= r.category) ? -1 : 1);
        self.quote.quantity = 0;
        let i;
        for (i = 0; i < self.quote.items.length; ++i) {
          const item = self.quote.items[i];
          self.quote.quantity += item.quantity;
        }
        self.setState({show: true, editable: self.quote.state >= 10, promote: !!res.d.pm});
      }
    });
  };

  update_quote = (item, value, memo) => {
    const self = this;
    const params = {iid: item.id, price: value, memo: memo};
    self.setState({requesting: true});
    DataManager.getInstance().update_quote(this.id, params).then(function (res) {
      if (res.c === 0) {
        const q = res.d.q;
        item.price = q.price;
        item.memo = memo;
        if (q.ds) item.state = q.ds;
        self.quote.state = q.state;
        self.quote.subtotal = q.subtotal;
        self.setState({requesting: false, editable: self.quote.state >= 10, promote: !!res.d.pm});
      }
    });
  };

  update_res = (value) => {
    const self = this;
    const params = {res: value};
    self.setState({requesting: true});
    DataManager.getInstance().update_quote(this.id, params).then(function (res) {
      if (res.c === 0) {
        self.quote.res = value;
        self.quote.state = res.d.q.state;
        self.quote.subtotal = res.d.q.subtotal;
        self.setState({requesting: false, editable: self.quote.state >= 10, promote: !!res.d.pm});
      }
    });
  };

  submit_quote = (value) => {
    const self = this;
    const params = {valid: value || ''};
    self.setState({requesting: true, promote: false});
    DataManager.getInstance().update_quote(this.id, params).then(function (res) {
      if (res.c === 0) {
        self.quote.state = res.d.q.state;
        self.quote.valid_before = res.d.q.valid_before;
        self.setState({requesting: false, editable: self.quote.state >= 10});
        DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'success', m: T.translate('notification.success')});
      }else{
        DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'error', m: T.translate('notification.failed')});
      }
    });
  };

  show_item_images = (index) => {
    this.setState({item_images: index});
  };

  close_item_images = (e, reason) => {
    if (reason === 'backdropClick') return;
    this.setState({selected: -1, item_images: -1});
  };

  expand = (e) => {
    this.setState({expanded: !this.state.expanded});
  };

  selected = (id, index) => {
    this.setState({selected: index});
    //DataManager.getInstance().pub(Event.REDIRECT, `/products/i/${id}`);
  };

  deselect = () => {
    this.setState({selected: -1});
  };

  render() {
    if (!this.state.show) return <React.Fragment/>;
    //const { classes } = this.props;

    return (
      <React.Fragment>
        {this.table()}
        <ProductDialog open={this.state.selected >= 0} onClose={this.deselect} product={this.state.selected >= 0 ? this.quote.items[this.state.selected] : undefined} />

        {this.state.item_images >= 0 &&
        <ImagesDialog
          maxWidth="xl"
          images={this.quote.items[this.state.item_images].media}
          open={this.state.item_images >= 0}
          title=" "
          onClose={this.close_item_images}
        />
        }
      </React.Fragment>
    )
  }

  table() {
    const { classes } = this.props;
    const quote = this.quote;
    const editable = this.state.editable && DataManager.getInstance().permit('Quote') && DataManager.getInstance().permit('Price');
    const counter = {c: 0, q: 0, uq: 0, t: priceDisplay(quote.subtotal, quote.currency)};
    let i;
    for (i = 0; i < quote.items.length; ++i) {
      const item = quote.items[i];
      const price = parseFloat(item.price);
      if (price >= 0){
        counter.q += item.quantity;
        counter.c++;
      } else
        counter.uq += item.quantity;
    }
    const u_str = T.translate('deals.unit');

    const self = this;
    return (
      <Grid alignItems="center" justify="center" container>
        <Grid item xs={12} sm={8} md={8} lg={6} xl={6} className={classes.root}>
          <React.Fragment>
            <Box style={{paddingBottom: this.state.expanded ? 130 : 0}}>
              <List className={classes.list}>
                <ListSubheader >{T.translate('quotation.req')}: {quote.req}</ListSubheader>
                <ListSubheader>{quote.items.length} {u_str}, {T.translate('orders.total_items', {c: quote.quantity})}</ListSubheader>
                {quote.items.map((item, index) => {
                  return <React.Fragment key={item.id}>
                    {index === 0 || item.category !== quote.items[index - 1].category ?
                      <ListSubheader className={classes.subheader}>{item.category}</ListSubheader>
                      :
                      <Divider component="li" />
                    }
                    <ItemCell
                      key={item.id}
                      item={item}
                      onImageClick={() => self.show_item_images(index)}
                      onSave={editable ? this.update_quote : undefined}
                      history={this.histories}
                      deal_id={this.quote.deal_id}
                      disabled={this.state.requesting}
                    />
                  </React.Fragment>;
                })}
                <ListSubheader className={classes.subheader}>{T.translate('quotation.quote')}</ListSubheader>
                <ListItem className={classes.cell} alignItems="center" >
                  <ListItemText
                    primary={
                      <Box className={classes.costBlock}>
                        <Box className={classes.textBox}>
                          <Typography component="span" className={classes.costLabel}>{T.translate('orders.subtotal')}</Typography>
                          <Typography component="span" className={classes.importantInfo}>{priceDisplay(quote.subtotal, quote.currency)}</Typography>
                        </Box>
                        <Box className={classes.textBox}>
                          <Typography component="span" className={classes.costLabel}>{T.translate('quotation.valid_date')}</Typography>
                          <Typography component="span" className={classes.importantInfo}>{quote.valid_before || T.translate('quotation.valid_date_empty')}</Typography>
                        </Box>
                      </Box>
                      }
                  />
                </ListItem>
                <ListSubheader className={classes.subheader}>{T.translate('orders.memo')}
                  {editable && <ResDialog value={quote.res} onSave={this.update_res} disabled={this.state.requesting} />}
                </ListSubheader>
                <ListItem className={classes.cell} alignItems="center" >
                  <ListItemText
                    primary={
                      <Box className={classes.costBlock}>
                        <Typography className={classes.costLabel}>{quote.res}</Typography>
                      </Box>
                      }
                  />
                </ListItem>
              </List>
            </Box>

            <Paper className={classes.header}>
              <Box>
                <Typography component="span" className={classes.label}>{T.translate('quotation.quoted')}:
                  <Typography component="span" className={classes.importantInfo}> {counter.c} / {quote.items.length} </Typography>
                  {u_str}, </Typography>
                <Typography component="span" className={classes.label}>{T.translate('orders.total')}: &nbsp;</Typography>
                <Typography component="span" className={classes.importantInfo}>{counter.t}</Typography>
              </Box>
              {editable ?
              <QuoteButton
                min={quote.min}
                value={quote.valid_before}
                classes={classes}
                counter={counter}
                onSubmit={this.submit_quote}
                disabled={this.state.requesting}
                open={this.state.promote}
                onClose={() => {if (self.state.promote) self.setState({promote: false});}}
              />
                :
              QuoteBadge(quote.state)
              }
            </Paper>
          </React.Fragment>
        </Grid>
      </Grid>
    )
  }
}


function ResDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(props.value);

  function handleClickOpen(e) {
    setOpen(true);
  }

  function handleClose(e) {
    setOpen(false);
    if (e.currentTarget.dataset.tag === '1') {
      props.onSave(value);
    }else{
      setValue(props.value);
    }
  }

  const handleChange = e => {
    setValue(e.target.value);
  };

  return (
    <div style={{display: 'inline-block'}} onClick={(e) => e.stopPropagation()}>
      <IconButton disabled={props.disabled} color="primary" align="center" size="small" onClick={handleClickOpen}>
        <EditIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth='xs'
        fullWidth
      >
        <DialogTitle id="form-dialog-title">{T.translate('orders.memo')}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            multiline={true}
            inputProps={{
              maxLength: 256
            }}
            //defaultValue={props.value}
            value={value}
            onChange={handleChange}
            label={T.translate('orders.memo')}
            type="text"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button data-tag={0} onClick={handleClose}>
            {T.translate('buttons.cancel')}
          </Button>
          <Button data-tag={1} onClick={handleClose} color="primary">
            {T.translate('buttons.submit')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


function QuoteButton(props) {
  const { classes, counter } = props;

  function dateFormat(date) {
    var mm = date.getMonth() + 1; // getMonth() is zero-based
    var dd = date.getDate();

    return [date.getFullYear(),
      (mm > 9 ? '' : '0') + mm,
      (dd > 9 ? '' : '0') + dd
    ].join('-');
  }

  const min = new Date();
  min.setDate(min.getDate() + props.min);
  const dt = dateFormat(min);

  let def = props.value;
  let op = props.open;
  if (def) {
    const ts = Date.parse(def);
    if (ts) {
      let date = new Date(ts);
      if (date < min) {
        date = min;
        def = dateFormat(date);
      }
    }
  }
  const [open, setOpen] = React.useState(op);
  const [value, setValue] = React.useState(def);
  React.useEffect(() => {
    setValue(def);
    setOpen(op);
  }, [def, op]);

  function handleClickOpen(e) {
    setOpen(true);
  }

  function handleClose(e) {
    setOpen(false);
    if (e.currentTarget.dataset.tag === '1') {
      props.onSubmit(value);
    }else{
      props.onClose();
    }
  }

  const handleChange = e => {
    const v = e.target.value;
    if (!v) setValue('');
    const ts = Date.parse(v);
    if (!ts) return;
    let date = new Date(ts);
    if (date < min) date = min;
    const ds = dateFormat(date);
    setValue(ds);
  };

  const u_str = T.translate('orders.unit');
  return (
    <div style={{display: 'inline-block'}} onClick={(e) => e.stopPropagation()}>
      <Button variant="contained" color="primary" size="small" onClick={handleClickOpen} disabled={props.disabled} >
        {T.translate('quotation.submit')}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        maxWidth='xs'
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">{T.translate('quotation.submit')}</DialogTitle>
        <DialogContent>
          <Box>
            <Typography component="span" className={classes.label}>{T.translate('quotation.unquoted')}:
              <Typography component="span" className={classes.importantInfo}> {counter.uq} </Typography>
              {u_str}, </Typography>
            <Typography component="span" className={classes.label}>{T.translate('quotation.quoted')}:
              <Typography component="span" className={classes.importantInfo}> {counter.q} </Typography>
              {u_str}, </Typography>
            <Typography component="span" className={classes.label}>{T.translate('orders.total')}: &nbsp;</Typography>
            <Typography component="span" className={classes.importantInfo}>{counter.t}</Typography>
          </Box>
          <Box>
            <Typography component="span" className={classes.label}>{T.translate('quotation.before_submit', {app: T.translate('appName')})}</Typography>
          </Box>
          <Box>
            <TextField
              name="date"
              type="date"
              fullWidth
              label={T.translate('quotation.valid_date_desc')}
              placeholder={T.translate('quotation.valid_date_desc')}
              inputProps={{
                min: dt
              }}
              value={value || ''}
              onChange={handleChange}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Box>

        </DialogContent>
        <DialogActions>
          <Button data-tag={0} onClick={handleClose}>
            {T.translate('buttons.cancel')}
          </Button>
          <Button data-tag={1} onClick={handleClose} color="primary">
            {T.translate('buttons.submit')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


export default withRouter(withStyles(styles)(Show));