import React, { Component } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  image: {
    maxWidth: '100%',
    maxHeight: '100%',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  img: {
    //width: '100%',
    maxWidth: '100%',
    maxHeight: '100%',
    verticalAlign: 'middle'
  },
  title: {
    position: 'absolute',
    bottom: theme.spacing(1),
    //left: 0,
    lineHeight: 1.3,
    padding: theme.spacing(0.2, 1, 0, 1),
    textAlign: 'center',
    fontSize: 13,
    borderRadius: 30,
    backgroundColor: 'rgba(60, 60, 60, 0.4)',
    color: 'white'
    //border: '1px solbid'
  }
});

class Image extends Component {
  render() {
    const { classes, src, alt, title, ...props } = this.props;
    return (
      <div {...props} className={classes.image}>
        <img style={this.props.onClick ? {cursor: 'pointer'} : null} className={classes.img} src={src} alt={alt} />
        {title && <Typography align="center" variant="caption" className={classes.title}>{title}</Typography>}
      </div>
    )
  }
}

export default withStyles(styles)(Image);
