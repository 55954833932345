/**
 * Created by milan on 2019/7/18.
 */
import React, { Component } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import { DataManager } from '../models/';

import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';


const PROVINCES = [
  {
  "name": "北京市",
  "id": "110000000000"
}, {
  "name": "天津市",
  "id": "120000000000"
}, {
  "name": "河北省",
  "id": "130000000000"
}, {
  "name": "山西省",
  "id": "140000000000"
}, {
  "name": "内蒙古自治区",
  "id": "150000000000"
}, {
  "name": "辽宁省",
  "id": "210000000000"
}, {
  "name": "吉林省",
  "id": "220000000000"
}, {
  "name": "黑龙江省",
  "id": "230000000000"
}, {
  "name": "上海市",
  "id": "310000000000"
}, {
  "name": "江苏省",
  "id": "320000000000"
}, {
  "name": "浙江省",
  "id": "330000000000"
}, {
  "name": "安徽省",
  "id": "340000000000"
}, {
  "name": "福建省",
  "id": "350000000000"
}, {
  "name": "江西省",
  "id": "360000000000"
}, {
  "name": "山东省",
  "id": "370000000000"
}, {
  "name": "河南省",
  "id": "410000000000"
}, {
  "name": "湖北省",
  "id": "420000000000"
}, {
  "name": "湖南省",
  "id": "430000000000"
}, {
  "name": "广东省",
  "id": "440000000000"
}, {
  "name": "广西壮族自治区",
  "id": "450000000000"
}, {
  "name": "海南省",
  "id": "460000000000"
}, {
  "name": "重庆市",
  "id": "500000000000"
}, {
  "name": "四川省",
  "id": "510000000000"
}, {
  "name": "贵州省",
  "id": "520000000000"
}, {
  "name": "云南省",
  "id": "530000000000"
}, {
  "name": "西藏自治区",
  "id": "540000000000"
}, {
  "name": "陕西省",
  "id": "610000000000"
}, {
  "name": "甘肃省",
  "id": "620000000000"
}, {
  "name": "青海省",
  "id": "630000000000"
}, {
  "name": "宁夏回族自治区",
  "id": "640000000000"
}, {
  "name": "新疆维吾尔自治区",
  "id": "650000000000"
}];


const styles = theme => ({
  root: {
  },
  select: {
    marginRight: 16,
    minWidth: 80
  }
});


class RegionSelector extends Component {
  projects = null;
  names = ['country', 'province', 'city', 'county'];
  keys = ['country', 'province'];
  state = {values: ['86', '', '', ''], requesting: true, alert: false};
  //state = {values: ['86', '110000000000', '110100000000', '110105000000'], requesting: true, alert: false};
  regions = {country: {'86': PROVINCES}, province: {}, city: {}, county: {}};

  constructor(props) {
    super(props);

    const initial = props.initialRegion;
    if (initial) {
      const rs = initial.split(',');
      this.state.values.splice(1, rs.length, ...rs);
    }
    this.state.requesting = this.list(this.state.values);
  }

  list = (values) => {
    const params = {};
    let c = 0;
    let i;
    for (i = 0; i < this.keys.length; ++i) {
      const key = this.names[i];
      const chosen = values[i];
      if (chosen && !this.regions[key][chosen]) {
        params[this.names[i]] = chosen;
        c++;
      }
    }
    if (c === 0) {
      return false;
    }

    if (!this.state.requesting)
      this.setState({ requesting: true });
    const self = this;
    DataManager.getInstance().list_regions(params).then(function (res) {
      if (res.c === 0) {
        let key;
        for (key in res.d) {
          if (res.d.hasOwnProperty(key)) {
            const current = params[key];
            self.regions[key][current] = res.d[key];
          }
        }
      }
      self.do_favor(values);
      self.setState({ values: values, requesting: false });
    });
    return true;
  };

  do_favor = (values) => {
    const self = this;
    let i;
    for (i = 0; i < self.keys.length; ++i) {
      if (values[i + 1]) continue;
      const v = values[i];
      const key = self.keys[i];
      if (self.regions[key][v] && self.regions[key][v].length === 1) {
        const nv = self.regions[key][v][0].id;
        const nk = self.names[i + 1];
        if (i === self.keys.length - 1 || (self.regions[nk][nv] && self.regions[nk][nv].length > 0))
         values[i + 1] = nv;
      }
    }
    const ret = [];
    const last = this.keys.length;
    if (values[last]) {
      //this.props.onSave(values.slice(0, last + 1));
      let region = null;
      for (i = 0; i <= last; ++i) {
        const v = values[i];
        const key = self.names[i];
        if (region) {
          let j;
          for (j = 0; j < region.length; ++j) {
            if (region[j].id === v) {
              ret.push(region[j]);
              break;
            }
          }
        }
        region = self.regions[key][v];
      }
    }
    this.props.onSave(ret);
  };

  on_change = (e) => {
    const name = e.target.name;
    const chosen = e.target.value;
    const index = this.names.indexOf(name);
    if (chosen === this.state.values[index]) return;
    const values = this.state.values.slice();
    values[index] = chosen;
    let i;
    for (i = index + 1; i < values.length; ++i) {
      values[i] = '';
    }
    this.do_favor(values);
    this.setState({ values: values });
    this.list(values);
  };

  render() {
    const { error, label, classes } = this.props;

    return (
      <React.Fragment>
        {this.keys.map((key, index) => {
          const current = this.state.values[index];
          const labelId = `label-id-${index}`;
          if (this.state.requesting && !this.regions[key][current])
            return <CircularProgress key={key} style={{marginTop: 24}} size={24} />;
          const com = <Select
              labelId={labelId}
              name={this.names[index + 1]}
              value={this.state.values[index + 1]}
              onChange={this.on_change}
              //variant="outlined"
              error={!!error}
              className={classes.select}
            >
              {this.regions[key][current] && this.regions[key][current].map((region, _) => {
                return <MenuItem key={region.id} value={region.id}>{region.name}</MenuItem>
              })}
            </Select>;
          //if (index > 0) return <React.Fragment key={key}>{com}</React.Fragment>;

          return <FormControl required={index === 0} error={!!error} key={key} >
            <InputLabel id={labelId} shrink={true}>
              {index === 0 ? label : ''}
            </InputLabel>
            {com}
            <FormHelperText>{index === 0 ? error : ''}</FormHelperText>
          </FormControl>
        })}
      </React.Fragment>
    );
  }
}


export default withStyles(styles)(RegionSelector);