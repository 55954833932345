import React,  { Component } from 'react';
import { Route } from 'react-router-dom';
import withStyles from '@material-ui/styles/withStyles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Topbar from '../Topbar';
import { Permit } from '../models/';

import List from './List';
import Edit from './Edit';
import Show from './Show';


const styles = theme => ({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
    marginTop: theme.spacing(2),
    padding: theme.spacing(0)
  }
});

class Inquiries extends Component {
  render() {
    const { classes } = this.props;
    const currentPath = this.props.location.pathname;
    const path = '/' + this.props.match.path.split('/')[1];

    return (
      <React.Fragment>
        <CssBaseline />
        <Topbar currentPath={currentPath} />
        <Permit permit={['Price', 'Quote']}>
        <Route path={path} exact component={ List } />
        <div className={classes.root}>
          <Route path={`${path}/s/:id?`} component={ Show } />
          <Route path={`${path}/e/:id?`} component={ Edit } />
        </div>
        </Permit>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(Inquiries);