import React, { Component } from 'react';
//import { Redirect } from 'react-router';
import withStyles from '@material-ui/styles/withStyles';
import { withRouter } from 'react-router-dom';
import DataManager from './DataManager';
import {Event} from './Data';

import Notification from '../common/Notification';
import Footer from '../common/Footer';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    minHeight: '100vh',
    backgroundColor: theme.palette.grey['A500']
  }
});

class Authorizer extends Component {
  state = {
    notification: {show: false, variant: '', message: ''}
  };

  static auth_not_required = ['/landing', '/signup', '/signin', '/inquiries/c/'];
  unauthorized = (params) => {
    if (Authorizer.auth_not_required.indexOf(this.props.history.location.pathname) < 0)
      this.props.history.push(params);
  };

  redirect = (params) => {
    if (params === '__go_back__')
      this.props.history.goBack();
    else if (this.props.history.location.pathname !== params)
      this.props.history.push(params);
  };

  notification = (params) => {
    this.setState({notification: {show: true, variant: params.t, message: params.m}});
  };

  onNotificationClose = (params) => {
    this.setState({notification: {show: false, variant: '', message: ''}});
  };

  componentDidMount() {
    DataManager.getInstance().sub(Event.REDIRECT, this.redirect);
    DataManager.getInstance().sub(Event.UNAUTHORIZED, this.unauthorized);
    DataManager.getInstance().sub(Event.NOTIFICATION, this.notification);
  }

  componentWillUnmount() {
    DataManager.getInstance().unsub(Event.REDIRECT, this.redirect);
    DataManager.getInstance().unsub(Event.UNAUTHORIZED, this.unauthorized);
    DataManager.getInstance().unsub(Event.NOTIFICATION, this.notification);
  }

  render() {
    const {classes} = this.props;
    let n = this.state.notification;
    return (
      <div className={classes.root}>
        {n.show &&
          <Notification variant={n.variant} message={n.message} onClose={this.onNotificationClose.bind(this)} />
        }
        {this.props.children}
        <Footer />
      </div>
    )
  }
}

export default withRouter(withStyles(styles)(Authorizer));
