import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/styles/withStyles';
import BaseDialog from './BaseDialog';


const styles = theme => ({
  container: {
    flexGrow: 1,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minWidth: 320
  },
  bottomMargin: {
    marginBottom: theme.spacing(2)
  },
  player: {
    maxWidth: '100%',
    maxHeight: '80vh',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  fullPlayer: {
    //maxWidth: '100%',
    //maxHeight: '80vh',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  img: {
    //width: '100%',
    maxWidth: '100%',
    maxHeight: '80vh',
    verticalAlign: 'middle'
  }
});

class VideoDialog extends Component {
  state = {
    autoplay: true,
  };

  render() {
    const { classes, url, ...props } = this.props;
    const player = this.props.maxWidth === 'xl' ? classes.fullPlayer : classes.player;
    return (
      <BaseDialog {...props}>
        <div className={classes.container}>
          <div className={classes.bottomMargin} >
            <div key={url} className={player}>
              {url && props.open &&
              <video className={classes.img} controls autoPlay="autoplay">
                <source src={url} />
                <p>您的浏览器不支持 HTML5 视频播放. 您可以更换至最新版的 Chrome/Edge/Safari/Firefox 等现代浏览器后在线播放, 或<a href={url}>点击下载</a>这个视频后本地观看.</p>
              </video>
              }
            </div>
          </div>
        </div>
      </BaseDialog>
    )
  }
}

export default withRouter(withStyles(styles)(VideoDialog));
