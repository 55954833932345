import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Badge from '@material-ui/core/Badge';
import T from 'i18n-react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Mode, DataManager } from '../models/';


function allyProps(index) {
  return {
    id: `order-state-${index}`,
    'aria-controls': `order-state-${index}`
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  },
  padding: {
    padding: theme.spacing(0, 2)
  }
}));

export default function Navigator(props) {
  const { current, onChange } = props;
  const classes = useStyles(props.theme);

  const states = [];
  let i;
  let active;
  const mode = DataManager.getInstance().mode;
  if (mode >= Mode.ADMIN) {
    states.push({title: T.translate('products.wanted'), state: 2});
    states.push({title: T.translate('products.quoted'), state: 1});
  }else{
    if (mode >= Mode.BUSINESS) {
      active = ['active', 'ec', 'my_quote'];
    }else{
      active = ['customizable', 'standard', 'ec'];
    }
    for (i = 0; i < active.length; ++i) {
      states.push({title: T.translate(`products.${active[i]}`), state: i});
    }
    states.reverse();
  }
  let def = current;

  const [value, setValue] = React.useState(def);
  const matches = useMediaQuery('(min-width:720px)');

  React.useEffect(() => {
    setValue(def);
  }, [def]);

  const handleChange = (e, newValue) => {
    setValue(newValue);
    e.target.value = newValue;
    onChange(e);
  };

  return (
    <div className={classes.root}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant={matches ? "fullWidth" : "scrollable"}
          scrollButtons="on"
          //centered
          aria-label="state switch">
          {states.map((state, index) =>
            <Tab
              key={index}
              value={state.state}
              {...allyProps(index)}
              label={
                <Badge className={classes.padding} color="primary" badgeContent={0}>
                  {state.title}
                </Badge>
              }
            />
          )}
        </Tabs>
    </div>
  );
}
