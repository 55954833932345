import React, { Component } from 'react';
import withStyles from '@material-ui/styles/withStyles';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import T from 'i18n-react';
import { Mode, Event, DataManager } from '../models';

import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import TriD from '../common/TriD';


const styles = theme => ({
  root: {
    backgroundColor: 'white'
  },
  box: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    padding: theme.spacing(0.5)
  },
  title: {
    width: '100%',
    textAlign: 'center'
  },
  chip: {
    width: 160,
    height: 160,
    maxWidth: 160,
    maxHeight: 160,
    minWidth: 160,
    minHeight: 160,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  content: {
    
    maxWidth: '100%',
    maxHeight: '100%',
  },
  btns: {
    margin: theme.spacing(0.5),
    width: 160,
    height: 160,
    maxWidth: 160,
    maxHeight: 160,
    minWidth: 160,
    minHeight: 160,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
});


class SpecsEdit extends Component {
  chipData = null;
  base = null;
  dragging = -1;
  adding = -1;
  state = {};
  file_ref = null;
  selected_files = null;
  uploading_key = null;
  uploading_path = null;
  state = {cursor: 0};

  constructor(props) {
    super(props);
    if (props.specs)
      this.chipData = props.specs;
    else
      this.chipData = this.onShow();
  }

  onChange = (e) => {
    const tri = this.chipData.tri;
    if (!tri || tri.length <= 0) return;
    const tag = parseInt(e.currentTarget.dataset.tag);
    let nc = this.state.cursor + tag;
    if (nc < 0) nc = tri.length - 1;
    else if (nc >= tri.length) nc = 0;
    this.setState({ cursor: nc });
  }

  onShow = () => {
    const item = this.props.item;
    const item_id = item.item_id >= 0 ? item.item_id : item.id;
    const self = this;
    const m = DataManager.getInstance().mode === Mode.ADMIN ? 1 : 0;
    return DataManager.getInstance().show_specs(item.product_id, item_id, {e: m}).then(function (res) {
      if (res.c !== 0) {
        DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'error', m: T.translate('notification.failed')});
        return;
      }
      self.base = res.d.base;
      self.chipData = res.d.specs;
      self.forceUpdate();
    });
  };

  onSave = (e) => {
    const onClose = this.props.onClose;
    const item = this.props.item;
    const item_id = item.item_id >= 0 ? item.item_id : item.id;

    const params = {key: 'save', specs:this.chipData};
    return DataManager.getInstance().save_specs(item.product_id, item_id, params).then(function (res) {
      if (res.c !== 0) {
        DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'error', m: T.translate('notification.failed')});
        return;
      }
      DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'success', m: T.translate('notification.success')});
      onClose();
    });
  };

  onUpload = (files, url, key) => {
    const self = this;
    if (!key) key = this.uploading_key;
    const item = this.props.item;
    const item_id = item.item_id >= 0 ? item.item_id : item.id;

    const params = {key: key};
    if (files) params.files = files;
    if (url) params.url = url;
    if (this.uploading_path) params.path = this.uploading_path;
    return DataManager.getInstance().upload_specs(item.product_id, item_id, params).then(function (res) {
      if (res.c !== 0) {
        DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'error', m: T.translate('notification.failed')});
        return;
      }
      self.chipData[key] = self.chipData[key].concat(res.d);
      self.uploading_path = null;
      self.forceUpdate();
      DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'success', m: T.translate('notification.success')});
    });
  };

  onPathUpload = (key, path) => {
    this.uploading_key = key;
    this.uploading_path = path;
    this.file_ref.click();
  };

  onAdd = (e) => {
    const tag = parseInt(e.currentTarget.dataset.tag);
    if (tag === -2) {
      this.uploading_path = null;
      this.uploading_key = e.currentTarget.dataset.key;
      this.file_ref.click();
    }
  };

  onDrag = (e) => {
    const tag = e.currentTarget.dataset.tag;
    this.dragging = parseInt(tag);
    this.adding = -1;
  };

  onDrop = (e) => {
    const key = e.currentTarget.dataset.key;
    const drop = parseInt(e.currentTarget.dataset.tag);
    const chipData = this.chipData[key];
    if (this.dragging >= 0) {
      if (drop >= 0) {
        const drag = chipData[this.dragging];
        chipData[this.dragging] = chipData[drop];
        chipData[drop] = drag;
      }else{
        chipData.splice(this.dragging, 1);
      }
      this.forceUpdate();
    }
  };

  render() {
    const { edit, open, onClose } = this.props;
    const show = !edit && this.chipData.tri && this.chipData.tri.length > 0;

    return (
      <React.Fragment>
        {show ? 
        <Dialog open={open} onClose={onClose} fullWidth maxWidth='md' aria-labelledby="dialog-title">
          <DialogContent>
            {this.show_3d()}
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color="secondary">
              {T.translate('buttons.cancel')}
            </Button>
            <Button onClick={this.onChange} data-tag={-1} color="primary">
              {T.translate('buttons.prev')}
            </Button>
            <Button onClick={this.onChange} data-tag={1} color="primary">
              {T.translate('buttons.next')}
            </Button>
          </DialogActions>
        </Dialog>
        : 
        <Dialog open={open} onClose={onClose} fullWidth maxWidth='md' aria-labelledby="dialog-title">
          <DialogContent>
            {this.editor()}
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color="secondary">
              {T.translate('buttons.cancel')}
            </Button>
            <Button onClick={this.onSave} color="primary">
              {T.translate('buttons.save')}
            </Button>
          </DialogActions>
        </Dialog>
        }
      </React.Fragment>
    );
  }

  show_3d() {
    const { classes } = this.props;
    const chipData = this.chipData;
    const url = chipData.tri[this.state.cursor];
    // const fn = decodeURIComponent(url.split('/').pop().split('#')[0].split('?')[0]).toLowerCase();
    // const ft = fn.split('.').pop();

    return (
      <Box className={classes.root}>
        <Box className={classes.box}>
          <TriD texture={this.base + 'texture/'} url={url}></TriD>
        </Box>
      </Box>
    )
  }

  editor() {
    if (!this.chipData) return null;
    const self = this;
    const { classes } = this.props;
    const chipData = this.chipData;
    
    return (
      <Box className={classes.root}>
        <input
          ref={fileInput => self.file_ref = fileInput}
          style={{display: 'none'}}
          // accept="image/*"
          type="file"
          name="file"
          multiple="multiple"
          onChange={(e) => {
            self.onUpload(e.currentTarget.files, undefined, undefined);
          }}
        />
        {Object.keys(chipData).map(function(key) {
          const chips = chipData[key];
          return <React.Fragment key={key}>
            <Typography className={classes.title} variant="subtitle1">{T.translate(`products.${key}`)}</Typography>
            <Box className={classes.box}>
              <Box 
                className={classes.btns}
                key={-1}
                data-tag={-1}
                data-key={key}
                draggable={false}
                onDragOver={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
                onDrop={self.onDrop}
              >
                {key === 'texture' ? 
                <PathButton color="primary" variant="outlined" aria-label="url" data-tag={-1} spec_key={key} onClick={self.onPathUpload} >
                  {T.translate('buttons.upload_with_path')}
                </PathButton>
                :
                <URLButton color="primary" variant="outlined" aria-label="url" data-tag={-1} spec_key={key} onClick={self.onUpload} >
                  {T.translate('buttons.url')}
                </URLButton>
                }
                <Button color="primary" variant="outlined" aria-label="local" data-tag={-2} data-key={key} onClick={self.onAdd} >
                  {T.translate('buttons.upload')}
                </Button>
                <Button disabled={true} variant="outlined" aria-label="delete" data-tag={-3} data-key={key} >
                  {T.translate('buttons.delete')}
                </Button>
              </Box>

              {chips.map((label, index) => {            
                return (
                  <Spec
                    key={index}
                    data-tag={index}
                    data-key={key}
                    alt={label}
                    src={label}
                    classes={classes}
                    draggable={true}
                    onDragOver={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                    onDragStart={self.onDrag}
                    onDrop={self.onDrop}
                  />
                );
              })}
            </Box>
          </React.Fragment>;
          
        })}
      </Box>
    )
  }
}


function Spec(props) {
  const { classes, src, alt, ...left } = props;
  const fn = decodeURIComponent(src.split('/').pop().split('#')[0].split('?')[0]).toLowerCase();
  const ft = fn.split('.').pop();
  const is_image = ['png', 'jpg', 'jpeg'].indexOf(ft) >= 0;

  const style = {margin: 5};
  // if (!is_image) {
  //   style.border = '1px solid #8304C0';
  // }
  return (
    <div {...left} className={classes.chip} style={style}>
      {is_image ?
      <img 
        draggable={false} 
        style={{cursor: 'grab'}} 
        className={classes.content} 
        src={src} 
        alt={alt} 
      />
      :
      <Button disabled={true} className={classes.chip} color="primary" variant="outlined">{fn}</Button>
      }
    </div>
  );
}


function URLButton(props) {
  const { url, onClick } = props;
  const key = props.spec_key;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [value, setValue] = React.useState(url);
  const [open, setOpen] = React.useState(false);
  const regexp =  /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;

  const handleSave = (e) => {
    onClick(undefined, value, key);
    setOpen(false);
    setAnchorEl(null);
  };

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const id = open ? 'simple-popover' : undefined;

  return (
    <React.Fragment>
      <Button color="primary" variant="outlined" aria-label="url" data-tag={-1} data-key={key} onClick={handleClick} >
        {T.translate('buttons.url')}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
      >
        <div style={{padding: 16}}>
        <TextField
          onChange={handleChange}
          autoFocus
          margin="dense"
          id="url"
          label="URL"
          type="url"
          fullWidth
        />
        <Button color="primary" disabled={!regexp.test(value)} data-tag={-1} data-key={key} onClick={handleSave} >
          {T.translate('buttons.submit')}
        </Button>
        </div>
      </Popover>
    </React.Fragment>
  );
}


function PathButton(props) {
  const { onClick } = props;
  const key = props.spec_key;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [value, setValue] = React.useState('');
  const [open, setOpen] = React.useState(false);

  const handleSave = (e) => {
    onClick(key, value);
    setOpen(false);
    setAnchorEl(null);
  };

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const id = open ? 'simple-popover' : undefined;

  return (
    <React.Fragment>
      <Button color="primary" variant="outlined" aria-label="url" data-tag={-1} data-key={key} onClick={handleClick} >
        {T.translate('buttons.upload_with_path')}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
      >
        <div style={{padding: 16}}>
        <TextField
          onChange={handleChange}
          autoFocus
          margin="dense"
          label="text"
          type="text"
          fullWidth
        />
        <Button color="primary" data-tag={-1} data-key={key} onClick={handleSave} >
          {T.translate('buttons.upload')}
        </Button>
        </div>
      </Popover>
    </React.Fragment>
  );
}


export default withStyles(styles)(SpecsEdit);
