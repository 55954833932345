/**
 * Created by milan on 2019/7/18.
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/styles/withStyles';
import T from 'i18n-react';
import { Event, DataManager } from '../models/';
import { tableIcons } from '../common/Constants';
import ImagesDialog from '../dialogs/ImagesDialog';
import Container from '@material-ui/core/Container';

import MaterialTable from 'material-table';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';

import { priceDisplay, QualityLookup } from '../common/Constants';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';


const styles = theme => ({
  header: {
    margin: 0,
    padding: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  distributor: {
    width: 150,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'right',
    justifyContent: 'center'
  },
  slider: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'right'
  },
  sliderContent: {
    height: 32,
    textAlign: 'center',
    margin: theme.spacing(0, 1, 0.5, 1)
  }
});


class Distribution extends Component {
  productColumns = [
    { title: T.translate('products.media'), field: 'media', editable: 'never' },
    { title: T.translate('products.title'), field: 'title', editable: 'never' },
    //{ title: T.translate('products.brand'), field: 'brand', editable: 'never' },
    //{ title: T.translate('products.type'), field: 'item_type', editable: 'never' },
    { title: T.translate('products.quality'), field: 'quality', editable: 'never', lookup: QualityLookup },
    { title: T.translate('products.size'), field: 'size', editable: 'never' },
    { title: T.translate('products.material'), field: 'material', editable: 'never' },
    //{ title: T.translate('products.item_space'), field: 'category', editable: 'always' },
    //{ title: T.translate('orders.shipping'), field: 'shipping', editable: 'always', type: 'numeric' },
    //{ title: T.translate('orders.install'), field: 'install', editable: 'always', type: 'numeric' },
    //{ title: T.translate('orders.miscellaneous'), field: 'miscellaneous', editable: 'always', type: 'numeric' },
    { title: T.translate('products.quantity'), field: 'quantity', editable: 'always', type: 'numeric' },
    { title: T.translate('products.price1'), field: 'display', editable: 'never' }
  ];
  order = null;
  units = [];

  constructor(props) {
    super(props);
    Distribution.init_order(props.order, props.order.units);
    this.order = props.order;
    this.units = props.order.units;
    this.state = {selected: 0, carousel: null, c: this.order.quantity, data: this.order.items, requesting: false};
  }

  static getDerivedStateFromProps(props, curr_state) {
    Distribution.init_order(props.order, props.order.units);
    return {
      data: props.order.items
    };
  }

  static init_order(order, units) {
    let i, j;
    for (i = 0; i < order.items.length; ++i) {
      const item = order.items[i];
      if (!item._dist) {
        item._dist = {total: item.quantity, spared: item.quantity};
        for (j = 0; j < units.length; ++j) {
          const unit = units[j];
          item._dist[unit.id] = 0;
        }
      }
    }
  }

  update_order_items = (newData, oldData) => {
    const self = this;
    let order_id = this.order.id;
    if (order_id > 0) {
      let params = {id: order_id, iid: oldData.item_id, p: oldData.id};
      if (newData) {
        params.q = parseInt(newData.quantity);
        params.shipping = newData.shipping;
        params.install = newData.install;
        params.miscellaneous = newData.miscellaneous;
        params.category = newData.category;
      }else{
        params.q = -1;
      }
      return DataManager.getInstance().update_order_items(params).then(function (res) {
        if (res.c === 0) {
          const d = res.d;
          const costs = d.costs;
          for (var k in costs) {
            if (costs.hasOwnProperty(k)) {
              self.order[k] = costs[k];
            }
          }

          //self.order.subtotal = d.t;
          //self.props.updater({subtotal: d.t});
          self.props.updater(self.order);
          const data = self.state.data.slice();
          if (d.new < 0){
            data.splice(data.indexOf(oldData), 1);
          }else {
            newData.quantity = d.new;
            data[data.indexOf(oldData)] = newData;
          }
          self.order.items = data;
          self.setState({data: data, c: d.c});
        }
      });
    }else{
      return new Promise((resolve, reject) => {
        DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'error', m: T.translate('errors.products.order_error')});
        setTimeout(() => {
          reject();
        }, 1000);
      })
    }
  };

  save = () => {
    let orders = {};
    let i;
    const excluded = ['iid', 'spared', 'total'];
    for (i = 0; i < this.order.items.length; ++i) {
      const item = this.order.items[i];

      for (var k in item._dist) {
        if (item._dist.hasOwnProperty(k) && excluded.indexOf(k) < 0) {
          const v = item._dist[k];
          if (v === 0) continue;
          if (!(k in orders)) orders[k] = {};
          orders[k][item.item_id] = v;
        }
      }
    }
    let path = '/' + this.props.match.path.split('/')[1];
    const self = this;
    self.setState({requesting: true});
    DataManager.getInstance().update_order(this.order.id, {dist: orders}).then(function (res) {
      self.setState({requesting: false});
      if (res.c !== 0) {
        //const d = res.d;
        return;
      }
      DataManager.getInstance().pub(Event.REDIRECT, path);
    });
  };

  showCarousel = (rowData) => {
    this.setState({carousel: rowData});
  };

  closeCarousel = (e) => {
    this.setState({carousel: null});
  };

  media_render = (rowData) => {
    const self = this;
    return (
      <IconButton color="primary" align="center" size="small" onClick={(e) => {
        self.showCarousel(rowData);
      }}>
        <img
          style={{ height: 36, maxWidth: 100 }}
          alt={rowData.media[0]}
          src={rowData.media[0]}
        />
      </IconButton>
    )
  };

  quantity_render = (rowData) => {
    const { classes } = this.props;
    const self = this;
    const unit = this.units[this.state.selected];
    return (
      <Container className={classes.distributor} >
        <div className={classes.slider} >
          <IconButton className={classes.sliderContent} color="primary" size="small" onClick={(e) => {
            rowData._dist[unit.id] += -1;
            rowData._dist.spared -= -1;
            self.onDistChange();
          }} disabled={rowData._dist[unit.id] === 0}>
            -
          </IconButton>
          <Typography color="primary">{rowData._dist[unit.id]}</Typography>
          <IconButton className={classes.sliderContent} color="primary" size="small" onClick={(e) => {
            rowData._dist[unit.id] += 1;
            rowData._dist.spared -= 1;
            self.onDistChange();
          }} disabled={rowData._dist.spared === 0}>
            +
          </IconButton>
        </div>
        <Typography>未分配 {rowData._dist.spared} 件</Typography>
        <Typography>共 {rowData._dist.total} 件</Typography>
        <div style={{width: 32}} ></div>

      </Container>
    )
  };

  onDistChange = () => {
    this.setState({data: this.state.data});
  };

  onUnitChange = (e) => {
    //const unit_id = parseInt(e.target.value);
    const index = parseInt(e.currentTarget.dataset.tag);
    this.setState({selected: index});
  };

  render() {
    const { classes } = this.props;
    const self = this;
    this.productColumns[0].render = this.media_render;
    this.productColumns[4].render = this.quantity_render;

    let images = [];
    let text = {};
    let i;
    if (this.state.carousel != null) {
      const product = this.state.carousel;
      text.title = product.title;
      for (i = 0; i < product.media.length; ++i) {
        const url = product.media[i];
        const image = {label: i, description: url, imgPath: url};
        images.push(image);
      }
    }

    const unit = this.units[this.state.selected];
    let j = 0;
    const current = {};
    for (j = 0; j < this.units.length; ++j) {
      const u = this.units[j];
      current[u.id] = 0;
    }
    let total = 0;
    let spared = 0;

    for (i = 0; i < this.state.data.length; ++i) {
      const item = this.state.data[i];
      item['display'] = priceDisplay(item.price, item.currency);
      total += item._dist.total;
      spared += item._dist.spared;

      for (j = 0; j < this.units.length; ++j) {
        const u = this.units[j];
        current[u.id] += item._dist[u.id];
      }
    }

    const header =
      <Container className={classes.header} >
        <Select
          value={unit.id}
          onChange={this.onUnitChange}
          disableUnderline={true}
          variant="standard"
        >
          {this.units.map((unit, index) => (
            <MenuItem key={unit.id} value={unit.id} data-tag={index}><Typography align="left">{unit.name}, {current[unit.id]} 件</Typography></MenuItem>
          ))}
        </Select>
        <div style={{width: 16}} ></div>
        <Typography align="center">{T.translate('products.items', {c: total})}, 未分配 {spared} 件</Typography>
        <Button style={{marginLeft: 16}} variant="outlined" data-tag={1} color="primary" size="small"
                onClick={this.save} disabled={!(total > 0 && spared === 0 && !this.state.requesting)}>
          {T.translate('orders.distribute')}
        </Button>
      </Container>;

    return (
      <React.Fragment>
        <Container component="main" maxWidth="md" style={{marginTop: 10, marginBottom: 10}}>
          <MaterialTable
            icons={tableIcons}
            //title={T.translate('products.items', {c: this.state.c})}
            title={header}
            options={{search: false, paging: false, detailPanelType: 'single'}}
            columns={self.productColumns}
            data={self.state.data}
          />
        </Container>

        <ImagesDialog
          images={images}
          open={this.state.carousel != null}
          onClose={this.closeCarousel}
          {...text}
        />
      </React.Fragment>
    )
  }
}


export default withRouter(withStyles(styles)(Distribution));