import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import T from 'i18n-react';
import { Mode, DataManager } from '../models/';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Image from '../common/Image';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';

import { priceDisplay } from '../common/Constants';
import { StateBadge, OrderItemBadge } from '../common/StateBadge';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';


const useStyles = makeStyles(theme => ({
  titleBlock: {
    padding: theme.spacing(0, 0, 0, 1),
    minHeight: 150,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'space-between',
    justifyContent: 'space-between'
  },
  rightBlock: {
    padding: theme.spacing(0, 0, 0, 1),
    minHeight: 150,
    minWidth: 110,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'space-between'
  },
  label: {
    fontSize: 14,
    display: 'block',
    [theme.breakpoints.down('xs')]: {
      maxWidth: 130
    }
  },
  boldLabel: {
    fontSize: 20,
    //color: theme.palette.primary.main,
    [theme.breakpoints.down('xs')]: {
      maxWidth: 36
    }
  },
  cell: {
    backgroundColor: 'white',
    padding: theme.spacing(1, 1, 1, 1)
  }
}));


export default function Cell(props) {
  const [requesting, setRequesting] = React.useState(false);

  const { item, divider, onClick, onImageClick, children, tracking } = props;
  const classes = useStyles(props.theme);
  const is_seller = DataManager.getInstance().mode === Mode.BUSINESS;
  const OrderState = DataManager.getInstance().states.items;

  function saveOrder(external) {
    let params = {iid: item.item_id, external: external};
    setRequesting(true);
    const prom = function (res) {
      if (res.c !== 0) {
        return;
      }
      item.external = external;
      setRequesting(false);
    };
    if (DataManager.getInstance().mode < Mode.BUSINESS)
      return DataManager.getInstance().user_order(item.order_id, params).then(prom);
    else
      return DataManager.getInstance().seller_order(item.order_id, params).then(prom);
  }
  let title = item.title;
  if (is_seller && item.external) {
    title = item.external;
  }

  let badges = undefined;
  if (!children && tracking && item.ui) {
    if (is_seller) {
      // badges = <React.Fragment>
      //   {(item.ui.sm > 0 ||item.ui.sc > 0) ?
      //     <StateBadge state={item.ui.sm + item.ui.sa} type={'state_urgent'} title={T.translate('orders.new_message')} />
      //     :
      //     (item.ui.sa > 0 &&
      //       <StateBadge state={item.ui.sa} type={'state_urgent'} title={T.translate('trackers.new_actions', {c: item.ui.sa})} />
      //     )
      //   }
      // </React.Fragment>;
      if (item.ui.sm > 0 ||item.ui.sc > 0) {
        badges = <StateBadge state={item.ui.sm + item.ui.sa} type={'state_urgent'} title={T.translate('orders.new_message')} />;
      }else if (item.ui.sa > 0) {
        badges = <StateBadge state={item.ui.sa} type={'state_urgent'} title={T.translate('trackers.new_actions', {c: item.ui.sa})} />;
      }
    }else{
      if (item.ui.um > 0 ||item.ui.uc > 0) {
        badges = <StateBadge state={item.ui.um + item.ui.ua} type={'state_urgent'} title={T.translate('orders.new_message')} />;
      }else if (item.ui.ua > 0) {
        badges = <StateBadge state={item.ui.ua} type={'state_urgent'} title={T.translate('trackers.new_actions', {c: item.ui.ua})} />;
      }
      // badges = <React.Fragment>
      //   {(item.ui.um > 0 ||item.ui.uc > 0) ?
      //     <StateBadge state={item.ui.um + item.ui.ua} type={'state_urgent'} title={T.translate('orders.new_message')} />
      //     :
      //     (item.ui.ua > 0 &&
      //       <StateBadge state={item.ui.ua} type={'state_urgent'} title={T.translate('trackers.new_actions', {c: item.ui.ua})} />
      //     )
      //   }
      // </React.Fragment>;
    }
  }
  if (!badges) {
    badges = <Box>
      <Typography style={item.quality === '100' ? {color: 'orange'} : undefined} component="span" className={classes.label}>{T.translate(`quality.${item.quality}`)}</Typography>
    </Box>;
  }

  return (
    <React.Fragment>
      <ListItem className={classes.cell} key={item.id} alignItems="center" button={!!onClick} onClick={onClick} >
        <Image
          style={{ height: 130, width: 130, maxWidth: 130, maxHeight: 130, minWidth: 130, minHeight: 130 }}
          alt={item.media[0]}
          src={item.media[0]}
          title={onImageClick ? T.translate('trackers.n_images', {c: item.media.length}) : null}
          onClick={onImageClick}
        />
        <ListItemText
          primary={
            <Box className={classes.titleBlock}>
              <Box style={{display: 'flex', alignItems: 'center'}}>
                <Typography component="span" className={classes.label}>{title}</Typography>
                {props.showEdit && <ExternalDialog value={item.external || item.title} onSave={saveOrder} disabled={requesting} />}
              </Box>
              <Box>
                <Typography component="p" className={classes.label}>{item.material}</Typography>
              </Box>
              <Box>
                <Typography component="p" className={classes.label}>{item.size}</Typography>
              </Box>
            </Box>
          }
        />
        <Box className={classes.rightBlock}>
          <Box style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}}>
            {props.hidePrice ?
              <Typography component="p" className={classes.label}>
                <Typography component="span" className={classes.boldLabel}>{item.quantity}</Typography>
                &nbsp;{T.translate('orders.unit')}</Typography>
              :
            <React.Fragment>
              <Typography component="p" className={classes.label}>{priceDisplay(item.price1, item.currency)}</Typography>

              <Typography component="p" className={classes.label}>&nbsp;x&nbsp;
                <Typography component="span" className={classes.boldLabel}>{item.quantity}</Typography>
                &nbsp;{T.translate('orders.unit')}</Typography>
            </React.Fragment>
            }
          </Box>
          {badges}
          {children || (tracking &&
            <React.Fragment>
              {OrderItemBadge(item.state, OrderState)}
            </React.Fragment>
          )}
        </Box>
      </ListItem>
      {item.memo &&
        <Box className={classes.cell}>
          <Typography component="p" variant="caption" >{T.translate('orders.memo')}: {item.memo}</Typography>
        </Box>
      }
      {divider && <Divider />}
    </React.Fragment>
  );
  //{item.ui && (item.ui.um > 0 ||item.ui.uc > 0) ?
  //  <StateBadge state={item.ui.um + item.ui.ua} type={'state_urgent'} title={T.translate('orders.new_message')} />
  //  :
  //  (item.ui && item.ui.ua > 0 &&
  //    <StateBadge state={item.ui.ua} type={'state_urgent'} title={T.translate('trackers.new_actions', {c: item.ui.ua})} />
  //  )
  //}
}


function ExternalDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(props.value);

  function handleClickOpen(e) {
    setOpen(true);
  }

  function handleClose(e) {
    setOpen(false);
    if (e.currentTarget.dataset.tag === '1') {
      props.onSave(value);
    }else{
      setValue(props.value);
    }
  }

  const handleChange = e => {
    setValue(e.target.value);
  };

  return (
    <div style={{display: 'inline-block'}} onClick={(e) => e.stopPropagation()}>
      <IconButton disabled={props.disabled} color="primary" align="center" size="small" onClick={handleClickOpen}>
        <EditIcon style={{width: 16, height: 16}} />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth='xs'
        fullWidth
      >
        <DialogTitle id="form-dialog-title">{T.translate('orders.edit_external')}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            multiline={true}
            inputProps={{
              maxLength: 75
            }}
            //defaultValue={props.value}
            value={value}
            onChange={handleChange}
            label={T.translate('orders.external')}
            type="text"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button data-tag={0} onClick={handleClose}>
            {T.translate('buttons.cancel')}
          </Button>
          <Button data-tag={1} onClick={handleClose} color="primary">
            {T.translate('buttons.submit')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
