import React,  { Component } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';

import { Link } from 'react-router-dom';
import { Event, Mode, DataManager } from '../models/';
import Landing from '../Landing';
import T from 'i18n-react';
import Pagination from '../common/Pagination';
import TitleBar from '../common/TitleBar';
import ResponsiveButton from '../buttons/ResponsiveButton';
import AlertDialog from '../dialogs/AlertDialog';
import Cell from './Cell';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import SendIcon from '@material-ui/icons/Send';
import TextField from '@material-ui/core/TextField';


const styles = theme => ({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
    marginTop: theme.spacing(2),
    padding: theme.spacing(1)
  },
  paper: {
    width: '100%',
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  list: {
    width: '100%'
  },
  content: {
    fontSize: 24,
    margin: theme.spacing(2)
  },
  pagination: {
    marginTop: theme.spacing(5)
  }
});


class Inquiries extends Component {
  state = {total: 1, page: 1, per: 0, fc: -1, selected: -1, filter: 0, keywords: '', requesting: false};
  inquiries = null;
  inquiry = null;

  constructor(props) {
    super(props);
    const search = props.location.search.substring(1).split('.');
    if (search.length > 1) {
      const p = search[0];
      const page = parseInt(p);
      if (page > 0) this.state.page = page;
      const f = search[1];
      const filter = parseInt(f);
      if (filter > 0) this.state.filter = filter;
    }
    this.state.requesting = true;
    this.list(this.state.page, this.state.filter);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.location.search !== nextProps.location.search) {
      const search = nextProps.location.search.substring(1).split('.');
      if (search.length > 1) {
        const p = search[0];
        const page = parseInt(p);
        if (page > 0) nextState.page = page;
        const f = search[1];
        const filter = parseInt(f);
        if (filter >= 0) nextState.filter = filter;
      }
      this.list(nextState.page, nextState.filter);
      return true;
    }
    return true;
  }

  itemSelected = (index) => {
    const inquiry = this.inquiries[index];
    const path = '/' + this.props.match.path.split('/')[1];
    if (inquiry.state >= 10 || DataManager.getInstance().mode >= Mode.ADMIN) {
      if (inquiry.deal_id > 0)
        DataManager.getInstance().pub(Event.REDIRECT, `${path}/o/${inquiry.deal_id}`);
      else
        DataManager.getInstance().pub(Event.REDIRECT, `${path}/s/${inquiry.id}`);
    }else{
      this.setState({selected: index});
    }
  };

  inquiry_to_qs = (inquiry) => {
    if (!inquiry || !inquiry.title) return null;
    const params = {p: inquiry.project_id, t: inquiry.title};
    return Object.keys(params).map((key) => {
      return key + '=' + encodeURIComponent(params[key])
    }).join('&');
  };

  onChange = (e) => {
    const v = e.target.value;
    this.setState({keywords: v});
  };

  onSearch = () => {
    this.list(this.state.page, this.state.filter);
  };

  displayIndex = (index) => {
    const base = (this.state.page - 1) * this.state.per;
    const display = this.state.total - base - index;
    return display < 10 ? `0${display}` : display;
  };

  handlePagination = (offset) => {
    const page = 1 + offset / this.state.per;
    DataManager.getInstance().pub(Event.REDIRECT, {pathname: this.props.location.pathname, search: `${page}.${this.state.filter}`});
  };

  handleFilter = (e) => {
    const f = parseInt(e.target.value);
    this.setState({filter: f, requesting: true});
    DataManager.getInstance().pub(Event.REDIRECT, {pathname: this.props.location.pathname, search: `1.${f}`});
  };

  closeAlert = (e) => {
    this.setState({selected: -1});
  };

  closeAlertBusiness = (e) => {
    const inquiry = this.inquiries[this.state.selected];
    const path = '/' + this.props.match.path.split('/')[1];

    this.setState({selected: -1});
    if (e.currentTarget.dataset.tag === '1') {
      DataManager.getInstance().pub(Event.REDIRECT, `${path}/e/${inquiry.id}`);
    }else{
      window.open(inquiry.req_file, '_self');
    }
  };

  render() {
    if (!this.inquiries)
      return <div></div>;

    const is_inquirer = DataManager.getInstance().mode < Mode.BUSINESS;
    if (this.state.fc === 0 && is_inquirer)
      return <Landing contentOnly={true} />;

    const { classes } = this.props;

    return (
      <React.Fragment>
        <div className={classes.root}>
          {this.table()}
        </div>

        {is_inquirer ?
          <AlertDialog
            open={this.state.selected >= 0}
            onClose={this.closeAlert}
            title={T.translate('dialogs.no_offer')}
            desc={T.translate('dialogs.please_wait')}
            //left={T.translate('buttons.cancel')}
            right={T.translate('buttons.ok')}
          />
          :
          <AlertDialog
            open={this.state.selected >= 0}
            onClose={this.closeAlertBusiness}
            title={T.translate('quotation.new_inquiry')}
            desc={T.translate('quotation.inquiry_asap')}
            left={T.translate('quotation.download_inquiry')}
            right={T.translate('quotation.inquiry_now')}
          />
        }

      </React.Fragment>
    );
  }

  table = () => {
    const { classes } = this.props;
    const is_inquirer = DataManager.getInstance().mode < Mode.ADMIN;
    const iq = is_inquirer ? 'inquiries' : 'quotation';
    const path = '/' + this.props.match.path.split('/')[1];
    const inquiries = this.inquiries;
    const self = this;
    const states = [T.translate('inquiries.all'), T.translate('inquiries.state_wait'), 
    T.translate('inquiries.state_new'), T.translate('inquiries.state_updating')];  // , T.translate('inquiries.state_read')

    const back_to = {show: true, title: T.translate('inquiries.all'), to: {pathname: path, search: `${this.state.page}`}};
    DataManager.getInstance().storage.inquiries_list = back_to;

    return (
      <Grid alignItems="center" justify="center" container>
        <TitleBar left={{show: false}} titleComponent={
          <Select
            value={this.state.filter}
            onChange={this.handleFilter}
            disableUnderline={true}
            variant="standard"
          >
            <MenuItem value={0}><Typography align="center" variant="h6">{states[0]}</Typography></MenuItem>
            <MenuItem value={1}><Typography align="center" variant="h6">{states[1]}</Typography></MenuItem>
            <MenuItem value={2}><Typography align="center" variant="h6">{states[2]}</Typography></MenuItem>
            <MenuItem value={3}><Typography align="center" variant="h6">{states[3]}</Typography></MenuItem>
            {/* <MenuItem value={4}><Typography align="center" variant="h6">{states[4]}</Typography></MenuItem> */}
          </Select>
        }>
          {is_inquirer ?
            <ResponsiveButton color='primary' variant="contained" align="center" component={Link}
                              to={{pathname: `${path}/c/`}}>
              {T.translate(`${iq}.inquiry_again`)}
            </ResponsiveButton>
            :
            <TextField
              onChange={this.onChange}
              value={this.state.keywords || ''}
              autoFocus
              margin="dense"
              id="text"
              label="Keywords"
              type="text"
              fullWidth
              InputProps={{
                endAdornment: <InputAdornment position="end">
                  <IconButton
                    type="submit"
                    color="primary"
                    disabled={!(this.state.keywords && this.state.keywords.length > 0)}
                    onClick={this.onSearch}
                  >
                    <SendIcon />
                  </IconButton>
                </InputAdornment>
              }}
            />
          }
        </TitleBar>

        <Grid item xs={12} sm={8} md={8} lg={6} xl={6}>
          <Paper className={classes.paper}>
            {this.state.requesting ?
              <CircularProgress size={48} />
              :
              (inquiries.length === 0 ?
                <Typography className={classes.content}>{T.translate('inquiries.empty_list', {state: states[this.state.filter]})}</Typography>
                :
                <List className={classes.list}>
                  {inquiries.map((inquiry, index) => (
                    <Cell
                      key={inquiry.id}
                      item={inquiry}
                      index={index}
                      displayIndex={self.displayIndex(index)}
                      path={path}
                      qs={self.inquiry_to_qs(inquiry)}
                      onClick={() => self.itemSelected(index)}
                    />
                  ))}
                </List>
              )
            }
          </Paper>
          {!this.state.requesting && this.state.per > 0 && this.state.total > this.state.per &&
          <Pagination
            offset={this.state.per * (this.state.page - 1)}
            limit={this.state.per}
            total={this.state.total}
            onClick={this.handlePagination}
          />
          }
        </Grid>
      </Grid>
    );
  };

  list = (page, f) => {
    //if (this.inquiries !== null) return;
    let self = this;
    const q = this.state.keywords;
    DataManager.getInstance().list_inquiries({page: page, f: f, q: q}).then(function (res) {
      if (res.c === 0) {
        let d = res.d;
        self.inquiries = d.i;
        self.setState({page: page, total: d.t, per: d.p, fc: d.fc, requesting: false});
        window.scrollTo(0, 0);
        //self.inquiries = [];
        //self.setState({page: 1, total: 0, per: 0});
      }
    });
  };
}


export default withStyles(styles)(Inquiries);