import React from 'react';
import Button from '@material-ui/core/Button';
import withWidth from '@material-ui/core/withWidth';


function ResponsiveButton({ width, children, ...props }) {
  // This is equivalent to theme.breakpoints.down("sm")
  const isSmallScreen = /xs|sm/.test(width);
  const buttonProps = {
    size: isSmallScreen ? "small" : "large"
  };
  return (
    <Button {...buttonProps} {...props} >{children}</Button>
  );
}

export default withWidth()(ResponsiveButton);
