/**
 * Created by milan on 2019/7/18.
 */
import { DataManager } from '../models/';

import Add from '@material-ui/icons/Add';
//import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Sync from '@material-ui/icons/Sync';
import Slideshow from '@material-ui/icons/Slideshow';

//import React, { forwardRef } from 'react';
//const tableIcons = {
//  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
//  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
//  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
//  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
//  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
//  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
//  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
//  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
//  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
//  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
//  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
//  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
//  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
//  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
//  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
//  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
//  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
//};


const M_KEYS = [
  'identifier', 'title', 'desc', 'domain', 'kingdom', 'division', 'clazz', 'order', 'family', 'genus', 'species', 'quality',
  'content', 'origin', 'region', 'appearance', 'specs', 'uses', 'es',  // 'color', 'cs', 'es_hcho',
  'mc', 'density', 'hardness', 'gw', 'atp', 'marp', 'dry_marp', 'wet_marp',
  // 'inoxidability', 'wetfastness', 'agglutinate', 'mop', 'wap', 'etp', 'pc',
  'tech', 'weave', 'tan', 'surface', 'paint'
];

const M_ITEMS = [  // 's_id', 'currency', 'specs'
  's_name', 's_mid', 's_geo', 'unit', 'ex_factory', 'wholesale', 'retail', 'cut', 'color', 'cs', 'cn'
];

const M_SEARCHABLE = [
  'identifier', 'title', 'desc', 'kingdom', 'clazz', 'family', 'species', 'quality',
  'content', 'region', 'appearance', 'specs', 'uses',
  'cs', 's_name', 's_mid'  // , 'color', 'es_hcho', 'unit', 'ex_factory', 'wholesale', 'retail', 'cut', 'es'
];

const M_VISIBLE = [
  'identifier', 'title', 'desc', 'kingdom', 'clazz', 'family', 'species', 'quality',
  'content', 'origin', 'region', 'appearance', 'specs', 'uses', 'es',  // , 'color', 'cs'
  // 's_name', 's_mid', 'unit', 'ex_factory', 'wholesale', 'retail', 'cut', 'es_hcho',
  'hardness', 'density', 'gw', 'atp', 'marp', 'dry_marp', 'wet_marp',
  // 'inoxidability', 'wetfastness', 'agglutinate', 'mop', 'wap', 'etp', 'pc',
  'tech', 'weave', 'tan', 'surface', 'paint', 'mc'
];

const M_FRIENDLY = [
  'title', 'kingdom', 'species', 'quality', 'content', 'region', 'appearance', 'specs', 'uses'  // , 's_name', 's_mid', 'wholesale'
];

const M_REQUIRED = [
  'title', 'kingdom', 'clazz', 'family', 'species', 'quality',
  'content', 'region', 'appearance', 'specs'
  // 's_name', 's_mid', 'unit', 'wholesale'
];


const tableIcons = {
  Add: Add,
  Check: Check,
  Clear: Clear,
  Delete: DeleteOutline,
  DetailPanel: ChevronRight,
  Edit: Edit,
  Export: SaveAlt,
  Filter: FilterList,
  FirstPage: FirstPage,
  LastPage: LastPage,
  NextPage: ChevronRight,
  PreviousPage: ChevronLeft,
  ResetSearch: Clear,
  Search: Search,
  SortArrow: ArrowUpward,
  ThirdStateCheck: Remove,
  ViewColumn: ViewColumn,
  Sync: Sync,
  SlideShow: Slideshow
};


const Roles = [
  {k: 0, max: 0, min: 0, s: 'disabled'},
  {k: 1, max: 1, min: 1, s: 'audited'},
  {k: 10, max: 15, min: 10, s: 'member'},
  {k: 20, max: 25, min: 20, s: 'manager'},
  {k: 30, max: 100, min: 30, s: 'owner'}
];


const SellerRoles = {
  0: 'disabled',
  1: 'audited',
  10: 'member',
  11: 'assistant',
  15: 'buyer',
  20: 'director',
  28: 'manager',
  30: 'owner'
};


const CustomerRoles = {
  0: 'disabled',
  1: 'audited',
  10: 'member',
  11: 'assistant',
  15: 'buyer',
  20: 'designer',
  28: 'manager',
  30: 'owner'
};


const Currencies = [
  {k: 'CNY', v: 'CNY', s: '¥', r: 1.0, d: true},
  {k: 'EUR', v: 'EUR', s: '€', r: 8.0, d: false},
  {k: 'USD', v: 'USD', s: '$', r: 7.0, d: false}
];


const Qualities = [
  {k: '100', v: '100', d: false},
  {k: '200', v: '200', d: false},
  {k: '300', v: '300', d: true},
  {k: '400', v: '400', d: false}
];
const QualityLookup = {'100': '进口', '200': 'A+', '300': 'A', '400': 'B'};

const ProductModel = [
  {k: 'identifier', tk: 'identifier', t: 'text', r: false, d: ''},
  {k: 'item_type', tk: 'item_type', t: 'text', r: true, d: ''},
  {k: 'item_space', tk: 'item_space', t: 'text', r: true, d: ''},
  {k: 'title', tk: 'title', t: 'text', r: true, d: ''},
  {k: 'item_style', tk: 'item_style', t: 'text', r: true, d: ''},
  {k: 'desc', tk: 'desc', t: 'text', r: false, d: ''},
  {k: 'item_designer', tk: 'item_designer', t: 'text', r: false, d: ''},
  {k: 'brand', tk: 'brand', t: 'text', r: true, d: ''},
  {k: 'tags', tk: 'tags', t: 'text', r: false, d: ''},
  {k: 'state', tk: 'state', t: 'number', r: false, d: 1},
  {k: 'flow', tk: 'flow', t: 'text', r: false, d: ''}
];


const ItemModel = [
  {k: 'identifier', tk: 'identifier', t: 'text', r: false, o: true, d: ''},
  {k: 'material', tk: 'material', t: 'text', r: true, d: ''},
  // {k: 'material1', tk: 'material1', t: 'text', r: false, d: ''},
  // {k: 'material2', tk: 'material2', t: 'text', r: false, d: ''},
  {k: 'size', tk: 'size', t: 'text', r: true, d: ''},
  {k: 'quality', tk: 'quality', t: 'text', r: true, d: '300', select: Qualities},
  {k: 'currency', tk: 'currency', t: 'text', r: true, d: 'CNY', select: Currencies},
  {k: 'price1', tk: 'price1', t: 'number', r: true, d: ''},
  {k: 'price2', tk: 'price2', t: 'number', r: false, d: ''},
  {k: 'title', tk: 'title', t: 'text', r: false, o: true, d: ''},
  {k: 'desc', tk: 'desc', t: 'text', r: false, o: true, d: ''},
  {k: 'brand', tk: 'brand', t: 'text', r: false, d: ''},
  {k: 'model', tk: 'item_model', t: 'text', r: false, o: true, d: ''},
  {k: 'manufacturing_time', tk: 'manufacturing_time', t: 'text', r: false, o: true, d: ''},
  {k: 'state', tk: 'state', t: 'number', r: false, o: true, d: 1}
];


const FlowTexts = {
  'cs': 'common.specs', 'cm': 'common.manufactured', 'csp': 'common.shipping',
  'cmt': 'common.material', 'cmg': 'common.manufacturing', 'cp': 'common.pack',
  'crv': 'common.received', 'cas': 'common.afterSales','crt': 'common.rated',
  'c': 'common', 's': 'soft', 'm': 'metal', 'w': 'wood', 'r': 'rock',
  'sf': 'soft.frame', 'sc': 'soft.cotton', 'ss': 'soft.sew',
  'mf': 'metal.frame', 'ms': 'metal.surface',
  'wf': 'wood.frame', 'ws': 'wood.surface', 'wd': 'wood.decor',
  'rm': 'rock.material', 'rf': 'rock.frame'
};


function randomString(length) {
  var result           = '';
  var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for ( var i = 0; i < length; i++ ) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}


function priceDisplay(price, currency) {
  if (!DataManager.getInstance().permit('Price')) return '*';
  if (!price || price < 0) return '';
  if (price >= 0) price = `${price}`;
  let symbol = '';
  let i;
  for (i = 0; i < Currencies.length; ++i) {
    const c = Currencies[i];
    if (c.k === currency) {
      symbol = c.s;
      break;
    }
  }
  let display;
  if (price.length <= 3) {
    display = price;
  }else{
    const list = price.split('').reverse();
    const newList = [];
    i = 0;
    for (var char of list) {
      if (i > 0 && i % 3 === 0)
        newList.push(',');
      newList.push(char);
      i++;
    }
    display = newList.reverse().join('');
  }
  return `${symbol}${display}`;
}


function fromBits(bits, n) {
  const flags = [];
  let i;
  for (i = 0; i < n; ++i) {
    flags.push((bits >> i) & 1);
  }
  return flags;
}


function toBits(flags, n) {
  let bits = 0;
  let i;
  for (i = 0; i < n; ++i) {
    if (flags[i] === 1)
      bits |= (1 << i);
  }
  return bits;
}


export { Roles, SellerRoles, CustomerRoles, tableIcons, ProductModel, ItemModel, 
  QualityLookup, Qualities, Currencies, FlowTexts, randomString, priceDisplay, fromBits, toBits,
  M_KEYS, M_ITEMS, M_SEARCHABLE, M_VISIBLE, M_FRIENDLY, M_REQUIRED 
};