import React,  { Component } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';

import { Event, DataManager } from '../models/';
import { priceDisplay } from '../common/Constants';
import Image from '../common/Image';
import ProductDialog from '../Product/ProductDialog';

import T from 'i18n-react';
import AlertDialog from '../dialogs/AlertDialog';

import CircularProgress from '@material-ui/core/CircularProgress';

import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';


const styles = theme => ({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
    marginTop: theme.spacing(2),
    padding: theme.spacing(1, 0, 1, 0),
    marginBottom: 50
  },
  paper: {
    //width: '100%',
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  list: {
    width: '100%'
  },
  content: {
    fontSize: 24,
    margin: theme.spacing(2)
  },
  pagination: {
    marginTop: theme.spacing(5)
  },

  titleBlock: {
  },
  leading: {
    width: 54,
    minWidth: 54,
    maxWidth: 54,
    margin: theme.spacing(1)
  },
  id: {
    display: 'block',
    fontSize: 24,
    width: '100%'
  },
  label: {
    lineHeight: 2,
    fontSize: 14,
    marginLeft: theme.spacing(1)
  },
  importantInfo: {
    lineHeight: 2,
    fontSize: 18
  },
  cell: {
    backgroundColor: 'white',
    paddingLeft: 0,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },

  distributor: {
    width: 150,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'right',
    justifyContent: 'center'
  },
  slider: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'right'
  },
  sliderContent: {
    height: 32,
    textAlign: 'center',
    margin: theme.spacing(0, 1, 0.5, 1)
  },
  panel: {
    position: 'fixed',
    height: 64,
    bottom: 0,
    zIndex: 101,
    width: '100%',
    maxWidth: 'inherit',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  checkout: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  textBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
});


class Cart extends Component {
  state = {total: 1, page: 1, per: 0, fc: -1, cc: 0, selected: -1, filter: 0, requesting: false};
  products = null;

  constructor(props) {
    super(props);
    const search = props.location.search.substring(1).split('.');
    if (search.length > 1) {
      const p = search[0];
      const page = parseInt(p);
      if (page > 0) this.state.page = page;
      const f = search[1];
      const filter = parseInt(f);
      if (filter > 0) this.state.filter = filter;
    }
    this.state.requesting = true;
    this.list(this.state.page, this.state.filter);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.location.search !== nextProps.location.search) {
      const search = nextProps.location.search.substring(1).split('.');
      if (search.length > 1) {
        const p = search[0];
        const page = parseInt(p);
        if (page > 0) nextState.page = page;
        const f = search[1];
        const filter = parseInt(f);
        if (filter >= 0) nextState.filter = filter;
      }
      this.list(nextState.page, nextState.filter);
      return true;
    }
    return true;
  }

  change_quantity = (item, quantity, d) => {
    item.quantity = quantity;
    if (quantity <= 0) {
      this.products.splice(this.products.indexOf(item), 1);
    }
    this.setState({ cc: d.cc, order: d.order });
  };

  displayIndex = (index) => {
    const base = (this.state.page - 1) * this.state.per;
    const display = this.state.total - base - index;
    return display < 10 ? `0${display}` : display;
  };

  selected = (id, index) => {
    this.setState({selected: index});
  };

  deselect = () => {
    this.setState({selected: -1});
  };

  checkout = (e) => {
    DataManager.getInstance().pub(Event.REDIRECT, '/cart/checkout/');
  };

  handlePagination = (offset) => {
    const page = 1 + offset / this.state.per;
    DataManager.getInstance().pub(Event.REDIRECT, {pathname: this.props.location.pathname, search: `${page}.${this.state.filter}`});
  };

  handleFilter = (e) => {
    const f = parseInt(e.target.value);
    this.setState({filter: f, requesting: true});
    DataManager.getInstance().pub(Event.REDIRECT, {pathname: this.props.location.pathname, search: `1.${f}`});
  };

  closeAlert = (e) => {
    this.setState({selected: -1});
  };

  render() {
    if (!this.products)
      return <div></div>;

    //if (this.state.fc === 0)
    //  return <Typography className={classes.content}>{T.translate('products.empty_list')}</Typography>;

    const { classes } = this.props;

    return (
      <React.Fragment>
        <ProductDialog open={this.state.selected >= 0} onClose={this.deselect} product={this.state.selected >= 0 ? this.products[this.state.selected] : undefined} />
        <div className={classes.root}>
          {this.table()}
        </div>

        <AlertDialog
          open={false}
          onClose={this.closeAlert}
          title={T.translate('dialogs.no_offer')}
          desc={T.translate('dialogs.please_wait')}
          //left={T.translate('buttons.cancel')}
          right={T.translate('buttons.ok')}
        />
      </React.Fragment>
    );
  }

  table = () => {
    const { classes } = this.props;
    const products = this.products;
    const self = this;

    return (
      <Grid alignItems="center" justify="center" container>
        <Grid item xs={12} sm={8} md={8} lg={6} xl={6}>
          <React.Fragment>
            <Box style={{paddingBottom: this.state.expanded ? 130 : 0}}>
              <Paper className={classes.paper}>
                {this.state.requesting ?
                  <CircularProgress size={48} />
                  :
                  (products.length === 0 ?
                    <React.Fragment>
                      <Typography className={classes.content}>{T.translate('orders.empty_cart')}</Typography>
                      <Link className={classes.content} to="/products/">{T.translate('orders.go_shopping')}</Link>
                    </React.Fragment>
                    :
                    <List className={classes.list}>
                      {products.map((product, index) => (
                        self.item_cell(product, index, self.displayIndex(index), index !== products.length - 1)
                      ))}
                    </List>
                  )
                }
              </Paper>
            </Box>
          </React.Fragment>
        </Grid>
        {products.length > 0 &&
        <Paper className={classes.panel}>
          <Grid item xs={12} sm={8} md={8} lg={6} xl={6}>
            <Box className={classes.checkout}>
              <Box className={classes.textBox}>
                <Typography component="span" className={classes.label}>{T.translate('orders.total_count', {c: this.state.cc})}</Typography>
              </Box>

              <Box className={classes.textBox}>
                <Typography component="span" className={classes.label}>{T.translate('orders.subtotal')}: &nbsp;</Typography>
                <Typography component="span" className={classes.importantInfo}>{priceDisplay(this.state.order.price, 'CNY')}</Typography>
                <Box width={16}/>
                <Button variant="contained" color="primary" size="small" disabled={this.state.requesting} onClick={this.checkout}>
                  {T.translate('orders.publish')}
                </Button>
              </Box>
            </Box>
          </Grid>
        </Paper>
        }
      </Grid>
    );
  };

  item_cell(item, index, displayIndex, divider) {
    const { classes } = this.props;

    const self = this;
    return (
      <ListItem className={classes.cell} key={item.id} divider={divider} alignItems="center" >
        <Image
          style={{ height: 130, width: 130, maxWidth: 130, maxHeight: 130, minWidth: 130, minHeight: 130 }}
          alt={item.media[0]}
          src={item.media[0]}
          onClick={() => self.selected(item.id, index)}
        />
        <ListItemText
          primary={
            <Box className={classes.titleBlock}>
              <Box>
                <Typography component="span" className={classes.label}>{T.translate('orders.type')}: {item.item_type} / {item.item_space}</Typography>
              </Box>
              <Box>
                <Typography component="span" className={classes.label}>{T.translate('orders.material')}: {item.material}</Typography>
              </Box>
              <Box>
                <Typography component="span" className={classes.label}>{T.translate('orders.size')}: {item.size}</Typography>
              </Box>
              <Box style={{alignItems: 'center'}}>
                <Typography component="span" className={classes.label}>{T.translate('orders.price1')}: </Typography>
                <Typography component="span" className={classes.importantInfo}>{priceDisplay(item.price1, item.currency)}</Typography>
              </Box>
            </Box>
            }
        />
        <ListItemSecondaryAction>
          <QuantityButton
            classes={classes}
            item_id={item.item_id}
            product_id={item.product_id}
            view_id={item.id}
            title={item.title}
            quantity={item.quantity}
            onChange={(q, d) => {
              self.change_quantity(item, q, d);
            }}
          />
        </ListItemSecondaryAction>

      </ListItem>
    )
  }

  list = (page, f) => {
    const self = this;
    const params = {page: page, f: f};
    return DataManager.getInstance().show_cart(params).then(function (res) {
      if (res.c === 0) {
        let d = res.d;
        const products = d.ps;
        let i;
        for (i = 0; i < products.length; ++i) {
          const item = products[i];
          item.p1 = priceDisplay(item.price1, item.currency);
          item.p2 = priceDisplay(item.price2, item.currency);
        }
        self.products = products;
        self.setState({page: page, total: d.t, per: d.p, fc: d.fc, cc: res.d.cc, order: d.order, requesting: false});
      }
    })
  };
}


function QuantityButton(props) {
  const { classes } = props;

  const [quantity, setQuantity] = React.useState(props.quantity);
  const [open, setOpen] = React.useState(false);

  function handleClose(e) {
    setOpen(false);
    if (e.currentTarget.dataset.tag === '1') {
      updateCart(0, -1);
    }
  }

  const handleInc = event => {
    updateCart(1, 0);
    //setQuantity(quantity + 1);
  };

  const handleDec = event => {
    if (quantity <= 1) setOpen(true);
    else updateCart(-1, 0);
  };

  const updateCart = (diff, q) => {
    const params = {iid: props.item_id, pid: props.product_id, vid: props.view_id, d: diff, q: q};
    return DataManager.getInstance().update_cart(params).then(function (res) {
      if (res.c !== 0) {
        DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'error', m: T.translate('notification.failed')});
        return;
      }
      if (q === 0) {
        q = quantity + diff;
      }
      setQuantity(q);
      props.onChange(q, res.d);
      //DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'success', m: T.translate('notification.added')});
    });
  };

  return (
    <React.Fragment >
      <div className={classes.slider} >
        <IconButton className={classes.sliderContent} color="primary" size="small" onClick={handleDec} disabled={quantity === 0}>
          -
        </IconButton>
        <Typography color="primary">{quantity}</Typography>
        <IconButton className={classes.sliderContent} color="primary" size="small" onClick={handleInc} disabled={false}>
          +
        </IconButton>
      </div>
      <AlertDialog
        open={open}
        onClose={handleClose}
        title={T.translate('dialogs.delete_cart_item')}
        desc={T.translate('dialogs.confirm_delete_cart_item', {t: props.title ? `「${props.title}」` : T.translate('dialogs.cart_item') })}
        left={T.translate('buttons.cancel')}
        right={T.translate('buttons.yes')}
      />
    </React.Fragment>
  );
}


export default withStyles(styles)(Cart);