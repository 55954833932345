import React from 'react';
import Typography from '@material-ui/core/Typography';
import T from 'i18n-react';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';


function Filters(props) {
  const { labels, qs } = props;
  const style = {padding: 16, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'};
  const keys = ['kingdom', 'clazz', 'quality', 'region'];
  const def = {};
  keys.forEach((key) => def[key] = -1);

  const [selected, setSelected] = React.useState(def);
  React.useEffect(() => {
    if (!qs) return;
    const kingdom = selected.kingdom >= 0 ? labels.kingdom[selected.kingdom] : undefined;
    const kv = {};
    let updated = false;
    let i;
    for (i = 0; i < keys.length; ++i) {
      const label = keys[i];
      if (qs.hasOwnProperty(label)) {
        const v = qs[label];
        let lbs = labels[label];
        if (label !== 'kingdom')
          lbs = kingdom ? lbs[kingdom] : [];
        kv[label] = lbs.indexOf(v);
      }else{
        kv[label] = -1;
      }
      if (kv[label] !== selected[label])
        updated = true;
    }
    if (updated)
      setSelected(kv);
  }, [keys, labels, qs, selected]);

  const onChange = (e) => {
    const key = e.currentTarget.dataset.tag;
    const index = parseInt(e.target.value);
    const after = {...selected};
    let i;
    after[key] = index;
    if (key === 'kingdom') {
      for (i = 1; i < keys.length; ++i) {
        after[keys[i]] = -1;
      }
    }
    setSelected(after);
    const values = {kingdom: after.kingdom >= 0 ? labels.kingdom[after.kingdom] : ''};
    for (i = 1; i < keys.length; ++i) {
      const k = keys[i];
      values[k] = after[k] >= 0 && labels[k][values.kingdom] ? labels[k][values.kingdom][after[k]] : '';
    }
    props.onChange(values);
  };
  
  const selects = [];
  let index;
  for (index = 0; index < keys.length; ++index) {
    const label = keys[index];
    if (labels.hasOwnProperty(label)) {
      let values = labels[label];
      if (label !== 'kingdom') {
        const kdi = selected.kingdom;
        if (kdi >= 0) {
          const kd = labels.kingdom[kdi];
          values = values[kd];
        }else{
          values = undefined;
        }
      }
      // if (!values || values.length === 0) continue;
      const items = [];
      if (values) {
        let i;
        for (i = 0; i < values.length; ++i) {
          const item = 
          <MenuItem data-tag={label} key={i} value={i}><Typography align="center">{values[i]}</Typography></MenuItem>;
          items.push(item);
        }
      }
      const select = 
      <Select
        key={label}
        inputProps={{'data-tag': label}}
        value={selected[label]}
        onChange={onChange}
        disableUnderline={true}
        variant="standard"
      >
        <MenuItem data-tag={label} key={-1} value={-1}><Typography align="center" >{T.translate(`materials.${label}`)}</Typography></MenuItem>
        {items}
      </Select>;
      selects.push(select);
      selects.push(<div key={index} style={{width: 16}}></div>);
    }
  }

  return (
    <div style={style}>
      {selects}
    </div>
  );
}


export default Filters;