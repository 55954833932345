import React from 'react';
import { useTheme, makeStyles } from '@material-ui/core/styles';
//import Badge from '@material-ui/core/Badge';
import T from 'i18n-react';
import { Event, DataManager } from '../models/';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Badge from '@material-ui/core/Badge';
import Box from '@material-ui/core/Box';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
//import ListItemIcon from '@material-ui/core/ListItemIcon';
//import InboxIcon from '@material-ui/icons/MoveToInbox';
//import MailIcon from '@material-ui/icons/Mail';
import Fab from '@material-ui/core/Fab';
import MenuIcon from '@material-ui/icons/Menu';
import Hidden from '@material-ui/core/Hidden';


const useStyles = makeStyles(theme => ({
  fab: {
    marginLeft: theme.spacing(1)
  },
  drawer: {
    //width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    //width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  toolbar: theme.mixins.toolbar,
  border: {
    borderColor: theme.palette.primary.main
  },
  padding: {
    padding: theme.spacing(0, 1)
  }
}));


function Sidebar(props) {
  const { current, mc } = props;
  const theme = useTheme();
  const classes = useStyles(theme);
  const xs = useMediaQuery('(min-width:600px)');
  const [open, setOpen] = React.useState(xs);
  const urls = {tm: 'messages', tse: 'sellers', tsp: 'spaces'};

  let i;
  const t1 = ['tm', 'tse', 'tsp'];
  const tracks = [{title: T.translate('projects.track'), state: 'track', padding: 0}];
  for (i = 0; i < t1.length; ++i) {
    const key = t1[i];
    tracks.push({title: T.translate(`projects.by_${urls[key]}`), state: key, padding: 16});
  }

  const t2 = ['products', 'inquiries', 'orders']; //, 'info'
  // if (DataManager.debug) t2.push('privileges');
  const full = [];
  for (i = 0; i < t2.length; ++i) {
    const key = t2[i];
    full.push({title: T.translate(`projects.${key}`), state: key});
  }

  const handleChange = (e) => {
    setOpen(false);
    let f = e.currentTarget.dataset.tag;
    if (f === 'track') {
      const ci = t1.indexOf(current);
      f = t1[(ci + 1) % t1.length];
    }
    DataManager.getInstance().pub(Event.REDIRECT, `${props.base}/${f}`);
  };

  const handleClick = (e) => {
    setOpen(!open);
  };
  //<ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>

  const mck = {inquiries: 'i', orders: 'o', track: 'items', sellers: 'items', spaces: 'items'};

  const badged = (item) => {
    return <Badge className={classes.padding} color="primary" badgeContent={mc[mck[item.state]] || 0}>
      {item.title}
    </Badge>;
  };

  return (
    <React.Fragment>
      <Hidden smUp>
        <Fab className={classes.fab} color="primary" variant="round" size="small" aria-label="toggle" onClick={handleClick}>
          <MenuIcon />
        </Fab>
      </Hidden>

      <Drawer
        open={xs || open}
        onClose={handleClick}
        variant={xs ? "persistent" : "temporary"}
        className={classes.drawer}
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <Box className={classes.toolbar} />
        <Box height={16} />
        <List>
          {full.map((item, index) => (
            <ListItem button key={item.state} onClick={handleChange} data-tag={item.state}>
              {current === item.state ?
                <Box borderBottom={2} className={classes.border}>
                  <ListItemText primary={badged(item)} />
                </Box>
                :
                <ListItemText primary={badged(item)} />
              }
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          {tracks.map((item, index) => (
            <ListItem button key={item.state} onClick={handleChange} data-tag={item.state}>
              <div style={{width: item.padding}}></div>
              {current === item.state ?
                <Box borderBottom={2} className={classes.border}>
                  <ListItemText primary={item.title} />
                </Box>
                :
                (item.padding > 0 ?
                    <ListItemText secondary={item.title} />
                    :
                    <ListItemText primary={badged(item)} />
                )
              }
            </ListItem>
          ))}
        </List>
      </Drawer>
    </React.Fragment>
  );
}

export default Sidebar;
