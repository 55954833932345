import React,  { Component } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import T from 'i18n-react';
import Paper from '@material-ui/core/Paper';
import Image from '../common/Image';

const styles = theme => ({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
    marginTop: theme.spacing(2),
    padding: theme.spacing(0)
  },
  paper: {
    padding: theme.spacing(3),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  text: {
    margin: theme.spacing(2)
  }
});

class About extends Component {
  render() {
    const { classes } = this.props;

    const name = this.props.match.params.name === 'app' ? 'Casa' : 'ZhuChao';

    const app = T.translate(name);

    return (

      <Container component="main" className={classes.root} maxWidth="md">

        <Paper className={classes.paper}>
          <Typography className={classes.text} component="h6" color='secondary'>
            {T.translate('title.about_zhuchao', {app: app})}
          </Typography>
          <Image style={{width: 96, height: 96}} src="https://aifa.oss-cn-qingdao.aliyuncs.com/logo.png" alt="logo" />
          <Typography className={classes.text}>
            {app} (https://zhuchaox.com) 是一家软装产品交易服务商，致力于为软装产品的买家（室内设计师等）和卖家（家居厂商等）提供安全可靠的、成本最低的交易平台。
            {app}基于先进的AI技术，一方面为室内设计师提供快速、准确的产品报价，并提供优选的软装产品供应商和安全稳定的交易体验，对订单实行“先行赔付”保障；
            另一方面，为众多的中小家居厂商提供稳定增长的销售通道，助力诚实守信、品质可靠的厂商获得更多的发展机会。
            {app}，以利他主义为核心价值观、以人工智能为核心技术、以软装产品交易为核心业务，为家装行业的良性发展而努力。
          </Typography>
        </Paper>

      </Container>
    )
  }
}

export default withStyles(styles)(About);