/**
 * Created by milan on 2019/7/18.
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/styles/withStyles';
import T from 'i18n-react';

import StarIcon from '@material-ui/icons/Star';
import StarHalfIcon from '@material-ui/icons/StarHalf';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Link } from 'react-router-dom';


const styles = theme => ({
  board: {
    minWidth: 320,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: theme.spacing(2)
  },
  block: {
    width: '100%',
    maxWidth: '100%',
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  stats: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  value: {
    fontSize: 24
  }
});


class Seller extends Component {

  stars = (rank) => {
    if (!(rank > 0 && rank <= 5)) return T.translate('units.rank_none');
    const stars = [];
    let i;
    for (i = 1; i <= 5; ++i) {
      if (rank >= i) {
        stars.push(<StarIcon key={i} />);
      }else if (rank >= i - 0.5) {
        stars.push(<StarHalfIcon key={i} />);
      }else{
        break;
      }
    }
    return stars;
  };

  render() {
    const { unit, ext, classes } = this.props;
    return (
      <React.Fragment>
        <div className={classes.board}>
          {unit.id && unit.id > 0 ? 
          <Typography variant="h6" component={Link} to={`/seller/s/${unit.id}`}>
            {unit.display}
          </Typography>
          :
          <Typography variant="h6">
            {unit.display}
          </Typography>
          }
          <Typography variant="subtitle2">
            {unit.name}
          </Typography>
          {unit.stats ?
          <Box className={classes.block}>
            <Box className={classes.stats}>
              <Typography component="p" variant="caption" align="center">
                {T.translate('units.orders')}
              </Typography>
              <Typography component="p" align="center" className={classes.value}>
                {unit.stats.orders || '无'}
              </Typography>
            </Box>
            <Box className={classes.stats}>
              <Typography component="p" variant="caption" align="center">
                {T.translate('units.rate')}
              </Typography>
              <Typography component="p" align="center" className={classes.value}>
                {unit.stats.rate || '无'}
              </Typography>
            </Box>
            <Box className={classes.stats}>
              <Typography component="p" variant="caption" align="center">
                {T.translate('units.rank', {app: T.translate('appName')})}
              </Typography>
              <Typography component="p" align="center" className={classes.value}>
                {this.stars(unit.stats.rank) || '无'}
              </Typography>
            </Box>
          </Box>
          :
          <Box className={classes.block}/>
          }
          {ext &&
          <Box className={classes.block}>
            <Typography component="p" variant="caption" align="center">
              {ext}
            </Typography>
          </Box>
          }
        </div>
      </React.Fragment>
    );
  }
}


export default withRouter(withStyles(styles)(Seller));