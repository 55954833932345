import React,  { Component } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// import Paper from '@material-ui/core/Paper';
import { Event, DataManager } from '../models';
import MaterialCard from './MaterialCard';
import Filters from './Filters';

import T from 'i18n-react';
import Pagination from '../common/Pagination';

import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import ListSubheader from '@material-ui/core/ListSubheader';
// import IconButton from '@material-ui/core/IconButton';
// import InfoIcon from '@material-ui/icons/Info';


const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    // backgroundColor: 'transparent',
    // backgroundColor: theme.palette.background.paper,
  },
  tile: {
    marginBottom: theme.spacing(1),
    cursor: 'pointer'
  },
  image: {
    // cursor: 'pointer',
    minWidth: '100%',
    minHeight: '100%',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  img: {
    width: '100%',
    objectFit: 'cover',
    minWidth: '100%',
    minHeight: '100%',
    verticalAlign: 'middle'
  },
  badge3D: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    padding: 3,
    fontSize: 13,
    position: 'absolute',
    right: 0,
    top: 0
  },
  badgeNew: {
    backgroundColor: 'red',
    color: 'white',
    padding: 3,
    fontSize: 13,
    position: 'absolute',
    right: 0,
    top: 0
  },

  root: {
    flexGrow: 1,
    overflow: 'hidden',
    marginTop: theme.spacing(2),
    padding: theme.spacing(1)
  },
  content: {
    fontSize: 24,
    margin: theme.spacing(2)
  },
  pagination: {
    marginTop: theme.spacing(5)
  }
});


class Discover extends Component {
  state = {total: 1, page: 1, per: 0, selected: -1, filter: 2, params: {}, requesting: false};
  materials = null;
  labels = null;
  pid = 0;
  wanted = false;

  constructor(props) {
    super(props);
    if (this.props.match.params.pid > 0)
      this.pid = this.props.match.params.pid;
    this.wanted = this.props.location.pathname.split('/')[1] === 'wanted';

    const search = props.location.search.substring(1);
    if (search) {
      const params = search.split('&').reduce(function(prev, curr, i, arr) {
        var p = curr.split('=');
        prev[p[0]] = decodeURIComponent(p[1]);
        return prev;
      }, {});
      this.state.params = params;
      const page = parseInt(params.p);
      if (page > 0) this.state.page = page;
      const filter = parseInt(params.f);
      if (filter >= 0) this.state.filter = filter;
    }
    this.state.requesting = true;
    this.list(this.state.page, this.state.filter, this.state.params);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.location.search !== nextProps.location.search) {
      const search = nextProps.location.search.substring(1);
      if (search) {
        const params = search.split('&').reduce(function(prev, curr, i, arr) {
          var p = curr.split('=');
          prev[p[0]] = decodeURIComponent(p[1]);
          return prev;
        }, {});
        nextState.params = params;
        const page = parseInt(params.p);
        if (page > 0) nextState.page = page;
        const filter = parseInt(params.f);
        if (filter >= 0) nextState.filter = filter;
      }else{
        nextState.params = {};
        nextState.page = 1;
        nextState.filter = 2;
      }
      this.list(nextState.page, nextState.filter, nextState.params);
      return true;
    }
    return true;
  }

  handlePagination = (offset) => {
    const page = 1 + offset / this.state.per;
    const params = {...this.state.params};
    params.p = page;
    params.f = this.state.filter;
    const qs = Object.keys(params).map((key) => {
      return key + '=' + encodeURIComponent(params[key])
    }).join('&');
    DataManager.getInstance().pub(Event.REDIRECT, {pathname: this.props.location.pathname, search: qs});
    // DataManager.getInstance().pub(Event.REDIRECT, {pathname: this.props.location.pathname, search: `${page}.${this.state.filter}`});
  };

  handleFilter = (e) => {
    const f = parseInt(e.target.value);
    // this.setState({filter: f, requesting: true});
    const params = {...this.state.params};
    params.p = 1;
    params.f = f;
    const qs = Object.keys(params).map((key) => {
      return key + '=' + encodeURIComponent(params[key])
    }).join('&');
    DataManager.getInstance().pub(Event.REDIRECT, {pathname: this.props.location.pathname, search: qs});
  };

  handleFilters = (params) => {
    params.p = this.state.page;
    params.f = this.state.filter;
    const qs = Object.keys(params).map((key) => {
      return key + '=' + encodeURIComponent(params[key])
    }).join('&');
    DataManager.getInstance().pub(Event.REDIRECT, {pathname: this.props.location.pathname, search: qs});
  };

  handleClick = (e) => {
    const index = parseInt(e.currentTarget.dataset.tag);
    this.show(index);
  };

  render() {
    if (!this.materials)
      return <div></div>;

    const { classes } = this.props;
    const open = this.state.open;
    const self = this;

    return (
      <React.Fragment>
        <div className={classes.root}>
          {this.table()}
        </div>
        {open >= 0 &&
        <MaterialCard card={false} open={open >= 0} onClose={() => self.setState({open: -1}) } material={this.materials[open].material} />
        }
      </React.Fragment>
    );
  }

  table = () => {
    const { classes } = this.props;
    const materials = this.materials;

    return (
      <Grid alignItems="center" justify="center" container>
        <Grid item xs={12} sm={8} md={8} lg={6} xl={6}>
          <React.Fragment>
            <Filters labels={this.labels} qs={this.state.params} onChange={this.handleFilters} />
          </React.Fragment>
          <div className={classes.container}>
            {materials.length === 0 ?
              <Typography className={classes.content}>{T.translate('materials.empty_list')}</Typography>
              :
              <GridList cols={2} cellHeight={250} spacing={12} className={classes.gridList}>
                <GridListTile key="Subheader" cols={2} style={{ height: 'auto' }}>
                  <ListSubheader component="div"></ListSubheader>
                </GridListTile>
                {materials.map((mat, index) => (
                  <GridListTile key={`${mat.id}.${index}`} data-tag={index} className={classes.tile} onClick={this.handleClick}>
                    <div className={classes.image}>
                      <img className={classes.img} src={mat.media[0]} alt={mat.title} />
                    </div>
                    <GridListTileBar
                      // onClick={(e) => {e.stopPropagation();}}
                      title={mat.title}
                      subtitle={<span>{mat.clazz} / {mat.family} / {mat.species}</span>}
                      // actionIcon={undefined}
                    />
                  </GridListTile>
                ))}
              </GridList>
            }
          </div>
          
          {!this.state.requesting && this.state.per > 0 && this.state.total > this.state.per &&
          <Pagination
            offset={this.state.per * (this.state.page - 1)}
            limit={this.state.per}
            total={this.state.total}
            onClick={this.handlePagination}
          />
          }
        </Grid>
      </Grid>
    );
  };

  show = (index) => {
    const mat = this.materials[index];
    if (mat.material) {
      this.setState({open: index});
      return;
    }
    const self = this;
    DataManager.getInstance().show_material_by_code(mat.identifier, null).then(function (res) {
      if (res.c === 0) {
        mat.material = res.d.mat;
        self.setState({open: index});
      }
    });
  };

  list = (page, f, qs) => {
    const self = this;
    if (!this.state.requesting) this.setState({requesting: true});
    const params = {...qs, page: page, f: f, l: !!this.labels ? 0 : 1};
    const callback = function (res) {
      if (res.c === 0) {
        let d = res.d;
        self.materials = d.mats;
        if (d.labels) self.labels = d.labels;
        self.setState({page: page, total: d.t, per: d.p, requesting: false});
      }
    };
    
    return DataManager.getInstance().discover_materials(params).then(callback);
  };
}


export default withStyles(styles)(Discover);