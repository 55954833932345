/**
 * Created by milan on 2019/7/18.
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/styles/withStyles';
import { TextField } from 'formik-material-ui';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import { Formik, Form, Field } from 'formik';
import T from 'i18n-react';
import { Event, DataManager } from '../models/';
import Uploader from './Upload';

import { ProductModel, ItemModel } from '../common/Constants';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Menu from '@material-ui/core/Menu';

import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';

import FlowDisplay from '../common/FlowDisplay';
import FlagsEditor from '../common/FlagsEditor';
import AlertDialog from '../dialogs/AlertDialog';


const styles = theme => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2)
  },
  form: {
    //paddingTop: theme.spacing(4),
    width: '100%' // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(1, 0, 0)
  },
  show: {
  },
  hide: {
    display: 'none'
  },
  menu: {
    width: 200
  },
  center: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
});

class Editor extends Component {
  Model = null;
  state = {optional: false, alert: false};

  constructor(props) {
    super(props);
    this.Model = props.index < 0 ? ProductModel : ItemModel;
  }

  handleSwitch = () => {
    this.setState({optional: !this.state.optional});
  };

  validate = values => {
    let errors = {};
    this.Model.forEach(function (property, _) {
      const key = property.k;
      if (property.r && !values[key]) {
        errors[key] = T.translate('errors.required');
      }
    });
    if (Object.keys(errors).length) {
      return errors;
    }
  };

  submit = (values, actions) => {
    const product = this.props.product;
    //let i;
    let err = null;
    if (product.media.length === 0)
      err = T.translate('errors.products.no_image');
    if (err) {
      DataManager.log(err);
      DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'error', m: err});
      actions.setSubmitting(false);
      return;
    }
    const pro = {};
    ProductModel.forEach(function (property, _) {
      const key = property.k;
      pro[key] = property.t === 'text' ? values[key].trim() : values[key];
    });
    pro.media = product.media.join(';');
    pro.priority = values.priority;

    return this.save_product(pro);
  };

  save_product = (product) => {
    const pid = this.props.product.id;
    return DataManager.getInstance().save_product(pid, product).then(function (res) {
      if (res.c !== 0) {
        return;
      }
      DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'success', m: T.translate('notification.success')});
      if (!(pid > 0)) {
        DataManager.getInstance().pub(Event.REDIRECT, `/products/e/${res.d.id}`);
      }
    });
  };

  delete_product = (e) => {
    this.setState({alert: false});
    if (e.currentTarget.dataset.tag !== '1') {
      return;
    }
    const pid = this.props.product.id;
    if (!(pid > 0)) return;
    const self = this;
    self.setState({isLoading: true});
    return DataManager.getInstance().delete_product(pid, null).then(function (res) {
      if (res.c !== 0) {
        return;
      }
      DataManager.getInstance().pub(Event.REDIRECT, '/products/');
      DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'success', m: T.translate('notification.success')});
      return res;
    })
  };

  render() {
    const { classes, product } = this.props;
    const initialValues = {};
    this.Model.forEach(function (property, _) {
      initialValues[property.k] = product[property.k] || property.d;
    });
    initialValues.priority = product.priority;
    const labels = DataManager.getInstance().storage.labels;

    const self = this;
    const showProduct = this.props.index < 0;
    const title = showProduct ? T.translate('products.product') : `${T.translate(`products.item`)}${this.props.index + 1}`;

    return (
      <React.Fragment>
        <AlertDialog
          open={this.state.alert}
          onClose={this.delete_product}
          title={T.translate('dialogs.alert')}
          desc={T.translate('dialogs.confirm_delete_product')}
          left={T.translate('buttons.cancel')}
          right={T.translate('buttons.yes')}
        />
        <Uploader product={product} search={this.props.search} />
        
        <Container component="main" maxWidth="xs" className={classes.root}>
          <Paper className={classes.paper}>

            <Grid container justify="center">
              <Grid item xs={4} sm={4} md={2} className={classes.center}>
                <Switch
                  checked={this.state.optional}
                  onChange={this.handleSwitch}
                  value="switcher"
                  color="primary"
                />
              </Grid>
              <Grid item xs={4} sm={4} md={8} className={classes.center}>
                <Typography align="center" variant="h6">{title}</Typography>
              </Grid>
              <Grid item xs={4} sm={4} md={2} className={classes.center}>
                {product.id > 0 &&
                <IconButton color="secondary" align="center" size="small" onClick={e => self.setState({alert: true})}>
                  <DeleteIcon />
                </IconButton>
                }
              </Grid>
            </Grid>

            <Formik
              initialValues={initialValues}
              validate={this.validate}
              //validateOnBlur={true}
              onSubmit={this.submit}
            >
              {props => {
                return (
                  <Form className={classes.form} noValidate>
                    <FlagsEditor 
                      choices={['customizable', 'ec', 'standard']} 
                      current={product.priority}
                      onChange={(flags) => {
                        props.setFieldValue('priority', flags);
                      }} 
                    />
                    {self.Model.map((property, index) => {
                      if (property.k === 'flow')
                        return !self.state.optional ? undefined :
                        <FormControl
                          key={index}
                          error={!!props.errors[property.k]}
                          required={property.r}
                          className={!property.r && !self.state.optional ? classes.hide : classes.show}
                          variant="standard"
                          margin="dense"
                          fullWidth
                        >
                          <InputLabel htmlFor={property.k} shrink={true}>
                            {T.translate('products.flow')}
                          </InputLabel>
                          <div
                            style={{paddingTop: 20}}
                            id={property.k}
                          >
                            <FlowDisplay key={index} flow={product.flow} onChange={(value) => {
                              props.setFieldValue(property.k, value);
                            }} />
                          </div>
                        </FormControl>;
                      else if (property.select)
                        return <FormControl
                          key={index}
                          error={!!props.errors[property.k]}
                          required={property.r}
                          className={!property.r && !self.state.optional ? classes.hide : classes.show}
                          variant="standard"
                          margin="dense"
                          fullWidth
                        >
                          <InputLabel htmlFor={property.k} >
                            {T.translate(`products.${property.tk}`)}
                          </InputLabel>
                          <Select
                            value={props.values[property.k]}
                            onChange={(e) => {
                              props.setFieldValue(property.k, e.target.value);
                            }}
                            type={property.t}
                            name={property.k}
                            autoComplete={property.k}
                            inputProps={{
                              name: property.k,
                              id: property.k
                            }}
                          >
                            {property.select.map(option => (
                              <MenuItem key={option.k} value={option.v}>
                                {T.translate(`${property.k}.${option.k}`)}
                              </MenuItem>
                            ))}
                          </Select>
                          {!!props.errors[property.k] && <FormHelperText>{props.errors[property.k]}</FormHelperText>}
                        </FormControl>;
                      else
                        return <Field
                          component={TextField}
                          key={index}
                          error={!!props.errors[property.k]}
                          helperText={props.errors[property.k] || ''}
                          variant="standard"
                          margin="dense"
                          fullWidth
                          required={property.r}
                          id={property.k}
                          name={property.k}
                          autoComplete={property.k}
                          type={property.t}
                          className={!property.r && !self.state.optional ? classes.hide : classes.show}
                          label={T.translate(`products.${property.tk}`)}
                          InputProps={
                            !labels[property.k] ? null : {endAdornment: 
                              <InputAdornment position="end">
                                <DropdownButton k={property.k} onChange={(k, v) => {
                                  props.setFieldValue(k, v);
                                }} />
                              </InputAdornment>
                            }
                          }
                        />;
                    })}

                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.btn}
                      disabled={props.isSubmitting}
                    >
                      {T.translate(`products.submit`)}
                    </Button>
                  </Form>
                );
              }}
            </Formik>
          </Paper>
        </Container>
      </React.Fragment>
    )
  }
}


function DropdownButton(props) {
  const { k } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = e => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = e => {
    setAnchorEl(null);
  };

  const options = DataManager.getInstance().storage.labels[k];

  const handleChange = e => {
    const index = parseInt(e.target.value);
    props.onChange(k, options[index]);
    setAnchorEl(null);
  };


  const id = `action-menu-${props.index}`;
  return (
    <React.Fragment>
      <IconButton style={{padding: 0}} aria-controls={id} aria-haspopup="true" color="primary" aria-label={props.index} data-tag={props.index} onClick={handleClick}>
        <ExpandMoreIcon />
      </IconButton>
      <Menu
        id={id}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options.map((option, index) => {
            return <MenuItem key={index} value={index} onClick={handleChange}>{option}</MenuItem>
          })}
      </Menu>
    </React.Fragment>
  );
}


export default withRouter(withStyles(styles)(Editor));