/**
 * Created by milan on 2019/7/18.
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/styles/withStyles';
import Button from '@material-ui/core/Button';
import { TextField } from 'formik-material-ui';
import Container from '@material-ui/core/Container';
import { Formik, Form, Field } from 'formik';
import T from 'i18n-react';
import Typography from '@material-ui/core/Typography';


const styles = theme => ({
  container: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2)
  },
  form: {
    minWidth: 360,
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
});

const validate = values => {
  let errors = {};
  //if (!values.phone) {
  //  errors.phone = T.translate('errors.required');
  //} else if (!/^[1]([3-9])[0-9]{9}$/.test(values.phone)) {
  //  errors.phone = T.translate('errors.invalid_phone');
  //}
  //if (!values.name) {
  //  errors.name = T.translate('errors.required');
  //}
  if (!values.address) {
    errors.address = T.translate('errors.required');
  }
  if (Object.keys(errors).length)
    return errors;
};

class Shipping extends Component {
  action = (values, actions) => {
    this.props.onSave(values.address);
  };

  render() {
    const { classes } = this.props;
    const current = this.props.shipping || '';
    return (
      <React.Fragment>
        <Container component="main" maxWidth="sm" className={classes.container}>
          <Typography component="h1" variant="h5">
            {T.translate("deals.shipping_info")}
          </Typography>
          <Formik
            initialValues={{ address: current || '' }}
            //initialValues={{ name: current.name || '', phone: current.phone || '', address: current.address || '' }}
            validate={validate}
            validateOnChange={true}
            onSubmit={this.action}
          >
            {props => {
              return (
                <Form className={classes.form} noValidate>
                  <Field
                    component={TextField}
                    error={!!props.errors.address}
                    helperText={props.errors.address}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="address"
                    label={T.translate("orders.shipping_info.address")}
                    name="address"
                    autoComplete="shipping_address"
                  />
                  <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                    <Button
                      type="button"
                      variant="contained"
                      color="secondary"
                      className={classes.submit}
                      disabled={props.isSubmitting}
                      onClick={() => this.props.onSave(null)}
                    >
                      {T.translate("buttons.cancel")}
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      disabled={props.isSubmitting}
                    >
                      {T.translate("buttons.save")}
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Container>

      </React.Fragment>
    );
  }
}

export default withRouter(withStyles(styles)(Shipping));