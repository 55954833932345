/**
 * Created by milan on 2019/7/18.
 */
import React, { Component } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import Button from '@material-ui/core/Button';
import { TextField } from 'formik-material-ui';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { Formik, Form, Field } from 'formik';
import T from 'i18n-react';
import { Event, DataManager } from '../models';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';


const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white
    }
  },
  paper: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2)
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
});

const validate = values => {
  const errors = {};
  if (!values.due) {
    errors.due = T.translate('errors.required');
  }
  if (Object.keys(errors).length)
    return errors;
};

class Plans extends Component {
  
  action = (values, actions) => {
    return DataManager.getInstance().update_plan(this.props.unit.id, values).then(function (res) {
      const errors = {};
      if (res.c !== 0) {
        errors.due = T.translate('errors.' + res.c);
        actions.setSubmitting(false);
        if (Object.keys(errors).length) {
          actions.setErrors(errors);
        }
        return;
      }
      DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'success', m: T.translate('notification.success')});
    });
  };

  render() {
    const { classes, unit } = this.props;

    const keys = [0, 100, 200, 300];
    const plans = {0: {t: 'blue', c: '#6495ed'}, 100: {t: 'silver', c: '#C0C0C0'},
                  200: {t: 'gold', c: '#FFD700'}, 300: {t: 'platinum', c: '#e5e4e2'}};
    return (
      <React.Fragment>
        <Container component="main" maxWidth="xs">
          <Paper className={classes.paper}>
            <Formik
              initialValues={{ plan: unit.plan, due: unit.due }}
              validate={validate}
              // validateOnChange={true}
              onSubmit={this.action}
            >
              {props => {
                return (
                  <Form className={classes.form} noValidate>
                    <FormControl
                      error={!!props.errors.plan}
                      required={true}
                      margin="normal"
                      fullWidth
                    >
                      <InputLabel htmlFor="plan" shrink={true}>
                        {T.translate('units.plan_due')}
                      </InputLabel>
                      <Select
                        value={props.values.plan}
                        onChange={(e) => {
                          props.setFieldValue('plan', e.target.value);
                        }}
                        name="plan"
                        inputProps={{
                          name: 'plan',
                        }}
                      >
                        {keys.map(key => (
                          <MenuItem key={key} value={key}>
                            {T.translate(`units.plan_${plans[key].t}`)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <Field
                      component={TextField}
                      error={!!props.errors.due}
                      helperText={props.errors.due}
                      margin="normal"
                      type="date"
                      fullWidth
                      label={T.translate("units.plan_due")}
                      name="due"
                      InputLabelProps={{shrink: true}}
                    />

                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      disabled={props.isSubmitting}
                    >
                      {T.translate("buttons.submit")}
                    </Button>
                  </Form>
                );
              }}
            </Formik>
          </Paper>
        </Container>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(Plans);