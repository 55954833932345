/**
 * Created by milan on 2019/7/18.
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/styles/withStyles';
import Button from '@material-ui/core/Button';
import { TextField } from 'formik-material-ui';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { Formik, Form, Field } from 'formik';
import T from 'i18n-react';
import { Event, DataManager } from '../models/';
import Uploader from '../Inquiry/Upload';
import AlertDialog from '../dialogs/AlertDialog';
import { FlowTexts } from '../common/Constants';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import LinearProgress from '@material-ui/core/LinearProgress';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import IconButton from '@material-ui/core/IconButton';
import BackIcon from '@material-ui/icons/ArrowBack';
//import DeleteIcon from '@material-ui/icons/DeleteForever';


const styles = theme => ({
  buttons: {
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  root: {
    padding: theme.spacing(1)
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2)
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    //paddingTop: theme.spacing(4),
    width: '100%' // Fix IE 11 issue.
  },
  formControl: {
    marginBottom: 48
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  deletion: {
    color: theme.palette.danger.main
  }
});

class Edit extends Component {
  item = null;
  tracker = null;
  id = 0;
  timer = null;
  file_ref = null;

  constructor(props) {
    super(props);
    this.state = {
      files: [],
      show: false,
      progress: -1,
      confirmDeletion: 0
    };
    this.order_id = props.match.params.order_id;
    this.item_id = props.match.params.item_id;
    let id = props.match.params.tracker_id;
    if (id) this.id = parseInt(id);
    this.show();
  }

  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  go_back = (e) => {
    const pid = this.props.match.params.id;
    if (pid > 0) {
      DataManager.getInstance().pub(Event.REDIRECT, `/projects/s/${pid}/${this.props.match.params.track}/trackers/${this.order_id}/${this.item_id}`);
    }else{
      DataManager.getInstance().pub(Event.REDIRECT, `/orders/trackers/${this.order_id}/${this.item_id}`);
    }
  };

  onProgress = (e) => {
    let p = e.loaded / e.total;
    let self = this;
    if (p < 1) {
      p *= 90;
      self.setState({progress: p});
    }else{
      self.setState({progress: 90});
      this.timer = setInterval(function () {
        let p = self.state.progress * 1.005;
        self.setState({progress: p});
      }, 1000);
    }
    //const diff = Math.random() * 10;
    //return Math.min(this.state.progress + diff, 100);
  };

  delete_tracker = () => {
    const self = this;
    DataManager.getInstance().delete_tracker(this.item_id, this.id, null).then(function (res) {
      if (res.c !== 0) {
        //DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'error', m: T.translate('errors.' + res.c)});
        return;
      }
      DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'success', m: T.translate('notification.deleted')});
      self.go_back();
    });
  };

  show = () => {
    let self = this;
    DataManager.getInstance().show_tracker(this.item_id, this.id, null).then(function (res) {
      if (res.c === 0) {
        self.item = res.d.item;
        const allowed = res.d.options;
        //const TrackerState = DataManager.getInstance().states.trackers;
        const types = [];
        let k;
        for (k of allowed) {
          types.push({v: `${k}`, l: T.translate(`products.${FlowTexts[k]}`)});
        }
        self.types = types;
        if (res.d.tracker) {
          self.tracker = res.d.tracker;
        }
        self.setState({show: true});
      }
    });
  };

  update_tracker = (values, actions) => {
    //console.log(values, 'not now');
    //if (true) return values;
    values.file = this.state.files;
    values.media = '';
    if (this.tracker && this.tracker.media && this.tracker.media.length > 0)
      values.media = this.tracker.media.join(';');
    let self = this;
    values.key = values.stage;
    const params = {...values};
    delete params.stage;
    return DataManager.getInstance().update_tracker(this.item_id, this.id, params, this.onProgress).then(function (res) {
      let errors = {};
      if (res.c !== 0) {
        errors.file = T.translate('errors.' + res.c);
        actions.setSubmitting(false);
        self.setState({progress: -1});
        if (self.timer) {
          clearInterval(self.timer);
        }
        if (Object.keys(errors).length) {
          actions.setErrors(errors);
          //actions.setStatus(errors);
        }
        return;
      }
      DataManager.getInstance().pub(Event.REDIRECT, `/orders/trackers/${self.order_id}/${self.item_id}`);
      //DataManager.getInstance().pub(Event.REDIRECT, '__go_back__');
    });
  };

  validate = values => {
    let errors = {};
    const cl = this.tracker && this.tracker.media ? this.tracker.media.length : 0;
    if (!(cl + this.state.files.length > 0)) {
      errors.file = T.translate('errors.required');
    }
    //if (!(this.id > 0) && !(this.state.files.length > 0)) {
    //  errors.file = T.translate('errors.required');
    //}
    if (!values.stage || values.stage === '0') {
      errors.stage = T.translate('errors.required');
    }
    if (Object.keys(errors).length)
      return errors;
  };

  file_selected = (fs) => {
    const files = this.state.files.slice();
    const update = {};
    for (var file of fs) {
      if (files.indexOf(file) < 0)
        files.splice(0, 0, file);
    }
    update.files = files;
    this.setState(update);
  };

  triggerAdd = (e) => {
    this.file_ref.focus();
    this.file_ref.click();
  };

  triggerDelete = (f) => {
    if (f instanceof File) {
      this.file_ref.value = '';
      const index = this.state.files.indexOf(f);
      if (index >= 0) {
        const fs = this.state.files.slice();
        fs.splice(index, 1);
        this.setState({files: fs});
      }
    }else if (this.tracker){
      const index = this.tracker.media.indexOf(f);
      if (index >= 0) {
        this.tracker.media.splice(index, 1);
        this.setState({files: this.state.files});
      }
    }
  };

  showAlert = (e) => {
    this.setState({confirmDeletion: 1});
  };

  closeAlert = (e) => {
    if (e.currentTarget.dataset.tag === '1') {
      this.delete_tracker();
    }
    this.setState({confirmDeletion: 0});
  };

  render() {

    //{this.id > 0 && this.tracker && this.tracker.state < 10 && !this.tracker.key &&
    //<IconButton aria-label="delete" color="inherit" onClick={this.showAlert}>
    //  <DeleteIcon style={{width: 32, height: 32}} />
    //</IconButton>
    //}
    if (!this.state.show) return <div></div>;
    const { classes } = this.props;
    let id = this.id;
    //const is_customer = DataManager.getInstance().mode < Mode.BUSINESS;
    let self = this;
    const initialValues = this.tracker ?
    {id: id || -1, memo: this.tracker.sender_memo || '', file: '', stage: this.tracker.key ? '' + this.tracker.key : '' } :
    {id: id || -1, memo: '', file: '', stage: '0'};
    if (this.types.length === 1) initialValues.stage = this.types[0].v;

    const fs = this.tracker ? this.state.files.concat(this.tracker.media) : this.state.files;
    return (
      <div>
        <AlertDialog
          open={this.state.confirmDeletion === 1}
          onClose={this.closeAlert}
          title={T.translate('dialogs.alert')}
          desc={T.translate('dialogs.confirm_delete_tracker')}
          left={T.translate('buttons.cancel')}
          right={T.translate('buttons.delete')}
        />

        <Container component="main" maxWidth="xs" className={classes.buttons}>
          <IconButton aria-label="close" color="inherit" onClick={this.go_back}>
            <BackIcon style={{width: 32, height: 32}} />
          </IconButton>
        </Container>
        <Container component="main" maxWidth="xs" className={classes.root}>
          <Paper className={classes.paper}>
            <Formik
              initialValues={initialValues}
              validate={this.validate}
              //validateOnChange={false}
              validateOnBlur={true}
              onSubmit={this.update_tracker}
            >
              {props => {
                return (
                  <Form className={classes.form} noValidate>
                    <Field
                      hidden
                      value={id}
                      required
                      id="id"
                      name="id"
                    />

                    <FormControl
                      error={!!props.errors.stage}
                      required
                      fullWidth
                      className={classes.formControl}
                      style={{padding: 12, paddingBottom: 0}}
                    >
                      <InputLabel
                        htmlFor="stage"
                        shrink={true}
                        style={{left: 12}}
                      >
                        {T.translate("trackers.stage")}
                      </InputLabel>
                      <RadioGroup
                        name="stage"
                        id="stage"
                        value={props.values.stage}
                        style={{marginTop: 16}}
                        row
                        onChange={(e) => {
                          props.setFieldValue('stage', e.target.value);
                        }}
                      >
                        {this.types.map((t, index) => (
                          <FormControlLabel
                            key={index}
                            value={t.v}
                            disabled={self.id > 0}
                            control={<Radio color="primary" />}
                            labelPlacement="bottom"
                            style={{marginLeft: 0}}
                            label={<Typography style={{fontSize: 14}}>{T.translate(t.l)}</Typography>}
                          />
                        ))}
                      </RadioGroup>
                      {!!props.errors.stage && <FormHelperText>{props.errors.stage}</FormHelperText>}
                    </FormControl>

                    <input
                      ref={fileInput => self.file_ref = fileInput}
                      style={{display: 'none'}}
                      accept="image/*"
                      type="file"
                      name="file"
                      multiple="multiple"
                      onChange={(e) => {
                        self.file_selected(e.currentTarget.files);
                      }}
                    />
                    <label htmlFor="file">
                      <FormControl
                        error={!!props.errors.file}
                        margin="normal"
                        required={!(self.id > 0)}
                        fullWidth
                        style={{margin: 0, paddingTop: 30}}
                      >
                        <InputLabel
                          htmlFor="upload-display"
                          style={{paddingLeft: 16}}
                          shrink={true} >
                          {T.translate("trackers.upload")}
                        </InputLabel>
                        <Uploader files={fs} onAdd={self.triggerAdd} onDelete={self.triggerDelete} />
                        <FormHelperText id="upload-display-text">{props.errors.file}</FormHelperText>
                      </FormControl>
                    </label>

                    <Field
                      component={TextField}
                      error={!!props.errors.memo}
                      helperText={props.errors.memo}
                      multiline={true}
                      inputProps={{
                        maxLength: 256
                      }}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="memo"
                      label={T.translate("orders.memo")}
                      name="memo"
                      autoComplete="memo"
                      className={classes.formControl}
                    />

                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      disabled={props.isSubmitting}
                    >
                      {T.translate("trackers.submit")}
                    </Button>
                    {self.state.progress >= 0 &&
                    <LinearProgress variant="determinate" value={self.state.progress} />
                    }
                  </Form>
                );
              }}
            </Formik>
          </Paper>
        </Container>
      </div>
    )
  }
}


export default withRouter(withStyles(styles)(Edit));