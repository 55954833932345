/**
 * Created by milan on 2019/7/18.
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/styles/withStyles';
import Grid from '@material-ui/core/Grid';
// import T from 'i18n-react';
import { Switch, Route } from 'react-router-dom';
import { DataManager, Permit } from '../models/';

import Info from './Info';
import Track from './Track';
import ShowTrackers from '../Order/ItemTrackers';
import EditTracker from '../Order/Tracker';

import Products from './Products';
// import Products from '../Product/List';
import ShowProduct from '../Product/ShowProduct';
import Inquiries from './Inquiries';
import Orders from './Orders';
import ShowDeal from '../Deal/Show';
import ShowOrder from '../Order/Show';
import Privilege from './Privilege';

import Sidebar from './Sidebar';


const styles = theme => ({
  root: {
  }
});


class Show extends Component {
  id = 0;
  project = null;
  state = {total: 1, page: 1, per: 0, show: false, requesting: true, stats: {}};

  constructor(props) {
    super(props);
    const id = props.match.params.id;
    if (id) this.id = parseInt(id);

    const storage = DataManager.getInstance().storage;
    if (storage.mc && storage.mc.projects) {
      this.state.stats = storage.mc.projects;
    }

    this.show(this.state);
  }

  show = (state) => {
    let self = this;
    DataManager.getInstance().show_project(this.id, null).then(function (res) {
      if (res.c === 0) {
        self.project = res.d.project;
        self.setState({requesting: false});
      }
    });
  };

  render() {
    if (this.state.requesting) return <div></div>;
    const path = '/' + this.props.match.path.split('/')[1];
    const self = this;

    return (
      <React.Fragment>
        <Grid alignItems="center" justify="center" container>
          <Grid item xs={12} sm={false}>
            <Sidebar base={`${path}/s/${this.id}`} current={this.props.match.params.type || 'items'} mc={this.project.mc} />
          </Grid>
          <Grid item xs={12} container alignItems="center" justify="center" style={{marginBottom: 8}}>
            <Info project={self.project} />
          </Grid>
          <Switch>
            <Route path={`${path}/s/:pid`} exact render={ (props) => <Inquiries {...props} project={self.project} />} />
            <Route path={`${path}/s/:pid/info`} exact render={ (props) => <Info {...props} project={self.project} />} />
            <Route path={`${path}/s/:pid/privileges`} exact render={ (props) => <Privilege {...props} project={self.project} />} />

            <Route path={`${path}/s/:pid/products`} exact component={ Products }/>
            <Route path={`${path}/s/:pid/products/s/:id`} component={ ShowProduct } />
            
            <Route path={`${path}/s/:pid/inquiries`} exact render={ (props) => <Permit permit="Quote"><Inquiries {...props} project={self.project} /></Permit> } />
            <Route path={`${path}/s/:pid/inquiries/:id`} component={ (props) => <Permit permit="Quote"><ShowDeal {...props} /></Permit> } />
            <Route path={`${path}/s/:pid/orders`} exact component={ (props) => <Permit permit="Order"><Orders {...props} /></Permit> }/>
            <Route path={`${path}/s/:pid/orders/:id`} component={ (props) => <Permit permit="Order"><ShowOrder {...props} /></Permit> } />

            <Route path={`${path}/s/:id/:track(tm|tsp|tse)`} exact component={ Track }/>
            <Route path={`${path}/s/:id/:track(tm|tsp|tse)/trackers/:order_id/:item_id`} component={ ShowTrackers } />
            <Route path={`${path}/s/:id/:track(tm|tsp|tse)/tracker/:order_id/:item_id/:tracker_id?`} component={ EditTracker } />
          </Switch>
        </Grid>

        <div style={{height: 20}}></div>
      </React.Fragment>
    );
  }
}


export default withRouter(withStyles(styles)(Show));