/**
 * Created by milan on 2019/7/18.
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/styles/withStyles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';

// import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';


const styles = theme => ({
  root: {

  }
});


class Quality extends Component {
  rows = [
    ['进口原版', '原产地实木, 不锈钢', '99%以上'],
    ['A+', '欧标E0级实木, 304不锈钢', '90%以上'],
    ['A', '国标E0级实木, 304不锈钢', '70%以上'],
    ['B', '胶合板, 木质腿', '60%以上'],
  ];
  // constructor(props) {
  //  super(props);
  // }

  cell(value) {
    if (value === true || value === false) {
      return <Typography>{value ? <CheckIcon /> : <CloseIcon />}</Typography>;
    }
    return <Typography>{value}</Typography>;

  }

  render() {
    return (
      <React.Fragment>
        <CssBaseline />
        <Container component="main" maxWidth="sm">
          <Table aria-label="subscriptions table">
            <TableHead>
              <TableRow>
                <TableCell>等级</TableCell>
                <TableCell align="right">内材</TableCell>
                <TableCell align="right">模型还原度</TableCell>
                {/* <TableCell align="center">白金卡</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {this.rows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    {row[0]}
                  </TableCell>
                  <TableCell align="right">{this.cell(row[1])}</TableCell>
                  <TableCell align="right">{this.cell(row[2])}</TableCell>
                  <TableCell align="right">{this.cell(row[3])}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <div style={{height: 48}}></div>

        </Container>
      </React.Fragment>
    )
  }
}


export default withRouter(withStyles(styles)(Quality));