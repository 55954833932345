import React,  { Component } from 'react';
import { Route } from 'react-router-dom';
import withStyles from '@material-ui/styles/withStyles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Topbar from '../Topbar';
import { Mode, DataManager } from '../models';

import { MaterialPicker } from './Picker';
import List from './List';
import Batch from './Batch';
import Export from './Export';
import MaterialCard from './MaterialCard';
import Discover from './Discover';


const styles = theme => ({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
    marginTop: theme.spacing(2)
  }
});


class Materials extends Component {
  //constructor(props) {
  //  super(props);
  //}
  render() {
    if (DataManager.getInstance().mode < Mode.ADMIN)
      return this.customer();
    else
      return this.admin();
  }

  customer() {
    const path = '/' + this.props.match.path.split('/')[1];
    const currentPath = this.props.location.pathname;

    return (
      <React.Fragment>
        <CssBaseline />
        <Topbar currentPath={currentPath} />
        <Route path={path} exact component={ Discover } />
        <Route path={`${path}/s/:id?`} component={ MaterialCard } />
      </React.Fragment>
    )
  }

  admin() {
    const { classes } = this.props;
    const currentPath = this.props.location.pathname;
    const path = '/' + this.props.match.path.split('/')[1];

    return (
      <React.Fragment>
        <CssBaseline />
        <Topbar currentPath={currentPath} />
        <div className={classes.root}>
          <Route path={path} exact component={ List } />
          <Route path={`${path}/picker/`} component={ MaterialPicker } />
          <Route path={`${path}/batch/`} component={ Batch } />
          <Route path={`${path}/export/`} component={ Export } />
          <Route path={`${path}/s/:id?`} component={ MaterialCard } />
        </div>
      </React.Fragment>
    )
  }
}


export default withStyles(styles)(Materials);