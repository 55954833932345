/**
 * Created by milan on 2019/7/18.
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/styles/withStyles';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
//import TextField from '@material-ui/core/TextField';
import { TextField } from 'formik-material-ui';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Topbar from '../Topbar';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { Formik, Form, Field } from 'formik';
import T from 'i18n-react';
import { Mode, Event, DataManager } from '../models';
import AlertDialog from '../dialogs/AlertDialog';


const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white
    }
  },
  paper: {
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2)
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
});


const validate = values => {
  let errors = {};
  if (!values.invitation) {
    errors.invitation = T.translate('errors.required');
  } else if (values.invitation.length < 4) {
    errors.invitation = T.translate('errors.invalid_invitation');
  }
  // if (!values.display) {
  //   errors.display = T.translate('errors.required');
  // }
  if (Object.keys(errors).length)
    return errors;
};


class Join extends Component {
  state = {
    accepted: true,
    alert: false
  };

  componentDidMount() {}

  action = (values, actions) => {
    const self = this;
    
    return DataManager.getInstance().join_unit(values).then(function (res) {
      let errors = {};
      let c = res.c;
      if (c !== 0) {
        let e = T.translate('errors.' + res.c);
        if (c === 401) {
          errors.invitation = e;
        }
        actions.setSubmitting(false);
        if (Object.keys(errors).length) {
          actions.setErrors(errors);
          return;
        }
      }
      self.setState({alert: true});
    });
  };

  goodToGo = (e) => {
    // this.setState({alert: false});
    // const agreed = e.currentTarget.dataset.tag;
    const to = '/signout';
    if (DataManager.getInstance().mode >= Mode.ADMIN && window.location.pathname !== '/a/') {
      window.location = `/a/#${to}`;
    }else if (DataManager.getInstance().mode === Mode.BUSINESS && window.location.pathname !== '/s/') {
      window.location = `/s/#${to}`;
    }else if (DataManager.getInstance().mode < Mode.BUSINESS && window.location.pathname !== '/') {
      window.location = `/#${to}`;
    }
    DataManager.getInstance().pub(Event.REDIRECT, to);
  }

  render() {
    const { classes } = this.props;
    const currentPath = this.props.location.pathname;

    return (
      <React.Fragment>
        <CssBaseline />
        <Topbar currentPath={currentPath} />
        <Container component="main" maxWidth="xs">
          <Paper className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              {T.translate("units.join")}
            </Typography>
            <Formik
              initialValues={{invitation: '', display: '', accept: false }}
              validate={validate}
              validateOnChange={true}
              onSubmit={this.action}
            >
              {props => (
                <Form className={classes.form} noValidate>
                  <Field
                    component={TextField}
                    error={!!props.errors.invitation}
                    helperText={props.errors.invitation}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="invitation"
                    label={T.translate("units.join_code")}
                    type="text"
                    id="invitation"
                    autoComplete="invitation"
                  />
                  {/* <Field
                    component={TextField}
                    error={!!props.errors.display}
                    helperText={props.errors.display}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="display"
                    label={T.translate("form.display")}
                    type="text"
                    id="display"
                    autoComplete="display"
                  /> */}
                  <Typography component="p" variant="subtitle1" color="primary" style={{marginTop: 32}}>
                    申请加入新团队意味着您将离开当前团队并失去当前团队所有数据的访问权限, 
                    且依据新团队的设置, 您可能需要等待新团队负责人的审核, 审核通过后方可加入新团队.
                  </Typography>
                  <FormControlLabel
                    control={<Checkbox
                      value="accept" 
                      color="primary" 
                      checked={props.values.accept}
                      onChange={e => {
                        props.setFieldValue('accept', !props.values.accept);
                      }}
                      />}
                    label={T.translate("units.agree")}
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    disabled={props.isSubmitting || !props.values.accept}
                  >
                    {T.translate("units.apply_join")}
                  </Button>
                </Form>
              )}
            </Formik>
          </Paper>
        </Container>

        <AlertDialog
          open={this.state.alert}
          onClose={this.goodToGo}
          title={T.translate('dialogs.alert')}
          desc={T.translate('dialogs.unit_changed')}
          right={T.translate('buttons.ok')}
        />
      </React.Fragment>
    )
  }
}

export default withRouter(withStyles(styles)(Join));