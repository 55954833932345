/**
 * Created by milan on 2019/7/18.
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/styles/withStyles';
import T from 'i18n-react';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';

import { Event, DataManager } from '../models/';
import Checkbox from '@material-ui/core/Checkbox';
import AlertDialog from '../dialogs/AlertDialog';
import FormControlLabel from '@material-ui/core/FormControlLabel';


const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(1),
    overflowX: 'auto'
  }
});


const ensureFloat = (dictionary) => {
  const dict = {};
  Object.keys(dictionary).forEach(function(key) {
    dict[key] = parseFloat(dictionary[key] || 0);
  });
  return dict;
};


class Costs extends Component {
  id = -1;
  state = {
    memo: '',
    currency: 'CNY',
    values: {
      tax_rate: 6,
      shipping: 0,
      service: 0,
      install: 0,
      miscellaneous: 0,

      subtotal: 0,
      tax: 0,
      total: 0
    },
    requesting: false,

    orderable: false,
    alert: false
  };

  save_deal = () => {
    if (this.props.deal.state < 10) {
      const items = this.props.deal.items;
      let all_set = true;
      let i;
      for (i = 0; i < items.length; ++i) {
        const item = items[i];
        if (!item.flow || item.flow.length <= 1) {
          all_set = false;
          break;
        }
      }
      if (!all_set && this.state.orderable) {
        this.setState({alert: true});
        return new Promise(function(resolve, reject) {
          resolve({c: 8964});
        });
      }
    }
    this.setState({requesting: true});
    const params = {order: this.state.orderable, memo: this.state.memo, currency: this.state.currency, ...this.state.values};
    return DataManager.getInstance().update_deal(this.props.deal.id, params);
  };

  save = () => {
    let path = '/' + this.props.match.path.split('/')[1];
    const self = this;
    //self.setState({requesting: true});
    //const params = {memo: this.state.memo, currency: this.state.currency, ...this.state.values};
    self.save_deal().then(function (res) {
      if (res.c !== 0) {
        //const d = res.d;
        self.setState({requesting: false});
        return;
      }
      if (!self.props.onRef) DataManager.getInstance().pub(Event.REDIRECT, path);
      else self.setState({requesting: false});
    });
  };

  remove = () => {
    const self = this;
    self.setState({requesting: true});
    let path = '/' + this.props.match.path.split('/')[1];
    DataManager.getInstance().delete_deal(this.id, null).then(function (res) {
      if (res.c !== 0) {
        //const d = res.d;
        self.setState({requesting: false});
        return;
      }
      DataManager.getInstance().pub(Event.REDIRECT, path);
    });
  };

  componentDidMount() {
    if (this.props.onRef) this.props.onRef(this);
  }

  componentWillUnmount() {
    if (this.props.onRef) this.props.onRef(undefined);
  }

  constructor(props) {
    super(props);
    this.state.values.subtotal = props.subtotal || 0;
    if (props.deal) {
      const deal = props.deal;
      this.state.values.subtotal = deal.subtotal || 0;
      this.state.values.tax_rate = deal.tax_rate || 0;
      this.state.values.shipping = deal.shipping || 0;
      this.state.values.service = deal.service || 0;
      this.state.values.install = deal.install || 0;
      this.state.values.miscellaneous = deal.miscellaneous || 0;

      this.state.memo = deal.memo;
      this.state.currency = deal.currency;

      this.id = deal.id;
      this.state.orderable = deal.state >= 10;
    }
    if (!this.state.memo || this.state.memo.length === 0) {
      const default_memo = `1. 下单以后, 每个产品均有产品三视图和实物材质版可供确认; \n2. 运费中不含非电梯上楼费用, 如需通过${T.translate('appName')}使用该服务需实报实销.`;
      this.state.memo = default_memo;
    }
  }

  static getDerivedStateFromProps(props, curr_state) {
    const values = ensureFloat(curr_state.values);
    const subtotal = parseFloat(props.deal.subtotal || props.subtotal || 0);

    const total = subtotal + values.shipping + values.service + values.install + values.miscellaneous;
    return {
      values: {
        ...curr_state.values,
        subtotal: subtotal.toFixed(0),
        // tax: taxed.toFixed(0),
        total: total.toFixed(0)
      }
    };
  }

  close_alert = event => {
    this.setState({alert: false});
  };

  handleChange = event => {
    const s = {};
    s[event.target.name] = event.target.value;
    this.setState(s);
  };

  handleValueChange = event => {
    const values = this.state.values;
    values[event.target.name] = event.target.value;
    this.setState({values: values});
  };

  render() {
    const { classes } = this.props;
    return (
      <Paper className={classes.root}>
        <AlertDialog
          open={this.state.alert}
          onClose={this.close_alert}
          title={T.translate('dialogs.alert')}
          desc={T.translate('dialogs.can_order_desc')}
          right={T.translate('buttons.ok')}
        />
        <Table className={classes.table}>
          <TableBody>
            <TableRow>
              <TableCell> </TableCell>
              <TableCell> </TableCell>
              <TableCell> </TableCell>
              <TableCell> </TableCell>
              <TableCell> </TableCell>
              <TableCell> </TableCell>
              <TableCell> </TableCell>
              <TableCell> </TableCell>
            </TableRow>

            <TableRow>
              <TableCell rowSpan={7} colSpan={5}>
                <TextField
                  id="memo"
                  name="memo"
                  value={this.state.memo}
                  onChange={this.handleChange}
                  label={T.translate('orders.desc')}
                  placeholder={T.translate('orders.desc')}
                  multiline
                  margin="dense"
                  fullWidth
                />
              </TableCell>
              <TableCell colSpan={2}> </TableCell>
              <TableCell align="right"> </TableCell>
              {/* <TableCell colSpan={2}>{T.translate('orders.subtotal')}</TableCell>
              <TableCell align="right">{this.state.values.subtotal}</TableCell> */}
            </TableRow>
            <TableRow>
              <TableCell>{T.translate('orders.taxed')}</TableCell>
              <TableCell align="right">
                <TextField
                  //defaultValue={7}
                  placeholder={T.translate('orders.tax_rate')}
                  name="tax_rate"
                  value={this.state.values.tax_rate}
                  onChange={this.handleValueChange}
                  margin="dense"
                  InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                      inputProps: {style: {textAlign: 'right'}}
                    }}
                />
              </TableCell>
              <TableCell align="right">{this.state.values.subtotal}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2}>{T.translate('orders.shipping')}</TableCell>
              <TableCell align="right">{this.state.values.shipping}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2}>{T.translate('orders.install')}</TableCell>
              <TableCell align="right">{this.state.values.install}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2}>{T.translate('orders.miscellaneous')}</TableCell>
              <TableCell align="right">{this.state.values.miscellaneous}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2}>{T.translate('orders.service')}</TableCell>
              <TableCell align="right">{this.state.values.service}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2}>{T.translate('orders.total')}</TableCell>
              <TableCell align="right">{this.state.values.total}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                {this.id > 0 &&
                <Button variant="outlined" color="secondary" size="small" onClick={this.remove} disabled={this.state.requesting}>
                  {T.translate('orders.remove')}
                </Button>
                }
              </TableCell>
              <TableCell colSpan={6} align="center">

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.orderable}
                      name={`${this.props.deal.state}`}
                      value={this.props.deal.state}
                      onChange={this.order_checked}
                      color="primary"
                      inputProps={{ 'aria-label': 'item checkbox', 'data-tag': 0 }}
                    />
                  }
                  label={T.translate("dialogs.can_order")}
                />
              </TableCell>
              <TableCell colSpan={2} align="right">
                <Button variant="outlined" color="primary" size="small" onClick={this.save} disabled={this.state.requesting}>
                  {T.translate('orders.save')}
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
    )
  }

  order_checked = (e) => {
    this.setState({orderable: !this.state.orderable});
  };
}


export default withRouter(withStyles(styles)(Costs));