import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles(theme => ({
  player: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  img: {
    // height: '100%',
    maxWidth: '100%',
    maxHeight: '100%',
    verticalAlign: 'middle'
  },

  root: {
    cursor: 'pointer',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    overflow: 'hidden',
    // flexWrap: 'nowrap',
    transform: 'translateZ(0)',
  },
}));


export default function MediaRow(props) {
  const classes = useStyles();
  const l = props.images && props.images.length > 0 ? props.images.length + 1 : 1;

  return (
    <div className={classes.root} onClick={props.onClick}>
      <GridList className={classes.gridList} cols={Math.min(l, 3)}>
        {props.images.slice(0, 5).map((url, index) => (
        <div key={index} className={classes.player}>
            <img className={classes.img} src={url} alt={url} />
        </div>
        ))}
        <div className={classes.player}>
          <Button
            color="secondary"
            className={classes.img}
            data-tag={props.tag}
            style={{width: '100%', height:  '100%', textAlign: 'center', verticalAlign: 'middle'}}
          >
            <Typography component="span" variant="caption">共{props.images.length}张<br/>点击查看大图</Typography>
          </Button>
        </div>
      </GridList>
    </div>
  );
}
