import React, { Component } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import ShowItem from './ShowItem';
import ShowProduct from './ShowProduct';


const styles = theme => ({
  titleBar: {
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
    position: 'relative'
  },
  title: {
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(0.5)
    }
  },
  closeButton: {
    position: 'absolute',
    width: 48,
    height: 48,
    padding: theme.spacing(0),
    right: theme.spacing(0),
    top: theme.spacing(0),
    color: theme.palette.grey[500]
  }
});


class ProductDialog extends Component {

  render() {
    const { classes, product, open, onClose, deal_id } = this.props;
    if (!product) return <React.Fragment/>;

    return (
      <Dialog
        maxWidth="sm"
        fullWidth={true}
        fullScreen={false}
        open={open}
        onClose={onClose}
        scroll='paper'
      >
        <DialogTitle>
          <div className={classes.titleBar}>
            <Typography align="center" variant="h6" className={classes.title}>{product.title}</Typography>
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
              <CloseIcon style={{width: 48, height: 48}} />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          {deal_id && product.state >= 10 ? 
          <ShowProduct key={product.product_id} product={product} deal_id={deal_id} customizable={deal_id > 0} />
          :
          <ShowItem product={product}/>
          }
        </DialogContent>
      </Dialog>
    );
  }
}


export default withStyles(styles)(ProductDialog);
