import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/styles/withStyles';
import BaseDialog from './BaseDialog';
import Carousel from '../common/Carousel';


const styles = theme => ({
  container: {
    flexGrow: 1,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minWidth: 320
  },
  bottomMargin: {
    marginBottom: theme.spacing(2)
  },
  player: {
    maxWidth: '100%',
    maxHeight: '80vh',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  fullPlayer: {
    //maxWidth: '100%',
    //maxHeight: '80vh',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  img: {
    //width: '100%',
    maxWidth: '100%',
    maxHeight: '100%',
    verticalAlign: 'middle'
  }
});

class ImagesDialog extends Component {

  state = {
    autoplay: true,
    activeStep: 0
  };

  handleNext = () => {
    this.setState(prevState => ({
      activeStep: prevState.activeStep + 1,
      autoplay: false
    }));
  };

  handleBack = () => {
    this.setState(prevState => ({
      activeStep: prevState.activeStep - 1,
      autoplay: false
    }));
  };

  handleStepChange = activeStep => {
    this.setState({ activeStep });
  };

  componentDidUpdate(prevProps) {
    if (this.props.open !== prevProps.open)
      this.setState({activeStep: 0});
  }

  render() {
    const { classes, images, active, ...props } = this.props;
    const maxSteps = images.length;
    const player = this.props.maxWidth === 'xl' ? classes.fullPlayer : classes.player;

    return (
      <BaseDialog {...props}>
        <div className={classes.container}>
          {maxSteps > 1 ?
            <React.Fragment>
              <Carousel
                animation={'slide'}
                interval={5000}
                indicators={true}
                autoPlay={true}
                active={active}
              >
                {images.map((step, index) => (
                  !step.imgPath || typeof step === 'string' ?
                  <div key={step} className={player}>
                    <img className={classes.img} src={step} alt={step} />
                  </div>
                  :
                  <div key={step.label} className={player}>
                    <img className={classes.img} src={step.imgPath} alt={step.label} />
                  </div>
                ))}
              </Carousel>
            </React.Fragment>
            :
            <div className={classes.bottomMargin} >
              {images.map((step, index) => (
                typeof step === 'string' ?
                  <div key={step} className={player}>
                    <img className={classes.img} src={step} alt={step} />
                  </div>
                  :
                  <div key={step.label} className={player}>
                    <img className={classes.img} src={step.imgPath} alt={step.label} />
                  </div>
              ))}
            </div>
          }
        </div>
      </BaseDialog>
    )
  }
}

export default withRouter(withStyles(styles)(ImagesDialog));
