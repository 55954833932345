import React,  { Component } from 'react';
import { Route } from 'react-router-dom';
import withStyles from '@material-ui/styles/withStyles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Topbar from '../Topbar';
import List from './List';
import Checkout from './Checkout';
import ToOrder from './ToOrder';


const styles = theme => ({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
    marginTop: theme.spacing(2)
  }
});


class Cart extends Component {
  //constructor(props) {
  //  super(props);
  //}

  render() {
    const { classes } = this.props;
    const currentPath = this.props.location.pathname;
    const path = '/' + this.props.match.path.split('/')[1];

    return (
      <React.Fragment>
        <CssBaseline />
        <Topbar currentPath={currentPath} />
        <div className={classes.root}>
          <Route path={path} exact component={ List } />
          <Route path={`${path}/checkout/`} component={ Checkout } />
          <Route path={`${path}/to_order/`} component={ ToOrder } />
        </div>
      </React.Fragment>
    )
  }
}


export default withStyles(styles)(Cart);