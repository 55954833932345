/**
 * Created by milan on 2019/7/18.
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/styles/withStyles';
import T from 'i18n-react';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import { priceDisplay } from '../common/Constants';


const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(1),
    overflowX: 'auto'
  }
});


class Costs extends Component {
  id = -1;
  state = {
    alert: 0,
    memo: '',
    currency: 'CNY',
    values: {
      subtotal: 0,
      total: 0
    },
    requesting: false
  };

  constructor(props) {
    super(props);
    this.state.values.subtotal = props.subtotal || 0;
    if (props.order) {
      const order = props.order;
      this.state.values.subtotal = order.subtotal || 0;
      this.state.values.total = order.total || 0;

      this.state.memo = order.memo;
      this.state.currency = order.currency;

      this.id = order.id;
    }
    if (!this.state.memo || this.state.memo.length === 0) {
      //this.state.memo = '需要一个月内出货';
    }
  }

  handleChange = event => {
    const s = {};
    s[event.target.name] = event.target.value;
    this.setState(s);
  };

  render() {
    const { classes } = this.props;
    return (
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableBody>
            <TableRow>
              <TableCell> </TableCell>
              <TableCell> </TableCell>
              <TableCell> </TableCell>
              <TableCell> </TableCell>
              <TableCell> </TableCell>
            </TableRow>

            <TableRow>
              <TableCell rowSpan={3} colSpan={2}>
                <TextField
                  id="order_memo"
                  name="memo"
                  value={this.state.memo}
                  onChange={this.handleChange}
                  label={T.translate('orders.memo')}
                  placeholder={T.translate('orders.memo')}
                  multiline
                  margin="dense"
                  disabled={true}
                  fullWidth
                />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell colSpan={2}>{T.translate('orders.total')}</TableCell>
              <TableCell align="right">{priceDisplay(this.props.order.total, this.props.order.currency)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2}>{T.translate('orders.address')}</TableCell>
              <TableCell align="right">{this.props.order.address}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
    )
  }
}


export default withRouter(withStyles(styles)(Costs));