import React, { Component } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import withWidth from '@material-ui/core/withWidth';

const styles = theme => ({
  titleBar: {
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2)
  },
  title: {
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(0.5)
    }
  },
  closeButton: {
    position: 'absolute',
    padding: theme.spacing(2),
    right: theme.spacing(0),
    top: theme.spacing(0),
    color: theme.palette.grey[500]
  }
});

class BaseDialog extends Component {

  render() {
    const { classes, open, onClose, onEnter, width, maxWidth, fullWidth, ...props } = this.props;
    const fullScreen = width === 'xs';
    return (
      <Dialog
        maxWidth={maxWidth ? maxWidth : 'md'}
        fullWidth={fullWidth}
        fullScreen={fullScreen}
        open={open}
        onClose={onClose}
        onEnter={onEnter}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        scroll='paper'
      >
        <DialogTitle id="alert-dialog-title" disableTypography className={classes.titleBar}>
          <Typography align="center" variant="h6" className={classes.title}>{props.title || ''}</Typography>
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText align="center" id="alert-dialog-description">{props.desc || ''}</DialogContentText>
          {this.props.children}
        </DialogContent>
      </Dialog>
    )
  }
}

export default withWidth()(withStyles(styles)(BaseDialog));
