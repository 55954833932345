import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Badge from '@material-ui/core/Badge';
import { DataManager } from '../models/';
import T from 'i18n-react';
import useMediaQuery from '@material-ui/core/useMediaQuery';


function allyProps(index) {
  return {
    id: `order-state-${index}`,
    'aria-controls': `order-state-${index}`
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  },
  padding: {
    padding: theme.spacing(0, 2)
  }
}));

export default function Navigator(props) {
  const { stats, current, onChange } = props;
  const classes = useStyles(props.theme);

  const states = [];
  const OrderState = DataManager.getInstance().states.orders;
  const active = ['PaymentDue', 'Bidding', 'Blueprinting', 'Manufacturing', 'Shipping'];
  let i;
  for (i = 0; i < active.length; ++i) {
    const k = OrderState[active[i]];
    states.push({title: T.translate(`orders.short_states.${active[i]}`), state: k});
  }
  states.push({title: T.translate('orders.states.All'), state: 0});
  let def = current;
  //if (def < 0 && stats) {
  //  if (stats[states[0].state] > 0) def = states[0].state;
  //  else if (stats[states[1].state] > 0) def = states[1].state;
  //}
  //console.log(def);

  const [value, setValue] = React.useState(def);
  const matches = useMediaQuery('(min-width:720px)');

  React.useEffect(() => {
    setValue(def);
  }, [def]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    onChange(newValue);
  };

  return (
    <div className={classes.root}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant={matches ? "fullWidth" : "scrollable"}
          scrollButtons="on"
          //centered
          aria-label="state switch">
          {states.map((state, index) =>
            <Tab
              key={index}
              value={state.state}
              {...allyProps(index)}
              label={
                <Badge className={classes.padding} color="primary" badgeContent={stats ? stats[state.state] : 0}>
                  {state.title}
                </Badge>
              }
            />
          )}
        </Tabs>
    </div>
  );
}
