import {save, restore, clear, Mode, Event} from './Data';
const axios = require('axios');


class DataManager {
  static _instance = null;
  static getInstance() {
    if (DataManager._instance == null) {
      DataManager._instance = new DataManager();

      DataManager._instance.init();
    }

    return this._instance;
  }

  static debug = process.env.NODE_ENV !== 'production';
  static log = (...args) => {
    if (DataManager.debug)
      console.log(...args);
  };

  storage = {flags: []};
  observers = {};
  hook = null;

  mode = Mode.UNAUTHORIZED;
  states = null;

  reload() {
    let role = this.storage.role;
    let mode = Mode.UNAUTHORIZED;
    if (role >= 40) {
      mode = Mode.ADMIN;
    }else if (role >= 30) {
      mode = Mode.ADMIN;
    }else if (role >= 20) {
      mode = Mode.BUSINESS;
    }else if (role >= 15) {
      mode = Mode.PTR;
    }else if (role >= 10) {
      mode = Mode.NORMAL;
    }else if (role >= 0) {
      mode = Mode.NORMAL;
    }else{
      mode = Mode.UNAUTHORIZED;
    }
    this.mode = mode;
  }

  init() {
    restore(this.storage);

    this.agent = axios.create({
      //baseURL: 'http://localhost:3000',
      timeout: 30000,
      headers: {'Token': this.storage.token}
    });

    for (var e in Event) {
      if (Event.hasOwnProperty(e)) {
        this.observers[Event[e]] = [];
      }
    }

    this.reload();
  }

  sub(evt, observer) {
    if (this.observers[evt].indexOf(observer) < 0)
      this.observers[evt].push(observer);
  }

  unsub(evt, observer) {
    let i = this.observers[evt].indexOf(observer);
    if (i >= 0)
      this.observers[evt].splice(i, 1);
  }

  pub(evt, params) {
    if (DataManager.debug) {
      for (var e in Event) {
        if (Event.hasOwnProperty(e) && Event[e] === evt) {
          DataManager.log('Event:', e, evt, params);
        }
      }
    }
    this.observers[evt].forEach(function (observer, _) {
      observer(params);
    });
  }

  authorized() {
    let authorized = this.mode >= Mode.AUTHORIZED;
    if (!authorized) {
      DataManager.getInstance().pub(Event.REDIRECT, '/');
    }
    return authorized;
  }

  update_profile(profile) {
    this.storage.name = profile.display;
    save(this.storage);
  }

  permit(key) {
    if (key instanceof Array) {
      let f = true;
      let i;
      for (i = 0; i < key.length; ++i) {
        if (this.storage.flags.indexOf(key[i]) >= 0) {
          f = false;
          break;
        }
      }
      return f;
    }
    return this.storage.flags.indexOf(key) < 0;
  }

  is_admin() {
    return this.mode > Mode.BUSINESS || this.storage.ur >= 28;
  }

  trusted() {
    return this.mode !== Mode.BUSINESS || this.storage.ur > 10;
  }

  handle_resp(res) {
    if (res.c === 499) {
      DataManager.getInstance().pub(Event.REDIRECT, '/signin');
    }else if (res.c === 498) {
      DataManager.getInstance().hook = res.d;
      DataManager.getInstance().pub(Event.REDIRECT, '/unauthorized?498');
    }else if (res.c === 496) {
      DataManager.getInstance().pub(Event.REDIRECT, '/corp/plans');
    }else if (res.c === 400) {
      DataManager.getInstance().pub(Event.REDIRECT, '/unauthorized');
    }
    return res;
  }

  verify() {
    if (!this.authorized()) return new Promise(function(resolve, reject) {
      resolve({c: 400});
    });

    let self = this;
    let storage = this.storage;
    let agent = this.agent;
    let params = {t: storage.token, u: storage.updated};
    return agent.post('/api/users/token/', params)
      .then(function (response) {
        if (response.status === 200) {
          let res = response.data;
          if (res.c === 0) {
            storage.flags = res.d.flags;
            storage.ur = res.d.ur;
            storage.name = res.d.display;
            storage.messages = res.d.m;
            storage.mc = res.d.mc;
            storage.config = res.d.c;
            save(storage);
            DataManager.getInstance().pub(Event.UPDATED, storage.name);
            return res;
          }
        }
        clear(storage);
        restore(storage);
        agent.defaults.headers['Token'] = storage.token;
        self.reload();
        //DataManager.getInstance().pub(Event.UPDATED, storage.name);
        DataManager.getInstance().pub(Event.UNAUTHORIZED, '/');
        return {c: 1};
      })
      .catch(function (error) {
        console.log(error);
        return {c: 500};
      });
  }

  sign_out() {
    let self = this;
    let storage = this.storage;
    let agent = this.agent;
    return agent.delete('/api/users/.json')
      .then(function (response) {
        if (response.status === 200) {
          clear(storage);
          restore(storage);
          agent.defaults.headers['Token'] = storage.token;
          self.reload();
          DataManager.getInstance().pub(Event.UNAUTHORIZED, '/');
          return {c: 0};
        }
      })
      .catch(function (error) {
        console.log(error);
        return {c: 500};
      });
  }

  sign_in_or_up(url, params) {
    let self = this;
    let storage = this.storage;
    let agent = this.agent;
    return agent.post(url, params)
      .then(function (response) {
        //console.log(response);
        if (response.status === 200) {
          let res = response.data;
          if (res.c === 0) {
            storage.token = res.d.token;
            storage.role = res.d.role;
            storage.ur = res.d.ur;
            storage.flags = res.d.flags;
            storage.updated = res.d.updated;
            save(storage);
            agent.defaults.headers['Token'] = storage.token;
            self.reload();
            DataManager.getInstance().pub(Event.AUTHORIZED, null);
          }
          return res;
        }
      })
      .catch(function (error) {
        console.log(error);
        return {c: 500};
      });
  }

  delete_resources(url, params) {
    if (!this.authorized()) return new Promise(function(resolve, reject) {
      resolve({c: 400});
    });

    let agent = this.agent;
    return agent.delete(url, params ? {params: params} : params)
      .then(function (response) {
        //console.log(response);
        if (response.status === 200) {
          return DataManager.getInstance().handle_resp(response.data);
        }
      })
      .catch(function (error) {
        console.log(error);
        return {c: 500};
      });
  }

  get_resources(url, params) {
    if (!this.authorized()) return new Promise(function(resolve, reject) {
      resolve({c: 400});
    });

    let agent = this.agent;
    return agent.get(url, params ? {params: params} : params)
      .then(function (response) {
        //console.log(response);
        if (response.status === 200) {
          return DataManager.getInstance().handle_resp(response.data);
        }
      })
      .catch(function (error) {
        console.log(error);
        return {c: 500};
      });
  }

  post_resources(url, params, skipAuth) {
    if (!skipAuth && !this.authorized()) return new Promise(function(resolve, reject) {
      resolve({c: 400});
    });

    let agent = this.agent;
    return agent.post(url, params)
      .then(function (response) {
        //console.log(response);
        if (response.status === 200) {
          return DataManager.getInstance().handle_resp(response.data);
        }
      })
      .catch(function (error) {
        console.log(error);
        return {c: 500};
      });
  }

  form_post(url, params, onProgress) {
    if (!this.authorized()) return new Promise(function(resolve, reject) {
      resolve({c: 400});
    });

    var formData = new FormData();
    for (var key in params) {
      if (params.hasOwnProperty(key)) {
        const v = params[key];
        if (v instanceof FileList || v instanceof Array) {
          for (var i = 0; i < v.length; ++i) {
            formData.append(`${key}[${i}]`, v[i]);
          }
        }else
          formData.append(key, v);
      }
    }

    let agent = this.agent;
    return agent.post(url, formData,
      {
        timeout: 60000,
        headers: {'Content-Type': 'multipart/form-data'},
        onUploadProgress: onProgress
      })
      .then(function (response) {
        if (response.status === 200) {
          return DataManager.getInstance().handle_resp(response.data);
        }
      })
      .catch(function (error) {
        console.log(error);
        return {c: 500};
      });
  }

  list_regions(params) {
    return this.get_resources('/api/regions/web.json', params);
  }

  update_inquiry(params, onProgress) {
    let url = '/api/inquiries/batch/';
    return this.form_post(url, params, onProgress);
  }

  upload_product_media(id, params, onProgress) {
    let url = `/api/products/file/${id}`;
    return this.form_post(url, params, onProgress);
  }

  upload_with_url(params) {
    return this.post_resources('/api/products/url_image/', params);
  }

  quotation(id, params) {
    let url = `/api/inquiries/${id}/edit/`;
    return this.form_post(url, params, null);
  }

  sign_in(params) {
    return this.sign_in_or_up('/api/users/signin/', params);
  }

  sign_up(params) {
    return this.sign_in_or_up('/api/users/signup/', params);
  }

  show_me() {
    return this.get_resources('/api/users/me/', null);
  }

  update_me(params) {
    return this.post_resources('/api/users/me/', params);
  }

  update_password(params) {
    return this.post_resources('/api/users/password/', params);
  }

  request_code(params) {
    return this.post_resources('/api/users/request_code/', params, true);
  }

  reset_password(params) {
    return this.post_resources('/api/users/reset/', params, true);
  }

  promote_unit(id, params) {
    return this.post_resources(`/api/units/${id}/promote/`, params);
  }

  discover_units(params) {
    return this.get_resources('/api/units/', params);
  }

  list_units(params) {
    return this.get_resources('/api/admin/units/', params);
  }

  list_bidder(params) {
    return this.get_resources('/api/admin/units/bidder/', params);
  }

  update_unit(id, params) {
    return this.post_resources(`/api/units/${id}/update/`, params, null);
  }

  update_unit_admin(id, params) {
    let url = id > 0 ? `/api/admin/units/${id}/update/` : '/api/admin/units/';
    return this.post_resources(url, params);
  }

  delete_unit(id, params) {
    return this.delete_resources(`/api/admin/units/${id}/`, params);
  }

  update_plan(id, params) {
    return this.post_resources(`/api/admin/units/plan/${id}/`, params);
  }

  show_unit(id, params) {
    return this.get_resources(`/api/units/${id}/`, params);
  }

  admin_unit(id, params) {
    return this.get_resources(`/api/units/a/${id}/`, params);
  }

  update_unit_config(id, params) {
    return this.post_resources(`/api/units/a/${id}/`, params);
  }

  unit_config(params, save) {
    if (save)
      return this.post_resources('/api/units/config/', params);
    else
      return this.get_resources('/api/units/config/', params);
  }

  join_unit(params) {
    return this.get_resources('/api/units/join/', params);
  }

  unit_users(id, params) {
    return this.get_resources(`/api/units/${id}/users/`, params);
  }

  manage_user(id, params) {
    return this.post_resources(`/api/units/${id}/users/`, params);
  }

  show_dashboard(id, params) {
    return this.get_resources(`/api/units/db/${id}/`, params);
  }

  list_quotes(params) {
    return this.get_resources('/api/quotes/', params);
  }

  show_quote_history(params) {
    return this.get_resources('/api/quotes/history/', params);
  }

  show_quote(id, params) {
    return this.get_resources(`/api/quotes/${id}/`, params);
  }

  show_item_quotes(deal_id, item_id, params) {
    return this.get_resources(`/api/quotes/item/${deal_id}/${item_id}/`, params);
  }

  show_deal_with_quotes(id, params) {
    return this.get_resources(`/api/quotes/deal/${id}/`, params);
  }

  quotes_to_order(id, params) {
    return this.post_resources(`/api/quotes/order/${id}/`, params);
  }

  update_quote(id, params) {
    return this.post_resources(`/api/quotes/${id}/`, params);
  }

  delete_quote(id, params) {
    return this.delete_resources(`/api/quotes/${id}/`, params);
  }

  list_inquiries(params) {
    return this.get_resources('/api/inquiries/.json', params);
  }

  show_inquiry(id, params) {
    return this.get_resources(`/api/inquiries/${id}/`, params);
  }

  delete_inquiry(id, params) {
    return this.delete_resources(`/api/inquiries/${id}/`, params);
  }

  show_material_by_code(code, params) {
    return this.get_resources(`/api/materials/code/${code}/`, params);
  }

  list_materials_by_product(id, params) {
    return this.get_resources(`/api/materials/bp/${id}/`, params);
  }

  discover_materials(params) {
    return this.get_resources('/api/materials/discover/', params);
  }

  export_materials(params) {
    return this.post_resources('/api/materials/exp/', params);
  }

  list_materials(params) {
    return this.get_resources('/api/materials/', params);
  }

  delete_material(id, params) {
    return this.delete_resources(`/api/materials/${id}/`, params);
  }

  show_material(id, params) {
    return this.get_resources(`/api/materials/${id}/`, params);
  }

  save_material(id, params) {
    const url = id > 0 ? `/api/materials/${id}/` : '/api/materials/';
    return this.post_resources(url, params);
  }

  save_material_item(material_id, item_id, params) {
    const url = `/api/materials/${material_id}/${item_id}/`;
    return this.post_resources(url, params);
  }

  delete_material_item(material_id, item_id) {
    const url = `/api/materials/${material_id}/${item_id}/`;
    return this.delete_resources(url);
  }

  save_materials(params) {
    return this.post_resources('/api/materials/batch/', params);
  }

  upload_media(params, url, onProgress) {
    return this.form_post(url ? `/api/${url}/media/` : '/api/media/', params, onProgress);
  }

  quote_product(get, params) {
    if (get)
      return this.get_resources('/api/quotes/product/', params);
    else
      return this.post_resources('/api/quotes/product/', params);
  }

  list_products(params) {
    return this.get_resources('/api/products/.json', params);
  }

  delete_product(id, params) {
    return this.delete_resources(`/api/products/${id}/`, params);
  }

  show_product(id, params) {
    return this.get_resources(`/api/products/${id}/`, params);
  }

  save_product(id, params) {
    const url = id > 0 ? `/api/products/${id}.json` : '/api/products/';
    return this.post_resources(url, params);
  }

  save_product_flow(params) {
    return this.post_resources('/api/products/flow/', params);
  }

  save_product_materials(product_id, params) {
    return this.post_resources(`/api/products/${product_id}/materials/`, params);
  }

  save_item(product_id, params) {
    return this.post_resources(`/api/products/${product_id}/item/`, params);
  }

  delete_item(product_id, item_id, params) {
    return this.delete_resources(`/api/products/${product_id}/${item_id}/`, params);
  }

  show_item(id, params) {
    return this.get_resources(`/api/products/item/${id}/`, params);
  }

  show_wanted(id, params) {
    return this.get_resources(`/api/products/wanted/${id}/`, params);
  }

  show_specs(product_id, item_id, params) {
    return this.get_resources(`/api/products/specs/${product_id}/${item_id}/`, params);
  }

  save_specs(product_id, item_id, params) {
    return this.post_resources(`/api/products/specs/${product_id}/${item_id}/?e=1`, params);
  }

  upload_specs(product_id, item_id, params) {
    return this.form_post(`/api/products/specs/${product_id}/${item_id}/?e=1`, params, null);
  }

  add_to_cart(params) {
    return this.post_resources('/api/products/add_to_cart/', params);
  }

  show_cart(params) {
    return this.get_resources('/api/products/cart/', params);
  }

  update_cart(params) {
    return this.post_resources('/api/products/update_cart/', params);
  }

  list_deals(params) {
    return this.get_resources('/api/deals/.json', params);
  }

  cart_to_order(params) {
    return this.post_resources('/api/orders/from_cart/', params);
  }

  create_deal_from_collections(params) {
    return this.post_resources('/api/deals/fp/', params);
  }

  create_deal(params) {
    return this.post_resources('/api/deals/', params);
  }

  update_deal_items(params) {
    return this.post_resources('/api/deals/items/', params);
  }

  update_deal(id, params) {
    return this.post_resources(`/api/deals/${id}/update/`, params);
  }

  bidder_deal(id, params) {
    if (params)
      return this.post_resources(`/api/deals/${id}/bidder/`, params);
    else
      return this.get_resources(`/api/deals/${id}/bidder/`, params);
  }

  show_deal(id, params) {
    return this.get_resources(`/api/deals/${id}/`, params);
  }

  config_deal(id, params) {
    return this.post_resources(`/api/deals/config/${id}/`, params);
  }

  sync_deal_products(id, params) {
    return this.get_resources(`/api/deals/${id}/sync/`, params);
  }

  delete_deal(id, params) {
    return this.delete_resources(`/api/deals/${id}/`, params);
  }

  quotation_with_deal(id, params) {
    let url = `/api/inquiries/quotation/${id}/`;
    return this.post_resources(url, params, null);
  }

  customize_deal(params) {
    return this.post_resources('/api/deals/customize/', params);
  }

  attach_to_project(id, params) {
    let url = `/api/inquiries/attach/${id}/`;
    return this.post_resources(url, params, null);
  }

  request_deal_excel(id, params) {
    let url = `/api/deals/request_excel/${id}/`;
    return this.post_resources(url, params);
  }

  show_deal_with_excel(id, params) {
    let agent = this.agent;
    return agent.get(`/api/deals/excel/${id}/`, params ? {params: params} : params, {timeout: 60000})
      .then(function (response) {
        if (response.status === 200) {
          return DataManager.getInstance().handle_resp(response.data);
        }
      })
      .catch(function (error) {
        console.log(error);
        return {c: 500};
      });
    //return this.get_resources(`/api/deals/excel/${id}/`, params);
  }

  list_chats(remote, type, about, params) {
    let url = `/api/chats/${remote}/${type}/${about}/`;
    return this.get_resources(url, params);
  }

  send_chat(id, params) {
    return this.post_resources(`/api/chats/${id}/`, params);
  }

  send_chat_media(id, params, onProgress) {
    return this.form_post(`/api/chats/media/${id}/`, params, onProgress);
  }

  google(params) {
    return this.get_resources('https://hk.zhuchaox.com/api/products/g_search/', params);
  }

  ensure_order_states() {
    return this.get_resources('/api/orders/states/', null);
  }

  discover_products(params) {
    return this.get_resources('/api/products/discover/', params);
  }

  list_user_products(params) {
    return this.get_resources('/api/products/users/', params);
  }

  list_orders(params) {
    return this.get_resources('/api/orders/.json', params);
  }

  sub_orders(id, params) {
    return this.get_resources(`/api/orders/${id}/sub_orders/`, params);
  }

  create_order_from_cart(params) {
    return this.post_resources('/api/orders/create_from_cart/', params);
  }

  deal_to_order(id, params) {
    return this.post_resources(`/api/orders/from_deal/${id}/`, params);
  }

  create_order(params) {
    return this.post_resources('/api/orders/', params);
  }

  update_order_items(params) {
    return this.post_resources('/api/orders/items/', params);
  }

  update_order(id, params) {
    return this.post_resources(`/api/orders/${id}/update/`, params);
  }

  show_order(id, params) {
    return this.get_resources(`/api/orders/${id}/`, params);
  }

  sync_order_products(id, params) {
    return this.get_resources(`/api/orders/${id}/sync/`, params);
  }

  delete_order(id, params) {
    return this.delete_resources(`/api/orders/${id}/`, params);
  }

  seller_order(id, params) {
    return this.post_resources(`/api/orders/${id}/seller/`, params);
  }

  user_order(id, params) {
    return this.post_resources(`/api/orders/${id}/user/`, params);
  }

  list_trackers(id, params) {
    return this.get_resources(`/api/trackers/${id}/`, params);
  }

  show_tracker(id, tracker_id, params) {
    return this.get_resources(`/api/trackers/${id}/${tracker_id}/`, params);
  }

  update_tracker(id, tracker_id, params, onProgress) {
    return this.form_post(`/api/trackers/${id}/${tracker_id}/`, params, onProgress);
  }

  delete_tracker(id, tracker_id, params) {
    return this.delete_resources(`/api/trackers/${id}/${tracker_id}/`, params);
  }

  quotation_with_order(id, params) {
    let url = `/api/inquiries/quotation/${id}/`;
    return this.post_resources(url, params, null);
  }

  show_order_with_excel(id, params) {
    return this.get_resources(`/api/orders/excel/${id}/`, params);
  }

  list_messages(params) {
    return this.get_resources('/api/messages/', params);
  }

  list_dinja_records(params) {
    return this.get_resources('/api/connector/records/', params);
  }

  update_dinja_record(params) {
    return this.post_resources('/api/connector/record/', params);
  }

  read_all_messages(params) {
    return this.get_resources('/api/messages/read/', params);
  }

  read_message(id, params) {
    return this.get_resources(`/api/messages/${id}/`, params);
  }

  collect_product(params) {
    return this.post_resources('/api/projects/favor/', params);
  }

  list_projects(params) {
    return this.get_resources('/api/projects/', params);
  }

  show_project(id, params) {
    return this.get_resources(`/api/projects/${id}/`, params);
  }

  update_project(params, onProgress) {
    return this.form_post('/api/projects/', params, onProgress);
  }

  delete_project(id, params) {
    return this.delete_resources(`/api/projects/${id}/`, params);
  }

  list_project_products(id, params) {
    return this.get_resources(`/api/projects/${id}/products/`, params);
  }

  config_project(id, params) {
    return this.get_resources(`/api/projects/${id}/prioritize/`, params);
  }

  show_privileges(params) {
    return this.get_resources('/api/privileges/', params);
  }

  update_privileges(params) {
    return this.post_resources('/api/privileges/', params);
  }
}


export default DataManager;



