/**
 * Created by milan on 2019/7/18.
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/styles/withStyles';
import T from 'i18n-react';
import { Event, DataManager } from '../models/';

import MaterialTable from 'material-table';
import { tableIcons } from '../common/Constants';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Box from '@material-ui/core/Box';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { Link } from 'react-router-dom';


const styles = theme => ({
  root: {

  }
});


class List extends Component {
  productColumns = [
    { title: T.translate('units.key'), field: 'key', editable: 'never' },
    { title: T.translate('units.type'), field: 'type', editable: 'always' },
    { title: T.translate('units.name'), field: 'name', editable: 'always' },
    { title: T.translate('units.display'), field: 'display', editable: 'always' },
    { title: T.translate('units.uri'), field: 'uri', editable: 'always' },
    { title: T.translate('units.create_at'), field: 'create_at', editable: 'never' },
    { title: T.translate('flags.ec'), field: 'promoted', filtering: false, editable: 'never', render: unit => (
      unit && unit.type === 2 ?
      <Flags key={unit.id} unit={unit} />
      :
      <React.Fragment/>
    ) }
  ];
  state = {
    total: 1, page: 1, per: 0, data: []
  };
  types = {0: false, 1: true, 2: true, 10: false};
  tableRef = React.createRef();

  list = (query) => {
    //const self = this;
    let p = query.page > 0 ? query.page + 1 : (parseInt(this.props.location.search.substring(1)) || 1);
    if (p < 0) p = 0;
    const types = this.types;
    const ts = Object.keys(types).filter(key => types[key]).join('|');
    return DataManager.getInstance().list_units({page: p, ts: ts}).then(function (res) {
      if (res.c === 0) {
        let d = res.d;
        const total = d.t;
        const max_page = Math.ceil(total / d.p);
        if (p > max_page) p = max_page;
        if (p < 1) p = 1;
        return {
          data: d.units,
          page: p - 1,
          totalCount: total
        };
      }
      return {data: [], page: 0, totalCount: 0};
    })
  };

  save_unit = (id, params) => {
    return DataManager.getInstance().update_unit_admin(id, params).then(function (res) {
      if (res.c !== 0) {
        DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'error', m: T.translate('errors.400')});
      }
      //self.tableRef.current.onQueryChange();
    })
  };

  update_unit = (newData, oldData) => {
    if (!newData.name || !newData.uri) {
      DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'error', m: T.translate('errors.units.required')});
      return new Promise((resolve, reject) => {
        reject();
      });
    }
    const params = {name: newData.name, display: newData.display, uri: newData.uri, type: newData.type};
    return this.save_unit(oldData.id, params);
  };

  new_unit = (newData) => {
    if (!newData.name || !newData.uri) {
      DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'error', m: T.translate('errors.units.required')});
      return new Promise((resolve, reject) => {
        reject();
      });
    }
    const params = {name: newData.name, display: newData.display, uri: newData.uri, type: newData.type};
    return this.save_unit(-1, params);
  };

  delete_unit = (oldData) => {
    return DataManager.getInstance().delete_unit(oldData.id, null).then(function (res) {
      if (res.c !== 0) {
        DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'error', m: T.translate('errors.400')});
        //return;
      }
      //self.tableRef.current.onQueryChange();
    })
  };

  render() {
    const types = {
      0: T.translate('units.user'),
      1: T.translate('units.buyer'),
      2: T.translate('units.seller'),
      10: T.translate('units.manager')
    };
    this.productColumns[1].lookup = types;

    const path = '/' + this.props.match.path.split('/')[1];
    const self = this;

    const title = <div style={{display: 'inline-block'}}>
      <Typography variant="h6" style={{display: 'inline', marginRight: 32}}>{T.translate('units.all')}</Typography>
      {Object.keys(types).map((type) => {
        return <FormControlLabel
          key={type}
          control={
            <Checkbox
              color="primary"
              checked={this.types[type]}
              onChange={e => {
                self.types[type] = !self.types[type];
                self.setState({refresh: !self.state.refresh});
                self.tableRef.current.onQueryChange();
              }}
            />
          }
          label={this.productColumns[1].lookup[type]}
        />;
      })}
      <Link style={{display: 'inline', marginLeft: 32}} to="/seller/discover/">推荐列表</Link>
    </div>;

    return (
      <React.Fragment>
        <MaterialTable
          tableRef={this.tableRef}
          icons={tableIcons}
          title={title}
          options={{addRowPosition: 'first', search: false, pageSize: 20, pageSizeOptions: [20], detailPanelType: 'single'}}
          columns={self.productColumns}
          actions={[
          //{
          //  icon: () => <tableIcons.Add />,
          //  tooltip: 'New Deal',
          //  isFreeAction: true,
          //  onClick: this.new_deal
          //}
          ]}
          editable={{
            onRowAdd: self.new_unit,
            onRowUpdate: self.update_unit,
            onRowDelete: self.delete_unit
          }}
          onRowClick={(event, rowData, togglePanel) => DataManager.getInstance().pub(Event.REDIRECT, `${path}/a/${rowData.id}`)}
          data={query => self.list(query)}
        />
      </React.Fragment>
    )
  }
}


function Flags(props) {
  const { unit } = props;

  const [promoted, setPromoted] = React.useState(unit.state >= 100 ? unit.state : 0);
  const values = [0, 100, 110];
  const titles = ['无', '推荐', '优先推荐'];

  const onChange = (e) => {
    const value = parseInt(e.target.value);
    return DataManager.getInstance().promote_unit(unit.id, {state: value}).then(function (res) {
      if (res.c !== 0) {
        DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'error', m: T.translate('notification.failed')});
        return;
      }
      setPromoted(value);
      DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'success', m: T.translate('notification.success')});
    });
  };

  return (
    <React.Fragment >
      <Box style={{width: 250}} onClick={e => {e.stopPropagation();}}>
        <RadioGroup
          value={promoted}
          style={{marginTop: 16}}
          row
          onChange={onChange}
        >
          {values.map((v, index) => (
            <FormControlLabel 
              key={index} 
              value={v}
              control={<Radio color="primary" />} 
              labelPlacement="bottom" 
              style={{marginLeft: 0}}
              label={<Typography style={{fontSize: 14}}>{titles[index]}</Typography>}
            />
          ))}
        </RadioGroup>
      </Box>
    </React.Fragment>
  );
}


export default withRouter(withStyles(styles)(List));