/**
 * Created by milan on 2019/7/18.
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/styles/withStyles';
import T from 'i18n-react';
import { Event, DataManager } from '../models/';
import { priceDisplay } from '../common/Constants';

import MaterialTable from 'material-table';
import { tableIcons, QualityLookup } from '../common/Constants';
//import AddBox from '@material-ui/icons/AddBox';
import CheckedIcon from '@material-ui/icons/CheckCircleOutline';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import IconButton from '@material-ui/core/IconButton';

import ImagesDialog from '../dialogs/ImagesDialog';
import FlagsEditor from '../common/FlagsEditor';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

function DenseTable(props) {
  if (!props.product || !props.product.items || props.product.items.length === 0) return <div></div>;

  const itemColumns = [
    { title: T.translate('products.media'), field: 'media' },
    //{ title: T.translate('products.sku'), field: 'identifier' },
    { title: T.translate('products.brand'), field: 'brand' },
    { title: T.translate('products.quality'), field: 'quality' },
    { title: T.translate('products.size'), field: 'size' },
    { title: T.translate('products.material'), field: 'material' },
    //{ title: T.translate('products.currency'), field: 'currency' },
    { title: T.translate('products.price2'), field: 'p2' },
    { title: T.translate('products.price1'), field: 'p1' }
  ];
  const onAdd = props.onAdd;

  const product = props.product;
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          {onAdd && <TableCell>{T.translate('products.inquiry')}</TableCell>}

          {itemColumns.map((col, idx) => (
            <TableCell key={idx} >{col.title}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {product.items.map((row, index) => (
          <TableRow key={index} >
            {onAdd &&
            <TableCell component="th" scope="row">
              {row.checked ?
              <IconButton color="secondary" align="center" size="small">
                  <CheckedIcon />
                </IconButton>
                :
              <IconButton color="primary" align="center" size="small" onClick={(e) => {
                onAdd(product, index);
              }}>
                <AddShoppingCartIcon />
              </IconButton>
              }
            </TableCell>
            }

            {itemColumns.map((col, idx) => {
              const cellData = row[col.field];
              if (col.field === 'media' && cellData && cellData.length > 0) {
                return (
                  <TableCell key={idx}>
                    {props.onRowClick ?
                    <IconButton color="primary" align="center" size="small" onClick={(e) => {
                        props.onRowClick(product.items[index]);
                      }}>
                      <img
                        style={{ height: 48, maxWidth: 100 }}
                        alt={cellData[0]}
                        src={cellData[0]}
                      />
                    </IconButton>
                    :
                    <img
                      style={{ height: 64, maxWidth: 100 }}
                      alt={cellData[0]}
                      src={cellData[0]}
                    />}
                  </TableCell>
                );
              } else
                return <TableCell key={idx} >{col.field === 'quality' ? QualityLookup[cellData] : cellData}</TableCell>;
            })}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}


const styles = theme => ({
  fab: {
    position: 'fixed',
    zIndex: 10000,
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  }
});


const onChange = (pid, priority) => {
  return DataManager.getInstance().save_product(pid, {priority: priority}).then(function (res) {
    if (res.c !== 0) {
      return;
    }
    DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'success', m: T.translate('notification.success')});
  });
};


class ProductsTable extends Component {
  productColumns = [
    { title: T.translate('products.media'), field: 'media', editable: 'never', filtering: false, render: rowData => (
    rowData && <img
        style={{ height: 72, maxWidth: 100 }}
        alt={rowData.media ? rowData.media[0] : ''}
        src={rowData.media ? rowData.media[0] : ''}
      />
    ) },  // , lookup: {34: 'İstanbul', 63: 'Şanlıurfa'}
    //{ title: T.translate('products.id'), field: 'identifier', editable: 'never', filtering: true },
    { title: T.translate('products.title'), field: 'title', editable: 'never', filtering: true },
    { title: T.translate('products.type'), field: 'item_type', editable: 'never', filtering: true },
    { title: T.translate('products.space'), field: 'item_space', editable: 'never', filtering: true },
    //{ title: T.translate('products.style'), field: 'item_style', editable: 'never', filtering: true },
    { title: T.translate('products.brand'), field: 'brand', editable: 'never', filtering: true },
    { title: T.translate('products.price'), field: 'prices', filtering: false },
    { title: T.translate('products.state'), field: 'priority', filtering: false, render: product => (
      <FlagsEditor
        key={product.id}
        choices={['customizable', 'ec', 'standard']} 
        current={product.priority} 
        onChange={(priority) => {
          onChange(product.id, priority);
        }} />
    ) }
  ];
  state = {
    isLoading: false, cart: 0, carousel: null, total: 1, page: 1, per: 0, data: []
  };
  deal_id = -1;
  initial_page = 1;
  deal = null;

  constructor(props) {
    super(props);
    if (props.deal) {
      this.deal = props.deal;
      this.deal_id = this.deal.id;
    }
    const search = props.location.search.substring(1);
    if (search) {
      const page = parseInt(search);
      if (page > 0) this.initial_page = page;
    }
  }

  update_deal_items = (product, index) => {
    const item = product.items[index];
    const deal_id = this.deal_id;
    if (deal_id > 0) {
      const self = this;
      const params = {id: deal_id, f: 1, p: item.id, q: 1, d: 1};
      const index = this.props.index;
      if (this.props.deal && index !== undefined && index >= 0)
        params.pid = this.props.deal.items[index].product_id;
      DataManager.getInstance().update_deal_items(params).then(function (res) {
        if (res.c === 0) {
          if (self.deal) {
            const pro = JSON.parse(JSON.stringify(product));
            for (var key in item) {
              if (item.hasOwnProperty(key) && key !== 'media' && item[key]) {
                pro[key] = item[key];
              }
            }
            pro.item_id = item.id;
            pro.media = pro.media.concat(item.media);
            pro.quantity = 1;
            pro.price = pro.price1;
            pro.shipping = 0;
            pro.install = 0;
            pro.miscellaneous = 0;
            pro.category = pro.item_space ? pro.item_space.split()[0] : '';
            self.deal.subtotal = res.d.t;
            if (index >= 0) {
              self.deal.items[index] = pro;
            }else{
              self.deal.items.push(pro);
            }

            const costs = res.d.costs;
            for (var k in costs) {
              if (costs.hasOwnProperty(k)) {
                self.deal[k] = costs[k];
              }
            }
          }
          self.setState({cart: res.d.c});
        }
      });
    }
  };

  sync_labels = (e) => {
    return DataManager.getInstance().get_resources('/api/products/reload/', null).then(function (res) {
      if (res.c !== 0) {
        DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'error', m: T.translate('notification.failed')});
        return;
      }
      if (res.d.labels) DataManager.getInstance().storage.labels = res.d.labels;
      DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'success', m: T.translate('notification.success')});
    });
  };

  list = (query) => {
    if (this.state.isLoading) return;
    const self = this;
    let p = this.initial_page > 1 ? this.initial_page : query.page + 1;
    if (p < 0) p = 0;
    const params = {page: p};
    if (query.search) params.tags = query.search;
    for (var i = 0; i < query.filters.length; ++i) {
      const col = query.filters[i];
      params[col.column.field] = col.value;
    }
    if (this.props.deal && this.props.index !== undefined && this.props.index >= 0)
      params.pid = this.props.deal.items[this.props.index].product_id;
    return DataManager.getInstance().list_products(params).then(function (res) {
      if (res.c === 0) {
        self.initial_page = 0;
        let d = res.d;
        const total = d.t;
        const max_page = Math.ceil(total / d.p);
        if (p > max_page) p = max_page;
        if (p < 1) p = 1;
        const products = d.ps;
        let i;
        for (i = 0; i < products.length; ++i) {
          const product = products[i];
          product.prices = `${priceDisplay(product.price1, 'CNY')} - ${priceDisplay(product.price2, 'CNY')}`;
          product.items.sort((l, r) => (l.update_at > r.update_at) ? -1 : 1);
          //product.items.sort((l, r) => (l.id > r.id) ? -1 : 1);
        }
        DataManager.getInstance().pub(Event.REDIRECT, {pathname: self.props.location.pathname, search: `${p}`});
        return {
          data: products,
          page: p - 1,
          totalCount: total
        };
      }
      return {data: [], page: 0, totalCount: 0};
    })
  };

  delete_product = (rowData) => {
    const self = this;
    self.setState({isLoading: true});
    return DataManager.getInstance().delete_product(rowData.id, null).then(function (res) {
      if (res.c === 0) {
        //let d = res.d;
        self.setState({isLoading: false});
      }
      return res;
    })
  };

  decor_product = (product) => {
    let i;
    for (i = 0; i < product.items.length; ++i) {
      const item = product.items[i];
      item.p1 = priceDisplay(item.price1, item.currency);
      item.p2 = priceDisplay(item.price2, item.currency);
    }
    if (!this.deal) return product;
    let j;
    for (i = 0; i < product.items.length; ++i) {
      let productItem = product.items[i];
      productItem.checked = false;
      for (j = 0; j < this.deal.items.length; ++j) {
        const dealItem = this.deal.items[j];
        if (dealItem.product_id !== productItem.product_id) continue;
        if (productItem.id === dealItem.item_id) {
          productItem.checked = true;
          break;
        }
      }
    }
    return product;
  };

  showCarousel = (rowData) => {
    this.setState({carousel: rowData});
  };

  closeCarousel = (e) => {
    this.setState({carousel: null});
  };

  index() {
    const self = this;
    const path = '/' + this.props.match.path.split('/')[1];

    let images = [];
    let text = {};
    if (this.state.carousel != null) {
      const product = this.state.carousel;
      text.title = product.title;
      for (var i = 0; i < product.media.length; ++i) {
        const url = product.media[i];
        const image = {label: i, description: url, imgPath: url};
        images.push(image);
      }
    }
    return (
      <React.Fragment>
        <MaterialTable
          icons={tableIcons}
          title={T.translate('products.all')}
          options={{search: true, filtering: true, isLoading: this.state.isLoading, debounceInterval: 1000, pageSize: 20, pageSizeOptions: [20], detailPanelType: 'single'}}
          columns={self.productColumns}
          actions={[{
              icon: () => <tableIcons.Add />,
              tooltip: 'New Product',
              isFreeAction: true,
              onClick: (event) => DataManager.getInstance().pub(Event.REDIRECT, `${path}/c/`)
            }, {
              icon: () => <tableIcons.Sync />,
              tooltip: 'Sync Labels',
              isFreeAction: true,
              onClick: this.sync_labels
            }, {
              icon: () => <tableIcons.Edit />,
              tooltip: 'Edit',
              onClick: (event, rowData) => DataManager.getInstance().pub(Event.REDIRECT, `${path}/e/${rowData.id}`)
            }
          ]}
          // editable={{
          //   onRowDelete: oldData => {
          //     let data = this.state.data;
          //     const index = data.indexOf(oldData);
          //     data.splice(index, 1);
          //     this.setState({ data });
          //     return self.delete_product(oldData);
          //   }
          // }}
          //onRowClick={(event, rowData, togglePanel) => togglePanel()}
          onRowClick={(event, rowData, togglePanel) => DataManager.getInstance().pub(Event.REDIRECT, `${path}/s/${rowData.id}`)}
          detailPanel={rowData => {
            return (
              <DenseTable product={self.decor_product(rowData)} onRowClick={self.showCarousel} />
            )
          }}
          data={query => self.list(query)}
        />

        <ImagesDialog
          images={images}
          open={this.state.carousel != null}
          onClose={this.closeCarousel}
          {...text}
        />
      </React.Fragment>
    )
  }

  simple() {
    const self = this;
    return (
      <React.Fragment>
        <MaterialTable
          icons={tableIcons}
          title={T.translate('products.all')}
          options={{search: true, filtering: true, isLoading: this.state.isLoading, debounceInterval: 1000, pageSize: 20, pageSizeOptions: [20], detailPanelType: 'single'}}
          columns={self.productColumns}
          onRowClick={(event, rowData, togglePanel) => togglePanel()}
          detailPanel={rowData => {
            return (
              <DenseTable product={self.decor_product(rowData)} onAdd={self.update_deal_items} />
            )
          }}
          data={query => self.list(query)}
        />
      </React.Fragment>
    )
  }

  render() {
    if (!this.state.data || this.state.data === 0)
      return <div></div>;
    if (this.deal_id > 0)
      return this.simple();

    return this.index();
  }
}


export default withRouter(withStyles(styles)(ProductsTable));