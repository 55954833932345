import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import T from 'i18n-react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import { Link } from 'react-router-dom';

import Divider from '@material-ui/core/Divider';
import { Mode, DataManager } from '../models/';
import { QuoteBadge } from '../common/StateBadge';
import { priceDisplay } from '../common/Constants';


const useStyles = makeStyles(theme => ({
  label: {
    margin: theme.spacing(2),
    fontWeight: 300
  },
  content: {
    fontSize: 24,
    margin: theme.spacing(2)
  },
  res_normal: {
    fontSize: 24,
    margin: theme.spacing(2),
    fontWeight: 'bold'
  },
  res_ready: {
    fontSize: 24,
    margin: theme.spacing(2),
    fontWeight: 'bold',
    color: '#03A9F4'
  }
}));

const types = [{v: '1', l: 'inquiries.t1'}, {v: '2', l: 'inquiries.t2'}, {v: '3', l: 'inquiries.t3'}];

export default function Cell(props) {
  const { item, index, displayIndex, path, onClick } = props;

  const classes = useStyles(props.theme);

  let title = '';
  if (item.req) title = item.req;
  else if (item.title) title = item.title;
  else{
    const t = `${item.type}`;
    let i;
    for (i = 0; i < types.length; ++i) {
      const tp = types[i];
      if (tp.v === t) {
        title = T.translate(tp.l);
        break;
      }
    }
  }

  const u_str = T.translate('deals.unit');
  return (
    <Box key={index} >
      {index > 0 && <Divider variant="inset" component="li" />}
      {DataManager.getInstance().mode >= Mode.ADMIN && item.deal_id > 0 &&
      <Link to={{pathname: `/inquiries/o/${item.deal_id}`}} className={classes.label}>查看设计师询价</Link>
      }
      <ListItem alignItems="center" button onClick={onClick} >
        <ListItemAvatar>
          <React.Fragment>
            <Typography component="p" align="center" style={{fontSize: 40, display: 'block'}}>
              {displayIndex}
            </Typography>
            {QuoteBadge(item.state)}
          </React.Fragment>
        </ListItemAvatar>
        <ListItemText
          component="div"
          primary={
            <React.Fragment>
              <Typography variant="body2" className={classes.label} color="textPrimary" >
                {T.translate('inquiries.create_at')}: {item.create_at}{(DataManager.getInstance().mode >= Mode.ADMIN ? ', ' + item.unit.display : '')}
              </Typography>
              <Typography className={classes.content}>{title}</Typography>
              <Typography variant="body2" className={classes.label} color="textPrimary" >
                {T.translate('inquiries.update_at')}: {item.state % 10 >= 2 ? item.update_at : ''}
              </Typography>
              {item.res ?
                <Typography className={classes.res_ready}>{item.res}</Typography>
              :
                <Typography variant="body2" className={item.state % 10 >= 2 ? classes.res_ready : classes.res_normal} >
                  {T.translate('quotation.quoted')}: {item.quoted} / {item.quantity} {u_str},
                  &nbsp;{T.translate('orders.total')}: &nbsp;{priceDisplay(item.subtotal, item.currency) || T.translate('quotation.unquoted')}
                </Typography>
              }
            </React.Fragment>
          }
        />
        {DataManager.getInstance().mode >= Mode.ADMIN &&
        <ListItemSecondaryAction>
          <IconButton style={{display: 'block'}} value={index} component={Link} edge="end" aria-label="comments"
                      to={{pathname: `${path}/e/${item.id}`, search: props.qs ? `${props.qs}` : null}}
          >
            <EditIcon />
          </IconButton>
        </ListItemSecondaryAction>
        }
      </ListItem>
    </Box>
  )
}
