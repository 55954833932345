/**
 * Created by milan on 2019/7/18.
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/styles/withStyles';
import T from 'i18n-react';
import { Event, DataManager } from '../models/';
import { priceDisplay } from '../common/Constants';
import Switch from './Navigator';

import MaterialTable from 'material-table';
import { tableIcons } from '../common/Constants';
import Business from '@material-ui/icons/Business';
import { StateBadge, OrderItemBadge } from '../common/StateBadge';


const styles = theme => ({
  root: {

  }
});


class List extends Component {
  productColumns = [
    { title: T.translate('orders.id'), field: 'identifier', editable: 'never' },
    { title: T.translate('orders.state'), field: 'state', editable: 'never', render: (order) => (
      <React.Fragment>
        <div style={{display: 'inline-block', marginRight: 3}} >
          {OrderItemBadge(order.state, DataManager.getInstance().states.orders)}
        </div>
        <div style={{display: 'inline-block'}} >
          {order.mc > 0 ?
            <StateBadge state={order.mc} type={'state_urgent'} title={T.translate('orders.new_message')} />
            :
            <React.Fragment>
              {order.payment >= 1000 && order.type !== 2 &&
              <StateBadge state={order.payment} type={'state_urgent'} title={T.translate(order.payment >= 2000 ? 'orders.payment.you_payee_verifying' : 'orders.payment.you_payee')} />
              }
              {order.payment >= 1000 && order.type === 2 &&
              <StateBadge state={order.payment} type={'state_urgent'} title={T.translate(order.payment >= 2000 ? 'orders.payment.seller_payee_verifying' : 'orders.payment.you_payer')} />
              }
            </React.Fragment>
          }
        </div>
      </React.Fragment>
    )},
    { title: T.translate('orders.buyer'), field: 'buyer', editable: 'never' },
    { title: T.translate('orders.seller'), field: 'seller', editable: 'never' },
    { title: T.translate('orders.quantity'), field: 'quantity', editable: 'never' },
    //{ title: T.translate('orders.subtotal'), field: 'subtotal', editable: 'never' },
    { title: T.translate('orders.total'), field: 'total', editable: 'never' },
    { title: T.translate('orders.paid'), field: 'paid', editable: 'never' },
    { title: T.translate('orders.received'), field: 'received', editable: 'never' },
    { title: T.translate('orders.create_at'), field: 'create_at', editable: 'never' },
    { title: T.translate('orders.update_at'), field: 'update_at', editable: 'never' }
  ];
  state = {
    total: 1, page: 1, per: 0, data: [], stats: {}
  };
  tableRef = React.createRef();
  filter = 0;

  constructor(props) {
    super(props);
    const storage = DataManager.getInstance().storage;
    if (storage.mc && storage.mc.orders) {
      this.state.stats = storage.mc.orders.oc;
    }
    const search = props.location.search.substring(1).split('.');
    if (search.length > 1) {
      const p = search[0];
      const page = parseInt(p);
      if (page > 0) this.state.page = page;
      const f = search[1];
      const filter = parseInt(f);
      if (filter >= 0) this.filter = filter;
    }else{
      const stats = this.state.stats;
      const active = ['PaymentDue', 'Bidding', 'Blueprinting', 'Manufacturing', 'Shipping'];
      const OrderState = DataManager.getInstance().states.orders;
      let i;
      for (i = 0; i < active.length; ++i) {
        const f = OrderState[active[i]];
        if (stats[f] > 0) {
          DataManager.getInstance().pub(Event.REDIRECT, {pathname: this.props.location.pathname, search: `1.${f}`});
          return;
        }
      }
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.location.search !== nextProps.location.search) {
      const search = nextProps.location.search.substring(1).split('.');
      if (search.length > 1) {
        const p = search[0];
        const page = parseInt(p);
        if (page > 0) nextState.page = page;
        const f = search[1];
        const filter = parseInt(f);
        if (filter >= 0) this.filter = filter;
      }
      this.tableRef.current.onQueryChange();
      return true;
    }
    return true;
  }

  list = (query) => {
    const self = this;
    let p = query.page > 0 ? query.page + 1 : this.state.page;
    if (p < 0) p = 0;
    return DataManager.getInstance().list_orders({page: p, f: this.filter, id: this.props.id || -1}).then(function (res) {
      if (res.c === 0) {
        let d = res.d;
        const storage = DataManager.getInstance().storage;
        if (storage.mc && storage.mc.orders) {
          storage.mc.orders = d.stats;
        }
        const total = d.t;
        const max_page = Math.ceil(total / d.p);
        if (max_page > 0 && p > max_page) p = max_page;
        if (p < 1) p = 1;
        const orders = d.orders;
        for (var i = 0; i < orders.length; ++i) {
          const order = orders[i];
          order.subtotal = priceDisplay(order.subtotal, order.currency);
          order.total = priceDisplay(order.total, order.currency);
          order.paid = priceDisplay(order.paid, order.currency);
          order.received = priceDisplay(order.received, order.currency);
          order.mc = d.stats.mc[order.id];
        }
        self.setState({stats: d.stats});
        return {
          data: orders,
          page: p - 1,
          totalCount: total
        };
      }
      return {data: [], page: 0, totalCount: 0};
    })
  };

  handle_filter = (v) => {
    const f = parseInt(v);
    DataManager.getInstance().pub(Event.REDIRECT, {pathname: this.props.location.pathname, search: `1.${f}`});
    //this.filter = f;
    //this.tableRef.current.onQueryChange();
  };

  new_order = () => {
    const path = '/' + this.props.match.path.split('/')[1];
    DataManager.getInstance().pub(Event.REDIRECT, `${path}/c`);
  };

  edit_order = (event, order) => {
    DataManager.getInstance().pub(Event.REDIRECT, `/orders/e/${order.id}`);
    //if (order.type === 10 && !order.tableData.isTreeExpanded) {
    //  this.tableRef.current.onTreeExpandChanged(order.tableData.path, order);
    //}else{
    //  DataManager.getInstance().pub(Event.REDIRECT, `/orders/e/${order.id}`);
    //}
  };

  render() {
    const self = this;
    return (
      <React.Fragment>
        <Switch stats={this.state.stats.oc} current={this.filter} onChange={this.handle_filter} />

        <MaterialTable
          tableRef={this.tableRef}
          icons={tableIcons}
          title={T.translate('orders.all')}
          options={{search: false, pageSize: 10, pageSizeOptions: [10], detailPanelType: 'single'}}
          columns={self.productColumns}
          actions={[{
            //   icon: () => <tableIcons.Add />,
            //   tooltip: '创建订单',
            //   isFreeAction: true,
            //   onClick: this.new_order
            // }, {
              icon: () => <Business />,
              tooltip: '厂家后台',
              //onClick: this.edit_order
              onClick: (event, rowData) => DataManager.getInstance().pub(Event.REDIRECT, `/orders/se/${rowData.id}`)
            }, {
              icon: () => <tableIcons.SlideShow />,
              tooltip: '设计师界面',
              //onClick: this.edit_order
              onClick: (event, rowData) => DataManager.getInstance().pub(Event.REDIRECT, `/orders/s/${rowData.id}`)
            }
          ]}
          onRowClick={this.edit_order}
          //parentChildData={(row, rows) => rows.find(a => a.id === row.parent_id)}
          data={query => self.list(query)}
          //onTreeExpandChange={(data, isTreeExpanded) => {
          //  console.log(data, isTreeExpanded);
          //}}
        />
      </React.Fragment>
    )
  }
}


export default withRouter(withStyles(styles)(List));