import React, { Component } from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';

import Fab from '@material-ui/core/Fab';
import ContactSupportIcon from '@material-ui/icons/Chat';
import ChatBoard from './ChatBoard';

//import clsx from 'clsx';
import { green } from '@material-ui/core/colors';
import Badge from '@material-ui/core/Badge';
import { Event, DataManager } from '../models/';


const StyledBadge = withStyles(theme => ({
  badge: {
    //top: '50%',
    top: -5,
    right: -5,
    color: theme.palette.danger,
    border: `2px solid ${
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[900]
    }`
  }
}))(Badge);


const styles = theme => ({
  fab: {
    position: 'fixed',
    zIndex: 1000,
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  },
  fabGreen: {
    position: 'fixed',
    zIndex: 1000,
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    color: theme.palette.common.white,
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[600]
    }
  },
  hidden: {
    display: 'none'
  }
});


class Chatter extends Component {
  state = {carousel: false, c: 0, anchorEl: null};
  key = 0;

  handleUpdated = (params) => {
    if (params.c !== this.state.c)
      this.setState({c: params.c});
  };

  componentDidMount() {
    DataManager.getInstance().sub(Event.DISPLAY, this.handleUpdated);
  }

  componentWillUnmount() {
    DataManager.getInstance().unsub(Event.DISPLAY, this.handleUpdated);
  }

  onClick = (e) => {
    this.props.onClick(e);
    if (this.state.carousel) {
      this.close(e);
      return;
    }
    this.key++;
    this.setState({carousel: true, anchorEl: e.currentTarget});
  };

  close = (e) => {
    this.setState({carousel: false, anchorEl: null});
  };

  render() {
    const { classes, type, about, remote, badge } = this.props;

    return (
      <React.Fragment>
        <ChatBoard
          key={this.key}
          type={type}
          about={about}
          remote={remote}
          open={this.state.carousel}
          anchorEl={this.state.anchorEl}
          onClose={this.close}
        />

        <Fab aria-label="chatter" className={classes.fabGreen} onClick={this.onClick}>
          <StyledBadge badgeContent={badge > 0 ? 'NEW' : null} color="error">
            <ContactSupportIcon />
          </StyledBadge>
        </Fab>

      </React.Fragment>
    );
  }
}

export default withStyles(styles)(withTheme(Chatter));

