import React, { Component } from 'react';
import withStyles from '@material-ui/styles/withStyles';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import T from 'i18n-react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import { FlowTexts } from '../common/Constants';


const styles = theme => ({
  root: {
    backgroundColor: 'white'
  },
  box: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    padding: theme.spacing(0.5)
  },
  title: {
    width: '100%',
    textAlign: 'center'
  },
  chip: {
    margin: theme.spacing(0.5)
  },
  icon_primary: {
    borderRadius: '50%',
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    height: 20,
    width: 20,
    textAlign: 'center'
  },
  icon: {
    borderRadius: '50%',
    border: `1px solid ${theme.palette.secondary.main}`,
    color: theme.palette.secondary.main,
    height: 20,
    width: 20,
    textAlign: 'center'
  }
});

const types = ['s', 'm', 'w', 'r'];
const options = ['sf', 'sc', 'ss', 'mf', 'ms', 'wf', 'ws', 'wd', 'rm', 'rf'];
const required = ['cs', 'csp'];

class SortableChips extends Component {
  source = [];
  chipData = [];
  dragging = -1;
  adding = -1;
  state = {};

  constructor(props) {
    super(props);
    this.source = props.labels ? props.labels : [];
    this.chipData = this.source.slice();
    if (this.chipData[0] !== required[0]) this.chipData.splice(0, 0, required[0]);
    //if (this.chipData[this.chipData.length - 1] !== required[required.length - 1])
    //  this.chipData.splice(this.chipData.length, 0, required[required.length - 1]);
  }

  handleDelete = (index) => {
    this.chipData.splice(index, 1);
    this.forceUpdate();
  };

  onBatch = (e) => {
    const index = parseInt(e.currentTarget.dataset.tag);
    const type = types[index];
    let i;
    for (i = 0; i < options.length; ++i) {
      const option = options[i];
      if (type === option[0] && this.chipData.indexOf(option) < 0) {
        this.chipData.push(option);
      }
    }
    this.forceUpdate();
  };

  onAdd = (e) => {
    const tag = e.currentTarget.dataset.tag;
    this.adding = parseInt(tag);
    this.dragging = -1;
  };

  onDrag = (e) => {
    const tag = e.currentTarget.dataset.tag;
    this.dragging = parseInt(tag);
    this.adding = -1;
  };

  onDrop = (e) => {
    const drop = parseInt(e.currentTarget.dataset.tag);
    const locked = required.indexOf(this.chipData[drop]) >= 0;
    if (this.dragging >= 0 && !locked) {
      const drag = this.chipData[this.dragging];
      this.chipData[this.dragging] = this.chipData[drop];
      this.chipData[drop] = drag;
    }else if (this.adding >= 0) {
      const drag = options[this.adding];
      if (this.chipData.indexOf(drag) >= 0) return;
      this.chipData.splice(drop + 1, 0, drag);
    }
    this.forceUpdate();
  };

  onSave = (e) => {
    this.props.onSave(this.chipData.slice(1, this.chipData.length));
  };

  render() {
    const { open, onClose } = this.props;

    return (
      this.props.dialog ?
        <React.Fragment>
          <Dialog open={open} onClose={onClose} fullWidth maxWidth='md' aria-labelledby="dialog-title">
            <DialogContent>
              {this.main()}
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} color="secondary">
                {T.translate('buttons.cancel')}
              </Button>
              <Button onClick={this.onSave} color="primary">
                {T.translate('buttons.save')}
              </Button>
            </DialogActions>
          </Dialog>
        </React.Fragment>
      :
        this.main()
    );
  }

  main() {
    const { classes } = this.props;
    const self = this;
    const chips = this.chipData;

    return (
      <Box className={classes.root}>
        <Typography className={classes.title} variant="subtitle1">{T.translate('products.candidates')}</Typography>
        <Box className={classes.box}>
          {types.map((label, index) => {
            const icon = <Typography
              className={classes.icon_primary}
              variant="caption">
              {T.translate(`products.${FlowTexts[label]}.title`)}
            </Typography>;
            return (
              <Chip
                key={index}
                data-tag={index}
                icon={icon}
                label={T.translate(`products.${FlowTexts[label]}`)}
                className={classes.chip}
                variant="outlined"
                color="primary"
                clickable={true}
                onClick={this.onBatch}
              />
            );
          })}
        </Box>
        <Box className={classes.box}>
          {options.map((label, index) => {
            if (chips.indexOf(label) >= 0) return null;
            const icon = <Typography
              className={classes.icon}
              variant="caption">
              {T.translate(`products.${FlowTexts[label[0]]}.title`)}
            </Typography>;
            return (
              <Chip
                key={index}
                data-tag={index}
                icon={icon}
                label={T.translate(`products.${FlowTexts[label]}`)}
                className={classes.chip}
                variant="outlined"
                color="secondary"
                draggable={true}
                onDragStart={this.onAdd}
              />
            );
          })}
        </Box>

        <Typography className={classes.title} variant="subtitle1">{T.translate('products.flow')}</Typography>
        <Box className={classes.box}>
          {chips.map((label, index) => {
            const locked = required.indexOf(label) >= 0;
            const added = self.source.indexOf(label) < 0;
            const color = added ? 'primary' : 'secondary';
            const icon =
              <Typography
              className={locked || !added ? classes.icon : classes.icon_primary}
              variant="caption">
              {T.translate(`products.${FlowTexts[label[0]]}.title`)}
            </Typography>;
            return (
              <Chip
                key={index}
                data-tag={index}
                icon={icon}
                label={T.translate(`products.${FlowTexts[label]}`)}
                className={classes.chip}
                variant="outlined"
                color={locked ? 'default' : color}
                //disabled={locked}

                draggable={!locked}
                onDelete={locked ? undefined : () => {self.handleDelete(index);}}
                onDragOver={(e) => {
                  //e.stopPropagation();
                  e.preventDefault();
                }}
                onDragStart={locked ? undefined : this.onDrag}
                onDrop={this.onDrop}
              />
            );
          })}
        </Box>
      </Box>
    )
  }
}

export default withStyles(styles)(SortableChips);
