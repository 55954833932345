import React from 'react';
import Menu from '@material-ui/core/Menu';
//import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import Add from '@material-ui/icons/ArrowDropDownCircle';
import ButtonBase from '@material-ui/core/ButtonBase';

export default function DropdownButton(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const id = `dropdown-menu-${props.index}`;
  return (
    <div className={props.className}>
      <IconButton aria-controls={id} aria-haspopup="true" color="primary" aria-label={props.index} data-tag={props.index} onClick={handleClick}>
        <Add />
      </IconButton>
      <Menu
        id={id}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <ButtonBase style={{display: 'flex', flexDirection: 'column'}} onClick={handleClose}>{props.children}</ButtonBase>
      </Menu>
    </div>
  );
}
