import React,  { Component } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Topbar from '../Topbar';
import { Mode, DataManager } from '../models/';

import Dashboard from './Dashboard';
import Landing from '../Landing';


const styles = theme => ({
  root: {
  }
});

class SellerHome extends Component {

  render() {
    const { classes } = this.props;
    const currentPath = this.props.location.pathname;
    const authed = DataManager.getInstance().mode >= Mode.BUSINESS;
    return (
    authed ?
      <React.Fragment>
        <CssBaseline />
        <Topbar currentPath={currentPath} />
        <div className={classes.root}>
          {authed ? <Dashboard {...this.props} /> : <Landing {...this.props} />}
        </div>
      </React.Fragment>
      :
      <Landing {...this.props} />
    )
  }
}

export default withStyles(styles)(SellerHome);