import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Help';


const useStyles = makeStyles(theme => ({
  typography: {
    padding: theme.spacing(2),
    textAlign: 'center'
  }
}));

export default function InfoButton(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <React.Fragment>
      <IconButton color="inherit" align="center" size="small" onClick={handleClick}>
        <InfoIcon style={{width: 16, height: 16, marginBottom: 3}} />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
      >
        {props.title && <Typography className={classes.typography}>{props.title}</Typography>}
        <Typography className={classes.typography}>{props.text}</Typography>
      </Popover>
    </React.Fragment>
  );
}
