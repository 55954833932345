/**
 * Created by milan on 2019/7/18.
 */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import T from 'i18n-react';
import { DataManager } from '../models';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
const InquiryIcon = require('../../images/inquiries.png');


const useStyles = makeStyles(theme => ({
  box: {
    // padding: theme.spacing(1, 3, 1, 3)
  },
  titleBar: {
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1),
    position: 'relative'
  },
  title: {
    width: '100%',
    minHeight: 48,
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(0.5)
    }
  },
  closeButton: {
    padding: 0
  },
}));


function Wanted(props) {
  const classes = useStyles(props.theme);

  const { product } = props;
  const [open, setOpen] = React.useState(!!props.open);
  const [wanted, setWanted] = React.useState(null);

  const onClick = e => {
    // if (wanted)
    //   setOpen(true);
    // else
    getWanted(e);
  };

  const onClose = e => {
    setOpen(false);
  };

  const getWanted = e => {
    const params = {pid: product.id};

    DataManager.getInstance().show_wanted(product.id, params).then(function (res) {
      if (res.c === 0) {
        const wt = res.d.wanted;
        setWanted(wt);
        setOpen(true);
      }
    })
  };

  const toggleFavor = e => {
    const index = parseInt(e.currentTarget.dataset.tag);
    setOpen(false);
    if (props.onClick)
      props.onClick(wanted, index);
  };

  if (!wanted && open)
    getWanted();

  return (
    <React.Fragment>
      <Box key="material" className={classes.box}>
        <IconButton aria-label="favor" onClick={onClick} style={{padding: 0, marginLeft: 8}}>
          <img style={{width: 24, height: 24}} alt="inquiry" src={InquiryIcon} />
        </IconButton>
        
        <Dialog open={open} onClose={onClose} fullWidth aria-labelledby="dialog-title">
          <div className={classes.titleBar}>
            <Box width={56} />
            <Box className={classes.title}>
              <Typography align="center" variant="h5">{product.title}</Typography>
            </Box>
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
              <CloseIcon style={{width: 48, height: 48}} />
            </IconButton>
          </div>
          <DialogContent>
            {wanted === null ?
              <Box className={classes.title} align="center">
                <CircularProgress size={48} />
              </Box>
              :
              (wanted.length === 0 ?
                <Typography align="center" component="p" className={classes.title}>{T.translate('products.empty_list')}</Typography>
                :
                <List className={classes.list}>
                  {wanted.map((item, index) => (
                    <ListItem key={index} alignItems="center" button divider={true} onClick={onClick} >
                      <ListItemText
                        component="div"
                        primary={`${item.quality} / ${item.size} / ${item.material2 || item.material}`}
                        secondary={`${item.user.display} / ${item.project.title} / ${item.project.address}`}
                      />
                      <ListItemSecondaryAction>
                        <Button
                          data-tag={index}
                          variant='outlined'
                          color='primary'
                          size="small"
                          onClick={toggleFavor}
                        >
                          {T.translate('products.new_item')}
                        </Button>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              )
            }
          </DialogContent>
        </Dialog>
      </Box>
    </React.Fragment>
  );
}


export default Wanted;