/**
 * Created by milan on 2019/7/18.
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/styles/withStyles';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
//import TextField from '@material-ui/core/TextField';
import { TextField } from 'formik-material-ui';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Topbar from '../Topbar';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { Formik, Form, Field } from 'formik';
import T from 'i18n-react';
import { Mode, Event, DataManager } from '../models';


const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white
    }
  },
  paper: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2)
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
});

//const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
const validate = values => {
  let errors = {};
  if (!values.name) {
    errors.name = T.translate('errors.required');
  } else if (!/^[1]([3-9])[0-9]{9}$/.test(values.name)) {
    errors.name = T.translate('errors.invalid_phone');
    //} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.name)) {
    //  errors.name = T.translate('errors.invalid_email');
  }
  if (!values.code) {
    errors.code = T.translate('errors.required');
  } else if (values.code.length < 6) {
    errors.code = T.translate('errors.min_length', {length: 4});
  }
  if (!values.password) {
    errors.password = T.translate('errors.required');
  } else if (values.password.length < 8) {
    errors.password = T.translate('errors.min_length', {length: 8});
  } else if (values.password !== values.confirm) {
    errors.confirm = T.translate('errors.password_not_match');
  }
  if (Object.keys(errors).length)
    return errors;
};

class Password extends Component {
  state = {
    coding: -1
  };
  timer = undefined;

  componentDidMount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  startTimer = () => {
    if (this.timer) {
      clearInterval(this.timer);
    }
    const self = this;
    self.setState({coding: 60});
    self.timer = setInterval(function () {
      self.setState({coding: self.state.coding - 1});
      if (this.timer && self.state.coding < 0) {
        clearInterval(this.timer);
      }
    }, 1000);
  }

  requestCode = (params, actions) => {
    const self = this;
    return DataManager.getInstance().request_code(params).then(function (res) {
      let errors = {};
      let c = res.c;
      if (c !== 0) {
        if (c === 401) {
          errors.code = T.translate('errors.invalid_code');
        }else if (c === 404) {
          errors.name = T.translate('errors.invalid_phone');
        }else{
          errors.code = T.translate('errors.1');
        }
        actions.setErrors(errors);
        return;
      }
      self.setState({sent: true});
      self.startTimer();
    });
  }

  action = (values, actions) => {
    actions.setSubmitting(true);
    const self = this;
    return DataManager.getInstance().reset_password(values).then(function (res) {
      let errors = {};
      let c = res.c;
      if (c !== 0) {
        actions.setSubmitting(false);
        if (c === 401) {
          errors.code = T.translate('errors.invalid_code');
        }else if (c === 404) {
          errors.name = T.translate('errors.invalid_phone');
        }
        if (Object.keys(errors).length) {
          actions.setErrors(errors);
          return;
        }
      }
      if (self.timer) {
        clearInterval(self.timer);
      }
      const to = '/signin';
      if (DataManager.getInstance().mode >= Mode.ADMIN && window.location.pathname !== '/a/') {
        window.location = `/a/#${to}`;
      }else if (DataManager.getInstance().mode === Mode.BUSINESS && window.location.pathname !== '/s/') {
        window.location = `/s/#${to}`;
      }else if (DataManager.getInstance().mode < Mode.BUSINESS && window.location.pathname !== '/') {
        window.location = `/#${to}`;
      }
      DataManager.getInstance().pub(Event.REDIRECT, to);
      DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'success', m: T.translate('notification.updated')});
    });
  };

  render() {
    const { classes } = this.props;
    const currentPath = this.props.location.pathname;
    const self = this;
    return (
      <React.Fragment>
        <CssBaseline />
        <Topbar currentPath={currentPath} />
        <Container component="main" maxWidth="xs">
          <Paper className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              {T.translate("reset_password")}
            </Typography>
            <Formik
              initialValues={{name: '', code: '', password: '', confirm: '' }}
              validate={validate}
              // validateOnChange={true}
              onSubmit={this.action}
            >
              {props => (
                <Form className={classes.form} noValidate>
                  <Field
                    component={TextField}
                    error={!!props.errors.name}
                    helperText={props.errors.name}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label={T.translate("form.phone")}
                    name="name"
                    autoComplete="username"
                  />
                  
                  <Grid container justify="flex-end">
                    <Grid item xs>
                      <Field
                        component={TextField}
                        error={!!props.errors.code}
                        helperText={props.errors.code}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="code"
                        label={T.translate("form.code")}
                        type="text"
                        autoComplete="code"
                      />
                    </Grid>
                    <div style={{width: 20}}></div>
                    <Grid item>
                      <Button
                        type="button"
                        variant="outlined"
                        color="primary"
                        className={classes.submit}
                        disabled={props.isSubmitting || this.state.coding >= 0 || !props.values.name || !!props.errors.name}
                        onClick={e => {
                          e.preventDefault();
                          e.stopPropagation();
                          return self.requestCode({name: props.values.name}, props);
                        }}
                      >
                        {T.translate("form.send_code")}{this.state.coding >= 0 ? `(${this.state.coding})` : ''}
                      </Button>
                    </Grid>
                    {this.state.sent &&
                    <Grid item xs={12}>
                      <Typography color="primary">{T.translate("form.code_sent")}</Typography>
                    </Grid>
                    }
                  </Grid>
                  <Field
                    component={TextField}
                    error={!!props.errors.password}
                    helperText={props.errors.password}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label={T.translate("labels.new_password")}
                    type="password"
                  />
                  <Field
                    component={TextField}
                    error={!!props.errors.confirm}
                    helperText={props.errors.confirm}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="confirm"
                    label={T.translate("labels.confirm_password")}
                    type="password"
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    disabled={props.isSubmitting}
                  >
                    {T.translate("reset_password")}
                  </Button>
                </Form>
              )}
            </Formik>
          </Paper>
        </Container>
      </React.Fragment>
    )
  }
}

export default withRouter(withStyles(styles)(Password));