/**
 * Created by milan on 2019/7/18.
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/styles/withStyles';
//import T from 'i18n-react';
import { DataManager } from '../models/';

//import ProductsTable from './Cart';
import Items from './Items';
import Costs from './Costs';
import Distribution from './Distribution';
import Container from '@material-ui/core/Container';
import ProjectSelector from '../Project/Selector';


const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(1),
    overflowX: 'auto'
  }
});


class Edit extends Component {
  id = -1;
  order = null;

  constructor(props) {
    super(props);
    let id = props.match.params.id;
    if (id) this.id = parseInt(id);
    this.state = {
      subtotal: 0,
      show: this.id < 0,
      costs: 8964
    };

    if (this.id > 0) {
      this.show();
    }
  }

  show = () => {
    let self = this;
    DataManager.getInstance().show_order(this.id, {i: 1}).then(function (res) {
      if (res.c === 0) {
        self.order = res.d.d;
        self.setState({show: true, subtotal: self.order.subtotal || 0});
      }
    });
  };

  sync_order_products = () => {
    const self = this;
    DataManager.getInstance().sync_order_products(this.id, null).then(function (res) {
      if (res.c === 0) {
        self.order = res.d.d;
        self.setState({show: true, subtotal: self.order.subtotal || 0});
      }
    });
  };

  save = (pid, title) => {
    if (!(pid > 0)) return;
    const self = this;
    const params = {pid: pid};
    DataManager.getInstance().update_order(this.order.id, params).then(function (res) {
      if (res.c === 0) {
        self.setState({show: true});
      }
    });
  };

  get_total = (d) => {
    this.order = d;
    this.setState({costs: this.state.costs + 1});
    //this.setState(d);
  };

  render() {
    if (!this.state.show) return <React.Fragment/>;
    const OrderState = DataManager.getInstance().states.orders;

    return (
      <React.Fragment>
        {this.order.state !== OrderState.Ready &&
        <React.Fragment>
          <Items order={this.order} updater={this.get_total} sync={this.sync_order_products} />
          <Costs key={this.state.costs} order={this.order} subtotal={this.state.subtotal} />
        </React.Fragment>
        }
        {this.order.state === OrderState.Ready &&
        <React.Fragment>
          <Distribution order={this.order} updater={this.get_total} sync={this.sync_order_products} />
        </React.Fragment>
        }
        {!(this.order.project_id > 0) &&
        <React.Fragment>
          <Container component="main" maxWidth="xs">
            <ProjectSelector
              create={false}
              uid={this.order.unit_id}
              selected={this.order.project_id || ''}
              onChange={this.save}
            />
          </Container>
        </React.Fragment>
        }

      </React.Fragment>
    )
  }
}


export default withRouter(withStyles(styles)(Edit));