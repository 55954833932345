import React,  { Component } from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import { Link, withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { Link as MaterialLink } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Dropdown from './buttons/Dropdown';
import Box from '@material-ui/core/Box';
import Menu from './Menu';
import { Mode, Event, DataManager } from './models/';
import Unread from './Message/Unread';

const logo = require('../images/logo.png');

const styles = theme => ({
  appBar: {
    //position: 'relative',
    boxShadow: 'none',
    borderBottom: `1px solid ${theme.palette.grey['100']}`,
    backgroundColor: 'white',
    zIndex: theme.zIndex.drawer + 1
  },
  toolbar: theme.mixins.toolbar,
  inline: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  flex: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    }
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  productLogo: {
    display: 'inline-block',
    borderLeft: `1px solid ${theme.palette.grey['A100']}`,
    marginLeft: 32,
    paddingLeft: 24,
    [theme.breakpoints.up('md')]: {
      paddingTop: '1.5em'
    }
  },
  tagline: {
    display: 'inline-block',
    marginLeft: 10
  },
  iconContainer: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block'
    }
  },
  iconButton: {
    float: 'right'
  },
  tabContainer: {
    width: '100%',
    marginLeft: 32,
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  tabItem: {
    paddingTop: 20,
    paddingBottom: 20,
    minWidth: 'auto'
  },
  tabItemRight: {
    float: 'right',
    paddingTop: 20,
    paddingBottom: 20,
    minWidth: 'auto'
  },
  rightItems: {
    marginLeft: 'auto',
    marginRight: -12,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  nested: {
    paddingLeft: theme.spacing(4)
  },
  listSelected: {
    color: theme.palette.primary.main
  },
  listUnselected: {
    color: 'inherit'
  }
});

class Topbar extends Component {

  state = {
    value: 0,
    menuDrawer: false,
    collapse: {},
    name: DataManager.getInstance().storage.name || 'ZhuChao'
  };

  handleCollapse = (e, value) => {
    e.preventDefault();
    let key = e.currentTarget.id;
    let collapse = {...this.state.collapse};
    collapse[key] = !collapse[key];
    this.setState({ collapse: collapse });
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  mobileMenuOpen = (event) => {
    this.setState({ menuDrawer: true });
  };

  mobileMenuClose = (event) => {
    this.setState({ menuDrawer: false });
  };

  handleUpdated = (params) => {
    this.activate();
    this.setState({name: DataManager.getInstance().storage.name});
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    DataManager.getInstance().sub(Event.UPDATED, this.handleUpdated);
  }

  componentWillUnmount() {
    DataManager.getInstance().unsub(Event.UPDATED, this.handleUpdated);
  }

  primary = null;
  auth = null;
  message = null;

  activate = () => {
    this.primary = Menu.primary(DataManager.getInstance().mode);
    this.auth = Menu.auth(DataManager.getInstance().mode);
    this.message = Menu.message();

    let path = this.props.currentPath || '/';
    path = path === '/' ? path : '/' + path.split('/')[1];

    Menu.activate(this.primary, path);
    Menu.activate(this.auth, path);
    Menu.activate(this.message, path);
  };

  selected = (menu) => {
    let index = false;
    for (var i = 0; i < menu.items.length; i++) {
      let item = menu.items[i];
      if (item.active) {
        index = i;
        break;
      }
    }
    return index;
  };

  render() {
    this.activate();
    let primary = this.primary;
    let auth = this.auth;
    const message = this.message;
    let path = this.props.currentPath || '/';
    path = path === '/' ? path : '/' + path.split('/')[1];
    let name = this.props.name ? this.props.name : this.state.name;
    const { theme, classes } = this.props;
    let self = this;
    const itemGenerator = (item, index) => {
      //<ListItem component={item.external ? MaterialLink : Link} href={item.external ? item.pathname : null} to={item.external ? null : {pathname: item.pathname, search: this.props.location.search}} button key={item.title}>
      //  <ListItemText primary={item.title === 'Me' ? name : item.title} />
      //</ListItem>
      let key = 'collapse_' + item.pathname;
      return !!item.items && item.items.length > 0 ?
        <Box key={index}>
          <ListItem id={key} onClick={self.handleCollapse} button>
            <ListItemText primary={item.label === 'Me' ? name : item.title}/>
            {!self.state.collapse[key] ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={!self.state.collapse[key]} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {item.items.map((sub, i) => (
                <ListItem component={Link} to={{pathname: sub.pathname}} key={i} button
                          className={classes.nested}>
                  {path === sub.pathname ?
                    <Box borderBottom={2} borderColor={theme.palette.primary.main}>
                      <ListItemText primary={sub.title} classes={{ primary: classes.listSelected }} />
                    </Box>
                    :
                    <ListItemText primary={sub.title} classes={{ primary: classes.listUnselected }} />
                  }
                </ListItem>
              ))}
            </List>
          </Collapse>
        </Box>
        :
        <ListItem component={Link} to={{pathname: item.pathname}} button key={index}>
          {path === item.pathname ?
            <Box borderBottom={2} borderColor={theme.palette.primary.main}>
              <ListItemText primary={item.title} classes={{ primary: classes.listSelected }} />
            </Box>
            :
            <ListItemText primary={item.title} classes={{ primary: classes.listUnselected }} />
          }
        </ListItem>
    };
    return (
      <React.Fragment>
        <AppBar position="fixed" color="default" className={classes.appBar}>
          <Toolbar>
            <Grid item xs={12} className={classes.flex}>
              <div className={classes.inline}>
                <Typography variant="h6" color="inherit" noWrap>
                  <Link to='/' className={classes.link} >
                    <img width={64} src={logo} style={{backgroundColor: 'transparent'}} alt="" />
                    <span className={classes.tagline}> </span>
                  </Link>
                </Typography>
              </div>
              <React.Fragment>
                <div className={classes.iconContainer}>
                  <IconButton onClick={this.mobileMenuOpen} className={classes.iconButton} color="inherit" aria-label="Menu">
                    <MenuIcon />
                  </IconButton>
                </div>
                <Toolbar className={classes.tabContainer}>
                  <SwipeableDrawer anchor="right" open={this.state.menuDrawer} onClose={this.mobileMenuClose} onOpen={this.mobileMenuOpen}>
                    <List
                      component="nav"
                      aria-labelledby="nested-list-subheader"
                    >
                      {primary.items.map(itemGenerator)}
                      {auth.items.map(itemGenerator)}
                    </List>
                  </SwipeableDrawer>
                  <Tabs
                    value={this.selected(primary)}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={this.handleChange}
                  >
                    {primary.items.map((item, index) => (
                      !!item.items && item.items.length > 0 ?
                        <Tab key={index} component={Dropdown} menu={item} currentPath={path}
                             to={{pathname: item.pathname}} classes={{root: classes.tabItem}} label={item.title} />
                        :
                        (item.external ?
                          <Tab key={index} component={MaterialLink} href={item.pathname} classes={{root: classes.tabItem}} label={item.title} />
                        :
                          <Tab key={index} component={Link} to={{pathname: item.pathname}} classes={{root: classes.tabItem}} label={item.title} />
                        )
                    ))}
                  </Tabs>
                  <div className={classes.rightItems}>
                    {DataManager.getInstance().mode >= Mode.AUTHORIZED &&
                    <Tabs
                      value={this.selected(message)}
                      indicatorColor="primary"
                      textColor="primary"
                      onChange={this.handleChange}
                    >
                      {message.items.map((item, index) => (
                        <Tab key={index} component={Unread} menu={item} to={{pathname: item.pathname}} classes={{root: classes.tabItemRight}} style={{textTransform: 'none'}}
                          label={item.title}
                        />
                      ))}
                    </Tabs>
                    }
                    <Tabs
                      value={this.selected(auth)}
                      indicatorColor="primary"
                      textColor="primary"
                      onChange={this.handleChange}
                    >
                      {auth.items.map((item, index) => (
                        !!item.items && item.items.length > 0 ?
                            <Tab key={index} component={Dropdown} menu={item} currentPath={path}
                           to={{pathname: item.pathname}} classes={{root: classes.tabItemRight}}
                           label={name} style={{textTransform: 'none'}} />
                        :
                          <Tab key={index} component={Link} to={{pathname: item.pathname}}
                          classes={{root: classes.tabItem}} label={item.title} />
                        ))}
                    </Tabs>
                  </div>
                </Toolbar>
              </React.Fragment>
            </Grid>
          </Toolbar>
        </AppBar>
        <div className={classes.toolbar} />
      </React.Fragment>
    );
      //<Grid container spacing={10} alignItems="baseline">
      //</Grid>
  }
}

export default withRouter(withStyles(styles)(withTheme(Topbar)))
