/**
 * Created by milan on 2019/7/18.
 */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import T from 'i18n-react';
import { DataManager } from '../models';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import MaterialCard from '../Material/MaterialCard';


const useStyles = makeStyles(theme => ({
  materials: {
    display: 'inline-block',
    // flexDirection: 'row',
    // justifyContent: 'start',
    // alignItems: 'center',
  },
  box: {
    padding: theme.spacing(1, 3, 1, 3)
  },
  itemButton: {
    margin: theme.spacing(0.5)
  },
  label: {
    fontSize: 13,
    marginRight: theme.spacing(1)
  }
}));


function MaterialsBlock(props) {
  const classes = useStyles(props.theme);

  const { product, itemIndex } = props;
  const [open, setOpen] = React.useState(-1);
  const [matIndex, setMatIndex] = React.useState(0);
  const item = itemIndex >= 0 ? product.items[itemIndex] : undefined;
  let codes;
  let titles;
  if (product.customizer && product.customizer.length > 0){
    codes = [];
    titles = [];
    for (var i = 0; i < product.customized.length; ++i) {
      const mat = product.customized[i];
      if (mat) {
        codes.push(mat.identifier);
        titles.push(mat.title);
      }
    }
  }else if (item && item.material1 && item.material1.length > 0){
    codes = item.material1.split(',');
    titles = item.material2.split(', ');
    if (!item.customized) item.customized = codes.slice().fill(undefined);
  }else{
    return <React.Fragment/>;
  }

  const onClose = mat => {
    if (product.customizer && product.customizer.length > 0){
      product.customized[open] = mat;
      codes[open] = mat.identifier;
      titles[open] = mat.title;
      props.onChange(mat);
    }
    setOpen(-1);
  };

  const onClick = e => {
    const index = parseInt(e.currentTarget.dataset.tag);
    showMaterial(index);
  };

  const showMaterial = (index) => {
    const code = codes[index];
    DataManager.getInstance().show_material_by_code(code, null).then(function (res) {
      if (res.c === 0) {
        const material = res.d.mat;
        product.customized[index] = material;
        let i;
        for (i = 0; i < material.items.length; ++i) {
          const item = material.items[i];
          const idf = item.identifier && item.identifier.length <= 3 ? `${material.identifier}${item.identifier}` : material.identifier;
          if (idf === code) {
            setMatIndex(i);
            break;
          }
        }
        setOpen(index);
      }
    });
  };

  return (
    <React.Fragment>
      <Box key="material" className={classes.box}>
        <Typography component="span" className={classes.label}>{T.translate('products.mats_details')}: </Typography>
        <Box className={classes.materials}>
        {codes.map((code, index) => (
          <Button key={code} data-tag={index} variant="outlined" color="secondary" size="small" className={classes.itemButton} onClick={onClick}>
            {titles[index]}
          </Button>
        ))}
        </Box>
        {open >= 0 &&
        <MaterialCard card={false} index={matIndex} open={open >= 0} onClose={onClose} material={product.customized[open]} />
        }
      </Box>
    </React.Fragment>
  );
}


export default MaterialsBlock;