import React,  { Component } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Paper from '@material-ui/core/Paper';

import { Event, DataManager } from '../models/';
import T from 'i18n-react';
import Pagination from '../common/Pagination';
import AlertDialog from '../dialogs/AlertDialog';
import ResponsiveButton from '../buttons/ResponsiveButton';

import CircularProgress from '@material-ui/core/CircularProgress';
import Avatar from '@material-ui/core/Avatar';


const styles = theme => ({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
    marginTop: theme.spacing(2),
    padding: theme.spacing(1)
  },
  center: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  paper: {
    width: '100%',
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  list: {
    width: '100%'
  },
  content: {
    fontSize: 24,
    margin: theme.spacing(2)
  },
  pagination: {
    marginTop: theme.spacing(5)
  },

  leading: {
    width: 54,
    minWidth: 54,
    maxWidth: 54,
    margin: theme.spacing(1)
  },
  label: {
    lineHeight: 2,
    fontSize: 14,
    marginLeft: theme.spacing(1)
  },
  importantInfo: {
    lineHeight: 2,
    fontSize: 18
  },
  cell: {
    backgroundColor: 'white',
    paddingLeft: 0
  },
  state_new: {
    backgroundColor: 'red'
  },
  state_read: {
    backgroundColor: theme.palette.primary.main
  }
});


class Products extends Component {
  state = {total: 1, page: 1, per: 0, selected: -1, filter: 0, requesting: false};
  messages = null;

  constructor(props) {
    super(props);
    const search = props.location.search.substring(1).split('.');
    if (search.length > 1) {
      const p = search[0];
      const page = parseInt(p);
      if (page > 0) this.state.page = page;
      const f = search[1];
      const filter = parseInt(f);
      if (filter > 0) this.state.filter = filter;
    }
    this.state.requesting = true;
    this.list(this.state.page, this.state.filter);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.location.search !== nextProps.location.search) {
      const search = nextProps.location.search.substring(1).split('.');
      if (search.length > 1) {
        const p = search[0];
        const page = parseInt(p);
        if (page > 0) nextState.page = page;
        const f = search[1];
        const filter = parseInt(f);
        if (filter >= 0) nextState.filter = filter;
      }
      this.list(nextState.page, nextState.filter);
      return true;
    }
    return true;
  }

  read_all = () => {
    const self = this;
    self.setState({requesting: true});
    DataManager.getInstance().read_all_messages(null).then(function (res) {
      if (res.c === 0) {
        const messages = self.messages;
        let i;
        for (i = 0; i < messages.length; ++i) {
          const m = messages[i];
          m.state = 2;
        }
        self.setState({requesting: false});
      }
    });
    //DataManager.getInstance().pub(Event.REDIRECT, item.url);
  };

  selected = (index) => {
    const item = this.messages[index];
    DataManager.getInstance().read_message(item.id, null).then(function (res) {
      switch (item.type) {
        case 100:
          DataManager.getInstance().pub(Event.REDIRECT, `/projects/s/${item.primary_id}/tm`);
          break;
        case 2:
          DataManager.getInstance().pub(Event.REDIRECT, `/orders/trackers/${item.primary_id}/${item.secondary_id}`);
          break;
        default:
          DataManager.getInstance().pub(Event.REDIRECT, item.url);
          break
      }
    });
    //DataManager.getInstance().pub(Event.REDIRECT, item.url);
  };

  handle_pagination = (offset) => {
    const page = 1 + offset / this.state.per;
    DataManager.getInstance().pub(Event.REDIRECT, {pathname: this.props.location.pathname, search: `${page}.${this.state.filter}`});
  };

  close_alert = (e) => {
    this.setState({selected: -1});
  };

  render() {
    if (!this.messages)
      return <div></div>;

    const { classes } = this.props;

    return (
      <React.Fragment>
        <div className={classes.root}>
          {this.table()}
        </div>

        <AlertDialog
          open={false}
          onClose={this.close_alert}
          title={T.translate('dialogs.no_offer')}
          desc={T.translate('dialogs.please_wait')}
          //left={T.translate('buttons.cancel')}
          right={T.translate('buttons.ok')}
        />
      </React.Fragment>
    );
  }

  table = () => {
    const { classes } = this.props;
    const messages = this.messages;
    const self = this;

    return (
      <Grid alignItems="center" justify="center" container>
        <Grid item xs={12} sm={12} className={classes.center}>
          <ResponsiveButton color="primary" variant="contained" align="center" onClick={this.read_all} disabled={this.state.requesting}>
            {T.translate('messages.read_all')}
          </ResponsiveButton>
        </Grid>
        <Grid item xs={12} sm={8} md={8} lg={6} xl={6}>
          <Paper className={classes.paper}>
            {this.state.requesting ?
              <CircularProgress size={48} />
              :
              (messages.length === 0 ?
                <Typography className={classes.content}>{T.translate('messages.empty_list')}</Typography>
                :
                <List className={classes.list}>
                  {messages.map((message, index) => (
                    self.item_cell(message, index, index !== messages.length - 1)
                  ))}
                </List>
              )
            }
          </Paper>
          {!this.state.requesting && this.state.per > 0 && this.state.total > this.state.per &&
          <Pagination
            offset={this.state.per * (this.state.page - 1)}
            limit={this.state.per}
            total={this.state.total}
            onClick={this.handle_pagination}
          />
          }
        </Grid>
      </Grid>
    );
  };

  item_cell(item, index, divider) {
    const { classes } = this.props;

    const self = this;
    return (
      <ListItem className={classes.cell} key={item.id} divider={divider} alignItems="center" button onClick={() => self.selected(index)} >
        <ListItemAvatar className={classes.leading}>
          <React.Fragment>
            {item.state === 1 ?
            <Avatar className={classes.state_new}>N</Avatar>
              :
            <Avatar className={classes.state_read}>R</Avatar>
            }
          </React.Fragment>
        </ListItemAvatar>
        <ListItemText
          primary={
            <Box>
              <Box style={{alignItems: 'center'}}>
                <Typography component="span" style={{paddingLeft: 6, fontSize: 15, whiteSpace: 'pre-line'}}>{T.translate(`messages.types.${item.type}`, {t: item.title})}</Typography>
              </Box>
            </Box>
            }
        />
      </ListItem>
    )
  }

  list = (page, f) => {
    const self = this;
    const params = {page: page, f: f};
    return DataManager.getInstance().list_messages(params).then(function (res) {
      if (res.c === 0) {
        let d = res.d;
        self.messages = d.messages;
        self.setState({page: page, total: d.mc, per: d.p, requesting: false});
      }
    })
  };
}


export default withStyles(styles)(Products);