/**
 * Created by milan on 2019/7/18.
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/styles/withStyles';

import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Add from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import { Link as ExternalLink } from '@material-ui/core';
import T from 'i18n-react';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ButtonBase from '@material-ui/core/ButtonBase';

const imageSize = 200;

const styles = theme => ({
  root: {
    minHeight: imageSize,
    maxWidth: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'start',
    justifyContent: 'start',
    overflow: 'hidden'
  },
  gridList: {
    flexWrap: 'nowrap',
    //width: '100%',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)'
  },
  buttons: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    height: imageSize,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'space-between',
    justifyContent: 'space-between'
  },
  button: {
    width: '100%',
    height: 50
  },
  paper: {
    width: imageSize,
    height: imageSize,
    maxWidth: imageSize,
    maxHeight: imageSize,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  img: {
    maxWidth: '100%',
    maxHeight: '100%',
    verticalAlign: 'middle'
  }
});


class Uploader extends Component {
  remove = (e) => {
    console.log(e);
  };

  showFile = (f, index) => {
    //<Button style={{padding: 0}} component={ExternalLink} className={classes.inlineLink} href={src}>
    //  {display}
    //</Button>
    let fn;
    let ft;
    let src = f;
    if (f instanceof File) {
      fn = f.name;
      const _ft = f.type.split('/');
      ft = _ft[_ft.length - 1];
      src = URL.createObjectURL(f);
    }else{
      fn = f.toLowerCase();
      ft = fn.split('.').pop();
      fn = decodeURIComponent(fn.split('/').pop().split('#')[0].split('?')[0]);
    }
    const { tag, classes } = this.props;
    let isImage = ft === 'png' || ft === 'jpg' || ft === 'jpeg';
    const display = isImage ?
      <Paper className={classes.paper}>
        <img className={classes.img} src={src} alt={src} />
      </Paper>
      :
      <div style={{width: imageSize, height: imageSize, border: '1px solid grey', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <Typography align="center">
          {fn}
        </Typography>
      </div>;
    return (
      <GridListTile key={index} rows={1} cols={1} style={{marginRight: 10, minWidth: imageSize, minHeight: imageSize, width: imageSize, height: imageSize}}>
        <DropdownButton
          actions={
            <React.Fragment>
              {this.props.onDelete &&
              <MenuItem onClick={ () => {this.props.onDelete(f, tag)} }>{T.translate('buttons.delete')}</MenuItem>
              }
              {src === f && f.indexOf('http') >= 0 &&
              <MenuItem component={ExternalLink} href={src}>{T.translate('buttons.download')}</MenuItem>
              }
            </React.Fragment>
          }
        >
          {display}
        </DropdownButton>
        }
      </GridListTile>
    );
  };

  render() {
    const { classes, files, onAdd } = this.props;
    return (
      <div className={classes.root}>
        <GridList className={classes.gridList} spacing={0} cellHeight={imageSize} rows={1}>
          {onAdd &&
          <GridListTile style={{width: 10 + imageSize / 2, height: imageSize}}>
            <div style={{width: imageSize / 2, height: imageSize, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              <Button
                size="medium"
                color="primary"
                data-tag={this.props.tag}
                onClick={onAdd}
                style={{marginRight: 3, width: imageSize / 2, height: imageSize / 2, border: '1px solid grey'}}
              >
                <Add style={{width: imageSize / 4, height: imageSize / 4}} />
              </Button>
            </div>
          </GridListTile>
          }
          {files.map(this.showFile)}
        </GridList>
      </div>
    )
  }
}




function DropdownButton(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const id = `action-menu-${props.index}`;
  return (
    <React.Fragment>
      <Button style={{padding: 0}} aria-controls={id} aria-haspopup="true" color="primary" aria-label={props.index} data-tag={props.index} onClick={handleClick}>
        {props.children}
      </Button>
      <Menu
        id={id}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <ButtonBase style={{display: 'flex', flexDirection: 'column'}} onClick={handleClose}>{props.actions}</ButtonBase>
      </Menu>
    </React.Fragment>
  );
}


export default withRouter(withStyles(styles)(Uploader));