import React,  { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import withStyles from '@material-ui/styles/withStyles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Topbar from '../Topbar';
//import T from 'i18n-react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';


const styles = theme => ({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
    marginTop: theme.spacing(2)
  }
});


class Code extends Component {
  //constructor(props) {
  //  super(props);
  //}

  render() {
    const { classes } = this.props;
    const currentPath = this.props.location.pathname;
    const path = '/' + this.props.match.path.split('/')[1];
    return (
      <React.Fragment>
        <CssBaseline />
        <Topbar currentPath={currentPath} />
        <div className={classes.root}>
          <Switch>
            <Route path={`${path}/m/:code?`} component={ Show } />
            <Route path={`${path}/:code?`} component={ Show } />
          </Switch>
        </div>
      </React.Fragment>
    )
  }
}


class Show extends Component {
  render() {
    return (
      <React.Fragment>
        <Box>
          <Typography>材质码 {this.props.match.params.code}</Typography>
        </Box>
      </React.Fragment>
    )
  }
}


export default withStyles(styles)(Code);