import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/styles/withStyles';
import Carousel from '../common/Carousel';
import ImagesDialog from '../dialogs/ImagesDialog';


const styles = theme => ({
  container: {
    minWidth: 320,
    maxWidth: 768
  },
  bottomMargin: {
    marginBottom: theme.spacing(2)
  },
  player: {
    //minHeight: '40vh',
    //maxHeight: '40vh',
    maxWidth: '100%',
    //maxHeight: '100%',
    width: '100%',
    height: '40vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  img: {
    // height: '100%',
    maxWidth: '100%',
    maxHeight: '100%',
    verticalAlign: 'middle'
  }
});

class ImagesCarousel extends Component {

  state = {
    autoplay: true,
    activeStep: 0
  };

  handleNext = () => {
    this.setState(prevState => ({
      activeStep: prevState.activeStep + 1,
      autoplay: false
    }));
  };

  handleBack = () => {
    this.setState(prevState => ({
      activeStep: prevState.activeStep - 1,
      autoplay: false
    }));
  };

  handleStepChange = activeStep => {
    this.setState({ activeStep });
  };

  componentDidUpdate(prevProps) {
    if (this.props.id !== prevProps.id)
      this.setState({activeStep: 0});
  }

  render() {
    const { classes, images, clickOpen } = this.props;
    const maxSteps = images.length;
    //const { activeStep } = this.state;
    const self = this;

    return (
      <div className={classes.container}>
        {clickOpen &&
          <ImagesDialog
            maxWidth="xl"
            images={images}
            active={this.state.showImages}
            open={this.state.showImages >= 0}
            onClose={_ => self.setState({showImages: -1})}
          />
        }
        {maxSteps > 1 ?
          <React.Fragment>
            <Carousel
              animation={'slide'}
              interval={5000}
              indicators={true}
              autoPlay={true}
              onClick={clickOpen ? e => self.setState({showImages: parseInt(e.currentTarget.dataset.tag)}) : undefined}
            >
              {images.map((image, index) => (
                <div key={index} className={classes.player}>
                  <img className={classes.img} src={image} alt={image} />
                </div>
              ))}
            </Carousel>
          </React.Fragment>
          :
          <div className={classes.bottomMargin} onClick={clickOpen ? e => self.setState({showImages: 0}) : undefined}>
            {images.map((image, index) => (
              <div key={index} className={classes.player}>
                <img className={classes.img} src={image} alt={image} />
              </div>
            ))}
          </div>
        }
      </div>
    )
  }
}

export default withRouter(withStyles(styles)(ImagesCarousel));
