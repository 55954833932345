import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import T from 'i18n-react';
import { DataManager } from '../models/';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Image from '../common/Image';

import { priceDisplay, Currencies } from '../common/Constants';
import QuoteHistory from '../Quote/QuoteHistory';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputAdornment from '@material-ui/core/InputAdornment';


const useStyles = makeStyles(theme => ({
  titleBlock: {
    padding: theme.spacing(0, 0, 0, 1),
    minHeight: 130,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'space-between',
    justifyContent: 'space-between'
  },
  rightBlock: {
    padding: theme.spacing(0, 0, 0, 1),
    minHeight: 130,
    minWidth: 110,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'space-between'
  },
  label: {
    fontSize: 14,
    verticalAlign: 'bottom',
    display: 'block',
    wordWrap: 'break-word',
    [theme.breakpoints.down('xs')]: {
      maxWidth: 130
    }
  },
  boldLabel: {
    fontSize: 20,
    verticalAlign: 'bottom',
    //color: theme.palette.primary.main,
    [theme.breakpoints.down('xs')]: {
      maxWidth: 36
    }
  },
  cell: {
    backgroundColor: 'white',
    padding: theme.spacing(2, 1, 2, 1)
  }
}));


export default function Cell(props) {
  const { item, onClick, onImageClick, history, children } = props;
  const classes = useStyles(props.theme);
  //const is_seller = DataManager.getInstance().mode === Mode.BUSINESS;

  return (
    <React.Fragment>
      <ListItem className={classes.cell} key={item.id} alignItems="center" button={!!onClick} onClick={onClick} >
        <Image
          style={{ height: 130, width: 130, maxWidth: 130, maxHeight: 130, minWidth: 130, minHeight: 130 }}
          alt={item.media[0]}
          src={item.media[0]}
          title={onImageClick ? T.translate('trackers.n_images', {c: item.media.length}) : null}
          onClick={onImageClick}
        />
        <ListItemText
          primary={
            <Box className={classes.titleBlock}>
              <Box style={{display: 'flex', alignItems: 'center'}}>
                <Typography component="span" className={classes.label}>{item.item_type} / {item.title}</Typography>
              </Box>
              <Box>
                <Typography component="p" className={classes.label}>{item.material}</Typography>
              </Box>
              <Box>
                <Typography component="p" className={classes.label}>{item.size}</Typography>
              </Box>
            </Box>
          }
        />
        <Box className={classes.rightBlock}>
          <Box style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', verticalAlign: 'bottom'}}>
            <React.Fragment>
              <Typography component="span" className={classes.boldLabel}>{item.quantity}</Typography>
              <Typography component="span" className={classes.label}>
                &nbsp;{T.translate('orders.unit')}
              </Typography>
            </React.Fragment>
          </Box>
          {item.price &&
          <Box style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', verticalAlign: 'bottom'}}>
            <Typography component="span" className={classes.label}>{T.translate('quotation.unit_price')}: &nbsp;</Typography>
            <Typography component="span" className={classes.boldLabel}>{priceDisplay(item.price, item.currency)}</Typography>
          </Box>
          }
          {children ||
            <React.Fragment>
              {props.onSave ?
              <ExternalDialog item={item} value={item.price} onSave={props.onSave} deal_id={props.deal_id} history={history} disabled={props.disabled} />
                :
              <Box height={24}/>
              }
            </React.Fragment>
          }
        </Box>
      </ListItem>
      {item.memoranda &&
      <Box className={classes.cell}>
        <Typography component="p" variant="caption" color="textSecondary">{T.translate('orders.memo')}: {item.memoranda}</Typography>
      </Box>
      }
      {item.state === 13 && !item.memo &&
      <Box className={classes.cell}>
        <Typography component="p" variant="caption" style={{color: 'red'}}>{T.translate('materials.customized')}</Typography>
      </Box>
      }
      {item.memo &&
      <Box className={classes.cell}>
        <Typography component="p" variant="caption" >{T.translate('trackers.my_memo')}: {item.memo}</Typography>
      </Box>
      }
    </React.Fragment>
  );
}


function ExternalDialog(props) {
  const history = props.history;
  const item = props.item;
  const [requesting, setRequesting] = React.useState(false);
  const [openHistory, setOpenHistory] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(props.value || '');
  const [memo, setMemo] = React.useState(item.memo || '');
  React.useEffect(() => {
    setValue(props.value || '');
    setMemo(item.memo || '');
  }, [props.value, item.memo]);


  const quote_history = () => {
    const key = `${item.product_id}.${item.item_id}`;
    if (history[key]) {
      if (history[key].length > 0)
        setOpenHistory(true);
      else
        setOpen(true);
      return;
    }
    if (requesting) return;
    setRequesting(true);
    const params = {did: props.deal_id, pid: item.product_id, iid: item.item_id};
    DataManager.getInstance().show_quote_history(params).then(function (res) {
      setRequesting(false);
      if (res.c === 0) {
        history[key] = res.d.items;
        if (history[key].length > 0)
          setOpenHistory(true);
        else
          setOpen(true);
      }
    });
  }

  function handleClickOpen(e) {
    if (item.price)
      setOpen(true);
    else
      quote_history();
  }

  function handleUse(quote) {
    setOpenHistory(false);
    if (quote) {
      props.onSave(item, Number(quote.price), quote.memo);
    }else{
      setOpen(true);
    }
  }

  function handleClose(e) {
    setOpen(false);
    if (e.currentTarget.dataset.tag === '2') {
      props.onSave(item, -1, memo);
    }else if (e.currentTarget.dataset.tag === '1') {
      props.onSave(item, Number(value), memo);
    }else{
      setValue(props.value || '');
    }
  }

  const handleChange = e => {
    const v1 = e.target.value;
    if (!v1) {
      setValue('');
      return;
    }
    const v2 = Number(v1);
    if (v2 >= 0)
      setValue(v1);
  };

  const handleMemo = e => {
    setMemo(e.target.value || '');
  };

  let title = '';
  let i;
  for (i = 0; i < Currencies.length; ++i) {
    const c = Currencies[i];
    if (c.k === item.currency) {
      title = T.translate(`currency.${c.k}`);
      break;
    }
  }
  return (
    <div style={{display: 'inline-block'}} onClick={(e) => e.stopPropagation()}>
      <Button variant="outlined" data-tag={0} color={props.value ? 'secondary' : 'primary'} size="small" disabled={props.disabled} onClick={handleClickOpen}>
        {T.translate(props.value ? 'quotation.edit' : 'quotation.quote')}
      </Button>

      <QuoteHistory
        mode={1}
        open={openHistory}
        product={item}
        history={history}
        onClose={() => setOpenHistory(false)}
        onUse={handleUse}
      />

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth='xs'
        fullWidth
      >
        <DialogTitle id="form-dialog-title">{item.title} {T.translate('quotation.quote')}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            multiline={false}
            InputProps={{
              startAdornment: <InputAdornment style={{minWidth: 72}} position="start">{title}</InputAdornment>
            }}
            //inputProps={{
            //  type: "number"
            //}}
            //type="number"
            name="price"
            value={value}
            onChange={handleChange}
            label={T.translate('quotation.quote_unit_price')}
            fullWidth
          />
          <TextField
            margin="dense"
            multiline={true}
            inputProps={{
              maxLength: 75
            }}
            type="text"
            name="memo"
            value={memo}
            onChange={handleMemo}
            label={T.translate('quotation.memo')}
            placeholder={T.translate('form.optional')}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          {props.value &&
          <Button data-tag={2} onClick={handleClose} style={{color: 'red', left: 16, position: 'absolute'}}>
            {T.translate('quotation.recall')}
          </Button>
          }
          <Button data-tag={0} onClick={handleClose}>
            {T.translate('buttons.cancel')}
          </Button>
          <Button data-tag={1} onClick={handleClose} color="primary">
            {T.translate('buttons.submit')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
