import React,  { Component } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import Paper from '@material-ui/core/Box';
import { SellerRoles, CustomerRoles } from '../common/Constants';
import { Event, Mode, DataManager } from '../models';

import T from 'i18n-react';
import CircularProgress from '@material-ui/core/CircularProgress';


import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';

import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputAdornment from '@material-ui/core/InputAdornment';


const styles = theme => ({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
    marginTop: theme.spacing(2),
    padding: theme.spacing(1)
  },
  paper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  list: {
    width: '100%'
  },

  panel: {
    position: 'fixed',
    height: 50,
    bottom: 6,
    zIndex: 101,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      height: 100,
      bottom: 6
    }
  },
  panelBody: {
    // border: `0.5px solid ${theme.palette.primary.main}`,
    // backgroundColor: 'white',
    margin: theme.spacing(0, 0.5, 0, 0.5),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
});


class Roles extends Component {
  state = {requesting: false, changed: false};
  unit_id = 0;

  constructor(props) {
    super(props);
    this.state.requesting = true;
    this.unit_id = props.unit_id;
    this.show();
  }

  render() {
    if (!this.roleNames) {
      this.roleNames = {};
      const is_seller = DataManager.getInstance().mode >= Mode.BUSINESS;
      const roles = is_seller ? SellerRoles : CustomerRoles;
      for (var k in roles) {
        if (roles.hasOwnProperty(k)) {
          this.roleNames[k] = T.translate(`users.role_${roles[k]}`);
        }
      }
    }

    if (!this.roles) return <div></div>;

    const { classes } = this.props;
    const roles = this.roles;
    const self = this;
    
    return (
      <React.Fragment>
        <div className={classes.root}>
          <Grid alignItems="center" justify="center" container>
            <Grid item xs={12} />
            <Grid item xs={12} sm={8} md={8} lg={6} xl={6}>
              <Paper className={classes.paper}>
                <List className={classes.list}>
                  {roles.map((role, index) => (
                    !this.roleNames[role] ? undefined :
                    <Cell
                      key={role}
                      unit={this.unit}
                      name={this.roleNames[role]}
                      role={role}
                      configs={this.configs[index]}
                      onChange={() => self.setState({changed: true})}
                    />
                  ))}
                </List>
              </Paper>
            </Grid>
          
            <Box className={classes.panel}>
              <Grid item xs={12} sm={8} md={8} lg={6} xl={6}>
                <Card className={classes.panelBody}>
                {this.state.requesting ?
                  <CircularProgress size={48} />
                  :
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={!this.state.changed || this.state.requesting}
                    style={{margin: 8, width: '100%'}}
                    onClick={this.save}
                  >
                    {T.translate('buttons.save')}
                  </Button>
                }
                </Card>
              </Grid>
            </Box>
          </Grid>
        </div>
      </React.Fragment>
    );
  };

  save = () => {
    const self = this;
    self.setState({requesting: true});
    const params = {uid: this.unit_id, cfgs: self.configs};
    return DataManager.getInstance().unit_config(params, true).then(function (res) {
      if (res.c === 0) {
        // const d = res.d;
        // self.roles = d.roles;
        // self.configs = d.cfgs;
        DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'success', m: T.translate('notification.success')});
        self.setState({requesting: false, changed: false});
      }else{
        DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'error', m: T.translate('errors.1')});
      }
    })
  };

  show = () => {
    const self = this;
    const params = {uid: this.unit_id};
    return DataManager.getInstance().unit_config(params).then(function (res) {
      if (res.c === 0) {
        const d = res.d;
        self.unit = d.unit;
        self.roles = d.roles;
        self.configs = d.cfgs;
        self.setState({requesting: false});
      }
    })
  };
}


const useStyles = makeStyles(theme => ({
  card: {
    marginBottom: theme.spacing(2)
  }
}));

function Cell(props) {
  const { unit, name, role, configs, onChange } = props;
  const classes = useStyles(props.theme);

  const [permissions, setPermissions] = React.useState(configs.pms);

  return (
    <React.Fragment key={role}>
      <Card className={classes.card}>
        <CardContent>
          <Box style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
            <Typography component="span" variant="h5">{name}</Typography>
          </Box>
          {configs.m1 && unit.type < 20 &&
          <Box style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
            <Typography variant="subtitle1" align="center">
              {T.translate('units.extra')}
            </Typography>
            <Typography variant="subtitle1" align="center">: </Typography>
            <EditableMultiplier
              onSave={v => {
                configs.m1 = v;
                onChange();
              }}
              value={configs.m1}
              title={`${name}${T.translate('s')}${T.translate('units.extra')}`}
            />
            {/* <Box width={24}/>
            <Typography variant="subtitle1" align="center">
              {T.translate('orders.price2')}{T.translate('units.extra')}
            </Typography>
            <Typography variant="subtitle1" align="center">: </Typography>
            <EditableMultiplier
              onSave={v => {
                configs.m2 = v;
                onChange();
              }}
              value={configs.m2}
              title={`${name}${T.translate('s')}${T.translate('orders.price2')}${T.translate('units.extra')}`}
            /> */}
          </Box>
          }
          <Box style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
            <Typography component="p" variant="subtitle1" align="center">
              {role === 30 ? T.translate('permissions.role_grande') : `${T.translate('permissions.role_permissions')}: `}
            </Typography>
            {role !== 30 && permissions.map((pm, index) => (
              <div key={pm.k} style={{padding: 8, display: 'inline-flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start'}}>
                <Checkbox
                  name={pm.k}
                  color="primary"
                  checked={pm.v === 1}
                  onChange={e => {
                    const ps = permissions.slice();
                    const p = {...ps[index]};
                    p.v = e.target.checked ? 1 : 0;
                    ps[index] = p;
                    setPermissions(ps);
                    configs.pms = ps;
                    onChange();
                  }}
                />
                <Box>
                  <Typography component="p" variant="caption" align="center">
                    {T.translate(`permissions.${pm.k}`)}
                  </Typography>
                </Box>
            </div>
            ))}
          </Box>
        </CardContent>
      </Card>
    </React.Fragment>
  )
}


function EditableMultiplier(props) {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(props.value || '');
  React.useEffect(() => {
    setValue(props.value || '');
  }, [props.value]);

  function handleClickOpen(e) {
    setOpen(true);
  }

  function handleClose(e) {
    if (e.currentTarget.dataset.tag === '1') {
      const price = parseInt(value);
      if (!(price >= 100 && price <= 200)) {
        DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'error', m: '价格系数必须是 100 - 200 之间的整数'});
        return;
      }
      setOpen(false);
      setValue(`${price}`);
      props.onSave(price);
    }else{
      setOpen(false);
      setValue(props.value || '');
    }
  }

  const handleChange = e => {
    const v1 = e.target.value;
    if (!v1) {
      setValue('');
      return;
    }
    const v2 = Number(v1);
    if (v2 >= 0)
      setValue(v1);
  };

  return (
    <div style={{display: 'inline-block'}}>
      <Button color="primary" align="center" onClick={handleClickOpen}>
        <Typography variant="subtitle1">{value}%</Typography>
      </Button>
      {open &&
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth='xs'
        fullWidth
      >
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent>
          <Typography component="p" variant="caption">
            显示价格系数指该团队内所有该角色的用户在系统内任意位置查看产品价格时, 价格将显示为「原价格 * 价格系数」, 
            如原价格为 1000 人民币, 设置系数为 120% 时, 该角色的用户看到的价格将为 1200 人民币.
          </Typography>
          {/* <Typography component="p" variant="caption">出厂价不能超过指导零售价, 如果出厂价乘以其价格系数后超过了指导零售价, 则出厂价将强制显示为指导零售价.</Typography> */}
          <Typography component="p" variant="caption">价格系数必须是 100 - 200 之间的整数, 即最低显示原价格, 最高显示原价格的 2 倍.</Typography>
          <TextField
            autoFocus
            fullWidth
            margin="dense"
            InputProps={{endAdornment: <InputAdornment position="end">%</InputAdornment>}}
            name="price"
            value={value}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button data-tag={0} onClick={handleClose}>
            {T.translate('buttons.cancel')}
          </Button>
          <Button data-tag={1} onClick={handleClose} color="primary">
            {T.translate('buttons.yes')}
          </Button>
        </DialogActions>
      </Dialog>
      }
    </div>
  );
}


export default withStyles(styles)(Roles);