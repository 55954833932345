import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import T from 'i18n-react';
import { Event, DataManager } from '../models/';
import Image from '../common/Image';
import MediaSet from './MediaSet';
import { M_ITEMS, M_REQUIRED } from '../common/Constants';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Popover from '@material-ui/core/Popover';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckedIcon from '@material-ui/icons/CheckCircleOutline';

import Collapse from '@material-ui/core/Collapse';
import clsx from 'clsx';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import CheckIcon from '@material-ui/icons/CheckBox';
import UncheckIcon from '@material-ui/icons/CheckBoxOutlineBlank';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';


const useStyles = makeStyles(theme => ({
  cell: {
    backgroundColor: 'white',
    padding: theme.spacing(1, 0, 0, 0),
    minHeight: 64
  },
  content: {
    marginRight: 48,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  prop: {
    width: 110,
    minWidth: 48,
    minHeight: 48,
    maxHeight: 64,
    margin: theme.spacing(0, 1, 2, 0),
    wordBreak: 'break-all',
    textOverflow: 'ellipsis',
    backgroundColor: '#fafafa'
  },
  required_prop: {
    width: 110,
    minWidth: 48,
    minHeight: 48,
    maxHeight: 64,
    margin: theme.spacing(0, 1, 2, 0),
    wordBreak: 'break-all',
    backgroundColor: '#f2f2f2'
  },
  error_prop: {
    width: 110,
    minWidth: 48,
    minHeight: 48,
    maxHeight: 64,
    margin: theme.spacing(0, 1, 2, 0),
    wordBreak: 'break-all',
    backgroundColor: '#ffb3b3'
  },
  main_image: {
    height: 130, width: 130, maxWidth: 130, maxHeight: 130, minWidth: 130, minHeight: 130,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  second_image: {
    width: 130, maxWidth: 130, minWidth: 130, maxHeight: 130, minHeight: 64,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  ellipsis: {
    overflow: 'hidden', 
    textOverflow: 'ellipsis', 
    display: '-webkit-box', 
    'WebkitBoxOrient': 'vertical', 
    'WebkitLineClamp': '2'
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },

  image: {
    // cursor: 'pointer',
    minWidth: '100%',
    minHeight: '100%',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  img: {
    width: '100%',
    objectFit: 'cover',
    minWidth: '100%',
    minHeight: '100%',
    verticalAlign: 'middle'
  }
}));


const M_ITEMS_REQUIRED = [  // 's_id', 'currency', 
  's_name', 'wholesale', 'cs'
];


function CustomizerCell(props) {
  const { screener, onChange } = props;
  const [data, setData] = React.useState(screener);

  const keys = ['kingdom', 'quality'];
  const candidates = {};
  let labels = DataManager.getInstance().storage.materials || {};
  const kingdom = undefined;
  let ii;
  for (ii = 0; ii < keys.length; ++ii) {
    const key = keys[ii];
    const options = labels ? labels[key] : undefined;
    let filters;
    if (!options || options instanceof Array) {
      filters = options;
    }else if (kingdom && options[kingdom] && options[kingdom] instanceof Array && options[kingdom].length > 0) {
      filters = options[kingdom];
    }else{
      filters = [];
      for (var k in options) {
        if (options.hasOwnProperty(k)) {
          const v = options[k];
          if (v instanceof Array) {
            let i;
            for (i = 0; i < v.length; ++i) {
              const f = v[i];
              if (f && filters.indexOf(f) < 0)
                filters.push(f);
            }
          }
        }
      }
    }
    candidates[key] = filters;
  }

  const onToggle = (e) => {
    const k = e.currentTarget.dataset.key;
    const v = e.currentTarget.dataset.value;
    if (!screener[k]) screener[k] = [];
    const index = screener[k].indexOf(v);
    if (index < 0)
      screener[k].push(v);
    else
      screener[k].splice(index, 1);
    setData({...screener});
    if (onChange) onChange();
  };

  const items = [];
  for (ii = 0; ii < keys.length; ++ii) {
    const key = keys[ii];
    const filters = candidates[key];
    const options = [];
    let i;
    for (i = 0; i < filters.length; ++i) {
      const f = filters[i];
      const option = <Box key={f} style={{padding: 8, display: 'inline-flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start'}}>
        <Typography component="p" variant="caption" align="center">{f}</Typography>
        <Checkbox
          color="primary"
          name={f}
          checked={data[key] && data[key].indexOf(f) >= 0}
          inputProps={{'data-key': key, 'data-value': f}}
          onChange={onToggle}
        />
      </Box>;
      options.push(option);
    }
    items.push(
      <Box key={key} style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start'}}>
        {T.translate(`materials.${key}`)}
        {options}
      </Box>
    );
  }

  // const classes = useStyles(props.theme);

  return (
    <Box style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start'}}>
      {items}
    </Box>
  )
}


function RestrictedCell(props) {
  const { selected, material, onChange } = props;

  const classes = useStyles(props.theme);
  const image = material.media && material.media.length > 0 ? material.media[0] : '';
  const texts = [material.quality, material.appearance, material.cn, material.region];

  const details = <Box style={{display: 'flex', flexDirection: 'column'}}>
    <Typography variant="body2" component="span">{material.clazz} / {material.family} / {material.species}</Typography>
    <Typography variant="caption" component="span">{texts.filter(t => !!t).join(' / ')}</Typography>
  </Box>;
  return (
    <React.Fragment>
      <div className={classes.image}>
        <img className={classes.img} src={image} alt={material.title} />
      </div>
      <GridListTileBar
        // onClick={(e) => {e.stopPropagation();}}
        style={{backgroundColor: 'rgb(0, 0, 0, 0.8)'}}
        title={<Typography variant="subtitle1" component="span">{material.title}</Typography>}
        subtitle={details}
        actionIcon={
          !onChange ? undefined :
          <IconButton aria-label={material.identifier} style={{color: 'green'}} className={classes.icon} onClick={() => onChange(material)} >
            {selected.indexOf(material.identifier) >= 0 ? 
              <CheckIcon color="primary" fontSize="large" /> 
              : 
              <UncheckIcon fontSize="large" />
            }
          </IconButton>
        }
      />
    </React.Fragment>
  )
}


function StaticCell(props) {
  const { keys, rules, material, selected, onChange } = props;
  const [expanded, setExpanded] = React.useState(false);

  const classes = useStyles(props.theme);
  const image = material.media && material.media.length > 0 ? material.media[0] : '';

  const style = {backgroundColor: 'white'};

  return (
    <Box style={{marginBottom: 10}}>
      <ListItem style={style} className={classes.cell} key={material.id} alignItems="center" >
        <Image
          style={{ height: 130, width: 130, maxWidth: 130, maxHeight: 130, minWidth: 130, minHeight: 130 }}
          alt={image}
          src={image}
          title={T.translate('products.items', {c: image ? material.items.length : 0})}
        />
        <Box width={12} />
        <Box className={classes.content}>
          {keys.map((key) => {
            const box = <Box className={classes.prop} data-tag={key} key={key}>
              <Box style={{display: 'flex', flexDirection: 'row', height: 16}}>
                <Typography component="span" variant="caption">{rules[key]}</Typography>
              </Box>
              <Typography component="p" className={classes.ellipsis}>{material[key]}</Typography>
            </Box>;
            return box;
          })}
        </Box>
        <ListItemSecondaryAction style={{display: 'flex', flexDirection: 'column'}}>
          {onChange &&
          <IconButton onClick={e => onChange(material, undefined)}>
            {selected.indexOf(material.identifier) >= 0 ?
            <CheckedIcon color="secondary" />
            :
            <AddIcon color="primary" />
            }
          </IconButton>
          }
          <IconButton
            className={clsx(classes.expand, {[classes.expandOpen]: expanded})}
            onClick={(e) => { e.stopPropagation(); setExpanded(!expanded); }}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon color="primary" />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {material.items && material.items.map((item, index) => {
          const img = item.media && item.media.length > 0 ? item.media[0] : '';
          return <React.Fragment key={item.id || - index} >
            <ListItem style={style} className={classes.cell} key={item.id} alignItems="center" >
              <Box className={classes.main_image} width={142} style={{ width: 142, maxWidth: 142, minWidth: 142 }} >
                <Image
                  style={{ height: 64, width: 64, maxWidth: 64, maxHeight: 64, minWidth: 64, minHeight: 64 }}
                  alt={img}
                  src={img}
                  title={T.translate('trackers.n_images', {c: img ? item.media.length : 0})}
                />
              </Box>
              <Box className={classes.content}>
                {M_ITEMS.map((key) => {
                  const box = <Box className={classes.prop} data-tag={key} key={key}>
                    <Box style={{display: 'flex', flexDirection: 'row', height: 16}}>
                      <Typography component="span" variant="caption">{rules[key]}</Typography>
                    </Box>
                    <Typography component="p" className={classes.ellipsis}>{item[key]}</Typography>
                  </Box>;
                  return box;
                })}
              </Box>
              <ListItemSecondaryAction style={{display: 'flex', flexDirection: 'row'}}>
                {onChange &&
                <IconButton onClick={e => onChange(material, item)}>
                  {selected.indexOf(`${material.identifier}${item.identifier}`) >= 0 ?
                  <CheckedIcon color="secondary" />
                  :
                  <AddIcon color="primary" />
                  }
                </IconButton>
                }
              </ListItemSecondaryAction>
            </ListItem>
          </React.Fragment>;
        })}
      </Collapse>
    </Box>
  )
}


function EditableCell(props) {
  const { errors, keys, rules, material } = props;
  const is_header = !props.onSaved;
  const err = errors || {};

  const classes = useStyles(props.theme);
  const [, forceUpdate] = React.useReducer(x => x + 1, 0);
  const [expanded, setExpanded] = React.useState(true);
  const [newItem, setNewItem] = React.useState(null);
  const [itemErrors, setItemErrors] = React.useState({});
  if (!material.sorter)
    material.sorter = 'update_at';

  const saveItem = (event) => {
    const index = parseInt(event.currentTarget.dataset.tag);
    const item = material.items[index];
    const item_id = item.id;
    const errors = {...itemErrors};
    errors[item_id] = {};
    let e = false;
    let i;
    for (i = 0; i < M_ITEMS_REQUIRED.length; ++i) {
      const k = M_ITEMS_REQUIRED[i];
      if (!item[k]) {
        errors[item_id][k] = 'r';
        e = true;
      }
    }
    if (!(item_id > 0) && item.s_mid) {
      for (i = 0; i < material.items.length; ++i) {
        const itm = material.items[i];
        if (itm.id !== item.id && itm.s_mid === item.s_mid) {
          errors[item_id].s_mid = 'd';
          e = true;
        }
      }
    }
    setItemErrors(errors);
    if (e) return;

    return DataManager.getInstance().save_material_item(material.id, item_id, item).then(function (res) {
      if (res.c !== 0) {
        DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'error', m: T.translate('notification.failed')});
        return;
      }
      item.id = res.d.id;
      setNewItem(null);
      DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'success', m: T.translate('notification.success')});
    });
  };

  const deleteItem = (event) => {
    const index = parseInt(event.currentTarget.dataset.tag);
    const item = material.items[index];
    if (item.id === 0) {
      material.items.splice(index, 1);
      setNewItem(null);
      return;
    }
    return DataManager.getInstance().delete_material_item(material.id, item.id).then(function (res) {
      if (res.c !== 0) {
        DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'error', m: T.translate('notification.failed')});
        return;
      }
      material.items.splice(index, 1);
      forceUpdate();
      DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'success', m: T.translate('notification.success')});
    });
  };

  const onAdd = (e) => {
    if (newItem) return;
    const sample = material.items.length > 0 ? material.items[0] : {};
    const item = {id: 0, media: []};
    for (var i = 0; i < M_ITEMS.length; ++i) {
      const k = M_ITEMS[i];
      item[k] = sample[k] || '';
    }
    item.s_mid = '';
    material.items.splice(0, 0, item);
    setNewItem(item);
  };

  const saveMedia = (index, media) => {
    if (index < 0)
      material.media = media;
    else
      material.items[index].media = media;
    forceUpdate();
  };

  const style = {backgroundColor: !is_header ? 'white' : '#f8f8f8'};
  let labels = DataManager.getInstance().storage.materials || {};
  let kingdom = material.kingdom;
  if (labels.kingdom && labels.kingdom.indexOf(kingdom) < 0)
    kingdom = '';

  return (
    <Box style={{marginBottom: expanded ? 24 : 12}}>
      <ListItem component="div" style={style} className={classes.cell} key={material.id} alignItems="center" divider={expanded} >
        {is_header ?
        <Box className={classes.main_image} >
          <Typography>{T.translate('materials.media')}</Typography>
        </Box>
        :
        <MediaSet index={-1} size={130} media={material.media || []} title={T.translate('products.items', {c: material.items.length})} onSave={saveMedia} />
        }
        <Box width={12} />
        <Box className={classes.content}>
          {keys.map((key) => {
            if (!is_header && key === 'identifier') {
              return <Box className={classes.prop} data-tag={key} key={key}>
                <Box style={{display: 'flex', flexDirection: 'row', height: 16}}>
                  <Typography component="span" variant="caption">{rules[key]}</Typography>
                </Box>
                <Typography component="p" className={classes.ellipsis}>{material[key]}</Typography>
              </Box>;
            }
            let options = labels ? labels[key] : null;
            const box = <EditButton
              kingdom={kingdom}
              updateAll={key === 'kingdom' ? forceUpdate : undefined}
              onChange={props.onChange}
              options={options}
              key={key}
              className={err[key] ? classes.error_prop : (M_REQUIRED.indexOf(key) < 0 ? classes.prop : classes.required_prop)} 
              item={material} 
              item_key={key}
              item_label={rules[key]} 
              data-tag={key}
            />;
            return box;
          })}
        </Box>
        <ListItemSecondaryAction style={{display: 'flex', flexDirection: 'column'}}>
          {props.children}
          {!is_header && material.id > 0 &&
          <IconButton
            className={clsx(classes.expand, {[classes.expandOpen]: expanded})}
            onClick={(e) => { e.stopPropagation(); setExpanded(!expanded); }}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon color="primary" />
          </IconButton>
          }
        </ListItemSecondaryAction>
      </ListItem>

      <Collapse in={!is_header && expanded} timeout="auto" unmountOnExit>
        <ListItem style={style} className={classes.cell} alignItems="center">
          <RadioGroup
            value={material.sorter}
            row
            onChange={(e) => {
              material.sorter = e.target.value;
              const sk = material.sorter;
              material.items.sort((l, r) => (l[sk].length > r[sk].length || (l[sk].length === r[sk].length && l[sk] >= r[sk])) ? -1 : 1);
              forceUpdate();
            }}
          >
            {['s_mid', 'cs', 'update_at'].map((key, index) => (
              <FormControlLabel
                key={key}
                value={key}
                control={<Radio color="primary" />}
                labelPlacement="bottom"
                style={{marginLeft: 0}}
                label={<Typography style={{fontSize: 14}}>{T.translate(`materials.${key}`)}</Typography>}
              />
            ))}
          </RadioGroup>
          <ListItemSecondaryAction style={{display: 'flex', flexDirection: 'row'}}>
            {props.cards}
            <IconButton color="primary" onClick={onAdd} disabled={!!newItem}>
              <AddIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>

        {material.items && material.items.map((item, index) => {
          const err = itemErrors[item.id] || {};
          return <React.Fragment key={item.id || - index} >
            <ListItem style={style} className={classes.cell} key={item.id} alignItems="center" >
              <Box className={classes.second_image} >
                <MediaSet index={index} size={64} media={item.media || []} onSave={saveMedia} />
              </Box>
              <Box className={classes.content}>
                {M_ITEMS.map((key) => {
                  let options = labels ? labels[key] : null;
                  const box = <EditButton
                    kingdom={kingdom}
                    options={options}
                    key={key} 
                    className={err[key] ? classes.error_prop : (M_ITEMS_REQUIRED.indexOf(key) < 0 ? classes.prop : classes.required_prop)} 
                    item={item} 
                    item_key={key}
                    item_label={rules[key]} 
                    data-tag={key}
                  />;
                  return box;
                })}
              </Box>
              <ListItemSecondaryAction style={{display: 'flex', flexDirection: 'row'}}>
                <IconButton onClick={deleteItem} data-tag={index}>
                  <DeleteIcon color="error" />
                </IconButton>
                <IconButton color="primary" onClick={saveItem} data-tag={index}>
                  <SaveIcon/>
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          </React.Fragment>;
        })}
      </Collapse>
    </Box>
  )


//   <Box key={key} className={classes.edit} data-tag={key}>
//   <TextField
//     onChange={e => item[key] = e.target.value}
//     defaultValue={item[key]}
//     autoFocus
//     margin="dense"
//     label="text"
//     type="text"
//     fullWidth
//   />
//   <Button color="primary" data-tag={key} onClick={handleChange} >
//     {T.translate('buttons.submit')}
//   </Button>
// </Box>
}


function EditButton(props) {
  const { className, options, item, item_label, kingdom, updateAll } = props;
  const key = props.item_key;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(item[key] || '');
  // React.useEffect(() => {
    
  // }, [kingdom]);

  let filters;
  if (!options || options instanceof Array) {
    filters = options;
  }else if (options[kingdom] && options[kingdom] instanceof Array && options[kingdom].length > 0) {
    filters = options[kingdom];
  }else{
    filters = [];
    for (var k in options) {
      if (options.hasOwnProperty(k)) {
        const v = options[k];
        if (v instanceof Array) {
          // filters = filters.concat(v);
          for (var i = 0; i < v.length; ++i) {
            const f = v[i];
            if (f && filters.indexOf(f) < 0)
              filters.push(f);
          }
        }
      }
    }
  }

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    item[key] = value;
    setOpen(false);
    setAnchorEl(null);
    // onChange(key, value);
    if (key === 'kingdom') updateAll();
    if (props.onChange) props.onChange(key, value);
  };

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleSelect = (key, value) => {
    item[key] = value;
    setValue(value);
    if (key === 'kingdom') updateAll();
    if (props.onChange) props.onChange(key, value);
  };

  const onKey = e => {
    if (e.key === 'Enter') {
      e.stopPropagation();
      e.preventDefault();
      handleClose();
    }
  };
  const onFocus = e => {
    e.target.selectionStart = value ? value.length : 0;
    e.target.selectionEnd = value ? value.length : 0;
  };

  const giveMeEllipsis = {overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', 'WebkitBoxOrient': 'vertical', 'WebkitLineClamp': '2'};

  return (
    <React.Fragment>
      <Box className={className} onClick={handleClick} data-tag={key}>
        <Box style={{display: 'flex', flexDirection: 'row', height: 16}}>
          <Typography component="span" variant="caption">{item_label}</Typography>
          {filters &&
            <DropdownButton k={key} options={filters} onChange={handleSelect} />
          }
        </Box>
        <Typography component="p" style={giveMeEllipsis}>{item[key]}</Typography>
      </Box>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
      >
        <div style={{padding: 16}}>
          <TextField
            onChange={handleChange}
            onFocus={onFocus}
            onKeyDown={onKey}
            value={value}
            autoFocus
            margin="dense"
            label={item_label || ''}
            style={{width: 480}}
            multiline={true}
            type="text"
            fullWidth
          />
        </div>
      </Popover>
    </React.Fragment>
  );
}


function DropdownButton(props) {
  const { k, options } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = e => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = e => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(null);
  };

  const handleChange = e => {
    e.preventDefault();
    e.stopPropagation();
    const index = parseInt(e.target.value);
    props.onChange(k, options[index]);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = `action-menu-${props.index}`;
  return (
    <React.Fragment>
      <IconButton style={{padding: 0}} aria-controls={id} aria-haspopup="true" color="primary" aria-label={props.index} data-tag={props.index} onClick={handleClick}>
        <ExpandMoreIcon />
      </IconButton>
      {open &&
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options.map((option, index) => {
          return <MenuItem key={index} value={index} onClick={handleChange}>{option}</MenuItem>
        })}
      </Menu>
      }
    </React.Fragment>
  );
}


export { RestrictedCell, StaticCell, EditableCell, CustomizerCell };