

function restore(store) {
  store.token = localStorage.getItem('t') || '';
  store.role = localStorage.getItem('r') || -1;
  store.ur = localStorage.getItem('p') || -1;
  store.updated = localStorage.getItem('u') || '';
  store.name = localStorage.getItem('name') || '';
}


function save(store) {
  localStorage.setItem('t', store.token);
  localStorage.setItem('r', store.role);
  localStorage.setItem('u', store.updated);
  localStorage.setItem('name', store.name);
  localStorage.setItem('p', store.ur);
}


function clear() {
  localStorage.removeItem('t');
  localStorage.removeItem('r');
  localStorage.removeItem('u');
  localStorage.removeItem('name');
  localStorage.removeItem('p');
}


const Event = {
  AUTHORIZED: 1,
  UNAUTHORIZED: 2,
  REDIRECT: 3,
  UPDATED: 4,
  NOTIFICATION: 5,
  DISPLAY: 6
};


const Mode = {
  UNAUTHORIZED: 1,
  AUTHORIZED: 2,
  NORMAL: 3,
  PTR: 4,
  BUSINESS: 5,
  ADMIN: 10,
  ROOT: 20
};


const RestMode = {
  UNKNOWN: 0,
  INDEX: 1,
  SHOW: 2,
  CREATE: 3,
  EDIT: 4,
  DELETE: 5,

  transform: (action) => {
    let m = RestMode.UNKNOWN;
    if (action === 'e') {
      m = RestMode.EDIT;
    }else if (action === 's') {
      m = RestMode.SHOW;
    }else if (action === 'c') {
      m = RestMode.CREATE;
    }else if (action === 'd') {
      m = RestMode.DELETE;
    }else{
      m = RestMode.INDEX;
    }
    return m;
  }
};


export {Event, Mode, RestMode, save, restore, clear};



