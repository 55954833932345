import React, { Component } from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Link } from 'react-router-dom';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import SvgIcon from '@material-ui/core/SvgIcon';


const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5'
  }
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }}
    transformOrigin={{
      vertical: 2,
      horizontal: 'center'
    }}
    {...props}
  />
));


const styles = theme => ({
  arrow: {
    //position: 'absolute',
    fontSize: 6,
    width: '3em',
    height: '3em',
    '&::before': {
    content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid'
    }
  },
  link: {
    textDecoration: 'none',
    color: 'inherit'
  },
  menuItem: {
    textDecoration: 'none',
    padding: 0,
    minHeight: 20,
    height: 20,
    minWidth: 100
  },
  menuItemSelected: {
    textDecoration: 'none',
    minHeight: 20,
    height: 20,
    minWidth: 100,
    padding: 0,
    color: theme.palette.primary.main
  }
});

function ArrowIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M5 8l4 4 4-4z"/>
    </SvgIcon>
  );
  //const arrowIcon = (<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"><path d="M5 8l4 4 4-4z"/></svg>);
  //<path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
}



class Dropdown extends Component {
  constructor(props) {
    super(props);
    this.state = { open: false };
  }


  handleClick = (e) => {
    e.preventDefault();
    this.setState({
      open: !this.state.open,
      anchorEl: e.currentTarget
    });
  };

  handleClose = () => {
    this.setState({
      open: false
    });
  };


  render() {
    const { theme, children, menu, currentPath, classes, to, onClick, ...other } = this.props;

    let items = menu && menu.items ? menu.items.map((item, index) => (
      <Link key={index} to={{pathname: item.pathname}} className={classes.link}>
        {currentPath === item.pathname ?
          <Box borderBottom={2} borderColor={theme.palette.primary.main}>
            <MenuItem key={index}>
              <Tab className={classes.menuItemSelected} value={index} label={item.title} style={{color: theme.palette.primary.main}} />
            </MenuItem>
          </Box>
          : (index === menu.items.length - 1 ?
          <MenuItem>
            <Tab className={classes.menuItem} value={index} label={item.title} />
          </MenuItem>
          :
          <Box borderBottom={1}>
            <MenuItem>
              <Tab className={classes.menuItem} value={index} label={item.title} />
            </MenuItem>
          </Box>)}
      </Link>
    )) : null;
    return (
      <a {...other}
        onClick={this.handleClick}
        aria-owns={this.state.open ? 'dropdown-menu' : null}
        aria-haspopup="true"
      >
        <Box m={1} />
        {children}
        <ArrowIcon color={menu.active ? 'primary' : 'inherit'} style={{ fontSize: 30 }}> </ArrowIcon>
        <StyledMenu
          id="dropdown-menu"
          open={this.state.open}
          anchorEl={this.state.anchorEl}
          onClose={this.handleClose}
          keepMounted
          disableRestoreFocus
        >
          {items}
        </StyledMenu>
      </a>
    );
  }
}

export default withStyles(styles)(withTheme(Dropdown));

