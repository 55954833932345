import React,  { Component } from 'react';
import { Route } from 'react-router-dom';
import withStyles from '@material-ui/styles/withStyles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Topbar from '../Topbar';
import { Mode, DataManager, Permit } from '../models/';
import T from 'i18n-react';

import List from './List';
import Table from './Table';
import Create from './Create';
import Edit from './Edit';
import SellerEdit from '../UnitOrder/Items';
import Show from './Show';
import Payment from './Payment';
import PaymentSeller from './PaymentSeller';
import ShowTrackers from './ItemTrackers';
import Tracker from './Tracker';


const styles = theme => ({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
    marginTop: theme.spacing(2),
    padding: theme.spacing(0)
  }
});

class Orders extends Component {
  constructor(props) {
    super(props);
    this.state = {show: !!DataManager.getInstance().states};
    if (!this.state.show)
      this.ensure_states();
  }

  ensure_states = () => {
    let self = this;
    DataManager.getInstance().ensure_order_states().then(function (res) {
      if (res.c === 0) {
        DataManager.getInstance().states = res.d;
        let orders = DataManager.getInstance().states.orders;
        let k;
        for (k in orders) {
          if (orders.hasOwnProperty(k)) {
            orders[orders[k]] = T.translate(`orders.states.${k}`);
          }
        }
        const items = DataManager.getInstance().states.items;
        for (k in items) {
          if (items.hasOwnProperty(k)) {
            items[items[k]] = T.translate(`orders.product_states.${k}`);
          }
        }
        const trackers = DataManager.getInstance().states.trackers;
        for (k in trackers) {
          if (trackers.hasOwnProperty(k)) {
            trackers[trackers[k]] = T.translate(`trackers.stages.${k}`);
          }
        }
        self.setState({show: true});
      }
    });
  };

  render() {
    const { classes } = this.props;
    const currentPath = this.props.location.pathname;
    const path = '/' + this.props.match.path.split('/')[1];
    const is_customer = DataManager.getInstance().mode < Mode.BUSINESS;
    return (
      <React.Fragment>
        <CssBaseline />
        <Topbar currentPath={currentPath} />
        {this.state.show &&
        <Permit permit="Order">
          <div className={classes.root}>
            <Route path={path} exact component={ is_customer ? List : Table } />
            <Route path={`${path}/s/:id?`} component={ Show } />
            <Route path={`${path}/trackers/:order_id/:item_id`} component={ ShowTrackers } />
            <Route path={`${path}/tracker/:order_id/:item_id/:tracker_id?`} component={ Tracker } />
            <Route path={`${path}/se/:id?`} component={ SellerEdit } />
            <Route path={`${path}/c/`} component={ Create } />
            <Route path={`${path}/e/:id?`} component={ Edit } />
            <Route path={`${path}/p/:id?`} component={ Payment } />
            <Route path={`${path}/ps/:id?`} component={ PaymentSeller } />
          </div>
        </Permit>
        }
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(Orders);