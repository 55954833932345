import React, { Component } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


const styles = theme => ({
  root: {
  }
});


class AlertDialog extends Component {

  render() {
    const { open, onClose, ...props } = this.props;

    return (
      <Dialog
        open={open}
        onClose={onClose}
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{minWidth: 320}}>{props.title || ''}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.desc || ''}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {props.left &&
          <Button onClick={onClose} color="secondary" data-tag={0}>
            {props.left}
          </Button>
          }
          <Button onClick={onClose} color="primary" data-tag={1} autoFocus>
            {props.right}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(AlertDialog);