/**
 * Created by milan on 2019/7/18.
 */
import React, { Component } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import T from 'i18n-react';
import { DataManager } from '../models/';
import Regions from './Regions';

import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';


const styles = theme => ({
  root: {
  }
});


function ProjectCreator(props) {
  const { open, onClose, onSave } = props;

  const def = props.value;
  const [value, setValue] = React.useState(def);
  React.useEffect(() => {
    setValue(def);
  }, [def]);

  const handleChange = (e) => {
    const v = {...value};
    v[e.target.name] = e.target.value;
    setValue(v);
  };

  const handleSubmit = () => {
    onSave(value);
  };

  const handleAddress = (v) => {
    let region = [];
    let address = [];
    if (v && v.length > 0) {
      let i;
      for (i = 0; i < v.length; ++i) {
        const r = v[i];
        region.push(r.id);
        address.push(r.name);
      }
    }
    const newValue = {...value};
    newValue.region = region.join(',');
    newValue.address = address.join(', ');
    setValue(newValue);
  };

  return (
    <React.Fragment>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
        <DialogTitle id="project-dialog-title">{T.translate('projects.new')}</DialogTitle>
        <DialogContent>
          <TextField
            value={value.title}
            onChange={handleChange}
            autoFocus
            required
            margin="normal"
            name="title"
            label={T.translate("projects.title")}
            type="text"
            fullWidth
          />
          <Regions
            initialRegion={value.region}
            label={T.translate("projects.address")}
            onSave={handleAddress}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            {T.translate('buttons.cancel')}
          </Button>
          <Button onClick={handleSubmit} color="primary" disabled={!(value.title && value.address)}>
            {T.translate('buttons.submit')}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}


class ProjectSelector extends Component {
  projects = null;
  state = {values: {title: '', region: '', address: ''}, chosen: '', requesting: false, alert: false};

  constructor(props) {
    super(props);
    if (props.projects) this.projects = props.projects;
    //else this.list();
  }

  list = () => {
    if (!this.state.requesting)
      this.setState({ requesting: true });
    const self = this;
    const params = {page: 1, f: 0, s: 1};
    if (this.props.uid)
      params.uid = this.props.uid;
    DataManager.getInstance().list_projects(params).then(function (res) {
      if (res.c === 0) {
        const projects = res.d.projects;
        if (self.props.create)
          projects.push({id: -1, title: T.translate('projects.new')});
        self.projects = projects;
        self.setState({ requesting: false, open: true, chosen: self.props.selected });
      }
    })
  };

  on_create = (values) => {
    if (this.props.create && this.projects && this.projects.length > 0) {
      this.projects[this.projects.length - 1].title = values.title;
      this.projects[this.projects.length - 1].region = values.region;
      this.projects[this.projects.length - 1].address = values.address;
    }
    this.setState({ alert: false, values: values, chosen: -1 });
    this.props.onChange(-1, values);
  };

  on_change = (e) => {
    const chosen = parseInt(e.target.value);
    if (chosen > 0){
      this.setState({ chosen: chosen });
      let i;
      for (i = 0; i < this.projects.length; ++i) {
        const project = this.projects[i];
        if (project.id === chosen) {
          this.props.onChange(project.id, {title: project.title, region: project.region, address: project.address});
          break;
        }
      }
    }else if (chosen === -1){
      this.setState({ alert: true });
    }
  };

  activate = (e) => {
    if (this.projects) {
    }else if (this.props.projects){
      this.projects = this.props.projects;
    } else if (!this.state.requesting && !this.projects) {
      this.list();
    }
  };

  render() {
    const { className, error } = this.props;
    const projects = this.projects;
    const chosen = this.state.chosen;

    return (
      <React.Fragment>
        <TextField
          className={className}
          error={!!error}
          helperText={error}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label={T.translate("projects.choose")}
          name="project"
          autoComplete="project"
          value={chosen}
          onChange={this.on_change}
          disabled={this.state.requesting}
          select={true}
          SelectProps={{
            onOpen: this.activate
          }}
        >
          {!projects ?
            <MenuItem
              key={-2}
              value={-2}
              disabled
            >
              <CircularProgress size={32} />
            </MenuItem>
            :
            projects.map(project => (
              <MenuItem
                key={project.id}
                value={project.id}
              >
                <Typography component="span" color={project.id < 0 ? 'primary' : 'textPrimary'}>{project.title}</Typography>
              </MenuItem>
            ))
          }
        </TextField>

        <ProjectCreator
          value={this.state.values}
          open={this.state.alert}
          onClose={this.close_alert}
          onSave={this.on_create}
        />
      </React.Fragment>
    );
  }

  close_alert = () => {
    this.setState({ alert: false });
  };
}


export default withStyles(styles)(ProjectSelector);