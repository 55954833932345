import React,  { Component } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import Paper from '@material-ui/core/Box';

import { Mode, Event, DataManager } from '../models';
import Cell from './Cell';

import T from 'i18n-react';
import Pagination from '../common/Pagination';
import CircularProgress from '@material-ui/core/CircularProgress';


const styles = theme => ({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
    marginTop: theme.spacing(2),
    padding: theme.spacing(1)
  },
  paper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  btns: {
    marginBottom: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  list: {
    width: '100%'
  },
  content: {
    fontSize: 24,
    margin: theme.spacing(2)
  },
  pagination: {
    marginTop: theme.spacing(5)
  }
});


class Discover extends Component {
  state = {total: 1, page: 1, per: 0, filter: 0, requesting: false};
  units = undefined;

  constructor(props) {
    super(props);
    const search = props.location.search.substring(1).split('.');
    if (search.length > 1) {
      const p = search[0];
      const page = parseInt(p);
      if (page > 0) this.state.page = page;
      const f = search[1];
      const filter = parseInt(f);
      if (filter > 0) this.state.filter = filter;
    }
    this.state.requesting = true;
    this.list(this.state.page, this.state.filter);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.location.search !== nextProps.location.search) {
      const search = nextProps.location.search.substring(1).split('.');
      if (search.length > 1) {
        const p = search[0];
        const page = parseInt(p);
        if (page > 0) nextState.page = page;
        const f = search[1];
        const filter = parseInt(f);
        if (filter >= 0) nextState.filter = filter;
      }
      this.list(nextState.page, nextState.filter);
      return true;
    }
    return true;
  }

  selected = (unit) => {
    const path = this.props.match.path.split('/')[1];
    DataManager.getInstance().pub(Event.REDIRECT, `/${path}/s/${unit.id}`);
  };

  handle_pagination = (offset) => {
    const page = 1 + offset / this.state.per;
    DataManager.getInstance().pub(Event.REDIRECT, {pathname: this.props.location.pathname, search: `${page}.${this.state.filter}`});
  };

  handle_filter = (e) => {
    const f = parseInt(e.target.value);
    this.setState({filter: f, requesting: true});
    DataManager.getInstance().pub(Event.REDIRECT, {pathname: this.props.location.pathname, search: `1.${f}`});
  };

  handle_prioritized = (e) => {
    const f = this.state.filter + 1;
    this.setState({filter: f, requesting: true});
    DataManager.getInstance().pub(Event.REDIRECT, {pathname: this.props.location.pathname, search: `1.${f}`});
  };

  render() {
    if (!this.units) return <div></div>;
    const { classes } = this.props;

    return (
      <React.Fragment>
        <div className={classes.root}>
          {this.table()}
        </div>
      </React.Fragment>
    );
  }

  table = () => {
    const { classes } = this.props;
    const units = this.units;
    const self = this;
    return (
      <Grid alignItems="center" justify="center" container>
        <Grid item xs={12} />
        <Grid item xs={12} sm={8} md={6} lg={6} xl={4}>
          <Paper className={classes.paper}>
            {this.state.requesting ?
              <CircularProgress size={48} />
              :
              (units.length === 0 ?
                <Typography className={classes.content}>{T.translate('units.empty_list')}</Typography>
                :
                <List className={classes.list}>
                  {units.map((unit, index) => (
                    <Cell
                      key={unit.id}
                      unit={unit}
                      onClick={() => self.selected(unit)}
                      onPrioritized={this.handle_prioritized}
                    />
                  ))}
                </List>
              )
            }
          </Paper>
          {!this.state.requesting && this.state.per > 0 && this.state.total > this.state.per &&
          <Pagination
            offset={this.state.per * (this.state.page - 1)}
            limit={this.state.per}
            total={this.state.total}
            onClick={this.handle_pagination}
          />
          }
        </Grid>
      </Grid>
    );
  };

  list = (page, f) => {
    const self = this;
    const params = {page: page, f: f};
    if (DataManager.getInstance().mode >= Mode.ADMIN) params.uid = 0;
    return DataManager.getInstance().discover_units(params).then(function (res) {
      if (res.c === 0) {
        let d = res.d;
        self.units = d.units;
        self.setState({page: page, total: d.t, per: d.p, requesting: false});
      }
    })
  };
}


export default withStyles(styles)(Discover);