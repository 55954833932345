import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import T from 'i18n-react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Image from '../common/Image';
import IconButton from '@material-ui/core/IconButton';

import { priceDisplay } from '../common/Constants';
import Popover from '@material-ui/core/Popover';

import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import Checkbox from '@material-ui/core/Checkbox';
import SellerBoard from '../Unit/SellerBoard';
import AddIcon from '@material-ui/icons/AddBox';
import MinusIcon from '@material-ui/icons/IndeterminateCheckBox';


const useStyles = makeStyles(theme => ({
  titleBlock: {
    padding: theme.spacing(0, 0, 0, 1),
    minHeight: 110,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'space-between',
    justifyContent: 'space-between'
  },
  rightBlock: {
    padding: theme.spacing(0, 0, 0, 1),
    minHeight: 110,
    minWidth: 110,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
    justifyContent: 'space-between'
  },
  label: {
    fontSize: 14,
    display: 'block',
    [theme.breakpoints.down('xs')]: {
      maxWidth: 130
    }
  },
  boldLabel: {
    fontSize: 20,
    //color: theme.palette.primary.main,
    [theme.breakpoints.down('xs')]: {
      maxWidth: 36
    }
  },
  cell: {
    backgroundColor: 'white',
    padding: theme.spacing(1, 1, 1, 1)
  },
  quotes: {
    backgroundColor: 'white',
    padding: theme.spacing(2.5)
  },

  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },

  popover: {
    pointerEvents: 'none'
  }
}));


export default function Cell(props) {
  const { item, quotes, units, onImageClick } = props;

  const [chosen, setChosen] = React.useState(item.chosen);
  const [expanded, setExpanded] = React.useState(item.chosen === 0);

  const classes = useStyles(props.theme);

  const toggle = (e) => {
    setExpanded(!expanded);
  };

  const onChange = (e) => {
    item.price = 0;
    const selected = e.target.checked;
    const unit_id = parseInt(e.target.name);
    let current = 0;
    let i;
    for (i = 0; i < quotes.length; ++i) {
      const quote = quotes[i];
      if (quote.unit_id === unit_id) {
        quote.chosen = selected;
      }else{
        quote.chosen = false;
      }
      if (quote.chosen) {
        current = quote.unit_id;
        item.price = quote.price;
      }
    }
    setChosen(current);
    props.onChange();
  };

  const onClick = (e) => {
    e.stopPropagation();
    toggle();
  };
  // button onClick={onClick} 
  return (
    <React.Fragment key={item.id} >
      <ListItem className={classes.cell} alignItems="center" >
        <Image
          style={{ height: 130, width: 130, maxWidth: 130, maxHeight: 130, minWidth: 130, minHeight: 130 }}
          alt={item.media[0]}
          src={item.media[0]}
          title={onImageClick ? T.translate('trackers.n_images', {c: item.media.length}) : null}
          onClick={onImageClick}
        />
        <ListItemText
          primary={
            <Box className={classes.titleBlock}>
              <Box style={{display: 'flex', alignItems: 'center'}}>
                <Typography component="span" className={classes.label}>{item.title}</Typography>
              </Box>
              <Box>
                <Typography component="p" className={classes.label}>{item.material}</Typography>
              </Box>
              <Box>
                <Typography component="p" className={classes.label}>{item.size}</Typography>
              </Box>
            </Box>
          }
        />
        <Box className={classes.rightBlock}>
          <Box style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', verticalAlign: 'bottom'}}>
            <Typography component="span" className={classes.label}>{T.translate('orders.quantity')}: &nbsp;</Typography>
            <QuantityEditor item={item} onChange={props.onChange} />
          </Box>
          <Box style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', verticalAlign: 'bottom'}}>
            <Typography component="span" className={classes.label}>{T.translate('quotation.unit_price')}: &nbsp;</Typography>
            <Typography component="span" className={classes.boldLabel}>
              {item.price ? priceDisplay(item.price, item.currency) : T.translate('deals.price_not_chosen')}
            </Typography>
          </Box>
          <IconButton
            className={clsx(classes.expand, {
                [classes.expandOpen]: expanded
              })}
            onClick={onClick}
            aria-expanded={expanded}
            aria-label="show more"
            style={{paddingTop: 0, paddingBottom: 0}}
          >
            <ExpandMoreIcon color="primary" />
          </IconButton>
        </Box>
      </ListItem>
      {item.memo &&
        <Box className={classes.cell}>
          <Typography component="p" variant="caption">{T.translate('orders.memo')}: {item.memo}</Typography>
        </Box>
      }
      <Collapse in={expanded} className={classes.quotes} timeout="auto" unmountOnExit>
        <Box>
          {quotes.map((quote, index) => {
            return quote.unit_id > 0 && units[quote.unit_id].type === 2 ?
             <SelectableQuote
              key={quote.unit_id}
              item_id={item.id}
              unit={units[quote.unit_id]}
              memo={quote.memo}
              price={priceDisplay(quote.price, item.currency)}
              active={item.qty > 0}
              selected={chosen === quote.unit_id}
              onChange={onChange}
             /> : undefined;
          })}
        </Box>
      </Collapse>
    </React.Fragment>
  );
  //{props.showEdit && <ExternalDialog value={item.title} onSave={saveOrder} disabled={requesting} />}
}


function SelectableQuote(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  //const handlePopoverOpen = event => {
  //  setAnchorEl(event.currentTarget);
  //};
  const handleClick = event => {
    if (anchorEl) setAnchorEl(null);
    else setAnchorEl(event.currentTarget);
  };
  const open = Boolean(anchorEl);

  const { active, unit, price, memo, selected } = props;

  return (
    <div style={{display: 'inline-block', paddingLeft: 10, paddingRight: 10}}>
      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start'}}>
        <Checkbox
          name={`${unit.id}`}
          color="primary"
          disabled={!active}
          checked={active && selected}
          onChange={props.onChange}
        />
        <Box>
          <Typography
            component="p"
            variant="subtitle2"
            align="center"
            // aria-owns={open ? id : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            //onMouseEnter={handlePopoverOpen}
            //onMouseLeave={handlePopoverClose}
            style={{paddingTop: 16, cursor: 'pointer'}}
          >
            {unit.display}
          </Typography>
          <Typography component="p" variant="h6" align="center">
            {price}
          </Typography>
        </Box>
        {open &&
        <Popover
          //className={classes.popover}
          //style={{pointerEvents: 'none'}}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <SellerBoard unit={unit} ext={memo ? `${T.translate('quotation.memo')}: ${memo}` : undefined} />
        </Popover>
        }
      </div>
    </div>

  );
}


function QuantityEditor(props) {
  const { item, maxQuantity, onChange } = props;
  const m = maxQuantity && maxQuantity > 0 ? maxQuantity : 99;
  const active = !!item.value && item.value >= 0;

  const [quantity, setQuantity] = React.useState(item.qty);

  const handleClick = (e) => {
    item.qty = quantity + parseInt(e.currentTarget.dataset.tag);
    setQuantity(item.qty);
    if (onChange)
      onChange();
  };
  const ds = {display: 'inline-flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', verticalAlign: 'bottom'};
  return (
    <React.Fragment>
      <div style={ds} onClick={e => e.stopPropagation()}>
        <IconButton style={{padding: 0, paddingRight: 6}} color="primary" size="medium" data-tag={-1} onClick={handleClick} disabled={!active || quantity <= 0}>
          <MinusIcon/>
        </IconButton>
        <Typography component="span" variant="subtitle1" style={{textAlign: 'center'}} >{active ? quantity : 0}</Typography>
        <IconButton style={{padding: 0, paddingLeft: 6}} color="primary" size="medium" data-tag={1} onClick={handleClick} disabled={!active || quantity >= m}>
          <AddIcon/>
        </IconButton>
      </div>
    </React.Fragment>
  );
}
