import React,  { Component } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Topbar from './Topbar';

import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import T from 'i18n-react';
import { grey } from '@material-ui/core/colors';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import { Mode, DataManager } from './models/';

import Carousel from './common/Carousel';


const tutorialSteps = [
  {
    label: '1',
    //description: '*可在3d66、欧莫、建E、拓者下载家具模型或自建模型上传询价',
    description: '',
    //imgPath: require('../images/landing/1.jpg'),
    imgPath: 'https://zhuchao.oss-cn-qingdao.aliyuncs.com/images/landing/1.jpg'
  },
  {
    label: '2',
    description: '',
    //imgPath: require('../images/landing/2.jpg'),
    imgPath: 'https://zhuchao.oss-cn-qingdao.aliyuncs.com/images/landing/2.jpg'
  },
  {
    label: '3',
    description: '',
    //imgPath: require('../images/landing/3.jpg'),
    imgPath: 'https://zhuchao.oss-cn-qingdao.aliyuncs.com/images/landing/3.jpg'
  }
];
const banners = [
  {
    label: '1',
    description: '进口国产均可采购',
    color: '#51d000',
    imgPath: 'https://zhuchao.oss-cn-qingdao.aliyuncs.com/images/landing/i1.png'
  },
  {
    label: '2',
    description: '12小时快速报价',
    color: '#4a90e2',
    imgPath: 'https://zhuchao.oss-cn-qingdao.aliyuncs.com/images/landing/i2.png'
  //},
  //{
  //  label: '3',
  //  description: '质量无忧价格低',
  //  color: '#f5a623',
  //  imgPath: 'https://zhuchao.oss-cn-qingdao.aliyuncs.com/images/landing/i3.png'
  }
];

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.grey['A500'],
    width: '100%',
    textAlign: 'center'
  },
  bottomMargin: {
    marginBottom: theme.spacing(2)
  },
  container: {
    position: 'relative'
  },
  img: {
    width: '100%'
  },
  button: {
    margin: 20,
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 60,
    paddingRight: 60
  },
  buttonBlack: {
    color: theme.palette.getContrastText(grey[900]),
    backgroundColor: grey[900],
    '&:hover': {
      backgroundColor: grey[700]
    }
  },
  buttonOutlined: {
    color: theme.palette.getContrastText(grey[50]),
    backgroundColor: grey[50],
    border: '2px solid black',
    '&:hover': {
      border: '2px solid grey',
      backgroundColor: grey[50]
    }
  },
  stepper: {
    position: 'absolute',
    backgroundColor: 'transparent',
    left: 0,
    right: 0,
    zIndex: 100,
    bottom: '5%'
  },
  block: {
    position: 'absolute',
    backgroundColor: 'transparent',
    left: 0,
    right: 0,
    [theme.breakpoints.down('sm')]: {
    },
    [theme.breakpoints.up('sm')]: {
      bottom: '10%'
    },
    [theme.breakpoints.up('md')]: {
      bottom: '25%'
    }
  },
  banners: {
    marginTop: 48,
    marginBottom: 48,
    [theme.breakpoints.down('sm')]: {
      marginTop: 100
    },
    [theme.breakpoints.up('md')]: {
      marginTop: 48
    }
  },
  banner: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  bigIcons: {
    display: 'block',
    width: 64,
    height: 64,
    marginBottom: 16
  }
});

class Landing extends Component {
  state = {
    activeStep: 0
  };

  handleNext = () => {
    this.setState(prevState => ({
      activeStep: prevState.activeStep + 1
    }));
  };

  handleBack = () => {
    this.setState(prevState => ({
      activeStep: prevState.activeStep - 1
    }));
  };

  componentWillUnmount = () => {
    this.handleStepChange = null;
  };

  handleStepChange = (_, activeStep) => {
    this.setState({ activeStep });
  };

  carousel() {
    const { classes } = this.props;
    //const maxSteps = tutorialSteps.length;
    const { activeStep } = this.state;
    const authed = DataManager.getInstance().mode >= Mode.AUTHORIZED;

    return (
      <div className={classes.root}>
        <div className={classes.container}>
          <Carousel
            animation={'slide'}
            interval={5000}
            indicators={true}
            autoPlay={true}
          >
            {tutorialSteps.map((step, index) => (
              <div key={step.label}>
                {Math.abs(activeStep - index) <= 2 ? (
                  <img className={classes.img} src={step.imgPath} alt={step.label} />
                ) : null}
              </div>
            ))}
          </Carousel>

          <div className={classes.block}>
            <div className={classes.buttons}>
              <Button component={Link} to={authed ? '/inquiries/c/' : '/signin/'} variant='contained' size="large" className={[classes.button, classes.buttonBlack].join(' ')} autoFocus>
                {T.translate('inquiries.do_inquiry')}
              </Button>

            </div>
            <Typography style={{textTransform: 'uppercase', fontSize: 14, margin: 10}} color='secondary' gutterBottom>
              {tutorialSteps[activeStep].description}
            </Typography>
          </div>
        </div>
        <Grid className={classes.banners} container justify="center">
          {banners.map((banner, index) => (
            <Grid key={index} item xs={6} sm={4} md={4}>
              <div className={classes.banner}>
                <Avatar alt={banner.label} src={banner.imgPath} className={classes.bigIcons} />
                <Typography style={{fontSize: 18, margin: 0, color: banner.color}} color='secondary' gutterBottom>
                  {banner.description}
                </Typography>
              </div>
            </Grid>
          ))}
        </Grid>
      </div>
    );

    //<Button component={Link} to={'/inquiries/c/'} variant='outlined' className={[classes.button, classes.buttonOutlined].join(' ')} autoFocus>
    //  {T.translate('inquiries.inquiry_with_image')}
    //</Button>
  }

  render() {
    //const { classes } = this.props;
    if (this.props.contentOnly) return this.carousel();

    return (
      <React.Fragment>
        <CssBaseline />
        <Topbar currentPath={this.props.location.pathname} />
        {this.carousel()}
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(Landing);