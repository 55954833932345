import React,  { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import T from 'i18n-react';
import Topbar from './Topbar';
import Image from './common/Image';
import { Link } from '@material-ui/core';
import { Event, Mode, DataManager } from './models/';
import Container from '@material-ui/core/Container';
import Hidden from '@material-ui/core/Hidden';
import { Link as ExternalLink } from '@material-ui/core';


const styles = theme => ({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
    backgroundSize: 'cover',
    backgroundPosition: '0 400px',
    paddingBottom: 200
  },
  grid: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 20px)'
    }
  },
  paper: {
    padding: theme.spacing(3),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  actionButton: {
    textTransform: 'uppercase',
    margin: theme.spacing(1),
    width: 152
  },
  block: {
    padding: theme.spacing(2)
  },
  box: {
  },
  text: {
    margin: theme.spacing(2)
  },
  footer: {
    padding: theme.spacing(2),
    textAlign: 'center',
    width: '100%',
    backgroundColor: 'transparent'
  },
  footerContainer: {
    textAlign: 'center',
    height: 64
  },
});

class Client extends Component {
  constructor(props) {
    super(props);
    if (DataManager.getInstance().mode === Mode.BUSINESS) {
      const target = this.props.match.params.target;
      if (!target) {
        setTimeout(function () {
          DataManager.getInstance().pub(Event.REDIRECT, {pathname: '/app/seller'});
        }, 100);
      }
    }
  }

  componentDidMount(){
    if (window.location.pathname.indexOf('casa') >= 0) {
      document.title = 'Chao Casa';
    } 
  }

  render() {
    const { classes } = this.props;
    const currentPath = this.props.location.pathname;
    const target = window.location.pathname.indexOf('casa') >= 0 ? 'casa' : this.props.match.params.target;
    const ba = window.location.hostname.toLowerCase().indexOf('chaocasa') >= 0 ? '3' : '2';

    let blocks;

    if (target === 'seller') {
      blocks = [
        <Grid key="android" item xs={12} md={5}>
          <Paper className={classes.paper}>
            <Typography className={classes.text} component="h6" color='secondary'>
              {T.translate('app.android')}
            </Typography>
            <Link className={classes.text} href="https://aifa.oss-cn-qingdao.aliyuncs.com/zhuchao_seller.apk">{T.translate('app.android_link')}</Link>
            <Typography component="p" variant="body2">
              {T.translate('app.or')}
            </Typography>
            <Typography className={classes.text} component="p" variant="body2">
              {T.translate('app.android_qr')}
            </Typography>
            <Image style={{width: 256, height: 256}} src="https://aifa.oss-cn-qingdao.aliyuncs.com/android_seller.png" alt="二维码" />
          </Paper>
        </Grid>,
        <Grid key="iOS" item xs={12} md={5}>
          <Paper className={classes.paper}>
            <Typography className={classes.text} component="h6" color='secondary'>
              {T.translate('app.iOS')}
            </Typography>
            <Typography className={classes.text} component="p" variant="body2">
              {T.translate('app.iOS_search_seller', {app: T.translate('appName')})}
            </Typography>
            <Typography component="p" variant="body2">
              {T.translate('app.or')}
            </Typography>
            <Link className={classes.text} href="https://apps.apple.com/us/app/%E4%BD%8F%E6%BD%AE%E5%8E%82%E5%AE%B6%E7%89%88/id1490821267">{T.translate('app.iOS_link')}</Link>
            <Typography component="p" variant="body2">
              {T.translate('app.or')}
            </Typography>
            <Typography className={classes.text} component="p" variant="body2">
              {T.translate('app.iOS_qr')}
            </Typography>
            <Image style={{width: 256, height: 256}} src="https://aifa.oss-cn-qingdao.aliyuncs.com/iOS_seller.png" alt="二维码" />
          </Paper>
        </Grid>
      ];
    }else if (target === 'casa'){
      blocks = [
        <Grid key="android" item xs={12} md={5}>
          <Paper className={classes.paper}>
            <Typography className={classes.text} component="h6" color='secondary'>
              {T.translate('app.android')}
            </Typography>
            <Link className={classes.text} href="https://aifa.oss-cn-qingdao.aliyuncs.com/chao_casa.apk">{T.translate('app.android_link')}</Link>
            <Typography component="p" variant="body2">
              {T.translate('app.or')}
            </Typography>
            <Typography className={classes.text} component="p" variant="body2">
              {T.translate('app.android_qr')}
            </Typography>
            <Image style={{width: 256, height: 256}} src="https://aifa.oss-cn-qingdao.aliyuncs.com/chao_casa_android.png" alt="二维码" />
          </Paper>
        </Grid>,
        <Grid key="iOS" item xs={12} md={5}>
          <Paper className={classes.paper}>
            <Typography className={classes.text} component="h6" color='secondary'>
              {T.translate('app.iOS')}
            </Typography>
            <Typography className={classes.text} component="p" variant="body2">
              {T.translate('app.iOS_search', {app: T.translate('Casa')})}
            </Typography>
            <Typography component="p" variant="body2">
              {T.translate('app.or')}
            </Typography>
            <Link className={classes.text} href="https://apps.apple.com/app/id1530189499">{T.translate('app.iOS_link')}</Link>
            <Typography component="p" variant="body2">
              {T.translate('app.or')}
            </Typography>
            <Typography className={classes.text} component="p" variant="body2">
              {T.translate('app.iOS_qr')}
            </Typography>
            <Image style={{width: 256, height: 256}} src="https://aifa.oss-cn-qingdao.aliyuncs.com/chao_casa_ios.png" alt="二维码" />
          </Paper>
        </Grid>
      ];
    }else{
      blocks = [
        <Grid key="android" item xs={12} md={5}>
          <Paper className={classes.paper}>
            <Typography className={classes.text} component="h6" color='secondary'>
              {T.translate('app.android')}
            </Typography>
            <Link className={classes.text} href="https://aifa.oss-cn-qingdao.aliyuncs.com/zhuchao_app.apk">{T.translate('app.android_link')}</Link>
            <Typography component="p" variant="body2">
              {T.translate('app.or')}
            </Typography>
            <Typography className={classes.text} component="p" variant="body2">
              {T.translate('app.android_qr')}
            </Typography>
            <Image style={{width: 256, height: 256}} src="https://aifa.oss-cn-qingdao.aliyuncs.com/android.jpg" alt="二维码" />
          </Paper>
        </Grid>,
        <Grid key="iOS" item xs={12} md={5}>
          <Paper className={classes.paper}>
            <Typography className={classes.text} component="h6" color='secondary'>
              {T.translate('app.iOS')}
            </Typography>
            <Typography className={classes.text} component="p" variant="body2">
              {T.translate('app.iOS_search', {app: T.translate('appName')})}
            </Typography>
            <Typography component="p" variant="body2">
              {T.translate('app.or')}
            </Typography>
            <Link className={classes.text} href="https://apps.apple.com/cn/app/%E4%BD%8F%E6%BD%AE/id1480826074">{T.translate('app.iOS_link')}</Link>
            <Typography component="p" variant="body2">
              {T.translate('app.or')}
            </Typography>
            <Typography className={classes.text} component="p" variant="body2">
              {T.translate('app.iOS_qr')}
            </Typography>
            <Image style={{width: 256, height: 256}} src="https://aifa.oss-cn-qingdao.aliyuncs.com/iOS.jpg" alt="二维码" />
          </Paper>
        </Grid>
      ];
    }
    const platform = navigator.platform.toLowerCase();
    let iOS_first = false;
    if (platform.indexOf('mac') >= 0 || platform.indexOf('iphone') >= 0 || platform.indexOf('ipad') >= 0 || platform.indexOf('ipod') >= 0)
      iOS_first = true;
    if (iOS_first) blocks.reverse();

    return (
      <React.Fragment>
        <CssBaseline />
        {target !== 'casa' && <Topbar currentPath={currentPath} />}
        
        <div className={classes.root}>
          <Grid container justify="center">
            <Grid spacing={4} alignItems="flex-start" justify="center" container className={classes.grid}>
              {blocks}
            </Grid>
          </Grid>


          <footer className={classes.footer}>
            <Hidden smDown>
            <Container maxWidth="lg" className={classes.footerContainer}>
              <Typography variant="body1">
                Copyright © 2019 zhuchaox.com. All rights reserved. <ExternalLink href="https://beian.miit.gov.cn/">京ICP备案18040431号-{ba}</ExternalLink> | 京公网安备 11010502033607
              </Typography>
            </Container>
            </Hidden>
          </footer>
        </div>

      </React.Fragment>
    )
  }
}

export default withRouter(withStyles(styles)(Client));
