import React,  { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import withStyles from '@material-ui/styles/withStyles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Topbar from '../Topbar';
import { Mode, DataManager } from '../models/';

import Discover from './Discover';
import List from './List';
// import UnitAdmin from './UnitAdmin';
import Unit from './Unit';
import Edit from './Edit';
import Join from './Join';
import Users from '../UnitUsers/Users';
import Roles from './Roles';
import Plans from './Plans';
import Subscriptions from './Subscriptions';


const styles = theme => ({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
    marginTop: theme.spacing(2),
    padding: theme.spacing(0)
  }
});

class Units extends Component {

  render() {
    const { classes } = this.props;
    const currentPath = this.props.location.pathname;
    const path = '/' + this.props.match.path.split('/')[1];
    return (
      <React.Fragment>
        <CssBaseline />
        <Topbar currentPath={currentPath} />
        <div className={classes.root}>
        {DataManager.getInstance().mode >= Mode.ADMIN ?
          <React.Fragment>
            <Route path={path} exact component={ List } />
            <Route path={`${path}/a/:id`} component={ Unit } />
          </React.Fragment>
          :
          <Route path={path} exact component={ Unit } />
        }
        <Switch>
          <Route path={`${path}/e/:id`} component={ Edit } />
          <Route path={`${path}/s/:id`} component={ Unit } />
          <Route path={`${path}/roles`} component={ Roles } />
          <Route path={`${path}/members`} component={ Users } />
          <Route path={`${path}/join`} component={ Join } />
          <Route path={`${path}/discover`} component={ Discover } />
          <Route path={`${path}/plans`} exact component={ Plans } />
        </Switch>
        <Route path={`${path}/plans/discover`} exact component={ Subscriptions } />
        </div>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(Units);