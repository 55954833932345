import React, { Component } from 'react';
import withStyles from '@material-ui/styles/withStyles';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import T from 'i18n-react';
import { Event, DataManager } from '../models';
import Image from '../common/Image';

import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';


const styles = theme => ({
  root: {
    backgroundColor: 'white'
  },
  box: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    padding: theme.spacing(0.5)
  },
  title: {
    width: '100%',
    textAlign: 'center'
  },
  chip: {
    width: 160,
    height: 160,
    maxWidth: 160,
    maxHeight: 160,
    minWidth: 160,
    minHeight: 160,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  content: {
    
    maxWidth: '100%',
    maxHeight: '100%',
  },
  btns: {
    margin: theme.spacing(0.5),
    width: 160,
    height: 160,
    maxWidth: 160,
    maxHeight: 160,
    minWidth: 160,
    minHeight: 160,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
});


class MediaEdit extends Component {
  media = null;
  base = null;
  dragging = -1;
  adding = -1;
  state = {};
  file_ref = null;
  selected_files = null;
  uploading_key = null;
  uploading_path = null;
  state = {cursor: 0};

  constructor(props) {
    super(props);
    this.media = props.media;
  }

  onSave = (e) => {
    if (this.props.onSave)
      this.props.onSave(this.props.index, this.media);
    this.props.onClose(e);
  };

  onUpload = (files, url) => {
    const self = this;

    const params = {};
    if (files) params.files = files;
    if (url) params.url = url;
    if (this.uploading_path) params.path = this.uploading_path;
    return DataManager.getInstance().upload_media(params, 'materials').then(function (res) {
      if (res.c !== 0) {
        DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'error', m: T.translate('notification.failed')});
        return;
      }
      self.media = self.media.concat(res.d);
      self.forceUpdate();
      DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'success', m: T.translate('notification.success')});
    });
  };

  onPathUpload = (key, path) => {
    this.uploading_path = path;
    this.file_ref.click();
  };

  onAdd = (e) => {
    const tag = parseInt(e.currentTarget.dataset.tag);
    if (tag === -2) {
      this.uploading_path = null;
      this.file_ref.click();
    }
  };

  onDrag = (e) => {
    const tag = e.currentTarget.dataset.tag;
    this.dragging = parseInt(tag);
    this.adding = -1;
  };

  onDrop = (e) => {
    const drop = parseInt(e.currentTarget.dataset.tag);
    const media = this.media;
    if (this.dragging >= 0) {
      if (drop >= 0) {
        const drag = media[this.dragging];
        media[this.dragging] = media[drop];
        media[drop] = drag;
      }else{
        media.splice(this.dragging, 1);
      }
      this.forceUpdate();
    }
  };

  render() {
    const { open, onClose } = this.props;

    return (
      <React.Fragment>
        <Dialog open={open} onClose={onClose} fullWidth maxWidth='md' aria-labelledby="dialog-title">
          <DialogContent>
            {this.editor()}
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color="secondary">
              {T.translate('buttons.cancel')}
            </Button>
            <Button onClick={this.onSave} color="primary">
              {T.translate('buttons.save')}
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }

  editor() {
    const self = this;
    const { classes } = this.props;
    const media = this.media;
    const key = 'media';
    
    return (
      <Box className={classes.root}>
        <input
          ref={fileInput => self.file_ref = fileInput}
          style={{display: 'none'}}
          // accept="image/*"
          type="file"
          name="file"
          multiple="multiple"
          onChange={(e) => {
            self.onUpload(e.currentTarget.files, undefined, undefined);
          }}
        />
        <React.Fragment>
          <Typography className={classes.title} variant="subtitle1">{T.translate('materials.media')}</Typography>
          <Box className={classes.box}>
            <Box 
              className={classes.btns}
              key={-1}
              data-tag={-1}
              data-key={key}
              draggable={false}
              onDragOver={(e) => {
                e.stopPropagation();
                e.preventDefault();
              }}
              onDrop={self.onDrop}
            >
              <URLButton color="primary" variant="outlined" aria-label="url" data-tag={-1} spec_key={key} onClick={self.onUpload} >
                {T.translate('buttons.url')}
              </URLButton>
              <Button color="primary" variant="outlined" aria-label="local" data-tag={-2} data-key={key} onClick={self.onAdd} >
                {T.translate('buttons.upload')}
              </Button>
              <Button disabled={true} variant="outlined" aria-label="delete" data-tag={-3} data-key={key} >
                {T.translate('buttons.delete')}
              </Button>
            </Box>

            {media.map((label, index) => {            
              return (
                <Item
                  key={index}
                  data-tag={index}
                  data-key={key}
                  alt={label}
                  src={label}
                  classes={classes}
                  draggable={true}
                  onDragOver={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                  onDragStart={self.onDrag}
                  onDrop={self.onDrop}
                />
              );
            })}
          </Box>
        </React.Fragment>
      </Box>
    )
  }
}


function Item(props) {
  const { classes, src, alt, ...left } = props;
  const fn = decodeURIComponent(src.split('/').pop().split('#')[0].split('?')[0]).toLowerCase();
  const ft = fn.split('.').pop();
  const is_image = ['png', 'jpg', 'jpeg'].indexOf(ft) >= 0;

  const style = {margin: 5};
  return (
    <div {...left} className={classes.chip} style={style}>
      {is_image ?
      <img 
        draggable={false} 
        style={{cursor: 'grab'}} 
        className={classes.content} 
        src={src} 
        alt={alt} 
      />
      :
      <Button disabled={true} className={classes.chip} color="primary" variant="outlined">{fn}</Button>
      }
    </div>
  );
}


function URLButton(props) {
  const { url, onClick } = props;
  const key = props.spec_key;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [value, setValue] = React.useState(url);
  const [open, setOpen] = React.useState(false);
  const regexp =  /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;

  const handleSave = (e) => {
    onClick(undefined, value, key);
    setOpen(false);
    setAnchorEl(null);
  };

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const id = open ? 'simple-popover' : undefined;

  return (
    <React.Fragment>
      <Button color="primary" variant="outlined" aria-label="url" data-tag={-1} data-key={key} onClick={handleClick} >
        {T.translate('buttons.url')}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
      >
        <div style={{padding: 16}}>
        <TextField
          onChange={handleChange}
          autoFocus
          margin="dense"
          id="url"
          label="URL"
          type="url"
          fullWidth
        />
        <Button color="primary" disabled={!regexp.test(value)} data-tag={-1} data-key={key} onClick={handleSave} >
          {T.translate('buttons.submit')}
        </Button>
        </div>
      </Popover>
    </React.Fragment>
  );
}


function MediaSet(props) {
  const { title, index, size, media, classes } = props;

  const [open, setOpen] = React.useState(false);

  function handleClickOpen(e) {
    e.stopPropagation();
    setOpen(true);
  }

  function handleClose(e) {
    e.stopPropagation();
    setOpen(false);
  }
  const image = media && media.length > 0 ? media[0] : undefined;

  return (
    <div onClick={handleClickOpen} style={{display: 'inline-block'}}>
      <Image
        style={{ height: size, width: size, maxWidth: size, maxHeight: size, minWidth: size, minHeight: size }}
        alt={image || ''}
        src={image}
        title={title || T.translate('trackers.n_images', {c: image ? media.length : 0})}
      />
      
      {open &&
      <MediaEdit
        index={index}
        classes={classes}
        media={media}
        open={open}
        onClose={handleClose}
        onSave={props.onSave}
      />
      }
    </div>
  );
}


export default withStyles(styles)(MediaSet);
