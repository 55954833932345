/**
 * Created by milan on 2019/7/18.
 */
import React, { Component } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import Button from '@material-ui/core/Button';
import { TextField } from 'formik-material-ui';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { Formik, Form, Field } from 'formik';
import T from 'i18n-react';
import { Event, DataManager } from '../models/';


const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white
    }
  },
  paper: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2)
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
});

const validate = values => {
  let errors = {};
  if (!values.orders) {
    errors.orders = T.translate('errors.required');
  }
  if (!values.rate) {
    errors.rate = T.translate('errors.required');
  }
  if (!values.rank) {
    errors.rank = T.translate('errors.required');
  }
  if (Object.keys(errors).length)
    return errors;
};

class Stats extends Component {
  state = {
    debug: false
  };

  action = (values, actions) => {
    return DataManager.getInstance().update_unit_config(this.props.unit.id, values).then(function (res) {
      let errors = {};
      if (res.c !== 0) {
        errors.name = T.translate('errors.' + res.c);
        actions.setSubmitting(false);
        if (Object.keys(errors).length) {
          actions.setErrors(errors);
          //actions.setStatus(errors);
        }
        return;
      }
      DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'success', m: T.translate('notification.success')});
    });
  };

  render() {
    const { classes, unit } = this.props;
    return (
      <React.Fragment>
        <Container component="main" maxWidth="xs">
          <Paper className={classes.paper}>
            <Formik
              initialValues={{ orders: unit.stats.orders, rate: unit.stats.rate, 'rank': unit.stats.rank }}
              validate={validate}
              validateOnChange={true}
              onSubmit={this.action}
            >
              {props => {
                return (
                  <Form className={classes.form} noValidate>
                    <Field
                      component={TextField}
                      error={!!props.errors.orders}
                      helperText={props.errors.orders}
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      label={T.translate("units.orders")}
                      name="orders"
                    />
                    <Field
                      component={TextField}
                      error={!!props.errors.rate}
                      helperText={props.errors.rate}
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      label={T.translate("units.rate")}
                      name="rate"
                    />
                    <Field
                      component={TextField}
                      error={!!props.errors.rank}
                      helperText={props.errors.rank}
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      label={T.translate("units.rank")}
                      name="rank"
                    />

                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      disabled={props.isSubmitting}
                    >
                      {T.translate("buttons.submit")}
                    </Button>
                  </Form>
                );
              }}
            </Formik>
          </Paper>
        </Container>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(Stats);