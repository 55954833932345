import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/styles/withStyles';
import { Event, Mode, DataManager } from '../models/';
import T from 'i18n-react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';

import { TextField } from 'formik-material-ui';
import { Formik, Form, Field } from 'formik';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { green } from '@material-ui/core/colors';
import InputAdornment from '@material-ui/core/InputAdornment';
import SendIcon from '@material-ui/icons/Send';
import PlayIcon from '@material-ui/icons/PlayCircleOutline';
import UploadIcon from '@material-ui/icons/AddCircle';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import ImagesDialog from '../dialogs/ImagesDialog';
import VideoDialog from '../dialogs/VideoDialog';


const styles = theme => ({
  container: {
    minWidth: 320,
    padding: theme.spacing(1),
    //maxHeight: '50vh',
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.down('sm')]: {
      minWidth: 320,
      width: 320
    },
    [theme.breakpoints.up('sm')]: {
      minWidth: 480,
      width: 480
    },
    [theme.breakpoints.up('md')]: {
      minWidth: 480,
      width: 480
    }
  },
  box: {
    height: '60vh',
    minHeight: '60vh',
    overflow: 'auto',
    position: 'relative'
  },
  list: {
    bottom: 0,
    width: '100%',
    minHeight: '60vh',
    display: 'flex',
    flexDirection: 'column-reverse',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  form: {
    paddingTop: theme.spacing(0)
  },
  paper: {
    padding: theme.spacing(1),
    maxWidth: '75%',
  },
  paperSelf: {
    padding: theme.spacing(1),
    backgroundColor: green[500],
    maxWidth: '75%'
  },
  inline: {
    display: 'block'
  },
  inlineRight: {
    //display: 'block',
    textAlign: 'right'
  },
  imageLeft: {
    padding: theme.spacing(0),
    maxWidth: '40%',
    maxHeight: '40%'
  },
  imageRight: {
    padding: theme.spacing(0),
    maxWidth: '40%',
    maxHeight: '40%',
    display: 'block',
    float: 'right'
  },
  img: {
    maxWidth: '100%',
    maxHeight: '100%',
    verticalAlign: 'middle'
  },
  player: {
    maxWidth: '100%',
    //maxHeight: '100%',
    width: '100%',
    //height: '40vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
});

class ChatBoard extends Component {
  chats = [];
  chat_id = 0;
  room = {};
  state = {show: false, request: false, c: 0, done: false, url: null};
  messagesEnd = null;
  file_ref = null;
  mode = 0;

  //constructor(props) {
  //  super(props);
  //  this.list();
  //}

  //componentDidUpdate() {
  //  this.scrollToBottom(false);
  //}

  handleScroll = (event) => {
    const e = event.target;
    if (e.scrollTop < 1)
     this.list();
  };

  list = () => {
    if (this.state.done) return;
    let self = this;
    const params = {page: this.state.page};
    if (this.chats && this.chats.length > 0) {
      params.first = this.chats[0].create_at;
      params.last = this.chats[this.chats.length - 1].create_at;
    }
    const first_page = self.chats.length === 0;
    return DataManager.getInstance().list_chats(this.props.remote, this.props.type, this.props.about, params).then(function (res) {
      if (res.c === 0) {
        const chats = res.d.chats;
        const done = chats.length === 0;
        let i;
        for (i = 0; i < chats.length; ++i) {
          self.chats.push(chats[i]);
        }
        if (first_page) {
          self.room = res.d.chat;
          self.chat_id = res.d.chat.id;
        }
        self.setState({show: true, done: done});
        if (first_page)
          self.scrollToBottom(true);
        DataManager.getInstance().pub(Event.DISPLAY, {c: 0});
      }
    })
  };

  send = (values, actions) => {
    if (!values.msg || values.msg.length === 0) {
      actions.resetForm();
      return;
    }
    let self = this;
    const params = {msg: values.msg};
    if (this.chats && this.chats.length > 0) {
      params.first = this.chats[0].create_at;
      params.last = this.chats[this.chats.length - 1].create_at;
    }
    return DataManager.getInstance().send_chat(this.chat_id, params).then(function (res) {
      if (res.c === 0) {
        actions.resetForm();
        const chats = res.d.chats;
        let i;
        for (i = chats.length - 1; i >= 0; --i) {
          self.chats.unshift(chats[i]);
        }
        self.setState({show: true});
        self.scrollToBottom(true);
      }
      actions.setSubmitting(false);
    })
  };

  sendMedia = (values, actions) => {
    let self = this;
    const params = {file: values.file};
    if (this.chats && this.chats.length > 0) {
      params.first = this.chats[0].create_at;
      params.last = this.chats[this.chats.length - 1].create_at;
    }
    return DataManager.getInstance().send_chat_media(this.chat_id, params, null).then(function (res) {
      if (res.c === 0) {
        const chats = res.d.chats;
        let i;
        for (i = chats.length - 1; i >= 0; --i) {
          self.chats.unshift(chats[i]);
        }
        self.setState({show: true});
        self.scrollToBottom(true);
      }
      actions.setSubmitting(false);
    });
  };

  validate = values => {
    let errors = {};
    if (!values.msg || values.msg.length === 0) {
      errors.msg = T.translate('errors.required');
    }
    if (Object.keys(errors).length)
      return errors;
  };

  scrollToBottom = (force) => {
    if (!force && this.state.page > 2) return;
    const end = this.messagesEnd;
    setTimeout(function () {
      if (end) end.scrollIntoView({ behavior: 'smooth' });
    }, 100);
  };

  onClose = () => {
    if (this.state.video) {
      this.setState({video: null});
    }else if (this.state.url) {
      this.setState({url: null});
    }else{
      this.props.onClose();
    }
  };

  render() {
    const { open, anchorEl } = this.props;
    const id = open ? 'chat-popover' : undefined;
    const images = [];
    if (this.state.url != null) {
      const image = {label: '', description: '', imgPath: this.state.url};
      images.push(image);
    }
    return (
      <div>
        <Popover
          id={id}
          style={{zIndex: 1001}}
          anchorEl={anchorEl}
          open={open}
          onClose={this.onClose}
          onEntered={this.list}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
        >
          {this.table()}
        </Popover>

        <ImagesDialog
          maxWidth="xl"
          images={images}
          open={!!this.state.url}
          onClose={this.onClose}
        />
        
        <VideoDialog
          maxWidth="xl"
          url={this.state.video}
          open={!!this.state.video}
          onClose={this.onClose}
        />
      </div>
    )
  }

  table() {
    const { classes } = this.props;
    const chats = this.chats;
    const self = this;
    const is_seller = DataManager.getInstance().mode >= Mode.BUSINESS;
    const my_key = self.room.me !== undefined ? self.room.me : (is_seller ? 'right' : 'left');
    const remote_key = my_key === 'left' ? 'right' : 'left';

    return (
      <Container component="main" className={classes.container}>
        <div className={classes.box} onScroll={this.handleScroll}>
          <List className={classes.list}>
            {chats.map((chat, index) => {
              let bubble = chat.me ?
                <ListItem key={chat.id} component="div"
                          style={{width: '100%', display:'flex', flexDirection: 'column', alignItems: 'flex-end', justifyContent:'flex-end'}}>
                  <div style={{width: '100%', display:'flex', alignItems: 'flex-end', justifyContent:'flex-end'}}>
                    <Typography
                      component="span"
                      variant="body2"
                      className={classes.inlineRight}
                      color="textPrimary"
                    >
                      {this.room[my_key]}
                    </Typography>
                  </div>

                  {chat.type === 100 && chat.msg.length > 4 &&
                    <Paper className={classes.imageRight} onClick={() => { self.setState({url: chat.msg}); }}>
                      <img className={classes.img} src={chat.msg} alt={chat.msg}/>
                    </Paper>
                  }

                  {chat.type === 120 && chat.msg.length > 4 &&
                    <div className={classes.imageRight}>
                      <IconButton
                        style={{padding: 0, width: 96, height: 96}}
                        onClick={() => { self.setState({video: chat.msg}); }}
                      >
                        <PlayIcon color="primary" fontSize="large" style={{paddingRight: 0, width: 96, height: 96}} />
                      </IconButton>
                      <Typography component="span" variant="caption" style={{display: 'block', width: '100%', textAlign: 'center'}}>
                        {T.translate('chats.play')}
                      </Typography>
                    </div>
                  }

                  {chat.type < 100 &&
                    <Paper className={classes.paperSelf}>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textPrimary"
                      >
                        {chat.msg}
                      </Typography>
                    </Paper>
                  }
                </ListItem>
                :
                <ListItem key={chat.id} alignItems="flex-start" component="div"
                          style={{width: '100%', display:'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent:'flex-start'}}>
                  <div style={{width: '100%', display:'flex', alignItems: 'flex-start', justifyContent:'flex-start'}}>
                    <Typography
                      component="span"
                      variant="body2"
                      className={classes.inline}
                      color="textPrimary"
                    >
                      {this.room[remote_key]}
                    </Typography>
                  </div>

                  {chat.type === 100 && chat.msg.length > 4 &&
                    <Paper className={classes.imageLeft} onClick={() => { self.setState({url: chat.msg}); }}>
                      <img className={classes.img} src={chat.msg} alt={chat.msg}/>
                    </Paper>
                  }

                  {chat.type === 120 && chat.msg.length > 4 &&
                    <div className={classes.imageLeft}>
                      <IconButton
                        style={{padding: 0, width: 96, height: 96}}
                        onClick={() => { self.setState({video: chat.msg}); }}
                      >
                        <PlayIcon color="primary" fontSize="large" style={{paddingRight: 0, width: 96, height: 96}} />
                      </IconButton>
                      <Typography component="span" variant="caption" style={{display: 'block', width: '100%', textAlign: 'center'}}>
                        {T.translate('chats.play')}
                      </Typography>
                    </div>
                  }

                  {chat.type < 100 &&
                    <Paper className={classes.paper}>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textPrimary"
                      >
                        {chat.msg}
                      </Typography>
                    </Paper>
                  }
                </ListItem>;
              let showTime = index === chats.length - 1;
              if (!showTime) {
                const next = chats[index + 1];
                if (chat.ts - next.ts > 60) {
                  showTime = true;
                }
              }
              if (showTime)
                bubble = <React.Fragment key={chat.id}>
                  {bubble}
                  <div
                    style={{width: '100%', display:'flex', flexDirection: 'row', alignItems: 'center', justifyContent:'center'}}>
                    <Typography
                      component="span"
                      variant="body2"
                      color="textPrimary"
                    >
                      {chat.create_at.substring(0, chat.create_at.length - 3)}
                    </Typography>
                  </div>
                </React.Fragment>;
              return bubble;
            })}
          </List>
          <Typography style={{ float:"left", clear: "both" }} ref={(el) => { self.messagesEnd = el; }} />
        </div>

        <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
          <Formik
            initialValues={{ msg: '' }}
            //validate={this.validate}
            validateOnChange={false}
            onSubmit={this.send}
          >
            {props => {
              return (
                <Form className={classes.form} style={{width: '100%'}} noValidate>
                  <Field
                    component={TextField}
                    error={!!props.errors.disabled}
                    helperText={props.errors.disabled}
                    className={classes.input}
                    variant="outlined"
                    margin="normal"
                    multiline={true}
                    fullWidth
                    id="msg"
                    label={T.translate('chats.msg')}
                    name="msg"
                    autoFocus
                    InputProps={{
                      inputProps: { maxLength: 100 },
                      endAdornment: <InputAdornment position="end">
                        <IconButton
                          type="submit"
                          disabled={props.isSubmitting}
                          style={{paddingRight: 0}}
                          //onClick={self.sendButton}
                        >
                          <SendIcon color="primary" size={64} />
                        </IconButton>
                      </InputAdornment>
                    }}
                  />
                </Form>
              );
            }}
          </Formik>

          <Formik
            initialValues={{ file: null }}
            //validate={this.validate}
            validateOnChange={false}
            onSubmit={this.sendMedia}
          >
            {props => {
              return (
                <Form className={classes.form} style={{width: '10%', height: '100%'}} noValidate>
                  <IconButton
                    style={{height: 48, marginTop: 8}}
                    type="button"
                    disabled={props.isSubmitting}
                    onClick={(e) => {
                    self.file_ref.click();
                  }}
                  >
                    <UploadIcon size={44} color="primary" />
                  </IconButton>
                  <input
                    ref={fileInput => self.file_ref = fileInput}
                    style={{display: 'none'}}
                    accept="image/*"
                    type="file"
                    name="file"
                    onChange={(e) => {
                    props.setFieldValue('file', e.currentTarget.files, false);
                    props.submitForm();
                  }}
                  />
                </Form>
              );
            }}
          </Formik>
        </div>

      </Container>
    );
  }
}

export default withRouter(withStyles(styles)(ChatBoard));
