import React,  { Component } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import ProductsList from '../Product/List';
import ResponsiveButton from '../buttons/ResponsiveButton';
import T from 'i18n-react';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import BackIcon from '@material-ui/icons/ArrowBack';
import Typography from '@material-ui/core/Typography';

import { Event, Mode, DataManager } from '../models/';
import { priceDisplay } from '../common/Constants';
import Edit from '../Inquiry/SimpleEdit';

import withWidth from '@material-ui/core/withWidth';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import CheckedIcon from '@material-ui/icons/CheckBox';
import UncheckedIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Button from '@material-ui/core/Button';


const styles = theme => ({
  titleBar: {
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2)
  },
  title: {
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(0.5)
    }
  },
  backButton: {
    position: 'absolute',
    padding: theme.spacing(2),
    left: 0,
    top: 0,
    color: theme.palette.grey[500]
  },
  closeButton: {
    position: 'absolute',
    padding: theme.spacing(2),
    right: 0,
    top: 0,
    color: theme.palette.grey[500]
  },

  btns: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(1),
    padding: theme.spacing(1)
  },

  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    // backgroundColor: 'transparent',
    // backgroundColor: theme.palette.background.paper,
  },
  tile: {
    marginBottom: theme.spacing(1),
    cursor: 'pointer'
  },
  image: {
    // cursor: 'pointer',
    minWidth: '100%',
    minHeight: '100%',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  img: {
    width: '100%',
    objectFit: 'cover',
    minWidth: '100%',
    minHeight: '100%',
    verticalAlign: 'middle'
  },
});


const maxCount = 100;


class Products extends Component {
  state = {chosen: [], inquiry: 0, requesting: false};
  pid = 0;
  
  constructor(props) {
    super(props);
    if (this.props.match.params.pid > 0)
      this.pid = this.props.match.params.pid;
  }

  list = () => {
    const self = this;
    if (!this.state.requesting) this.setState({requesting: true});
    const params = {pid: this.pid, page: 1, f: 0, load_all: 1};
    return DataManager.getInstance().list_user_products(params).then(function (res) {
      if (res.c === 0) {
        let d = res.d;
        self.products = d.ps;
        self.setState({requesting: false, inquiry: 1});
      }
    });
  };

  onInquiry = (e) => {
    if (!this.products)
      this.list();
    this.setState({inquiry: 1});
  }

  onSelect = (e) => {
    const index = parseInt(e.currentTarget.dataset.tag);
    const product_id = this.products[index].product_id;
    const chosen = this.state.chosen.slice();
    const idx = chosen.indexOf(product_id);
    if (idx >= 0) {
      chosen.splice(idx, 1);
    }else{
      if (chosen.length >= maxCount) {
        this.alertTooMuch();
        return;
      }
      chosen.push(product_id);
    }
    this.setState({chosen: chosen});
  }

  onBatch = (e) => {
    const chosen = [];
    let i;
    if (e.currentTarget.dataset.tag === '0') {
      for (i = 0; i < this.products.length; ++i) {
        chosen.push(this.products[i].product_id);
        if (chosen.length >= maxCount) {
          this.alertTooMuch();
          break;
        }
      }
    }
    this.setState({chosen: chosen});
  }

  alertTooMuch = () => {
    DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'error', m: T.translate('errors.inquiries.too_much', {c: maxCount})});
  }

  render() {
    const products = this.products;
    const { classes, width, ...other } = this.props;
    let cols = 5;
    if (width === 'xs') cols = 2;
    else if (width === 'sm') cols = 2;
    else if (width === 'md') cols = 4;
    else if (width === 'lg') cols = 5;
    let title;
    if (this.state.inquiry >= 2) {
      title = T.translate('products.pre_order');
    }else if (this.state.chosen.length > 0) {
      title = `${T.translate('deals.chosen')}: ${this.state.chosen.length} / ${this.products.length} ${T.translate('deals.unit')}`;
    }else{
      title = T.translate('products.select_product');
    }
    
    const self = this;

    const cell_title = (product) => {
      if (DataManager.getInstance().mode === Mode.ADMIN && product.unit)
        return product.title + ' / ' + product.unit.display;
      if (product.price) {
        const price = priceDisplay(product.price, product.currency);
        if (price)
          return product.title + ' / ' + price;
      }
      return product.title;
    }
    const onClose = e => self.setState({inquiry: 0});

    return (
      <React.Fragment>
        <Grid item xs={12} sm={8} md={8} lg={6} xl={6} className={classes.btns}>
          <div></div>
          {DataManager.getInstance().permit('Quote') &&
          <ResponsiveButton color='primary' variant="contained" align="center" onClick={this.onInquiry}>
            {T.translate('products.pre_order')}
          </ResponsiveButton>
          }
        </Grid>
        <Grid item xs={12} />
        <ProductsList {...other} />

        <Dialog
          maxWidth="xl"
          fullScreen={width === 'xs'}
          open={this.state.inquiry > 0}
          onClose={onClose}
          scroll='paper'
        >
          <DialogTitle disableTypography className={classes.titleBar}>
            {this.state.inquiry > 1 &&
            <IconButton aria-label="back" className={classes.backButton} onClick={e => self.setState({inquiry: 1})}>
              <BackIcon />
            </IconButton>
            }
            <Typography align="center" variant="h5" className={classes.title}>{title}</Typography>
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            {this.state.inquiry === 1 &&
              <React.Fragment>
                <GridList cols={cols} cellHeight={250} spacing={12} className={classes.gridList}>
                  <GridListTile key="Subheader" cols={cols} style={{height: 'auto', display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                    {products && products.length > 0 &&
                    <React.Fragment>
                    <Button data-tag="0" variant="outlined" color="primary" onClick={this.onBatch}>
                      {T.translate('buttons.select_all')}
                    </Button>
                    <div style={{display: 'inline-block', width: 20}}></div>
                    <Button data-tag="1" variant="outlined" color="primary" onClick={this.onBatch}>
                      {T.translate('buttons.deselect_all')}
                    </Button>
                    </React.Fragment>
                    }
                  </GridListTile>
                  {products && products.map((product, index) => (
                    <GridListTile key={`${product.id}.${index}`} data-tag={index} className={classes.tile} onClick={this.onSelect}>
                      <div style={{position: 'absolute', top: 0, right: 0}}>
                        {this.state.chosen.indexOf(product.product_id) >= 0 ?
                          <CheckedIcon color="primary" style={{margin: 8}} />
                          :
                          <UncheckedIcon style={{margin: 8, color: 'green'}} />
                        }
                      </div>
                      <div className={classes.image}>
                        <img className={classes.img} src={product.media[0]} alt={product.title} />
                      </div>
                      <GridListTileBar
                        title={cell_title(product)}
                        subtitle={<span>{product.brand} / {product.item_type}</span>}
                      />
                    </GridListTile>
                  ))}
                </GridList>
              </React.Fragment>
            }
            {this.state.inquiry === 2 &&
            <Edit pid={this.pid} chosen={this.state.chosen} />
            }
          </DialogContent>

          {this.state.inquiry === 1 &&
          <DialogActions>
            <Button data-tag={1} disabled={this.state.chosen.length === 0} variant="contained" color="primary" onClick={e => self.setState({inquiry: 2})}>
              {T.translate('products.pre_order')}
            </Button>
          </DialogActions>
          }
        </Dialog>
      </React.Fragment>
    );
  }
}


export default withWidth()(withStyles(styles)(Products));