import React,  { Component } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';

const styles = theme => ({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
    padding: theme.spacing(0)
  },
  paper: {
    textAlign: 'center',
    color: theme.palette.text.secondary,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  text: {
    margin: theme.spacing(2)
  }
});

class Policy extends Component {
  render() {
    const { classes } = this.props;

    let name = this.props.match.params.name;
    if (name !== 'app') name = 'zc';

    return (
      <Container component="main" className={classes.root} maxWidth="md">
        <Paper className={classes.paper}>
          <iframe title="policy" style={{border: 0, width: '100%', minHeight: '90vh'}} src={`/docs/${name}/policy.html`} ></iframe>
        </Paper>

      </Container>
    )
  }
}

export default withStyles(styles)(Policy);