import React, {Component} from 'react';
import withStyles from '@material-ui/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Back from './Back';


const styles = theme => ({
  titleBar: {
    flexGrow: 1,
    overflow: 'hidden',
    marginTop: theme.spacing(2),
    padding: theme.spacing(2)
  },
  center: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
});


class TitleBar extends Component {

  render() {
    const { classes, left, title, titleComponent } = this.props;

    return (
      <Grid container justify="center">
        <Grid item xs={4} sm={4} md={2} className={classes.center}>
          {left && left.show && <Back title={left.title} to={left.to} />}
        </Grid>
        <Grid item xs={4} sm={4} md={8} className={classes.center}>
          {titleComponent ?
          titleComponent
            :
          <Typography align="center" variant="h6">{title.title}</Typography>
          }
        </Grid>
        <Grid item xs={4} sm={4} md={2} className={classes.center}>
          {this.props.children}
        </Grid>
      </Grid>
    )
  }
}

export default withStyles(styles)(TitleBar);