/**
 * Created by milan on 2019/7/18.
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/styles/withStyles';
import T from 'i18n-react';
import { Event, Mode, DataManager } from '../models/';
import { priceDisplay } from '../common/Constants';
import ImageCarousel from '../common/ImageCarousel';
import SpecsDisplay from './SpecsDisplay';
import BaseDialog from '../dialogs/BaseDialog';
import Quality from '../Doc/Quality';

import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Help';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import Box from '@material-ui/core/Box';
import { grey } from '@material-ui/core/colors';
import MaterialsBlock from './MaterialsBlock';
import { MaterialsCustomizer } from '../Material/Customizer';
import CollectToProjects from './CollectToProjects';
import Customizer from './Customizer';
import QuoteProduct from './QuoteProduct';


const styles = theme => ({
  container: {
    padding: theme.spacing(2)
  },
  priceBox: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 3, 1, 3)
  },
  materials: {
    display: 'inline-block',
    // flexDirection: 'row',
    // justifyContent: 'start',
    // alignItems: 'center',
  },
  bottom: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-end'
  },
  box: {
    padding: theme.spacing(1, 3, 1, 3)
  },
  titleBar: {
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
    position: 'relative'
  },
  title: {
    width: '100%',
    height: 48,
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(0.5)
    }
  },
  itemButton: {
    margin: 5
  },
  closeButton: {
    width: 64,
    height: 64,
    position: 'absolute',
    padding: theme.spacing(0),
    right: theme.spacing(0),
    top: theme.spacing(0),
    color: theme.palette.grey[500]
  },
  price: {
    fontSize: 32,
    lineHeight: 1,
    verticalAlign: 'bottom'
  },
  label: {
    fontSize: 13,
    marginRight: theme.spacing(1)
  },
  info: {
    fontSize: 16
  }
});


class Show extends Component {
  pid = 0;
  id = -1;
  product = null;
  optionalLabels = [
    {k: 'brand', tk: 'brand'},
    {k: 'model', tk: 'item_model'}
  ];
  labels = [
    // {k: 'material', tk: 'material'},
    // {k: 'size', tk: 'size'},
    {k: 'item_type', tk: 'item_type'},
    {k: 'item_space', tk: 'item_space'},
    {k: 'item_style', tk: 'item_style'},
    // {k: 'manufacturing_time', tk: 'manufacturing_time'},
    {k: 'item_designer', tk: 'item_designer'},
    {k: 'desc', tk: 'desc'}
  ];
  quoting = false;
  mats = {i: [], c: []};

  constructor(props) {
    super(props);
    this.quoting = DataManager.getInstance().mode === Mode.BUSINESS;
    if (this.props.match.params.pid > 0)
      this.pid = this.props.match.params.pid;

    const idx = props.location.search.substring(1);
    const index = parseInt(idx);

    this.state = {
      params: {},
      show: false,
      index: index >= 0 ? index : -1,
      sub: false,
      mat: undefined,
      size: undefined,
      quality: undefined
    };

    const search = props.location.search.substring(1);
    if (search) {
      const keys = {i: 'item_id', q: 'quality', s: 'size', m: 'material', m1: 'material1', m2: 'material2', 'new': 'new', 'pid': 'pid', 'flt': 'flt', 'uid': 'uid'};
      const params = search.split('&').reduce(function(prev, curr, i, arr) {
        var p = curr.split('=');
        prev[keys[p[0]]] = decodeURIComponent(p[1]);
        return prev;
      }, {});
      if (DataManager.getInstance().mode >= Mode.ADMIN) this.quoting = !!params.uid;
      this.state.params = params;
    }

    if (props.product) {
      if (props.product.customizer && props.product.customizer.length > 0) {
        this.id = props.product.product_id;
        this.show();
      }else{
        const product = props.product;
        product.items = [product];
        product.qualities = [product.quality];
        const update = this.init(product);
        const self = this;
        Object.keys(update).forEach(function (k, index) {
          self.state[k] = update[k];
        });
        this.state.show = true;
      }
      return;
    }

    let id = props.match.params.id;
    if (id) this.id = parseInt(id);
    if (this.id > 0) {
      this.show();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.location.search !== nextProps.location.search) {
      const search = nextProps.location.search.substring(1);
      if (search) {
        const keys = {i: 'item_id', q: 'quality', s: 'size', m: 'material', m1: 'material1', m2: 'material2', 'new': 'new', 'pid': 'pid', 'uid': 'uid'};
        const params = search.split('&').reduce(function(prev, curr, i, arr) {
          var p = curr.split('=');
          prev[keys[p[0]]] = decodeURIComponent(p[1]);
          return prev;
        }, {});
        nextState.params = params;
        if (DataManager.getInstance().mode >= Mode.ADMIN) this.quoting = !!params.uid;
      }else{
        nextState.params = {};
      }
      this.show();
    }
    return true;
  }

  onQuote = (product) => {
    DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'success', m: T.translate('notification.success')});
    const params = {};
    const keys = ['item_id', 'quality', 'size', 'material', 'material1', 'material2', 'price', 'uid'];
    const ids = ['i', 'q', 's', 'm', 'm1', 'm2', 'uid'];
    for (var i = 0; i < keys.length; ++i) {
      const key = keys[i];
      if (product[key])
        params[ids[i]] = product[key];
    }
    const qs = Object.keys(params).map((key) => {
      return key + '=' + encodeURIComponent(params[key])
    }).join('&');
    DataManager.getInstance().pub(Event.REDIRECT, {pathname: this.props.location.pathname, search: qs});
    // this.setState({params: product});
  }

  initWithParams = (product) => {
    const params = this.props.product || this.state.params;
    const ns = {show: true};
    const iid = params.item_id ? parseInt(params.item_id) : undefined;
    let i;
    for (i = 0; i < product.items.length; ++i) {
      const item = product.items[i];
      if (iid > 0) {
        if (item.item_id === iid) {
          ns.index = i;
          break;
        }
        continue;
      }
      if (!params.size || params.size !== item.size || !params.quality || params.quality !== item.quality) continue;
      if (params.material1 && item.material1 === params.material1) {
        ns.index = i;
        break;
      }
      if (params.material && item.material === params.material) {
        ns.index = i;
        break;
      }
    }
    if (ns.index >= 0) {
      const item = product.items[ns.index];
      ns.mat = item.material;
      ns.size = item.size;
      ns.quality = item.quality;
    }else{
      if (product.mats.indexOf(params.material) >= 0)
        ns.mat = params.material;
      if (product.sizes.indexOf(params.size) >= 0)
        ns.size = params.size;
      if (product.qualities.indexOf(params.quality) >= 0)
        ns.quality = params.quality;
      if (ns.mat && ns.size && ns.quality) {
        for (i = 0; i < product.items.length; ++i) {
          const item = product.items[i];
          if (ns.size === item.size && ns.mat === item.material && ns.quality === item.quality) {
            ns.index = i;
            break;
          }
        }
      }
      if (!ns.mat && !ns.size && !ns.quality && product.items.length > 0) {
        ns.index = 0;
        const item = product.items[ns.index];
        ns.mat = item.material;
        ns.size = item.size;
        ns.quality = item.quality;
      }
    }
    return ns;
  };

  init = (product) => {
    const self = this;
    if (!product.qualities) {
      if (this.props.customizable)
        product.qualities = ['200', '300', '400'];
      else
        product.qualities = ['100', '200', '300', '400'];
    }

    product.zcSizes = product.sizes;
    product.zcMats = product.mats;
    if (!product.item_id) product.item_id = 0;
    product.customized = [];
    let i;
    product.sizes = [];
    product.mats = [];
    const mic = self.mats;
    for (i = 0; i < product.items.length; ++i) {
      const item = product.items[i];
      if (this.quoting) item.quality = '300';
      const marker = [];
      if (item.material1 && item.material1.length > 0) {
        const codes = item.material1.split(',');
        for (var j = 0; j < codes.length; ++j) {
          const code = codes[j];
          marker.push(code.length > 10 ? code.substring(0, 10) : code);
        }
      }
      item.marker = marker.join(',');

      if (product.sizes.indexOf(item.size) < 0)
        product.sizes.push(item.size);
      if (product.mats.indexOf(item.material) < 0)
        product.mats.push(item.material);
      
      if (item.quality === '100' && mic.i.indexOf(item.material) < 0)
        mic.i.push(item.material);
      if (item.quality !== '100' && mic.c.indexOf(item.material) < 0)
        mic.c.push(item.material);
    }
    if (product.zcSizes) product.sizes = product.sizes.concat(product.zcSizes).filter((v, i, a) => a.indexOf(v) === i);
    if (product.zcMats) product.mats = product.mats.concat(product.zcMats).filter((v, i, a) => a.indexOf(v) === i);
    // const update = {show: true};
    if (product.customizer && product.customized.length < product.customizer.length) {
      for (i = 0; i < product.customizer.length; ++i) {
        product.customized.push(undefined);
      }
    }
    for (i = 0; i < product.mats.length; ++i) {
      const mat = product.mats[i];
      if (mic.i.indexOf(mat) < 0 && mic.c.indexOf(mat) < 0) {
        mic.i.push(mat);
        mic.c.push(mat);
      }
    }
    const update = self.initWithParams(product);
    let qs = self.props.product || self.state.params;
    if (product.customizer && !qs.material1) {
      for (i = 0; i < product.items.length; ++i) {
        const item = product.items[i];
        if (item.material1 && item.material1.length > 0) {
          qs = item;
          break;
        }
      }
    }
    if (product.customizer && qs.material1 && qs.material2) {
      const codes = qs.material1.split(',');
      const titles = qs.material2.split(', ');
      if (codes.length === product.customizer.length && titles.length === product.customizer.length) {
        for (i = 0; i < product.customizer.length; ++i) {
          product.customized[i] = {identifier: codes[i], title: titles[i]};
        }
      }
    }
    if (update.index >= 0) {
      update.mode = product.customizer ? 1 : 0;
    }else if (product.customizer) {
      //update.index = -1;
      update.mode = 1;
    }else{
      update.mode = 0;
    }
    if (self.quoting) {
      update.mode = 0;
      update.quality = '300';
    }
    self.product = product;
    return update;
  }

  customizing = (q) => this.state.mode === 1 && q !== '100';

  show = () => {
    const self = this;
    const params = {...this.state.params};
    if (this.pid > 0) params.pid = this.pid;
    const onDone = function (res) {
      if (res.c === 0) {
        // const product = res.d.p;
        const update = self.init(res.d.p);
        self.setState(update);
      }
    };
    if (this.quoting){
      params.pid = this.id;
      DataManager.getInstance().quote_product(true, params).then(onDone);
    }else
      DataManager.getInstance().show_product(this.id, params).then(onDone);
  };

  render() {
    const product = this.product;
    if (!this.state.show || !product) return <React.Fragment/>;
    const { classes } = this.props;
    const has_item = this.state.index >= 0 && this.state.index < product.items.length;
    const items_directly = !this.customizing(this.state.quality);
    const item = has_item ? product.items[this.state.index] : {};
    const media = has_item && item.media && item.media.length > 0 ? item.media : product.media;
    let n = 0;
    let specs = item;
    if (item.specs) {
      n = parseInt(item.specs.split(';')[0]);
    }
    if (!(n > 0) && product.specs) {
      n = parseInt(product.specs.split(';')[0]);
      specs = product;
    }
    const params = {deal_id: this.props.deal_id};
    let customizable = !!this.state.size && !!this.state.quality;
    let code;
    let title;
    let material2;
    if (items_directly) {
      if (has_item) {
        code = item.material1;
        title = item.material2 || item.material;
        material2 = item.material2;
      }else{
        customizable = false;
        title = this.state.mat;
        if (title) {
          let i;
          for (i = 0; i < product.items.length; ++i) {
            const item = product.items[i];
            if (item.material === title) {
              code = item.material1;
              material2 = item.material2;
              break;
            }
          }
        }
      }
    }else{
      const codes = [];
      const titles = [];
      if (product.customized && product.customized.length > 0) {
        for (var j = 0; j < product.customized.length; ++j) {
          if (product.customized[j] && product.customized[j].identifier) {
            codes.push(product.customized[j].identifier);
            titles.push(product.customized[j].title);
          }
        }
      }
      if (codes.length === product.customizer.length) {
        code = codes.join(',');
        title = titles.join(', ');
        material2 = title;
        params.codes = codes;
        params.titles = titles;
      }else{
        customizable = false;
      }
    }
    params.material1 = code;
    params.material2 = title;
    params.material = title;
    params.size = this.state.size;
    params.quality = this.state.quality;
    params.price = item.price;
    params.item_id = item.item_id;
    const cq = this.state.quality;
    const mic = this.mats;

    return (
      <Container component="main" maxWidth="sm">
        <ImageCarousel key={this.state.index} images={media} clickOpen={true} />
        <BaseDialog
          maxWidth="sm"
          fullWidth
          open={this.state.sub}
          onClose={e => this.setState({sub: false})}
          title={T.translate('quality.desc')}
        >
          <Quality />
        </BaseDialog>
        <div>
          <Box className={classes.priceBox}>
            <Box className={classes.bottom} style={{marginRight: 56}}>
              <Typography component="span" className={classes.label}>{T.translate(this.quoting ? 'quotation.my_quote' : 'products.price1')}: </Typography>
              <Typography component="pre" className={classes.price}>{item.price1 ? priceDisplay(item.price1, item.currency || 'CNY') : ' '}</Typography>
              {this.props.customizable && this.props.product && customizable &&
                <Customizer
                  product={this.props.product}
                  params={params}
                />
              }

              {!this.props.customizable && !this.quoting && this.state.size && this.state.quality && title &&
                <CollectToProjects
                  product={this.product}
                  index={this.state.index}
                  quality={this.state.quality}
                  size={this.state.size}
                  codes={code}
                  titles={title}
                  material2={material2}
                  path={this.props.location.pathname}
                  project_id={this.pid}
                />
              }

              {!this.props.customizable && this.quoting &&
                <QuoteProduct
                  product={this.product}
                  index={this.state.index}
                  size={this.state.size}
                  mat={this.state.mat}
                  quality={this.state.quality}
                  onQuote={this.onQuote}
                  uid={this.state.params.uid}
                />
              }
            </Box>
            {!this.quoting &&
            <Box className={classes.bottom}>
              <Typography component="span" className={classes.label}>{T.translate('products.price2')}: </Typography>
              <Typography component="pre" className={classes.info} style={{textDecoration: 'line-through'}}>{item.price2 ? priceDisplay(item.price2, item.currency) : ''}</Typography>
            </Box>
            }
          </Box>

          <Box borderBottom={1} borderColor={grey[400]} />

          {!this.quoting &&
          <Box className={classes.box}>
            <Typography component="span" className={classes.label}>{T.translate('products.select_quality')}: </Typography>
            {product.qualities.map((quality, index) => (
              <Button key={quality} data-tag={quality} variant={quality === this.state.quality ? 'contained' : 'outlined'} 
              color="primary" size="small" className={classes.itemButton} onClick={this.onQuality}>
                {T.translate(`quality.${quality}`)}
              </Button>
            ))}
            <IconButton color="inherit" align="center" size="small" onClick={e => this.setState({sub: true})}>
              <InfoIcon style={{width: 16, height: 16, marginBottom: 3}} />
            </IconButton>
          </Box>
          }

          <Box className={classes.box}>
            <Typography component="span" className={classes.label}>{T.translate('products.select_size')}: </Typography>
            {product.sizes.map((size, index) => (
              <Button key={size} data-tag={size} variant={size === this.state.size ? 'contained' : 'outlined'} 
              color="primary" size="small" className={classes.itemButton} onClick={this.onSize}>
                {size}
              </Button>
            ))}
          </Box>
          
          <Box className={classes.box}>
            <Typography component="span" className={classes.label}>{T.translate('products.select_mats')}: </Typography>
            {items_directly ? 
            product.mats.map((mat, index) => (
              cq && ((cq === '100' && mic.i.indexOf(mat) < 0) || (cq !== '100' && mic.c.indexOf(mat) < 0)) ? undefined :
              <Button key={mat} data-tag={mat} variant={mat === this.state.mat ? 'contained' : 'outlined'} 
              color="primary" size="small" className={classes.itemButton} onClick={this.onMat}>
                {mat}
              </Button>
            ))
            : 
            <MaterialsCustomizer product={product} onSave={this.onCustomized} />
            }
          </Box>
          
          <Box borderBottom={1} borderColor={grey[400]} />
          <MaterialsBlock
            product={product}
            itemIndex={this.state.index}
            onChange={e => this.onCustomized({}) }
          />

          {n > 0 &&
          <Box className={classes.box}>
            <Typography component="span" className={classes.label}>{T.translate('products.specs')}: </Typography>
            <Typography component="span" className={classes.info}><SpecsDisplay item={specs} /></Typography>
          </Box>
          }

          {this.labels.map((property, index) => (
            <Box key={property.k} className={classes.box}>
              <Typography component="span" className={classes.label}>{T.translate(`products.${property.tk}`)}: </Typography>
              <Typography component="span" className={classes.info}>{item[property.k] || product[property.k] || ''}</Typography>
            </Box>
          ))}

          {!this.quoting && item && item.currency && item.currency !== 'CNY' &&
          <React.Fragment>
            <Box borderBottom={1} borderColor={grey[400]} />
            {this.optionalLabels.map((property, index) => (
              <Box key={property.k} className={classes.box}>
                <Typography component="span" className={classes.label}>{T.translate(`products.${property.tk}`)}: </Typography>
                <Typography component="span" className={classes.info}>{item[property.k] || product[property.k] || ''}</Typography>
              </Box>
            ))}
          </React.Fragment>
          }

          {this.props.product &&
          <React.Fragment>
            <Box borderBottom={1} borderColor={grey[400]} />
            <Box className={classes.box}>
              <Typography component="span" className={classes.label}>{T.translate('products.memo')}: </Typography>
              <Typography component="span" className={classes.info}>{this.props.product.memo}</Typography>
            </Box>
          </React.Fragment>
          }
          <Box style={{height: 100}} />
        </div>
      </Container>
    )
  }

  
  onCustomized = (params) => {
    const codes = [];
    const product = this.product;
    if (product.customized && product.customized.length > 0) {
      for (var j = 0; j < product.customized.length; ++j) {
        if (!product.customized[j]) continue;
        const code = product.customized[j].identifier;
        codes.push(code.length > 10 ? code.substring(0, 10) : code);
      }
      //codes.sort();
    }
    const size = params.size || this.state.size;
    const quality = params.quality || this.state.quality;

    const match = [];
    let index = -1;
    if (codes.length === product.customized.length) {
      const marker = codes.join(',');
      for (var i = 0; i < product.items.length; ++i) {
        const item = product.items[i];
        if (item.marker === marker) {
          match.push(i);
          if (size && item.size === size && quality && item.quality === quality) {
            index = i;
          }
        }
      }
    }
    
    const update = {show: true, index: index};
    if (params.hasOwnProperty('size')) update.size = params.size;
    if (params.hasOwnProperty('quality')) update.quality = params.quality;
    else if (index < 0 && match.length === 1) {
      index = match[0];
      update.index = index;
      update.size = product.items[index].size;
      update.quality = product.items[index].quality;
    }
    this.setState(update);
  }

  checkAvailable = (mat, size, quality) => {
    const product = this.product;
    let i;
    for (i = 0; i < product.items.length; ++i) {
      const item = product.items[i];
      if (item.material === mat && item.size === size && item.quality === quality) {
        return true;
      }
    }
    return !mat || !size || !quality;
  }

  onChange = (mat, size, quality) => {
    let index = -1;
    const product = this.product;
    let i;
    for (i = 0; i < product.items.length; ++i) {
      const item = product.items[i];
      if (item.material === mat && item.size === size && item.quality === quality) {
        index = i;
        break;
      }
    }
    this.setState({mat: mat, size: size, quality: quality, index: index});
  }

  onMat = (e) => {
    const mat = e.currentTarget.dataset.tag;
    const size = this.state.size;
    const quality = this.state.quality;
    this.onChange(mat === this.state.mat ? undefined : mat, size, quality);
  }

  onSize = (e) => {
    const size = e.currentTarget.dataset.tag;
    const mat = this.state.mat;
    const quality = this.state.quality;
    if (this.customizing(quality))
      this.onCustomized({size: size === this.state.size ? undefined : size});
    else
      this.onChange(mat, size === this.state.size ? undefined : size, quality);
  }

  onQuality = (e) => {
    const mat = this.state.mat;
    const size = this.state.size;
    const quality = e.currentTarget.dataset.tag;
    if (this.customizing(quality))
    this.onCustomized({quality: quality === this.state.quality ? undefined : quality});
    else
      this.onChange(mat, size, quality === this.state.quality ? undefined : quality);
  }

}


export default withRouter(withStyles(styles)(Show));