/**
 * Created by milan on 2019/7/18.
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/styles/withStyles';
//import T from 'i18n-react';
import { DataManager } from '../models/';

//import ProductsTable from './Cart';
import Items from './Items';
import Costs from './Costs';


const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(1),
    overflowX: 'auto'
  }
});


class Edit extends Component {
  id = -1;
  deal = null;

  constructor(props) {
    super(props);
    let id = props.match.params.id;
    if (id) this.id = parseInt(id);
    this.state = {
      subtotal: 0,
      show: this.id < 0,
      costs: 8964
    };

    if (this.id > 0) {
      this.show();
    }
  }

  show = () => {
    let self = this;
    DataManager.getInstance().show_deal(this.id, {i: 1}).then(function (res) {
      if (res.c === 0) {
        self.deal = res.d.d;
        self.setState({show: true, subtotal: self.deal.subtotal || 0});
      }
    });
  };

  sync_deal_products = () => {
    const self = this;
    DataManager.getInstance().sync_deal_products(this.id, null).then(function (res) {
      if (res.c === 0) {
        self.deal = res.d.d;
        self.setState({show: true, subtotal: self.deal.subtotal || 0});
      }
    });
  };

  get_total = (d) => {
    this.deal = d;
    this.setState({costs: this.state.costs + 1});
    //this.setState(d);
  };

  render() {
    if (!this.state.show) return <React.Fragment/>;

    return (
      <React.Fragment>
        <Items deal={this.deal} updater={this.get_total} sync={this.sync_deal_products} />

        <Costs key={this.state.costs} deal={this.deal} subtotal={this.state.subtotal} />
      </React.Fragment>
    )
  }
}


export default withRouter(withStyles(styles)(Edit));