import React, {Component} from 'react';
import withStyles from '@material-ui/styles/withStyles';
import Box from '@material-ui/core/Box';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
// import { TransformControls } from 'three/examples/jsm/controls/TransformControls';
// import { TDSLoader } from 'three/examples/jsm/loaders/TDSLoader';
// import { ColladaLoader } from 'three/examples/jsm/loaders/ColladaLoader';
// import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader';
import { OBJLoader2 } from 'three/examples/jsm/loaders/OBJLoader2';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { Typography } from '@material-ui/core';
// import { RoughnessMipmapper } from 'three/examples/jsm/utils/RoughnessMipmapper';
import { Mode, DataManager } from '../models';


const styles = theme => ({
  center: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
});

function getBoundingBox(node) {
  const t = (new THREE.Box3()).setFromObject(node), n = new THREE.Vector3();
  return t.getSize(n);
}


const computeBoundingBox = function (obj) {
  if (obj instanceof THREE.Mesh) {
    var geometry = obj.geometry;
    if (geometry) {
      if (!geometry.boundingBox) {
        geometry.computeBoundingBox();
      }
      
      var geometryBBox = geometry.boundingBox;
      obj.updateMatrix();
      geometryBBox.applyMatrix4(obj.matrix);
      return geometryBBox;
    }
    else {
      return new THREE.Box3(new THREE.Vector3(), new THREE.Vector3());
    }
  }
  else {
    var i, len = obj.children.length;
    var boundingBox = new THREE.Box3(new THREE.Vector3(), new THREE.Vector3());
    
    for (i = 0; i < len; i++) {
      var bbox = computeBoundingBox(obj.children[i]);
      if ( bbox.min.x < boundingBox.min.x ) {
        boundingBox.min.x = bbox.min.x;
      }
      
      if ( bbox.max.x > boundingBox.max.x ) {
        boundingBox.max.x = bbox.max.x;
      }
      if ( bbox.min.y < boundingBox.min.y ) {
        boundingBox.min.y = bbox.min.y;
      }
      
      if ( bbox.max.y > boundingBox.max.y ) {
        boundingBox.max.y = bbox.max.y;
      }
      if ( bbox.min.z < boundingBox.min.z ) {
        boundingBox.min.z = bbox.min.z;
      }
      
      if ( bbox.max.z > boundingBox.max.z ) {
        boundingBox.max.z = bbox.max.z;
      }
    }
    obj.updateMatrix();
    boundingBox.applyMatrix4(obj.matrix);
    return boundingBox;
  }
}

function disposeNode(parentObject) {
  parentObject.traverse(function (node) {
    if (node instanceof THREE.Mesh) {
      if (node.geometry) {
          node.geometry.dispose();
      }
      if (node.material) {
        var materialArray;
        if (node.material instanceof THREE.MeshFaceMaterial || node.material instanceof THREE.MultiMaterial) {
          materialArray = node.material.materials;
        }
        else if(node.material instanceof Array) {
          materialArray = node.material;
        }
        if(materialArray) {
          materialArray.forEach(function (mtrl, idx) {
            if (mtrl.map) mtrl.map.dispose();
            if (mtrl.lightMap) mtrl.lightMap.dispose();
            if (mtrl.bumpMap) mtrl.bumpMap.dispose();
            if (mtrl.normalMap) mtrl.normalMap.dispose();
            if (mtrl.specularMap) mtrl.specularMap.dispose();
            if (mtrl.envMap) mtrl.envMap.dispose();
            mtrl.dispose();
          });
        } else {
          if (node.material.map) node.material.map.dispose();
          if (node.material.lightMap) node.material.lightMap.dispose();
          if (node.material.bumpMap) node.material.bumpMap.dispose();
          if (node.material.normalMap) node.material.normalMap.dispose();
          if (node.material.specularMap) node.material.specularMap.dispose();
          if (node.material.envMap) node.material.envMap.dispose();
          node.material.dispose();
        }
      }
    }
  });
}

function disposeHierarchy(node, callback) {
  for (var i = node.children.length - 1; i >= 0; i--) {
    const child = node.children[i];
    disposeHierarchy(child, callback);
    callback(child);
  }
}


function resizeRendererToDisplaySize(renderer) {
  const canvas = renderer.domElement;
  const width = canvas.clientWidth;
  const height = canvas.clientHeight;
  const needResize = canvas.width !== width || canvas.height !== height;
  if (needResize) {
    renderer.setSize(width, height, false);
  }
  return needResize;
}


class TriD extends Component {
  canvas = null;
  state = {};

  // constructor(props) {
  //   super(props);
  // }

  render() {
    const { classes } = this.props;
    // <div ref={ref => (this.mount = ref)}></div>

    return (
      <React.Fragment>
        {DataManager.getInstance().mode === Mode.ADMIN &&
          <Typography component="p">{this.state.box}</Typography>
        }
        <Box className={classes.center}>
          <canvas ref={ref => (this.canvas = ref)} width={500} height={500}/>
        </Box>
      </React.Fragment>
    )
  }

  componentDidUpdate(prevProps) {
    const url = this.props.url;
    if (url && url !== prevProps.url) {
      this.loadModel(this.props.url);
    }
  }

  componentDidMount() {
    this.initScene();
    this.loadModel(this.props.url);
  }

  componentWillUnmount() {
    const node = this.scene;
    disposeHierarchy(node, disposeNode);
    for (var i = node.children.length - 1; i >= 0; i--) {
      const child = node.children[i];
      node.remove(child);
    }

    this.orbit.dispose();
    this.scene.dispose();
    this.renderer.dispose();
    this.renderer = null;
    this.camera = null;
    this.orbit = null;
    this.scene = null;
    this.canvas = null;
  }

  loadModel = (url) => {
    const self = this;
    const camera = this.camera;
    const controls = this.orbit;
    const render = this.doRender;
    const scene = this.scene;
    const renderer = this.renderer;
    
    for (var i = scene.children.length - 1; i >= 0; i--) {
      const child = scene.children[i];
      // if (child.name === 'model')
        scene.remove(child);
    }

    function onLoad(obj) {
      const root = new THREE.Object3D();
      obj.name = 'root';
      scene.add(root);
      // obj.traverse( function (child) {
      //   if ( child.isMesh ) {
      //     // child.material.normalMap = normal;
      //   }
      // });
      obj.name = 'model';
      // obj.scale.set(0.5, 0.5, 0.5);
      const t = getBoundingBox(obj);
      const bbox = computeBoundingBox(obj);
      self.setState({box: `${JSON.stringify(t)}`});

      let dis = t.z;
      if (t.y > dis) dis = t.y;
      if (t.x > dis) dis = t.x;
      camera.position.set(0, 0, bbox.max.z + dis * 1.0);

      root.add(obj);

      obj.position.set(- (bbox.max.x + bbox.min.x) / 2, - (bbox.max.y + bbox.min.y) / 2, - (bbox.max.z + bbox.min.z) / 2);

      const r = new THREE.SpotLight(16777215);
      r.position.set(0, 0, 10);
      r.castShadow = true;
      r.angle = .5;
      r.shadow.mapSize.width = 1024;
      r.shadow.mapSize.height = 1024;
      r.intensity = .01;
      r.distance = 3.1;
      r.target = root;
      scene.add(r);

      const lights = [];
      const lightsDictionary = {
        "HemisphereLight": {
          "intensity": 0.26,
          "color": 16777215,
          "helperColor": 16711680
        },
        "DirectionalLight": {
          "intensity": 0.5,
          "color": 16777215,
          "helperColor": 65280
        },
        "PointLight": {
          "intensity": 0,
          "color": 16777215,
          "helperColor": 255
        },
        "AmbientLight": {
          "intensity": 0.26,
          "color": 16777215
        },
        "SpotLight": {
          "intensity": 0.05,
          "color": 16777215
        }
      };
      {
        let n = void 0;
        n = new THREE.HemisphereLight(lightsDictionary.HemisphereLight.color, 4473924, lightsDictionary.HemisphereLight.intensity);
        n.position.set(0, 2 * t.y, t.z / 2);
        n.name = n.type + "_1";
        lightsDictionary[n.type].position = n.position;
        lights.push(n);
        n = new THREE.DirectionalLight(lightsDictionary.DirectionalLight.color,lightsDictionary.DirectionalLight.intensity);
        n.position.set(0, 2 * t.y, 0);
        // n.castShadow = true;
        // n.target = obj;
        // if (n.shadow && n.shadow.mapSize) {
        //   n.shadow.mapSize.width = 2048; 
        //   n.shadow.mapSize.height = 2048;
        // }
        n.name = n.type + "_1";
        lightsDictionary[n.type].position = n.position;
        lights.push(n);
        n = new THREE.PointLight(lightsDictionary.PointLight.color,lightsDictionary.PointLight.intensity);
        n.position.set(t.x + 2, -(t.y + 2), t.z + 2);
        n.name = n.type + "_1";
        lightsDictionary[n.type].position = n.position;
        lights.push(n);
        n = new THREE.PointLight(lightsDictionary.PointLight.color,lightsDictionary.PointLight.intensity);
        n.position.set(-(t.x + 2), -(t.y + 2), t.z + 2);
        n.name = n.type + "_2";
        lightsDictionary[n.type].position = n.position;
        lights.push(n);
        n = new THREE.PointLight(lightsDictionary.PointLight.color,lightsDictionary.PointLight.intensity);
        n.position.set(t.x + 2, t.y + 2, -(t.z + 2));
        n.name = n.type + "_3";
        lightsDictionary[n.type].position = n.position;
        lights.push(n);
        n = new THREE.PointLight(lightsDictionary.PointLight.color,lightsDictionary.PointLight.intensity,2e3);
        n.position.set(-(t.x + 2), 8 * t.y, -(t.z + 2));
        n.name = n.type + "_4";
        lightsDictionary[n.type].position = n.position;
        lights.push(n);
        n = new THREE.AmbientLight(lightsDictionary.AmbientLight.color,lightsDictionary.AmbientLight.intensity);
        n.position.set(t.x + 2, t.y + 2, t.z + 4);
        n.name = n.type + "_1";
        lightsDictionary[n.type].position = n.position;
        lights.push(n);
        n = new THREE.SpotLight(lightsDictionary.SpotLight.color,lightsDictionary.SpotLight.intensity);
        n.position.set(0, 2 * t.y, 2 * t.z);
        n.decay = 2;
        n.penumbra = 1;
        n.name = n.type + "_1";
        lightsDictionary[n.type].position = n.position;
        lights.push(n);

        for (var a = 0; a < lights.length; a++)
          root.add(lights[a]);
  
        renderer.shadowMap.needsUpdate = true;
      }

      // const hemiLight = new THREE.HemisphereLight(0xffffff, 0x000000, 1);
      // hemiLight.position.set(0, 0, 0);
      // scene.add(hemiLight);

      controls.update();
      requestAnimationFrame(render);
    };

    const fn = decodeURIComponent(url.split('/').pop().split('#')[0].split('?')[0]).toLowerCase();
    const ft = fn.split('.').pop();
    const resource = this.props.texture;
    if (url.indexOf('/zcpri/') >= 0) {
      url = `/api/products/proxy/?u=${encodeURIComponent(url)}`;
    }
    if (ft === 'mtl') {
      const loader = new OBJLoader2();
      new MTLLoader()
      // .setPath( 'models/obj/male02/' )
      .setResourcePath(resource)
      .load(url, function (materials) {
        materials.preload();
        const nu = url.replace('.mtl', '.obj');
        loader.addMaterials(materials.materials).setResourcePath(resource)
          // .setPath( 'models/obj/male02/' )
          .load(nu, onLoad);
      });
      return;
    }else 
    if (ft === 'glb' || ft === 'gltf') {
      // const roughnessMipmapper = new RoughnessMipmapper( renderer );
      const loader = new GLTFLoader();
      loader.setResourcePath(resource);
      loader.setRequestHeader({'Token': DataManager.getInstance().storage.token});  // , 'Cache-Control': 'no-cache'
      loader.load(url, (obj) => {
        // obj.scene.traverse( function ( child ) {
        //   if ( child.isMesh ) {
        //     roughnessMipmapper.generateMipmaps( child.material );
        //   }
        // });
        onLoad(obj.scene);
      });
    // }else if (ft === 'dae') {
    //   loader = new ColladaLoader();
    //   loader.setResourcePath(resource);
    //   loader.load(url, (obj) => {
    //     onLoad(obj.scene);
    //   });
    // }else if (ft === '3ds') {
    //   loader = new TDSLoader();
    //   loader.setResourcePath(resource);
    //   loader.load(url, onLoad);
    }else if (ft === 'obj'){
      const loader = new OBJLoader2();
      loader.setResourcePath(resource);
      loader.setRequestHeader({'Token': DataManager.getInstance().storage.token});
      loader.load(url, onLoad);
      // scene.add(new THREE.HemisphereLight(0xffffff, 0xffffff, 0.5));
      // const light = new THREE.DirectionalLight(0xffffff, 0.5);
      // light.position.set(100, 100, 100);
      // light.castShadow = false;
      // scene.add(light);
    }
  }

  initScene() {
    const canvas = this.canvas;
    const renderer = new THREE.WebGLRenderer({canvas: canvas, antialias: true, alpha: true, preserveDrawingBuffer: true});
    renderer.autoClear = false;
    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = THREE.PCFSoftShadowMap;
    // renderer.gammaInput = true;
    // renderer.gammaOutput = true;
    renderer.outputEncoding = THREE.GammaEncoding;
    renderer.toneMappingExposure = 1;

    const aspect = 1;  // window.innerWidth / window.innerHeight;
    const camera = new THREE.PerspectiveCamera(75, aspect, 0.1, 1000);
    // camera.position.set(0, -30, -10);
    camera.position.set(0, 0, 0);
  
    const orbit = new OrbitControls(camera, canvas);
    orbit.target.set(0, 0, 0);
    orbit.update();

    // const trans = new TransformControls(camera, canvas);
    // trans.addEventListener( 'change', render );
    // trans.addEventListener( 'dragging-changed', function ( event ) {
    //   orbit.enabled = !event.value;
    // } );

    const scene = new THREE.Scene();
    scene.background = new THREE.Color('white');

    // const ambientLight = new THREE.AmbientLight(0xffffff, 1);
    // scene.add(ambientLight);

    // scene.add(new THREE.HemisphereLight(0xffffbb, 0x080820, 1));

    // const directionalLight = new THREE.DirectionalLight( 0xffffff );
    // directionalLight.position.set( 0, 0, 2 );
    // scene.add( directionalLight );    

    this.renderer = renderer;
    this.camera = camera;
    this.scene = scene;
    this.orbit = orbit;
  
    requestAnimationFrame(this.doRender);
  }

  doRender = () => {
    if (!this.canvas) return;

    const camera = this.camera;
    const renderer = this.renderer;
    const render = this.doRender;
    const scene = this.scene;
    const controls = this.orbit;

    if (resizeRendererToDisplaySize(renderer)) {
      const canvas = renderer.domElement;
      camera.aspect = canvas.clientWidth / canvas.clientHeight;
      camera.updateProjectionMatrix();
    }

    controls.update();
    renderer.render(scene, camera);
    requestAnimationFrame(render);
  }

  sample() {
    var scene = new THREE.Scene();
    var renderer = new THREE.WebGLRenderer();
    var camera = new THREE.PerspectiveCamera( 75, window.innerWidth / window.innerHeight, 0.1, 10000 );
    renderer.setSize( window.innerWidth * 0.5, window.innerHeight * 0.5 );
    // renderer.setSize( 500, 500 );
    // document.body.appendChild( renderer.domElement );
    // use ref as a mount point of the Three.js scene instead of the document.body
    this.mount.appendChild( renderer.domElement );
    var geometry = new THREE.BoxGeometry( 1, 1, 1 );
    var material = new THREE.MeshBasicMaterial( { color: 0x00ff00 } );
    var cube = new THREE.Mesh( geometry, material );
    scene.add( cube );
    camera.position.z = 5;
    var animate = function () {
      requestAnimationFrame( animate );
      cube.rotation.x += 0.01;
      cube.rotation.y += 0.01;
      renderer.render( scene, camera );
    };
    animate();
  
  }
}

export default withStyles(styles)(TriD);