import React,  { Component } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';

import { Event, DataManager } from '../models/';
import { priceDisplay } from '../common/Constants';
import T from 'i18n-react';
import Cell from '../Order/Cell';


const styles = theme => ({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
    marginTop: theme.spacing(1),
    padding: theme.spacing(1)
  },
  paper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  list: {
    width: '100%'
  },
  content: {
    fontSize: 24,
    margin: theme.spacing(2)
  }
});


class Orders extends Component {
  state = {total: 1, page: 1, per: 0, filter: 0, show: false, requesting: false, stats: {}};
  orders = null;

  constructor(props) {
    super(props);
    this.OrderState = DataManager.getInstance().states.orders;
    this.state.requesting = true;
    this.list(this.state.page, this.state.filter);
  }

  order_selected = (order) => {
    const path = '/' + this.props.match.path.split('/')[1];
    DataManager.getInstance().pub(Event.REDIRECT, `${path}/s/${this.props.match.params.pid}/orders/${order.id}`);
  };

  render() {
    if (!this.state.show)
      return <div></div>;

    const { classes } = this.props;

    return (
      <React.Fragment>
        <Grid item xs={12} sm={8} md={8} lg={6} xl={6}>
        <div className={classes.root}>
          {this.table()}
        </div>
        </Grid>
      </React.Fragment>
    );
  }

  table = () => {
    const { classes } = this.props;

    const orders = this.orders;
    const self = this;

    return (
      <Box className={classes.paper}>
        {this.state.requesting ?
          <CircularProgress size={48} />
          :
          (orders.length === 0 ?
              <Paper className={classes.paper}>
                <Typography className={classes.content}>{T.translate('projects.empty_orders')}</Typography>
              </Paper>
              :
              <List className={classes.list}>
                {orders.map((order, index) =>
                  <Cell key={order.id} order={order} index={index} onClick={self.order_selected} onExpand={this.on_expanded} />
                )}
              </List>
          )
        }
      </Box>
    );
  };

  on_expanded = () => {
    this.setState({expanded: !this.state.expanded});
  };

  list = (page, f) => {
    const self = this;
    const pid = this.props.match.params.pid;
    const params = {page: page, f: f, id: -1, pid: pid};
    return DataManager.getInstance().list_orders(params).then(function (res) {
      if (res.c === 0) {
        let d = res.d;

        const storage = DataManager.getInstance().storage;
        if (storage.mc && storage.mc.orders) {
          storage.mc.orders = d.stats;
        }
        const orders = d.orders;
        for (var i = 0; i < orders.length; ++i) {
          const order = orders[i];
          order.subtotal = priceDisplay(order.subtotal, order.currency);
          order.total = priceDisplay(order.total, order.currency);
          order.paid = priceDisplay(order.paid, order.currency);
          order.received = priceDisplay(order.received, order.currency);
          order.mc = d.stats.mc[order.id];
        }
        self.orders = orders;

        self.setState({page: page, total: d.t, per: d.p, requesting: false, show: true, stats: d.stats});
        window.scrollTo(0, 0);
      }
    })
  };
}


export default withStyles(styles)(Orders);