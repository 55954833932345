/**
 * Created by milan on 2019/7/18.
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/styles/withStyles';
import T from 'i18n-react';
import { Mode, Event, DataManager } from '../models/';

import AlertDialog from '../dialogs/AlertDialog';
import ImagesDialog from '../dialogs/ImagesDialog';
import { priceDisplay } from '../common/Constants';
import { OrderItemBadge } from '../common/StateBadge';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';


const staff = [
  {
    label: '扫描二维码联系客服下单',
    description: '或微信内搜索 zhuchaox 添加',
    imgPath:
      'https://aifa.oss-cn-qingdao.aliyuncs.com/wechat.jpg'
  }
];


const styles = theme => ({
  header: {
    position: 'fixed',
    padding: theme.spacing(0, 2, 0, 2),
    height: 64,
    bottom: 24,
    zIndex: 101,
    width: '100%',
    maxWidth: 'inherit',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  label: {
    fontSize: 14,
    [theme.breakpoints.down('xs')]: {
      maxWidth: 130
    }
  },
  textBox: {
    padding: theme.spacing(0.5, 0, 0, 0),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  textBoxColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  }
});


class UserActions extends Component {
  order = null;

  constructor(props) {
    super(props);

    this.state = {
      show: true,
      requesting: false,

      contact: false
    };

    this.order = props.order;
    if (!this.order || !(this.order.id > 0))
      this.state.show = false;
  }

  pay = (e) => {
    DataManager.getInstance().pub(Event.REDIRECT, `/orders/p/${this.order.id}`);
  };

  show_contact = (e) => {
    this.setState({contact: true});
  };

  close_contact = (e) => {
    this.setState({contact: false});
  };

  state_badge = (state) => {
    return OrderItemBadge(state, DataManager.getInstance().states.orders);
  };

  closeAlert = (e) => {
    if (e.currentTarget.dataset.tag === '1') {
      const params = {};
      params.state = this.state.alert;
      this.update_order(params);
    }
    this.setState({alert: 0});
  };

  update_order = (params) => {
    const self = this;
    self.setState({requesting: true});
    const prom = function (res) {
      if (res.c !== 0) {
        return;
      }
      self.order.due = res.d.due;
      self.order.state = params.state;
      let i;
      for (i = 0; i < self.order.items.length; ++i) {
        const item = self.order.items[i];
        item.state = params.state;
      }
      self.setState({requesting: false});
      self.props.show();
    };
    if (DataManager.getInstance().mode < Mode.BUSINESS)
      return DataManager.getInstance().user_order(this.order.id, params).then(prom);
    else
      return DataManager.getInstance().seller_order(this.order.id, params).then(prom);
  };

  handle_state = (e) => {
    const state = parseInt(e.target.value);
    if (state > this.order.state)
      this.setState({alert: state});
    else
      return this.update_order({state: state});
  };

  show_state = (item) => {
    const is_customer = DataManager.getInstance().mode < Mode.BUSINESS;
    let editable = false;
    const OrderState = DataManager.getInstance().states.items;
    const states = [];
    let k;
    if (is_customer && item.state >= OrderState.Shipping) {
      for (k in OrderState) {
        if (OrderState.hasOwnProperty(k) && k >= OrderState.Shipping && k <= OrderState.Received && k % 10 === 0) {
          states.push({title: OrderState[k], state: parseInt(k)});
          if (parseInt(k) === item.state) editable = true;
        }
      }
    }else if (!is_customer && item.state <= OrderState.Shipping) {
      for (k in OrderState) {
        if (OrderState.hasOwnProperty(k) && k >= OrderState.Manufacturing && k <= OrderState.Shipping && k % 10 === 0) {
          states.push({title: OrderState[k], state: parseInt(k)});
          if (parseInt(k) === item.state) editable = true;
        }
      }
    }
    if (!editable) {
      return this.state_badge(item.state);
    }
    const show = this.state.alert >= states[0].state && this.state.alert <= states[states.length - 1].state;
    return (
      <React.Fragment>
        <Select
          value={this.order.state}
          onChange={this.handle_state}
          disableUnderline={false}
          variant="standard"
        >
          {states.map((order_state, index) => {
            return <MenuItem key={index} value={order_state.state}>{order_state.title}</MenuItem>
          })}
        </Select>
        {show &&
        <AlertDialog
          open={show}
          onClose={this.closeAlert}
          title={T.translate('orders.alert.notice')}
          desc={T.translate('orders.alert.states_change_alert', {s: OrderState[this.state.alert]})}
          left={T.translate('buttons.cancel')}
          right={T.translate('buttons.yes')}
        />
        }
      </React.Fragment>
    );
  };

  render() {
    if (!this.state.show) return <React.Fragment/>;

    const { classes } = this.props;
    const order = this.order;
    const OrderState = DataManager.getInstance().states.orders;
    let display_state = null;
    if (this.order.state >= OrderState.Ready && this.order.state < OrderState.Bid) display_state = OrderState.Ready;
    else if (this.order.state >= OrderState.Bid && this.order.state < OrderState.Blueprinting) display_state = OrderState.Bid;
    else if (this.order.state >= OrderState.Blueprinting && this.order.state < OrderState.Prepared) display_state = OrderState.Blueprinting;
    const trans = this.order.due;

    return (
      <React.Fragment>
        <Box className={classes.textBox}>
          {this.order.state >= OrderState.Sealed && trans ?
            <Box className={classes.textBoxColumn}>
              <Typography component="p" className={classes.label}>
                {T.translate(trans.state >= 2 && trans.state < 10 ? 'orders.payment.verifying' : 'orders.payment.due', {c: priceDisplay(trans.amount, trans.currency)})}
              </Typography>
              <Button variant="outlined" color="primary" size="small" onClick={this.pay} >
                {T.translate(trans.state >= 2 && trans.state < 10 ? 'orders.payment.repay' : 'orders.pay')}
              </Button>
            </Box>
            :
            <Box width={48}/>
          }
        </Box>

        {display_state !== null && this.state_badge(display_state)}
        {this.order.state >= OrderState.Prepared && this.order.state <= OrderState.Partitioned && this.show_state(order)}

        <Button variant="contained" color="primary" size="small" onClick={this.show_contact} disabled={this.state.requesting} >
          {T.translate('orders.contact')}
        </Button>

        <ImagesDialog
          images={staff}
          title={staff[0].label}
          desc={staff[0].description}
          open={this.state.contact}
          onClose={this.close_contact}
        />
      </React.Fragment>
    )
  }
}


export default withRouter(withStyles(styles)(UserActions));