/**
 * Created by milan on 2019/7/18.
 */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import T from 'i18n-react';
import { DataManager } from '../models';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { MaterialsCustomizer } from '../Material/Customizer';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MoreIcon from '@material-ui/icons/MoreVertRounded';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { grey } from '@material-ui/core/colors';
import AlertDialog from '../dialogs/AlertDialog';


const useStyles = makeStyles(theme => ({
  container: {
    // padding: theme.spacing(1, 3, 1, 3)
  },
  iconButton: {
    // position: 'absolute',
    // bottom: -8
  },
  titleBar: {
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1),
    position: 'relative'
  },
  title: {
    width: '100%',
    minHeight: 48,
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(0.5)
    }
  },
  closeButton: {
    padding: 0
  },
  btns: {
    padding: theme.spacing(1, 2, 1, 2),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  box: {
    padding: theme.spacing(1, 3, 1, 3),
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'start',
    alignItems: 'center'
  },
  itemButton: {
    margin: 5
  },
  label: {
    fontSize: 13,
    marginRight: theme.spacing(1)
  },
}));


function QuoteProduct(props) {
  const classes = useStyles(props.theme);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleActionsClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleActionsClose = () => {
    setAnchorEl(null);
  };

  const { product, index, size, mat } = props;
  const mode = product.customizer && product.customizer.length > 0 ? 1 : 0;
  if (!product.zcs)
    product.zcs = product.sizes.concat(product.zcSizes).filter((v, i, a) => a.indexOf(v) === i);
  if (!product.zcm)
    product.zcm = product.mats.concat(product.zcMats).filter((v, i, a) => a.indexOf(v) === i);
  const sizes = product.zcs;
  const mats = product.zcm;

  const [error, setError] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [revoke, setRevoke] = React.useState(false);
  const [sizeIndex, setSizeIndex] = React.useState(-1);
  const [matIndex, setMatIndex] = React.useState(mode === 0 ? -1 : 0);
  const [values, setValues] = React.useState({vid: 0, price: '', size: '', material: '', material1: '', material2: ''});

  const submitable = (vs, si, mi) => {
    if (si < 0 || mi < 0 || !vs.price)
      return undefined;
    if (si > 0) vs.size = sizes[si - 1];
    if (mode === 0 && mi > 0) vs.material = mats[mi - 1];
    if (!vs.size || !vs.material)
      return undefined;
    const price = Number(vs.price);
    if (price >= 0)
      return vs;
    return undefined;
  };
  const [disabled, setDisabled] = React.useState(!submitable({...values}, sizeIndex, matIndex));

  const handleClick = e => {
    if (index >= 0) {
      setAnchorEl(null);
    }
    const tag = e.currentTarget.dataset.tag;
    let j;
    const vs = {...values};
    if (tag === 'add' || tag === 'new') {
      vs.vid = 0;
      vs.price = '';
    }
    if (tag === 'add') {
      if (product.customized && product.customized.length > 0) {
        for (j = 0; j < product.customized.length; ++j) {
          product.customized[j] = undefined;
        }
      }
      vs.size = '';
      vs.material = '';
      vs.material1 = '';
      vs.material2 = '';
      setValues(vs);
      setSizeIndex(-1);
      setMatIndex(mode === 0 ? -1 : 0);
      setOpen(true);
      return;
    }
    vs.vid = index >= 0 ? product.items[index].item_id : 0;
    if (tag === 'del') {
      setValues(vs);
      setRevoke(true);
      return;
    }
    let item;
    if (index >= 0) {
      item = product.items[index];      
    }
    if (item) {
      vs.price = item.price1;
      // vs.size = item.size;
    }
    if (product.customized && product.customized.length > 0) {
      if (!item && mat) {
        for (j = 0; j < product.items.length; ++j) {
          const it = product.items[j];
          if (it.material2 === mat) {
            item = it;
            break;
          }
        }
      }
      let codes = undefined;
      let titles = undefined;
      if (item && item.material1 && item.material2) {
        codes = item.material1.split(',');
        titles = item.material2.split(', ');
        vs.material1 = item.material1;
        vs.material2 = item.material2;
        vs.material = item.material;
      }
      for (j = 0; j < product.customized.length; ++j) {
        product.customized[j] = codes ? {identifier: codes[j], title: titles[j]} : undefined;
      }
    }
    setValues(vs);
    const ds = sizes.indexOf(size);
    const si = ds >= 0 ? (ds + 1) : -1;
    setSizeIndex(si);
    let mi = 0;
    if (mode === 0) {
      const dm = mats.indexOf(mat);
      mi = dm >= 0 ? (dm + 1) : -1;
      setMatIndex(mi);
    }
    setDisabled(!submitable({...vs}, si, mi));
    setOpen(true);
  };

  const onClose = e => {
    setError(undefined);
    setOpen(false);
  };

  const onEdit = e => {
    setError(undefined);
    const name = e.target.name;
    const value = e.target.value;
    const vs = {...values};
    if (name !== 'price') {
      vs[name] = value;
      setValues(vs);
    }else{
      if (!value) {
        vs[name] = '';
        setValues(vs);
        setDisabled(true);
        return;
      }
      const v2 = Number(value);
      if (v2 >= 0) {
        vs[name] = value;
        setValues(vs);
      }
    }
    setDisabled(!submitable({...vs}, sizeIndex, matIndex));
  };

  const onSize = e => {
    const i = parseInt(e.currentTarget.dataset.tag);
    const ni = i === sizeIndex ? -1 : i;
    setSizeIndex(ni);
    setError(undefined);
    setDisabled(!submitable({...values}, ni, matIndex));
  };

  const onMat = e => {
    const i = parseInt(e.currentTarget.dataset.tag);
    const ni = i === matIndex ? -1 : i;
    setMatIndex(ni);
    setError(undefined);
    setDisabled(!submitable({...values}, sizeIndex, ni));
  };

  const onCustomized = e => {
    const codes = [];
    const titles = [];
    if (product.customized && product.customized.length > 0) {
      for (var j = 0; j < product.customized.length; ++j) {
        if (product.customized[j] && product.customized[j].identifier) {
          codes.push(product.customized[j].identifier);
          titles.push(product.customized[j].title);
        }
      }
    }
    const vs = {...values};
    if (codes.length === product.customizer.length) {
      vs.material1 = codes.join(',');
      vs.material2 = titles.join(', ');
      vs.material = vs.material2;
    }else{
      vs.material1 = '';
      vs.material2 = '';
      vs.material = '';
    }
    setError(undefined);
    setValues(vs);
    setDisabled(!submitable({...vs}, sizeIndex, matIndex));
  };

  const onSubmit = e => {
    const params = submitable({...values}, sizeIndex, matIndex);
    params.pid = product.product_id;
    params.uid = props.uid;
    DataManager.getInstance().quote_product(false, params).then(function (res) {
      if (res.c === 0) {
        setOpen(false);
        props.onQuote(params);
        return;
      }
      setError(T.translate('notification.failed'));
    });
  };

  const onRevoke = e => {
    setRevoke(false);
    if (e.currentTarget.dataset.tag === '1') {
      const params = {...values, uid: props.uid, pid: product.product_id, revoke: '1', size: size, material: mat};
      DataManager.getInstance().quote_product(false, params).then(function (res) {
        if (res.c === 0) {
          props.onQuote({});
          return;
        }
      });
    }
  };
  return (
    <React.Fragment>
      {index < 0 ?
        <Button
          data-tag="new"
          variant="outlined"
          color="secondary"
          // size="small"
          onClick={handleClick}
        >
          {T.translate('quotation.inquiry_now')}
        </Button>
        :
        <div style={{position: 'relative'}}>
          <div style={{position: 'absolute', bottom: -8, display: 'flex', flexDirection: 'row', width: '100%'}}>
            <React.Fragment>
              <IconButton aria-haspopup="true" aria-controls="more-menu" aria-label="edit" onClick={handleActionsClick} className={classes.iconButton}>
                <MoreIcon color="primary" />
              </IconButton>
              <Menu
                id="more-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleActionsClose}
              >
                <MenuItem data-tag="edit" onClick={handleClick}><EditIcon color="primary" style={{margin: 5}} /> {T.translate('quotation.edit')}</MenuItem>
                <MenuItem data-tag="del"  onClick={handleClick}><DeleteIcon color="primary" style={{margin: 5}} /> {T.translate('quotation.recall')}</MenuItem>
                <MenuItem data-tag="add"  onClick={handleClick}><AddIcon color="primary" style={{margin: 5}} /> {T.translate('quotation.add')}</MenuItem>
              </Menu>
            </React.Fragment>        
          </div>
        </div>
      }
      <Dialog open={open} onClose={onClose} fullWidth aria-labelledby="dialog-title">
        <div className={classes.titleBar}>
          <Box width={56} />
          <Box className={classes.title}>
            <Typography align="center" variant="h5">{product.title}</Typography>
            <Typography align="center" component="p" variant="subtitle1">{size ? `${size}, ` : ''}{mat}</Typography>
          </Box>
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon style={{width: 48, height: 48}} />
          </IconButton>
        </div>
        <DialogContent>
        <Box className={classes.box}>
          <Typography component="span" className={classes.label}>{T.translate('products.select_size')}: </Typography>
          {sizeIndex === 0 &&
            <TextField
              placeholder={T.translate('quotation.new_size')}
              name="size"
              value={values.size}
              onChange={onEdit}
              margin="dense"
              fullWidth
            />
          }
          <Button key="0" data-tag="0" variant={sizeIndex === 0 ? 'contained' : 'outlined'} 
          color="primary" size="small" className={classes.itemButton} onClick={onSize}>
            {T.translate('quotation.new_size')}
          </Button>
          {sizes && sizes.map((s, index) => (
            <Button key={index} data-tag={index + 1} variant={index + 1 === sizeIndex ? 'contained' : 'outlined'} 
            color="primary" size="small" className={classes.itemButton} onClick={onSize}>
              {s}
            </Button>
          ))}
        </Box>
        <Box borderBottom={0} borderColor={grey[400]} style={{margin: 24}} />

        <Box className={classes.box}>
          <Typography component="span" className={classes.label}>{T.translate('products.select_mats')}: </Typography>
          {mode === 0 ? 
          <React.Fragment>
            {matIndex === 0 &&
              <TextField
                placeholder={T.translate('quotation.new_material')}
                name="material"
                value={values.material}
                onChange={onEdit}
                margin="dense"
                fullWidth
              />
            }
            <Button key="0" data-tag="0" variant={matIndex === 0 ? 'contained' : 'outlined'} 
            color="primary" size="small" className={classes.itemButton} onClick={onMat}>
              {T.translate('quotation.new_material')}
            </Button>
            {mats && mats.map((m, index) => (
              <Button key={index} data-tag={index + 1} variant={index + 1 === matIndex ? 'contained' : 'outlined'} 
              color="primary" size="small" className={classes.itemButton} onClick={onMat}>
                {m}
              </Button>
            ))}
          </React.Fragment>
          : 
          <MaterialsCustomizer product={product} onSave={onCustomized} />
          }
        </Box>
        <Box borderBottom={0} borderColor={grey[400]} style={{margin: 24}} />

        <Box className={classes.box}>
          <Typography component="span" className={classes.label}>{T.translate('quotation.quote')}: </Typography>
          <Box style={{display: 'inline-block', width: 26}}/>
          <TextField
            margin="dense"
            multiline={false}
            InputProps={{
              startAdornment: <InputAdornment style={{minWidth: 72}} position="start">{T.translate('currency.CNY')}</InputAdornment>
            }}
            name="price"
            value={values.price}
            onChange={onEdit}
            label={T.translate('quotation.quote_unit_price')}
          />
          <Box />
        </Box>
        <Box borderBottom={0} borderColor={grey[400]} style={{margin: 24}} />

        <Box className={classes.btns}>
          <Button data-tag="cancel" variant="outlined"
          color="primary" size="small" className={classes.itemButton} onClick={onClose}>
            {T.translate('buttons.cancel')}
          </Button>
          <Typography component="span" style={{color: 'red'}}>{error}</Typography>
          <Button data-tag="submit" variant="contained" disabled={disabled}
          color="primary" size="small" className={classes.itemButton} onClick={onSubmit}>
            {T.translate('buttons.submit')}
          </Button>
        </Box>
        </DialogContent>
      </Dialog>

      <AlertDialog
        open={revoke}
        onClose={onRevoke}
        title={T.translate('dialogs.alert')}
        desc={T.translate('dialogs.confirm_delete_quote')}
        left={T.translate('buttons.cancel')}
        right={T.translate('buttons.yes')}
      />
    </React.Fragment>
  );
}


export default QuoteProduct;