import React,  { Component } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Topbar from './Topbar';
import SectionHeader from './typo/SectionHeader';
import T from 'i18n-react';
import { DataManager } from './models/';


const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.grey['A500'],
    overflow: 'hidden',
    marginTop: 20,
    padding: 20,
    paddingBottom: 200
  },
  grid: {
    width: 1000
  }
});

class SignOut extends Component {
  render() {
    const { classes } = this.props;
    const currentPath = this.props.location.pathname;
    const search = this.props.location.search;
    let code = '400';
    if (search && search.length > 0) {
      code = search.substring(1);
    }

    return (
      <React.Fragment>
        <CssBaseline />
        <Topbar currentPath={currentPath} />
        <div className={classes.root}>
          <Grid container justify="center"> 
            <Grid spacing={10} alignItems="center" justify="center" container className={classes.grid}>
              <Grid item xs={12}>
                <SectionHeader title={T.translate('dialogs.sorry')} subtitle={T.translate(`errors.${code}`, {s: DataManager.getInstance().hook ? `「${DataManager.getInstance().hook.name}」` : ''})} />
              </Grid>
            </Grid>
          </Grid>
        </div>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(SignOut);