/**
 * Created by milan on 2019/7/18.
 */
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import withStyles from '@material-ui/styles/withStyles';
import T from 'i18n-react';
import { Event, DataManager } from '../models';

import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import Paper from '@material-ui/core/Box';
import { EditableCell } from './Cell';
import Pagination from '../common/Pagination';
import { M_KEYS, M_SEARCHABLE, M_VISIBLE, M_REQUIRED, M_ITEMS } from '../common/Constants';

import Switch from '@material-ui/core/Switch';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import CardIcon from '@material-ui/icons/CreditCard';
import CodeIcon from '@material-ui/icons/ViewColumn';
import AlertDialog from '../dialogs/AlertDialog';
import MaterialCard from './MaterialCard';

import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';


const styles = theme => ({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
    marginTop: theme.spacing(2),
    padding: theme.spacing(1)
  },
  paper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  btns: {
    marginBottom: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  list: {
    width: '100%'
  },
  content: {
    fontSize: 24,
    margin: theme.spacing(2)
  },
  pagination: {
    marginTop: theme.spacing(5)
  },

  tile: {
    marginBottom: theme.spacing(1),
    cursor: 'pointer'
  },
  image: {
    // cursor: 'pointer',
    minWidth: '100%',
    minHeight: '100%',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  img: {
    width: '100%',
    objectFit: 'cover',
    minWidth: '100%',
    minHeight: '100%',
    verticalAlign: 'middle'
  }
});


class Table extends Component {
  state = {params: {p: 1}, header: {keys: null, values: null}, total: 1, page: 1, per: 0, deletion: -1, 
            show: {index: -1, card: false}, filters: {}, errors: {}, requesting: true, friendly: true};
  materials = null;
  material = null;
  prevent_list = false;

  constructor(props) {
    super(props);
    const search = props.location.search.substring(1);
    if (search) {
      const params = search.split('&').reduce(function(prev, curr, i, arr) {
        var p = curr.split('=');
        prev[p[0]] = decodeURIComponent(p[1]);
        return prev;
      }, {});
      if (params.p) params.p = parseInt(params.p);
      this.state.params = params;
      this.state.friendly = params.f === '1';
    }
    this.list(this.state.params);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.location.search !== nextProps.location.search) {
      const search = nextProps.location.search.substring(1);
      if (search) {
        const params = search.split('&').reduce(function(prev, curr, i, arr) {
          var p = curr.split('=');
          prev[p[0]] = decodeURIComponent(p[1]);
          return prev;
        }, {});
        if (params.p) params.p = parseInt(params.p);
        nextState.params = params;
        nextState.friendly = params.f === '1';
      }else{
        nextState.friendly = true;
        nextState.params = {p: 1};
        const filters = nextState.filters;
        let k;
        for (k in filters) {
          if (filters.hasOwnProperty(k)) {
            filters[k] = '';
          }
        }
        nextState.filters = filters;
      }
      if (this.prevent_list) {
        this.prevent_list = false;
      }else{
        this.list(nextState.params);
      }
      return true;
    }
    return true;
  }
  
  handlePagination = (offset) => {
    const page = 1 + offset / this.state.per;
    const params = {...this.state.params};
    params.f = this.state.friendly ? 1 : 0;
    params.p = page;
    const qs = Object.keys(params).map((key) => {
      return key + '=' + encodeURIComponent(params[key])
    }).join('&');
    DataManager.getInstance().pub(Event.REDIRECT, {pathname: this.props.location.pathname, search: qs});
  };

  handleFilter = (e) => {
    const filters = this.state.filters;
    const params = {p: 1, f: this.state.friendly ? 1 : 0};
    let k;
    for (k in filters) {
      if (filters.hasOwnProperty(k) && filters[k]) {
        params[k] = filters[k];
      }
    }
    const qs = Object.keys(params).map((key) => {
      return key + '=' + encodeURIComponent(params[key])
    }).join('&');
    DataManager.getInstance().pub(Event.REDIRECT, {pathname: this.props.location.pathname, search: qs});
  }

  handleConfig = (e) => {
    const params = {...this.state.params};
    params.f = !!e.target.checked ? 1 : 0;
    const qs = Object.keys(params).map((key) => {
      return key + '=' + encodeURIComponent(params[key])
    }).join('&');
    this.prevent_list = true;
    DataManager.getInstance().pub(Event.REDIRECT, {pathname: this.props.location.pathname, search: qs});
    // this.setState({friendly: !!e.target.checked});
  }

  onSaved = (item) => {
    if (item.id > 0) return;
    this.material = null;
    // this.forceUpdate();
    this.setState({editing: -1});
  }

  onAdd = (e) => {
    if (this.material) return;
    // const keys = this.state.header.keys;
    const new_item = {};
    for (var i = 0; i < M_VISIBLE.length; ++i) {
      const k = M_VISIBLE[i];
      new_item[k] = '';
    }
    new_item.media = [];
    new_item.items = [];
    this.materials.splice(0, 0, new_item);
    this.material = new_item;
    this.setState({editing: 0});
    // this.forceUpdate();
  }

  onNaming = (e) => {
    const tag = e.currentTarget.dataset.tag;
    const self = this;
    DataManager.getInstance().get_resources(`/api/naming/${tag}/`).then(function (res) {
      if (res.c === 0) {
        self.setState({people: res.d});
      }
    });
  }

  onUseName = (e) => {
    DataManager.getInstance().post_resources('/api/naming/n/', this.state.people).then(function (res) {
      if (res.c !== 0) {
        DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'error', m: T.translate('notification.failed')});
        return;
      }
      const copyText = document.getElementById("people");
      copyText.select();
      copyText.setSelectionRange(0, 99999);
      document.execCommand("copy");
      DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'success', m: T.translate('notification.success')});
    });
  }

  render() {
    if (!this.materials) return <div></div>;
    const { classes } = this.props;
    const self = this;
    const mc = this.state.show;
    const show = mc.index >= 0 ? this.materials[mc.index] : null;
    const people = this.state.people;
    let name;
    if (people) name = [people.name, people.courtesy_name].filter((v, i, a) => !!v).join(', ');

    return (
      <React.Fragment>
        <Link to="/materials/batch/">{T.translate('materials.upload')}</Link>
        <div style={{display: 'inline-block', width: 24}}></div>
        <Link to="/materials/export/">{T.translate('materials.export')}</Link>
        <div style={{display: 'inline-block', width: 24}}></div>
        <Button data-tag="en" variant="outlined" color="primary" onClick={this.onNaming}>随机艺术家</Button>
        <Button data-tag="cn" variant="outlined" color="primary" onClick={this.onNaming}>随机中文名/字</Button>
        {people &&
          <React.Fragment>
            <div style={{display: 'inline-block', width: 24}}></div>
            {people.id > 0 ? 
            <Button variant="outlined" onClick={this.onUseName}>{name} (点击复制并从名字库中删除)</Button>
            :
            <Typography component="span">{name}</Typography>
            }
            <input readOnly type="text" value={name}
            id="people" style={{position: 'absolute', top: -64}} />
          </React.Fragment>
        }
        <Button style={{float: 'right'}} onClick={this.reload_db}>同步所有材质名</Button>
        <div className={classes.root}>
          {this.grid()}
        </div>
        <AlertDialog
          open={this.state.deletion >= 0}
          onClose={this.close_alert}
          title={T.translate('dialogs.alert')}
          desc={T.translate('dialogs.confirm_delete_material')}
          left={T.translate('buttons.cancel')}
          right={T.translate('buttons.yes')}
        />
        <MaterialCard key={mc.index} card={mc.card} open={mc.index >= 0} onClose={() => self.setState({show: {index: -1, card: mc.card}}) } material={show} />
        <Dialog
          open={this.state.editing >= 0}
          onClose={e => self.setState({editing: -1})}
          aria-labelledby="form-dialog-title"
          maxWidth='xl'
          fullWidth
        >
          <DialogTitle style={{textAlign: "right"}}>
            <IconButton aria-label="close" onClick={e => self.setState({editing: -1})}>
              <CloseIcon style={{width: 32, height: 32}} />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <List>
            {this.state.editing >= 0&& this.editable_cell(this.materials[this.state.editing], this.state.editing)}
            </List>
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }

  grid = () => {
    const { classes } = this.props;
    const materials = this.materials;
    const self = this;

    return (
      <Grid alignItems="center" justify="center" container>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            {this.state.requesting ?
              <CircularProgress size={48} />
              :
              <GridList className={classes.list} cols={4} cellHeight={250} spacing={12}>
                <EditableCell
                  key={-1}
                  material={this.state.filters}
                  rules={this.state.header.values}
                  keys={M_SEARCHABLE}
                  onChange={this.handleFilter}
                >
                  <React.Fragment>
                    <IconButton
                      aria-controls="add"
                      aria-label="add"
                      data-tag="add"
                      onClick={this.onAdd}
                      color="primary"
                      disabled={!!this.material}
                    >
                      <AddIcon />
                    </IconButton>
                    <Switch
                      checked={this.state.friendly}
                      onChange={this.handleConfig}
                      value="friendly"
                      color="primary"
                      inputProps={{ 'aria-label': 'friendly toggle' }}
                    />
                  </React.Fragment>
                </EditableCell>
                {materials.length === 0 &&
                  <Typography style={{width: '100%', textAlign: 'center'}} className={classes.content}>{T.translate('materials.empty_list')}</Typography>
                }
                {materials.map((material, index) => {
                  const media = material.media && material.media.length > 0 ? material.media[0] : undefined;
                  return <GridListTile key={`${material.id}.${index}`} data-tag={index} className={classes.tile} onClick={e => self.setState({editing: index})}>
                    <div className={classes.image}>
                      <img className={classes.img} src={media} alt={media ? material.title : '没有图片'} />
                    </div>
                    <GridListTileBar
                      // onClick={(e) => {e.stopPropagation();}}
                      title={ `${material.title} (${T.translate('products.items', {c: material.items.length})})`}
                      subtitle={<span>{material.clazz} / {material.family} / {material.species}</span>}
                      actionIcon={
                        <React.Fragment>
                          <IconButton onClick={e => {
                            e.stopPropagation();
                            self.setState({show: {index: index, card: true}});
                          }}>
                            <CodeIcon style={{color: 'white'}} />
                          </IconButton>
                          <IconButton onClick={e => {
                            e.stopPropagation();
                            self.setState({show: {index: index, card: false}});
                          }}>
                            <CardIcon style={{color: 'white'}} />
                          </IconButton>
                        </React.Fragment>
                      }
                    />
                  </GridListTile>;
                })}
              </GridList>
            }
          </Paper>
          {!this.state.requesting && this.state.per > 0 && this.state.total > this.state.per &&
          <Pagination
            offset={this.state.per * (this.state.params.p - 1)}
            limit={this.state.per}
            total={this.state.total}
            onClick={this.handlePagination}
          />
          }
        </Grid>
      </Grid>
    );
  };

  table = () => {
    const { classes } = this.props;
    const materials = this.materials;

    return (
      <Grid alignItems="center" justify="center" container>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            {this.state.requesting ?
              <CircularProgress size={48} />
              :
              <List className={classes.list}>
                <EditableCell
                  key={-1}
                  material={this.state.filters}
                  rules={this.state.header.values}
                  keys={M_SEARCHABLE}
                  onChange={this.handleFilter}
                >
                  <React.Fragment>
                    <IconButton
                      aria-controls="add"
                      aria-label="add"
                      data-tag="add"
                      onClick={this.onAdd}
                      color="primary"
                      disabled={!!this.material}
                    >
                      <AddIcon />
                    </IconButton>
                    <Switch
                      checked={this.state.friendly}
                      onChange={this.handleConfig}
                      value="friendly"
                      color="primary"
                      inputProps={{ 'aria-label': 'friendly toggle' }}
                    />
                  </React.Fragment>
                </EditableCell>
                {materials.length === 0 &&
                  <Typography style={{width: '100%', textAlign: 'center'}} className={classes.content}>{T.translate('materials.empty_list')}</Typography>
                }
                {materials.map(this.editable_cell)}
              </List>
            }
          </Paper>
          {!this.state.requesting && this.state.per > 0 && this.state.total > this.state.per &&
          <Pagination
            offset={this.state.per * (this.state.params.p - 1)}
            limit={this.state.per}
            total={this.state.total}
            onClick={this.handlePagination}
          />
          }
        </Grid>
      </Grid>
    );
  };

  editable_cell = (material, index) => {
    const self = this;
    const err_id = material.id || 0;
    const err = this.state.errors && this.state.errors[err_id] ? this.state.errors[err_id] : {};
    // const has_error = Object.keys(err).length > 0;

    return <EditableCell
      key={material.identifier || material.id || `mat-${index}`}
      material={material}
      errors={err}
      rules={this.state.header.values}
      keys={M_VISIBLE}
      // keys={material.id > 0 && !has_error && this.state.friendly ? M_FRIENDLY : M_VISIBLE}
      // keys={this.state.header.keys}
      onSaved={this.onSaved}
      // cards={
      //   <React.Fragment>
      //     <IconButton onClick={e => {
      //       self.setState({show: {index: index, card: true}});
      //     }}>
      //       <CodeIcon color="primary" />
      //     </IconButton>
      //     <IconButton onClick={e => {
      //       self.setState({show: {index: index, card: false}});
      //     }}>
      //       <CardIcon color="primary" />
      //     </IconButton>
      //   </React.Fragment>
      // }
    >
      <React.Fragment>
      <IconButton onClick={e => {
        // DataManager.getInstance().pub(Event.REDIRECT, `/materials/s/${material.id}`);
        // self.setState({show: index});
        if (material.id > 0)
          self.setState({deletion: index});
        else{
          self.materials.splice(index, 1);
          self.material = null;
          self.setState({editing: -1});
          // self.forceUpdate();
        }
      }}>
        <DeleteIcon color="error" />
      </IconButton>
      <IconButton onClick={e => self.save_material(index, material)}>
        <SaveIcon color="primary" />
      </IconButton>
      </React.Fragment>
    </EditableCell>;
  };

  reload_db = (e) => {
    return DataManager.getInstance().get_resources('/api/materials/reload/', null).then(function (res) {
      if (res.c !== 0) {
        DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'error', m: T.translate('notification.failed')});
        return;
      }
      if (res.d.labels) DataManager.getInstance().storage.materials = res.d.labels;
      DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'success', m: T.translate('notification.success')});
    });
  };

  list = (query) => {
    const self = this;
    if (!this.state.requesting) this.setState({requesting: true});
    const params = {...query, l: !!this.labels ? 0 : 1};
    return DataManager.getInstance().list_materials(params).then(function (res) {
      if (res.c === 0) {
        if (res.d.labels) DataManager.getInstance().storage.materials = res.d.labels;

        let d = res.d;
        const mats = d.mats;
        for (var i = 0; i < mats.length; ++i) {
          const mat = mats[i];
          if (!mat.items)
            mat.items = [];  // {...mat}
        }
        self.materials = mats;
        const update = {params: query, total: d.t, per: d.p, requesting: false};
        if (!self.state.header.keys) {
          // const keys = [];
          const filters = {};
          const values = {};
          // const excluded = ['media', 'material_id', 'id'];
          // const mat = mats[0];
          // let k;
          // for (k in mat) {
          //   if (mat.hasOwnProperty(k) && excluded.indexOf(k) < 0) {
          //     values[k] = T.translate(`materials.${k}`);
          //     // keys.push(k);
          //   }
          // }
          let i;
          for (i = 0; i < M_VISIBLE.length; ++i) {
            const k = M_VISIBLE[i];
            values[k] = T.translate(`materials.${k}`);
            filters[k] = params[k] || '';
          }
          for (i = 0; i < M_ITEMS.length; ++i) {
            const k = M_ITEMS[i];
            values[k] = T.translate(`materials.${k}`);
            filters[k] = params[k] || '';
          }
          values.media = ['Images'];
          // keys.sort();
          update.header = {keys: M_KEYS, values: values};
          update.filters = filters;
        }
        // const total = d.t;
        // const max_page = Math.ceil(total / d.p);
        // if (p > max_page) p = max_page;
        // if (p < 1) p = 1;
        self.setState(update);
      }
    })
  };

  save_material = (index, material) => {
    const errors = {...this.state.errors};
    const err_id = material.id || 0;
    errors[err_id] = {};
    let e = false;
    let i;
    for (i = 0; i < M_REQUIRED.length; ++i) {
      const k = M_REQUIRED[i];
      if (!material[k]) {
        errors[err_id][k] = 'r';
        e = true;
      }
    }
    this.setState({errors: errors});
    if (e) {
      return;
    }
    const self = this;
    return DataManager.getInstance().save_material(material.id, material).then(function (res) {
      if (res.c !== 0) {
        DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'error', m: T.translate('notification.failed')});
        return;
      }
      DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'success', m: T.translate('notification.success')});
      material.identifier = res.d.identifier;
      if (!(material.id > 0)) {
        material.id = res.d.id;
        self.material = null;
      }
      self.forceUpdate();
    })
  };

  delete_material = (index, material) => {
    const self = this;
    return DataManager.getInstance().delete_material(material.id, null).then(function (res) {
      if (res.c !== 0) {
        DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'error', m: T.translate('notification.failed')});
        return;
      }
      DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'success', m: T.translate('notification.success')});
      self.materials.splice(index, 1);
      self.setState({editing: -1});
      // self.forceUpdate();
    })
  };

  close_alert = (e) => {
    const index = this.state.deletion;
    this.setState({deletion: -1});
    if (e.currentTarget.dataset.tag === '1') {
      if (index >= 0) {
        this.delete_material(index, this.materials[index]);
      }
    }
  };
}


export default withRouter(withStyles(styles)(Table));