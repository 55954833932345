import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import T from 'i18n-react';

import { Mode, DataManager } from '../models/';
//import { priceDisplay } from '../common/Constants';
import { StateBadge, OrderBadge } from '../common/StateBadge';
import Seller from '../common/Seller';
import ImagesList from '../common/ImagesList';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';

import CardActionArea from '@material-ui/core/CardActionArea';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Card from '@material-ui/core/Card';
//import Collapse from '@material-ui/core/Collapse';
//import ExpandMoreIcon from '@material-ui/icons/ArrowDropDownCircle';
//import clsx from 'clsx';


const useStyles = makeStyles(theme => ({
  cell_body: {
    width: '100%',
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  price: {
    fontSize: 21,
    display: 'block'
  },
  price_small: {
    fontSize: 17,
    display: 'block',
    marginBottom: theme.spacing(1)
  },
  right: {
    //width: 48,
    //minWidth: 48,
    lineHeight: 2,
    display: 'block',
    textAlign: 'center'
  },
  block: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },
  footer: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 16,
    paddingRight: 16,
    height: 32,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
}));


export default function Cell(props) {
  //const order_selected = (order) => {
  //  DataManager.getInstance().pub(Event.REDIRECT, `/orders/s/${order.id}`);
  //};
  const is_seller = DataManager.getInstance().mode === Mode.BUSINESS;

  //const expand_item = (order) => {
  //  if (order.sub_orders) {
  //    order._expanded = !order._expanded;
  //    props.onExpand();
  //  }else{
  //    DataManager.getInstance().sub_orders(order.id, null).then(function (res) {
  //      if (res.c === 0) {
  //        let d = res.d;
  //
  //        const orders = d.orders;
  //        for (var i = 0; i < orders.length; ++i) {
  //          const order = orders[i];
  //          order.subtotal = priceDisplay(order.subtotal, order.currency);
  //          order.total = priceDisplay(order.total, order.currency);
  //          order.paid = priceDisplay(order.paid, order.currency);
  //          order.received = priceDisplay(order.received, order.currency);
  //        }
  //        order.sub_orders = orders;
  //        order._expanded = !order._expanded;
  //        props.onExpand();
  //      }
  //    })
  //  }
  //};

  const classes = useStyles(props.theme);
  const OrderState = DataManager.getInstance().states.orders;

  const order_images = (order) => {
    const images = [];
    let i;
    for (i = 0; i< order.items.length; ++i) {
      const item = order.items[i];
      images.push(item.media[0]);
      if (i > 3) break;
    }
    return images;
  };

  const order_item = (order, index) => (
    <React.Fragment key={order.id}>
      <CardHeader
        style={{ paddingTop: 6, paddingBottom: 0 }}
        title={
          is_seller ?
          <Typography component="span" variant="subtitle2" align="center">
            {order.address}
          </Typography>
          :
          <Seller seller_id={order.seller_id} title={order.seller} />
        }
      />
      <CardActionArea component="div" onClick={() => props.onClick(order)}>
        <CardContent className={classes.cell_body}  style={{ paddingTop: 0, paddingBottom: 0 }}>
          <ImagesList files={order_images(order)} />
          <Box width={50} />
          <ListItemAvatar>
            <React.Fragment>
              {DataManager.getInstance().permit('Price') &&
              <React.Fragment>
                <React.Fragment>
                  <Typography component="p" variant="caption" align="center">
                    {T.translate('orders.total')}
                  </Typography>
                  <Typography component="p" align="center" className={order.parent_id > 0 ? classes.price_small : classes.price}>
                    {order.total}
                  </Typography>
                </React.Fragment>
                {is_seller ?
                  <React.Fragment>
                    <Typography component="p" variant="caption" align="center">
                      {T.translate('orders.received')}
                    </Typography>
                    <Typography component="p" align="center" className={order.parent_id > 0 ? classes.price_small : classes.price}>
                      {order.received || ''}
                    </Typography>
                  </React.Fragment>
                  :
                  <React.Fragment>
                    {/* <Typography component="p" variant="caption" align="center">
                      {T.translate('orders.paid')}
                    </Typography>
                    <Typography component="p" align="center" className={order.parent_id > 0 ? classes.price_small : classes.price}>
                      {order.paid || '¥0'}
                    </Typography> */}
                  </React.Fragment>
                }
              </React.Fragment>
              }
              <Typography component="div" variant="body2" className={classes.right} color="textPrimary" >
                {T.translate('orders.total_items', {c: order.quantity})}
              </Typography>
            </React.Fragment>
          </ListItemAvatar>
        </CardContent>
        <CardActions disableSpacing className={classes.footer}>
          <Typography variant="body2" color="textPrimary">
            {T.translate('orders.update_at')}: {order.update_at}
          </Typography>
          {order.mc > 0 ?
            <StateBadge state={order.mc} type={'state_urgent'} title={T.translate('orders.new_message')} />
            :
            OrderBadge(order, OrderState)
          }
        </CardActions>
      </CardActionArea>
    </React.Fragment>
  );

  const parent_item = (order, index) => (
    <React.Fragment key={order.id}>
      <CardActionArea component="div" onClick={() => props.onClick(order)}>
        <CardHeader
          style={{ paddingTop: 6, paddingBottom: 0 }}
          title={
            <Typography component="span" variant="subtitle2" align="center">
              {order.address}
            </Typography>
          }
        />
        <CardContent className={classes.cell_body} style={{ paddingTop: 16, paddingBottom: 16 }}>
          <React.Fragment>
            <Box className={classes.block}>
              <Typography component="p" variant="caption" align="center">
                {T.translate('products.product')}
              </Typography>
              <Typography component="p" align="center" className={order.parent_id > 0 ? classes.price_small : classes.price}>
                {T.translate('orders.total_items', {c: order.quantity})}
              </Typography>
            </Box>
            <Box className={classes.block}>
              <Typography component="p" variant="caption" align="center">
                {T.translate('orders.total')}
              </Typography>
              <Typography component="p" align="center" className={order.parent_id > 0 ? classes.price_small : classes.price}>
                {order.total}
              </Typography>
            </Box>
            <Box className={classes.block}>
              <Typography component="p" variant="caption" align="center">
                {T.translate('orders.paid')}
              </Typography>
              <Typography component="p" align="center" className={order.parent_id > 0 ? classes.price_small : classes.price}>
                {order.paid || '¥0'}
              </Typography>
            </Box>
          </React.Fragment>
        </CardContent>
        <CardActions disableSpacing className={classes.footer}>
          <Typography variant="body2" color="textPrimary">
            {T.translate('orders.create_at')}: {order.create_at}
          </Typography>
          {order.mc > 0 ?
            <StateBadge state={order.mc} type={'state_urgent'} title={T.translate('orders.new_message')} />
            :
            OrderBadge(order, OrderState)
          }
        </CardActions>
      </CardActionArea>
    </React.Fragment>
  //{order.type === 10 ?
  //  <IconButton
  //    className={clsx(classes.expand, {
  //              [classes.expandOpen]: order._expanded
  //            })}
  //    onClick={(e) => { e.stopPropagation(); expand_item(order); }}
  //    aria-expanded={order._expanded}
  //    aria-label="show more"
  //  >
  //    <ExpandMoreIcon color="primary" />
  //  </IconButton>
  //  :
  //  <Box height={48}/>
  //}
  );

  //{p_order.type === 10 && p_order.sub_orders &&
  //<Collapse in={p_order._expanded} timeout="auto" unmountOnExit>
  //  {p_order.sub_orders.map(order_item)}
  //</Collapse>
  //}

  const p_order = props.order;
  const p_index = props.index;
  const cell = p_order.type === 2 ? order_item(p_order, p_index) : parent_item(p_order, p_index);

  return (
    <React.Fragment key={p_index}>
      {props.skipCard ? cell :
      <Card>
        {cell}
      </Card>
      }
      <Box height={16} />
    </React.Fragment>
  )
}
