import React,  { Component } from 'react';
import { DataManager } from '.';
import T from 'i18n-react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';


class Permit extends Component {

  render() {
    const permitted = DataManager.getInstance().permit(this.props.permit);
    return (
      <React.Fragment>
        {!permitted ?
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          style={{ minHeight: '100vh' }}
        >
          <Grid item xs={3}>
        <Typography variant="h6">{T.translate('errors.not_permitted')}</Typography>
          </Grid>
        </Grid>
        :
        this.props.children
        }
      </React.Fragment>
    )
  }
}

export default Permit;