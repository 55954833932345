import React,  { Component } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import Box from '@material-ui/core/Box';

import { Event, DataManager } from '../models/';
import T from 'i18n-react';

import Tab from '@material-ui/core/Tab';
import { Link } from 'react-router-dom';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import SvgIcon from '@material-ui/core/SvgIcon';
import Badge from '@material-ui/core/Badge';
import Typography from '@material-ui/core/Typography';


const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5'
  }
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }}
    transformOrigin={{
      vertical: 2,
      horizontal: 'center'
    }}
    {...props}
  />
));


const styles = theme => ({
  tabItem: {
    paddingTop: 20,
    paddingBottom: 20,
    minWidth: 'auto'
  },
  tabItemRight: {
    float: 'right',
    paddingTop: 20,
    paddingBottom: 20,
    minWidth: 'auto'
  },
  arrow: {
    //position: 'absolute',
    fontSize: 6,
    width: '3em',
    height: '3em',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid'
    }
  },
  link: {
    textDecoration: 'none',
    color: 'inherit'
  },
  menuItem: {
    textDecoration: 'none',
    padding: 0,
    minHeight: 20,
    height: 20,
    minWidth: 100
  },
  menuItemSelected: {
    textDecoration: 'none',
    minHeight: 20,
    height: 20,
    minWidth: 100,
    padding: 0,
    color: theme.palette.primary.main
  }
});

function ArrowIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M5 8l4 4 4-4z"/>
    </SvgIcon>
  );
  //const arrowIcon = (<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"><path d="M5 8l4 4 4-4z"/></svg>);
  //<path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
}


class Unread extends Component {
  state = {open: false, total: 0, page: 1, per: 0, selected: -1, requesting: false};
  messages = null;

  constructor(props) {
    super(props);
    const storage = DataManager.getInstance().storage;
    if (storage.messages) {
      this.state.total = storage.mc.msg.c;
      this.messages = storage.messages;
    }else{
      this.state.requesting = true;
      //this.list();
    }
  }

  handleUpdated = (params) => {
    const storage = DataManager.getInstance().storage;
    let mc = this.state.total;
    if (storage.messages) {
      mc = storage.mc.msg.c;
      this.messages = storage.messages;
    }
    this.setState({total: mc, requesting: false});
  };

  componentDidMount() {
    DataManager.getInstance().sub(Event.UPDATED, this.handleUpdated);
  }

  componentWillUnmount() {
    DataManager.getInstance().unsub(Event.UPDATED, this.handleUpdated);
  }

  handleClick = (e) => {
    e.preventDefault();
    this.setState({
      open: !this.state.open,
      anchorEl: e.currentTarget
    });
  };

  handleClose = () => {
    this.setState({
      open: false
    });
  };

  render() {
    const messages = this.messages;
    if (!messages) return <div></div>;
    const { children, menu, classes, ...other } = this.props;
    const self = this;
    //<Tab className={classes.menuItem} value={index} label={T.translate(`messages.types.${item.type}`)} />

    let items = messages.map((item, index) => (
      <Box key={index} onClick={() => self.selected(index)}>
        <Box borderBottom={1}>
          <MenuItem style={{overflow: "hidden", textOverflow: "ellipsis", width: '13rem'}}>
            <Badge
              color="primary"
              variant="dot"
              badgeContent={1}
            >
              <Box/>
            </Badge>
            <Typography component="span" style={{paddingLeft: 6, fontSize: 15, whiteSpace: 'pre-line'}}>{T.translate(`messages.types.${item.type}`, {t: item.title})}</Typography>
          </MenuItem>
        </Box>
      </Box>
    ));
    items.push(
      <Link key={-1} to={{pathname: menu.pathname}} className={classes.link}>
        <MenuItem>
          <Tab className={classes.menuItem} value={-1} label={T.translate('messages.show_all')} />
        </MenuItem>
      </Link>
    );
    return (
      <a {...other}
        onClick={this.handleClick}
        aria-owns={this.state.open ? 'dropdown-menu' : null}
        aria-haspopup="true"
      >
        <Box m={1} />
        {children}
        <Badge className={classes.badge} color="primary" badgeContent={this.state.total}>
          <ArrowIcon color={menu.active ? 'primary' : 'inherit'} style={{ fontSize: 30 }}> </ArrowIcon>
        </Badge>
        <StyledMenu
          id="dropdown-menu"
          open={this.state.open}
          anchorEl={this.state.anchorEl}
          onClose={this.handleClose}
          keepMounted
          disableRestoreFocus
        >
          {items}
        </StyledMenu>
      </a>
    );
  }

  selected = (index) => {
    const self = this;
    const item = this.messages[index];
    DataManager.getInstance().read_message(item.id, null).then(function (res) {
      if (res.c === 0) {
        let d = res.d;
        DataManager.getInstance().storage.messages = d.messages;
        DataManager.getInstance().storage.mc.msg.c = d.mc;
        self.messages = d.messages;
        self.setState({total: d.mc, per: d.p, requesting: false});
      }
      switch (item.type) {
        case 100:
          DataManager.getInstance().pub(Event.REDIRECT, `/projects/s/${item.primary_id}/tm`);
          break;
        case 2:
          DataManager.getInstance().pub(Event.REDIRECT, `/orders/trackers/${item.primary_id}/${item.secondary_id}`);
          break;
        default:
          DataManager.getInstance().pub(Event.REDIRECT, item.url);
          break
      }
    });
  };

  list = () => {
    if (DataManager.getInstance().storage.messages) {
      this.messages = DataManager.getInstance().storage.messages;
      this.setState({requesting: false});
      return;
    }
    const self = this;
    const params = {s: 1};
    return DataManager.getInstance().list_messages(params).then(function (res) {
      if (res.c === 0) {
        let d = res.d;
        DataManager.getInstance().storage.messages = d.messages;
        DataManager.getInstance().storage.mc.msg.c = d.mc;
        self.messages = d.messages;
        self.setState({total: d.mc, per: d.p, requesting: false});
      }
    })
  };
}


export default withStyles(styles)(Unread);