/**
 * Created by milan on 2019/7/18.
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/styles/withStyles';
import Button from '@material-ui/core/Button';
//import MuiTextField from '@material-ui/core/TextField';
import { TextField } from 'formik-material-ui';
import Paper from '@material-ui/core/Paper';
import { Link as ExternalLink } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { Formik, Form, Field } from 'formik';
import T from 'i18n-react';
import { Mode, Event, RestMode, DataManager } from '../models/';
import TitleBar from '../common/TitleBar';
import Uploader from './Upload';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import LinearProgress from '@material-ui/core/LinearProgress';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import ResponsiveButton from '../buttons/ResponsiveButton';
import AlertDialog from '../dialogs/AlertDialog';
import ProjectSelector from '../Project/Selector';

const iconPlaceholder = require('../../images/placeholder.png');
const types = [{v: '1', l: 'inquiries.t1'}, {v: '2', l: 'inquiries.t2'}, {v: '3', l: 'inquiries.t3'}];


const styles = theme => ({
  root: {
    padding: theme.spacing(1)
  },
  paper: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2)
  },
  inlineLink: {
    textDecoration: 'underline',
    display: 'inline',
    marginLeft: 5
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    //paddingTop: theme.spacing(4),
    width: '100%' // Fix IE 11 issue.
  },
  input: {
    display: 'block',
    width: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  img: {
    marginTop: 10,
    marginLeft: theme.spacing(2),
    maxWidth: 280,
    maxHeight: 280,
    minWidth: 20,
    minHeight: 20
  },
  formControl: {
    marginBottom: 48
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  deletion: {
    color: theme.palette.danger.main
  }
});

class Edit extends Component {
  inquiry = null;
  id = -1;
  pid = -1;
  timer = null;
  file_ref = null;

  constructor(props) {
    super(props);
    this.state = {
      files: [],
      mode: this.props.mode,
      saved: null,
      selected: iconPlaceholder,
      progress: -1,
      confirmDeletion: 0,
      title: null
    };
    let id = props.match.params.id;
    if (id) this.id = parseInt(id);

    const search = props.location.search.substring(1);
    if (search) {
      const params = search.split('&').reduce(function(prev, curr, i, arr) {
        var p = curr.split('=');
        prev[p[0]] = decodeURIComponent(p[1]);
        return prev;
      }, {});
      const pid = parseInt(params.p);
      if (pid > 0) this.pid = pid;
      this.state.title = params.t;
    }
    if (this.id > 0) {
      this.state.mode = RestMode.EDIT;
      this.show();
    }else{
      this.state.mode = RestMode.CREATE;
    }
  }

  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  onProgress = (e) => {
    let p = e.loaded / e.total;
    let self = this;
    if (p < 1) {
      p *= 90;
      self.setState({progress: p});
    }else{
      self.setState({progress: 90});
      this.timer = setInterval(function () {
        let p = self.state.progress * 1.005;
        self.setState({progress: p});
      }, 1000);
    }
    //const diff = Math.random() * 10;
    //return Math.min(this.state.progress + diff, 100);
  };

  delete_inquiry = () => {
    if (this.id === -1 || (this.state.mode === RestMode.EDIT && this.inquiry === null)) return;
    let path = '/' + this.props.match.path.split('/')[1];
    if (this.pid > 0) {
      path = `/projects/s/${this.pid}/inquiries`;
    }
    DataManager.getInstance().delete_inquiry(this.id, null).then(function (res) {
      if (res.c !== 0) {
        DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'error', m: T.translate('errors.' + res.c)});
        return;
      }
      DataManager.getInstance().pub(Event.REDIRECT, path);
      DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'success', m: T.translate('notification.deleted')});
    });
  };

  show = () => {
    if (this.id === -1 || (this.state.mode === RestMode.EDIT && this.inquiry !== null)) return;
    let self = this;
    const params = {};
    if (this.pid > 0) params.pid = this.pid;
    DataManager.getInstance().show_inquiry(this.id, params).then(function (res) {
      if (res.c === 0) {
        let inquiry = res.d;
        self.inquiry = inquiry;

        const fn = inquiry.req_file.toLowerCase();
        const ft = fn ? fn.split('.').pop() : null;
        const filename = fn ? decodeURIComponent(fn.split('/').pop().split('#')[0].split('?')[0]) : null;
        let update = {saved: filename};
        if (ft === 'png' || ft === 'jpg' || ft === 'jpeg') {
          update.selected = inquiry.req_file;
        }
        self.setState(update);
        //DataManager.getInstance().pub(Event.DISPLAY, {c: inquiry.c});
      }else{
        DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'error', m: T.translate('errors.' + res.c)});
      }
    });
  };

  update_inquiry = (values, actions) => {
    let path = '/' + this.props.match.path.split('/')[1];
    const search = this.props.location.search;
    if (this.pid > 0) {
      path = `/projects/s/${this.pid}/inquiries`;
    }else if (search && search.length > 1) {
      path = search.substring(1);
    }
    //if (true) return values;
    if (this.id < 0 && this.pid > 0) {
      values.pid = this.pid;
    }
    values.file = this.state.files;
    values.req_data = '';
    if (this.inquiry && this.inquiry.req_data && this.inquiry.req_data.length > 0)
      values.req_data = this.inquiry.req_data.join(';');
    let self = this;
    return DataManager.getInstance().update_inquiry(values, this.onProgress).then(function (res) {
      let errors = {};
      if (res.c !== 0) {
        DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'error', m: T.translate('errors.' + res.c)});
        actions.setSubmitting(false);
        self.setState({progress: -1});
        if (self.timer) {
          clearInterval(self.timer);
        }
        if (Object.keys(errors).length) {
          actions.setErrors(errors);
          //actions.setStatus(errors);
        }
        return;
      }
      DataManager.getInstance().pub(Event.REDIRECT, path);
    });
  };

  validate = values => {
    let errors = {};
    if (!(this.pid > 0) && !values.pid) {
      errors.pid = T.translate('errors.required');
    }
    //if (!values.req) {
    //  errors.req = T.translate('errors.required');
    //}
    if (!(this.id > 0) && !(this.state.files.length > 0)) {
      errors.file = T.translate('errors.required');
    }
    if (!values.type || values.type === '0') {
      errors.type = T.translate('errors.required');
    }
    if (Object.keys(errors).length)
      return errors;
  };

  file_selected = (fs) => {
    const files = this.state.files.slice();
    const update = {};
    for (var file of fs) {
      if (files.indexOf(file) < 0)
        files.splice(0, 0, file);
      if (file.type.split('/')[0] === 'image') {
        update.selected = URL.createObjectURL(file);
      }else {
        update.selected = iconPlaceholder;
      }
    }
    update.files = files;
    this.setState(update);
  };

  showAlert = (e) => {
    this.setState({confirmDeletion: 1});
  };

  closeAlert = (e) => {
    if (e.currentTarget.dataset.tag === '1') {
      this.delete_inquiry();
    }
    this.setState({confirmDeletion: 0});
  };

  triggerAdd = (e) => {
    this.file_ref.click();
  };

  triggerDelete = (f) => {
    if (f instanceof File) {
      this.file_ref.value = '';
      const index = this.state.files.indexOf(f);
      if (index >= 0) {
        const fs = this.state.files.slice();
        fs.splice(index, 1);
        this.setState({files: fs});
      }
    }else if (this.inquiry){
      const index = this.inquiry.req_data.indexOf(f);
      if (index >= 0) {
        this.inquiry.req_data.splice(index, 1);
        this.setState({files: this.state.files});
      }
    }
  };

  render() {
    if (this.state.mode === RestMode.EDIT && !this.inquiry) {
      return <div></div>;
    }
    const { classes } = this.props;
    let id = this.id;
    const is_inquirer = DataManager.getInstance().mode < Mode.BUSINESS;
    const iq = is_inquirer ? 'inquiries' : 'quotation';
    const key = this.state.mode === RestMode.EDIT ? 'edit' : 'new';
    let self = this;
    const initialValues = {id: id || -1, budget: '', req: '', file: '', type: '0', pid: '', title: '', region: '', address: ''};
    if (this.inquiry) {
      initialValues.budget = this.inquiry.budget || '';
      initialValues.req = this.inquiry.req || '';
      initialValues.type = this.inquiry.type ? '' + this.inquiry.type : '0';
    }

    const fs = this.inquiry ? this.state.files.concat(this.inquiry.req_data) : this.state.files;
    return (
      <div>
        {this.state.mode !== RestMode.EDIT ?
          <TitleBar left={DataManager.getInstance().storage.inquiries_list} title={{title: this.state.title}}>
            <Typography align="center" variant="h6" component={ExternalLink} className={classes.inlineLink}
                        href="https://aifa.oss-cn-qingdao.aliyuncs.com/%E4%BA%A7%E5%93%81%E6%B8%85%E5%8D%95.xlsx">
              {T.translate(`${iq}.sample`)}
            </Typography>
          </TitleBar>
        :
          <TitleBar left={DataManager.getInstance().storage.inquiries_list} title={{title: this.state.title || T.translate(`${iq}.${key}`)}}>
            <ResponsiveButton color="primary" variant="contained" align="center" onClick={this.showAlert} >
              {T.translate('inquiries.delete')}
            </ResponsiveButton>
          </TitleBar>
        }
        <AlertDialog
          open={this.state.confirmDeletion === 1}
          onClose={this.closeAlert}
          title={T.translate('dialogs.alert')}
          desc={T.translate('dialogs.confirm_delete_inquiry')}
          left={T.translate('buttons.cancel')}
          right={T.translate('buttons.delete')}
        />
        <Container component="main" maxWidth="xs" className={classes.root}>
          <Paper className={classes.paper}>
            <Formik
              initialValues={initialValues}
              validate={this.validate}
              //validateOnChange={false}
              validateOnBlur={true}
              onSubmit={this.update_inquiry}
            >
              {props => {
                return (
                  <Form className={classes.form} noValidate>
                    <Field
                      hidden
                      value={id}
                      required
                      id="id"
                      name="id"
                    />

                    <div style={{display: DataManager.getInstance().mode <= Mode.PTR && this.id < 0 && this.pid < 0 ? 'block' : 'none'}}>
                    <ProjectSelector
                      className={classes.formControl}
                      error={props.errors.pid}
                      create={true}
                      selected={this.inquiry && this.inquiry.project_id ? this.inquiry.project_id : ''}
                      onChange={(pid, v) => {
                        props.setFieldValue('pid', pid, false);
                        props.setFieldValue('title', v.title, false);
                        props.setFieldValue('region', v.region, false);
                        props.setFieldValue('address', v.address, false);
                      }}
                    />
                    </div>

                    <Field
                      component={TextField}
                      error={!!props.errors.req}
                      helperText={props.errors.req}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="req"
                      label={T.translate("inquiries.name")}
                      placeholder={T.translate("inquiries.req")}
                      name="req"
                      autoComplete="req"
                      className={classes.formControl}
                    />

                    <Field
                      component={TextField}
                      error={!!props.errors.budget}
                      helperText={props.errors.budget}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="budget"
                      label={T.translate(`${iq}.budget`)}
                      placeholder={T.translate('inquiries.optional_budget')}
                      name="budget"
                      autoComplete="budget"
                      className={classes.formControl}
                    />

                    <FormControl
                      error={!!props.errors.type}
                      required
                      fullWidth
                      className={classes.formControl}
                      style={{padding: 12, paddingBottom: 0}}
                    >
                      <InputLabel
                        htmlFor="type"
                        shrink={true}
                        style={{left: 12}}
                      >
                        {T.translate("inquiries.tier")}
                      </InputLabel>
                      <RadioGroup
                        name="type"
                        id="type"
                        value={props.values.type}
                        style={{marginTop: 16}}
                        row
                        onChange={(e) => {
                          props.setFieldValue('type', e.target.value);
                        }}
                      >
                        {types.map((t, index) => (
                          <FormControlLabel key={index} value={t.v} control={<Radio color="primary" />} labelPlacement="bottom" style={{marginLeft: 0}}
                                            label={<Typography style={{fontSize: 14}}>{T.translate(t.l)}</Typography>}
                          />
                        ))}
                      </RadioGroup>
                      {!!props.errors.type && <FormHelperText>{props.errors.type}</FormHelperText>}
                    </FormControl>

                    <input
                      ref={fileInput => self.file_ref = fileInput}
                      style={{display: 'none'}}
                      accept="image/*, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      type="file"
                      name="file"
                      multiple="multiple"
                      onChange={(e) => {
                        props.setFieldValue('file', e.currentTarget.files);
                        self.file_selected(e.currentTarget.files);
                      }}
                    />
                    <label htmlFor="file">
                      <FormControl
                        error={!!props.errors.file}
                        margin="normal"
                        required={!(self.id > 0)}
                        fullWidth
                        style={{margin: 0, paddingTop: 30}}
                      >
                        <InputLabel
                          htmlFor="upload-display"
                          shrink={true} >
                          {T.translate("inquiries.file_suggestion")}
                        </InputLabel>
                        <Uploader files={fs} onAdd={self.triggerAdd} onDelete={self.triggerDelete} />
                        <FormHelperText id="upload-display-text">{props.errors.file}</FormHelperText>
                      </FormControl>
                    </label>

                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      disabled={props.isSubmitting}
                    >
                      {T.translate("inquiries.submit")}
                    </Button>
                    {self.state.progress >= 0 &&
                    <LinearProgress variant="determinate" value={self.state.progress} />
                    }
                  </Form>
                );
              }}
            </Formik>
          </Paper>
        </Container>
      </div>
    )
  }
}


export default withRouter(withStyles(styles)(Edit));