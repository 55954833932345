import React, { Component } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import T from 'i18n-react';


const styles = theme => ({

});


class InputDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {v: props.value, legal: true};
  }

  onChange = (e) => {
    const v = e.target.value;
    const nv = this.props.type === 'text' ? 1 : parseFloat(v);
    this.setState({v: v, legal: nv >= 0 && nv <= 2});
  };

  onSubmit = () => {
    if (!this.props.onSave) return;
    const value = {};
    value[this.props.k] = this.props.type === 'text' ? this.state.v : parseFloat(this.state.v);
    this.props.onSave(value);
  };

  render() {
    const { open, onClose } = this.props;
    return (
      <Dialog open={open} onClose={onClose} fullWidth maxWidth='xs' aria-labelledby="dialog-title">
        <DialogTitle id="form-dialog-title">{T.translate(this.props.title)}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {this.props.desc && T.translate(this.props.desc)}
          </DialogContentText>
          <TextField
            defaultValue={this.props.value}
            onChange={this.onChange}
            autoFocus
            margin="dense"
            id="input"
            type="input"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            {T.translate('buttons.cancel')}
          </Button>
          <Button onClick={this.onSubmit} color="primary" disabled={!this.state.legal}>
            {T.translate('buttons.submit')}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(styles)(InputDialog);