import React,  { Component } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link } from 'react-router-dom';
import ResponsiveButton from '../buttons/ResponsiveButton';

import { Event, DataManager } from '../models/';
import T from 'i18n-react';
import AlertDialog from '../dialogs/AlertDialog';
import Cell from '../Inquiry/Cell';


const styles = theme => ({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
    padding: theme.spacing(1)
  },
  paper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  list: {
    width: '100%'
  },
  content: {
    fontSize: 24,
    margin: theme.spacing(2)
  },
  btns: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(1),
    padding: theme.spacing(1)
  }
});


class Inquiries extends Component {
  state = {total: 1, page: 1, per: 0, selected: -1, filter: 0, requesting: false};
  inquiries = null;

  constructor(props) {
    super(props);
    this.state.requesting = true;
    this.list(this.state.page, this.state.filter);
  }

  item_selected = (index) => {
    const inquiry = this.inquiries[index];
    if (inquiry.state >= 2) {
      if (inquiry.deal_id > 0)
        DataManager.getInstance().pub(Event.REDIRECT, `/projects/s/${this.props.match.params.pid}/inquiries/${inquiry.deal_id}`);
      else
        DataManager.getInstance().pub(Event.REDIRECT, `/inquiries/s/${inquiry.id}`);
    }else{
      this.setState({selected: index});
    }
  };

  display_index = (index) => {
    const base = (this.state.page - 1) * this.state.per;
    const display = this.state.total - base - index;
    return display < 10 ? `0${display}` : display;
  };

  close_alert = (e) => {
    this.setState({selected: -1});
  };

  render() {
    if (!this.inquiries)
      return <div></div>;

    const { classes, project } = this.props;
    const inquiries = this.inquiries;
    const self = this;
    const back_to = {show: true, title: T.translate('projects.back'), to: this.props.location.pathname};
    DataManager.getInstance().storage.inquiries_list = back_to;

    const params = {p: project.id, t: project.title};
    const qs = Object.keys(params).map((key) => {
      return key + '=' + encodeURIComponent(params[key])
    }).join('&');

    return (
      <React.Fragment>
        <Grid item xs={12} sm={8} md={8} lg={6} xl={6} className={classes.btns}>
          <div></div>
          <ResponsiveButton color='primary' variant="contained" align="center" component={Link} to={{pathname: '/inquiries/c/', search: qs}}>
            {T.translate('inquiries.inquiry_again')}
          </ResponsiveButton>
        </Grid>
        <Grid item xs={12} />
        <Grid item xs={12} sm={8} md={8} lg={6} xl={6}>
        <div className={classes.root}>
          <Paper className={classes.paper}>
            {this.state.requesting ?
              <CircularProgress size={48} />
              :
              (inquiries.length === 0 ?
                  <Typography className={classes.content}>{T.translate('projects.empty_inquiries')}</Typography>
                  :
                  <List className={classes.list}>
                    {inquiries.map((inquiry, index) => (
                      <Cell
                        key={inquiry.id}
                        item={inquiry}
                        index={index}
                        displayIndex={this.display_index(index)}
                        path="/inquiries"
                        qs={qs}
                        //pid={this.props.match.params.pid}
                        onClick={() => self.item_selected(index)}
                      />
                    ))}
                  </List>
              )
            }
          </Paper>
        </div>
        </Grid>

        <AlertDialog
          open={this.state.selected >= 0}
          onClose={this.close_alert}
          title={T.translate('dialogs.no_offer')}
          desc={T.translate('dialogs.please_wait')}
          //left={T.translate('buttons.cancel')}
          right={T.translate('buttons.ok')}
        />

      </React.Fragment>
    );
  }

  list = (page, f) => {
    //if (this.inquiries !== null) return;
    let self = this;
    const q = this.state.keywords;
    const pid = this.props.match.params.pid;
    DataManager.getInstance().list_inquiries({pid: pid, page: page, f: f, q: q}).then(function (res) {
      if (res.c === 0) {
        let d = res.d;
        self.inquiries = d.i;
        self.setState({page: page, total: d.t, per: d.p, fc: d.fc, requesting: false});
        window.scrollTo(0, 0);
        //self.inquiries = [];
        //self.setState({page: 1, total: 0, per: 0});
      }
    });
  };
}


export default withStyles(styles)(Inquiries);