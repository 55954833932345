import React,  { Component } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Route, Switch } from 'react-router-dom';
import Hidden from '@material-ui/core/Hidden';
import Topbar from './Topbar';
import Contact from './Doc/Contact';
import About from './Doc/About';
import Policy from './Doc/Policy';
import Terms from './Doc/Terms';
import Quality from './Doc/Quality';

const styles = theme => ({
});

class Docs extends Component {
  render() {
    const { classes } = this.props;
    const currentPath = this.props.location.pathname;
    const path = '/' + this.props.match.path.split('/')[1];

    return (
      <React.Fragment>
        <CssBaseline />
        <Hidden smDown>
          <Topbar currentPath={currentPath} />
        </Hidden>

        <div className={classes.root}>
          <Switch>
          <Route path={`${path}/contact/`} component={ Contact } />
          <Route path={`${path}/about/`} component={ About } />
          <Route path={`${path}/policy/`} component={ Policy } />
          <Route path={`${path}/terms/`} component={ Terms } />
          <Route path={`${path}/qualities/`} component={ Quality } />

          <Route path={`${path}/:name?/contact/`} component={ Contact } />
          <Route path={`${path}/:name?/about/`} component={ About } />
          <Route path={`${path}/:name?/policy/`} component={ Policy } />
          <Route path={`${path}/:name?/terms/`} component={ Terms } />
          <Route path={`${path}/:name?/qualities/`} component={ Quality } />
          </Switch>
        </div>

      </React.Fragment>
    )
  }
}

export default withStyles(styles)(Docs);