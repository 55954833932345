import {Mode} from './models/Data';
import T from 'i18n-react';


const _M = {
  landing: {
    items: [
      {
        label: "Home",
        pathname: "/"
      }
    ]
  },
  normal: {
    items: [
      {
        label: "Products",
        pathname: "/products"
      },
      {
        label: "Materials",
        pathname: "/materials"
      },
      {
        label: "Inquiries",
        pathname: "/inquiries"
      },
      {
        label: "Projects",
        pathname: "/projects"
      },
      {
        label: "Orders",
        pathname: "/orders"
      },
      {
        label: "Corp",
        pathname: "/corp"
      },
      {
        label: "App",
        pathname: "/app"
      }
    ]
  },
  ptr: {
    items: [
      {
        label: "Products",
        pathname: "/products"
      },
      {
        label: "Materials",
        pathname: "/materials"
      },
      {
        label: "Inquiries",
        pathname: "/inquiries"
      },
      {
        label: "Projects",
        pathname: "/projects"
      },
      {
        label: "Orders",
        pathname: "/orders"
      },
      {
        label: "Corp",
        pathname: "/corp"
      },
      {
        label: "App",
        pathname: "/app"
      }
    ]
  },
  business: {
    items: [
      {
        label: "Dashboard",
        pathname: "/"
      },
      {
        label: "Quotes",
        pathname: "/quotes"
      },
      {
        label: "Products",
        pathname: "/products"
      },
      {
        label: "Materials",
        pathname: "/materials"
      },
      {
        label: "Orders",
        pathname: "/orders"
      },
      {
        label: "Admin",
        pathname: "/corp"
      },
      {
        label: "App",
        pathname: "/app"
      }
    ]
  },
  admin: {
    items: [
      {
        label: "Home",
        pathname: "/"
      },
      {
        label: "Materials",
        pathname: "/materials"
      },
      {
        label: "Products",
        pathname: "/products"
      },
      {
        label: "Wanted",
        pathname: "/wanted"
      },
      {
        label: "Inquiries",
        pathname: "/inquiries"
      },
      {
        label: "Quotes",
        pathname: "/quotes"
      },
      {
        label: "Orders",
        pathname: "/orders"
      },
      {
        label: "Units",
        pathname: "/units"
      },
      {
        label: "Admin",
        pathname: "/admin",
        items: [
          {
            label: "Projects",
            pathname: "/projects"
          },
          {
            label: "Deals",
            pathname: "/deals"
          },
          {
            label: "Dinja",
            pathname: "/dinja"
          },
          {
            label: "Dashboard",
            pathname: "/dashboard"
          },
          {
            label: "Analytics",
            pathname: "/analytics"
          },
          {
            label: "Channels",
            pathname: "/channels"
          },
          {
            label: "App",
            pathname: "/app"
          }
        ]
      }
    ]
  },
  messages: {
    items: [
      {
        label: "Messages",
        pathname: "/messages"
      }
    ]
  },

  authorized: {
    items: [
      {
        label: "Me",
        pathname: "/me",
        items: [
          {
            label: "Me",
            pathname: "/me"
          },
          {
            label: "Signout",
            pathname: "/signout"
          }
        ]
      }
    ]
  },
  unauthorized: {
    items: [
      {
        label: "Signup",
        pathname: "/signup"
      },
      {
        label: "Signin",
        pathname: "/signin"
      }
    ]
  }
};


function _primary(mode) {
  let m = null;
  if (mode >= Mode.ADMIN) {
    m = _M.admin;
  }else if (mode >= Mode.BUSINESS) {
    m = _M.business;
  }else if (mode >= Mode.PTR) {
    m = _M.ptr;
  }else if (mode >= Mode.NORMAL) {
    m = _M.normal;
  }else if (mode >= Mode.AUTHORIZED) {
    m = _M.normal;
  }else{
    m = _M.landing;
  }
  return m;
}


function _auth(mode) {
  let m = null;
  if (mode >= Mode.AUTHORIZED) {
    m = _M.authorized;
  }else{
    m = _M.unauthorized;
  }
  return m;
}


function _message() {
  return _M.messages;
}


function _active(menu, pathname) {
  let f = false;
  menu.items.forEach(function (item, _) {
    let a = false;
    if (item.items) {
      let _a = _active(item, pathname);
      if (_a) a = true;
    }else if (pathname === item.pathname) {
      a = true;
    }
    item.active = a;
    if (!item.title) item.title = T.translate('title.' + item.label);
    if (a) f = a;
  });
  
  return f;
}


const Menu = {
  primary: _primary,
  auth: _auth,
  message: _message,
  activate: _active
};

export default Menu;
