import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import T from 'i18n-react';
import Typography from '@material-ui/core/Typography';

import Card from '@material-ui/core/Card';
// import CardMedia from '@material-ui/core/CardMedia';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import StarIcon from '@material-ui/icons/Star';
import StarHalfIcon from '@material-ui/icons/StarHalf';


const useStyles = makeStyles(theme => ({
  card: {
    marginBottom: theme.spacing(2)
  },
  media: {
    height: 140
  },

  block: {
    width: '100%',
    maxWidth: '100%',
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  stats: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  value: {
    fontSize: 24
  }
}));


export default function Cell(props) {
  const { unit, onClick } = props;

  const classes = useStyles(props.theme);

  const stars = (rank) => {
    if (!(rank > 0 && rank <= 5)) return T.translate('units.rank_none');
    const stars = [];
    let i;
    for (i = 1; i <= 5; ++i) {
      if (rank >= i) {
        stars.push(<StarIcon key={i} />);
      }else if (rank >= i - 0.5) {
        stars.push(<StarHalfIcon key={i} />);
      }else{
        break;
      }
    }
    return stars;
  };

  return (
    <React.Fragment key={unit.id}>
      <Card className={classes.card}>
        <CardActionArea onClick={onClick}>
          {/* <CardMedia
            className={classes.media}
            image={unit.logo || placeholders[unit.id % placeholders.length]}
            title={unit.display}
          /> */}
          <CardContent>
            <div style={{margin: 8, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
              <Avatar src={unit.logo} style={{marginRight: 16, width: 64, height: 64, backgroundColor: unit.logo ? 'white' : '#8304C0'}} alt="logo" data-tag="logo">
                {unit.logo ? '' : 'Logo'}
              </Avatar>
              <div style={{display: 'flex', flexDirection: 'column'}}>
                <Typography component="span" variant="h6">{unit.display}</Typography>
                <Typography component="span" variant="body2">{unit.name}</Typography>
              </div>
            </div>
            <Box className={classes.block}>
              <Box className={classes.stats}>
                <Typography component="p" variant="caption" align="center">
                  {T.translate('units.orders')}
                </Typography>
                <Typography component="p" align="center" className={classes.value}>
                  {unit.stats.orders}
                </Typography>
              </Box>
              <Box className={classes.stats}>
                <Typography component="p" variant="caption" align="center">
                  {T.translate('units.rate')}
                </Typography>
                <Typography component="p" align="center" className={classes.value}>
                  {unit.stats.rate}
                </Typography>
              </Box>
              <Box className={classes.stats}>
                <Typography component="p" variant="caption" align="center">
                  {T.translate('units.rank', {app: T.translate('appName')})}
                </Typography>
                <Typography component="p" align="center" className={classes.value}>
                  {stars(unit.stats.rank)}
                </Typography>
              </Box>
            </Box>
            {unit.tech &&
            <div style={{margin: 16, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
              <Typography component="span" variant="body2">{T.translate('units.tech')}: {unit.tech}</Typography>
            </div>
            }
          </CardContent>
        </CardActionArea>
      </Card>
    </React.Fragment>
  )
}
