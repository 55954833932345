/**
 * Created by milan on 2019/7/18.
 */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import T from 'i18n-react';
import { DataManager } from '../models';
import TextField from '@material-ui/core/TextField';


import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { ReactComponent as MatIcon } from '../../images/materials.svg';


const useStyles = makeStyles(theme => ({
  box: {
    // padding: theme.spacing(1, 3, 1, 3)
  },
  titleBar: {
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1),
    position: 'relative'
  },
  title: {
    width: '100%',
    minHeight: 48,
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(0.5)
    }
  },
  closeButton: {
    padding: 0
  },
}));


function Customizer(props) {
  const classes = useStyles(props.theme);

  const { product } = props;
  const [open, setOpen] = React.useState(false);
  const [memo, setMemo] = React.useState(product.memo || '');
  React.useEffect(() => {
    setMemo(product.memo || '');
  }, [product.memo]);

  const handleMemo = e => {
    setMemo(e.target.value || '');
  };

  const onClick = e => {
    setOpen(true);
  };

  const onClose = e => {
    setOpen(false);
  };

  const customizeProduct = e => {
    const params = props.params;
    params.memo = memo;
    params.product_id = product.product_id;
    params.iid = product.id;
    // return;

    DataManager.getInstance().customize_deal(params).then(function (res) {
      if (res.c === 0) {
        product.material1 = params.material1;
        product.material2 = params.material2;
        product.material = params.material;
        product.size = params.size;
        product.quality = params.quality;
        product.price = params.price;
        product.price1 = product.price;
        product.item_id = params.item_id;
        product.memo = params.memo;
        setOpen(false);
      }
    });
  };

  return (
    <React.Fragment>
      <Box key="material" className={classes.box}>
        <div style={{position: 'relative'}}>
          <IconButton aria-label="favor" onClick={onClick} style={{position: 'absolute', bottom: -8}}>
            <MatIcon style={{width: 24, height: 24}} fill="blue" color="primary" />
          </IconButton>
        </div>
        
        <Dialog open={open} onClose={onClose} fullWidth aria-labelledby="dialog-title">
          <div className={classes.titleBar}>
            <Box className={classes.title}>
              <Typography align="center" variant="h5">{T.translate('materials.customize')}</Typography>
              <Typography align="center" component="p" variant="subtitle1">{' '}</Typography>
            </Box>
          </div>
          
          <DialogContent>
            <TextField
              margin="dense"
              multiline={true}
              inputProps={{
                maxLength: 100
              }}
              type="text"
              name="memo"
              value={memo}
              onChange={handleMemo}
              label={T.translate('products.memo')}
              placeholder={T.translate('products.memo')}
              fullWidth
            />
            <div style={{height: 16}}></div>
            {product.customizer &&
              <Typography component="p" variant="subtitle1">{T.translate('materials.alert_for_price')}</Typography>
            }
          </DialogContent>
          <DialogActions>
            <Button data-tag={0} onClick={onClose}>
              {T.translate('buttons.cancel')}
            </Button>
            <Button data-tag={1} onClick={customizeProduct} color="primary">
              {T.translate('buttons.yes')}
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </React.Fragment>
  );
}


export default Customizer;