import React, { Component } from 'react';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme, withTheme } from '@material-ui/core/styles';
import Routes from './routes';
import './components/text/zh.js';
import { Mode, Event, DataManager } from './components/models/';
//import { blue, indigo } from '@material-ui/core/colors';
import { grey, red, blue, lightBlue } from '@material-ui/core/colors';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import T from 'i18n-react';


let theme = createMuiTheme({
  palette: {
    secondary: {
      main: grey[900],
      contrastText: "#fff"
    },
    primary: {
      main: '#8304C0',
      contrastText: "#fff"
    },
    danger: {
      main: red[400],
      contrastText: "#fff"
    },
    blue: blue,
    lightBlue: lightBlue
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      'PingFangSC-Regular',
      'Helvetica',
      'Tahoma',
      'Arial',
      '"Microsoft Yahei"',
      'sans-serif'
    ].join(',')
  }
});

function fluidRange({ cssProperty, min, max, lowerRange = 400, higherRange = 960 }) {
  const factor = Math.round(((max - min) / (higherRange - lowerRange)) * 10000) / 10000;

  return {
    [cssProperty]: `${min}px`,
    [`@media (min-width:${lowerRange}px)`]: {
      [cssProperty]: `calc(${min}px  (100vw - ${lowerRange}px) * ${factor})`
    },
    [`@media (min-width:${higherRange}px)`]: {
      [cssProperty]: `${max}px`
    }
  };
}

function remToPx(value) {
  return Math.round(parseFloat(value) * 16);
}

function responsiveTypography(theme, { minFontSize, scale, ...other }) {
  const output = theme;
  output.typography = { ...theme.typography };

  [
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'subtitle1',
    'subtitle2',
    'body1',
    'body2',
    'buttonNext',
    'captionNext',
    'overline'
  ].forEach(variant => {
    const style = output.typography[variant];
    if (!style) return;
    const pixelFontSize = remToPx(style.fontSize);

    if (pixelFontSize <= minFontSize) {
      return;
    }

    output.typography[variant] = {
      ...style,
      ...fluidRange({
        cssProperty: 'fontSize',
        min: Math.max(minFontSize, Math.round(pixelFontSize * scale)),
        max: pixelFontSize,
        ...other
      })
    };
  });

  return output;
}

theme = responsiveTypography(theme, {minFontSize: 14, scale: 0.7});

class App extends Component {
  state = {verifying: true};
  constructor(props) {
    super(props);
    //DataManager.getInstance();
    this.verify(1);
  }

  componentDidMount() {
    DataManager.getInstance().sub(Event.AUTHORIZED, this.verify);
  }

  componentWillUnmount() {
    DataManager.getInstance().unsub(Event.AUTHORIZED, this.verify);
  }

  verify = (t) => {
    const self = this;
    if (t === 1 && !self.state.verifying)
      self.setState({verifying: true});
    DataManager.getInstance().verify().then(function (res) {
      if (res.c === 0) {
        const to = '/';
        if (DataManager.getInstance().mode >= Mode.ADMIN && window.location.pathname !== '/a/') {
          window.location = `/a/#${to}`;
        }else if (DataManager.getInstance().mode === Mode.BUSINESS && window.location.pathname !== '/s/') {
          window.location = `/s/#${to}`;
        }else if (DataManager.getInstance().mode < Mode.BUSINESS && window.location.pathname !== '/') {
          window.location = `/#${to}`;
        }

        const states = res.d.s;
        if (states) {
          DataManager.getInstance().states = states;
          let orders = states.orders;
          let k;
          for (k in orders) {
            if (orders.hasOwnProperty(k)) {
              orders[orders[k]] = T.translate(`orders.states.${k}`);
            }
          }
          const items = states.items;
          for (k in items) {
            if (items.hasOwnProperty(k)) {
              items[items[k]] = T.translate(`orders.product_states.${k}`);
            }
          }
          const trackers = states.trackers;
          for (k in trackers) {
            if (trackers.hasOwnProperty(k)) {
              trackers[trackers[k]] = T.translate(`trackers.stages.${k}`);
            }
          }
        }
      }
      if (t === 1 && self.state.verifying)
        self.setState({verifying: false});
      DataManager.log('token', res.c === 0 ? 'verified' : 'exp');
    });
  };

  render() {
    return (
      <div>
        <ThemeProvider theme={theme}>
          {this.state.verifying ?
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
            style={{ minHeight: '100vh' }}
          >
            <Grid item xs={3}>
              <CircularProgress />
            </Grid>
          </Grid>
            :
          <Routes/>
          }
        </ThemeProvider>
      </div>
    );
  }
}

export default withTheme(App);
