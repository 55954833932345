import React from 'react';

import SpecsEdit from './SpecsEdit';
import ShowIcon from '@material-ui/icons/ThreeDRotation';
import EditIcon from '@material-ui/icons/Edit';
import InfoIcon from '@material-ui/icons/Help';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import T from 'i18n-react';
import { Mode, DataManager } from '../models';
import AlertDialog from '../dialogs/AlertDialog';


//const useStyles = makeStyles(theme => ({
//
//}));

export default function SpecsDisplay(props) {
  const item = props.item;

  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(0);

  function handleClickOpen(e) {
    const tag = parseInt(e.currentTarget.dataset.tag);
    setValue(tag);
    if (tag < 0) {
      return;
    }
    setOpen(true);
  }

  function handleClose(e) {
    setOpen(false);
  }
  const n = item.specs ? parseInt(item.specs.split(';')[0]) : 0;

  return (
    <div onClick={(e) => e.stopPropagation()} style={{display: 'inline-block'}}>
      <Typography component="span" variant="subtitle1">{T.translate('products.n_specs', {c: n})}</Typography>
      {n > 0 && DataManager.getInstance().mode < Mode.ADMIN &&
      <IconButton color="primary" align="center" size="small" data-tag={-1} onClick={handleClickOpen}>
        <InfoIcon style={{width: 20, height: 20}} color="primary" />
      </IconButton>
      }
      {n > 0 && DataManager.getInstance().mode >= Mode.ADMIN &&
      <IconButton color="primary" align="center" size="small" data-tag={1} onClick={handleClickOpen}>
        <ShowIcon style={{width: 20, height: 20}} />
      </IconButton>
      }
      {DataManager.getInstance().mode >= Mode.ADMIN &&
      <IconButton color="primary" align="center" size="small" data-tag={0} onClick={handleClickOpen}>
        <EditIcon style={{width: 20, height: 20}} />
      </IconButton>
      }
      
      {open &&
      <SpecsEdit
        edit={value === 0}
        item={item}
        // specs={{texture: [p[0], p[1], p[2]], tri: [p[2], p[1], p[0]], flat: [p[1], p[2]], doc: []}}
        open={open}
        onClose={handleClose}
      />
      }
      <AlertDialog
        open={value === -1}
        onClose={e => setValue(0) }
        title={T.translate('dialogs.alert')}
        desc={`请打开${T.translate('appName')} App 查看产品高清 3D 模型, 同时支持 AR 模式查看.`}
        right={T.translate('buttons.i_know')}
      />
    </div>
  );
}
