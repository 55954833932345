/**
 * Created by milan on 2019/7/18.
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/styles/withStyles';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
//import TextField from '@material-ui/core/TextField';
import { TextField } from 'formik-material-ui';
//import Typography from '@material-ui/core/Typography';
import Topbar from '../Topbar';
import Container from '@material-ui/core/Container';
import { Formik, Form, Field } from 'formik';
import T from 'i18n-react';
import { Event, DataManager } from '../models';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
//import { Link } from 'react-router-dom';


const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.grey['A500'],
    overflow: 'hidden',
    marginTop: theme.spacing(2),
    padding: theme.spacing(2)
  },
  container: {
    backgroundColor: 'white',
    marginTop: theme.spacing(4),
    padding: theme.spacing(2)
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
});

//const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
const validateInfo = values => {
  let errors = {};
  if (!values.phone) {
    //errors.phone = T.translate('errors.required');
  } else if (!/^[1]([3-9])[0-9]{9}$/.test(values.phone)) {
    errors.phone = T.translate('errors.invalid_phone');
  }
  if (!values.display) {
    errors.display = T.translate('errors.required');
  //} else if (values.display.length < 1) {
  //  errors.display = T.translate('errors.min_length', {length: 5});
  }
  //if (!values.email) {
  //  errors.email = T.translate('errors.required');
  //}
  if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errors.email = T.translate('errors.invalid_email');
  }
  if (Object.keys(errors).length)
    return errors;
};

const validatePassword = values => {
  let errors = {};
  if (!values.cp) {
    errors.cp = T.translate('errors.required');
  } else if (values.cp.length < 8) {
    errors.cp = T.translate('errors.min_length', {length: 8});
  }
  if (!values.np) {
    errors.np = T.translate('errors.required');
  } else if (values.np.length < 8) {
    errors.np = T.translate('errors.min_length', {length: 8});
  } else if (values.np !== values.confirm) {
    errors.confirm = T.translate('errors.password_not_match');
  }
  if (Object.keys(errors).length)
    return errors;
};

class Account extends Component {
  state = {
    value: 0
  };

  primary = {
    items: [
      {
        label: "Me",
        title: T.translate("title.Me"),
        pathname: "/me"
      },
      {
        label: "Signout",
        title: T.translate("title.ChangePassword"),
        pathname: "/signout"
      }
    ]
  };

  info = null;

  constructor(props) {
    super(props);
    this.show();
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  updateInfo = (values, actions) => {
    let self = this;
    return DataManager.getInstance().update_me(values).then(function (res) {
      let errors = {};
      let c = res.c;
      actions.setSubmitting(false);
      if (c !== 0) {
        let e = T.translate('errors.' + res.c);
        if (c === 401) {
          errors.display = e;
        }else if (c === 402) {
          errors.email = e;
        }
        if (Object.keys(errors).length) {
          actions.setErrors(errors);
          //actions.setStatus(errors);
        }
        return;
      }
      DataManager.getInstance().update_profile(res.d);
      DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'success', m: T.translate('notification.updated')});
      self.info = res.d;
      self.forceUpdate();
    });
  };

  updatePassword = (values, actions) => {
    return DataManager.getInstance().update_password(values).then(function (res) {
      let errors = {};
      let c = res.c;
      if (c !== 0) {
        let e = T.translate('errors.update_password.' + res.c);
        if (c === 403) {
          errors.cp = e;
        }else if (c === 401) {
          errors.confirm = e;
        }
        if (Object.keys(errors).length) {
          actions.setErrors(errors);
          //actions.setStatus(errors);
        }
      }
      actions.setSubmitting(false);
      DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'success', m: T.translate('notification.updated')});
    });
  };

  show = () => {
    let self = this;
    DataManager.getInstance().show_me().then(function (res) {
      if (res.c === 0) {
        self.info = res.d;
        self.forceUpdate();
      }
    });
  };

  render() {
    let primary = this.primary;

    const { classes } = this.props;
    const currentPath = this.props.location.pathname;
    return (
      <React.Fragment>
        <CssBaseline />
        <Topbar currentPath={currentPath} name={DataManager.getInstance().storage.name} />
        <div className={classes.root}>
          <Tabs
            value={this.state.value}
            indicatorColor="primary"
            textColor="primary"
            onChange={this.handleChange}
            centered
          >
            {primary.items.map((item, index) => (
              <Tab key={index} component={Button} classes={{root: classes.tabItem}} label={item.title} />
            ))}
          </Tabs>
          <Container component="main" maxWidth="xs" className={classes.container}>
            <div className={classes.paper}>
              {this.state.value === 0 ?
                <Info {...this.props} action={this.updateInfo.bind(this)} info={this.info} />
                :
                <Password {...this.props} action={this.updatePassword.bind(this)} />
              }
            </div>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}


function Password(props) {
  const { classes } = props;
  return (
    <Formik
      initialValues={{cp: '', np: '', confirm: '' }}
      validate={validatePassword}
      validateOnChange={true}
      onSubmit={props.action}
    >
      {props => (
        <Form className={classes.form} noValidate>
          <Field
            component={TextField}
            error={!!props.errors.cp}
            helperText={props.errors.cp}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="cp"
            label={T.translate("labels.current_password")}
            type="password"
            id="cp"
            autoComplete="current-password"
          />
          <Field
            component={TextField}
            error={!!props.errors.np}
            helperText={props.errors.np}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="np"
            label={T.translate("labels.new_password")}
            type="password"
            id="np"
          />
          <Field
            component={TextField}
            error={!!props.errors.confirm}
            helperText={props.errors.confirm}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="confirm"
            label={T.translate("labels.confirm_password")}
            type="password"
            id="confirm"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={props.isSubmitting}
          >
            {T.translate("buttons.submit")}
          </Button>
        </Form>
      )}
    </Formik>
  )
}

function Info(props) {
  const { classes, info } = props;
  if (!info) return <div></div>;
  return (
    <Formik
      initialValues={{contact: info.contact || '', display: info.display || '', email: info.email || '', phone: info.phone || ''}}
      validate={validateInfo}
      validateOnChange={true}
      onSubmit={props.action}
    >
      {props => (
        <Form className={classes.form} noValidate>
          <Field
            component={TextField}
            error={!!props.errors.display}
            helperText={props.errors.display}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="display"
            label={T.translate("form.display")}
            type="text"
            id="display"
            autoComplete="display"
          />
          <Field
            component={TextField}
            error={!!props.errors.phone}
            helperText={props.errors.phone}
            variant="outlined"
            margin="normal"
            fullWidth
            name="phone"
            label={T.translate("form.phone_notification")}
            placeholder={T.translate("form.phone_placeholder")}
            type="text"
            id="phone"
            autoComplete="phone"
          />
          <Field
            component={TextField}
            error={!!props.errors.email}
            helperText={props.errors.email}
            variant="outlined"
            margin="normal"
            fullWidth
            id="email"
            label={T.translate("email")}
            name="email"
            autoComplete="email"
          />
          <Field
            component={TextField}
            error={!!props.errors.contact}
            helperText={props.errors.contact}
            variant="outlined"
            margin="normal"
            fullWidth
            name="contact"
            label={T.translate("form.contact")}
            placeholder={T.translate("form.contact_placeholder")}
            type="text"
            id="contact"
            autoComplete="contact"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={props.isSubmitting}
          >
            {T.translate("buttons.save")}
          </Button>
        </Form>
      )}
    </Formik>
  );
}

export default withRouter(withStyles(styles)(Account));