import React,  { Component } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';

import { Mode, Event, DataManager } from '../models/';
import { priceDisplay, Currencies } from '../common/Constants';
import ProductDialog from '../Product/ProductDialog';
import ItemCell from './ItemCell';

import Shipping from './Shipping';
import T from 'i18n-react';
import AlertDialog from '../dialogs/AlertDialog';
import Dialog from '@material-ui/core/Dialog';

import CircularProgress from '@material-ui/core/CircularProgress';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import ListSubheader from '@material-ui/core/ListSubheader';
import Divider from '@material-ui/core/Divider';
import InfoButton from '../common/InfoButton';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';


const styles = theme => ({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
    marginTop: theme.spacing(2),
    padding: theme.spacing(1, 0, 1, 0),
    marginBottom: 50
  },
  paper: {
    //width: '100%',
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  list: {
    width: '100%',
    padding: theme.spacing(0, 0, 1, 0)
  },
  content: {
    fontSize: 24,
    margin: theme.spacing(2)
  },

  leading: {
    width: 54,
    minWidth: 54,
    maxWidth: 54,
    margin: theme.spacing(1)
  },
  label: {
    lineHeight: 2,
    fontSize: 14,
    marginLeft: theme.spacing(1)
  },
  importantInfo: {
    lineHeight: 2,
    fontSize: 18
  },

  checkout: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  textBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  costLabel: {
    lineHeight: 1.5,
    fontSize: 14,
    whiteSpace: 'pre-line',
    margin: theme.spacing(0.5)
  },
  info: {
    lineHeight: 1.5,
    fontSize: 14
  },
  subheader: {
    fontSize: 12,
    lineHeight: 2,
    paddingLeft: theme.spacing(2.5),
    color: '#4A90E2',
    backgroundColor: '#ebf3fd'
  },

  panel: {
    backgroundColor: '#fafafa',
    position: 'fixed',
    padding: theme.spacing(0, 2, 0, 2),
    height: 50,
    bottom: 0,
    zIndex: 101,
    width: '100%',
    maxWidth: 'inherit',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
});


class Checkout extends Component {
  deal_id = -1;
  state = {selected: -1, filter: 0, updated: 0, shipping: '', promote: false, show: false, agreement: false, requesting: false};
  deal = null;
  quotes = null;
  units = null;
  rates = null;
  summary = null;
  keys = ['subtotal', 'shipping', 'install', 'miscellaneous', 'service', 'total'];
  desc = ['miscellaneous', 'service', 'tax'];

  constructor(props) {
    super(props);
    const search = props.location.search.substring(1);
    if (search) {
      const deal_id = parseInt(search);
      if (deal_id > 0) this.deal_id = deal_id;
    }
    this.state.requesting = true;
    //this.state.shipping = {name: '123', phone: '18601224301', 'address': 123};
    if (this.deal_id > 0) this.show_deal();
  }

  selected = (id, index) => {
    this.setState({selected: index});
    //DataManager.getInstance().pub(Event.REDIRECT, `/products/i/${id}`);
  };

  deselect = () => {
    this.setState({selected: -1});
  };

  submit = (shipping) => {
    const quantities = {};
    const items = this.deal.items;
    let i;
    for (i = 0; i < items.length; ++i) {
      const item = items[i];
      quantities[item.item_id] = item.qty;
    }
    return DataManager.getInstance().quotes_to_order(this.deal_id, {quantities: quantities, summary: this.summary, address: shipping}).then(function (res) {
      if (res.c !== 0) {
        DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'error', m: T.translate('notification.failed')});
        return;
      }
      DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'success', m: T.translate('notification.success')});
      DataManager.getInstance().pub(Event.REDIRECT, `/orders/s/${res.d.id}`);
    });
  };

  show_shipping = (e) => {
    this.setState({show: true});
  };

  close_shipping = () => {
    this.setState({show: false});
  };

  close_agreement = (e) => {
    this.setState({agreement: false});
    const agreed = e.currentTarget.dataset.tag;
    if (agreed === '1') {
      this.submit();
    }
  };

  on_shipping = (shipping) => {
    this.close_shipping();
    if (shipping)
      this.setState({shipping: shipping});
  };

  on_publish = (e) => {
    if (!this.state.shipping) {
      this.show_shipping();
      return;
    }
    this.setState({agreement: true});
  };

  render() {
    if (!this.deal)
      return <div></div>;

    const { classes } = this.props;

    return (
      <React.Fragment>
        <ProductDialog open={this.state.selected >= 0} onClose={this.deselect} product={this.state.selected >= 0 ? this.deal.items[this.state.selected] : undefined} />
        <div className={classes.root}>
          {this.table()}
        </div>

        <Dialog
          PaperProps={{style: {backgroundColor: '#fafafa'}}}
          open={this.state.show}
          onClose={this.close_shipping}
          scroll="paper"
        >
          <Shipping shipping={this.state.shipping} onSave={this.on_shipping} />
        </Dialog>

        <AlertDialog
          open={this.state.agreement}
          onClose={this.close_agreement}
          title={T.translate('orders.publish')}
          desc={T.translate('dialogs.agree', T.translate('appName'))}
          left={T.translate('buttons.cancel')}
          right={T.translate('buttons.yes')}
        />
      </React.Fragment>
    );
  }

  table = () => {
    const { classes } = this.props;
    const deal = this.deal;
    const items = deal.items;
    const unit = this.unit;
    const fee = unit ? `${unit.fee * 100}%` : '';

    return (
      <Grid alignItems="center" justify="center" container>
        <Grid item xs={12} sm={8} md={8} lg={6} xl={6}>
          <React.Fragment>
            <Box>
              <Typography component="p" className={classes.importantInfo}>{T.translate('deals.shipping_info')}
                <IconButton onClick={this.show_shipping} >
                  {this.state.shipping ?
                    <EditIcon color="primary" fontSize="small" />
                    :
                    <AddIcon color="primary" fontSize="small" />
                  }
                </IconButton>
              </Typography>
              {this.state.shipping &&
              <React.Fragment>
                <Typography component="p" className={classes.label}>{this.state.shipping}</Typography>
              </React.Fragment>
              }
              <Box width={16}/>
            </Box>
            <Box style={{paddingBottom: this.state.expanded ? 130 : 0}}>
              <Paper className={classes.paper}>
                {this.state.requesting ?
                  <CircularProgress size={48} />
                  :
                  (items.length === 0 ?
                    <Typography className={classes.content}>{T.translate('products.empty_list')}</Typography>
                    :
                    <List className={classes.list}>
                      <ListSubheader>{T.translate('orders.products')} ({this.deal.count} {T.translate('deals.unit')}, {T.translate('orders.total_items', {c: this.deal.quantity})})</ListSubheader>
                      {items.map((item, index) => {
                        return <React.Fragment key={item.id}>
                          {index === 0 || item.category !== items[index - 1].category ?
                            <ListSubheader className={classes.subheader}>{item.category}</ListSubheader>
                            :
                            <Divider component="li" />
                          }
                          <ItemCell item={item} quotes={this.quotes[item.item_id]} units={this.units} onChange={this.calculate} onImageClick={() => this.selected(item.id, index)} />
                        </React.Fragment>;
                      })}
                      <ListSubheader className={classes.subheader}>{T.translate('orders.costs')}</ListSubheader>
                      <ListItem className={classes.cell} alignItems="center" >
                        <ListItemText
                          primary={
                            <Box className={classes.costBlock} key={this.state.updated}>
                            {this.keys.map((key, index) => (
                              <Box key={key} className={classes.textBox}>
                                <Typography component="span" className={classes.costLabel}>{T.translate(`orders.${key}`)}
                                {this.desc.indexOf(key) >= 0 &&
                                <InfoButton text={T.translate(`orders.${key}_desc`, {s: key === 'service' ? fee : `${deal.tax_rate}%`})} />
                                }
                                </Typography>
                                <Typography component="span" className={key === 'subtotal' || key === 'total' ? classes.importantInfo : classes.info}>{deal[key]}</Typography>
                              </Box>
                            ))}
                            </Box>
                          }
                        />
                      </ListItem>
                    </List>
                  )
                }
              </Paper>
            </Box>
          </React.Fragment>
          {this.panel()}
        </Grid>
      </Grid>
    );
  };

  panel() {
    const { classes } = this.props;
    const u_str = T.translate('deals.unit');
    const self = this;

    return (
      <Paper className={classes.panel}>
        <Box>
          <Typography component="span" className={classes.label}>
            {T.translate('deals.chosen')}: {this.deal.chosen} / {this.deal.count} {u_str}, {T.translate('orders.total_items', {c: this.deal.cq})},
          </Typography>
          <Typography component="span" className={classes.label}>{T.translate('orders.total')}: &nbsp;</Typography>
          <Typography component="span" className={classes.importantInfo}>{this.deal.total}</Typography>
        </Box>
        <QuoteButton
          classes={classes}
          units={this.units}
          summary={this.summary}
          address={this.state.shipping}
          deal={this.deal}
          onSubmit={this.submit}
          open={this.state.promote}
          disabled={this.state.requesting}
          onClose={() => {if (self.state.promote) self.setState({promote: false});}}
        />
      </Paper>
    );
  }

  calculate = () => {
    let chosen = 0;
    let count = 0;
    let q = 0;
    let quantity = 0;
    let subtotal = 0;
    let shipping = 0;
    let install = 0;
    let miscellaneous = 0;
    const summary = {};

    const items = this.deal.items;
    let i, j;
    for (i = 0; i < items.length; ++i) {
      const item = items[i];
      const qty = item.qty;
      quantity += qty;
      count++;

      item.price = 0;
      item.chosen = 0;
      const quotes = this.quotes[item.item_id];
      for (j = 0; j < quotes.length; ++j) {
        const quote = quotes[j];
        if (quote.chosen) {
          item.chosen = quote.unit_id;
          item.price = quote.price;
          break;
        }
      }
      const price = parseFloat(item.price);
      if (item.chosen && item.chosen > 0 && qty > 0 && item.quantity >= 0){
        const rate = this.rates[item.currency];
        const t = rate * price * qty;
        if (!summary[item.chosen]) summary[item.chosen] = {items: [], q: 0, t: 0};
        summary[item.chosen].items.push(item.item_id);
        summary[item.chosen].q += qty;
        summary[item.chosen].t += t;

        chosen++;
        q += qty;
        subtotal += t;
        shipping += (item.shipping || 0) * qty;
        install += (item.install || 0) * qty;
        miscellaneous += (item.miscellaneous || 0) * qty;
      }
    }
    this.deal.shipping = shipping;
    this.deal.install = install;
    this.deal.miscellaneous = miscellaneous;

    this.deal.quantity = quantity;
    this.deal.subtotal = subtotal;

    this.deal.service = subtotal * this.unit.fee;
    subtotal += shipping + install + miscellaneous;
    subtotal += this.deal.service;
    this.deal.total = subtotal;
    // this.deal.tax = this.deal.tax_rate * 0.01 * subtotal;
    // this.deal.total = subtotal + this.deal.tax;

    let symbol = '';
    for (i = 0; i < Currencies.length; ++i) {
      const c = Currencies[i];
      if (c.k === this.deal.currency) {
        symbol = c.s;
        break;
      }
    }
    for (i = 0; i < this.keys.length; ++i) {
      const key = this.keys[i];
      const value = Math.round(this.deal[key]);
      this.deal[key] = value > 0 ? priceDisplay(value, this.deal.currency) : `${symbol}0`;
    }
    this.deal.chosen = chosen;
    this.deal.count = count;
    this.deal.cq = q;
    this.summary = summary;
    this.setState({updated: this.state.updated + 1, promote: chosen >= count});
  };

  show_deal = () => {
    let self = this;
    DataManager.getInstance().show_deal(this.deal_id, {d: 10}).then(function (res) {
    // DataManager.getInstance().show_deal_with_quotes(this.deal_id, null).then(function (res) {
      if (res.c === 0) {
        const deal = res.d.d;
        if (DataManager.getInstance().mode < Mode.ADMIN && deal.state < 10) {
          DataManager.getInstance().pub(Event.REDIRECT, `/inquiries/o/${self.deal_id}`);
          return;
        }
        deal.items.sort((l, r) => (l.category >= r.category) ? -1 : 1);
        self.deal = deal;
        self.quotes = res.d.quotes;
        self.rates = res.d.rates;
        self.units = {};
        let i;
        for (i = 0; i < res.d.units.length; ++i) {
          const unit = res.d.units[i];
          if (!(unit.fee >= 0)) unit.fee = 0.05;
          self.units[unit.id] = unit;
        }
        const unit_id = res.d.unit_id || 0;
        self.unit = self.units[unit_id];

        const items = self.deal.items;
        for (i = 0; i < items.length; ++i) {
          const item = items[i];
          item.qty = item.quantity;
          item.value = parseFloat(item.price);
        }

        self.calculate();
        self.setState({requesting: false});
      }
    });
  };
}


function QuoteButton(props) {
  const { classes, deal, units, summary } = props;

  //let op = props.open;
  let def = props.address;

  const [open, setOpen] = React.useState(props.open);
  const [value, setValue] = React.useState(def);
  React.useEffect(() => {
    setValue(def);
    //setOpen(op);
  }, [def]);

  function handleClickOpen(e) {
    setOpen(true);
  }

  function handleClose(e) {
    setOpen(false);
    if (e.currentTarget.dataset.tag === '1') {
      props.onSubmit(value);
    }else{
      props.onClose();
    }
  }

  const handleChange = e => {
    const v = e.target.value;
    setValue(v);
  };

  const u_str = T.translate('deals.unit');
  return (
    <div style={{display: 'inline-block'}} onClick={(e) => e.stopPropagation()}>
      <Button variant="contained" color="primary" size="small" onClick={handleClickOpen} disabled={deal.chosen === 0} >
        {T.translate('orders.submit')}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        maxWidth='xs'
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">{T.translate('orders.publish')}</DialogTitle>
        <DialogContent>
          <Box>
            <Typography component="p">
              {T.translate('deals.chosen')}: {deal.chosen} / {deal.count} {u_str}, {T.translate('orders.total_items', {c: deal.cq})}
            </Typography>
            <Typography component="span">{T.translate('orders.subtotal')}: &nbsp;</Typography>
            <Typography component="span" className={classes.importantInfo}>{deal.subtotal}, &nbsp;</Typography>
            <Typography component="span">{T.translate('orders.total')}: &nbsp;</Typography>
            <Typography component="span" className={classes.importantInfo}>{deal.total}, &nbsp;</Typography>
            <Typography component="span">{T.translate('deals.summary')}: </Typography>
            <Box style={{paddingTop: 8}}>
              <Table size="small" aria-label="">
                <TableHead>
                  <TableRow>
                    <TableCell>{T.translate('orders.seller')}</TableCell>
                    <TableCell align="right">{T.translate('deals.count')}</TableCell>
                    <TableCell align="right">{T.translate('deals.quantity')}</TableCell>
                    <TableCell align="right">{T.translate('orders.subtotal')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.keys(units).map((unit_id, index) => {
                    const unit = units[unit_id];
                    const sm = summary[unit.id];
                    if (!sm || sm.items.length === 0) return null;
                    return (
                      <TableRow key={unit_id}>
                        <TableCell component="th" scope="row">{unit.display}</TableCell>
                        <TableCell align="right">{sm.items.length}</TableCell>
                        <TableCell align="right">{sm.q}</TableCell>
                        <TableCell align="right">{priceDisplay(Math.round(sm.t), deal.currency)}</TableCell>
                      </TableRow>
                    );
                    //return (
                    //  <Box style={{marginBottom: 8, width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}} key={unit.id}>
                    //    <Typography component="span" className={classes.info}>{unit.display}</Typography>
                    //    <Box>
                    //      <Typography component="span" className={classes.info}>
                    //        {sm.items.length}{u_str}, {T.translate('orders.total_items', {c: sm.q})}, {T.translate('orders.subtotal')} {priceDisplay(Math.round(sm.t), deal.currency)}
                    //      </Typography>
                    //    </Box>
                    //  </Box>
                    //)
                    }
                  )}
                </TableBody>
              </Table>

            </Box>
          </Box>
          <Box>
            <Typography component="span">{T.translate('deals.before_submit', {app: T.translate('appName')})}</Typography>
          </Box>
          <Box height={16} />
          <Box>
            <TextField
              name="address"
              type="text"
              fullWidth
              required
              label={T.translate('deals.shipping_info')}
              placeholder={T.translate('deals.shipping_info')}
              value={value || ''}
              onChange={handleChange}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button data-tag={0} onClick={handleClose}>
            {T.translate('buttons.cancel')}
          </Button>
          <Button data-tag={1} onClick={handleClose} color="primary" disabled={deal.chosen === 0 || !value}>
            {T.translate('orders.submit')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


export default withStyles(styles)(Checkout);