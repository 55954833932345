/**
 * Created by milan on 2019/7/18.
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/styles/withStyles';
import Button from '@material-ui/core/Button';
import { TextField } from 'formik-material-ui';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import { Formik, Form, Field } from 'formik';
import T from 'i18n-react';
import { Event, DataManager } from '../models/';
import Uploader from '../Inquiry/Upload';
import Regions from './Regions';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import LinearProgress from '@material-ui/core/LinearProgress';

import IconButton from '@material-ui/core/IconButton';
import BackIcon from '@material-ui/icons/ArrowBack';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import AlertDialog from '../dialogs/AlertDialog';


const styles = theme => ({
  buttons: {
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  root: {
    padding: theme.spacing(1)
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2)
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    //paddingTop: theme.spacing(4),
    width: '100%' // Fix IE 11 issue.
  },
  formControl: {
    marginBottom: 48
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  deletion: {
    color: theme.palette.danger.main
  }
});

class Edit extends Component {
  project = null;
  id = 0;
  timer = null;
  file_ref = null;

  constructor(props) {
    super(props);
    this.state = {
      files: [],
      show: false,
      alert: 0,
      progress: -1
    };
    let id = props.match.params.id;
    if (id) this.id = parseInt(id);
    if (this.id > 0)
      this.show();
    else
      this.state.show = true;
  }

  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  go_back = (e) => {
    const search = this.props.location.search;
    if (search && search.length > 0) {
      DataManager.getInstance().pub(Event.REDIRECT, search.substring(1));
      return;
    }
    DataManager.getInstance().pub(Event.REDIRECT, this.id > 0 ? `/projects/s/${this.id}/inquiries` : '/projects');
  };

  close = (e) => {
    this.setState({alert: 0});
    if (e.currentTarget.dataset.tag === '1') {
      if (this.state.alert === 1) {
        this.delete_project();
      }
    }
  };

  onProgress = (e) => {
    let p = e.loaded / e.total;
    let self = this;
    if (p < 1) {
      p *= 90;
      self.setState({progress: p});
    }else{
      self.setState({progress: 90});
      this.timer = setInterval(function () {
        let p = self.state.progress * 1.005;
        self.setState({progress: p});
      }, 1000);
    }
    //const diff = Math.random() * 10;
    //return Math.min(this.state.progress + diff, 100);
  };

  delete_project = () => {
    const self = this;
    DataManager.getInstance().delete_project(this.id, null).then(function (res) {
      if (res.c !== 0) {
        self.setState({alert: 2});
        return;
      }
      DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'success', m: T.translate('notification.deleted')});
      //self.go_back();
      DataManager.getInstance().pub(Event.REDIRECT, '/projects');
    });
  };

  on_delete = () => {
    this.setState({alert: 1});
  };

  show = () => {
    let self = this;
    DataManager.getInstance().show_project(this.id, null).then(function (res) {
      if (res.c === 0) {
        self.project = res.d.project;
        self.setState({show: true});
      }
    });
  };

  update_project = (values, actions) => {
    values.file = this.state.files;
    values.media = '';
    if (this.project && this.project.media && this.project.media.length > 0)
      values.media = this.project.media.join(';');
    let self = this;
    return DataManager.getInstance().update_project(values, this.onProgress).then(function (res) {
      let errors = {};
      if (res.c !== 0) {
        errors.title = T.translate('errors.' + res.c);
        actions.setSubmitting(false);
        self.setState({progress: -1});
        if (self.timer) {
          clearInterval(self.timer);
        }
        if (Object.keys(errors).length) {
          actions.setErrors(errors);
          //actions.setStatus(errors);
        }
        return;
      }
      self.go_back();
      //DataManager.getInstance().pub(Event.REDIRECT, '__go_back__');
    });
  };

  validate = values => {
    let errors = {};
    if (!values.title) {
      errors.title = T.translate('errors.required');
    }
    if (!values.region) {
      errors.region = T.translate('errors.required');
    }
    //if (!values.address) {
    //  errors.address = T.translate('errors.required');
    //}
    //if (!(this.id > 0) && !(this.state.files.length > 0)) {
    //  errors.file = T.translate('errors.required');
    //}
    if (Object.keys(errors).length)
      return errors;
  };

  file_selected = (fs) => {
    const files = this.state.files.slice();
    const update = {};
    for (var file of fs) {
      if (files.indexOf(file) < 0)
        files.splice(0, 0, file);
    }
    update.files = files;
    this.setState(update);
  };

  triggerAdd = (e) => {
    this.file_ref.focus();
    this.file_ref.click();
  };

  triggerDelete = (f) => {
    if (f instanceof File) {
      this.file_ref.value = '';
      const index = this.state.files.indexOf(f);
      if (index >= 0) {
        const fs = this.state.files.slice();
        fs.splice(index, 1);
        this.setState({files: fs});
      }
    }else if (this.project){
      const index = this.project.media.indexOf(f);
      if (index >= 0) {
        this.project.media.splice(index, 1);
        this.setState({files: this.state.files});
      }
    }
  };

  render() {
    if (!this.state.show) return <div></div>;
    const { classes } = this.props;
    let id = this.id;
    let self = this;
    const initialValues = {id: id || -1, title: '', region: '', address: ''};
    if (this.project) {
      initialValues.title = this.project.title || '';
      initialValues.region = this.project.region || '';
      initialValues.address = this.project.address || '';
    }

    const fs = this.project ? this.state.files.concat(this.project.media) : this.state.files;
    return (
      <div>
        <Container component="main" maxWidth="xs" className={classes.buttons}>
          <IconButton aria-label="close" color="inherit" onClick={this.go_back}>
            <BackIcon style={{width: 32, height: 32}} />
          </IconButton>
          {this.id > 0 && this.project && this.project.state < 10 &&
          <IconButton aria-label="delete" color="inherit" onClick={this.on_delete}>
            <DeleteIcon style={{width: 32, height: 32}} />
          </IconButton>
          }
          {this.state.alert === 1 &&
          <AlertDialog
            open={this.state.alert === 1}
            onClose={this.close}
            title={T.translate('dialogs.alert')}
            desc={T.translate('dialogs.confirm_delete_project')}
            left={T.translate('buttons.cancel')}
            right={T.translate('buttons.yes')}
          />
          }
          {this.state.alert === 2 &&
          <AlertDialog
            open={this.state.alert === 2}
            onClose={this.close}
            title={T.translate('dialogs.alert')}
            desc={T.translate('dialogs.failed_delete_project', {app: T.translate('appName')})}
            right={T.translate('buttons.ok')}
          />
          }

        </Container>
        <Container component="main" maxWidth="xs" className={classes.root}>
          <Paper className={classes.paper}>
            <Formik
              initialValues={initialValues}
              validate={this.validate}
              //validateOnChange={false}
              validateOnBlur={true}
              onSubmit={this.update_project}
            >
              {props => {
                return (
                  <Form className={classes.form} noValidate>
                    <Field
                      hidden
                      value={id}
                      required
                      id="id"
                      name="id"
                    />

                    <Field
                      component={TextField}
                      error={!!props.errors.title}
                      helperText={props.errors.title}
                      //variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      label={T.translate("projects.title")}
                      name="title"
                      autoComplete="title"
                      className={classes.formControl}
                    />

                    <div className={classes.formControl}>
                      <Regions
                        initialRegion={initialValues.region}
                        error={props.errors.region}
                        label={T.translate("projects.address")}
                        onSave={(v) => {
                          let region = [];
                          let address = [];
                          if (v && v.length > 0) {
                            let i;
                            for (i = 0; i < v.length; ++i) {
                              const r = v[i];
                              region.push(r.id);
                              address.push(r.name);
                            }
                          }
                          props.setFieldValue('address', address.join(', '), false);
                          props.setFieldValue('region', region.join(','), false);
                        }}
                      />
                    </div>

                    <Field
                      hidden
                      required
                      name="address"
                    />

                    <input
                      ref={fileInput => self.file_ref = fileInput}
                      style={{display: 'none'}}
                      accept="image/*"
                      type="file"
                      name="file"
                      multiple="multiple"
                      onChange={(e) => {
                        self.file_selected(e.currentTarget.files);
                      }}
                    />
                    <label htmlFor="file">
                      <FormControl
                        error={!!props.errors.file}
                        margin="normal"
                        fullWidth
                        style={{margin: 0, paddingTop: 30}}
                      >
                        <InputLabel
                          htmlFor="upload-display"
                          //style={{paddingLeft: 16}}
                          shrink={true} >
                          {T.translate("projects.upload")}
                        </InputLabel>
                        <Uploader files={fs} onAdd={self.triggerAdd} onDelete={self.triggerDelete} />
                        <FormHelperText id="upload-display-text">{props.errors.file}</FormHelperText>
                      </FormControl>
                    </label>

                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      disabled={props.isSubmitting}
                    >
                      {T.translate("projects.submit")}
                    </Button>
                    {self.state.progress >= 0 &&
                    <LinearProgress variant="determinate" value={self.state.progress} />
                    }
                  </Form>
                );
              }}
            </Formik>
          </Paper>
        </Container>
      </div>
    );


  }
}


export default withRouter(withStyles(styles)(Edit));