import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import T from 'i18n-react';
import { Mode, DataManager } from '../models/';


const useStyles = makeStyles(theme => ({
  badge: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    alignment: 'center'
  },
  typography: {
    padding: theme.spacing(2)
  },
  state_new: {
    padding: theme.spacing(0.2, 1, 0, 1),
    textAlign: 'center',
    fontSize: 13,
    color: 'white',
    borderRadius: 30,
    backgroundColor: 'red'
  },
  state_wait: {
    padding: theme.spacing(0.2, 1, 0, 1),
    textAlign: 'center',
    fontSize: 13,
    borderRadius: 30,
    color: 'white',
    backgroundColor: 'green'
  },
  state_read: {
    padding: theme.spacing(0.2, 1, 0, 1),
    textAlign: 'center',
    fontSize: 13,
    color: 'white',
    borderRadius: 30,
    backgroundColor: 'orange'
  },
  state_import: {
    padding: theme.spacing(0.2, 0.5, 0, 0.5),
    fontSize: 11,
    backgroundColor: 'orange',
    color: 'white',
    borderRadius: 30,
    marginBottom: 3
  },

  state_normal: {
    padding: theme.spacing(0.2, 1, 0, 1),
    textAlign: 'center',
    fontSize: 13,
    borderRadius: 30,
    color: 'grey',
    border: '1px solid'
  },
  state_action: {
    padding: theme.spacing(0.2, 1, 0, 1),
    textAlign: 'center',
    fontSize: 13,
    borderRadius: 30,
    color: 'black',
    border: '1px solid'
  },
  state_urgent: {
    padding: theme.spacing(0.2, 1, 0, 1),
    textAlign: 'center',
    fontSize: 13,
    borderRadius: 30,
    color: 'red',
    border: '1px solid'
  }
}));


function StateBadge(props) {
  const classes = useStyles();

  return (
    <div className={classes.badge}>
    <Typography key={props.state} component="span" className={classes[props.type]}>
      {props.title}
    </Typography>
    </div>
  );
}


function OrderBadge(order, OrderState) {
  const state = order.state;
  let status = OrderState[state];
  let cls;
  const is_seller = DataManager.getInstance().mode === Mode.BUSINESS;
  if (order.payment >= 1000) {
    if (!is_seller && order.type !== 2) {
      status = T.translate(order.payment >= 2000 ? 'orders.payments_verifying' : 'orders.payments_due');
      cls = order.payment >= 2000 ? 'normal' : 'urgent';
    }else if (is_seller && order.type === 2) {
      status = T.translate(order.payment >= 2000 ? 'orders.payment.you_payee_verifying' : 'orders.payment.you_payee');
      cls = order.payment >= 2000 ? 'urgent' : 'normal';
    }else{
      cls = 'normal';
    }
  }else if (is_seller && state === OrderState.Bidding) {
    cls = 'urgent';
  }else if (state === OrderState.Partitioned){
    return;
  }else if (state < OrderState.Partitioned && state >= OrderState.Shipping){
    cls = 'action';
  }else{
    cls = 'normal';
  }
  return <StateBadge state={state} type={`state_${cls}`} title={status} />;
}


function OrderItemBadge(state, OrderState) {
  if (state === OrderState.Partitioned) return;
  let status = OrderState[state];
  let cls;
  if (state === OrderState.Blueprinting){
    cls = 'action';
  }else{
    cls = 'normal';
  }

  return <StateBadge state={state} type={`state_${cls}`} title={status} />;
}


function DealBadge(state) {
  if (state % 10 === 1){
    return <StateBadge state={state} type="state_wait" title={T.translate('inquiries.state_wait')} />;
  }else if (state % 10 === 2){
    return <StateBadge state={state} type="state_new" title={T.translate('inquiries.state_new')} />;
  }else if (state % 10 === 3){
    return <StateBadge state={state} type="state_read" title={T.translate('inquiries.state_updating')} />;
  }else{
    return undefined;
  }
  // return <StateBadge state={state} type={t} title={T.translate(`inquiries.${t}`)} />;
}


function QuoteBadge(state) {
  if (state % 10 === 7){
    return <StateBadge state={state} type="state_normal" title={T.translate('quotation.state_read')} />;
  }else if (state % 10 === 5){
    return <StateBadge state={state} type="state_action" title={T.translate('quotation.state_lock')} />;
  }else if (state % 10 === 3){
    return <StateBadge state={state} type="state_urgent" title={T.translate('quotation.state_updating')} />;
  }else if (state % 10 === 2){
    return <StateBadge state={state} type="state_action" title={T.translate('quotation.state_wait')} />;
  }else if (state % 10 === 1){
    return <StateBadge state={state} type="state_urgent" title={T.translate('quotation.state_new')} />;
  }else{
    return undefined;
    // return <StateBadge state={state} type="state_urgent" title={T.translate('quotation.state_new')} />;
  }
}


export { StateBadge, OrderBadge, OrderItemBadge, DealBadge, QuoteBadge };