import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import T from 'i18n-react';

import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { fromBits, toBits } from './Constants';


const useStyles = makeStyles(theme => ({
}));


export default function Flags(props) {
  // const f = [0, 1, 0, 0];
  // const tb = toBits(f, f.length);
  // const fb = fromBits(tb, f.length);
  // console.log(f, tb, fb);

  const { choices, current } = props;

  const [chosen, setChosen] = React.useState(fromBits(current, choices.length));

  const classes = useStyles(props.theme);

  const onChange = (e) => {
    const checked = e.target.checked;
    //const key = e.currentTarget.dataset.tag;
    const index = parseInt(e.currentTarget.dataset.index);
    const new_flags = [...chosen];
    new_flags[index] = checked ? 1 : 0;
    setChosen(new_flags);
    props.onChange(toBits(new_flags, choices.length));
  };

  return (
    <React.Fragment >        
      <Box onClick={e => {e.stopPropagation();}}>
        {choices.map((choice, index) => {
          return <FormControlLabel
            key={choice}
            control={
              <Checkbox
                name={`flags_${choice}_${index}`}
                value={index}
                checked={chosen[index] > 0}
                onChange={onChange}
                color="primary"
                disabled={!props.onChange}
                inputProps={{ 'aria-label': 'item checkbox', 'data-tag': choice, 'data-index': index }}
              />
            }
            className={classes.formControl}
            label={T.translate(`flags.${choice}`)}
          />;
        })}
      </Box>
    </React.Fragment>
  );
}

