import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import T from 'i18n-react';
import { Event, DataManager } from '../models/';

import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';

import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';


const useStyles = makeStyles(theme => ({
  leading: {
    width: 54,
    minWidth: 54,
    maxWidth: 54,
    margin: theme.spacing(1)
  },
  id: {
    display: 'block',
    fontSize: 24,
    width: '100%'
  },
  label: {
    lineHeight: 2,
    fontSize: 14,
    marginLeft: theme.spacing(1)
  },
  cell: {
    backgroundColor: 'white',
    paddingLeft: 0,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  card: {
    marginBottom: theme.spacing(2)
  },
  media: {
    height: 140
  },
  btn: {
    marginRight: theme.spacing(4)
  },
  padding: {
    padding: theme.spacing(0, 1)
  }
}));

//const placeholders = ['https://aifa.oss-cn-qingdao.aliyuncs.com/project/3//00c207654129b6727344da6380690f01/00c207654129b6727344da6380690f01.jpg'];
const placeholders = [
  'https://aifa.oss-cn-qingdao.aliyuncs.com/project/p01.jpg',
  'https://aifa.oss-cn-qingdao.aliyuncs.com/project/p02.jpg',
  'https://aifa.oss-cn-qingdao.aliyuncs.com/project/p03.jpg'
];

export default function Cell(props) {
  const { item, onClick, onPrioritized } = props;

  const classes = useStyles(props.theme);
  const [top, setTop] = React.useState(!!item.top);

  const toggleTop = (e) => {
    let checked = e.target.checked;
    DataManager.getInstance().config_project(item.id, {pu: 1}).then(function (res) {
      if (res.c === 0) {
        if (res.d.top >= 0)
          checked = res.d.top > 0;
        item.top = checked;
        setTop(checked);
        if (onPrioritized) onPrioritized();
      }else if (res.c === 403){
        const n = res.d.n || 3;
        DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'error', m: T.translate('projects.stick_top_too_much', {c: n})});
      }
    });
  };

  return (
    <React.Fragment key={item.id}>
      <Card className={classes.card}>
        <CardActionArea onClick={onClick}>
          <CardMedia
            className={classes.media}
            image={item.media[0] || placeholders[item.id % placeholders.length]}
            title={item.title}
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              {item.title}
            <div
              onClick={e => {e.stopPropagation();}}
              style={{float: 'right', right: 0, top: 0}}
              // style={{position: 'absolute', right: 0, top: 0}}
            >
              <FormControlLabel
                control={
                <Checkbox
                  color="primary" 
                  disabled={false}
                  value={top}
                  checked={top}
                  onChange={toggleTop}
                />}
                size="small"
                labelPlacement="top"
                label={T.translate("projects.stick_top")}
              />
            </div>
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {item.address}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <Button className={classes.btn} size="small" color="primary" component={Link} to={{pathname: `/projects/s/${item.id}/products`}}>
            <Badge className={classes.padding} color="primary" badgeContent={item.mc.p || 0}>
              {T.translate('projects.products')}
            </Badge>
          </Button>
          <Button className={classes.btn} size="small" color="primary" component={Link} to={{pathname: `/projects/s/${item.id}/inquiries`}}>
            <Badge className={classes.padding} color="primary" badgeContent={item.mc.i || 0}>
              {T.translate('projects.inquiries')}
            </Badge>
          </Button>
          <Button className={classes.btn} size="small" color="primary" component={Link} to={{pathname: `/projects/s/${item.id}/orders`}}>
            <Badge className={classes.padding} color="primary" badgeContent={item.mc.o || 0}>
              {T.translate('projects.orders')}
            </Badge>
          </Button>
          <Button className={classes.btn} size="small" color="primary" component={Link} to={{pathname: `/projects/s/${item.id}/tm`}}>
            <Badge className={classes.padding} color="primary" badgeContent={item.mc.items || 0}>
              {T.translate('projects.track')}
            </Badge>
          </Button>
        </CardActions>
      </Card>
    </React.Fragment>
  )
}
