/**
 * Created by milan on 2019/7/18.
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/styles/withStyles';
import T from 'i18n-react';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { Event, DataManager } from '../models/';
import AlertDialog from '../dialogs/AlertDialog';
import { priceDisplay } from '../common/Constants';


const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(1),
    overflowX: 'auto'
  }
});


//const ensureFloat = (dictionary) => {
//  const dict = {};
//  Object.keys(dictionary).forEach(function(key) {
//    dict[key] = parseFloat(dictionary[key] || 0);
//  });
//  return dict;
//};


class Costs extends Component {
  id = -1;
  state = {
    alert: 0,
    memo: '',
    currency: 'CNY',
    values: {
      tax_rate: 6,
      shipping: 0,
      service: 0,
      install: 0,
      miscellaneous: 0,

      subtotal: 0,
      tax: 0,
      total: 0
    },
    requesting: false
  };

  save_order = () => {
    this.setState({requesting: true});
    const params = {memo: this.state.memo, currency: this.state.currency, ...this.state.values};
    return DataManager.getInstance().update_order(this.props.order.id, params);
  };

  save = (e) => {
    let tag = e.currentTarget.dataset.tag;
    const pub = tag === '1';
    const self = this;
    self.setState({requesting: true});
    // let path = '/' + this.props.match.path.split('/')[1];
    const params = {memo: this.state.memo, pub: pub, currency: this.state.currency, ...this.state.values};
    DataManager.getInstance().update_order(this.props.order.id, params).then(function (res) {
      self.setState({requesting: false});
      if (res.c !== 0) {
        //const d = res.d;
        return;
      }
      // DataManager.getInstance().pub(Event.REDIRECT, path);
    });
  };

  verified = (e) => {
    this.setState({alert: this.OrderState.PaymentClaimed});
  };

  accepted = (e) => {
    this.setState({alert: this.OrderState.Bidding});
  };

  close_alert = (e) => {
    this.setState({alert: 0});
    if (e.currentTarget.dataset.tag === '1') {
      const self = this;
      self.setState({requesting: true});
      let path = '/' + this.props.match.path.split('/')[1];
      const params = {verified: true};
      DataManager.getInstance().update_order(this.props.order.id, params).then(function (res) {
        if (res.c !== 0) {
          //const d = res.d;
          self.setState({requesting: false});
          return;
        }
        DataManager.getInstance().pub(Event.REDIRECT, path);
      });
    }
  };

  remove = () => {
    const self = this;
    self.setState({requesting: true});
    let path = '/' + this.props.match.path.split('/')[1];
    DataManager.getInstance().delete_order(this.id, null).then(function (res) {
      if (res.c !== 0) {
        //const d = res.d;
        self.setState({requesting: false});
        return;
      }
      DataManager.getInstance().pub(Event.REDIRECT, path);
    });
  };

  componentDidMount() {
    if (this.props.onRef) this.props.onRef(this);
  }

  componentWillUnmount() {
    if (this.props.onRef) this.props.onRef(undefined);
  }

  constructor(props) {
    super(props);
    this.OrderState = DataManager.getInstance().states.orders;
    this.state.values.subtotal = props.subtotal || 0;
    if (props.order) {
      const order = props.order;
      this.state.values.subtotal = order.subtotal || 0;
      this.state.values.tax_rate = order.tax_rate || 0;
      this.state.values.shipping = order.shipping || 0;
      this.state.values.service = order.service || 0;
      this.state.values.install = order.install || 0;
      this.state.values.miscellaneous = order.miscellaneous || 0;
      this.state.values.tax = order.tax || 0;
      this.state.values.total = order.total || 0;

      this.state.memo = order.memo;
      this.state.currency = order.currency;

      this.id = order.id;
    }
    if (!this.state.memo || this.state.memo.length === 0) {
      this.state.memo = '';
    }
  }

  static getDerivedStateFromProps(props, curr_state) {
    return {
      values: {
        ...curr_state.values,
        subtotal: props.order.subtotal || 0,
        shipping: props.order.shipping || 0,
        install: props.order.install || 0,
        miscellaneous: props.order.miscellaneous || 0,
        service: props.order.service || 0,
        tax: props.order.tax || 0,
        total: props.order.total || 0
      }
    };
  }

  handleChange = event => {
    const s = {};
    s[event.target.name] = event.target.value;
    this.setState(s);
  };

  handleValueChange = event => {
    const values = this.state.values;
    values[event.target.name] = event.target.value;
    this.setState({values: values});
  };

  render() {
    const OrderState = this.OrderState;
    const trans = this.props.order.due;
    const so = this.props.order.type === 2;

    const { classes } = this.props;
    return (
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableBody>
            <TableRow>
              <TableCell> </TableCell>
              <TableCell> </TableCell>
              <TableCell> </TableCell>
              <TableCell> </TableCell>
              <TableCell> </TableCell>
              <TableCell> </TableCell>
              <TableCell> </TableCell>
              <TableCell> </TableCell>
            </TableRow>

            <TableRow>
              <TableCell rowSpan={7} colSpan={5}>
                <TextField
                  id="memo"
                  name="memo"
                  value={this.state.memo}
                  onChange={this.handleChange}
                  label={T.translate('orders.desc')}
                  placeholder={T.translate('orders.desc')}
                  multiline
                  margin="dense"
                  fullWidth
                />
              </TableCell>
              <TableCell colSpan={2}>{so ? T.translate('orders.subtotal') : T.translate('orders.taxed')}</TableCell>
              <TableCell align="right">{this.state.values.subtotal}</TableCell>
            </TableRow>
            <TableRow>
              {so ? <TableCell colSpan={3}/> :
              <React.Fragment>
              <TableCell colSpan={2}>{T.translate('orders.tax_rate')}</TableCell>
              <TableCell align="right">
                {this.props.order.state >= OrderState.Sealed ?
                  `${this.state.values.tax_rate}%`
                  :
                <TextField
                  //defaultValue={7}
                  placeholder={T.translate('orders.tax_rate')}
                  name="tax_rate"
                  value={this.state.values.tax_rate}
                  onChange={this.handleValueChange}
                  margin="dense"
                  InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                      inputProps: {style: {textAlign: 'right'}}
                    }}
                />
                }
              </TableCell>
              </React.Fragment>
              }
              {/* <TableCell align="right">{this.state.values.tax}</TableCell> */}
            </TableRow>
            <TableRow>
              <TableCell colSpan={2}>{T.translate('orders.shipping')}</TableCell>
              <TableCell align="right">{this.state.values.shipping}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2}>{T.translate('orders.install')}</TableCell>
              <TableCell align="right">{this.state.values.install}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2}>{T.translate('orders.miscellaneous')}</TableCell>
              <TableCell align="right">{this.state.values.miscellaneous}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2}>{T.translate('orders.service')}</TableCell>
              <TableCell align="right">{this.state.values.service}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2}>{T.translate('orders.total')}</TableCell>
              <TableCell align="right">{this.state.values.total}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Button variant="outlined" color="secondary" size="small" onClick={this.remove} disabled={this.state.requesting}>
                  {T.translate('orders.remove')}
                </Button>
              </TableCell>
              <TableCell colSpan={6} align="center">
                {this.props.order.state < OrderState.Sealed && this.props.order.items.length > 0 &&
                <Button variant="outlined" data-tag={1} color="primary" size="small" onClick={this.save} disabled={this.state.requesting}>
                  {T.translate('orders.publish')}
                </Button>
                }

                {this.props.order.state >= OrderState.Sealed && trans && trans.type === 1 &&
                <React.Fragment>
                  <Typography component="p" className={classes.label}>{T.translate('orders.payment.user_to_pay', {c: priceDisplay(trans.amount, trans.currency)})}</Typography>
                  {trans.external &&
                  <Typography component="p" className={classes.label}>{T.translate('orders.payment.external_order_id')}: {trans.external}</Typography>
                  }
                  {trans.state >= 2 &&
                  <React.Fragment>
                    <Button variant="outlined" data-tag={2} color="primary" size="small" onClick={this.verified} disabled={this.state.requesting}>
                      {T.translate('orders.payment.verified')}
                    </Button>
                    <AlertDialog
                      open={this.state.alert === OrderState.PaymentClaimed}
                      onClose={this.close_alert}
                      title={T.translate('orders.alert.verified', {a: this.props.order.due.amount})}
                      desc={T.translate('orders.alert.verified_info', {a: this.props.order.due.amount, o: this.props.order.due.external})}
                      left={T.translate('buttons.cancel')}
                      right={T.translate('orders.alert.confirm')}
                    />
                  </React.Fragment>
                  }
                </React.Fragment>
                }

                {this.props.order.state >= OrderState.Bid && trans && trans.type === 2 &&
                <React.Fragment>
                  <Typography component="p" className={classes.label}>{T.translate('orders.payment.due', {c: priceDisplay(trans.amount, trans.currency)})}</Typography>
                  {trans.external &&
                  <Typography component="p" className={classes.label}>{T.translate('orders.payment.external_order_id')}: {trans.external}</Typography>
                  }
                  {trans.state < 10 &&
                  <React.Fragment>
                    <Button variant="outlined" data-tag={2} color="primary" size="small" onClick={(e) => {
                      DataManager.getInstance().pub(Event.REDIRECT, `/orders/ps/${this.props.order.id}`);
                    }} >
                      {T.translate(trans.state >= 2 && trans.state < 10 ? 'orders.payment.repay' : 'orders.payment.pay_seller')}
                    </Button>
                  </React.Fragment>
                  }
                </React.Fragment>
                }
                {this.props.order.state === OrderState.Partitioned &&
                <Typography component="p" className={classes.label}>{OrderState[OrderState.Partitioned]}</Typography>
                }
              </TableCell>

              <TableCell align="right">
                <Button variant="outlined" data-tag={0} color="primary" size="small" onClick={this.save} disabled={this.state.requesting}>
                  {T.translate('orders.save')}
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
    )
  }
}


export default withRouter(withStyles(styles)(Costs));