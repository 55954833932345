/**
 * Created by milan on 2019/7/18.
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/styles/withStyles';
import Button from '@material-ui/core/Button';
import { TextField, Select } from 'formik-material-ui';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import { Formik, Form, Field } from 'formik';
import T from 'i18n-react';
import { Event, DataManager } from '../models/';

import LinearProgress from '@material-ui/core/LinearProgress';

import IconButton from '@material-ui/core/IconButton';
import BackIcon from '@material-ui/icons/ArrowBack';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import AlertDialog from '../dialogs/AlertDialog';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';


const styles = theme => ({
  buttons: {
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  root: {
    padding: theme.spacing(1)
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2)
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    //paddingTop: theme.spacing(4),
    width: '100%' // Fix IE 11 issue.
  },
  formControl: {
    marginBottom: 48
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  deletion: {
    color: theme.palette.danger.main
  }
});

class Edit extends Component {
  quote = null;
  id = 0;

  constructor(props) {
    super(props);
    this.state = {
      files: [],
      show: false,
      alert: 0,
      progress: -1
    };
    let id = props.match.params.id;
    if (id) this.id = parseInt(id);
    if (this.id > 0)
      this.show();
    else
      this.state.show = true;
  }

  go_back = (e) => {
    DataManager.getInstance().pub(Event.REDIRECT, this.id > 0 ? `/quotes/s/${this.id}` : '/quotes');
  };

  close = (e) => {
    this.setState({alert: 0});
    if (e.currentTarget.dataset.tag === '1') {
      if (this.state.alert === 1) {
        this.delete_quote();
      }
    }
  };

  delete_quote = () => {
    const self = this;
    DataManager.getInstance().delete_quote(this.id, null).then(function (res) {
      if (res.c !== 0) {
        self.setState({alert: 2});
        return;
      }
      DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'success', m: T.translate('notification.deleted')});
      //self.go_back();
      DataManager.getInstance().pub(Event.REDIRECT, '/quotes');
    });
  };

  on_delete = () => {
    this.setState({alert: 1});
  };

  show = () => {
    let self = this;
    DataManager.getInstance().show_quote(this.id, null).then(function (res) {
      if (res.c === 0) {
        self.quote = res.d.q;
        self.setState({show: true});
      }
    });
  };

  update_quote = (values, actions) => {
    const self = this;
    return DataManager.getInstance().update_quote(this.id, values).then(function (res) {
      let errors = {};
      if (res.c !== 0) {
        errors.req = T.translate('errors.' + res.c);
        actions.setSubmitting(false);
        if (Object.keys(errors).length) {
          actions.setErrors(errors);
        }
        return;
      }
      self.go_back();
      //DataManager.getInstance().pub(Event.REDIRECT, '__go_back__');
    });
  };

  validate = values => {
    let errors = {};
    if (!values.req) {
      errors.req = T.translate('errors.required');
    }
    // if (!values.budget) {
    //   errors.budget = T.translate('errors.required');
    // }
    if (Object.keys(errors).length)
      return errors;
  };

  render() {
    if (!this.state.show) return <div></div>;
    const { classes } = this.props;
    let id = this.id;
    let self = this;
    const initialValues = {id: id || -1, req: '', budget: '', state: ''};
    if (this.quote) {
      initialValues.req = this.quote.req || '';
      initialValues.budget = this.quote.budget || '';
      initialValues.state = this.quote.state || '';
    }
    const states = [T.translate('quotation.state_new'), T.translate('quotation.state_wait'), T.translate('quotation.state_updating'),
    T.translate('quotation.state_lock'), T.translate('quotation.state_read')];
    const values = [11, 12, 13, 5, 7];

    return (
      <div>
        <Container component="main" maxWidth="xs" className={classes.buttons}>
          <IconButton aria-label="close" color="inherit" onClick={this.go_back}>
            <BackIcon style={{width: 32, height: 32}} />
          </IconButton>
          {this.id > 0 && this.quote &&
          <IconButton aria-label="delete" color="inherit" onClick={this.on_delete}>
            <DeleteIcon style={{width: 32, height: 32}} />
          </IconButton>
          }
          {this.state.alert === 1 &&
          <AlertDialog
            open={this.state.alert === 1}
            onClose={this.close}
            title={T.translate('dialogs.alert')}
            desc={T.translate('dialogs.confirm_delete_quote')}
            left={T.translate('buttons.cancel')}
            right={T.translate('buttons.yes')}
          />
          }
          {this.state.alert === 2 &&
          <AlertDialog
            open={this.state.alert === 2}
            onClose={this.close}
            title={T.translate('dialogs.alert')}
            desc={T.translate('dialogs.failed_delete_quote')}
            right={T.translate('buttons.ok')}
          />
          }

        </Container>
        <Container component="main" maxWidth="xs" className={classes.root}>
          <Paper className={classes.paper}>
            <Formik
              initialValues={initialValues}
              validate={this.validate}
              //validateOnChange={false}
              validateOnBlur={true}
              onSubmit={this.update_quote}
            >
              {props => {
                return (
                  <Form className={classes.form} noValidate>
                    <Field
                      hidden
                      value={id}
                      required
                      id="id"
                      name="id"
                    />

                    <Field
                      component={TextField}
                      error={!!props.errors.req}
                      helperText={props.errors.req}
                      //variant="outlined"
                      margin="none"
                      required
                      fullWidth
                      label={T.translate("quotation.req")}
                      name="req"
                      autoComplete="req"
                      className={classes.formControl}
                    />

                    <Field
                      component={TextField}
                      error={!!props.errors.budget}
                      helperText={props.errors.budget}
                      margin="none"
                      //variant="outlined"
                      // required
                      fullWidth
                      label={T.translate("quotation.budget")}
                      name="budget"
                      autoComplete="budget"
                      className={classes.formControl}
                    />

                    <Field
                      component={Select}
                      error={!!props.errors.state}
                      margin="none"
                      required
                      fullWidth
                      label={T.translate("quotation.state")}
                      name="state"
                      autoComplete="state"
                      className={classes.formControl}
                    >
                      {states.map((state, index) => (
                        <MenuItem key={values[index]} value={values[index]}><Typography align="center" variant="h6">{state}</Typography></MenuItem>
                      ))}
                    </Field>

                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      disabled={props.isSubmitting}
                    >
                      {T.translate("quotation.submit")}
                    </Button>
                    {self.state.progress >= 0 &&
                    <LinearProgress variant="determinate" value={self.state.progress} />
                    }
                  </Form>
                );
              }}
            </Formik>
          </Paper>
        </Container>
      </div>
    );


  }
}


export default withRouter(withStyles(styles)(Edit));