/**
 * Created by milan on 2019/7/18.
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/styles/withStyles';
import T from 'i18n-react';
import { DataManager } from '../models/';
import AlertDialog from '../dialogs/AlertDialog';

import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { priceDisplay } from '../common/Constants';
import { OrderItemBadge } from '../common/StateBadge';


const styles = theme => ({
  header: {
    margin: 0,
    padding: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  }
});


class SellerActions extends Component {
  order = null;

  constructor(props) {
    super(props);
    this.OrderState = DataManager.getInstance().states.orders;
    this.state = {carousel: null, show: true, requesting: false};
    this.order = props.order;
    if (!this.order || !(this.order.id > 0))
      this.state.show = false;
  }

  update_order = (params) => {
    const self = this;
    self.setState({requesting: true});
    return DataManager.getInstance().seller_order(this.order.id, params).then(function (res) {
      if (res.c !== 0) {
        return;
      }
      self.order.due = res.d.due;
      if (self.order.state !== res.d.state || self.order.payment !== res.d.payment) {
        self.order.state = res.d.state;
        self.order.payment = res.d.payment;
        self.props.show();
      }else{
        self.setState({requesting: false});
      }
    });
  };

  accept = (e) => {
    this.setState({alert: this.OrderState.Bidding});
  };

  got_paid = (e) => {
    this.setState({alert: this.OrderState.PaymentConfirmed});
  };

  request_payment = (e) => {
    this.setState({alert: this.OrderState.PaymentClaimed});
  };

  closeAlert = (e) => {
    if (e.currentTarget.dataset.tag === '1') {
      const params = {};
      if (this.state.alert === this.OrderState.Bidding) params.accept = true;
      else if (this.state.alert === this.OrderState.PaymentConfirmed) params.paid = true;
      else if (this.state.alert === this.OrderState.PaymentClaimed) params.request = true;
      else params.state = this.state.alert;
      this.update_order(params);
    }
    this.setState({alert: 0});
  };

  state_badge = (state) => {
    return OrderItemBadge(state, DataManager.getInstance().states.orders);
  };

  handle_state = (e) => {
    const state = parseInt(e.target.value);
    if (state > this.order.state)
      this.setState({alert: state});
    else
      this.update_order({state: state});
  };

  state_selector = (order) => {
    const OrderState = DataManager.getInstance().states.orders;
    const states = [];
    let editable = order.type !== 2;
    let k;
    if (editable) {
      for (k in OrderState) {
        if (OrderState.hasOwnProperty(k) && k >= OrderState.Bid && k < OrderState.Partitioned && k % 10 === 0) {
          states.push({title: OrderState[k], state: parseInt(k)});
        }
      }
    }else{
      if (order.state === OrderState.Prepared) {
        states.push({title: OrderState[OrderState.Prepared], state: OrderState.Prepared});
        editable = true;
      }
      for (k in OrderState) {
        if (OrderState.hasOwnProperty(k) && k > OrderState.Prepared && k < OrderState.Received && k % 10 === 0) {
          states.push({title: OrderState[k], state: parseInt(k)});
          if (parseInt(k) === order.state) editable = true;
        }
      }
    }

    if (!editable) {
      return this.state_badge(order.state);
    }
    const show = this.state.alert >= states[0].state && this.state.alert <= states[states.length - 1].state;
    return (
      <React.Fragment>
        <Select
          value={order.state}
          onChange={this.handle_state}
          disableUnderline={false}
          variant="standard"
        >
          {states.map((order_state, index) => {
            return <MenuItem key={index} value={order_state.state}>{order_state.title}</MenuItem>
          })}
        </Select>
        {show &&
        <AlertDialog
          open={show}
          onClose={this.closeAlert}
          title={T.translate('orders.alert.notice')}
          desc={T.translate('orders.alert.states_change_alert', {s: OrderState[this.state.alert]})}
          left={T.translate('buttons.cancel')}
          right={T.translate('buttons.yes')}
        />
        }
      </React.Fragment>
    );
  };

  render() {
    if (!this.state.show) return <div></div>;
    const { classes } = this.props;
    const OrderState = this.OrderState;
    const trans = this.order.due;

    if (!DataManager.getInstance().permit('Order'))
      return <Container className={classes.header} >
        {this.state_badge(this.order.state)}
      </Container>;

    return <Container className={classes.header} >
      {this.order.state === OrderState.Bidding &&
      <React.Fragment>
        <Button variant="outlined" data-tag={2} color="primary" size="small" onClick={this.accept} disabled={this.state.requesting}>
          {T.translate('orders.accept')}
        </Button>
        <AlertDialog
          open={this.state.alert === OrderState.Bidding}
          onClose={this.closeAlert}
          title={T.translate('orders.accept')}
          desc={T.translate('orders.alert.confirm_accept_info', {c: this.order.quantity, a: priceDisplay(this.order.total, this.order.currency)})}
          left={T.translate('buttons.cancel')}
          right={T.translate('orders.alert.confirm_accept')}
        />
      </React.Fragment>
      }
      {this.order.state >= OrderState.Bid && this.order.state < OrderState.Received &&
        this.state_selector(this.order)
      }
      {this.order.state >= OrderState.Received &&
      <React.Fragment>
        {this.state_badge(this.order.state)}
      </React.Fragment>
      }
      {this.order.state >= OrderState.Bid && trans && trans.state < 2 &&
      <React.Fragment>
        <div style={{width: 20}}></div>
        <Typography component="p" className={classes.label}>
          {T.translate('orders.payment.paying', {c: priceDisplay(trans.amount, trans.currency)})}
        </Typography>
      </React.Fragment>
      }
      {this.order.state >= OrderState.Bid && trans && trans.state >= 2 && trans.state < 10 &&
      <React.Fragment>
        <div style={{width: 20}}></div>
        <Button variant="outlined" data-tag={3} color="primary" size="small" onClick={this.got_paid} disabled={this.state.requesting}>
          {T.translate('orders.alert.confirm')}
        </Button>
        <AlertDialog
          open={this.state.alert === OrderState.PaymentConfirmed}
          onClose={this.closeAlert}
          title={T.translate('orders.alert.verified', {a: this.order.due.amount})}
          desc={T.translate('orders.alert.verified_info', {a: this.order.due.amount, o: this.order.due.external})}
          left={T.translate('buttons.cancel')}
          right={T.translate('orders.alert.confirm')}
        />
      </React.Fragment>
      }
    </Container>;
  }
}


export default withRouter(withStyles(styles)(SellerActions));