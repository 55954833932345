/**
 * Created by milan on 2019/7/18.
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/styles/withStyles';
import T from 'i18n-react';
import { Event, DataManager } from '../models/';
import ProductDialog from '../Product/ProductDialog';
import Image from '../common/Image';
import InfoButton from '../common/InfoButton';
import { priceDisplay } from '../common/Constants';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Box from '@material-ui/core/Box';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import ExpandIcon from '@material-ui/icons/ExpandLess';
import LessIcon from '@material-ui/icons/ExpandMore';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Checkbox from '@material-ui/core/Checkbox';

import MenuItem from '@material-ui/core/MenuItem';

import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';

import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

const styles = theme => ({
  root: {
    //position: 'relative'
    marginBottom: 100
  },
  box: {
    paddingBottom: 160
  },
  panel: {
    position: 'fixed',
    padding: theme.spacing(0, 2, 0, 2),
    minHeight: 50,
    bottom: 24,
    zIndex: 101,
    width: '100%',
    maxWidth: 'inherit',
    backgroundColor: '#fafafa'
  },
  panelHeader: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  paper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0)
  },
  list: {
    width: '100%'
    //marginTop: theme.spacing(4),
    //backgroundColor: theme.palette.background.paper
  },
  costBlock: {
    padding: theme.spacing(0, 0, 0, 2)
  },
  leading: {
    width: 54,
    minWidth: 54,
    maxWidth: 54,
    margin: theme.spacing(1)
  },
  id: {
    display: 'block',
    fontSize: 24,
    width: '100%'
  },
  label: {
    lineHeight: 2,
    fontSize: 14,
    marginLeft: theme.spacing(1)
  },
  costLabel: {
    lineHeight: 1.5,
    fontSize: 14,
    whiteSpace: 'pre-line',
    margin: theme.spacing(0.5)
  },
  info: {
    lineHeight: 1.5,
    fontSize: 14
  },
  importantInfo: {
    lineHeight: 2,
    fontSize: 18
  },
  cell: {
    backgroundColor: 'white',
    paddingLeft: 0,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  subheader: {
    fontSize: 12,
    lineHeight: 2,
    paddingLeft: theme.spacing(2.5),
    color: '#4A90E2',
    backgroundColor: '#ebf3fd'
  },
  textBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  progress: {
  },
  state_new: {
    fontSize: 11,
    backgroundColor: 'red',
    color: 'white',
    borderRadius: 30,
    marginBottom: 3
  },
  state_wait: {
    fontSize: 11,
    backgroundColor: 'green',
    color: 'white',
    borderRadius: 30,
    marginBottom: 3
  },
  state_read: {
    fontSize: 11,
    backgroundColor: 'orange',
    color: 'white',
    borderRadius: 30,
    marginBottom: 3
  }
});


class Tender extends Component {
  id = -1;
  deal = null;
  bidder = null;
  biddable = {};
  bids = null;
  keys = ['subtotal', 'total'];
  //keys = ['subtotal', 'shipping', 'install', 'miscellaneous', 'service', 'tax', 'total'];
  desc = ['miscellaneous', 'service', 'tax'];

  constructor(props) {
    super(props);

    this.state = {
      show: false,
      expanded: false,
      requesting: true,

      selected: -1,
      bidding: -1
    };

    let id = props.match.params.id;
    if (id) this.id = parseInt(id);
    if (this.id > 0) {
      this.show();
      //this.list();
    }
  }

  sorter = (l, r) => {
    const lc = this.biddable[l.id] ? this.biddable[l.id].length : 0;
    const rc = this.biddable[r.id] ? this.biddable[r.id].length : 0;
    return lc > rc ? -1 : 1;
  };

  show = () => {
    let self = this;
    DataManager.getInstance().bidder_deal(this.id, null).then(function (res) {
      if (res.c === 0) {
        self.deal = res.d.d;
        self.deal.items.sort((l, r) => (l.category >= r.category) ? -1 : 1);
        self.biddable = res.d.bids;
        self.bidder = res.d.bidder;
        self.bidder.sort(self.sorter);
        self.setState({show: true, bidding: self.bidder && self.bidder.length > 0 ? 0 : -1, requesting: false});
      }
    });
  };

  expand = (e) => {
    this.setState({expanded: !this.state.expanded});
  };

  selected = (id, index) => {
    this.setState({selected: index});
  };

  deselect = () => {
    this.setState({selected: -1});
  };

  item_checked = (e) => {
    const bidder = this.state.bidding >= 0 ? this.bidder[this.state.bidding] : null;
    if (!this.biddable[bidder.id]) this.biddable[bidder.id] = [];
    const bids = this.biddable[bidder.id];

    const name = e.target.name;
    //const checked = e.target.checked;
    //const tag = e.currentTarget.dataset.tag;
    if (name === 'check_all') {
      const items = this.deal.items;
      const ids = [];
      if (bids.length !== items.length) {
        let i;
        for (i = 0; i < items.length; ++i) {
          ids.push(items[i].item_id);
        }
      }
      this.biddable[bidder.id] = ids;
    }else{
      const item_id = parseInt(name);
      const index = bids.indexOf(item_id);
      if (index < 0) bids.push(item_id);
      else bids.splice(index, 1);
    }
    //this.bidder.sort(this.sorter);
    this.setState({show: true, requesting: false});
  };

  item_cell(item, index, displayIndex, divider) {
    const { classes } = this.props;
    const bidder = this.state.bidding >= 0 ? this.bidder[this.state.bidding] : null;
    const bids = this.biddable[bidder.id] ? this.biddable[bidder.id] : [];
    const checked = bids.indexOf(item.item_id) >= 0;

    const badges = [];
    if (item.currency !== 'CNY') {
      badges.push(
        <Typography key={`bc_${displayIndex}`} component="p" align="center" className={classes.state_read}>
          {T.translate('inquiries.t3')}
        </Typography>
      );
    }
    if (item.quantity <= 0) {
      badges.push(
        <Typography key={`bq_${displayIndex}`} component="p" align="center" className={classes.state_new}>
          {T.translate('orders.recommended')}
        </Typography>
      );
    }

    const self = this;
    return (
      <ListItem className={classes.cell} key={item.id} divider={divider} alignItems="center" >
        <ListItemAvatar className={classes.leading}>
          <React.Fragment>
            <Typography component="p" align="center" className={classes.id}>{displayIndex < 10 ? '0' : ''}{displayIndex}</Typography>
            {badges}
          </React.Fragment>
        </ListItemAvatar>
        <Image
          style={{ height: 130, width: 130, maxWidth: 130, maxHeight: 130, minWidth: 130, minHeight: 130 }}
          alt={item.media[0]}
          src={item.media[0]}
          onClick={() => self.selected(item.id, index)}
        />
        <ListItemText
          primary={
            <Box className={classes.titleBlock}>
              <Box>
                <Typography component="span" className={classes.label}>{T.translate('orders.type')}: {item.item_type} / {item.item_space}</Typography>
              </Box>
              <Box>
                <Typography component="span" className={classes.label}>{T.translate('orders.material')}: {item.material}</Typography>
              </Box>
              <Box>
                <Typography component="span" className={classes.label}>{T.translate('orders.size')}: {item.size}</Typography>
              </Box>
              <Box style={{alignItems: 'center'}}>
                <Typography component="span" className={classes.label}>{T.translate('orders.price1')}: </Typography>
                <Typography component="span" className={classes.importantInfo}>{priceDisplay(item.price1, item.currency)}</Typography>
                <Typography component="span" className={classes.label}>x {item.quantity}{T.translate('orders.unit')}</Typography>
              </Box>
            </Box>
            }
        />
        <ListItemSecondaryAction>
          <Checkbox
            checked={checked}
            name={`${item.item_id}`}
            value={item.item_id}

            onChange={this.item_checked}
            color="primary"
            inputProps={{ 'aria-label': 'item checkbox', 'data-tag': 0 }}
          />
        </ListItemSecondaryAction>
      </ListItem>
    )
  }

  render() {
    if (!this.deal || !this.bidder) return <React.Fragment/>;

    const { classes } = this.props;
    const deal = this.deal;

    const self = this;
    return (
      <Grid alignItems="center" justify="center" container>
        <ProductDialog open={this.state.selected >= 0} onClose={this.deselect} product={this.state.selected >= 0 ? this.deal.items[this.state.selected] : undefined} />
        <Grid item xs={12} sm={8} md={8} lg={6} xl={6} className={classes.root}>
          <React.Fragment>
            <Box style={{paddingBottom: this.state.expanded ? 130 : 0}}>
              <List className={classes.list}>
                <ListSubheader>{deal.items.length} {T.translate('deals.unit')}, {T.translate('orders.total_items', {c: deal.quantity})}</ListSubheader>
                {deal.items.map((item, index) => {
                  return <React.Fragment key={index}>
                    {index === 0 || item.category !== deal.items[index - 1].category ?
                      <ListSubheader className={classes.subheader}>{item.category}</ListSubheader>
                      :
                      <Divider component="li" />
                    }
                    {self.item_cell(item, index, deal.items.length - index, false)}
                  </React.Fragment>;
                })}
                <ListSubheader className={classes.subheader}>{T.translate('orders.costs')}</ListSubheader>
                <ListItem className={classes.cell} alignItems="center" >
                  <ListItemText
                    primary={
                      <Box className={classes.costBlock}>
                      {this.keys.map((key, index) => (
                        <Box key={key} className={classes.textBox}>
                          <Typography component="span" className={classes.costLabel}>{T.translate(`orders.${key}`)}
                          {this.desc.indexOf(key) >= 0 &&
                          <InfoButton text={T.translate(`orders.${key}_desc`, {s: key === 'service' ? DataManager.getInstance().storage.config.sv : `${deal.tax_rate}%`})} />
                          }
                          </Typography>
                          <Typography component="span" className={key === 'subtotal' || key === 'total' ? classes.importantInfo : classes.info}>{priceDisplay(deal[key], deal.currency)}</Typography>
                        </Box>
                      ))}
                      </Box>
                    }
                  />
                </ListItem>
                <ListSubheader className={classes.subheader}>{T.translate('orders.memo')}</ListSubheader>
                <ListItem className={classes.cell} alignItems="center" >
                  <ListItemText
                    primary={
                      <Box className={classes.costBlock}>
                        <Typography className={classes.costLabel}>{deal.memo}</Typography>
                      </Box>
                    }
                  />
                </ListItem>
              </List>
            </Box>
            {this.panel()}
          </React.Fragment>
        </Grid>
      </Grid>
    )
  }

  panel() {
    const deal = this.deal;
    const { classes } = this.props;
    const bidder = this.state.bidding >= 0 ? this.bidder[this.state.bidding] : null;
    const bids = this.biddable[bidder.id] ? this.biddable[bidder.id] : [];
    const self = this;

    return (
      <ExpansionPanel className={classes.panel} expanded={this.state.expanded} onChange={this.expand}>
        <ExpansionPanelSummary
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          style={{padding: 0, margin: 0}}
        >
          <Box className={classes.panelHeader}>
            {this.state.expanded ? <LessIcon /> : <ExpandIcon />}
            <Box
             onClick={event => event.stopPropagation()}
             onFocus={event => event.stopPropagation()}
            >
              {bidder &&
              <Select
                value={bidder.id}
                onChange={this.onBidderChange}
                variant="standard"
              >
                {this.bidder.map((unit, index) => (
                  <MenuItem key={unit.id} value={unit.id} data-tag={index}>
                    <Typography align="left">{unit.display}, {this.biddable[unit.id] ? this.biddable[unit.id].length : 0} 件</Typography>
                  </MenuItem>
                ))}
              </Select>
              }
            </Box>
            <Box
              onClick={event => event.stopPropagation()}
              onFocus={event => event.stopPropagation()}
            >
              <Checkbox
                checked={bids.length > 0}
                indeterminate={bids.length > 0 && bids.length !== deal.items.length}
                name="check_all"
                onChange={this.item_checked}
                color="primary"
                inputProps={{ 'aria-label': 'bidder checkbox', 'data-tag': 1 }}
              />
            </Box>

          </Box>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{padding: 0, margin: 0}}>
          <div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'left', justifyContent: 'center'}}>
          {this.bidder.map((unit, index) => {
            const bids = this.biddable[unit.id];
            if (!bids || bids.length === 0) return null;
            return (
              <Box style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}} key={unit.id}>
                <Typography component="span" className={classes.info}>{unit.display}</Typography>
                <Box>
                  <Typography component="span" className={classes.info}>{bids.length} 件</Typography>
                  <Checkbox
                    checked={bids.length > 0}
                    indeterminate={bids.length > 0 && bids.length !== deal.items.length}
                    disabled
                    //onChange={this.item_checked}
                    color="primary"
                    inputProps={{ 'aria-label': 'bidder checkbox', 'data-tag': 1 }}
                  />
                </Box>
              </Box>
            )
            }
          )}
          </div>
        </ExpansionPanelDetails>
        <Divider />
        <ExpansionPanelActions style={{width: '100%', display: 'flex', flexDirection: 'center', alignItems: 'left', justifyContent: 'space-between'}}>
          <PubDialog value={deal.req} onSave={this.save} disabled={this.state.requesting} />
          <Button variant="outlined" data-tag={0} color="primary" size="small" disabled={this.state.requesting} onClick={(e) => {self.save('0', null);}}>
            {T.translate('buttons.save')}
          </Button>
        </ExpansionPanelActions>
      </ExpansionPanel>
    )
  }

  save = (mode, req) => {
    const self = this;
    self.setState({requesting: true});
    const params = {bids: this.biddable, pub: mode, req: req};

    DataManager.getInstance().bidder_deal(this.deal.id, params).then(function (res) {
      if (res.c !== 0) {
        self.setState({requesting: false});
        const m = res.c === 404 ? `有${res.d.c}个产品没有指定商品信息` : T.translate('errors.500');
        DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'error', m: m});
        return;
      }
      if (params.pub === '1')
        DataManager.getInstance().pub(Event.REDIRECT, '/quotes/');
      else
        self.setState({requesting: false});
    });
  };

  onBidderChange = (e) => {
    //const unit_id = parseInt(e.target.value);
    const index = parseInt(e.currentTarget.dataset.tag);
    this.setState({bidding: index});
  };

  //list = () => {
  //  const self = this;
  //  return DataManager.getInstance().list_bidder({f: ''}).then(function (res) {
  //    if (res.c === 0) {
  //      self.bidder = res.d.bidder;
  //      if (self.biddable)
  //        self.bidder.sort(self.sorter);
  //    }
  //    self.setState({bidding: self.bidder && self.bidder.length > 0 ? 0 : -1, requesting: false});
  //  })
  //};
}


function PubDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(props.value);

  function handleClickOpen(e) {
    setOpen(true);
  }

  function handleClose(e) {
    setOpen(false);
    if (e.currentTarget.dataset.tag === '1') {
      props.onSave('1', value);
    }else{
      setValue(props.value);
    }
  }

  const handleChange = e => {
    setValue(e.target.value);
  };

  return (
    <div style={{display: 'inline-block'}} onClick={(e) => e.stopPropagation()}>
      <Button variant="outlined" data-tag={1} color="primary" size="small" disabled={props.disabled} onClick={handleClickOpen}>
        {T.translate('buttons.publish')}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth='xs'
        fullWidth
      >
        <DialogTitle id="form-dialog-title">{T.translate('inquiries.req')}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            multiline={true}
            inputProps={{
              maxLength: 256
            }}
            //defaultValue={props.value}
            value={value}
            onChange={handleChange}
            label={T.translate('inquiries.req')}
            type="text"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button data-tag={0} onClick={handleClose}>
            {T.translate('buttons.cancel')}
          </Button>
          <Button data-tag={1} onClick={handleClose} color="primary">
            {T.translate('buttons.publish')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


export default withRouter(withStyles(styles)(Tender));