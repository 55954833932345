/**
 * Created by milan on 2019/7/18.
 */
import React, { Component } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import T from 'i18n-react';
import { Mode, DataManager } from '../models';

import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Box';
import { RestrictedCell, EditableCell, CustomizerCell } from './Cell';
import Pagination from '../common/Pagination';
import { M_KEYS, M_ITEMS, M_SEARCHABLE, M_VISIBLE } from '../common/Constants';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import List from '@material-ui/core/List';

import IconButton from '@material-ui/core/IconButton';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import ShowIcon from '@material-ui/icons/Slideshow';
import EditIcon from '@material-ui/icons/Edit';


const styles = theme => ({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
    marginTop: theme.spacing(2),
    padding: theme.spacing(1)
  },
  paper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  btns: {
    marginBottom: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  list: {
    width: '100%'
  },
  content: {
    fontSize: 24,
    margin: theme.spacing(2)
  },
  pagination: {
    marginTop: theme.spacing(5)
  }
});


class Picker extends Component {
  state = {
    params: {p: 1}, header: {keys: null, values: null}, 
    total: 1, page: 1, per: 0, screener: {}, filters: {}, ctm: -1, savable: true,
    chosen: [], requesting: true, friendly: true
  };
  materials = null;
  titles = [];

  constructor(props) {
    super(props);
    if (props.materials) this.state.chosen = props.materials;
    if (props.titles) this.titles = props.titles;
    if (props.screener) {
      this.state.screener = props.screener;
    }
    if (props.filters) this.state.params = {...this.state.params, ...props.filters};
    else if (props.product && props.product.ctmz && props.product.ctmz.length > 0) {
      if (props.product.ctmz[0].v)
        this.state.filters = props.product.ctmz[0].v;
      if (props.product.ctmz[0].s)
        this.state.screener = props.product.ctmz[0].s;
      this.state.params = {...this.state.params, ...this.state.filters};
      this.state.ctm = 0;
      this.state.chosen = [];
      this.titles = [];
      for (var i = 0; i < props.product.ctmz.length; ++i) {
        this.state.chosen.push(i < props.materials.length ? props.materials[i] : undefined);
        this.titles.push(i < props.titles.length ? props.titles[i] : undefined);
        if (!this.state.chosen[i]) this.state.savable = false;
      }
    }
    this.list(this.state.params);
  }

  handlePagination = (offset) => {
    const page = 1 + offset / this.state.per;
    const params = {...this.state.params};
    params.f = this.state.friendly ? 1 : 0;
    params.p = page;
    this.setState({params: params});
    this.list(params);
  };

  handleFilter = (e) => {
    const filters = this.state.filters;
    const params = {p: 1, f: this.state.friendly ? 1 : 0};
    let k;
    for (k in filters) {
      if (filters.hasOwnProperty(k) && filters[k]) {
        params[k] = filters[k];
      }
    }
    this.setState({params: params});
    this.list(params);
  }

  handleCustomizer = (e) => {
    const tag = parseInt(e.currentTarget.dataset.tag);
    const filters = this.props.product.ctmz[tag].v || {};
    const screener = this.props.product.ctmz[tag].s || {};
    const params = {p: 1, f: this.state.friendly ? 1 : 0};
    let k;
    for (k in filters) {
      if (filters.hasOwnProperty(k) && filters[k]) {
        params[k] = filters[k];
      }
    }
    this.setState({params: params, filters: filters, screener: screener, ctm: tag}, () => this.list(params));
  }

  handleConfig = (e) => {
    this.setState({friendly: !!e.target.checked});
  }

  onChange = (material, item) => {
    const chosen = [...this.state.chosen];
    const id = `${material.identifier}${item ? item.identifier : ''}`;
    const i = chosen.indexOf(id);
    let savable = true;
    if (this.state.ctm >= 0) {
      if (chosen[this.state.ctm] === id) {
        chosen[this.state.ctm] = undefined;
        this.titles[this.state.ctm] = undefined;
      }else{
        chosen[this.state.ctm] = id;
        this.titles[this.state.ctm] = item ? `${material.title}-${item.cn || item.identifier}` : material.title;
      }
      for (var j = 0; j < chosen.length; ++j) {
        if (!chosen[j]) savable = false;
      }
    }else if (i >= 0) {
      chosen.splice(i, 1);
      this.titles.splice(i, 1);
    }else{
      chosen.push(id);
      this.titles.push(item ? `${material.title}-${item.cn || item.identifier}` : material.title);
    }
    this.setState({chosen: chosen, savable: savable});
    if (this.props.onChange)
      this.props.onChange(chosen, this.titles);
  }

  onSave = (e) => {
    if (this.props.onFilter) {
      const params = {...this.state.screener};
      this.props.onFilter(params);
    }
    else if (this.props.onSave)
      this.props.onSave(this.state.chosen, this.titles);
  }

  render() {
    if (!this.materials) return <div></div>;
    const { classes, dialog, open, onClose } = this.props;
    const ctms = this.props.product && this.props.product.ctmz ? this.props.product.ctmz : [];

    return (
      <React.Fragment>
        {!dialog ?
        <div className={classes.root}>
          {this.table()}
        </div>
        :
        <Dialog open={open} onClose={onClose} fullWidth maxWidth='xl' aria-labelledby="dialog-title">
          <DialogTitle>
            <Box className={classes.btns}>
              {ctms.map((ctm, index) => {
                return <Button key={index} data-tag={index} style={{margin: 3}}
                variant={this.state.ctm === index ? "contained" : "outlined"} 
                color="primary" size="small" onClick={this.handleCustomizer}
                >
                  {ctm.k}{this.state.chosen[index] ? `: ${this.titles[index]}` : undefined}
                </Button>;
              })}
            </Box>
            <List>
              {this.props.onFilter ?
              <CustomizerCell
                screener={this.state.screener}
                onChange={this.handleFilter}
              />
                :
              <EditableCell
                material={this.state.filters}
                rules={this.state.header.values}
                keys={M_SEARCHABLE}
                onChange={this.handleFilter}
              />
            }
            </List>
          </DialogTitle>
          <DialogContent>
            {this.table()}
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color="secondary">
              {T.translate('buttons.cancel')}
            </Button>
            <Button onClick={this.onSave} color="primary" disabled={!this.state.savable}>
              {T.translate('buttons.save')}
            </Button>
          </DialogActions>
        </Dialog>
        }
      </React.Fragment>
    );
  }

  table = () => {
    const { classes } = this.props;
    const materials = this.materials;

    return (
      <Box>
        <Paper className={classes.paper}>
          {this.state.requesting && materials.length === 0 ?
            <CircularProgress size={48} />
            :
            <GridList cols={4} cellHeight={250} spacing={12} className={classes.list}>
              {materials.length === 0 &&
                <Typography style={{width: '100%', textAlign: 'center'}} className={classes.content}>{T.translate('materials.empty_list')}</Typography>
              }
              {materials.map((material, index) => {
                return <GridListTile component="div" key={material.identifier} cols={1} style={{cursor: 'pointer'}}>
                  <RestrictedCell
                    key={material.identifier || material.id || `mat-${index}`}
                    material={material}
                    cols={1}
                    selected={this.state.chosen}
                    onChange={this.props.onFilter ? undefined : this.onChange}
                  />
                </GridListTile>
              })}
            </GridList>
          }
        </Paper>
        {!this.state.requesting && this.state.per > 0 && this.state.total > this.state.per &&
        <Pagination
          offset={this.state.per * (this.state.params.p - 1)}
          limit={this.state.per}
          total={this.state.total}
          onClick={this.handlePagination}
        />
        }
      </Box>
    );
  };

  list = (query) => {
    const self = this;
    if (!this.state.requesting) this.setState({requesting: true});
    const params = {...query, l: !!this.labels ? 0 : 1};
    params.sc = JSON.stringify(this.state.screener);
    return DataManager.getInstance().list_materials(params).then(function (res) {
      if (res.c === 0) {
        if (res.d.labels) DataManager.getInstance().storage.materials = res.d.labels;

        let d = res.d;
        const mats = d.mats;
        for (var i = 0; i < mats.length; ++i) {
          const mat = mats[i];
          if (!mat.items)
            mat.items = [];
        }
        self.materials = mats;
        const update = {params: query, total: d.t, per: d.p, requesting: false};
        if (!self.state.header.keys) {
          const filters = {};
          const values = {};
          let i;
          for (i = 0; i < M_VISIBLE.length; ++i) {
            const k = M_VISIBLE[i];
            values[k] = T.translate(`materials.${k}`);
            filters[k] = params[k] || '';
          }
          for (i = 0; i < M_ITEMS.length; ++i) {
            const k = M_ITEMS[i];
            values[k] = T.translate(`materials.${k}`);
            filters[k] = params[k] || '';
          }
          values.media = ['Images'];
          // keys.sort();
          update.header = {keys: M_KEYS, values: values};
          update.filters = filters;
        }
        // const total = d.t;
        // const max_page = Math.ceil(total / d.p);
        // if (p > max_page) p = max_page;
        // if (p < 1) p = 1;
        self.setState(update);
      }
    })
  };
}


const MaterialPicker = withStyles(styles)(Picker);


function MaterialsDisplay(props) {
  const { item, onChange } = props;
  // const materials = [];
  // if (item.material1) {
  //   item.material1.split(',').forEach(function (id, _) {
  //     materials.push(id);
  //   });
  // }
  const materials = item.material1 ? item.material1.split(',') : [];
  const titles = item.material2 ? item.material2.split(', ') : [];
  
  const [open, setOpen] = React.useState(false);
  // const [value, setValue] = React.useState(0);

  function handleClickOpen(e) {
    // const tag = parseInt(e.currentTarget.dataset.tag);
    // setValue(tag);
    setOpen(true);
  }

  function handleClose(e) {
    setOpen(false);
  }

  function handleSave(mats, tls) {
    item.material1 = mats.join(',');
    item.material2 = tls.join(', ');
    onChange(item.material2);
    setOpen(false);
  }

  return (
    <div onClick={(e) => e.stopPropagation()} style={{display: 'inline-block'}}>
      <Typography component="span" variant="subtitle1">{item.material2}</Typography>
      {false && materials.length > 0 &&
      <IconButton color="primary" align="center" size="small" data-tag={1} onClick={handleClickOpen}>
        <ShowIcon style={{width: 20, height: 20}} />
      </IconButton>
      }
      {DataManager.getInstance().mode >= Mode.ADMIN &&
      <IconButton color="primary" align="center" size="small" data-tag={0} onClick={handleClickOpen}>
        <EditIcon style={{width: 20, height: 20}} />
      </IconButton>
      }
      
      {open &&
      <MaterialPicker
        key={item.material1}
        product={props.product}
        materials={materials}
        titles={titles}
        dialog={true}
        open={open}
        onClose={handleClose}
        onSave={handleSave}
      />
      }
    </div>
  );
}

const Materials = withStyles(styles)(MaterialsDisplay);

export { Materials, MaterialPicker };