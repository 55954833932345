import React,  { Component } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';

import { Event, DataManager } from '../models/';
import { priceDisplay } from '../common/Constants';
import Image from '../common/Image';
import ProductDialog from '../Product/ProductDialog';

import Shipping from './Shipping';
import T from 'i18n-react';
import AlertDialog from '../dialogs/AlertDialog';
import Dialog from '@material-ui/core/Dialog';

import CircularProgress from '@material-ui/core/CircularProgress';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import ListSubheader from '@material-ui/core/ListSubheader';
import InfoButton from '../common/InfoButton';


const styles = theme => ({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
    marginTop: theme.spacing(2),
    padding: theme.spacing(1, 0, 1, 0),
    marginBottom: 50
  },
  paper: {
    //width: '100%',
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  list: {
    width: '100%',
    padding: theme.spacing(0, 0, 1, 0)
  },
  content: {
    fontSize: 24,
    margin: theme.spacing(2)
  },
  pagination: {
    marginTop: theme.spacing(5)
  },

  titleBlock: {
  },
  buttons: {
    padding: theme.spacing(2, 0, 2, 0),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'right'
  },
  leading: {
    width: 54,
    minWidth: 54,
    maxWidth: 54,
    margin: theme.spacing(1)
  },
  id: {
    display: 'block',
    fontSize: 24,
    width: '100%'
  },
  label: {
    lineHeight: 2,
    fontSize: 14,
    marginLeft: theme.spacing(1)
  },
  importantInfo: {
    lineHeight: 2,
    fontSize: 18
  },
  cell: {
    backgroundColor: 'white',
    padding: theme.spacing(0, 1, 0, 1)
  },

  distributor: {
    width: 150,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'right',
    justifyContent: 'center'
  },
  slider: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'right'
  },
  sliderContent: {
    height: 32,
    textAlign: 'center',
    margin: theme.spacing(0, 1, 0.5, 1)
  },
  panel: {
    position: 'fixed',
    height: 64,
    bottom: 0,
    zIndex: 101,
    width: '100%',
    maxWidth: 'inherit',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  checkout: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  textBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  costLabel: {
    lineHeight: 1.5,
    fontSize: 14,
    whiteSpace: 'pre-line',
    margin: theme.spacing(0.5)
  },
  info: {
    lineHeight: 1.5,
    fontSize: 14
  },
  subheader: {
    fontSize: 12,
    lineHeight: 2,
    paddingLeft: theme.spacing(2.5),
    color: '#4A90E2',
    backgroundColor: '#ebf3fd'
  }
});


class Checkout extends Component {
  deal_id = -1;
  state = {cc: 0, selected: -1, filter: 0, shipping: '', show: false, agreement: false, requesting: false};
  products = null;
  keys = ['subtotal', 'shipping', 'install', 'miscellaneous', 'service', 'total'];
  desc = ['miscellaneous', 'service', 'tax'];

  constructor(props) {
    super(props);
    const search = props.location.search.substring(1);
    if (search) {
      const deal_id = parseInt(search);
      if (deal_id > 0) this.deal_id = deal_id;
    }
    this.state.requesting = true;
    //this.state.shipping = {name: '123', phone: '18601224301', 'address': 123};
    if (this.deal_id > 0) this.show_deal();
    else this.list();
  }

  selected = (id, index) => {
    this.setState({selected: index});
    //DataManager.getInstance().pub(Event.REDIRECT, `/products/i/${id}`);
  };

  deselect = () => {
    this.setState({selected: -1});
  };

  show_shipping = (e) => {
    this.setState({show: true});
  };

  close_shipping = () => {
    this.setState({show: false});
  };

  close_agreement = (e) => {
    this.setState({agreement: false});
    const agreed = e.currentTarget.dataset.tag;
    if (agreed === '1') {
      const prom = function (res) {
        if (res.c !== 0) {
          DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'error', m: T.translate('notification.failed')});
          return;
        }
        DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'success', m: T.translate('notification.success')});
        DataManager.getInstance().pub(Event.REDIRECT, `/orders/s/${res.d.id}`);
      };
      if (this.deal_id > 0)
        return DataManager.getInstance().deal_to_order(this.deal_id, {address: this.state.shipping}).then(prom);
      else
        return DataManager.getInstance().cart_to_order({address: this.state.shipping}).then(prom);
    }
  };

  on_shipping = (shipping) => {
    this.close_shipping();
    if (shipping)
      this.setState({shipping: shipping});
  };

  on_publish = (e) => {
    if (!this.state.shipping) {
      this.show_shipping();
      return;
    }
    this.setState({agreement: true});
  };

  render() {
    if (!this.products)
      return <div></div>;

    const { classes } = this.props;

    return (
      <React.Fragment>
        <ProductDialog open={this.state.selected >= 0} onClose={this.deselect} product={this.state.selected >= 0 ? this.products[this.state.selected] : undefined} />
        <div className={classes.root}>
          {this.table()}
        </div>

        <Dialog
          PaperProps={{style: {backgroundColor: '#fafafa'}}}
          open={this.state.show}
          onClose={this.close_shipping}
          scroll="paper"
        >
          <Shipping shipping={this.state.shipping} onSave={this.on_shipping} />
        </Dialog>

        <AlertDialog
          open={this.state.agreement}
          onClose={this.close_agreement}
          title={T.translate('orders.publish')}
          desc={T.translate('dialogs.agree', T.translate('appName'))}
          left={T.translate('buttons.cancel')}
          right={T.translate('buttons.yes')}
        />
      </React.Fragment>
    );
  }

  table = () => {
    const { classes } = this.props;
    const products = this.products;
    const order = this.state.order;
    const self = this;
    return (
      <Grid alignItems="center" justify="center" container>
        <Grid item xs={12} sm={8} md={8} lg={6} xl={6}>
          <React.Fragment>
            <Box >
              <Typography component="p" className={classes.importantInfo}>{T.translate('orders.shipping_info.info')}
                <IconButton onClick={this.show_shipping} >
                  {this.state.shipping ?
                    <EditIcon color="primary" fontSize="small" />
                    :
                    <AddIcon color="primary" fontSize="small" />
                  }
                </IconButton>
              </Typography>
              {this.state.shipping &&
              <React.Fragment>
                <Typography component="p" className={classes.label}>{this.state.shipping}</Typography>
              </React.Fragment>
              }
              <Box width={16}/>
            </Box>
            <Box style={{paddingBottom: this.state.expanded ? 130 : 0}}>
              <Paper className={classes.paper}>
                {this.state.requesting ?
                  <CircularProgress size={48} />
                  :
                  (products.length === 0 ?
                      <Typography className={classes.content}>{T.translate('products.empty_list')}</Typography>
                      :
                      <List className={classes.list}>
                        <ListSubheader className={classes.subheader}>{T.translate('orders.products')} ({T.translate('orders.total_items', {c: this.state.cc})})</ListSubheader>
                        {products.map((product, index) => (
                          self.item_cell(product, index, index, index !== products.length - 1)
                        ))}
                        <ListSubheader className={classes.subheader}>{T.translate('orders.costs')}</ListSubheader>
                        <ListItem className={classes.cell} alignItems="center" >
                          <ListItemText
                            primary={
                            <Box className={classes.costBlock}>
                            {this.keys.map((key, index) => (
                              <Box key={key} className={classes.textBox}>
                                <Typography component="span" className={classes.costLabel}>{T.translate(`orders.${key}`)}
                                {this.desc.indexOf(key) >= 0 &&
                                <InfoButton text={T.translate(`orders.${key}_desc`, {s: key === 'service' ? DataManager.getInstance().storage.config.sv : `${order.tax_rate}%`})} />
                                }
                                </Typography>
                                <Typography component="span" className={key === 'subtotal' || key === 'total' ? classes.importantInfo : classes.info}>{priceDisplay(order[key], order.currency)}</Typography>
                              </Box>
                            ))}
                            <Box className={classes.buttons}>
                              <Button variant="contained" color="primary" size="small" onClick={this.on_publish} disabled={this.state.requesting} >
                                {T.translate('orders.publish')}
                              </Button>
                            </Box>
                            </Box>
                            }
                          />
                        </ListItem>
                      </List>
                  )
                }
              </Paper>
            </Box>
          </React.Fragment>
        </Grid>
      </Grid>
    );
  };

  item_cell(item, index, displayIndex, divider) {
    const { classes } = this.props;

    const self = this;
    return (
      <ListItem className={classes.cell} key={item.id} divider={divider} alignItems="center" >
        <Image
          style={{ height: 130, width: 130, maxWidth: 130, maxHeight: 130, minWidth: 130, minHeight: 130 }}
          alt={item.media[0]}
          src={item.media[0]}
          onClick={() => self.selected(item.id, index)}
        />
        <ListItemText
          primary={
            <Box className={classes.titleBlock}>
              <Box>
                <Typography component="span" className={classes.label}>{T.translate('orders.type')}: {item.item_type} / {item.item_space}</Typography>
              </Box>
              <Box>
                <Typography component="span" className={classes.label}>{T.translate('orders.material')}: {item.material}</Typography>
              </Box>
              <Box>
                <Typography component="span" className={classes.label}>{T.translate('orders.size')}: {item.size}</Typography>
              </Box>
              <Box style={{alignItems: 'center'}}>
                <Typography component="span" className={classes.label}>{T.translate('orders.price1')}: </Typography>
                <Typography component="span" className={classes.importantInfo}>{priceDisplay(item.price1, item.currency)}</Typography>
                <Typography component="span" className={classes.label}>&nbsp;x {item.quantity}{T.translate('orders.unit')}</Typography>
              </Box>
            </Box>
            }
        />

      </ListItem>
    )
  }

  list = () => {
    const self = this;
    return DataManager.getInstance().show_cart(null).then(function (res) {
      if (res.c === 0) {
        let d = res.d;
        const products = d.ps;
        let i;
        for (i = 0; i < products.length; ++i) {
          const item = products[i];
          item.p1 = priceDisplay(item.price1, item.currency);
          item.p2 = priceDisplay(item.price2, item.currency);
        }
        self.products = products;
        self.setState({cc: res.d.cc, order: d.order, requesting: false});
      }
    })
  };

  show_deal = () => {
    let self = this;
    DataManager.getInstance().show_deal(this.deal_id, null).then(function (res) {
      if (res.c === 0) {
        const deal = res.d.d;

        const products = deal.items;
        let i;
        for (i = 0; i < products.length; ++i) {
          const item = products[i];
          item.p1 = priceDisplay(item.price1, item.currency);
          item.p2 = priceDisplay(item.price2, item.currency);
        }
        self.products = products;
        self.setState({cc: deal.quantity, order: deal, requesting: false});
      }
    });
  };
}


export default withStyles(styles)(Checkout);