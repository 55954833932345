import React from 'react';
import Typography from '@material-ui/core/Typography';

import T from 'i18n-react';
import { FlowTexts } from '../common/Constants';
import SortableChips from '../common/SortableChips';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import { Event, DataManager } from '../models/';


//const useStyles = makeStyles(theme => ({
//
//}));

export default function FlowDisplay(props) {
  let def = props.flow ? props.flow.split(',') : [];

  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(def);

  function handleClickOpen(e) {
    setOpen(true);
  }

  function handleClose(e) {
    setOpen(false);
  }

  const handleChange = (newValue) => {
    setOpen(false);
    if (props.onChange) {
      setValue(newValue);
      props.onChange(newValue.join(','));
    }else{
      saveFlow(newValue);
    }
  };

  const saveFlow = (newValue) => {
    const params = {flow: newValue.join(','), pid: props.product_id, iid: props.item_id};
    return DataManager.getInstance().save_product_flow(params).then(function (res) {
      if (res.c !== 0) {
        DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'error', m: T.translate('notification.failed')});
        return;
      }
      setValue(newValue);
      DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'success', m: T.translate('notification.success')});
    });
  };

  const str = [];
  let i;
  for (i = 0; i < value.length; ++i) {
    str.push(T.translate(`products.${FlowTexts[value[i]]}`));
  }

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Typography component="span" variant="body2">
        {str.join(', ')}
      </Typography>
      <IconButton color="primary" align="center" size="small" onClick={handleClickOpen}>
        <EditIcon style={{width: 20, height: 20}} />
      </IconButton>

      <SortableChips
        dialog={true}
        labels={value}
        open={open}
        onClose={handleClose}
        onSave={handleChange}
      />

    </div>
  );
}
