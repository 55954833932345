/**
 * Created by milan on 2019/7/18.
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/styles/withStyles';
import { Link } from 'react-router-dom';
import T from 'i18n-react';
import { Mode, DataManager } from '../models';

import Users from '../UnitUsers/Users';
import Stats from './Stats';
import Plans from './PlanEdit';

import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import StarIcon from '@material-ui/icons/Star';
import StarHalfIcon from '@material-ui/icons/StarHalf';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import ActionIcon from '@material-ui/icons/Settings';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import ImagesDialog from '../dialogs/ImagesDialog';
import VideoDialog from '../dialogs/VideoDialog';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import PlayIcon from '@material-ui/icons/PlayCircleOutline';
import MediaRow from './MediaRow';
import InfoButton from '../common/InfoButton';


const styles = theme => ({
  root: {
  },
  paper: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(1),
    position: 'relative'
  },
  box: {
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(1)
  },
  title: {
    padding: theme.spacing(2)
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main
  },
  block: {
    width: '100%',
    maxWidth: '100%',
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  stats: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  value: {
    fontSize: 24
  }
});


class Seller extends Component {
  id = 0;
  unit = undefined;
  media_keys = ['d', 'm', 'h', 't'];
  media_titles = undefined;
  seller_titles = ['成品展示', '制作过程', '基材展示', '证照/获奖'];
  customer_titles = ['案例展示', '团队介绍', '设计理念', '证照/获奖'];
  state = {requesting: true};
  mode = 'admin';

  constructor(props) {
    super(props);
    const path = props.match.url;
    const segs = path.split('/');
    if (DataManager.getInstance().mode < Mode.ADMIN && (segs.length >= 3 && segs[2] === 's')) {
      this.mode = 'show';
    }

    if (props.unit) {
      this.unit = props.unit;
      return;
    }
    let id = props.match.params.id;
    if (id) this.id = parseInt(id);
    this.show();
  }

  show = () => {
    const self = this;
    return this.mode === 'admin' ? DataManager.getInstance().admin_unit(this.id, null).then(function (res) {
      if (res.c === 0) {
        const auth = res.d.auth;
        const unit = res.d.unit;
        unit.config.uri = unit.uri;
        unit.enabled = res.d.enabled || [];
        self.unit = unit;
        self.media_titles = unit.type === 2 ? self.seller_titles : self.customer_titles;
        self.setState({editable: false, auth: auth, desc: unit.desc, requesting: false});
      }
    })
    : 
    DataManager.getInstance().show_unit(this.id, null).then(function (res) {
      if (res.c === 0) {
        const unit = res.d.unit;
        self.unit = unit;
        self.media_titles = unit.type === 2 ? self.seller_titles : self.customer_titles;
        self.setState({requesting: false});
      }
    })
  };

  stars = (rank) => {
    if (!(rank > 0 && rank <= 5)) return T.translate('units.rank_none');
    const stars = [];
    let i;
    for (i = 1; i <= 5; ++i) {
      if (rank >= i) {
        stars.push(<StarIcon key={i} />);
      }else if (rank >= i - 0.5) {
        stars.push(<StarHalfIcon key={i} />);
      }else{
        break;
      }
    }
    return stars;
  };

  render() {
    if (!this.unit) return <React.Fragment/>;
    const self = this;
    const { classes } = this.props;
    const unit = this.unit;
    const card = this.unit.card || {c: {}};
    const logo = unit.logo ? unit.logo : undefined;
    const video = card.video && card.video.length > 0 ? card.video[0] : undefined;
    const plans = {0: {t: 'blue', c: '#6495ed'}, 100: {t: 'silver', c: '#C0C0C0'},
                  200: {t: 'gold', c: '#FFD700'}, 300: {t: 'platinum', c: '#e5e4e2'}};
    const plan = plans[unit.plan];

    return (
      <React.Fragment>
        <Container component="main" maxWidth="sm" className={classes.root}>
          <Paper className={classes.paper} color="primary">
            <div style={{margin: 8, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
              <Avatar src={logo} style={{marginRight: 16, width: 64, height: 64, backgroundColor: logo ? 'white' : '#8304C0'}} alt="logo" data-tag="logo">
                {logo ? '' : 'Logo'}
              </Avatar>
              <div style={{display: 'flex', flexDirection: 'column'}}>
                <Typography component="span" variant="h6">{unit.display}</Typography>
                <Typography component="span" variant="body2">{unit.name}</Typography>
              </div>
            </div>
            {this.mode === 'admin' &&
            <React.Fragment>
              <div style={{position: 'absolute', right: 8, top: 8}}>
                <DropdownButton unit={unit} />
              </div>
              {unit.enabled.indexOf('uri') >= 0 &&
              <React.Fragment>
                <Typography variant="h6" style={{marginTop: 24}}>
                  {T.translate('units.uri')}
                  <InfoButton title={unit.type === 2 ? undefined : T.translate('service.with_plan')} text={T.translate('service.invitation_desc', {app: T.translate('appName')})} />
                </Typography>
                <Typography>
                  {unit.uri}
                </Typography>
              </React.Fragment>
              }
            </React.Fragment>
            }
            {this.mode === 'show' && unit.type === 2 &&
            <Box className={classes.block}>
              <Box className={classes.stats}>
                <Typography component="p" variant="caption" align="center">
                  {T.translate('units.orders')}
                </Typography>
                <Typography component="p" align="center" className={classes.value}>
                  {unit.stats.orders}
                </Typography>
              </Box>
              <Box className={classes.stats}>
                <Typography component="p" variant="caption" align="center">
                  {T.translate('units.rate')}
                </Typography>
                <Typography component="p" align="center" className={classes.value}>
                  {unit.stats.rate}
                </Typography>
              </Box>
              <Box className={classes.stats}>
                <Typography component="p" variant="caption" align="center">
                  {T.translate('units.rank', {app: T.translate('appName')})}
                </Typography>
                <Typography component="p" align="center" className={classes.value}>
                  {this.stars(unit.stats.rank)}
                </Typography>
              </Box>
            </Box>
            }

            {unit.type !== 2 &&
            <React.Fragment>
              <Typography variant="h6" className={classes.title}>
                {T.translate('units.plan')}
              </Typography>
              <Box style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                {DataManager.getInstance().mode !== Mode.BUSINESS ?
                <Button
                  variant="outlined"
                  color="primary"
                  // style={{border: `1px solid ${plan.c}`, color: plan.c}}
                  component={Link}
                  to={'/corp/plans'}
                >
                  {T.translate(`units.plan_${plan.t}`)}
                </Button>
                :
                <Button
                  variant="outlined"
                  color="primary"
                >
                  {T.translate(`units.plan_${plan.t}`)}
                </Button>
                }
                {unit.plan > 0 && unit.due &&
                <Typography variant="caption" style={{margin: 3}}>
                  ({T.translate('units.plan_due')} {unit.due})
                </Typography>
                }
              </Box>
            </React.Fragment>
            }
            
            {unit.type === 2 && unit.tech &&
            <React.Fragment>
              <Typography variant="h6" className={classes.title}>
                {T.translate('units.tech')}
              </Typography>
              <Typography style={{whiteSpace: 'pre-line', textAlign: 'start'}}>
                {unit.tech}
              </Typography>
            </React.Fragment>
            }

            {unit.type === 2 &&
            <React.Fragment>
              <Typography variant="h6" className={classes.title}>
                {T.translate('units.desc')}
              </Typography>
              <Typography style={{whiteSpace: 'pre-line', textAlign: 'start'}}>
                {unit.desc || T.translate('units.no_desc')}
              </Typography>
            </React.Fragment>
            }

            <div className={classes.box}>
            {video &&
              <React.Fragment>
                <VideoDialog
                  maxWidth="xl"
                  url={this.state.video}
                  open={!!this.state.video}
                  onClose={e => { self.setState({video: undefined}); }}
                />
                <Typography variant="h6" className={classes.title}>
                  {T.translate("units.video")}
                </Typography>
                <Button
                  size="medium"
                  color="primary"
                  data-tag="video"
                  onClick={e => { self.setState({video: video}); }}
                  style={{width: 200, height: 300, border: '1px solid grey', backgroundColor: 'black'}}
                >
                  <PlayIcon style={{width: 64, height: 64, color: 'white'}} />
                </Button>
              </React.Fragment>
            }

            {this.media_keys.map((key, index) => (
              card.c[key] && card.c[key].length > 0 ?
              <React.Fragment key={key}>
                <div style={{height: 32}}></div>
                <Typography variant="h6" className={classes.title}>
                  {self.media_titles[index]}
                </Typography>
                <MediaRow images={card.c[key]} onClick={e => {self.setState({key: key});}} />
              </React.Fragment>
                :
              <Typography key={key}>{card.c[key]}</Typography>
            ))}

            {this.state.key &&
              <ImagesDialog
                maxWidth="xl"
                images={card.c[this.state.key]}
                open={!!this.state.key}
                title=" "
                onClose={e => {self.setState({key: undefined});}}
              />
            }
            </div>
          </Paper>
        </Container>
        <div style={{height: 20}}></div>
        {DataManager.getInstance().mode > Mode.BUSINESS &&
        <React.Fragment>
          {unit.type === 2 ?
           <Stats unit={unit} />
           :
           <Plans unit={unit} />
           }
          <Users id={this.id} />
        </React.Fragment>
        }
      </React.Fragment>
    );
  }
}


function DropdownButton(props) {
  const unit = props.unit;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <React.Fragment>
      <IconButton
        color="primary"
        aria-haspopup="true"
        aria-label={props.id}
        data-tag={props.id}
        onClick={handleClick}
      >
        <ActionIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {DataManager.getInstance().is_admin() &&
        <MenuItem component={Link} to={`/corp/e/${unit.id}`}>{T.translate('buttons.edit')}</MenuItem>
        }
        <MenuItem component={Link} to={'/corp/members'}>{T.translate('users.management')}</MenuItem>
        {unit.enabled.indexOf('roles') >= 0 && DataManager.getInstance().is_admin() &&
        <MenuItem component={Link} to={'/corp/roles'}>{T.translate('permissions.settings')}</MenuItem>
      }
      </Menu>
    </React.Fragment>
  );
}


export default withRouter(withStyles(styles)(Seller));