/**
 * Created by milan on 2019/7/18.
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/styles/withStyles';
import Button from '@material-ui/core/Button';
import { TextField } from 'formik-material-ui';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import { Formik, Form, Field } from 'formik';
import T from 'i18n-react';
import { Event, DataManager, Mode } from '../models/';
import Uploader from '../Inquiry/Upload';
import VideoDialog from '../dialogs/VideoDialog';
import ProgressDialog from '../dialogs/ProgressDialog';
import AlertDialog from '../dialogs/AlertDialog';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';

import IconButton from '@material-ui/core/IconButton';
import BackIcon from '@material-ui/icons/ArrowBack';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Avatar from '@material-ui/core/Avatar';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import PlayIcon from '@material-ui/icons/PlayCircleOutline';


const styles = theme => ({
  buttons: {
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  root: {
    padding: theme.spacing(1)
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2)
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    width: 100,
    height: 100,
    cursor: 'pointer'
  },
  form: {
    //paddingTop: theme.spacing(4),
    width: '100%' // Fix IE 11 issue.
  },
  formControl: {
    marginBottom: 24
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  deletion: {
    color: theme.palette.danger.main
  }
});

class Edit extends Component {
  unit = null;
  id = 0;
  timer = null;
  file_ref = undefined;
  video_ref = undefined;
  keys = ['name', 'display'];
  constraints = [{l: 3}, {l: 2}, {l: 4}];
  tag = undefined;
  card = undefined;
  media_keys = ['d', 'm', 'h', 'e', 't'];
  media_titles = undefined;
  seller_titles = ['成品展示', '制作过程', '基材展示', '软件/硬件实力', '证照/获奖'];
  customer_titles = ['案例展示', '团队介绍', '设计理念', '软件/硬件实力', '证照/获奖'];

  constructor(props) {
    super(props);
    this.state = {
      files: [],
      show: false,
      alert: 0,
      progress: -1
    };
    const id = props.match.params.id;
    if (id) this.id = parseInt(id);
    this.show();
  }

  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  go_back = (e) => {
    const search = this.props.location.search;
    if (search && search.length > 0) {
      DataManager.getInstance().pub(Event.REDIRECT, search.substring(1));
      return;
    }
    const path = '/' + this.props.match.path.split('/')[1];
    if (DataManager.getInstance().mode >= Mode.ADMIN) {
      DataManager.getInstance().pub(Event.REDIRECT, path);
    }else{
      DataManager.getInstance().pub(Event.REDIRECT, path);
    }
  };

  onProgress = (e) => {
    let p = e.loaded / e.total;
    let self = this;
    if (p < 1) {
      p *= 90;
      self.setState({progress: p});
    }else{
      self.setState({progress: 90});
      this.timer = setInterval(function () {
        let p = self.state.progress * 1.005;
        self.setState({progress: p});
      }, 1000);
    }
  };

  show = () => {
    const self = this;
    return DataManager.getInstance().admin_unit(this.id, null).then(function (res) {
      if (res.c === 0) {
        const unit = res.d.unit;
        unit.config.uri = unit.uri;
        unit.enabled = res.d.enabled || [];
        if (unit.enabled.indexOf('uri') >= 0) self.keys.push('uri');
        self.unit = unit;
        self.card = unit.card;
        if (!self.card)
          self.card = {logo: [], video: [], c: {}};
        if (self.card.logo.length === 0 && unit.logo) self.card.logo.push(unit.logo);
        self.media_titles = unit.type === 2 ? self.seller_titles : self.customer_titles;
        self.setState({desc: unit.desc, requesting: false, show: true});
      }
    })
  };

  validate = values => {
    const errors = {};
    for (var index in this.keys) {
      const key = this.keys[index];
      const cons = this.constraints[index];
      if (!values[key]) {
        errors[key] = T.translate('errors.required');
      }else if (values[key].length < cons.l) {
        errors[key] = T.translate('errors.min_length', {length: cons.l});
      }
    }
    if (!errors.uri) {
      if (!/^[a-zA-Z0-9]+$/.test(values.uri)) {
        errors.uri = T.translate('errors.alphabets_only');
      }
    }
    
    if (Object.keys(errors).length)
      return errors;
  };

  closeAlert = (e) => {
    this.setState({alert: 0});
    if (e.currentTarget.dataset.tag === '0') {
      this.update_unit(this.values, this.actions);
    }else{
      this.actions.setSubmitting(false);
    }
    this.actions = undefined;
    this.values = undefined;
  };

  submit = (values, actions) => {
    if (values.auto) {
      this.values = values;
      this.actions = actions;
      this.setState({alert: 1});
    }else{
      this.update_unit(values, actions);
    }
  }

  update_unit = (values, actions) => {
    const params = {...values};
    params.auto = params.auto ? 1 : 0;
    values.card = this.card;

    this.setState({requesting: true});
    const self = this;
    DataManager.getInstance().update_unit(this.id, values).then(function (res) {
      if (self.timer) {
        clearInterval(self.timer);
      }
      let errors = {};
      if (res.c !== 0) {
        if (res.d && res.d.occupied) {
          for (var index in self.keys) {
            const key = self.keys[index];
            if (res.d.occupied.indexOf(key) >= 0) {
              errors[key] = T.translate('errors.occupied');
            }
          }
        }
        if (res.c === 30) {
          DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'error', m: T.translate('errors.units.owner_required')});
        }else if (res.c === 100) {
          DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'error', m: T.translate('errors.units.unauthorized')});
        }
        actions.setSubmitting(false);
        self.setState({progress: -1});
        if (Object.keys(errors).length) {
          actions.setErrors(errors);
          //actions.setStatus(errors);
        }
        
        return;
      }
      self.go_back();
    })
  };

  upload_now = (files, path) => {
    const self = this;
    const tag = this.tag;
    this.setState({pgs: true, progress: 0});

    const params = {};
    params.files = files;
    if (path) params.path = path;
    return DataManager.getInstance().upload_media(params, undefined, this.onProgress).then(function (res) {
      if (self.timer) {
        clearInterval(self.timer);
      }
      if (res.c !== 0) {
        DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'error', m: T.translate('notification.failed')});
        self.setState({pgs: false, progress: -1});
        return;
      }
      if (tag === 'logo' || tag === 'video')
        self.card[tag] = [res.d[0]];
      else
        self.card.c[tag] = self.card.c[tag].concat(res.d);
      self.setState({pgs: false, progress: -1});
      //DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'success', m: T.translate('notification.success')});
    });
  };

  file_selected = (files) => {
    const path = `units/${this.id}`;
    this.upload_now(files, path);
  };

  triggerAdd = (e) => {
    this.tag = e.currentTarget.dataset.tag;
    if (this.tag !== 'logo' && this.tag !== 'video' && !this.card.c[this.tag])
      this.card.c[this.tag] = [];
    if (this.tag === 'video') {
      this.video_ref.focus();
      this.video_ref.click();
    }else{
      this.file_ref.focus();
      this.file_ref.click();
    }
  };

  triggerDelete = (f, tag) => {
    const files = this.card.c[tag];
    const index = files.indexOf(f);
    if (index >= 0) {
      files.splice(index, 1);
      this.setState({show: true});
    }
  };

  render() {
    if (!this.state.show) return <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      style={{ minHeight: '100vh' }}
    >
      <Grid item xs={3}>
        <CircularProgress />
      </Grid>
    </Grid>;
    const { classes } = this.props;
    const self = this;
    const initialValues = {};
    if (this.unit) {
      for (var key of this.keys) {
        initialValues[key] = this.unit[key] || '';
      }
      if (this.keys.indexOf('uri') >= 0)
        initialValues.auto = this.unit.config.auto === 1;
      if (this.unit.type === 2) {
        initialValues.desc = this.unit.desc || '';
        initialValues.tech = this.unit.tech || '';
      }
    }
    const video = this.card.video && this.card.video.length > 0 ? this.card.video[0] : undefined;
    const logo = this.card.logo && this.card.logo.length > 0 ? this.card.logo[0] : undefined;
    const imageSize = 200;

    return (
      <div>
        <AlertDialog
          open={this.state.alert === 1}
          onClose={this.closeAlert}
          title={T.translate('dialogs.danger')}
          desc={T.translate('dialogs.auto_accept', {app: T.translate('appName')})}
          left={T.translate('buttons.continue')}
          right={T.translate('buttons.cancel')}
        />

        {video &&
        <VideoDialog
          maxWidth="xl"
          url={this.state.video}
          open={!!this.state.video}
          onClose={e => { self.setState({video: undefined}); }}
        />
        }
        <ProgressDialog open={!!this.state.pgs} progress={this.state.progress} />
        <Container component="main" maxWidth="sm" className={classes.buttons}>
          <IconButton aria-label="close" color="inherit" onClick={this.go_back}>
            <BackIcon style={{width: 32, height: 32}} />
          </IconButton>
        </Container>
        <Container component="main" maxWidth="sm" className={classes.root}>
          <Paper className={classes.paper}>
            <Formik
              initialValues={initialValues}
              validate={this.validate}
              //validateOnChange={false}
              validateOnBlur={true}
              onSubmit={this.submit}
            >
              {props => {
                return (
                  <Form className={classes.form} noValidate>
                    {this.keys.map((key, index) => (
                      <Field
                        key={key}
                        component={TextField}
                        error={!!props.errors[key]}
                        helperText={props.errors[key]}
                        //variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        label={T.translate(`units.${key}${key === 'uri' ? '' : '_label'}`)}
                        name={key}
                        autoComplete={key}
                        className={classes.formControl}
                      />
                    ))}
                    {this.keys.indexOf('uri') >= 0 &&
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={props.values.auto}
                            onChange={e => props.setFieldValue('auto', e.target.checked)}
                          />
                        }
                        label={T.translate('units.auto')}
                        style={{marginBottom: 32}}
                      />
                    }

                    {this.unit.type === 2 &&
                    <React.Fragment>
                      <Field
                        component={TextField}
                        error={!!props.errors.tech}
                        helperText={props.errors.tech}
                        margin="normal"
                        fullWidth
                        label={T.translate('units.tech')}
                        name="tech"
                        autoComplete="tech"
                        className={classes.formControl}
                      />

                      <Field
                        component={TextField}
                        error={!!props.errors.desc}
                        helperText={props.errors.desc}
                        //variant="outlined"
                        margin="normal"
                        fullWidth
                        multiline
                        label={T.translate('units.desc')}
                        name="desc"
                        autoComplete="desc"
                        className={classes.formControl}
                      />
                    </React.Fragment>
                    }

                    <input
                      ref={fileInput => self.file_ref = fileInput}
                      style={{display: 'none'}}
                      accept="image/*"
                      type="file"
                      name="file"
                      multiple="multiple"
                      onChange={(e) => {
                        self.file_selected(e.currentTarget.files);
                      }}
                    />
                    <input
                      ref={fileInput => self.video_ref = fileInput}
                      style={{display: 'none'}}
                      accept="video/*"
                      type="file"
                      name="video"
                      onChange={(e) => {
                        self.file_selected(e.currentTarget.files);
                      }}
                    />

                    <label htmlFor="logo" style={{display: 'block', marginBottom: 32}}>
                      <FormControl
                        error={!!props.errors.logo}
                        margin="normal"
                        fullWidth
                        style={{margin: 0, paddingTop: 30}}
                      >
                        <InputLabel shrink={true}>Logo</InputLabel>
                        <Avatar src={logo} onClick={self.triggerAdd} className={classes.avatar} alt="Logo" data-tag="logo">
                          {logo ? undefined : 'Logo'}
                        </Avatar>
                        <FormHelperText>{props.errors.logo}</FormHelperText>
                      </FormControl>
                    </label>
                    {this.unit.type === 2 &&
                    <React.Fragment>
                      <label htmlFor="video" style={{display: 'block', marginBottom: 32}}>
                        <FormControl
                          error={!!props.errors.video}
                          margin="normal"
                          fullWidth
                          style={{margin: 0, paddingTop: 30}}
                        >
                          <InputLabel shrink={true}>{T.translate("units.video")}</InputLabel>
                          <div style={{width: imageSize, height: imageSize, display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}>
                            <Button
                              size="medium"
                              color="primary"
                              data-tag="video"
                              onClick={self.triggerAdd}
                              style={{marginRight: 3, width: imageSize / 2, height: imageSize / 2, border: '1px solid grey'}}
                            >
                              {video ?
                              <EditIcon style={{width: imageSize / 8, height: imageSize / 8}} />
                              :
                              <AddIcon style={{width: imageSize / 4, height: imageSize / 4}} />
                              }
                            </Button>
                            {video &&
                              <Button
                                size="medium"
                                color="primary"
                                data-tag="video"
                                onClick={e => { self.setState({video: video}); }}
                                style={{marginRight: 3, width: imageSize / 2, height: imageSize / 2, border: '1px solid grey'}}
                              >
                                <PlayIcon style={{width: imageSize / 8, height: imageSize / 8}} />
                              </Button>
                            }
                          </div>
                          <FormHelperText>{props.errors.video}</FormHelperText>
                        </FormControl>
                      </label>

                      {this.media_keys.map((key, index) => (
                        <label key={key} htmlFor="file" style={{display: 'block'}}>
                          <FormControl
                            margin="normal"
                            fullWidth
                            style={{margin: 0, paddingTop: 30}}
                          >
                            <InputLabel shrink={true}>{self.media_titles[index]}</InputLabel>
                            <Uploader tag={key} files={self.card.c[key] || []} onAdd={self.triggerAdd} onDelete={self.triggerDelete} />
                            <FormHelperText id="upload-display-text">{props.errors.file}</FormHelperText>
                          </FormControl>
                        </label>
                      ))}
                    </React.Fragment>
                    }

                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      disabled={props.isSubmitting}
                    >
                      {T.translate("buttons.save")}
                    </Button>
                    {self.state.progress >= 0 &&
                    <LinearProgress variant="determinate" value={self.state.progress} />
                    }
                  </Form>
                );
              }}
            </Formik>
          </Paper>
        </Container>
      </div>
    );
  }
}


export default withRouter(withStyles(styles)(Edit));