/**
 * Created by milan on 2019/7/18.
 */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import T from 'i18n-react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { priceDisplay } from '../common/Constants';


const useStyles = makeStyles(theme => ({
  titleBar: {
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1),
    position: 'relative'
  },
  title: {
    width: '100%',
    minHeight: 48,
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(0.5)
    }
  },
  closeButton: {
    padding: 0
  },
}));


function QuoteHistory(props) {
  const classes = useStyles(props.theme);

  const { mode, open, unit, product, history, onClose, onUse } = props;
  if (!product)
   return <React.Fragment/>;

  const key = `${product.product_id}.${product.item_id}`;
  const quotes = history[key];

  const onClick = e => {
    const index = parseInt(e.currentTarget.dataset.tag);
    onUse(index >= 0 ? quotes[index] : undefined);
  };

  const title = mode === 0 ? (unit.display + T.translate('s') + T.translate('quotation.quote_history')) : T.translate('quotation.my_quote_history');
  let subtitle = product.title;
  if (product.quality) subtitle += ', ' + product.quality;
  if (product.size) subtitle += ', ' + product.size;
  if (product.material) subtitle += ', ' + product.material;

  return (
    <React.Fragment>
      {open ? 
      <Dialog open={open} onClose={onClose} fullWidth>
        <div className={classes.titleBar}>
          <Box width={56} />
          <Box className={classes.title}>
            <Typography align="center" variant="h5">{title}</Typography>
            <Typography align="center" component="p" variant="subtitle1">{subtitle}</Typography>
          </Box>
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon style={{width: 48, height: 48}} />
          </IconButton>
        </div>
        <DialogContent>
          {quotes.length === 0 ?
            <Typography align="center" component="p" className={classes.title}>{T.translate('quotation.unquoted')}</Typography>
            :
            <List className={classes.list}>
              {mode !== 0 &&
              <div className={classes.titleBar}>
                <Button data-tag={-1} variant="outlined" color="primary" size="small" onClick={onClick} >{T.translate('quotation.new_quote')}</Button>
              </div>
              }
              <ListItem divider={true} />
              {quotes.map((quote, index) => (
                <ListItem key={`${quote.id}.${index}`} alignItems="center" divider={true} >
                  <ListItemText
                    component="div"
                    primary={
                      <div>
                        <Typography component="span" color={quote.chosen ? 'primary': 'secondary'}>{priceDisplay(quote.price, quote.currency)}</Typography>
                        <Typography component="span" variant="caption" color={quote.chosen ? 'primary': 'secondary'}>  ({quote.update_at})</Typography>
                      </div>
                    }
                    secondary={<Typography variant="caption" color={quote.chosen ? 'primary': 'secondary'}>{quote.memo}</Typography>}
                  />
                  {mode !== 0 &&
                  <ListItemSecondaryAction>
                    <Button
                      data-tag={index}
                      variant="outlined"
                      color="primary"
                      size="small"
                      onClick={onClick}
                    >
                      {T.translate('quotation.use_quote')}
                    </Button>
                  </ListItemSecondaryAction>
                  }
                </ListItem>
              ))}
              {mode === 0 &&
              <div className={classes.titleBar}>
                <Button variant="outlined" color="secondary" size="small" onClick={onClose} >{T.translate('buttons.i_know')}</Button>
              </div>
              }
            </List>
          }
        </DialogContent>
      </Dialog>
      :
      <React.Fragment />
      }
    </React.Fragment>
  );
}


export default QuoteHistory;