/**
 * Created by milan on 2019/7/18.
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/styles/withStyles';
//import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Link as ExternalLink } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Container from '@material-ui/core/Container';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableFooter from '@material-ui/core/TableFooter';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import T from 'i18n-react';
import { Event, Mode, DataManager } from '../models/';
import TitleBar from '../common/TitleBar';

import ImagesDialog from '../dialogs/ImagesDialog';
import ResponsiveButton from '../buttons/ResponsiveButton';


const staff = [
  {
    label: '扫描二维码联系客服下单',
    description: '或微信内搜索 zhuchaox 添加',
    imgPath:
      'https://aifa.oss-cn-qingdao.aliyuncs.com/wechat.jpg'
  }
];

const styles = theme => ({
  paper: {
    marginTop: theme.spacing(4),
    //display: 'flex',
    //flexDirection: 'column',
    //alignItems: 'center',
    overflowX: 'auto',
    padding: theme.spacing(2)
  },
  emptyPaper: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  inlineLink: {
    textDecoration: 'underline',
    display: 'inline',
    marginLeft: 5
  },
  img: {
    maxWidth: 100
  },
  footer: {
    maxWidth: '80vw'
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
});

const carouselHide = -8964;

class Show extends Component {
  inquiry = null;
  id = -1;
  constructor(props) {
    super(props);
    this.state = {
      mode: this.props.mode,
      carousel: carouselHide
    };
    let id = props.match.params.id;
    if (id) this.id = parseInt(id);
    this.show();
  }

  show = () => {
    if (this.id === -1) return;
    let self = this;
    DataManager.getInstance().show_inquiry(this.id, {d: 1}).then(function (res) {
      if (res.c === 0) {
        self.inquiry = res.d;
        self.forceUpdate();
        DataManager.getInstance().pub(Event.DISPLAY, {c: self.inquiry.c});
      }
    });
  };

  render() {
    if (!this.inquiry) {
      return <div></div>;
    }
    const { classes } = this.props;
    const empty = this.inquiry.state < 2;

    let images = [];
    let text = {};
    if (this.state.carousel === -1) {
      images = staff;
      text.title = staff[0].label;
      text.desc = staff[0].description;
    }else if (this.state.carousel >= 0) {
      const row = this.inquiry.data[this.state.carousel];
      text.title = row.c[1];
      for (var i = 0; i < row.i.length; ++i) {
        const url = row.i[i];
        const image = {label: i, description: url, imgPath: url};
        images.push(image);
      }
    }
    const is_inquirer = DataManager.getInstance().mode < Mode.BUSINESS;
    const path = '/' + this.props.match.path.split('/')[1];

    return (
      <React.Fragment>
        <TitleBar left={DataManager.getInstance().storage.inquiries_list} title={{title: T.translate('inquiries.show')}}>
          {!is_inquirer ?
            <ResponsiveButton color='primary' variant="contained"  align="center" component={Link}
                              to={{pathname: `${path}/e/${this.id}`}}>
              {T.translate('quotation.edit')}
            </ResponsiveButton>
            :
          !empty &&
            <ResponsiveButton color='primary' variant="contained" align="center" onClick={(e) => this.showCarousel(e, -1)} >
              {T.translate('inquiries.place_order')}
            </ResponsiveButton>
          }
        </TitleBar>
        {empty ?
          <Container component="main" maxWidth="xs">
            <Paper className={classes.emptyPaper}>
              <Typography align="center">{T.translate('inquiries.please_wait')}</Typography>

              <Typography align="center" component={ExternalLink} className={classes.inlineLink}
                          href={this.inquiry.req_file} >
                {T.translate('inquiries.download_inquiry')}
              </Typography>
            </Paper>
          </Container>
          :
          this.table()
        }
        <ImagesDialog
          images={images}
          open={this.state.carousel > carouselHide}
          onClose={this.closeCarousel}
          {...text}
        />
      </React.Fragment>
    )
  }

  showCarousel = (e, index) => {
    this.setState({carousel: index});
  };

  closeCarousel = (e) => {
    this.setState({carousel: carouselHide});
  };

  table() {
    const data = this.inquiry.data;
    if (!data || data.length === 0) return <div></div>;
    const header_offset = 1;
    const header = data[0].c;
    const rows = data.slice(header_offset);
    let image_index = -1;
    for (var i = 0; i < header.length; ++i) {
      if (header[i] && header[i].indexOf('图片') >= 0) {
        image_index = i;
        break;
      }
    }
    if (image_index < 0) image_index = 2;

    const { classes } = this.props;
    return (
      <Paper className={classes.paper}>
        <Table>
          <TableHead>
            <TableRow>
              {header.map((col, idx) => (
                idx === 0 ?
                  <TableCell key={`h_${idx}`} component="th" scope="row">
                    {col}
                  </TableCell>
                  :
                  <TableCell key={`h_${idx}`} align="right">{col}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => {
              const has_image = row.i.length > 0;
              return (
                <TableRow
                  hover={has_image}
                  key={`r_${index}`}
                  onClick={has_image ? (e) => this.showCarousel(e, index + header_offset) : null}>
                  {row.c.map((col, idx) => {
                    if (idx === image_index && has_image) {
                      return <TableCell key={`c_${idx}`} align="right"><img className={classes.img} alt="logo" src={row.i[0]} /></TableCell>;
                    }else if (idx === 0) {
                      return <TableCell key={`c_${idx}`} component="th" scope="row">
                        {col}
                      </TableCell>
                    }else{
                      return <TableCell key={`c_${idx}`} align="right">{col}</TableCell>;
                    }
                  })}
                </TableRow>
              )
            })}
          </TableBody>
          <TableFooter>
            <TableRow className={classes.footer}>
              <TableCell colSpan={header.length} align="center">
                <Grid item xs={12} className={classes.flex}>
                  <Button variant="outlined" color="primary" size="small" component={ExternalLink} href={this.inquiry.res_file} >
                    {T.translate('inquiries.download_quotation')}
                  </Button>
                  <Button variant="outlined" color="primary" size="small" component={ExternalLink} href={this.inquiry.req_file} >
                    {T.translate('inquiries.download_inquiry')}
                  </Button>
                </Grid>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </Paper>
    );

   }
}

export default withRouter(withStyles(styles)(Show));