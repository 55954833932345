/**
 * Created by milan on 2019/7/18.
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/styles/withStyles';
import T from 'i18n-react';
import { Event, DataManager, Mode } from '../models/';
import AlertDialog from '../dialogs/AlertDialog';

import MaterialTable from 'material-table';
import { tableIcons, Roles, SellerRoles, CustomerRoles } from '../common/Constants';

import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import InputDialog from '../Unit/InputDialog';
import ExitIcon from '@material-ui/icons/ExitToApp';


const styles = theme => ({
  root: {

  }
});


class Users extends Component {
  roles = undefined;

  productColumns = [
    { title: T.translate('users.role'), field: 'role', editable: 'always' },
    { title: T.translate('users.push_notifications'), field: 'push', editable: 'always',
      lookup: {
        0: T.translate('users.push_none'),
        10: T.translate('users.push_sms')
      } },
    { title: T.translate('users.display'), field: 'display', editable: 'never' },
    { title: T.translate('users.phone'), field: 'phone', editable: 'never' },
    { title: T.translate('users.email'), field: 'email', editable: 'never' },
    { title: T.translate('users.contact'), field: 'contact', editable: 'never' },
    { title: T.translate('users.create_at'), field: 'create_at', editable: 'never' }
  ];
  state = {
    auth: false, per: 0, data: [], alertFrom: undefined, alert: undefined, uid: -1
  };
  params = null;
  tableRef = React.createRef();

  //constructor(props) {
  //  super(props);
  //}

  list = (query) => {
    const self = this;
    let p = query.page > 0 ? query.page + 1 : (parseInt(this.props.location.search.substring(1)) || 1);
    if (p < 0) p = 0;
    return DataManager.getInstance().unit_users(this.props.id || 0, {page: p}).then(function (res) {
      if (res.c === 0) {
        let d = res.d;
        const total = d.t;
        const max_page = Math.ceil(total / d.p);
        if (max_page > 0 && p > max_page) p = max_page;
        if (p < 1) p = 1;
        self.setState({auth: d.auth, per: max_page > 1 ? d.p : 0});
        return {
          data: d.users,
          page: p - 1,
          totalCount: total
        };
      }
      return {data: [], page: 0, totalCount: 0};
    })
  };

  move_user = (values) => {
    values.uid = this.state.uid;
    this.setState({uid: -1});
    const self = this;
    return DataManager.getInstance().join_unit(values).then(function (res) {
      let c = res.c;
      if (c !== 0) {
        DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'error', m: T.translate('errors.' + res.c)});
      }
      self.tableRef.current.onQueryChange();
    });
  };

  manage_user = (params, rowData) => {
    const self = this;
    return DataManager.getInstance().manage_user(this.props.id || 0, params).then(function (res) {
      if (res.c !== 0) {
        if (res.c === 30) {
          DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'error', m: T.translate('errors.units.owner_required')});
        }else if (res.c === 100) {
          DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'error', m: T.translate('errors.units.unauthorized')});
        }else if (res.c >= 300 && res.c <= 310) {
          DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'error', m: T.translate('errors.units.plan_required')});
        }else{
          DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'error', m: T.translate('errors.units.failed')});
          self.tableRef.current.onQueryChange();
        }
        return;
      }
      if (params.refresh) {
        self.tableRef.current.onQueryChange();
      }else if (params.action === 2 && rowData) {
        rowData.role = params.role;
        self.tableRef.current.setState({data: self.tableRef.current.state.data});
      }
    })
  };

  update_user = (newData, oldData) => {
    // if (newData.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(newData.email)) {
    //   DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'error', m: T.translate('errors.invalid_email')});
    //   return new Promise((resolve, reject) => {
    //     reject();
    //   });
    // }
    if (oldData.role < 10 && newData.push > 0) {
      DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'error', m: T.translate('errors.invalid_role_to_push')});
      return new Promise((resolve, reject) => {
        reject();
      });
    }
    const params = {uid: oldData.user_id, action: newData.role === oldData.role ? 1 : 2,
      role: newData.role};  // email: newData.email, contact: newData.contact, 
    if (oldData.push !== newData.push) params.push = newData.push;
    if (newData.role < oldData.role && oldData.dg === 10) {
      params.refresh = true;
      this.params = params;
      const newRole = parseInt(newData.role);
      this.setState({alertFrom: this.roles[oldData.role], alert: this.roles[newRole]});
      return new Promise((resolve, reject) => {
        resolve();
      });
    }
    return this.manage_user(params, null);
  };

  remove_user = (oldData) => {
    const params = {uid: oldData.user_id, phone: oldData.phone, action: 0};
    //return this.manage_user(params, null);

    params.refresh = true;
    this.params = params;
    this.setState({alertFrom: undefined, alert: 8964});
    return new Promise((resolve, reject) => {
      resolve();
    });
  };

  update_role = (rowData, role) => {
    //if (false) {
    //  DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'error', m: T.translate('errors.invalid_email')});
    //  return new Promise((resolve, reject) => {
    //    reject();
    //  });
    //}
    const params = {uid: rowData.user_id, action: 2, role: role};
    return this.manage_user(params, rowData);
  };

  closeAlert = (e) => {
    this.setState({alertFrom: undefined, alert: undefined});
    if (e.currentTarget.dataset.tag === '1') {
      this.manage_user(this.params, null);
      this.params = null;
    }
  };

  render_role_management = (rowData) => {
    const self = this;
    return (
      <RoleSelector
        value={rowData.role}
        onChange={(e) => {
          //rowData.role = e.target.value;
          self.update_role(rowData, e.target.value);
        }}
      />
    );
  };

  render_role = (rowData) => {
    let role;
    let i;
    for (i = 0; i < Roles.length; ++i) {
      const r = Roles[i];
      if (r.k === rowData.role) {
        role = r;
        break;
      }
    }
    if (!role) return <Typography />;
    return (
      <Typography>
        {T.translate(`users.role_${role.s}`)}
      </Typography>
    );
  };

  render() {
    if (!this.roles) {
      this.roles = {};
      const is_seller = DataManager.getInstance().mode >= Mode.BUSINESS;
      const roles = is_seller ? SellerRoles : CustomerRoles;
      for (var k in roles) {
        if (roles.hasOwnProperty(k)) {
          this.roles[k] = T.translate(`users.role_${roles[k]}`);
        }
      }
    }
    this.productColumns[0].lookup = this.roles;
    const per = this.state.per;
    const self = this;
    const actions = [{
      icon: () => <ExitIcon />,
      tooltip: T.translate('units.join'),
      isFreeAction: true,
      onClick: (e, rowData) => DataManager.getInstance().pub(Event.REDIRECT, '/corp/join')
    }];
    if (DataManager.getInstance().mode >= Mode.ADMIN) {
      actions.push({
        icon: () => <tableIcons.Sync />,
        onClick: (e, rowData) => { self.setState({uid: rowData.user_id}); }
      });
    }

    return (
      <React.Fragment>
        <div style={{height: 20}}></div>
        <MaterialTable
          tableRef={this.tableRef}
          icons={tableIcons}
          title={T.translate('users.all')}
          options={{addRowPosition: 'first', search: false, paging: per > 0, pageSize: per, pageSizeOptions: [per], detailPanelType: 'single'}}
          columns={self.productColumns}
          localization={{
            body: {
              editRow: {
                deleteText: T.translate('labels.confirm_delete')
              }
            }
          }}
          actions={actions}
          editable={this.state.auth ? {
            onRowUpdate: self.update_user,
            onRowDelete: self.remove_user
          } : null}
          //onRowClick={(event, rowData, togglePanel) => DataManager.getInstance().pub(Event.REDIRECT, `${path}/s/${rowData.user_id}`)}
          data={query => self.list(query)}
        />
        <AlertDialog
          open={!!this.state.alert}
          onClose={this.closeAlert}
          title={T.translate('dialogs.alert')}
          desc={
            this.state.alert === 8964 ?
              T.translate('dialogs.confirm_user_deletion', {s: this.params.phone})
            :
              T.translate('dialogs.confirm_role', {a: this.state.alertFrom, s: this.state.alert})
          }
          left={T.translate('buttons.cancel')}
          right={T.translate('buttons.yes')}
        />

        {DataManager.getInstance().mode >= Mode.ADMIN &&
          <InputDialog
            open={this.state.uid >= 0}
            onClose={e => self.setState({uid: -1})}
            onSave={this.move_user}
            type="text"
            k="invitation"
            title={T.translate('units.join_code')}
            desc=""
            value=""
          />
        }
      </React.Fragment>
    )
  }
}


function RoleSelector(props) {
  const [open, setOpen] = React.useState(false);

  //const handleChange = event => {
  //};

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  //<InputLabel htmlFor="RoleSelector">Role</InputLabel>

  return (
    <FormControl>
      <Select
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        value={props.value}
        onChange={props.onChange}
        inputProps={{
          name: 'role'
        }}
      >
        {Roles.map(option => (
          <MenuItem key={option.k} value={option.k}>
            {T.translate(`users.role_${option.s}`)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}


export default withRouter(withStyles(styles)(Users));