/**
 * Created by milan on 2019/7/18.
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/styles/withStyles';
import { DataManager } from '../models/';
import T from 'i18n-react';
import ImageCarousel from '../common/ImageCarousel';
import { priceDisplay } from '../common/Constants';
import SpecsDisplay from './SpecsDisplay';

import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Box from '@material-ui/core/Box';
import { grey } from '@material-ui/core/colors';
import Container from '@material-ui/core/Container';
// import MaterialsBlock from './MaterialsBlock';


const styles = theme => ({
  container: {
    padding: theme.spacing(2)
  },
  priceBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 3, 1, 3)
  },
  bottom: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-end'
  },
  box: {
    padding: theme.spacing(1, 3, 1, 3)
  },
  titleBar: {
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
    position: 'relative'
  },
  title: {
    width: '100%',
    height: 48,
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(0.5)
    }
  },
  closeButton: {
    width: 64,
    height: 64,
    position: 'absolute',
    padding: theme.spacing(0),
    right: theme.spacing(0),
    top: theme.spacing(0),
    color: theme.palette.grey[500]
  },
  price: {
    fontSize: 32,
    lineHeight: 1,
    verticalAlign: 'bottom'
  },
  label: {
    fontSize: 13,
    marginRight: theme.spacing(1)
  },
  info: {
    fontSize: 16
  }
});


class Show extends Component {
  id = -1;
  product = null;
  optionalLabels = [
    {k: 'brand', tk: 'brand'},
    {k: 'model', tk: 'item_model'}
  ];
  labels = [
    {k: 'material', tk: 'material'},
    {k: 'size', tk: 'size'},
    {k: 'quality', tk: 'quality'},
    {k: 'item_type', tk: 'item_type'},
    //{k: 'item_style', tk: 'item_style'},
    {k: 'manufacturing_time', tk: 'manufacturing_time'},
    {k: 'item_designer', tk: 'item_designer'},
    {k: 'desc', tk: 'desc'}
  ];

  constructor(props) {
    super(props);
    if (props.product) {
      this.product = props.product;
      this.state = {
        show: true
      };
      return;
    }

    this.state = {
      show: false
    };
    let id = props.match.params.id;
    if (id) this.id = parseInt(id);
    if (this.id > 0) {
      this.show();
    }
  }

  show = () => {
    let self = this;
    DataManager.getInstance().show_item(this.id, null).then(function (res) {
      if (res.c === 0) {
        self.product = res.d.item;
        self.setState({show: true});
      }
    });
  };

  render() {
    if (!this.state.show) return <React.Fragment/>;
    const { classes, onClose } = this.props;
    const product = this.product;
    const n = product.specs ? parseInt(product.specs.split(';')[0]) : 0;

    return (
      <Container component="main" maxWidth="xs">
        {onClose &&
        <div className={classes.titleBar}>
          <Typography align="center" variant="h6" className={classes.title}>{product.title}</Typography>
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon style={{width: 64, height: 64}} />
          </IconButton>
        </div>
        }
        <ImageCarousel images={product.media} clickOpen={true} />

        <div>
          <Box className={classes.priceBox}>
            <Box className={classes.bottom}>
              <Typography component="span" className={classes.label}>{T.translate('orders.price1')}: </Typography>
              <Typography component="span" className={classes.price}>{priceDisplay(product.price1, product.currency)}</Typography>
            </Box>
            <Box className={classes.bottom}>
              <Typography component="span" className={classes.label}>{T.translate('orders.price2')}: </Typography>
              <Typography component="span" className={classes.info} style={{textDecoration: 'line-through'}}>{priceDisplay(product.price2, product.currency)}</Typography>
            </Box>
          </Box>
          <Box borderBottom={1} borderColor={grey[400]} />

          {product.currency !== 'CNY' &&
            <React.Fragment>
              {this.optionalLabels.map((property, index) => (
                <Box key={property.k} className={classes.box}>
                  <Typography component="span" className={classes.label}>{T.translate(`orders.${property.tk}`)}: </Typography>
                  <Typography component="span" className={classes.info}>{product[property.k] || ''}</Typography>
                </Box>
              ))}
              <Box borderBottom={1} borderColor={grey[400]} />
            </React.Fragment>
          }

          {n > 0 &&  // disabled for now
          <Box className={classes.box}>
            <Typography component="span" className={classes.label}>{T.translate('products.specs')}: </Typography>
            <Typography component="span" className={classes.info}><SpecsDisplay item={product} /></Typography>
          </Box>
          }

          {this.labels.map((property, index) => (
            // property.k === 'material' && product.material1.indexOf(',') >= 0 ?
            // <MaterialsBlock key={property.k} product={product} />
            // :
            <Box key={property.k} className={classes.box}>
              <Typography component="span" className={classes.label}>{T.translate(`products.${property.tk}`)}: </Typography>
              <Typography component="span" className={classes.info}>{property.k === 'quality' ? T.translate(`quality.${product[property.k]}`) : product[property.k] || ''}</Typography>
            </Box>
          ))}

          <Box style={{height: 100}} />
        </div>
      </Container>
    )
  }
}


export default withRouter(withStyles(styles)(Show));