/**
 * Created by milan on 2019/7/18.
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/styles/withStyles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';

// import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';


const styles = theme => ({
  root: {

  }
});


class Subscriptions extends Component {
  rows = [
    ['交易服务费', '10%订单额度', '免费', '免费'],
    ['材宝箱', '原价', '8折, 送基础布皮版一套', '6折, 送基础布皮版两套'],
    ['团队人数', '单人', '7人以下', '100人以下'],
    ['产品/材质库', true, true, true],
    ['3D模型下载', false, '20个/月', '不限'],
    ['AR模式', true, true, true],
    ['项目管理/跟单', true, true, true],
    ['Excel报价单下载', true, true, true],
    ['住潮估价', '5单/月', '不限', '不限'],
    ['厂家招标', '5单/月', '不限', '不限'],
    ['团队协作', false, true, true],
    ['人员管理', false, true, true],
    ['权限管理', false, true, true],
    ['价格分级管理', false, false, true],
    ['第三方物流服务', true, true, true],
    ['第三方安装服务', true, true, true],
    ['代收代付服务', true, true, true],
    ['开票服务', true, true, true],
    ['云助理代跟单', '20%订单额度', '18%订单额度', '15%订单额度'],
  ];
  // constructor(props) {
  //  super(props);
  // }

  cell(value) {
    if (value === true || value === false) {
      return <Typography>{value ? <CheckIcon /> : <CloseIcon />}</Typography>;
    }
    return <Typography>{value}</Typography>;

  }

  render() {
    return (
      <React.Fragment>
        <CssBaseline />
        <Container component="main" maxWidth="sm">
          <Table aria-label="subscriptions table">
            <TableHead>
              <TableRow>
                <TableCell>功能</TableCell>
                <TableCell align="right">蓝卡</TableCell>
                <TableCell align="right">银卡</TableCell>
                <TableCell align="right">金卡</TableCell>
                {/* <TableCell align="center">白金卡</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {this.rows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    {row[0]}
                  </TableCell>
                  <TableCell align="right">{this.cell(row[1])}</TableCell>
                  <TableCell align="right">{this.cell(row[2])}</TableCell>
                  <TableCell align="right">{this.cell(row[3])}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

        </Container>
      </React.Fragment>
    )
  }
}


export default withRouter(withStyles(styles)(Subscriptions));