import React,  { Component } from 'react';
import { Route } from 'react-router-dom';
import withStyles from '@material-ui/styles/withStyles';
import CssBaseline from '@material-ui/core/CssBaseline';

import Show from './Inquiry/Show';


const styles = theme => ({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
    marginTop: theme.spacing(2),
    padding: theme.spacing(0)
  }
});

class Legacy extends Component {
  render() {
    const { classes } = this.props;
    const path = '/legacy';

    return (
      <React.Fragment>
        <CssBaseline />
        <div className={classes.root}>
          <Route path={`${path}/s/:id?`} component={ Show } />
        </div>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(Legacy);