/**
 * Created by milan on 2019/7/18.
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/styles/withStyles';
import T from 'i18n-react';
import { Event, DataManager } from '../models';
import { priceDisplay } from '../common/Constants';
import BaseDialog from '../dialogs/BaseDialog';
import Subscriptions from './Subscriptions';

import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { CardActionArea } from '@material-ui/core';

import AlertDialog from '../dialogs/AlertDialog';


const styles = theme => ({
  planCard: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),

    backgroundColor: theme.palette.primary.main,
    color: 'white'
  },
  plan: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    // padding: theme.spacing(1)
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(1)
  },
});


class Plans extends Component {
  state = {
    sub: false,
    paying: false
  };

  constructor(props) {
   super(props);
   this.show();
  }

  show = () => {
    const self = this;
    return DataManager.getInstance().get_resources('/api/units/plan/', {f: 1}).then(function (res) {
      if (res.c === 0) {
        const d = res.d;
        self.unit = d.unit;
        self.keys = d.keys;
        self.plans = d.plans;
        self.skus = d.skus;
        self.msg = d.msg;
        self.setState({requesting: false});
      }
    })
  };

  onDone = (e) => {
    const currentPlan = this.unit.plan;
    const newPlan = this.params.plan;
    const np = this.plans[this.params.plan];
    // const cp = this.plans[this.unit.plan];
    let text;
    if (currentPlan === newPlan) {
      text = '续费成功';
    }else if (currentPlan === 0) {
      text = `成功购买会员, 您的团队会员等级已变更为 ${np.t}.`;
    }else if (currentPlan > newPlan) {
      text = `成功购买会员, 当前会员等级到期后，您的团队会员等级将变更为 ${np['t']}.`;
    }else{
      text = `成功购买会员, 当前会员等级到期后, 您的团队会员等级将变更为 ${np['t']}. 如您需要立即升级, 请联系客服为您操作升级和当前会员等级退款事宜.`;
    }
    this.setState({cons: text});
    this.params = undefined;
    this.show();
  }

  updatePlan = () => {
    const params = this.params;
    const self = this;
    return DataManager.getInstance().post_resources('/api/units/plan/', params).then(function (res) {
      if (res.c === 0) {
        self.onDone();
      }else{
        DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'error', m: res.m || T.translate('errors.500')});
        self.setState({requesting: false});
      }
    })
  };

  tryUpdatePlan = (e) => {
    const tag = e.currentTarget.dataset.tag;
    const sku = this.skus[tag];
    if (sku.plan === 0) {
      this.showSubscriptions();
      return;
    }
    this.pay();
    // this.params = {sku: tag, plan: sku.plan};
    // const self = this;
    // self.setState({requesting: true});
    // return DataManager.getInstance().post_resources('/api/units/plan/', this.params).then(function (res) {
    //   if (res.c === 0) {
    //     self.pay();
    //   }else{
    //     DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'error', m: res.m || T.translate('errors.500')});
    //     self.setState({requesting: false});
    //   }
    // })
  }

  onPayed = (e) => {
    this.setState({paying: false});
    // this.params.receipt = 'webtest';
    // this.updatePlan();
  }

  pay = () => {
    this.setState({paying: true});
  }

  showSubscriptions = () => {
    this.setState({sub: true});
  }

  render() {
    if (!this.unit) return <React.Fragment/>;
    const { classes } = this.props;
    const plans = this.plans;
    const skus = this.skus;
    const unit = this.unit;
    const plan = unit.plan;
    const self = this;

    return (
      <React.Fragment>
        <BaseDialog
          maxWidth="sm"
          fullWidth
          open={this.state.sub}
          onClose={e => self.setState({sub: false})}
          title={T.translate('units.plan_rights')}
        >
          <Subscriptions />
        </BaseDialog>

        <AlertDialog
          open={!!this.state.cons}
          onClose={e => self.setState({cons: undefined})}
          title={T.translate('dialogs.alert')}
          desc={this.state.cons}
          right={T.translate('buttons.ok')}
        />

        <AlertDialog
          open={this.state.paying}
          onClose={this.onPayed}
          title={T.translate('dialogs.alert')}
          desc={`请到${T.translate('appName')} App 中购买会员`}
          right={T.translate('buttons.i_know')}
        />
        
        <Container component="main" maxWidth="xs">
          <div style={{height: 32}}></div>
          <Card>
            <CardActionArea className={classes.planCard} onClick={this.showSubscriptions} disabled={this.state.requesting}>
            <Typography variant="subtitle2">{T.translate('units.my_plan')}</Typography>
            <div style={{height: 24}}></div>
            <Typography variant="h6">{plans[plan].t}</Typography>
            {unit.plan > 0 &&
            <React.Fragment>
              <div style={{height: 24}}></div>
              <Typography variant="caption">{T.translate('units.plan_due')} {unit.due}</Typography>
            </React.Fragment>
            }
            {this.msg &&
            <React.Fragment>
              <div style={{height: 24}}></div>
              <Typography variant="subtitle2">{this.msg}</Typography>
            </React.Fragment>
            }
            </CardActionArea>
          </Card>

          {this.keys.map((key, idx) => (
            <div className={classes.card} key={key}>
              <div style={{height: 32}}></div>
              <Typography variant="h6">{plans[key].t}</Typography>

              <Grid container spacing={1} direction="row" alignItems="center" justify="flex-start">
              {plans[key].skus.map((sku, index) => (
                <Grid item xs={plans[key].skus.length <= 1 ? 12 : 6} key={sku}>
                  <Card>
                    <CardActionArea className={classes.card} data-tag={sku} onClick={this.tryUpdatePlan} disabled={this.state.requesting}>
                    <div style={{height: 12}}></div>
                    <Typography variant="subtitle2">{skus[sku].d}</Typography>
                    <div style={{height: 12}}></div>
                    {skus[sku].p1 && skus[sku].p1 > 0 ?
                    <React.Fragment>
                      <Typography variant="h5" color="primary">{priceDisplay(skus[sku].p1, 'CNY')}</Typography>
                      <div style={{height: 12}}></div>
                      <Typography variant="subtitle2" style={{textDecoration: skus[sku].p2 ? 'line-through' : 'none'}}>{priceDisplay(skus[sku].p2, 'CNY')}&nbsp;</Typography>
                      <div style={{height: 12}}></div>
                    </React.Fragment>
                    :
                    undefined
                    }
                    </CardActionArea>
                  </Card>
                </Grid>
              ))}
              </Grid>
            </div>
          ))}
        </Container>
      </React.Fragment>
    )
  }
}


export default withRouter(withStyles(styles)(Plans));