/**
 * Created by milan on 2019/7/18.
 */
import React, { Component } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import Button from '@material-ui/core/Button';
import { TextField } from 'formik-material-ui';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { Formik, Form, Field } from 'formik';
import T from 'i18n-react';
import { Event, DataManager } from '../models';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import LinearProgress from '@material-ui/core/LinearProgress';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const types = [{v: '1', l: 'inquiries.t1'}, {v: '2', l: 'inquiries.t2'}, {v: '3', l: 'inquiries.t3'}];


const styles = theme => ({
  root: {
    padding: theme.spacing(1)
  },
  paper: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2)
  },
  form: {
    //paddingTop: theme.spacing(4),
    width: '100%' // Fix IE 11 issue.
  },
  formControl: {
    marginBottom: 48
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
});

class Edit extends Component {
  id = -1;
  pid = -1;

  constructor(props) {
    super(props);
    this.state = {
      progress: -1,
    };
    this.pid = props.pid;
  }

  update_inquiry = (values, actions) => {
    //if (true) return values;
    const params = {...values};
    params.pid = this.pid;
    params.chosen = this.props.chosen;
    
    let self = this;
    return DataManager.getInstance().create_deal_from_collections(params).then(function (res) {
      const errors = {};
      if (res.c !== 0) {
        DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'error', m: T.translate('errors.' + res.c)});
        actions.setSubmitting(false);
        if (Object.keys(errors).length) {
          actions.setErrors(errors);
        }
        return;
      }
      DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'success', m: T.translate('notification.success')});
      DataManager.getInstance().pub(Event.REDIRECT, `/projects/s/${self.pid}/inquiries`);
    });
  };

  validate = values => {
    let errors = {};
    if (!values.type || values.type === '0') {
      errors.type = T.translate('errors.required');
    }
    if (Object.keys(errors).length)
      return errors;
  };

  render() {
    const { classes } = this.props;
    const self = this;
    const initialValues = {budget: '', req: '', type: '0'};
  
    return (
      <Container component="main" maxWidth="xs" className={classes.root}>
        <Formik
          initialValues={initialValues}
          validate={this.validate}
          validateOnBlur={true}
          onSubmit={this.update_inquiry}
        >
          {props => {
            return (
              <Form className={classes.form} noValidate>

                <Field
                  component={TextField}
                  error={!!props.errors.req}
                  helperText={props.errors.req}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="req"
                  label={T.translate("inquiries.name")}
                  placeholder={T.translate("inquiries.req")}
                  name="req"
                  autoComplete="req"
                  className={classes.formControl}
                />

                <Field
                  component={TextField}
                  error={!!props.errors.budget}
                  helperText={props.errors.budget}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="budget"
                  label={T.translate('inquiries.budget')}
                  placeholder={T.translate('inquiries.optional_budget')}
                  name="budget"
                  autoComplete="budget"
                  className={classes.formControl}
                />

                <FormControl
                  error={!!props.errors.type}
                  required
                  fullWidth
                  className={classes.formControl}
                  style={{padding: 12, paddingBottom: 0}}
                >
                  <InputLabel
                    htmlFor="type"
                    shrink={true}
                    style={{left: 12}}
                  >
                    {T.translate("inquiries.tier")}
                  </InputLabel>
                  <RadioGroup
                    name="type"
                    id="type"
                    value={props.values.type}
                    style={{marginTop: 16}}
                    row
                    onChange={(e) => {
                      props.setFieldValue('type', e.target.value);
                    }}
                  >
                    {types.map((t, index) => (
                      <FormControlLabel key={index} value={t.v} control={<Radio color="primary" />} labelPlacement="bottom" style={{marginLeft: 0}}
                                        label={<Typography style={{fontSize: 14}}>{T.translate(t.l)}</Typography>}
                      />
                    ))}
                  </RadioGroup>
                  {!!props.errors.type && <FormHelperText>{props.errors.type}</FormHelperText>}
                </FormControl>

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  disabled={props.isSubmitting}
                >
                  {T.translate("inquiries.submit")}
                </Button>
                {self.state.progress >= 0 &&
                <LinearProgress variant="determinate" value={self.state.progress} />
                }
              </Form>
            );
          }}
        </Formik>
      </Container>
    )
  }
}


export default withStyles(styles)(Edit);