import React,  { Component } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';

import { Event, Mode, DataManager } from '../models/';
import T from 'i18n-react';
import Pagination from '../common/Pagination';
import TitleBar from '../common/TitleBar';
import AlertDialog from '../dialogs/AlertDialog';
import Cell from './Cell';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import SendIcon from '@material-ui/icons/Send';
import TextField from '@material-ui/core/TextField';


const styles = theme => ({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
    marginTop: theme.spacing(2),
    padding: theme.spacing(1)
  },
  paper: {
    width: '100%',
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  list: {
    width: '100%'
  },
  content: {
    fontSize: 24,
    margin: theme.spacing(2)
  },
  pagination: {
    marginTop: theme.spacing(5)
  }
});


class Quotes extends Component {
  state = {total: 1, page: 1, per: 0, fc: -1, selected: -1, filter: 0, keywords: '', requesting: false, show: false};
  quotes = null;

  constructor(props) {
    super(props);
    const search = props.location.search.substring(1).split('.');
    if (search.length > 1) {
      const p = search[0];
      const page = parseInt(p);
      if (page > 0) this.state.page = page;
      const f = search[1];
      const filter = parseInt(f);
      if (filter > 0) this.state.filter = filter;
    }
    this.state.requesting = true;
    this.list(this.state.page, this.state.filter);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.location.search !== nextProps.location.search) {
      const search = nextProps.location.search.substring(1).split('.');
      if (search.length > 1) {
        const p = search[0];
        const page = parseInt(p);
        if (page > 0) nextState.page = page;
        const f = search[1];
        const filter = parseInt(f);
        if (filter >= 0) nextState.filter = filter;
      }
      this.list(nextState.page, nextState.filter);
      return true;
    }
    return true;
  }

  itemSelected = (index) => {
    const quote = this.quotes[index];
    const path = '/' + this.props.match.path.split('/')[1];
    if (DataManager.getInstance().mode >= Mode.ADMIN) {
      DataManager.getInstance().pub(Event.REDIRECT, `${path}/s/${quote.id}`);
    }else if (DataManager.getInstance().mode >= Mode.BUSINESS) {
      DataManager.getInstance().pub(Event.REDIRECT, `${path}/s/${quote.id}`);
    }else{
      this.setState({selected: index});
    }
  };

  quote_to_qs = (quote) => {
    if (!quote || !quote.title) return null;
    const params = {p: quote.project_id, t: quote.title};
    return Object.keys(params).map((key) => {
      return key + '=' + encodeURIComponent(params[key])
    }).join('&');
  };

  onChange = (e) => {
    const v = e.target.value;
    this.setState({keywords: v});
  };

  onSearch = () => {
    this.list(this.state.page, this.state.filter);
  };

  displayIndex = (index) => {
    const base = (this.state.page - 1) * this.state.per;
    const display = this.state.total - base - index;
    return display < 10 ? `0${display}` : display;
  };

  handlePagination = (offset) => {
    const page = 1 + offset / this.state.per;
    DataManager.getInstance().pub(Event.REDIRECT, {pathname: this.props.location.pathname, search: `${page}.${this.state.filter}`});
  };

  handleFilter = (e) => {
    const f = parseInt(e.target.value);
    this.setState({filter: f, requesting: true});
    DataManager.getInstance().pub(Event.REDIRECT, {pathname: this.props.location.pathname, search: `1.${f}`});
  };

  closeAlert = (e) => {
    this.setState({selected: -1});
  };

  closeAlertBusiness = (e) => {
    const quote = this.quotes[this.state.selected];
    const path = '/' + this.props.match.path.split('/')[1];

    this.setState({selected: -1});
    if (e.currentTarget.dataset.tag === '1') {
      DataManager.getInstance().pub(Event.REDIRECT, `${path}/e/${quote.id}`);
    }else{
      window.open(quote.req_file, '_self');
    }
  };

  render() {
    if (!this.state.show)
      return <div></div>;

    const { classes } = this.props;

    return (
      <React.Fragment>
        <div className={classes.root}>
          {this.table()}
        </div>

        <AlertDialog
          open={this.state.selected >= 0}
          onClose={this.closeAlert}
          title={T.translate('dialogs.no_offer')}
          desc={T.translate('dialogs.please_wait')}
          //left={T.translate('buttons.cancel')}
          right={T.translate('buttons.ok')}
        />
        }

      </React.Fragment>
    );
  }

  table = () => {
    const { classes } = this.props;
    const is_admin = DataManager.getInstance().mode >= Mode.ADMIN;
    const path = '/' + this.props.match.path.split('/')[1];
    const quotes = this.quotes;
    const self = this;
    const states = [T.translate('quotation.all'), T.translate('quotation.state_new'), T.translate('quotation.state_wait'), 
    T.translate('quotation.state_updating'), T.translate('quotation.state_lock'), T.translate('quotation.state_read')];
    const values = [0, 1, 2, 3, 5, 7];

    return (
      <Grid alignItems="center" justify="center" container>
        <TitleBar left={{show: false}} titleComponent={
          <Select
            value={this.state.filter}
            onChange={this.handleFilter}
            disableUnderline={true}
            variant="standard"
          >
            {states.map((state, index) => (
              <MenuItem key={values[index]} value={values[index]}><Typography align="center" variant="h6">{state}</Typography></MenuItem>
            ))}
          </Select>
        }>
          {is_admin &&
            <TextField
              onChange={this.onChange}
              value={this.state.keywords || ''}
              autoFocus
              margin="dense"
              id="text"
              label="Keywords"
              type="text"
              fullWidth
              InputProps={{
                endAdornment: <InputAdornment position="end">
                  <IconButton
                    type="submit"
                    color="primary"
                    disabled={!(this.state.keywords && this.state.keywords.length > 0)}
                    onClick={this.onSearch}
                  >
                    <SendIcon />
                  </IconButton>
                </InputAdornment>
              }}
            />
          }
        </TitleBar>

        <Grid item xs={12} sm={8} md={8} lg={6} xl={6}>
          <Paper className={classes.paper}>
            {this.state.requesting ?
              <CircularProgress size={48} />
              :
              (!quotes || quotes.length === 0 ?
                <Typography className={classes.content}>
                  {this.state.filter === 0 ?
                    T.translate('quotation.empty_list')
                    :
                    T.translate('quotation.empty_list_of_state', {s: states[this.state.filter]})
                  }
                </Typography>
                :
                <List className={classes.list}>
                  {quotes.map((quote, index) => (
                    <Cell
                      key={quote.id}
                      item={quote}
                      index={index}
                      displayIndex={self.displayIndex(index)}
                      path={path}
                      qs={self.quote_to_qs(quote)}
                      onClick={() => self.itemSelected(index)}
                    />
                  ))}
                </List>
              )
            }
          </Paper>
          {!this.state.requesting && this.state.per > 0 && this.state.total > this.state.per &&
          <Pagination
            offset={this.state.per * (this.state.page - 1)}
            limit={this.state.per}
            total={this.state.total}
            onClick={this.handlePagination}
          />
          }
        </Grid>
      </Grid>
    );
  };

  list = (page, f) => {
    //if (this.quotes !== null) return;
    let self = this;
    const q = this.state.keywords;
    DataManager.getInstance().list_quotes({page: page, f: f, q: q}).then(function (res) {
      if (res.c === 0) {
        let d = res.d;
        self.quotes = d.quotes;
        self.setState({page: page, total: d.t, per: d.p, fc: d.fc, requesting: false, show: true});
        window.scrollTo(0, 0);
      }
    });
  };
}


export default withStyles(styles)(Quotes);