import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles(theme => ({
  seller: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'start',
    textDecoration: 'none'
  },
  text: {
    textDecoration: 'none'
  },
  icon: {
    fontSize: 15,
    width: 24,
    height: 24,
    marginRight: 8
  }
}));

export default function Seller(props) {
  const classes = useStyles();

  return (
    <Box>
      <Link to={`/seller/s/${props.seller_id}`} className={classes.seller}>
        <Avatar className={classes.icon}>Z</Avatar>
        <Typography component="span" className={classes.text}>
          {props.title} >
        </Typography>
      </Link>
    </Box>
  );
}
