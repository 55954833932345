import React, { Component } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import T from 'i18n-react';


const styles = theme => ({
  bottomMargin: {
  }
});
const regexp =  /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;

class InputDialog extends Component {
  state = {url: '', legal: false};

  onChange = (e) => {
    const v = e.target.value;
    this.setState({url: v, legal: regexp.test(v)});
  };

  onSubmit = () => {
    if (!this.props.onSave) return;
    const urls = [this.state.url];
    this.props.onSave(urls);
  };

  render() {
    const { open, onClose } = this.props;
    return (
      <Dialog open={open} onClose={onClose} fullWidth maxWidth='xs' aria-labelledby="dialog-title">
        <DialogTitle id="form-dialog-title">{T.translate('buttons.url')}</DialogTitle>
        <DialogContent>
          <DialogContentText>

          </DialogContentText>
          <TextField
            onChange={this.onChange}
            autoFocus
            margin="dense"
            id="url"
            label="URL"
            type="url"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            {T.translate('buttons.cancel')}
          </Button>
          <Button onClick={this.onSubmit} color="primary" disabled={!this.state.legal}>
            {T.translate('buttons.submit')}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(styles)(InputDialog);