import React, { Component } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import SendIcon from '@material-ui/icons/Send';
import T from 'i18n-react';
import { Event, DataManager } from '../models/';

import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import ListSubheader from '@material-ui/core/ListSubheader';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/PlaylistAdd';
import CheckIcon from '@material-ui/icons/CheckBox';
import { Link } from '@material-ui/core';


const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper
  },
  gridList: {

  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)'
  },
  add: {
    color: 'green'
  },
  checked: {
    color: 'green'
  }
});


class SuggestionDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {text: props.text, selected: [], show: false, page: 1};
  }
  items = [];

  search = (page) => {
    const self = this;
    DataManager.getInstance().google({q: this.state.text, page: page}).then(function (res) {
      if (res.c === 0) {
        const result = res.d;
        self.items = page === 1 ? result.items : self.items.concat(result.items);
        self.setState({show: !self.state.show, page: page + 1});
        return;
      }
      DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'error', m: T.translate('errors.500')});
    });
  };

  onNext = () => {
    this.search(this.state.page);
    //this.props.onSave(urls);
  };

  onChange = (e) => {
    const v = e.target.value;
    this.setState({text: v});
  };

  onSelect = (v) => {
    const selected = this.state.selected;
    const index = selected.indexOf(v);
    const ns = selected.slice();
    if (index >= 0) {
      ns.splice(index, 1);
    }else{
      ns.push(v);
    }
    this.setState({selected: ns});
  };

  onSearch = () => {
    this.search(1);
    //this.props.onSave(urls);
  };

  onSubmit = () => {
    if (!this.props.onSave) return;
    const urls = this.state.selected;
    this.props.onSave(urls);
  };

  render() {
    const self = this;
    const show = this.state.show;
    const { open, onClose, classes } = this.props;
    return (
      <Dialog open={open} onClose={onClose} fullWidth maxWidth='lg' aria-labelledby="dialog-title">
        <DialogTitle id="form-dialog-title">
          <TextField
            onChange={this.onChange}
            value={this.state.text || ''}
            autoFocus
            margin="dense"
            id="text"
            label="Keywords"
            type="text"
            fullWidth
            InputProps={{
              endAdornment: <InputAdornment position="end">
                <IconButton
                  type="submit"
                  color="primary"
                  disabled={!(this.state.text && this.state.text.length > 0)}
                  onClick={this.onSearch}
                >
                  <SendIcon />
                </IconButton>
              </InputAdornment>
            }}
          />
        </DialogTitle>
        <DialogContent>
          <div className={classes.root}>
            <GridList cellHeight={256} cols={4} className={classes.gridList}>
              <GridListTile key="Subheader" cols={4} style={{ height: 'auto' }}>
                <ListSubheader component="div">{T.translate('dialogs.images_selected', {c: this.state.selected.length})}</ListSubheader>
              </GridListTile>
              {this.items.map((item, index) => (
                <GridListTile cols={1} key={`${index}_${item.link}`} show={show.toString()}>
                  <img src={item.link} alt={item.link} />
                  <GridListTileBar
                    title={<Link style={{color: 'white'}} target="_blank" href={item.image.contextLink}>{item.title || ''}</Link>}
                    subtitle={<span>{item.link}</span>}
                    actionIcon={
                      <IconButton aria-label={item.link} className={classes.icon} onClick={() => { self.onSelect(item.link); }} >
                        {self.state.selected.indexOf(item.link) >= 0 ? <CheckIcon className={classes.checked} fontSize="large" /> : <AddIcon color="primary" fontSize="large" />}
                      </IconButton>
                    }
                  />
                </GridListTile>
              ))}
            </GridList>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.onNext} color="secondary" disabled={this.state.page >= 10}>
            {T.translate('buttons.next_page')}
          </Button>
          <Button onClick={onClose} color="secondary">
            {T.translate('buttons.cancel')}
          </Button>
          <Button onClick={this.onSubmit} color="primary" disabled={!(this.state.selected.length > 0)}>
            {T.translate('buttons.submit')}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}


export default withStyles(styles)(SuggestionDialog);