/**
 * Created by milan on 2019/7/18.
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/styles/withStyles';

import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';


const imageSize = 120;

const styles = theme => ({
  root: {
    minHeight: imageSize,
    maxWidth: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'start',
    justifyContent: 'start',
    overflow: 'hidden'
  },
  gridList: {
    flexWrap: 'nowrap',
    overflow: 'hidden',
    //width: '100%',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)'
  },
  buttons: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    height: imageSize,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'space-between',
    justifyContent: 'space-between'
  },
  button: {
    width: '100%',
    height: 50
  },
  paper: {
    width: imageSize,
    height: imageSize,
    maxWidth: imageSize,
    maxHeight: imageSize,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  img: {
    maxWidth: '100%',
    maxHeight: '100%',
    verticalAlign: 'middle'
  }
});


class Images extends Component {
  remove = (e) => {
    console.log(e);
  };

  showFile = (f, index) => {
    let fn;
    let ft;
    let src = f;
    if (f instanceof File) {
      fn = f.name;
      const _ft = f.type.split('/');
      ft = _ft[_ft.length - 1];
      src = URL.createObjectURL(f);
    }else{
      fn = f.toLowerCase();
      ft = fn.split('.').pop();
      fn = decodeURIComponent(fn.split('/').pop().split('#')[0].split('?')[0]);
    }
    const { classes } = this.props;
    let isImage = ft === 'png' || ft === 'jpg' || ft === 'jpeg';
    const display = isImage ?
      <Paper className={classes.paper}>
        <img className={classes.img} src={src} alt={src} />
      </Paper>
      :
      <div style={{width: imageSize, height: imageSize, border: '1px solid grey', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <Typography align="center">
          {fn}
        </Typography>
      </div>;
    return (
      <GridListTile key={index} rows={1} cols={1} style={{marginRight: 10, minWidth: imageSize, minHeight: imageSize, width: imageSize, height: imageSize}}>
        {display}
      </GridListTile>
    );
  };

  render() {
    const { classes, files } = this.props;
    return (
      <div className={classes.root}>
        <GridList className={classes.gridList} spacing={0} cellHeight={imageSize} rows={1}>
          {files.map(this.showFile)}
        </GridList>
      </div>
    )
  }
}



export default withRouter(withStyles(styles)(Images));