import React,  { Component } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';

import { Event, DataManager } from '../models';
import T from 'i18n-react';
import Pagination from '../common/Pagination';
import AlertDialog from '../dialogs/AlertDialog';

import CircularProgress from '@material-ui/core/CircularProgress';

import Image from '../common/Image';
import Divider from '@material-ui/core/Divider';
import ImagesDialog from '../dialogs/ImagesDialog';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { FlowTexts } from '../common/Constants';
import TitleBar from '../common/TitleBar';

const FlowOptions = ['cs', 'cmt', 'cmg', 'rm', 'rf', 'mf', 'ms', 'wf', 'ws', 'wd', 'sf', 'sc', 'ss', 'cm', 'cp', 'csp']


const styles = theme => ({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
    marginTop: theme.spacing(2),
    padding: theme.spacing(1)
  },
  center: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  paper: {
    width: '100%',
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  list: {
    width: '100%'
  },
  content: {
    fontSize: 24,
    margin: theme.spacing(2)
  },
  pagination: {
    marginTop: theme.spacing(5)
  },

  leading: {
    width: 54,
    minWidth: 54,
    maxWidth: 54,
    margin: theme.spacing(1)
  },
  label: {
    lineHeight: 2,
    fontSize: 14,
    marginLeft: theme.spacing(1)
  },
  importantInfo: {
    lineHeight: 2,
    fontSize: 18
  },
  cell: {
    backgroundColor: 'white',
    paddingLeft: 0
  },
  state_new: {
    backgroundColor: 'red'
  },
  state_read: {
    backgroundColor: theme.palette.primary.main
  }
});


class Dinja extends Component {
  state = {total: 1, page: 1, per: 0, selected: -1, filter: 0, item_images: -1, requesting: false};
  records = null;

  constructor(props) {
    super(props);
    const search = props.location.search.substring(1).split('.');
    if (search.length > 1) {
      const p = search[0];
      const page = parseInt(p);
      if (page > 0) this.state.page = page;
      const f = search[1];
      const filter = parseInt(f);
      if (filter > 0) this.state.filter = filter;
    }
    this.state.requesting = true;
    this.list(this.state.page, this.state.filter);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.location.search !== nextProps.location.search) {
      const search = nextProps.location.search.substring(1).split('.');
      if (search.length > 1) {
        const p = search[0];
        const page = parseInt(p);
        if (page > 0) nextState.page = page;
        const f = search[1];
        const filter = parseInt(f);
        if (filter >= 0) nextState.filter = filter;
      }
      this.list(nextState.page, nextState.filter);
      return true;
    }
    return true;
  }

  read_all = () => {
    const self = this;
    self.setState({requesting: true});
    DataManager.getInstance().read_all_records(null).then(function (res) {
      if (res.c === 0) {
        const records = self.records;
        let i;
        for (i = 0; i < records.length; ++i) {
          const m = records[i];
          m.state = 2;
        }
        self.setState({requesting: false});
      }
    });
    //DataManager.getInstance().pub(Event.REDIRECT, item.url);
  };

  selected = (index) => {
    const item = this.records[index];
    DataManager.getInstance().read_message(item.id, null).then(function (res) {
      switch (item.type) {
        case 100:
          DataManager.getInstance().pub(Event.REDIRECT, `/projects/s/${item.primary_id}/tm`);
          break;
        case 2:
          DataManager.getInstance().pub(Event.REDIRECT, `/orders/trackers/${item.primary_id}/${item.secondary_id}`);
          break;
        default:
          DataManager.getInstance().pub(Event.REDIRECT, item.url);
          break
      }
    });
    //DataManager.getInstance().pub(Event.REDIRECT, item.url);
  };

  handleFilter = (e) => {
    const f = parseInt(e.target.value);
    this.setState({filter: f, requesting: true});
    DataManager.getInstance().pub(Event.REDIRECT, {pathname: this.props.location.pathname, search: `1.${f}`});
  };

  handle_pagination = (offset) => {
    const page = 1 + offset / this.state.per;
    DataManager.getInstance().pub(Event.REDIRECT, {pathname: this.props.location.pathname, search: `${page}.${this.state.filter}`});
  };

  close_alert = (e) => {
    this.setState({selected: -1});
  };

  show_item_images = (index) => {
    this.setState({item_images: index});
  };

  deselect = () => {
    this.setState({selected: -1, item_images: -1});
  };

  render() {
    if (!this.records)
      return <div></div>;

    const { classes } = this.props;

    return (
      <React.Fragment>
        <div className={classes.root}>
          {this.table()}
        </div>

        <AlertDialog
          open={false}
          onClose={this.close_alert}
          title={T.translate('dialogs.no_offer')}
          desc={T.translate('dialogs.please_wait')}
          //left={T.translate('buttons.cancel')}
          right={T.translate('buttons.ok')}
        />

        {this.state.item_images >= 0 &&
          <ImagesDialog
            maxWidth="xl"
            images={this.records[this.state.item_images].media}
            open={this.state.item_images >= 0}
            title=" "
            onClose={this.deselect}
          />
        }
      </React.Fragment>
    );
  }

  table = () => {
    const { classes } = this.props;
    const records = this.records;
    const self = this;

    return (
      <Grid alignItems="center" justify="center" container>
        <TitleBar left={{show: false}} titleComponent={
          <Select
            value={this.state.filter}
            onChange={this.handleFilter}
            disableUnderline={true}
            variant="standard"
          >
            <MenuItem key="pending" value="0"><Typography align="center" variant="h6">{T.translate('dinja.pending')}</Typography></MenuItem>
            <MenuItem key="done" value="1"><Typography align="center" variant="h6">{T.translate('dinja.done')}</Typography></MenuItem>
          </Select>
        }>
        </TitleBar>
        <Grid item xs={12} sm={8} md={8} lg={6} xl={6}>
          <Paper className={classes.paper}>
            {this.state.requesting ?
              <CircularProgress size={48} />
              :
              (records.length === 0 ?
                <Typography className={classes.content}>{T.translate('dinja.empty_list')}</Typography>
                :
                <List className={classes.list}>
                  {records.map((record, index) => (
                    self.item_cell(record, index, index !== records.length - 1)
                  ))}
                </List>
              )
            }
          </Paper>
          {!this.state.requesting && this.state.per > 0 && this.state.total > this.state.per &&
          <Pagination
            offset={this.state.per * (this.state.page - 1)}
            limit={this.state.per}
            total={this.state.total}
            onClick={this.handle_pagination}
          />
          }
        </Grid>
      </Grid>
    );
  };

  item_cell(item, index, divider) {
    let order;
    for (var i = 0; i < this.orders.length; ++i) {
      const o = this.orders[i]
      if (o.id === item.order_id) {
        order = o;
        break;
      }
    }
    return <Cell
      key={item.id}
      item={item}
      order={order}
      divider={true}
      hidePrice={true}
      tracking={true}
      onImageClick={() => this.show_item_images(index)}
      // onClick={() => this.selected(item)}
    />;
  }

  list = (page, f) => {
    const self = this;
    const params = {page: page, f: f};
    return DataManager.getInstance().list_dinja_records(params).then(function (res) {
      if (res.c === 0) {
        let d = res.d;
        self.records = d.records;
        self.orders = d.orders;
        self.setState({page: page, total: d.mc, per: d.p, requesting: false});
      }
    })
  };
}


const useStyles = makeStyles(theme => ({
  titleBlock: {
    padding: theme.spacing(0, 0, 0, 1),
    minHeight: 150,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'space-between',
    justifyContent: 'space-between'
  },
  rightBlock: {
    padding: theme.spacing(0, 0, 0, 1),
    minHeight: 150,
    minWidth: 110,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'space-between'
  },
  label: {
    fontSize: 14,
    display: 'block',
    [theme.breakpoints.down('xs')]: {
      maxWidth: 130
    }
  },
  boldLabel: {
    fontSize: 20,
    //color: theme.palette.primary.main,
    [theme.breakpoints.down('xs')]: {
      maxWidth: 36
    }
  },
  cell: {
    backgroundColor: 'white',
    padding: theme.spacing(1, 1, 1, 1)
  }
}));

function Cell(props) {
  const [requesting, setRequesting] = React.useState(false);

  const { item, order, divider, onClick, onImageClick, children, tracking } = props;
  const classes = useStyles(props.theme);

  function save(params) {
    setRequesting(true);
    const prom = function (res) {
      if (res.c !== 0) {
        return;
      }
      if (params.track_id) item.track_id = params.track_id;
      if (params.key) item.key = params.key;
      setRequesting(false);
    };
    return DataManager.getInstance().update_dinja_record(params).then(prom);
  }

  function handleOrderItem(e) {
    let params = {id: item.id, track_id: e.target.value};
    return save(params);
  }

  function handleStage(e) {
    let params = {id: item.id, key: e.target.value};
    return save(params);
  }


  let badges = undefined;
  if (!children && tracking && item.ui) {
  }
  if (!badges) {
    badges = <Box>
      <Typography style={item.quality === '100' ? {color: 'orange'} : undefined} component="span" className={classes.label}>{T.translate(`quality.${item.quality}`)}</Typography>
    </Box>;
  }
  const title = [item.dj_product, item.dj_name].filter(key => key).join(' / ')

  return (
    <React.Fragment >
      <ListItem className={classes.cell} key={item.id} alignItems="center" button={!!onClick} onClick={onClick} >
        <Image
          style={{ height: 130, width: 130, maxWidth: 130, maxHeight: 130, minWidth: 130, minHeight: 130 }}
          alt={item.media[0]}
          src={item.media[0]}
          title={onImageClick ? T.translate('trackers.n_images', {c: item.media.length}) : null}
        />
        <ListItemText
          primary={
            <Box className={classes.titleBlock}>
              <Box style={{display: 'flex', alignItems: 'center'}}>
                <Typography component="span" className={classes.label}>{requesting ? item.dj_center : item.dj_center}</Typography>
              </Box>
              <Box>
                <Typography component="p" className={classes.label}>{item.dj_wo}</Typography>
              </Box>
              <Box>
                <Typography component="p" className={classes.label}>{title}</Typography>
              </Box>
            </Box>
          }
        />
        <Box className={classes.rightBlock}>
          <Box style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}}>
            <React.Fragment>
              <Typography component="p" className={classes.label}>{order.project} / {order.identifier}</Typography>
            </React.Fragment>
          </Box>

          <Select
            value={item.track_id}
            onChange={handleOrderItem}
            disableUnderline={false}
            variant="standard"
          >
            {order.items.map((order_item, index) => {
              return <MenuItem key={index} value={order_item.id}>{order_item.title}</MenuItem>
            })}
          </Select>

          <Select
            value={item.key}
            onChange={handleStage}
            disableUnderline={false}
            variant="standard"
          >
            {FlowOptions.map((option, index) => {
              return <MenuItem key={index} value={option}>{T.translate(`products.${FlowTexts[option]}`)}</MenuItem>
            })}
          </Select>
        </Box>
      </ListItem>
      {divider && <Divider />}
    </React.Fragment>
  );
}


export default withStyles(styles)(Dinja);