import React,  { Component } from 'react';
import { Route } from 'react-router-dom';
import withStyles from '@material-ui/styles/withStyles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Topbar from '../Topbar';

import Index from './List';
//import Index from './Index';
import Edit from './Edit';
//import Quotation from './Quotation';
import DealQuote from './DealQuote';
import Excel from './Excel';
import Show from './Show';
import ShowDeal from '../Deal/Show';
import { Mode, DataManager, Permit } from '../models/';


const styles = theme => ({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
    marginTop: theme.spacing(2),
    padding: theme.spacing(0)
  }
});

class Inquiries extends Component {
  render() {
    const { classes } = this.props;
    const currentPath = this.props.location.pathname;
    const path = '/' + this.props.match.path.split('/')[1];
    const Editor = DataManager.getInstance().mode < Mode.BUSINESS ? Edit : DealQuote;

    let id = this.props.match.params.id;
    if (id) id = parseInt(id);

    return (
      <React.Fragment>
        <CssBaseline />
        <Topbar currentPath={currentPath} />
        <Permit permit="Quote">
        <Route path={path} exact component={ Index } />
        <div className={classes.root}>
          <Route path={`${path}/o/:id?`} component={ ShowDeal } />
          <Route path={`${path}/s/:id?`} component={ Show } />
          <Route path={`${path}/c/`} component={ Edit } />
          <Route path={`${path}/e/:id?`} component={ Editor } />
          <Route path={`${path}/ex/:id?`} component={ Excel } />
        </div>
        </Permit>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(Inquiries);