import React, { Component } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import Typography from '@material-ui/core/Typography';
import { Link, withRouter } from 'react-router-dom';
import T from 'i18n-react';

const styles = theme => ({
  root: {
    display: 'flex'
    //[theme.breakpoints.down('sm')]: {
    //  alignItems: 'center',
    //  flexDirection: "row"
    //},
    //[theme.breakpoints.up('md')]: {
    //  alignItems: 'right',
    //  paddingRight: 20,
    //  flexDirection: "row-reverse"
    //}
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
    verticalAlign: 'text-center'
  },
  center: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  text: {
    display: 'inline-block',
    verticalAlign: 'text-center',
    height: '100%'
  }
});

class Back extends Component {
  /*goBack = (e) => {
    let history = this.props.history;
    if (history.length > 0) {
      e.preventDefault();
      history.goBack();
    }
  };*/

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Link className={classes.link} to={this.props.to}>
          <Typography variant="h6" className={classes.center}>
            <KeyboardArrowLeft style={{marginBottom: 3}} />
            <span className={classes.text}>{this.props.title ? this.props.title : T.translate("buttons.back")}</span>
          </Typography>
        </Link>
      </div>
    )
  }
}

export default withRouter(withStyles(styles)(Back));