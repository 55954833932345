/**
 * Created by milan on 2019/7/18.
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/styles/withStyles';
import Button from '@material-ui/core/Button';
//import TextField from '@material-ui/core/TextField';
import { TextField, Checkbox } from 'formik-material-ui';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Paper from '@material-ui/core/Paper';
import { Link as ExternalLink } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { Formik, Form, Field } from 'formik';
import T from 'i18n-react';
import { Event, DataManager } from '../models/';
import TitleBar from '../common/TitleBar';
import Uploader from './Upload';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';

const iconPlaceholder = require('../../images/placeholder.png');


const styles = theme => ({
  paper: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2)
  },
  inlineLink: {
    textDecoration: 'underline',
    display: 'inline',
    marginLeft: 5
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    paddingTop: theme.spacing(4)
  },
  input: {
    //marginTop: theme.spacing(6)
  },
  img: {
    marginTop: 10,
    maxWidth: 280,
    maxHeight: 280,
    minWidth: 20,
    minHeight: 20
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
});

class Excel extends Component {
  inquiry = null;
  id = -1;
  constructor(props) {
    super(props);
    this.state = {
      mode: this.props.mode,
      req: null,
      saved: null,
      selected: iconPlaceholder
    };
    let id = props.match.params.id;
    if (id) this.id = parseInt(id);
    this.show();
  }

  show = () => {
    let self = this;
    DataManager.getInstance().show_inquiry(this.id, null).then(function (res) {
      if (res.c === 0) {
        let inquiry = res.d;
        self.inquiry = inquiry;
        const saved = inquiry.res_file ? decodeURIComponent(inquiry.res_file.split('/').pop().split('#')[0].split('?')[0]) : null;

        const fn = inquiry.req_file.toLowerCase();
        const ft = fn ? fn.split('.').pop() : null;
        const filename = fn ? decodeURIComponent(fn.split('/').pop().split('#')[0].split('?')[0]) : null;
        let update = {req: filename, saved: saved};
        if (ft === 'png' || ft === 'jpg' || ft === 'jpeg') {
          update.selected = inquiry.req_file;
        }
        self.setState(update);
        DataManager.getInstance().pub(Event.DISPLAY, {c: inquiry.c});
      }
    });
  };

  quotation = (values, actions) => {
    let updated = this.props.updated;
    let path = '/' + this.props.match.path.split('/')[1];
    let to = `${path}/s/${this.id}`;

    return DataManager.getInstance().quotation(this.id, values).then(function (res) {
      let errors = {};
      if (res.c !== 0) {
        errors.req = T.translate('errors.' + res.c);
        actions.setSubmitting(false);
        if (Object.keys(errors).length) {
          actions.setErrors(errors);
          //actions.setStatus(errors);
        }
        return;
      }
      if (updated) updated();
      DataManager.getInstance().pub(Event.REDIRECT, to);
    });
  };

  validate = values => {
    let errors = {};
    if (!values.req) {
      errors.req = T.translate('errors.required');
    }
    if (!values.file) {
      errors.file = T.translate('errors.required');
    }else{
      if (values.file.type.split('/')[0] === 'image') {
        this.setState({
          selected: URL.createObjectURL(values.file)
        });
      }else {
        this.setState({
          selected: iconPlaceholder
        });
      }
    }
    if (Object.keys(errors).length)
      return errors;

  };

  render() {
    if (!this.inquiry) {
      return <div></div>;
    }
    const { classes } = this.props;
    let id = this.id;
    const iq = 'quotation';
    const key = this.inquiry.state >= 2 ? 'edit' : 'create';
    let self = this;
    //<img className={classes.img} alt="selected" src={self.state.selected} />

    return (
      <div>
        <TitleBar left={DataManager.getInstance().storage.inquiries_list} title={{title: T.translate(`${iq}.${key}`)}}>
          <Typography align="center" variant="h6" component={ExternalLink} className={classes.inlineLink}
                      href="https://aifa.oss-cn-qingdao.aliyuncs.com/%E4%BA%A7%E5%93%81%E6%B8%85%E5%8D%95.xlsx">
            {T.translate(`${iq}.sample`)}
          </Typography>
        </TitleBar>
        <Container component="main" maxWidth="xs" className={classes.root}>
          <Paper className={classes.paper}>
            <Container>
              <Typography color="textPrimary" >
                客户: {self.inquiry.name}
              </Typography>
              <Typography color="textPrimary" >
                说明: {self.inquiry.req}
              </Typography>
              <Typography color="textPrimary" >
                预算: {self.inquiry.budget}
              </Typography>
              <Typography color="textPrimary" >
                标准: {T.translate(`inquiries.t${self.inquiry.type}`)}
              </Typography>
              <Typography color="textPrimary" >
                文件: {self.inquiry.req_data.length}个
              </Typography>
              <Uploader files={this.inquiry.req_data} />
            </Container>
            <Formik
              initialValues={{ id: id || -1, req: this.inquiry ? this.inquiry.res : '', file: '', notification: false }}
              validate={this.validate}
              validateOnChange={true}
              onSubmit={this.quotation}
            >
              {props => {
                return (
                  <Form className={classes.form} noValidate>
                    <Field
                      hidden
                      value={id}
                      required
                      id="id"
                      name="id"
                    />
                    <Input
                      inputProps={{
                        style: {height: '2em', display: 'none'},
                        accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                        type: 'file',
                        name: 'file',
                        id: 'file',
                        onChange: (e) => {
                          props.setFieldValue('file', e.currentTarget.files[0]);
                          //self.file_selected(e.currentTarget.files[0]);
                        }
                      }}
                    />
                    <label htmlFor="file">
                      <Button component="div" style={{display: 'block'}} disableRipple={true}>
                        <FormControl
                          error={!!props.errors.file}
                          margin="normal"
                          required
                          fullWidth
                        >
                          <InputLabel
                            htmlFor="upload-display"
                            shrink={true} >
                            {T.translate("inquiries.file_with_limits")}
                          </InputLabel>
                          <Input
                            inputComponent="span"
                            id="upload-display"
                            name="upload-display"
                            aria-describedby="upload-display-text"
                            inputProps={{
                              style: {
                                textTransform: 'none',
                                display: 'block',
                                width: '100%',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                color: props.errors.file ? '#f44336' : 'rgba(0, 0, 0, 0.54)'
                              },
                              children: props.values.file.name || self.state.saved || T.translate("form.select_file")
                            }}
                          >
                          </Input>
                          <FormHelperText id="upload-display-text">{props.errors.file}</FormHelperText>
                        </FormControl>
                      </Button>
                    </label>

                    <Field
                      component={TextField}
                      error={!!props.errors.req}
                      helperText={props.errors.req}
                      className={classes.input}
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="req"
                      label={T.translate(`${iq}.req`)}
                      name="req"
                      autoComplete="req"
                      autoFocus
                    />

                    <FormControlLabel
                      control={
                        <Field
                          component={Checkbox}
                          id="notification"
                          name="notification"
                        />
                      }
                      className={classes.formControl}
                      label={T.translate("form.notification")}
                    />

                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      disabled={props.isSubmitting}
                    >
                      {T.translate(`${iq}.submit`)}
                    </Button>
                  </Form>
                );
              }}
            </Formik>
          </Paper>
        </Container>
      </div>
    )
  }
}

export default withRouter(withStyles(styles)(Excel));