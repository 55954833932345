import React,  { Component } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { Link } from 'react-router-dom';
import { Mode, Event, DataManager } from '../models/';
import ProductDialog from '../Product/ProductDialog';
import Navigator from './Navigator';
import { priceDisplay } from '../common/Constants';

import T from 'i18n-react';
import Pagination from '../common/Pagination';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import ListSubheader from '@material-ui/core/ListSubheader';
import { Button } from '@material-ui/core';
import ArrowIcon from '@material-ui/icons/ArrowForward';
// import InfoIcon from '@material-ui/icons/Info';


const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    // backgroundColor: 'transparent',
    // backgroundColor: theme.palette.background.paper,
  },
  tile: {
    marginBottom: theme.spacing(1),
    cursor: 'pointer'
  },
  image: {
    // cursor: 'pointer',
    minWidth: '100%',
    minHeight: '100%',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  img: {
    width: '100%',
    objectFit: 'cover',
    minWidth: '100%',
    minHeight: '100%',
    verticalAlign: 'middle'
  },
  badge3D: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    padding: 3,
    fontSize: 13,
    position: 'absolute',
    right: 0,
    top: 0
  },
  badgeNew: {
    backgroundColor: 'red',
    color: 'white',
    padding: 3,
    fontSize: 13,
    position: 'absolute',
    right: 0,
    top: 0
  },

  root: {
    flexGrow: 1,
    overflow: 'hidden',
    marginTop: theme.spacing(2),
    padding: theme.spacing(1)
  },
  content: {
    fontSize: 24,
    margin: theme.spacing(2)
  },
  pagination: {
    marginTop: theme.spacing(5)
  }
});


class Products extends Component {
  state = {total: 1, page: 1, per: 0, selected: -1, filter: 2, params: {}, requesting: false};
  products = null;
  labels = null;
  pid = 0;
  wanted = false;

  constructor(props) {
    super(props);
    if (this.props.match.params.pid > 0)
      this.pid = this.props.match.params.pid;
    this.wanted = this.props.location.pathname.split('/')[1] === 'wanted';

    const search = props.location.search.substring(1);
    if (search) {
      const params = search.split('&').reduce(function(prev, curr, i, arr) {
        var p = curr.split('=');
        prev[p[0]] = decodeURIComponent(p[1]);
        return prev;
      }, {});
      this.state.params = params;
      const page = parseInt(params.p);
      if (page > 0) this.state.page = page;
      const filter = parseInt(params.f);
      if (filter >= 0) this.state.filter = filter;
    }
    this.state.requesting = true;
    this.list(this.state.page, this.state.filter, this.state.params);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.location.search !== nextProps.location.search) {
      const search = nextProps.location.search.substring(1);
      if (search) {
        const params = search.split('&').reduce(function(prev, curr, i, arr) {
          var p = curr.split('=');
          prev[p[0]] = decodeURIComponent(p[1]);
          return prev;
        }, {});
        nextState.params = params;
        const page = parseInt(params.p);
        if (page > 0) nextState.page = page;
        const filter = parseInt(params.f);
        if (filter >= 0) nextState.filter = filter;
      }else{
        nextState.params = {};
        nextState.page = 1;
        nextState.filter = 2;
      }
      this.list(nextState.page, nextState.filter, nextState.params);
      return true;
    }
    return true;
  }

  handlePagination = (offset) => {
    const page = 1 + offset / this.state.per;
    const params = {...this.state.params};
    params.p = page;
    params.f = this.state.filter;
    const qs = Object.keys(params).map((key) => {
      return key + '=' + encodeURIComponent(params[key])
    }).join('&');
    DataManager.getInstance().pub(Event.REDIRECT, {pathname: this.props.location.pathname, search: qs});
    // DataManager.getInstance().pub(Event.REDIRECT, {pathname: this.props.location.pathname, search: `${page}.${this.state.filter}`});
  };

  handleFilter = (e) => {
    const f = parseInt(e.target.value);
    // this.setState({filter: f, requesting: true});
    const params = {...this.state.params};
    params.p = 1;
    params.f = f;
    const qs = Object.keys(params).map((key) => {
      return key + '=' + encodeURIComponent(params[key])
    }).join('&');
    DataManager.getInstance().pub(Event.REDIRECT, {pathname: this.props.location.pathname, search: qs});
  };

  handleFilters = (params) => {
    params.p = this.state.page;
    params.f = this.state.filter;
    const qs = Object.keys(params).map((key) => {
      return key + '=' + encodeURIComponent(params[key])
    }).join('&');
    DataManager.getInstance().pub(Event.REDIRECT, {pathname: this.props.location.pathname, search: qs});
  };

  handleClick = (e) => {
    const index = parseInt(e.currentTarget.dataset.tag);
    const product = this.products[index];
    if (product.type !== 20 && this.wanted){
      DataManager.getInstance().pub(Event.REDIRECT, {pathname: `/products/e/${product.id}`, search: 'wanted'});
    }else{
      const params = product.ui ? product.ui : {};
      const keys = ['item_id', 'quality', 'size', 'material', 'material1', 'material2'];
      const ids = ['i', 'q', 's', 'm', 'm1', 'm2'];
      for (var i = 0; i < keys.length; ++i) {
        const key = keys[i];
        if (product[key])
          params[ids[i]] = product[key];
      }
      if (product.type === 20) {
        params['uid'] = product.uid;
      }
      const qs = Object.keys(params).map((key) => {
        return key + '=' + encodeURIComponent(params[key])
      }).join('&');
      let pn = this.props.location.pathname;
      if (pn.slice(-1) === '/') pn = pn.slice(0, -1);
      DataManager.getInstance().pub(Event.REDIRECT, {pathname: `${pn}/s/${product.id}`, search: qs});
    }
  };

  render() {
    if (!this.products)
      return <div></div>;

    const { classes } = this.props;

    return (
      <React.Fragment>
        <ProductDialog open={this.state.selected >= 0} onClose={this.deselect} product={this.state.selected >= 0 ? this.products[this.state.selected] : undefined} />
        <div className={classes.root}>
          {this.table()}
        </div>
      </React.Fragment>
    );
  }

  productBadge = (product) => {
    const { classes } = this.props;

    if (this.pid > 0 && product.ui.new > 0) {
      return <Typography className={classes.badgeNew}>{T.translate('products.valued')}</Typography>;
    }

    let n = 0;
    if (product.specs) {
      const ns = product.specs.split(';')[0];
      const ni = parseInt(ns);
      if (ni && ni > 0) n = ni;
    }
    if (n > 0) {
      return <Typography className={classes.badge3D}>3D</Typography>;
    }
    return undefined;
  }

  table = () => {
    const { classes } = this.props;
    const products = this.products;
    const is_project = this.pid > 0;

    const title = (product) => {
      if (DataManager.getInstance().mode === Mode.ADMIN && product.unit)
        return product.title + ' / ' + product.unit.display;
      if (is_project && product.price) {
        const price = priceDisplay(product.price, product.currency);
        if (price)
          return product.title + ' / ' + price;
      }
      return product.title;
    }

    return (
      <Grid alignItems="center" justify="center" container>
        <Grid item xs={12} sm={8} md={8} lg={6} xl={6}>
          {!(this.pid > 0) && 
          <React.Fragment>
            <Navigator current={this.state.filter} onChange={this.handleFilter} />
            {this.state.filter === 0 && this.labels ?
            <Filters labels={this.labels} qs={this.state.params} onChange={this.handleFilters} />
            :
            <div style={{visibility: 'inline', height: 69, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              <Button component={Link} to="/seller/discover/" endIcon={<ArrowIcon/>} variant="outlined" color="primary">
                {T.translate('units.discover')}
              </Button>
            </div>
            }
          </React.Fragment>
          }
          <div className={classes.container}>
            {products.length === 0 ?
              <Typography className={classes.content}>{T.translate('products.empty_list')}</Typography>
              :
              <GridList cols={2} cellHeight={250} spacing={12} className={classes.gridList}>
                <GridListTile key="Subheader" cols={2} style={{ height: 'auto' }}>
                  <ListSubheader component="div"></ListSubheader>
                </GridListTile>
                {products.map((product, index) => (
                  <GridListTile key={`${product.id}.${index}`} data-tag={index} className={classes.tile} onClick={this.handleClick}>
                    <div className={classes.image}>
                      <img className={classes.img} src={product.media[0]} alt={product.title} />
                    </div>
                    <GridListTileBar
                      // onClick={(e) => {e.stopPropagation();}}
                      title={title(product)}
                      subtitle={<span>{product.brand} / {product.item_type}</span>}
                      actionIcon={this.productBadge(product)}
                    />
                  </GridListTile>
                ))}
              </GridList>
            }
          </div>
          
          {!this.state.requesting && this.state.per > 0 && this.state.total > this.state.per &&
          <Pagination
            offset={this.state.per * (this.state.page - 1)}
            limit={this.state.per}
            total={this.state.total}
            onClick={this.handlePagination}
          />
          }
        </Grid>
      </Grid>
    );
  };

  list = (page, f, qs) => {
    const self = this;
    if (!this.state.requesting) this.setState({requesting: true});
    const params = {...qs, page: page, f: f, l: !!this.labels ? 0 : 1};
    const callback = function (res) {
      if (res.c === 0) {
        let d = res.d;
        self.products = d.ps;
        if (d.labels) self.labels = d.labels;
        self.setState({page: page, total: d.t, per: d.p, requesting: false});
      }
    };
    if (this.wanted || (this.state.filter === 2 && DataManager.getInstance().mode === Mode.BUSINESS)) {
      params.wanted = true;
      return DataManager.getInstance().list_user_products(params).then(callback);
    }else if (this.pid > 0) {
      params.pid = this.pid;
      return DataManager.getInstance().list_user_products(params).then(callback);
    }else
      return DataManager.getInstance().discover_products(params).then(callback);
  };
}


function Filters(props) {
  const { labels, qs } = props;
  const style = {padding: 16, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'};
  const keys = ['item_style', 'item_space', 'item_type', 'brand'];
  const def = {};
  keys.forEach((key) => def[key] = -1);

  const [selected, setSelected] = React.useState(def);
  React.useEffect(() => {
    if (!qs) return;
    const kv = {};
    let updated = false;
    let i;
    for (i = 0; i < keys.length; ++i) {
      const label = keys[i];
      if (qs.hasOwnProperty(label)) {
        const v = qs[label];
        kv[label] = labels[label].indexOf(v);
        if (kv[label] !== selected[label])
          updated = true;
      }
    }
    if (updated)
      setSelected(kv);
  }, [keys, labels, qs, selected]);

  const onChange = (e) => {
    const key = e.currentTarget.dataset.tag;
    const index = parseInt(e.target.value);
    const after = {...selected};
    after[key] = index;
    setSelected(after);
    const values = {};
    let i;
    for (i = 0; i < keys.length; ++i) {
      const k = keys[i];
      values[k] = after[k] >= 0 ? labels[k][after[k]] : '';
    }
    props.onChange(values);
  };
  
  const selects = [];
  let index;
  for (index = 0; index < keys.length; ++index) {
    const label = keys[index];
    if (labels.hasOwnProperty(label)) {
      const values = labels[label];
      if (!values || values.length === 0) continue;
      const items = [];
      let i;
      for (i = 0; i < values.length; ++i) {
        const item = 
        <MenuItem data-tag={label} key={i} value={i}><Typography align="center">{values[i]}</Typography></MenuItem>;
        items.push(item);
      }
      const select = 
      <Select
        key={label}
        inputProps={{'data-tag': label}}
        value={selected[label]}
        onChange={onChange}
        disableUnderline={true}
        variant="standard"
      >
        <MenuItem data-tag={label} key={-1} value={-1}><Typography align="center" >{T.translate(`products.${label}`)}</Typography></MenuItem>
        {items}
      </Select>;
      selects.push(select);
      selects.push(<div key={index} style={{width: 16}}></div>);
    }
  }

  return (
    <div style={style}>
      {selects}
    </div>
  );
}


export default withStyles(styles)(Products);