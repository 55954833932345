import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import T from 'i18n-react';


const useStyles = makeStyles(theme => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: theme.spacing(0, 1, 0, 1),
    padding: theme.spacing(1),
    backgroundColor: 'white',
    color: 'black'
  },
  title: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
}));


export default function Info(props) {
  const { project } = props;

  const classes = useStyles(props.theme);

  return (
    <Grid item xs={12} sm={8} md={8} lg={6} xl={6}>
      <Paper className={classes.paper} color="primary">
        <div className={classes.title}>
          <div style={{width: 64}}></div>
          <Typography variant="h5">
            {project.title}
          </Typography>
          <Button className={classes.btn} size="small" color="primary" component={Link} to={{pathname: `/projects/e/${project.id}`}}>
            {T.translate('projects.edit')}
          </Button>
        </div>
        <React.Fragment>
          <Typography>
            {project.address}
          </Typography>
        </React.Fragment>
      </Paper>
    </Grid>
  )
}
