/**
 * Created by milan on 2019/7/18.
 */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import T from 'i18n-react';
import { DataManager } from '../models';
import { Link } from 'react-router-dom';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
const ProjectIcon = require('../../images/projects.png');


const useStyles = makeStyles(theme => ({
  box: {
    // padding: theme.spacing(1, 3, 1, 3)
  },
  titleBar: {
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1),
    position: 'relative'
  },
  title: {
    width: '100%',
    minHeight: 48,
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(0.5)
    }
  },
  closeButton: {
    padding: 0
  },
}));


function CollectToProjects(props) {
  const classes = useStyles(props.theme);

  const { project_id, path, product, index, quality, size, codes, titles, material2 } = props;
  const [open, setOpen] = React.useState(false);
  const [projects, setProjects] = React.useState(null);
  const item = index >= 0 ? product.items[index] : {};

  const onClick = e => {
    getProjects(e);
  };

  const onClose = e => {
    setOpen(false);
  };

  const getProjects = e => {
    const params = {page: 1, f: 0, s: 1, pid: product.id};
    if (index >= 0) params.iid = product.items[index].item_id;
    if (codes) params.material1 = codes;
    if (material2) params.material2 = material2;
    if (titles) params.material = titles;
    if (size) params.size = size;
    if (quality) params.quality = quality;

    DataManager.getInstance().list_projects(params).then(function (res) {
      if (res.c === 0) {
        const projects = res.d.projects;
        if (projects.length > 1 && project_id) {
          const pid = parseInt(project_id);
          let i;
          for (i = 1; i < projects.length; ++i) {
            const project = projects[i];
            if (project.id === pid) {
              projects.splice(i, 1);
              projects.splice(0, 0, project);
              break;
            }
          }
        }
        setProjects(projects);
        setOpen(true);
      }
    })
  };

  const toggleFavor = e => {
    const index = parseInt(e.currentTarget.dataset.tag);
    const project = projects[index];

    const params = {pid: product.id, project_id: project.id};
    if (index >= 0) params.iid = item.item_id;
    if (codes) params.material1 = codes;
    if (material2) params.material2 = material2;
    if (titles) params.material = titles;
    if (size) params.size = size;
    if (quality) params.quality = quality;

    DataManager.getInstance().collect_product(params).then(function (res) {
      if (res.c === 0) {
        project.vi = !project.vi;
        setProjects([...projects]);
      }
    });
  };
  const permitted = DataManager.getInstance().permit('Price');

  return (
    <React.Fragment>
      <Box key="material" className={classes.box}>
        <div style={{position: 'relative'}}>
          {item.price1 || !permitted ?
          <IconButton aria-label="favor" onClick={onClick} style={{position: 'absolute', bottom: -8}}>
            <img style={{width: 24, height: 24}} alt="inquiry" src={ProjectIcon} />
          </IconButton>
          :
          <Button variant="outlined" color="primary" size="small" onClick={onClick}>
            {T.translate('products.request_value')}
          </Button>
          }
        </div>
        
        <Dialog open={open} onClose={onClose} fullWidth aria-labelledby="dialog-title">
          <div className={classes.titleBar}>
            <Box width={56} />
            <Box className={classes.title}>
              <Typography align="center" variant="h5">{product.title}</Typography>
              <Typography align="center" component="p" variant="subtitle1">{quality ? `${T.translate(`quality.${quality}`)}, ` : ''}{size ? `${size}, ` : ''}{titles}</Typography>
            </Box>
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
              <CloseIcon style={{width: 48, height: 48}} />
            </IconButton>
          </div>
          <DialogContent>
            {projects === null ?
              <Box className={classes.title} align="center">
                <CircularProgress size={48} />
              </Box>
              :
              (projects.length === 0 ?
                <Typography align="center" component="p" className={classes.title}>{T.translate('projects.empty_list')}</Typography>
                :
                <List className={classes.list}>
                  {projects.map((project, index) => (
                    <ListItem key={project.id} alignItems="center" divider={true} >
                      <ListItemText
                        component="div"
                        primary={project.title}
                        secondary={project.address}
                      />
                      <ListItemSecondaryAction>
                        <Button
                          data-tag={index}
                          variant={project.vi ? 'contained' : 'outlined'}
                          color={project.vi ? 'secondary' : 'primary'}
                          size="small"
                          onClick={toggleFavor}
                        >
                          {T.translate(project.vi ? 'products.collected' : (item.price1 || !permitted ? 'products.collect' : 'products.collect_and_inquiry'))}
                        </Button>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                  <div className={classes.titleBar}>
                    <Link to={{pathname: '/projects/c/', search: path}}>{T.translate('projects.new')}</Link>
                  </div>
                </List>
              )
            }
          </DialogContent>
        </Dialog>
      </Box>
    </React.Fragment>
  );
}


export default CollectToProjects;