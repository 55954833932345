import React,  { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import Home from './Home';
import Landing from './Landing';
import { Mode, DataManager } from './models/';

const styles = theme => ({
  root: {

  }
});

class Main extends Component {

  render() {
    const authed = DataManager.getInstance().mode >= Mode.BUSINESS;
    return (
      authed ? <Home {...this.props} /> : <Landing {...this.props} />
    )
  }
}

export default withRouter(withStyles(styles)(Main));
