/**
 * Created by milan on 2019/7/18.
 */
import React, { Component } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import T from 'i18n-react';
import { DataManager } from '../models';

import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Paper from '@material-ui/core/Box';
import { RestrictedCell } from './Cell';
import Pagination from '../common/Pagination';
import Filters from './Filters';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';


const styles = theme => ({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
    marginTop: theme.spacing(2),
    padding: theme.spacing(1)
  },
  paper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  btns: {
    // marginBottom: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  list: {
    width: '100%'
  },
  content: {
    fontSize: 24,
    margin: theme.spacing(2)
  },
  pagination: {
    marginTop: theme.spacing(5)
  },
  closeButton: {
    width: 64,
    height: 64,
    position: 'absolute',
    padding: theme.spacing(0),
    right: theme.spacing(0),
    top: theme.spacing(0),
    color: theme.palette.grey[500]
  }
});


class Customizer extends Component {
  state = {
    params: {p: 1}, filters: {}, 
    total: 1, page: 1, per: 0, index: 0,
    chosen: [], requesting: true, friendly: true
  };
  materials = null;

  constructor(props) {
    super(props);
    if (props.index >= 0) {
      let i;
      const product = props.product;
      if (!product.labels) {
        product.labels = [];
        product.chosenLabels = [];
        for (i = 0; i < product.customized.length; ++i) {
          product.labels.push(undefined);
          product.chosenLabels.push(undefined);
        }
      }
      const chosen = [];
      for (i = 0; i < product.customized.length; ++i) {
        const mat = product.customized[i];
        chosen.push(mat ? mat.identifier : undefined);
        if (product.labels.length < product.customized.length)
          product.labels.push(undefined);
      }
      this.state.chosen = chosen;
      this.state.index = props.index;
    }
    this.state.params.index = this.state.index;
    this.list(this.state.params);
  }

  shouldComponentUpdate(props, nextState) {
    if (props.index >= 0) {
      const product = props.product;
      const chosen = [];
      for (var i = 0; i < product.customized.length; ++i) {
        const mat = product.customized[i];
        chosen.push(mat ? mat.identifier : undefined);
      }
      nextState.chosen = chosen;
      nextState.index = props.index;
    }
    return true;
  }

  handlePagination = (offset) => {
    const page = 1 + offset / this.state.per;
    const params = {...this.state.params};
    params.f = this.state.friendly ? 1 : 0;
    params.p = page;
    params.index = this.state.index;
    this.setState({params: params});
    this.list(params);
  };

  handleFilters = (chosen) => {
    const product = this.props.product;
    const index = this.state.index;
    product.chosenLabels[index] = chosen;

    const params = {...this.state.params};
    params.f = this.state.friendly ? 1 : 0;
    params.p = 1;
    params.index = index;

    this.setState({params: params});
    this.list(params);
  }

  handleConfig = (e) => {
    this.setState({friendly: !!e.target.checked});
  }

  onChange = (material) => {
    const product = this.props.product;
    const index = this.state.index;

    const chosen = [...this.state.chosen];
    const id = material.identifier;
    if (chosen[index] === id) {
      chosen[index] = undefined;
      product.customized[index] = undefined;
      this.setState({chosen: chosen});
    }else{
      chosen[index] = id;
      product.customized[index] = material;
      this.props.onClose();
    }
    // if (this.props.onChange)
    //   this.props.onChange(chosen, this.titles);
  }

  handleIndex = (e) => {
    const index = parseInt(e.currentTarget.dataset.tag);
    const params = {...this.state.params};
    params.f = this.state.friendly ? 1 : 0;
    params.p = 1;
    params.index = index;
    this.setState({params: params, index: index});
    this.list(params);
  }

  render() {
    if (!this.materials) return <div></div>;
    const { index, product, classes, dialog, onClose } = this.props;
    // const self = this;
    const labels = product.labels[this.state.index];

    return (
      <React.Fragment>
        {!dialog ?
        <div className={classes.root}>
          {this.table()}
        </div>
        :
        <Dialog open={index >= 0} onClose={onClose} fullWidth maxWidth='md'>
          <DialogTitle style={{textAlign: "center"}}>
            {product.customizer[this.state.index]}
            {product.customized[this.state.index] ? ': ' + product.customized[this.state.index].title : ''}
            <React.Fragment>
              {labels && <Filters labels={labels} qs={product.chosenLabels[this.state.index]} onChange={this.handleFilters} />}
            </React.Fragment>
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
              <CloseIcon style={{width: 48, height: 48}} />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            {this.table()}
          </DialogContent>
          <DialogActions>
            {/* <Box className={classes.btns} style={{width: '100%'}}>
            {product.customizer && product.customizer.map((customizer, index) => (
              <Button 
                key={index}
                data-tag={index}
                variant={self.state.index === index ? 'contained' : 'outlined'}
                color="primary"
                size="small"
                style={{margin: 8}}
                onClick={this.handleIndex}
              >
                {product.customized[index] ? `${customizer}: ${product.customized[index].title}` : customizer}
              </Button>
            ))}
            </Box>
            <Button onClick={onClose} variant="outlined" color="secondary">
              {T.translate('buttons.close')}
            </Button> */}
          </DialogActions>
        </Dialog>
        }
      </React.Fragment>
    );
  }

  table = () => {
    const { classes } = this.props;
    const materials = this.materials;

    return (
      <Box>
        <Paper className={classes.paper}>
          {this.state.requesting && materials.length === 0 ?
            <CircularProgress size={48} />
            :
            <GridList cols={2} cellHeight={250} spacing={12} className={classes.list}>
              {materials.length === 0 &&
                <Typography style={{width: '100%', textAlign: 'center'}} className={classes.content}>{T.translate('materials.empty_list')}</Typography>
              }
              {materials.map((material, index) => {
                return <GridListTile key={material.identifier} cols={1} style={{cursor: 'pointer'}}>
                  <RestrictedCell
                    key={material.identifier || material.id || `mat-${index}`}
                    material={material}
                    cols={1}
                    selected={this.state.chosen}
                    onChange={this.onChange}
                  />
                </GridListTile>
              })}
            </GridList>
          }
        </Paper>
        {!this.state.requesting && this.state.per > 0 && this.state.total > this.state.per &&
        <Pagination
          offset={this.state.per * (this.state.params.p - 1)}
          limit={this.state.per}
          total={this.state.total}
          onClick={this.handlePagination}
        />
        }
      </Box>
    );
  };

  list = (query) => {
    const product = this.props.product;
    const index = query.index;
    let chosen = product.customized[index];
    const self = this;
    const params = {...query};
    if (product.chosenLabels[index]) {
      const cl = product.chosenLabels[index];
      for (var k in cl) {
        if (cl.hasOwnProperty(k)) {
          params[k] = cl[k];
          if (cl[k]) chosen = undefined;
        }
      }
    }
    if (chosen && !chosen.kingdom) params.crt = chosen.identifier;
    if (!product.labels[index]) params.l = 1;
    if (!this.state.requesting) this.setState({requesting: true});
    DataManager.getInstance().list_materials_by_product(product.id, params).then(function (res) {
      if (res.c === 0) {
        const d = res.d;
        if (d.labels) product.labels[index] = d.labels;
        const mats = d.mats;
        let f = -1;
        for (var i = 0; i < mats.length; ++i) {
          const mat = mats[i];
          if (!mat.items)
            mat.items = [];
          if (chosen && chosen.identifier === mat.identifier)
            f = i;
        }
        if (f >= 0) {
          chosen = mats[f];
          product.customized[index] = chosen;
          mats.splice(f, 1);
        }
        if (chosen && chosen.kingdom)
          mats.splice(0, 0, chosen);
        self.materials = mats;
        const update = {params: query, total: d.t, per: d.p, requesting: false};
        self.setState(update);
      }
    })
  };
}


const MaterialCustomizer = withStyles(styles)(Customizer);


function MaterialsDisplay(props) {
  const { product, onSave } = props;
  
  const [open, setOpen] = React.useState(-1);
  // const [value, setValue] = React.useState(-1);

  function handleClickOpen(e) {
    const index = parseInt(e.currentTarget.dataset.tag);
    setOpen(index);
  }

  function handleClose(e) {
    setOpen(-1);
    onSave({});
  }

//   <Button 
//   key={0}
//   data-tag={0}
//   variant="outlined"
//   color="primary"
//   size="small"
//   // style={{margin: 8}}
//   onClick={handleClickOpen}
// >
//   {T.translate('products.select_item')}
// </Button>
  return (
    <div onClick={(e) => e.stopPropagation()} style={{display: 'inline-block'}}>
      <Typography component="span" variant="subtitle1"></Typography>
      {product.customizer && product.customizer.map((customizer, index) => (
        <Button 
          key={index}
          data-tag={index}
          variant={product.customized[index] ? 'contained' : 'outlined'}
          color="primary"
          size="small"
          style={{margin: 5}}
          onClick={handleClickOpen}
        >
          {product.customized[index] ? `${customizer}: ${product.customized[index].title}` : customizer}
        </Button>
      ))}
      
      {open >= 0 &&
      <MaterialCustomizer
        key={product.material1}
        product={product}
        dialog={true}
        index={open}
        onClose={handleClose}
      />
      }
    </div>
  );
}

const MaterialsCustomizer = withStyles(styles)(MaterialsDisplay);

export { MaterialsCustomizer, MaterialCustomizer };