/**
 * Created by milan on 2019/7/18.
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/styles/withStyles';
import Button from '@material-ui/core/Button';
import { TextField } from 'formik-material-ui';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import { Formik, Form, Field } from 'formik';
import T from 'i18n-react';
import { Event, DataManager } from '../models/';
import TitleBar from '../common/TitleBar';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { priceDisplay } from '../common/Constants';


const styles = theme => ({
  paper: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    //display: 'flex',
    //flexDirection: 'column',
    //alignItems: 'center',
    padding: theme.spacing(2)
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    paddingTop: theme.spacing(1)
  },
  input: {
    //marginTop: theme.spacing(6)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
});

class Payment extends Component {
  id = -1;
  order = null;

  constructor(props) {
    super(props);
    this.state = {show: false};

    let id = props.match.params.id;
    if (id) this.id = parseInt(id);
    this.show();
  }

  show = () => {
    let self = this;
    DataManager.getInstance().show_order(this.id, null).then(function (res) {
      if (res.c === 0) {
        self.order = res.d.d;
        self.setState({show: true});
      }
    });
  };

  save = (values, actions) => {
    let path = '/' + this.props.match.path.split('/')[1];
    DataManager.getInstance().user_order(this.id, values).then(function (res) {
      let errors = {};
      if (res.c !== 0) {
        errors.oid = T.translate('errors.orders.' + res.c);
        actions.setSubmitting(false);
        if (Object.keys(errors).length) {
          actions.setErrors(errors);
        }
        return;
      }
      DataManager.getInstance().pub(Event.REDIRECT, path);
    });
  };

  validate = values => {
    let errors = {};
    //if (!values.oid) {
    //  errors.oid = T.translate('errors.required');
    //}
    if (Object.keys(errors).length)
      return errors;

  };

  render() {
    if (!this.state.show) {
      return <div></div>;
    }

    const { classes } = this.props;
    const trans = this.order.due;

    return (
      <div>
        <TitleBar title={{title: T.translate('orders.payment.info')}}>
        </TitleBar>
        <Container component="main" maxWidth="xs" className={classes.root}>
          <Paper className={classes.paper}>
            <Box className={classes.row}>
              <Typography component="span" color="textPrimary" >
                {T.translate('orders.payment.receiver_name')}:
              </Typography>
              <Typography component="span" color="primary" >
                {trans.name}
              </Typography>
            </Box>
            <Box className={classes.row}>
              <Typography component="span" color="textPrimary" >
                {T.translate('orders.payment.receiver_num')}:
              </Typography>
              <Typography component="span" color="primary" >
                {trans.num}
              </Typography>
            </Box>
            <Box className={classes.row}>
              <Typography component="span" color="textPrimary" >
                {T.translate('orders.payment.receiver_bank')}:
              </Typography>
              <Typography component="span" color="primary" >
                {trans.bank}
              </Typography>
            </Box>
            <Box className={classes.row}>
              <Typography component="span" color="textPrimary" >
                {T.translate('orders.payment.receiver_amount')}:
              </Typography>
              <Typography component="span" color="primary" >
                {priceDisplay(trans.amount, trans.currency)}
              </Typography>
            </Box>

            <Typography component="p" color="textPrimary" style={{marginTop: 32}} >
              {T.translate('orders.payment.after_transfer')}
            </Typography>
            <Formik
              initialValues={{ oid: trans.external || '' }}
              validate={this.validate}
              validateOnChange={false}
              onSubmit={this.save}
            >
              {props => {
                return (
                  <Form className={classes.form} noValidate>

                    <Field
                      component={TextField}
                      error={!!props.errors.oid}
                      helperText={props.errors.oid}
                      className={classes.input}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="oid"
                      label={T.translate('orders.payment.external_order_id')}
                      placeholder={T.translate('orders.payment.external_tranaction_info')}
                      name="oid"
                      autoComplete="oid"
                      autoFocus
                    />

                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      disabled={props.isSubmitting}
                    >
                      {T.translate('buttons.submit')}
                    </Button>
                  </Form>
                );
              }}
            </Formik>
          </Paper>
        </Container>
      </div>
    )
  }
}

export default withRouter(withStyles(styles)(Payment));