/**
 * Created by milan on 2019/7/18.
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/styles/withStyles';
import T from 'i18n-react';
import { Mode, Event, DataManager } from '../models/';
import ItemCell from './ItemCell';
import ProductDialog from '../Product/ProductDialog';
import OrderCell from './Cell';
import SellerActions from './SellerActions';
import UserActions from './UserActions';

import { priceDisplay } from '../common/Constants';
import Seller from '../common/Seller';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import List from '@material-ui/core/List';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';

import InputAdornment from '@material-ui/core/InputAdornment';
import SendIcon from '@material-ui/icons/Send';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';


const styles = theme => ({
  root: {
    //position: 'relative'
    marginBottom: 100
  },
  header: {
    position: 'fixed',
    padding: theme.spacing(0, 2, 0, 2),
    height: 64,
    bottom: 24,
    zIndex: 101,
    width: '100%',
    maxWidth: 'inherit',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  list: {
    width: '100%'
  },
  label: {
    fontSize: 14,
    [theme.breakpoints.down('xs')]: {
      maxWidth: 130
    }
  },
  info: {
    fontSize: 14
  },
  importantInfo: {
    fontSize: 18
  },
  block: {
    backgroundColor: 'white',
    padding: theme.spacing(1, 1, 1, 1),
    margin: theme.spacing(2, 0, 2, 0)
  },
  blockLine: {
    padding: theme.spacing(0.5, 0, 0, 0),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'start'
  },
  blockLabel: {
    marginLeft: 6,
    fontSize: 15,
    textDecoration: 'none',
    [theme.breakpoints.down('xs')]: {
      maxWidth: 130
    }
  },
  textBox: {
    padding: theme.spacing(0.5, 0, 0, 0),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
});


class Show extends Component {
  id = -1;
  order = null;
  highlight_keys = ['total', 'paid', 'received'];
  keys = ['subtotal', 'shipping', 'install', 'miscellaneous', 'service', 'tax', 'total', 'paid'];
  desc = ['miscellaneous', 'service', 'tax'];

  constructor(props) {
    super(props);

    this.state = {
      show: false,
      expanded: true,
      requesting: false,

      selected: -1,
      memo: null
    };

    let id = props.match.params.id;
    if (id) this.id = parseInt(id);
    if (this.id > 0) {
      this.show();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.match.params.id !== nextProps.match.params.id) {
      let id = nextProps.match.params.id;
      if (id) this.id = parseInt(id);
      if (this.id > 0) {
        this.show();
      }
    }
    return true;
  }

  show = () => {
    let self = this;
    DataManager.getInstance().show_order(this.id, null).then(function (res) {
      if (res.c === 0) {
        self.order = res.d.d;
        self.setState({show: true, memo: self.order.memo});
        if (self.order.type === 10)
          self.expand_item(self.order);
      }
    });
  };

  expand_item = (order) => {
    if (order.sub_orders) {
      order._expanded = !order._expanded;
      this.setState({expanded: !this.state.expanded});
    }else{
      const self = this;
      DataManager.getInstance().sub_orders(order.id, null).then(function (res) {
        if (res.c === 0) {
          let d = res.d;

          const orders = d.orders;
          for (var i = 0; i < orders.length; ++i) {
            const order = orders[i];
            order._subtotal = order.subtotal;
            order._total = order.total;
            order._paid = order.paid;
            order._received = order.received;

            order.subtotal = priceDisplay(order.subtotal, order.currency);
            order.total = priceDisplay(order.total, order.currency);
            order.paid = priceDisplay(order.paid, order.currency);
            order.received = priceDisplay(order.received, order.currency);
          }
          order.sub_orders = orders;
          order._expanded = !order._expanded;
          self.setState({expanded: !self.state.expanded});
        }
      })
    }
  };

  show_sub_order = (order) => {
    this.id = order.id;
    order.subtotal = order._subtotal;
    order.total = order._total;
    order.paid = order._paid;
    order.received = order._received;
    this.order = order;
    const path = '/' + this.props.match.path.split('/')[1];
    DataManager.getInstance().pub(Event.REDIRECT, `${path}/s/${order.id}`);
  };

  on_memo = () => {
    const self = this;
    let params = {memo: this.state.memo};
    self.setState({requesting: true});
    if (DataManager.getInstance().mode < Mode.BUSINESS)
      DataManager.getInstance().user_order(this.id, params).then(function (res) {
        if (res.c === 0) {
          DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'success', m: T.translate('notification.updated')});
        }
        self.setState({requesting: false});
      });
  };

  selected = (id, index) => {
    if (DataManager.getInstance().mode === Mode.BUSINESS)
      DataManager.getInstance().pub(Event.REDIRECT, `/orders/trackers/${this.order.id}/${id}`);
    else
      this.setState({selected: index});
  };

  deselect = () => {
    this.setState({selected: -1});
  };

  render() {
    if (!this.state.show) return <React.Fragment/>;
    //const { classes } = this.props;

    return (
      <React.Fragment>
        <ProductDialog open={this.state.selected >= 0} onClose={this.deselect} product={this.state.selected >= 0 ? this.order.items[this.state.selected] : undefined} />
        {this.table()}
      </React.Fragment>
    )
  }

  table() {
    const { classes } = this.props;
    const order = this.order;
    const self = this;
    const is_seller = DataManager.getInstance().mode === Mode.BUSINESS;
    const keys = this.order.type !== 2 ? this.keys : (is_seller ? ['total', 'received'] : ['total']);
    const OrderState = DataManager.getInstance().states.items;
    const permitted = DataManager.getInstance().permit('Price');

    return (
      <Grid alignItems="center" justify="center" container>
        <Grid item xs={12} sm={8} md={8} lg={6} xl={6} className={classes.root}>
          <Block classes={classes} title={T.translate('orders.about')}>
            {!is_seller && order.type === 2 &&
            <Box className={classes.blockLine}>
              <Typography component="span" variant="caption">{T.translate('orders.seller')}: </Typography>
              <Box width={28} />
              <Seller seller_id={order.seller_id} title={order.seller} />
            </Box>
            }
            <Box className={classes.blockLine}>
              <Typography component="span" variant="caption">{T.translate('orders.identifier')}: </Typography>
              <Typography component="p" variant="body1" className={classes.blockLabel}>{order.identifier}</Typography>
            </Box>
            <Box className={classes.blockLine}>
              <Typography component="span" variant="caption">{T.translate('orders.shipping_info.address')}: </Typography>
              <Typography component="p" variant="body1" className={classes.blockLabel}>{order.address}</Typography>
            </Box>
            <Box className={classes.blockLine}>
              <Typography component="span" variant="caption">{T.translate('orders.create_at')}: </Typography>
              <Typography component="p" variant="body1" className={classes.blockLabel}>{order.create_at}</Typography>
            </Box>
            <Box className={classes.blockLine}>
              <Typography component="span" variant="caption">{T.translate('orders.update_at')}: </Typography>
              <Typography component="p" variant="body1" className={classes.blockLabel}>{order.update_at}</Typography>
            </Box>
            <Box className={classes.blockLine}>
              <Typography component="span" variant="caption">{T.translate('orders.memo')}: </Typography>
              <Box width={is_seller ? 24 : 30} />
              {is_seller ?
              <Typography component="p" variant="body1" className={classes.blockLabel}>{order.memo}</Typography>
                :
              <TextField
                onChange={(e) => {
                  self.setState({memo: e.target.value});
                }}
                defaultValue={self.state.memo}
                margin="dense"
                type="text"
                style={{width: 280}}
                InputProps={{
                  endAdornment: <InputAdornment position="end">
                    <IconButton
                      style={{padding: 3}}
                      disabled={this.state.requesting || this.state.memo === order.memo}
                      type="submit"
                      color="primary"
                      onClick={this.on_memo}
                    >
                      <SendIcon />
                    </IconButton>
                  </InputAdornment>
                }}
              />
              }
            </Box>
            <Box height={16}/>
          </Block>

          <Block classes={classes} title={`${order.items.length} ${T.translate('deals.unit')}, ${T.translate('orders.total_items', {c: order.quantity})}`}>
            <List className={classes.list}>
              {order.items.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      <ItemCell
                        key={item.id}
                        item={item}
                        tracking={is_seller || (order.state >= OrderState.Shipping && order.state <= OrderState.Rated)}
                        hidePrice={!permitted}
                        divider={index < order.items.length - 1}
                        onClick={is_seller ? () => self.selected(item.id, index) : undefined}
                        onImageClick={!is_seller ? () => self.selected(item.id, index) : undefined}
                      />
                    </React.Fragment>
                  )
                }
              )}
            </List>
          </Block>
          {permitted &&
          <Block classes={classes} title={T.translate('orders.payments_details')}>
            {keys.map((key, index) => (
              order[key] ?
              <Box key={key} className={classes.textBox}>
                <Typography component="span" variant="caption">{T.translate(`orders.${key === 'received' ? 'seller_received' : key}`)}
                  {/* {this.desc.indexOf(key) >= 0 &&
                  <InfoButton text={T.translate(`orders.${key}_desc`, {s: key === 'service' ? DataManager.getInstance().storage.config.sv : `${order.tax_rate}%`})} />
                  } */}
                </Typography>
                <Typography component="span" className={this.highlight_keys.indexOf(key) >= 0 ? classes.importantInfo : classes.info}>
                  {priceDisplay(order[key] || '0', order.currency)}
                </Typography>
              </Box>
              :
              undefined
            ))}
          </Block>
          }
          {!is_seller && order.type === 10 &&
          <Block classes={classes} title={T.translate('projects.orders')}>
            <List className={classes.list}>
              {order._expanded && order.sub_orders && order.sub_orders.map((sub, index) =>
                <React.Fragment key={index}>
                  {index > 0 && <Divider component="li"/>}
                  <OrderCell skipCard={true} key={sub.id} order={sub} index={index} onClick={e => self.show_sub_order(sub)} />
                </React.Fragment>
              )}
            </List>
          </Block>
          }

          <Paper className={classes.header}>
            {is_seller ?
              <SellerActions key={order.id} order={order} show={this.show} />
            :
              <UserActions key={order.id} order={order} show={this.show} />
            }
          </Paper>
        </Grid>
      </Grid>
    );
  }
}


function Block(props) {
  const { classes } = props;

  return (
    <Box className={classes.block} alignItems="center">
      <Typography variant="subtitle2">{props.title}</Typography>
      {props.children}
    </Box>
  )
}


export default withRouter(withStyles(styles)(Show));