/**
 * Created by milan on 2019/7/18.
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/styles/withStyles';
import { TextField } from 'formik-material-ui';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import { Formik, Form, Field } from 'formik';
import T from 'i18n-react';
import { DataManager } from '../models/';
import Uploader from './Upload';

import { ItemModel } from '../common/Constants';
import Button from '@material-ui/core/Button';

import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Dialog from '@material-ui/core/Dialog';
import { Materials } from '../Material/Picker';


const styles = theme => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2)
  },
  form: {
    //paddingTop: theme.spacing(4),
    width: '100%' // Fix IE 11 issue.
  },
  btnLeft: {
    margin: theme.spacing(0, 1, 0, 0)
  },
  btn: {
    margin: theme.spacing(0, 0, 0, 1)
  },
  show: {
  },
  hide: {
    display: 'none'
  },
  menu: {
    width: 200
  },
  center: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
});

class Editor extends Component {
  Model = null;
  state = {optional: false};

  constructor(props) {
    super(props);
    this.Model = ItemModel;
  }

  handleSwitch = () => {
    this.setState({optional: !this.state.optional});
  };

  validate = values => {
    let errors = {};
    this.Model.forEach(function (property, _) {
      const key = property.k;
      if (property.r && !values[key]) {
        errors[key] = T.translate('errors.required');
      }
    });
    if (values.price1 && values.price2 && parseInt(values.price2) < parseInt(values.price1)) {
      errors.price2 = '零售价低于单价';
      if (!this.state.optional) this.setState({optional: true});
    }

    if (Object.keys(errors).length) {
      return errors;
    }

    const product = this.props.product;
    let f = this.props.index < 0;
    if (f && product && product.items) {
      let i;
      for (i = 0; i < product.items.length; ++i) {
        const itm = product.items[i];
        if (itm.id !== this.props.item.id && itm.material === values.material && itm.size === values.size && itm.quality === values.quality) {
          f = false;
          break;
        }
      }
      if (!f) {
        errors.material = T.translate('errors.products.duplicated_item');
        errors.size = T.translate('errors.products.duplicated_item');
        errors.quality = T.translate('errors.products.duplicated_item');
      }
    }
    if (Object.keys(errors).length) {
      return errors;
    }
  };

  submit = (values, _) => {
    const item = {};
    this.Model.forEach(function (property, _) {
      const key = property.k;
      item[key] = property.t === 'text' ? values[key].trim() : values[key];
    });
    item.id = this.props.item.id;
    item.media = this.props.item.media.join(';');
    item.material1 = this.props.item.material1;
    item.material2 = this.props.item.material2;

    return this.save_item(item);
  };

  save_item = (item) => {
    const self = this;
    return DataManager.getInstance().save_item(this.props.product_id, item).then(function (res) {
      if (res.c !== 0) {
        return;
      }
      const saved = res.d.item;
      //self.props.item.id = saved.id;
      //self.Model.forEach(function (property, _) {
      //  const key = property.k;
      //  self.props.item[key] = saved[key];
      //});
      //self.props.item.media = saved.media;
      let k;
      for (k in saved) {
        if (saved.hasOwnProperty(k)) {
          self.props.item[k] = saved[k];
        }
      }
      self.props.onClose(self.props.item);
    });
  };

  cancel = () => {
    this.props.onClose(null);
  };

  render() {
    const { item, classes } = this.props;
    if (!item || !this.props.open) return <React.Fragment/>;

    const initialValues = {};
    this.Model.forEach(function (property, _) {
      initialValues[property.k] = item[property.k] || property.d;
    });

    const self = this;
    const title = this.props.index < 0 ? T.translate('products.new_item') : `${T.translate(`products.item`)}${this.props.index + 1}`;

    return (
      <Dialog
        PaperProps={{style: {backgroundColor: '#fafafa'}}}
        fullScreen={true}
        open={this.props.open}
        onClose={this.cancel}
        aria-labelledby="item-editor"
        aria-describedby="item-editor-description"
        scroll='paper'
      >
        <Uploader product={item} search={this.props.search} />

        <Container component="main" maxWidth="xs" className={classes.root}>
          <Paper className={classes.paper}>

            <Grid container justify="center">
              <Grid item xs={4} sm={4} md={2} className={classes.center}>
                <Switch
                  checked={this.state.optional}
                  onChange={this.handleSwitch}
                  value="switcher"
                  color="primary"
                />
              </Grid>
              <Grid item xs={4} sm={4} md={8} className={classes.center}>
                <Typography align="center" variant="h6">{title}</Typography>
              </Grid>
              <Grid item xs={4} sm={4} md={2} className={classes.center}>
              </Grid>
            </Grid>

            <Formik
              initialValues={initialValues}
              validate={this.validate}
              //validateOnBlur={true}
              onSubmit={this.submit}
            >
              {props => {
                return (
                  <Form className={classes.form} noValidate>
                    <FormControl
                      key="mats"
                      error={!!props.errors['material']}
                      required={true}
                      className={classes.show}
                      variant="standard"
                      margin="dense"
                      fullWidth
                    >
                      <InputLabel htmlFor="mats" shrink={true}>
                        {T.translate('products.material')}
                      </InputLabel>
                      <div
                        style={{paddingTop: 20}}
                        id="mats"
                      >
                        <Materials
                          item={item}
                          product={self.props.product}
                          onChange={(newValue) => {
                            props.setFieldValue('material', newValue);
                          }}
                        />
                      </div>
                      {!!props.errors['material'] && <FormHelperText>{props.errors['material']}</FormHelperText>}
                    </FormControl>
                    {self.Model.map((property, index) => (
                      property.select ?
                        <FormControl
                          key={index}
                          error={!!props.errors[property.k]}
                          required={property.r}
                          className={!property.r && !self.state.optional ? classes.hide : classes.show}
                          variant="standard"
                          margin="dense"
                          fullWidth
                        >
                          <InputLabel htmlFor={property.k} shrink={true}>
                            {T.translate(`products.${property.tk}`)}
                          </InputLabel>
                          <Select
                            value={props.values[property.k]}
                            onChange={(e) => {
                              props.setFieldValue(property.k, e.target.value);
                            }
                          }
                            type={property.t}
                            name={property.k}
                            autoComplete={property.k}
                            inputProps={{
                            name: property.k,
                            id: property.k
                          }}
                          >
                            {property.select.map(option => (
                              <MenuItem key={option.k} value={option.v}>
                                {T.translate(`${property.k}.${option.k}`)}
                              </MenuItem>
                            ))}
                          </Select>
                          {!!props.errors[property.k] && <FormHelperText>{props.errors[property.k]}</FormHelperText>}
                        </FormControl>
                        :
                        <Field
                          component={TextField}
                          key={index}
                          error={!!props.errors[property.k]}
                          helperText={props.errors[property.k]}
                          variant="standard"
                          margin="dense"
                          fullWidth
                          required={property.r}
                          id={property.k}
                          name={property.k}
                          autoComplete={property.k}
                          type={property.t}
                          className={property.o && !self.state.optional ? classes.hide : classes.show}
                          label={T.translate(`products.${property.tk}`)}
                        />
                    ))}
                    <Grid container justify="center">
                      <Grid item xs={6} className={classes.center}>
                        <Button
                          type="button"
                          fullWidth
                          variant="contained"
                          color="secondary"
                          className={classes.btnLeft}
                          disabled={props.isSubmitting}
                          onClick={self.cancel}
                        >
                          {T.translate(`products.cancel`)}
                        </Button>
                      </Grid>
                      <Grid item xs={6} className={classes.center}>
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          color="primary"
                          className={classes.btn}
                          disabled={props.isSubmitting}
                        >
                          {T.translate(`products.submit`)}
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </Paper>
        </Container>
      </Dialog>
    )
  }
}


export default withRouter(withStyles(styles)(Editor));