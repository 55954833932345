import React,  { Component } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import Paper from '@material-ui/core/Box';

import { Mode, Event, DataManager } from '../models/';
import Cell from './Cell';

import T from 'i18n-react';
import Pagination from '../common/Pagination';
import CircularProgress from '@material-ui/core/CircularProgress';

import { Link } from 'react-router-dom';
import ResponsiveButton from '../buttons/ResponsiveButton';


const styles = theme => ({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
    marginTop: theme.spacing(2),
    padding: theme.spacing(1)
  },
  paper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  btns: {
    marginBottom: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  list: {
    width: '100%'
  },
  content: {
    fontSize: 24,
    margin: theme.spacing(2)
  },
  pagination: {
    marginTop: theme.spacing(5)
  }
});


class Projects extends Component {
  state = {total: 1, page: 1, per: 0, filter: 0, requesting: false};
  projects = null;

  constructor(props) {
    super(props);
    const search = props.location.search.substring(1).split('.');
    if (search.length > 1) {
      const p = search[0];
      const page = parseInt(p);
      if (page > 0) this.state.page = page;
      const f = search[1];
      const filter = parseInt(f);
      if (filter > 0) this.state.filter = filter;
    }
    this.state.requesting = true;
    this.list(this.state.page, this.state.filter);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.location.search !== nextProps.location.search) {
      const search = nextProps.location.search.substring(1).split('.');
      if (search.length > 1) {
        const p = search[0];
        const page = parseInt(p);
        if (page > 0) nextState.page = page;
        const f = search[1];
        const filter = parseInt(f);
        if (filter >= 0) nextState.filter = filter;
      }
      this.list(nextState.page, nextState.filter);
      return true;
    }
    return true;
  }

  selected = (project) => {
    const OrderState = DataManager.getInstance().states.orders;
    let current = '';
    if (project.state >= OrderState.Bid) {
      current = 'tm';
    }else if (project.state >= OrderState.Bidding) {
      current = 'orders';
    }else{
      current = 'products';
    }
    DataManager.getInstance().pub(Event.REDIRECT, `/projects/s/${project.id}/${current}`);
  };

  handle_pagination = (offset) => {
    const page = 1 + offset / this.state.per;
    DataManager.getInstance().pub(Event.REDIRECT, {pathname: this.props.location.pathname, search: `${page}.${this.state.filter}`});
  };

  handle_filter = (e) => {
    const f = parseInt(e.target.value);
    this.setState({filter: f, requesting: true});
    DataManager.getInstance().pub(Event.REDIRECT, {pathname: this.props.location.pathname, search: `1.${f}`});
  };

  handle_prioritized = (e) => {
    const f = this.state.filter + 1;
    this.setState({filter: f, requesting: true});
    DataManager.getInstance().pub(Event.REDIRECT, {pathname: this.props.location.pathname, search: `1.${f}`});
  };

  render() {
    if (!this.projects) return <div></div>;
    const { classes } = this.props;

    return (
      <React.Fragment>
        <div className={classes.root}>
          {this.table()}
        </div>
      </React.Fragment>
    );
  }

  table = () => {
    const { classes } = this.props;
    const projects = this.projects;
    const self = this;
    //<ResponsiveButton color='primary' variant="contained" align="center" component={Link} to={{pathname: '/inquiries/c/'}}>
    //  {T.translate('inquiries.do_inquiry')}
    //</ResponsiveButton>
    return (
      <Grid alignItems="center" justify="center" container>
        <Grid item xs={12} sm={8} md={8} lg={6} xl={6} className={classes.btns}>
          <ResponsiveButton color='primary' variant="contained" align="center" component={Link} to={{pathname: '/inquiries/c/', search: '/projects'}}>
            {T.translate('inquiries.inquiry_now')}
          </ResponsiveButton>
          <ResponsiveButton color='primary' variant="contained" align="center" component={Link} to={{pathname: '/projects/c/'}}>
            {T.translate('projects.new')}
          </ResponsiveButton>
        </Grid>
        <Grid item xs={12} />
        <Grid item xs={12} sm={8} md={8} lg={6} xl={6}>
          <Paper className={classes.paper}>
            {this.state.requesting ?
              <CircularProgress size={48} />
              :
              (projects.length === 0 ?
                <Typography className={classes.content}>{T.translate('projects.empty_list')}</Typography>
                :
                <List className={classes.list}>
                  {projects.map((project, index) => (
                    <Cell
                      key={project.id}
                      item={project}
                      onClick={() => self.selected(project)}
                      onPrioritized={this.handle_prioritized}
                    />
                  ))}
                </List>
              )
            }
          </Paper>
          {!this.state.requesting && this.state.per > 0 && this.state.total > this.state.per &&
          <Pagination
            offset={this.state.per * (this.state.page - 1)}
            limit={this.state.per}
            total={this.state.total}
            onClick={this.handle_pagination}
          />
          }
        </Grid>
      </Grid>
    );
  };

  list = (page, f) => {
    const self = this;
    const params = {page: page, f: f};
    if (DataManager.getInstance().mode >= Mode.ADMIN) params.uid = 0;
    return DataManager.getInstance().list_projects(params).then(function (res) {
      if (res.c === 0) {
        let d = res.d;
        self.projects = d.projects;
        const projects = d.projects;
        const mc = d.stats && d.stats.mc ? d.stats.mc : {};
        for (var i = 0; i < projects.length; ++i) {
          const project = projects[i];
          project.mc = mc[project.id];
          if (!project.mc)
            project.mc = {i: 0, o: 0, items: 0};
        }
        self.projects = projects;
        self.setState({page: page, total: d.t, per: d.p, requesting: false});
      }
    })
  };
}


export default withStyles(styles)(Projects);