/**
 * Created by milan on 2019/7/18.
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/styles/withStyles';
import Button from '@material-ui/core/Button';
//import TextField from '@material-ui/core/TextField';
import { TextField } from 'formik-material-ui';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Uploader from './Upload';

import { Formik, Form, Field } from 'formik';
import T from 'i18n-react';
import { Event, Mode, DataManager } from '../models/';
import TitleBar from '../common/TitleBar';

import Items from '../Deal/Items';
import Costs from '../Deal/Costs';

import ProjectSelector from '../Project/Selector';
import Checkbox from '@material-ui/core/Checkbox';

const styles = theme => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2)
  },
  center: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  inlineLink: {
    textDecoration: 'underline',
    display: 'inline',
    marginLeft: 5
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    paddingTop: theme.spacing(4)
  },
  input: {
    //marginTop: theme.spacing(6)
  },
  img: {
    marginTop: 10,
    maxWidth: 280,
    maxHeight: 280,
    minWidth: 20,
    minHeight: 20
  },
  submit: {
    margin: theme.spacing(1, 0, 1)
  },
  menu: {
    width: 200
  }
});

class DealQuote extends Component {
  inquiry = null;
  id = -1;
  constructor(props) {
    super(props);
    this.state = {
      req: null,
      key: 0,
      deal_id: -1,
      subtotal: 0,
      costs: 8964
    };
    let id = props.match.params.id;
    if (id) this.id = parseInt(id);
    this.show();
  }

  accounting = undefined;
  full_submit = (values, actions) => {
    const self = this;
    if (!(self.inquiry.deal_id > 0)) {
      self.quotation(values, actions);
    }else if (this.accounting) {
      this.accounting.save_deal().then(function (res) {
        if (res.c === 0)
          self.quotation(values, actions);
      });
    }
  };

  show = () => {
    let self = this;
    DataManager.getInstance().show_inquiry(this.id, null).then(function (res) {
      if (res.c === 0) {
        let inquiry = res.d;
        self.inquiry = inquiry;

        self.setState({req: inquiry.req});
        DataManager.getInstance().pub(Event.DISPLAY, {c: inquiry.c});
        if (self.inquiry.deal_id > 0) {
          self.show_deal(self.inquiry.deal_id);
        }
      }
    });
  };

  quotation = (values, actions) => {
    let self = this;
    let path = '/' + this.props.match.path.split('/')[1];
    let to = this.inquiry.deal_id > 0 ? `${path}/o/${this.inquiry.deal_id}` : `${path}/s/${this.id}`;

    return DataManager.getInstance().quotation_with_deal(this.id, values).then(function (res) {
      let errors = {};
      if (res.c !== 0) {
        errors.req = T.translate('errors.' + res.c);
        actions.setSubmitting(false);
        if (Object.keys(errors).length) {
          actions.setErrors(errors);
        }
        return;
      }
      if (!(self.inquiry.deal_id > 0)) {
        actions.setSubmitting(false);
        self.show();
        return;
      }
      DataManager.getInstance().pub(Event.REDIRECT, to);
    });
  };

  attach_to_project = (pid, values) => {
    const params = {...values};
    params.pid = pid;
    return DataManager.getInstance().attach_to_project(this.id, params).then(function (res) {
      if (res.c !== 0) {
        return;
      }
      DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'success', m: T.translate('notification.success')});
    });
  };

  validate = values => {
    let errors = {};
    if (!values.req) {
      errors.req = T.translate('errors.required');
    }
    if (Object.keys(errors).length)
      return errors;
  };

  render() {
    if (!this.inquiry) {
      return <div></div>;
    }
    const is_inquirer = DataManager.getInstance().mode < Mode.BUSINESS;
    const { classes } = this.props;
    let id = this.id;
    const iq = is_inquirer ? 'inquiries' : 'quotation';
    const key = this.inquiry.state >= 2 ? 'edit' : 'create';
    let self = this;

    return (
      <div>
        <TitleBar left={DataManager.getInstance().storage.inquiries_list} title={{title: T.translate(`${iq}.${key}`)}}/>
        <div className={classes.root}>
          <Paper className={classes.paper}>
            <Typography color="textPrimary" >
              {T.translate('quotation.desc')}: &nbsp;
              <Typography component="span" color="primary" >{self.inquiry.req}</Typography>, &nbsp;
            </Typography>
            <Container className={classes.center}>
              <Container component="main" maxWidth="md" className={classes.center} style={{flexDirection: 'row'}}>
                <Typography component="span" color="textPrimary" >
                  {T.translate('quotation.client')}: &nbsp;
                  <Typography component="span" color="primary" >{self.inquiry.name}</Typography>, &nbsp;
                </Typography>
                <Typography component="span" color="textPrimary" >
                  {T.translate('quotation.budget')}: &nbsp;
                  <Typography component="span" color="primary" >{self.inquiry.budget}</Typography>, &nbsp;
                </Typography>
                <Typography component="span" color="textPrimary" >
                  {T.translate('quotation.tier')}: &nbsp;
                  <Typography component="span" color="primary" >{T.translate(`inquiries.t${self.inquiry.type}`)}</Typography>, &nbsp;
                </Typography>
                <Typography component="span" color="textPrimary" >
                  {T.translate('quotation.files')}: &nbsp;
                  <Typography component="span" color="primary" >
                    {self.inquiry.req_data.length}个
                  </Typography>
                </Typography>
              </Container>
              <Uploader files={this.inquiry.req_data} />

              {!(this.inquiry.project_id > 0) &&
              <React.Fragment>
                <Container component="main" maxWidth="xs">
                  <ProjectSelector
                    create={true}
                    uid={this.inquiry.unit_id}
                    selected={this.inquiry.project_id || ''}
                    onChange={this.attach_to_project}
                  />
                </Container>
              </React.Fragment>
              }
            </Container>
            <Container component="main" maxWidth="xs">
              <Formik
                initialValues={{ id: id || -1, req: this.inquiry.res || '', notification: false }}
                validate={this.validate}
                validateOnChange={true}
                onSubmit={this.full_submit}
              >
                {props => {
                  return (
                    <Form className={classes.form} noValidate>
                      <Field
                        hidden
                        value={id}
                        required
                        id="id"
                        name="id"
                      />

                      <Field
                        component={TextField}
                        error={!!props.errors.req}
                        helperText={props.errors.req}
                        className={classes.input}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="req"
                        label={T.translate(`${iq}.req`)}
                        name="req"
                        autoComplete="req"
                        autoFocus
                      />

                      <FormControlLabel
                        control={
                          <Checkbox
                            //component={Checkbox}
                            id="notification"
                            name="notification"
                            onChange={e => props.setFieldValue('notification', e.target.checked)}
                          />
                        }
                        className={classes.formControl}
                        label={T.translate("form.notification")}
                      />

                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        disabled={props.isSubmitting}
                      >
                        {T.translate(`${iq}.submit`)}
                      </Button>
                    </Form>
                  );
                }}
              </Formik>
            </Container>

          </Paper>
        </div>

        {this.state.deal_id > 0 &&
          <React.Fragment>
            <Items key={this.state.key} deal={this.deal} updater={this.get_total} sync={this.sync_deal_products} />
            <Costs key={this.state.costs} deal={this.deal} subtotal={this.deal.subtotal} onRef={ref => (self.accounting = ref)} />
          </React.Fragment>
        }
      </div>
    )
  }

  deal = null;
  show_deal = (deal_id) => {
    let self = this;
    DataManager.getInstance().show_deal(deal_id, {i: 1}).then(function (res) {
      if (res.c === 0) {
        self.deal = res.d.d;
        self.setState({deal_id: deal_id, subtotal: self.deal.subtotal || 0});
      }
    });
  };

  sync_deal_products = () => {
    const deal_id = this.inquiry.deal_id;
    if (!(deal_id > 0)) return;
    const self = this;
    DataManager.getInstance().sync_deal_products(deal_id, null).then(function (res) {
      if (res.c === 0) {
        self.deal = res.d.d;
        self.setState({key: self.state.key + 1, deal_id: deal_id, subtotal: self.deal.subtotal || 0});
      }
    });
  };

  get_total = (d) => {
    this.deal = d;
    this.setState({costs: this.state.costs + 1});
    //this.setState(d);
  };

}

export default withRouter(withStyles(styles)(DealQuote));