/**
 * Created by milan on 2019/7/18.
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/styles/withStyles';
import T from 'i18n-react';
import { Event, DataManager } from '../models/';
import { tableIcons, QualityLookup } from '../common/Constants';
import ImagesDialog from '../dialogs/ImagesDialog';
import AlertDialog from '../dialogs/AlertDialog';
import Costs from './Costs';

import MaterialTable from 'material-table';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { priceDisplay } from '../common/Constants';
import { StateBadge, OrderItemBadge } from '../common/StateBadge';


const styles = theme => ({
  header: {
    margin: 0,
    padding: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  }
});


class Items extends Component {
  productColumns = [
    { title: T.translate('orders.state'), field: 'state', editable: 'onUpdate', render: (item) => (
      <React.Fragment>
        <div style={{display: 'inline-block', marginRight: 3}} >
        {OrderItemBadge(item.state, DataManager.getInstance().states.items)}
        </div>
        <div style={{display: 'inline-block'}} >
          {item.ui && (item.ui.sm > 0 || item.ui.sc > 0) ?
            <StateBadge state={item.ui.sm + item.ui.sa} type={'state_urgent'} title={T.translate('orders.new_message')} />
            :
            (item.ui && item.ui.sa > 0 &&
              <StateBadge state={item.ui.sa} type={'state_urgent'} title={T.translate('trackers.new_actions', {c: item.ui.sa})} />
            )
          }
        </div>
      </React.Fragment>
    )},
    { title: T.translate('products.media'), field: 'media', editable: 'never' },
    { title: T.translate('products.title'), field: 'title', editable: 'never' },
    { title: T.translate('products.type'), field: 'item_type', editable: 'never' },
    { title: T.translate('products.quality'), field: 'quality', editable: 'never', lookup: QualityLookup },
    { title: T.translate('products.size'), field: 'size', editable: 'never' },
    { title: T.translate('products.material'), field: 'material', editable: 'never' },
    { title: T.translate('products.quantity'), field: 'quantity', editable: 'never' },
    { title: T.translate('products.price1'), field: 'display', editable: 'never' }
  ];
  id = -1;
  order = null;

  constructor(props) {
    super(props);
    this.OrderState = DataManager.getInstance().states.orders;
    this.state = {carousel: null, c: 0, data: [], show: false, requesting: false};
    let id = props.match.params.id;
    if (id) this.id = parseInt(id);
    if (this.id > 0) {
      this.show();
    }
  }

  show = () => {
    let self = this;
    DataManager.getInstance().show_order(this.id, null).then(function (res) {
      if (res.c === 0) {
        self.order = res.d.d;
        self.setState({show: true, requesting: false, c: self.order.quantity, data: self.order.items});
      }
    });
  };

  update_order = (params) => {
    const self = this;
    self.setState({requesting: true});
    return DataManager.getInstance().seller_order(this.order.id, params).then(function (res) {
      if (res.c !== 0) {
        return;
      }
      self.order.due = res.d.due;
      if (self.order.state !== res.d.state || self.order.payment !== res.d.payment) {
        self.order.state = res.d.state;
        self.order.payment = res.d.payment;
        self.show();
      }else{
        self.setState({requesting: false});
      }
    });
  };

  update_order_items = (newData, oldData) => {
    if (this.order.id > 0) {
      const self = this;
      let params = {iid: oldData.item_id, state: newData.state};
      self.setState({requesting: true});
      return DataManager.getInstance().seller_order(this.order.id, params).then(function (res) {
        if (res.c !== 0) {
          return;
        }
        if (self.order.state !== res.d.state) {
          self.order.state = res.d.state;
          self.show();
        }else{
          const data = self.state.data.slice();
          data[data.indexOf(oldData)] = newData;
          self.order.items = data;
          self.setState({data: data, requesting: false});
        }
      });
    }else{
      return new Promise((resolve, reject) => {
        DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'error', m: T.translate('errors.products.order_error')});
        setTimeout(() => {
          reject();
        }, 1000);
      })
    }
  };

  accept = (e) => {
    this.setState({alert: this.OrderState.Bidding});
  };

  got_paid = (e) => {
    this.setState({alert: this.OrderState.PaymentConfirmed});
  };

  request_payment = (e) => {
    this.setState({alert: this.OrderState.PaymentClaimed});
  };

  on_select = (event, rowData, togglePanel) => {
    const OrderState = DataManager.getInstance().states.orders;
    if (this.order.state >= OrderState.Bid)
      DataManager.getInstance().pub(Event.REDIRECT, `/orders/trackers/${this.order.id}/${rowData.id}`);
    else
      this.showCarousel(rowData);
  };

  closeAlert = (e) => {
    if (e.currentTarget.dataset.tag === '1') {
      const params = {};
      if (this.state.alert === this.OrderState.Bidding) params.accept = true;
      else if (this.state.alert === this.OrderState.PaymentConfirmed) params.paid = true;
      else if (this.state.alert === this.OrderState.PaymentClaimed) params.request = true;
      this.update_order(params);
    }
    this.setState({alert: 0});
  };

  showCarousel = (rowData) => {
    this.setState({carousel: rowData});
  };

  closeCarousel = (e) => {
    this.setState({carousel: null});
  };

  media_render = (rowData) => {
    const self = this;
    return (
      <IconButton color="primary" align="center" size="small" onClick={(e) => {
        self.showCarousel(rowData);
      }}>
        <img
          style={{ height: 36, maxWidth: 100 }}
          alt={rowData.media[0]}
          src={rowData.media[0]}
        />
      </IconButton>
    )
  };

  state_badge = (state) => {
    const { classes } = this.props;
    const OrderState = DataManager.getInstance().states.orders;
    let cls;
    if (state < OrderState.Partitioned && state >= OrderState.Manufacturing){
      cls = classes.state_read;
    }else if (state >= OrderState.Sealed){
      cls = classes.state_new;
    }else{
      cls = classes.state_wait;
    }
    return (
      <Typography key={state} align="center" gutterBottom={false} className={cls}>
        {OrderState[state]}
      </Typography>
    );
  };

  handle_state = (e) => {
    const state = parseInt(e.target.value);

    this.update_order({state: state});
  };

  state_selector = (order) => {
    const OrderState = DataManager.getInstance().states.orders;
    const states = [];
    let editable = order.type !== 2;
    let k;
    if (editable) {
      for (k in OrderState) {
        if (OrderState.hasOwnProperty(k) && k >= OrderState.Bid && k < OrderState.Partitioned && k % 10 === 0) {
          states.push({title: OrderState[k], state: k});
        }
      }
    }else{
      if (order.state === OrderState.Prepared) {
        states.push({title: OrderState[OrderState.Prepared], state: OrderState.Prepared});
        editable = true;
      }
      for (k in OrderState) {
        if (OrderState.hasOwnProperty(k) && k > OrderState.Prepared && k < OrderState.Received && k % 10 === 0) {
          states.push({title: OrderState[k], state: k});
          if (parseInt(k) === order.state) editable = true;
        }
      }
    }

    if (!editable) {
      return this.state_badge(order.state);
    }
    return (
      <Select
        value={order.state}
        onChange={this.handle_state}
        disableUnderline={false}
        variant="standard"
      >
        {states.map((order_state, index) => {
          return <MenuItem key={index} value={order_state.state}>{order_state.title}</MenuItem>
        })}
      </Select>
    );
  };

  render() {
    if (!this.state.show) return <div></div>;
    const self = this;
    this.productColumns[1].render = this.media_render;
    let images = [];
    let text = {};
    let i;
    if (this.state.carousel != null) {
      const product = this.state.carousel;
      text.title = product.title;
      for (i = 0; i < product.media.length; ++i) {
        const url = product.media[i];
        const image = {label: i, description: url, imgPath: url};
        images.push(image);
      }
    }
    for (i = 0; i < this.state.data.length; ++i) {
      const item = this.state.data[i];
      item['display'] = priceDisplay(item.price, item.currency);
    }
    const { classes } = this.props;
    const OrderState = this.OrderState;
    const trans = this.order.due;

    const header =
      <Container className={classes.header} >
        <Typography variant="h6">{T.translate('products.items', {c: this.state.c})}</Typography>
        <div style={{width: 16}} ></div>
        {this.order.state === OrderState.Bidding &&
        <React.Fragment>
          <Button variant="outlined" data-tag={2} color="primary" size="small" onClick={this.accept} disabled={this.state.requesting}>
            {T.translate('orders.accept')}
          </Button>
          <AlertDialog
            open={this.state.alert === OrderState.Bidding}
            onClose={this.closeAlert}
            title={T.translate('orders.accept')}
            desc={T.translate('orders.alert.confirm_accept_info', {c: this.order.quantity, a: priceDisplay(this.order.total, this.order.currency)})}
            left={T.translate('buttons.cancel')}
            right={T.translate('orders.alert.confirm_accept')}
          />
        </React.Fragment>
        }
        {this.order.state >= OrderState.Bid && this.order.state < OrderState.Received &&
        <React.Fragment>
          {this.state_selector(this.order)}
        </React.Fragment>
        }
        {this.order.state >= OrderState.Received &&
        <React.Fragment>
          <div style={{width: 20}}></div>
          <Typography component="p" className={classes.label}>{OrderState[this.order.state]}</Typography>
        </React.Fragment>
        }
        {this.order.state >= OrderState.Bid && trans && trans.state < 2 &&
        <React.Fragment>
          <div style={{width: 20}}></div>
          <Typography component="p" className={classes.label}>
            {T.translate('orders.payment.paying', {c: priceDisplay(trans.amount, trans.currency)})}
          </Typography>
        </React.Fragment>
        }
        {this.order.state >= OrderState.Bid && trans && trans.state >= 2 && trans.state < 10 &&
        <React.Fragment>
          <div style={{width: 20}}></div>
          <Button variant="outlined" data-tag={3} color="primary" size="small" onClick={this.got_paid} disabled={this.state.requesting}>
            {T.translate('orders.alert.confirm')}
          </Button>
          <AlertDialog
            open={this.state.alert === OrderState.PaymentConfirmed}
            onClose={this.closeAlert}
            title={T.translate('orders.alert.verified', {a: this.order.due.amount})}
            desc={T.translate('orders.alert.verified_info', {a: this.order.due.amount, o: this.order.due.external})}
            left={T.translate('buttons.cancel')}
            right={T.translate('orders.alert.confirm')}
          />
        </React.Fragment>
        }
      </Container>;

    return (
      <React.Fragment>
        <MaterialTable
          icons={tableIcons}
          title={header}
          options={{search: false, paging: false}}
          columns={self.productColumns}
          data={self.state.data}
          //editable={this.order.state > OrderState.Bidding && this.order.state < OrderState.Partitioned ? {
          //  onRowUpdate: this.update_order_items
          //} : null}
          //actions={[{
          //    icon: () => <tableIcons.Edit />,
          //    tooltip: 'Edit',
          //    onClick: (event, rowData) => DataManager.getInstance().pub(Event.REDIRECT, `/orders/trackers/${this.order.id}/${rowData.id}`)
          //  }
          //]}
          onRowClick={ this.on_select }
        />
        <Costs order={this.order} subtotal={this.order.subtotal} />

        <ImagesDialog
          images={images}
          open={this.state.carousel != null}
          onClose={this.closeCarousel}
          {...text}
        />
      </React.Fragment>
    )
  }
}


export default withRouter(withStyles(styles)(Items));