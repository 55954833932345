import React from 'react'
import { Route, BrowserRouter, HashRouter, Switch } from 'react-router-dom'
import Legacy from './components/Legacy'
import Docs from './components/Docs'
import Landing from './components/Landing'
import Main from './components/Main'
import Dashboard from './components/Dashboard'
//import Units from './components/Units'
//import Products from './components/Products'
import Message from './components/Message/index'
import Dinja from './components/Dinja/index'
import Units from './components/Unit/index'
import Subscriptions from './components/Unit/Subscriptions'
import SellerHome from './components/Unit/home'
import Projects from './components/Project/index'
import Products from './components/Product/index'
import Wanted from './components/Product/wanted'
import Materials from './components/Material/index'
import Code from './components/Material/Code'
import Cart from './components/Cart/index'
import Orders from './components/Order/index'
import UnitOrders from './components/UnitOrder/index'
import UnitUsers from './components/UnitUsers/index'
import Deals from './components/Deal/index'
import Channels from './components/Channels'
import Analytics from './components/Analytics'
import Inquiries from './components/Inquiry/index'
import Quote from './components/Quote/index'
import SignIn from './components/User/Signin'
import SignUp from './components/User/Signup'
import SignOut from './components/User/Signout'
import Reset from './components/User/Reset'
import Account from './components/User/Account'
import Unauthorized from './components/Unauthorized'
import ScrollToTop from './components/ScrollTop'
import { Authorizer } from './components/models/';

//import DataCenter from './components/models/DataCenter';
//history={DataCenter.instance.history}

export default props => (

  <BrowserRouter>
    <Switch>
      <Route exact path='/casa'>
        <Channels />
      </Route>

      <Route exact path='/a'>
        <HashRouter>
          <ScrollToTop>
            <Authorizer>
              <Switch>
                <Route exact path='/landing' component={ Landing } />
                <Route exact path='/' component={ Main } />
                <Route exact path='/unauthorized' component={ Unauthorized } />
                <Route exact path='/app/:target?' component={ Channels } />
                <Route path='/docs/:doc/' component={ Docs } />
                <Route exact path='/signup' component={ SignUp } />
                <Route exact path='/signin' component={ SignIn } />
                <Route exact path='/signout' component={ SignOut } />
                <Route exact path='/reset' component={ Reset } />
                <Route exact path='/me' component={ Account } />
                <Route exact path='/subscriptions' component={ Subscriptions } />
                <Route path='/inquiries/:action?/:id?' component={ Inquiries } />
                <Route path='/legacy/:action?/:id?' component={ Legacy } />
                <Route path='/dinja/:action?/:id?' component={ Dinja } />

                <Route path='/quotes/:action?/:id?' component={ Quote } />
                <Route path='/messages/:id?' component={ Message } />
                <Route path='/units/:action?/:id?' component={ Units } />
                <Route path='/corp/:action?/:id?' component={ Units } />
                <Route path='/seller/:action?/:id?' component={ Units } />
                <Route path='/orders/:action?/:id?' component={ Orders } />
                <Route path='/deals/:action?/:id?' component={ Deals } />
                <Route path='/products/:action?/:id?' component={ Products } />
                <Route path='/wanted/:action?/:id?' component={ Wanted } />
                <Route path='/projects/:action?/:id?' component={ Projects } />
                <Route path='/cart/:action?/:id?' component={ Cart } />
                <Route path='/materials/:action?/:id?' component={ Materials } />

                <Route exact path='/dashboard' component={ Dashboard } />
                <Route exact path='/channels' component={ Channels } />
                <Route exact path='/analytics' component={ Analytics } />
              </Switch>
            </Authorizer>
          </ScrollToTop>
        </HashRouter>
      </Route>

      <Route exact path='/s'>
        <HashRouter>
          <ScrollToTop>
            <Authorizer>
              <Switch>
                <Route exact path='/landing' component={ Landing } />
                <Route exact path='/' component={ SellerHome } />
                <Route exact path='/unauthorized' component={ Unauthorized } />
                <Route exact path='/app/:target?' component={ Channels } />
                <Route path='/docs/:doc/' component={ Docs } />
                <Route exact path='/signup' component={ SignUp } />
                <Route exact path='/signin' component={ SignIn } />
                <Route exact path='/signout' component={ SignOut } />
                <Route exact path='/reset' component={ Reset } />
                <Route exact path='/me' component={ Account } />
                <Route path='/inquiries/:action?/:id?' component={ Inquiries } />
                <Route path='/legacy/:action?/:id?' component={ Legacy } />

                <Route path='/quotes/:action?/:id?' component={ Quote } />
                <Route path='/messages/:id?' component={ Message } />
                <Route path='/corp/:action?/:id?' component={ Units } />
                <Route path='/seller/:action?/:id?' component={ Units } />
                <Route path='/members/:action?/:id?' component={ UnitUsers } />
                <Route path='/orders/:action?/:id?' component={ UnitOrders } />
                <Route path='/deals/:action?/:id?' component={ Deals } />
                <Route path='/products/:action?/:id?' component={ Products } />
                <Route path='/materials/:action?/:id?' component={ Materials } />

                <Route exact path='/dashboard' component={ Dashboard } />
                <Route exact path='/channels' component={ Channels } />
                <Route exact path='/analytics' component={ Analytics } />
              </Switch>
            </Authorizer>
          </ScrollToTop>
        </HashRouter>
      </Route>

      <Route exact path='/'>
        <HashRouter>
          <ScrollToTop>
            <Authorizer>
              <Switch>
                <Route exact path='/landing' component={ Landing } />
                <Route exact path='/' component={ Main } />
                <Route exact path='/unauthorized' component={ Unauthorized } />
                <Route exact path='/app/:target?' component={ Channels } />
                <Route path='/docs/:doc/' component={ Docs } />
                <Route exact path='/signup' component={ SignUp } />
                <Route exact path='/signin' component={ SignIn } />
                <Route exact path='/signout' component={ SignOut } />
                <Route exact path='/reset' component={ Reset } />
                <Route exact path='/me' component={ Account } />
                <Route exact path='/subscriptions' component={ Subscriptions } />
                <Route path='/inquiries/:action?/:id?' component={ Inquiries } />
                <Route path='/legacy/:action?/:id?' component={ Legacy } />
                <Route path='/deals/:action?/:id?' component={ Deals } />
                <Route path='/orders/:action?/:id?' component={ Orders } />
                <Route path='/code/' component={ Code } />
                <Route path='/materials/:action?/:id?' component={ Materials } />

                <Route path='/messages/:id?' component={ Message } />
                <Route path='/corp/:action?/:id?' component={ Units } />
                <Route path='/seller/:action?/:id?' component={ Units } />
                <Route path='/projects/:action?/:id?' component={ Projects } />
                <Route path='/cart/:action?/:id?' component={ Cart } />
                <Route path='/products/:action?/:id?' component={ Products } />
                <Route exact path='/dashboard' component={ Dashboard } />
                <Route exact path='/channels' component={ Channels } />
                <Route exact path='/analytics' component={ Analytics } />
              </Switch>
            </Authorizer>
          </ScrollToTop>
        </HashRouter>
      </Route>
    </Switch>
  </BrowserRouter>
)