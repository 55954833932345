/**
 * Created by milan on 2019/7/18.
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/styles/withStyles';
import T from 'i18n-react';
import { DataManager, Mode } from '../models';
import ImageCarousel from '../common/ImageCarousel';

import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Box from '@material-ui/core/Box';
import { grey } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
// import JsBarcode from 'jsbarcode';


const styles = theme => ({
  container: {
    padding: theme.spacing(2)
  },
  priceBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 3, 1, 3)
  },
  bottom: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-end'
  },
  box: {
    padding: theme.spacing(1, 3, 1, 3)
  },
  titleBar: {
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
    position: 'relative'
  },
  title: {
    width: '100%',
    height: 48,
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(0.5)
    }
  },
  itemButton: {
    margin: theme.spacing(0.5)
  },
  printButton: {
    width: 48,
    height: 48,
    position: 'absolute',
    padding: theme.spacing(0),
    left: theme.spacing(0),
    top: theme.spacing(0),
    color: theme.palette.primary.main
  },
  closeButton: {
    width: 48,
    height: 48,
    position: 'absolute',
    padding: theme.spacing(0),
    right: theme.spacing(0),
    top: theme.spacing(0),
    color: theme.palette.grey[500]
  },
  price: {
    fontSize: 24,
    lineHeight: 1,
    verticalAlign: 'bottom'
  },
  label: {
    fontSize: 13,
    marginRight: theme.spacing(1)
  },
  delimiter: {
    // fontSize: 13,
    // color: theme.palette.primary.main
  },
  info: {
    fontSize: 16
  },
  barcode: {
    marginBottom: 16,
    maxWidth: '100%',
    maxHeight: '100%',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
});


const M_VISIBLE = [
  'quality', 'appearance', 'content', 'region', 'specs', 'uses', 'es'
];

const M_OPTIONAL = [
  'hardness', 'density', 'gw', 'atp', 'marp', 'dry_marp', 'wet_marp',
  'tech', 'weave', 'tan', 'surface', 'paint', 'mc', 'desc',
];

const M_ITEMS = [
  'color', 'cs', 'cn', 'unit', 'wholesale', 'retail', 'cut'  // , 's_name', 's_mid'
];

const M_ITEMS_SECRET = [
  's_name', 's_mid', 's_geo'
];

class MaterialCard extends Component {
  state = {show: false, index: 0, open: false, card: false};
  id = -1;
  material = null;
  coder = null;
  non_permitted = [];

  constructor(props) {
    super(props);
    if (!DataManager.getInstance().permit('Price')) {
      this.non_permitted = ['wholesale', 'retail', 'cut'];
    }

    if (props.onClose) {
      if (props.card !== undefined) this.state.card = props.card;
      this.material = props.material ? this.init(props.material) : props.material;
      this.state.open = props.open;
      this.state.show = true;
      this.state.index = props.index || 0;
    }else{
      const idx = props.location.search.substring(1);
      const index = parseInt(idx);
      if (index && index > 0) this.state.index = index;
      let id = props.match.params.id;
      if (id) this.id = parseInt(id);
      if (this.id > 0) {
        this.show();
      }
    }
  }

  shouldComponentUpdate(props, nextState) {
    if (props.card !== undefined) nextState.card = props.card;
    if (this.state.index === undefined && props.index !== undefined) nextState.index = props.index || 0;
    nextState.open = props.open;
    if (nextState.open && props.material !== this.material) {
      this.material = this.init(props.material);
    }
    return true;
  }

  init = (material) => {
    const cats = ['clazz', 'family', 'species'];
    const ids = ['cs', 'identifier'];  // , 's_name'
    material.material_id = material.id;
    material.item_id = 0;
    const categories = [];
    let i;
    for (i = 0; i < cats.length; ++i) {
      const cat = cats[i];
      if (material[cat]) categories.push(material[cat]);
    }
    material.categories = categories;
    if (!material.media) material.media = [];
    if (!material.items) material.items = [];

    let j;
    for (i = 0; i < material.items.length; ++i) {
      const item = material.items[i];
      item.idf = item.identifier && item.identifier.length <= 3 ? `${material.identifier}${item.identifier}` : material.identifier;
      if (item.cn) {
        item.categories = item.cn;
      }else{
        const categories = [];
        for (j = 0; j < ids.length; ++j) {
          const key = ids[j];
          if (item[key]) categories.push(item[key]);
        }
        item.categories = categories.join(' / ');
      }
    }
    // const item = material[this.state.index];
    // var canvas = document.createElement("canvas");
    // JsBarcode(canvas, item.identifier, {width: 3});
    // this.coder = canvas.toDataURL("image/png");
    return material;
  }

  show = () => {
    let self = this;
    DataManager.getInstance().show_material(this.id, null).then(function (res) {
      if (res.c === 0) {        
        self.material = self.init(res.d.mat);
        self.setState({show: true});
      }
    });
  };

  onChange = (e) => {
    const index = parseInt(e.currentTarget.dataset.tag);
    // DataManager.getInstance().pub(Event.REDIRECT, {pathname: this.props.location.pathname, search: index});
    this.setState({index: index});
  }

  onPrint = (e) => {
    // var content = document.getElementById("printable");
    window.print();
  }

  onClose = (e) => {
    const material = this.material;
    const item = material.items[this.state.index];
    const mat = {identifier: item.idf, title: `${material.title}-${item.cn || item.identifier}`}
    this.props.onClose(mat);
  }

  render() {
    if (!this.material || !this.state.show) return <React.Fragment/>;
    const { classes } = this.props;

    // <IconButton aria-label="print" className={classes.printButton} onClick={this.onPrint}>
    //   <PrintIcon style={{width: 64, height: 64}} />
    // </IconButton>
    return (
      <React.Fragment>
        {this.id > 0 ?
        this.body()
        :
        <Dialog open={this.state.open} onClose={this.onClose} fullWidth aria-labelledby="dialog-title">
          <DialogTitle>
            <div className={classes.titleBar}>
              <Typography align="center" variant="h6" className={classes.title}></Typography>
              <IconButton aria-label="close" className={classes.closeButton} onClick={this.onClose}>
                <CloseIcon style={{width: 48, height: 48}} />
              </IconButton>
            </div>
          </DialogTitle>
          <DialogContent>
            
            {this.body()}
          </DialogContent>
        </Dialog>
        }
      </React.Fragment>
    );
  }

  body() {
    // if (!this.state.show) return <React.Fragment/>;
    const { classes } = this.props;
    const material = this.material;
    const item = this.state.index < material.items.length ? material.items[this.state.index] : material;
    if (!item.media) item.media = [];
    let media = this.state.index < material.items.length && item.media.length > 0 ? item.media : material.media;
    if (this.state.card && this.state.index >= 0 && this.state.index < material.items.length)
      media = [
        `/api/materials/cd/${item.idf}/?id=${DataManager.getInstance().storage.token}&m=0`,
        `/api/materials/cd/${item.idf}/?id=${DataManager.getInstance().storage.token}&m=1`,
        // `/api/materials/cd/${item.idf}/?id=${DataManager.getInstance().storage.token}&m=2`
      ];

    return (
      <Container component="main" maxWidth="xs" id="printable">
        {media.length > 0 && <ImageCarousel id={this.state.index} images={media} />}
        <div>
          <React.Fragment>
            <Box className={classes.priceBox}>
              <Box className={classes.bottom}>
                <Typography component="span" className={classes.price}>{material.title}</Typography>
              </Box>
            </Box>
            <Box key="categories" className={classes.box}>
              {material.categories.map((category, index) => (
                <React.Fragment key={index}>
                  {index > 0 &&
                    <Typography component="span" className={classes.delimiter}> / </Typography>
                  }
                  <Typography component="span" className={classes.info}>{category}</Typography>
                </React.Fragment>
              ))}
            </Box>
            <Box borderBottom={1} borderColor={grey[400]} />
          </React.Fragment>

          <React.Fragment>
            <Box className={classes.box}>
              <Typography component="p" className={classes.label}>{T.translate('materials.select_color')}: </Typography>
              {material.items.map((item, index) => (
                <Button key={item.id} data-tag={index} variant={this.state.index === index ? 'contained' : 'outlined'} 
                        color="primary" size="small" className={classes.itemButton} onClick={this.onChange} >
                  {item.categories}
                </Button>
              ))}
            </Box>
            <Box borderBottom={1} borderColor={grey[400]} />
          </React.Fragment>

          <Box key="identifier" className={classes.box}>
            <Typography component="span" className={classes.label}>{T.translate('materials.identifier')}: </Typography>
            <Typography component="span" className={classes.info}>{item.idf}</Typography>
          </Box>

          {M_VISIBLE.map((key, index) => (
            !material[key] ? null :
            <Box key={key} className={classes.box}>
              <Typography component="span" className={classes.label}>{T.translate(`materials.${key}`)}: </Typography>
              <Typography component="span" className={classes.info}>{material[key] || ''}</Typography>
            </Box>
          ))}

          {M_ITEMS.map((key, index) => (
            !item[key] || this.non_permitted.indexOf(key) >= 0 ? null :
            <Box key={key} className={classes.box}>
              <Typography component="span" className={classes.label}>{T.translate(`materials.${key}`)}: </Typography>
              <Typography component="span" className={classes.info}>{item[key] || ''}</Typography>
            </Box>
          ))}

          {DataManager.getInstance().mode >= Mode.BUSINESS && M_ITEMS_SECRET.map((key, index) => (
            !item[key] || this.non_permitted.indexOf(key) >= 0 ? null :
            <Box key={key} className={classes.box}>
              <Typography component="span" className={classes.label}>{T.translate(`materials.${key}`)}: </Typography>
              <Typography component="span" className={classes.info}>{item[key] || ''}</Typography>
            </Box>
          ))}
          
          {M_OPTIONAL.map((key, index) => (
            !material[key] ? null :
            <Box key={key} className={classes.box}>
              <Typography component="span" className={classes.label}>{T.translate(`materials.${key}`)}: </Typography>
              <Typography component="span" className={classes.info}>{material[key]}</Typography>
            </Box>
          ))}
          <Box style={{height: 100}} />
        </div>
      </Container>
    )
  }
}


export default withRouter(withStyles(styles)(MaterialCard));