import React,  { Component } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import List from '@material-ui/core/List';
import CircularProgress from '@material-ui/core/CircularProgress';

import { Event, Mode, DataManager } from '../models/';
import { priceDisplay } from '../common/Constants';
import T from 'i18n-react';
import Pagination from '../common/Pagination';
import Cell from './Cell';
import ImagesDialog from '../dialogs/ImagesDialog';
import Navigator from './Navigator';
//const Navigator = Box;

const staff = [
  {
    label: '扫描二维码联系客服下单',
    description: '或微信内搜索 zhuchaox 添加',
    imgPath:
      'https://aifa.oss-cn-qingdao.aliyuncs.com/wechat.jpg'
  }
];


const styles = theme => ({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
    marginTop: theme.spacing(0)
  },
  paper: {
    width: '100%',
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  list: {
    width: '100%'
  },
  content: {
    fontSize: 24,
    margin: theme.spacing(2)
  },
  pagination: {
    marginTop: theme.spacing(5)
  }
});


class Orders extends Component {
  state = {total: 1, page: 1, per: 0, filter: 0, contact: false, show: false, requesting: false, stats: {}};
  orders = null;

  constructor(props) {
    super(props);
    const storage = DataManager.getInstance().storage;
    if (storage.mc && storage.mc.orders) {
      this.state.stats = storage.mc.orders.oc;
    }

    this.OrderState = DataManager.getInstance().states.orders;
    const search = props.location.search.substring(1).split('.');
    if (search.length > 1) {
      const p = search[0];
      const page = parseInt(p);
      if (page > 0) this.state.page = page;
      const f = search[1];
      const filter = parseInt(f);
      if (filter > 0) this.state.filter = filter;
    //}else if (DataManager.getInstance().mode !== Mode.BUSINESS){
    //  const stats = this.state.stats;
    //  const active = ['PaymentDue', 'Bidding', 'Blueprinting', 'Manufacturing', 'Shipping'];
    //  let i;
    //  for (i = 0; i < active.length; ++i) {
    //    const f = this.OrderState[active[i]];
    //    if (stats[f] > 0) {
    //      DataManager.getInstance().pub(Event.REDIRECT, {pathname: this.props.location.pathname, search: `1.${f}`});
    //      return;
    //    }
    //  }
    }
    this.state.requesting = true;
    this.list(this.state.page, this.state.filter);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.location.search !== nextProps.location.search) {
      const search = nextProps.location.search.substring(1).split('.');
      if (search.length > 1) {
        const p = search[0];
        const page = parseInt(p);
        if (page > 0) nextState.page = page;
        const f = search[1];
        const filter = parseInt(f);
        if (filter >= 0) nextState.filter = filter;
      }
      this.list(nextState.page, nextState.filter);
      return true;
    }
    return true;
  }

  show_contact = (e) => {
    this.setState({contact: true});
  };

  close_contact = (e) => {
    this.setState({contact: false});
  };

  order_selected = (order) => {
    //if (this.state.filter === 0 && order.type === 10 && !order._expanded) {
    //  this.expand_item(order);
    //}
    const path = '/' + this.props.match.path.split('/')[1];
    DataManager.getInstance().pub(Event.REDIRECT, `${path}/s/${order.id}`);
  };

  handle_pagination = (offset) => {
    const page = 1 + offset / this.state.per;
    DataManager.getInstance().pub(Event.REDIRECT, {pathname: this.props.location.pathname, search: `${page}.${this.state.filter}`});
  };

  handle_filter = (v) => {
    const f = parseInt(v);
    this.setState({filter: f, requesting: true});
    DataManager.getInstance().pub(Event.REDIRECT, {pathname: this.props.location.pathname, search: `1.${f}`});
  };

  render() {
    if (!this.state.show)
      return <div></div>;

    const { classes } = this.props;

    return (
      <React.Fragment>
        <div className={classes.root}>
          {this.table()}

          <ImagesDialog
            images={staff}
            title={staff[0].label}
            desc={staff[0].description}
            open={this.state.contact}
            onClose={this.close_contact}
          />
        </div>
      </React.Fragment>
    );
  }

  table = () => {
    const { classes } = this.props;

    const orders = this.orders;
    const self = this;
    const is_seller = DataManager.getInstance().mode === Mode.BUSINESS;

    return (
      <Grid alignItems="center" justify="center" container>
        <Grid item xs={12} sm={12} md={12} lg={10} xl={8} hidden={!is_seller}>
          <Navigator stats={this.state.stats.oc} current={this.state.filter} onChange={this.handle_filter} />
        </Grid>
        <Grid item xs={12} sm={8} md={8} lg={6} xl={5}>
          <Box className={classes.paper}>
            {this.state.requesting ?
              <CircularProgress size={48} />
              :
              (orders.length === 0 ?
                <React.Fragment>
                  <Typography className={classes.content}>
                    {this.state.filter === 0 ?
                      T.translate('orders.empty_list')
                      :
                      T.translate('orders.empty_list_with_state', {state: this.OrderState[this.state.filter]}) }
                  </Typography>
                  {!is_seller &&
                    <Button variant="contained" color="primary" size="small" onClick={this.show_contact} >
                      {T.translate('orders.order_with_customer_service')}
                    </Button>
                  }
                </React.Fragment>
                :
                <List className={classes.list}>
                  {orders.map((order, index) =>
                    <Cell key={order.id} order={order} index={index} onClick={self.order_selected} onExpand={this.on_expanded} />
                  )}
                </List>
              )
            }
          </Box>
          {!this.state.requesting && this.state.per > 0 && this.state.total > this.state.per &&
          <Pagination
            offset={this.state.per * (this.state.page - 1)}
            limit={this.state.per}
            total={this.state.total}
            onClick={this.handle_pagination}
          />
          }
        </Grid>
      </Grid>
    );
  };

  on_expanded = () => {
    this.setState({expanded: !this.state.expanded});
  };

  expand_item = (order) => {
    if (order.sub_orders) {
      order._expanded = !order._expanded;
      this.setState({expanded: !this.state.expanded});
    }else{
      const self = this;
      DataManager.getInstance().sub_orders(order.id, null).then(function (res) {
        if (res.c === 0) {
          let d = res.d;

          const orders = d.orders;
          for (var i = 0; i < orders.length; ++i) {
            const order = orders[i];
            order.subtotal = priceDisplay(order.subtotal, order.currency);
            order.total = priceDisplay(order.total, order.currency);
            order.paid = priceDisplay(order.paid, order.currency);
            order.received = priceDisplay(order.received, order.currency);
          }
          order.sub_orders = orders;
          order._expanded = !order._expanded;
          self.setState({expanded: !self.state.expanded});
        }
      })
    }
  };

  list = (page, f) => {
    const self = this;
    return DataManager.getInstance().list_orders({page: page, f: f, id: -1}).then(function (res) {
      if (res.c === 0) {
        let d = res.d;

        const storage = DataManager.getInstance().storage;
        if (storage.mc && storage.mc.orders) {
          storage.mc.orders = d.stats;
        }
        const orders = d.orders;
        for (var i = 0; i < orders.length; ++i) {
          const order = orders[i];
          order.subtotal = priceDisplay(order.subtotal, order.currency);
          order.total = priceDisplay(order.total, order.currency);
          order.paid = priceDisplay(order.paid, order.currency);
          order.received = priceDisplay(order.received, order.currency);
          order.mc = d.stats.mc[order.id];
        }
        self.orders = orders;

        self.setState({page: page, total: d.t, per: d.p, requesting: false, show: true, stats: d.stats});
        window.scrollTo(0, 0);
      }
    })
  };
}


export default withStyles(styles)(Orders);