/* ES6 & TS */
import T from 'i18n-react';
/* commonJS */
//var T = require('i18n-react').default;
/* when using UMD version w/o modules */
//var T = window['i18n-react'].default;

// Set some texts
T.setTexts({
  appName: "住潮",
  ZhuChao: "住潮",
  Casa: "CHAO CASA",
  settings: "设置",
  welcome: "Bienvenido {username}!",
  signup: "注册{platform}",
  signin: "登录",
  sign_in_up: "登录/注册",
  remember_me: "在这台设备上记住我",
  have_account: "已有账号? 现在登录",
  no_account: "没有账号? 马上注册",
  forgot_password: "忘记密码",
  reset_password: "重置密码",
  s: "的",
  me: "我",
  display: "显示名",
  name: "账号",
  email: "邮箱",
  password: "密码",
  title: {
    App: "App",
    Home: "首页",
    Landing: "着陆",
    Dashboard: "工作台",
    Projects: "项目",
    Collections: "收藏",
    Materials: "材质",
    Products: "产品",
    Wanted: "单品",
    Orders: "订单",
    Cart: "购物车",
    Deals: "报价单",
    Channels: "渠道",
    Analytics: "数据分析",
    Admin: "管理",
    Seller: "厂家",
    Corp: "团队",
    Members: "成员",
    Units: "公司",
    Dinja: "钉匠",
    Messages: "消息",
    Inquiries: "询价",
    Quotes: "报价",
    Account: "账号",
    Me: "我的账号",
    Signout: "登出",
    Signup: "注册",
    Signin: "登录",
    Unauthorized: "授权失败",
    Info: "我的账号",
    ChangePassword: "修改密码",
    Chat: "在线沟通",
    contact: "联系我们",
    help: "帮助",
    about: "关于",
    about_us: "关于我们",
    about_zhuchao: "关于{app}",
    welcome: "欢迎页面",
    policy: "隐私政策",
    terms: "用户协议",
    libraries: "开源组件",
    settings: "设置",
    view_file: "询价文件"
  },
  desc: {
    signout: "正在登出"
  },
  labels: {
    confirm_delete: "确定要删除这一行吗?",
    current_password: "当前密码",
    new_password: "新密码",
    confirm_password: "确认密码",
    sync_price: "同步最新价格",
    country: "国家",
    province: "省",
    city: "市",
    county: "县",
    town: "乡/镇"
  },
  dialogs: {
    file: "文件上传",
    file_select_images: "从手机相册选择",
    file_take_photos: "拍照",
    file_images: "图片",
    file_videos: "视频",
    file_too_big: "文件太大了, 不能超过 15 MB, 请重新选择",
    sorry: "抱歉",
    no_offer: "暂无报价",
    please_wait: "厂家会尽快为您报价, 请耐心等待",
    not_saved: "有未保存的修改, 确定要退出吗?",
    alert: "提示",
    danger: "危险提示",
    auto_accept: "勾选「自动接受加入申请」表示任何使用该邀请码注册{app}或申请加入该团队的用户将自动获得「成员」身份, 拥有该身份的所有权限, 将带来极大的数据泄漏风险. \n建议您不要选择「自动接受加入申请」, 并对申请加入的成员进行人工身份审核, 保护数据安全.",
    try_collect: "选择尺寸和材质后才能将产品加入项目或请求估价",
    confirm_role: "确定要将您的账号权限由{a}修改为{s}吗? 修改后您的账号将失去{a}的权限, 您将无法自行恢复至原权限.",
    confirm_user_deletion: "确定要将手机号为{s}的账号从您的组织中移除吗?",
    confirm_delete_inquiry: "确定要删除询价吗?",
    confirm_delete_tracker: "确定要删除这个产品追踪进度吗?",
    confirm_delete_project: "确定要删除这个项目吗?",
    confirm_delete_quote: "确定要删除这个报价吗?",
    confirm_delete_material: "确定要删除这个材质吗?",
    confirm_delete_product: "确定要删除这个产品吗?",
    failed_delete_project: "该项目中有尚在进行中的订单或询价, 暂不能删除, 如需帮助请联系{app}客服",
    signout: "确定要登出当前账号吗?",
    unit_changed: "加入新团队申请发送成功, 请重新登录",
    read_on_web: "此单报价数据量过大, App 内无法展示, 请在电脑端访问 https://zhuchaox.com 获得最佳查看效果",
    auth: "需要登录",
    auth_required: "为保障您的数据安全和报价的真实有效, 登录后才能发起询价",
    new_version: "版本更新",
    new_version_update: "{app} App 有新版本了, 请更新版本获得最佳体验",
    images_selected: "已选 {c} 张",
    delete_cart_item: "删除商品",
    cart_item: "这个商品",
    agree: "同意{app}交易协议",
    lack_of_accuracy: "受汇率和小数计算精度影响, 客户端选品时计算出的价格可能有不超过 10 元人民币的精度错误, 请知悉",
    can_order: "标记为可下单",
    can_order_desc: "所有产品均设置了生产阶段后才能将询价单设置为可下单状态",
    confirm_delete_cart_item: "确定要将{t}从购物车中删除吗?"
  },
  errors: {
    not_permitted: "没有权限",
    occupied: "已被占用",
    required: "必填",
    invalid_code: "验证码错误",
    invalid_phone: "无效的手机号",
    invalid_email: "无效的邮箱",
    invalid_role_to_push: "只有成员及以上权限的角色才能接收短信通知",
    min_length: "最短 {length} 位",
    invalid_invitation: "无效的邀请码",
    alphabets_only: "只允许字母和数字的组合",
    password_not_match: "密码不匹配",
    not_seller: "您不是厂家, 无法使用{app}厂家版",
    not_customer: "您是厂家, 请下载{app}厂家版",
    1: "出错了",
    500: "网络出错了, 请稍后重试",
    499: "你没有权限使用这个资源",
    498: "该账号还没有通过所属团队{s}的审核, 请联系该团队负责人或平台客服加速审核.",
    400: "你没有权限使用这个资源",
    401: "错误的邀请码",
    402: "账号已存在",
    403: "登录错误",
    404: "手机号或密码错误",
    405: "格式错误",
    369: "没有权限",
    update_password: {
      401: "密码不匹配",
      405: "格式错误",
      403: "当前密码错误"
    },
    products: {
      no_image: "请上传至少一张图片",
      has_error: "请修正表单中的错误",
      duplicated_item: "该产品上已存在材质和尺寸与表格中相同的商品, 请确认",
      deal_error: "订单出错了"
    },
    inquiries: {
      too_much: "一次询价不能超过 {c} 个产品",
    },
    units: {
      required: "公司名和邀请码不能为空",
      failed: "保存失败",
      unauthorized: "权限不足",
      plan_required: "银卡及以上会员等级的团队才能执行这个操作",
      owner_required: "拥有「负责人」权限角色不能少于一个"
    },
    orders: {
      404: "手机号不存在"
    }
  },
  buttons: {
    quit_directly: "直接退出",
    save_and_quit: "保存并退出",
    continue: "仍然继续",
    select: "选择",
    select_all: "全选",
    deselect_all: "全不选",
    back: "后退",
    signup: "注册",
    signin: "登录",
    edit: "编辑",
    save: "保存",
    submit: "提交",
    prev: "前一个",
    next: "后一个",
    next_step: "下一步",
    prev_step: "上一步",
    i_know: "知道了",
    ok: "好的",
    yes: "确定",
    cancel: "取消",
    close: "关闭",
    delete: "删除",
    download: "下载",
    view: "查看",
    publish: "发布",
    hide_optional: "隐藏可选项",
    wait_a_moment: "稍后再说",
    sign_in_now: "立即登录",
    update_now: "立即更新",
    upload: "本地文件上传",
    upload_with_path: "指定路径上传",
    url: "URL 链接上传",
    sugguest: "Google 搜索",
    next_page: "下一页"
  },
  form: {
    invitation: "邀请码",
    phone_notification: "通知手机号",
    phone_placeholder: "可选, 不填则默认使用注册手机号",
    phone: "手机号",
    password: "密码",
    display: "显示名",
    code: "验证码",
    code_sent: "验证码已发送, 5分钟内有效",
    send_code: "发送",
    contact: "其它联系方式",
    contact_placeholder: "可选, 微信/QQ等",
    select_file: "选择文件",
    optional: "可选",
    notification: "发送短信通知"
  },
  notification: {
    added_to_cart: "已加入购物车",
    failed_to_cart: "加入购物车失败",
    added: "添加成功",
    updated: "更新成功",
    deleted: "删除成功",
    failed: "提交失败",
    success: "提交成功"
  },
  dinja: {
    pending: "待处理",
    done: "已处理",
    empty_list: "没有数据"
  },
  messages: {
    empty_list: "没有新消息",
    show_all: "查看所有",
    orders: "订单",
    read_all: "全部设为已读",
    read_all_confirm: "确定要将所有未读消息设为已读吗? 可能漏过重要订单通知",
    items: "产品",
    updated: "状态更新了, 点击查看",
    types: {
      1: "订单{t}状态更新了, 点击查看",
      2: "{t}状态更新了, 点击查看",
      3: "有新的待接订单, 点击查看",
      100: "{t}状态更新了, 点击查看"
    }
  },
  units: {
    all: "所有公司",
    empty_list: "暂无厂家",
    user: "散客",
    buyer: "买家",
    seller: "厂家",
    manager: "管理",
    id: "编号",
    type: "类型",
    key: "识别号",
    secret: "识别码",
    name_label: "公司全称",
    display_label: "团队/公司简称",
    name: "全称",
    display: "显示名称",
    uri: "邀请码",
    team: "团队",
    plan: "会员等级",
    plan_due: "有效期至",
    plan_blue: "蓝卡",
    plan_silver: "银卡",
    plan_gold: "金卡",
    plan_platinum: "白金卡",
    my_plan: "我的会员等级",
    plan_rights: "会员权益",
    desc: "简介",
    tech: "擅长类别",
    no_desc: "暂无简介",
    preview: "预览",
    orders: "已接单数",
    rate: "平均评分",
    rank: "{app}认证等级",
    rank_none: "无",
    create_at: "创建时间",
    update_at: "更新时间",
    auto: "自动接受加入申请",
    extra: "价格系数",
    media: "图片",
    video: "视频介绍",
    discover: "发现优质厂家",
    empty_info: "暂无信息",
    join_code: "新团队的邀请码",
    join: "申请加入另一个团队",
    apply_join: "提交申请",
    agree: "我已知悉数据风险",
    extra_desc: "额外价格系数必须是0到1.5之间的数字, 最高精确到小数点后两位. 设置额外价格系数后, 该级别用户查看产品价格时将在出厂价格的基础上增加出厂价格 * 价格系数. 加价后的出厂价格不能超过指导零售价, 超过后出厂价将等于指导零售价."
  },
  users: {
    empty_list: "暂无成员",
    all: "所有成员",
    management: "成员列表",
    id: "编号",
    active: "激活",
    role: "权限级别",
    display: "显示名",
    phone: "手机号",
    email: "邮箱",
    contact: "联系方式",
    bio: "介绍",
    level: "级别",
    create_at: "注册时间",
    update_at: "最后登录时间",
    role_disabled: "禁用",
    role_audited: "审核中",
    role_member: "成员",
    role_manager: "经理",
    role_owner: "负责人",
    role_admin: "管理员",
    role_director: "主管",
    role_assistant: "助理",
    role_designer: "设计师",
    role_buyer: "采购",
    all_has_role: "所有",
    push_notifications: "系统通知",
    push_none: "不发送通知",
    push_sms: "发送短信"
  },
  permissions: {
    settings: "权限管理",
    role_grande: "负责人角色始终拥有团队内所有权限",
    role_permissions: "角色权限",
    Users: "成员管理",
    Inquiry: "询价",
    Quote: "查看报价",
    Member: "成员列表",
    Model: "下载模型",
    Order: "查看订单",
    Price: "显示价格",
  },
  collections: {
    all: "我的收藏",
  },
  projects: {
    choose: "选择项目",
    back: "返回项目",
    empty_list: "没有项目",
    empty_products: "没有收藏的产品",
    empty_inquiries: "没有询价",
    empty_orders: "没有下单",
    empty_items: "没有生产中的产品",
    privileges: "权限",
    by_messages: "时间排序",
    by_spaces: "空间排序",
    by_sellers: "厂家排序",
    track: "生产进度",
    messages: "进度",
    spaces: "进度",
    sellers: "进度",
    orders: "厂家订单",
    inquiries: "询价",
    products: "产品",
    info: "项目信息",
    all: "我的项目",
    new: "新建项目",
    edit: "编辑项目",
    title: "项目名称",
    address: "收货地址",
    upload: "项目图片",
    submit: "提交",
    delete: "删除项目",
    stick_top: "置顶",
    stick_top_too_much: "最多置顶 {c} 个项目"
  },
  inquiries: {
    n_quotes: "共 {c} 份报价, 点击切换",
    only_quote: "共 {c} 份报价",
    use_quote: "选择报价厂家",
    replace: "切换",
    state: "选择状态",
    all: "所有询价",
    my: "我的询价",
    please_wait: "厂家尚未报价",
    sample: "下载 Excel 模版",
    new: "上传图片或 Excel 文件询价",
    show: "报价详情",
    edit: "更改询价",
    delete: "删除询价",
    do_inquiry: "立即询价",
    inquiry_again: "再询一单",
    inquiry_with_list: "产品清单询价",
    inquiry_with_image: "产品图片询价",
    inquiry_now: "立即询价",
    //req: "询价说明",
    budget: "预算范围",
    tier: "质量标准",
    t0: "未知标准",
    t1: "工装标准",
    t2: "家装标准",
    t3: "进口原版",
    st0: "潮",
    st1: "工",
    st2: "家",
    st3: "进",
    name: "备注",
    req: "可选, 项目/询价补充备注",
    res: "报价说明",
    file: "询价文件",
    file_suggestion: "产品列表/图片/空间效果图上传",
    file_with_limits: "支持图片和 Excel 表格\nPC端询价请登录 https://zhuchaox.com ",
    file_select_images: "图片",
    file_select_excel: "Excel 表格",
    submit: "提交询价",
    place_order: "联系客服下单",
    download_inquiry: "下载原始询价单",
    download_quotation: "下载 Excel 格式报价单",
    optional_budget: "可选, 提供预算报价更准",
    create_at: "项目创建时间",
    update_at: "报价回复时间",
    state_sample: "示例",
    state_read: "已查看",
    state_new: "新回复",
    state_updating: "待更新",
    state_wait: "报价中",
    empty_list: "没有{state}的询价",
    placeholder_req: "询价示例",
    placeholder_res: "报价回复将在这里显示"
  },
  deals: {
    price_not_chosen: "未选择",
    not_chosen: "未选择",
    chosen: "已选择",
    unit: "个产品",
    summary: "其中",
    count: "产品数",
    quantity: "总件数",
    before_submit: "点击下单按钮表示您接受{app}交易协议。",
    shipping_info: "收货地址",
    detail_mode: "费用明细",
  },
  quotation: {
    dashboard: "待报价的预订单",
    empty_list: "没有待抢预订单",
    empty_list_of_state: "没有 {s} 的预订单",
    state_new: "待报价",
    state_wait: "已报价",
    state_updating: "待更新",
    state_lock: "已锁定",
    state_read: "已失效",
    add: "新增其它报价",
    recall: "撤销当前报价",
    my_quote_history: "我的历史报价",
    quote_history: "历史报价",
    use_quote: "使用报价",
    new_quote: "重新报价",
    my_quote: "我的报价",
    quoted: "已报价",
    unquoted: "未报价",
    unit_price: "单价",
    quote: "报价",
    quote_unit_price: "单价",
    tap_to_edit: "点击设置",
    valid_date: "报价有效期",
    valid_date_empty: "无有效期",
    valid_date_desc: "报价有效期(最短10天, 可不填, 不填表示报价永久有效)",
    before_submit: "提交报价表示您接受{app}交易协议，且在报价有效期内任何时间，您接受设计师以当前报价单中已报价格下单。设计师下单前，您仍可回到此页面修改报价，设计师下单后，整单报价将无法修改。",

    all: "所有预订单",
    my: "所有报价",
    please_wait: "系统报价中, 请耐心等待",
    sample: "下载 Excel 模版",
    new: "上传图片或 Excel 文件询价",
    download_inquiry: "下载询价单",
    new_inquiry: "新询价",
    new_size: "新尺寸",
    new_material: "新材质",
    inquiry_asap: "请尽快报价",
    create: "报价",
    edit: "修改报价",
    inquiry_now: "立即报价",
    add_product: "添加商品",
    budget: "设计师预算",
    tier: "标准",
    req: "设计师备注",
    res: "报价说明",
    file: "报价文件",
    file_with_limits: "报价文件(支持图片和 Excel)",
    submit: "提交报价",
    choose: "选择订单",
    client: "客户",
    desc: "说明",
    files: "文件",
    memo: "报价备注"
  },
  flags: {
    active: "激活",
    standard: "标品库",
    customizable: "定制产品库",
    ec: "推荐",
    new: "新品"
  },
  materials: {
    empty_list: "没有找到满足该条件的材质",
    select_color: "选择颜色",
    select_item: "选择颜色和供应商",
    all: "材质库",
    customized: "产品材质已修改, 请更新报价",
    customize: "修改材质",
    new: "新材质",
    presets: "预设类型",
    customizable: "产品可选材质类型",
    upload: "材质库批量上传(仅支持csv格式)",
    export: "批量导出",
    scanner: "{app}材质码",
    scanner_guide: "请将材质条码对准扫描框",
    code: "{app}材质码",
    goto: "扫描到{app}码 $text, 立即查看这个材质的详细信息吗?",
    rescan: "重新扫描",
    show: "查看材质信息",
    denied: "没有权限",
    camera_denied: "没有使用相机权限, 无法使用扫描功能. 如需扫描材质条码, 请到系统权限设置中授予{app} App 相机权限",
    not_allowed: "该材质不在当前产品材质部位的可选范围内, 无法使用该材质",
    alert_for_price: "更换不同等级的材质会导致估价和厂家报价失效, 需要厂家针对新材质重新报价后才能下单. 确定要更换材质吗?",
    quote_expired: "因材质等级改变, 部分厂家报价已失效, 请等待厂家重新报价.",

    id: "id",
    identifier: "编号",
    title: "材质名称",
    desc: "简介",
    media: "图片",
    type: "类型",
    state: "状态",
    uses: "用途",
    create_at: "创建时间",
    update_at: "更新时间",
    optional: "可选属性",

    domain: "域",
    kingdom: "类别",  // "界",
    division: "门",
    phylum: "门",
    clazz: "种类",  // "纲",
    legion: "部",
    cohort: "群",
    section: "派",
    order: "目",
    family: "品类",  // "科",
    tribe: "族",
    genus: "属",
    species: "品名",  // "种",
    quality: "等级",
    content: "原料/成分",
    content_src: "原料来源",
    origin: "原料产地",
    region: "产地",
    color: "色值",
    color_system: "色系",
    cs: "色系",
    cn: "颜色名",
    color_name: "颜色名",
    appearance: "外观",
    s_id: "供应商id",
    s_name: "供应商",
    s_mid: "供应商产品编号",
    s_geo: "供应商地区",
    specs: "规格",
    currency: "货币",
    unit: "计价单位",
    ex_factory: "出厂价",
    wholesale: "批发价",
    retail: "零售价",
    cut: "零剪价",
    es: "环保等级",
    es_hcho: "甲醛释放量",

    mc: "含水率",
    density: "密度",
    hardness: "硬度",
    gw: "克重",
    inoxidability: "抗氧化性",
    wetfastness: "耐湿性",
    agglutinate: "胶合性",
    marp: "耐磨",
    mop: "防虫工艺",
    wap: "防水工艺",
    etp: "防腐性",
    atp: "抗起毛球",
    dry_marp: "干磨",
    wet_marp: "湿磨",
    tech: "金属处理工艺",
    weave: "编织工艺",
    tan: "鞣制工艺",
    surface: "表面工艺",
    pc: "适合漆面",
    paint: "漆面工艺"
  },
  products: {
    pre_order: "向厂家询价",
    collect_and_inquiry: "加入项目并估价",
    collect: "加入项目",
    collected: "已加入",
    request_value: "请求估价",
    wanted: "单品估价",
    quoted: "单品报价",
    my_quote: "我的报价",
    valued: "估价更新",
    active: "产品库",
    standard: "成品库",
    customizable: "定制产品库",
    ec: "推荐",
    new: "新品",
    empty_list: "没有找到满足该条件的产品",
    id: "id",
    identifier: "id",
    title: "标题",
    desc: "简介",
    media: "图片",
    type: "类型",
    currency: "货币",
    item_type: "类型",
    item_designer: "设计师",
    item_style: "风格",
    item_space: "空间",
    item_brand: "品牌",
    item_manufacturing_time: "生产周期",
    designer: "设计师",
    style: "风格",
    space: "空间",
    brand: "品牌",
    manufacturing_time: "生产周期",
    specs: "模型",
    n_specs: "{c} 个模型",
    model: "模型",
    item_model: "型号",
    state: "状态",
    quoted_price: "报价",
    price: "价格",
    price1: "估价",
    price2: "指导零售价",
    all: "产品列表",
    sku: "SKU",
    size: "尺寸",
    quality: "等级",
    memo: "材质备注",
    material: "材质",
    material1: "材质id",
    material2: "材质列表",
    quantity: "数量",
    tags: "标签",
    q: "数量",
    product: "产品",
    item: "商品",
    product_info: "产品信息",
    item_info: "商品信息",
    select_item: "选择材质和尺寸",
    mats_memo: "材质补充说明",
    select_mats: "选择材质",
    select_size: "选择尺寸",
    select_quality: "选择等级",
    mats_details: "材质信息",
    select_product: "选择产品",
    select_items: "选择商品",

    items: "共{c}件商品",
    total_count: "共{c}个产品",
    inquiry: "加入报价单",
    go_to_cart: "查看购物车",
    checkout: "结算",
    submit: "保存",
    cancel: "取消",
    new_item: "新建商品",
    please_save: "创建并保存产品后才能创建商品",

    candidates: "所有阶段",
    flow: "已选阶段",

    texture: "纹理",
    tri: "三维",
    flat: "二维",
    doc: "文档",

    common: {
      title: "Z",
      specs: "三视图",
      material: "选材",
      manufacturing: "制作中",
      manufactured: "生产完成",
      pack: "包装",
      shipping: "发货",
      received: "收货",
      afterSales: "售后",
      rated: "评价"
    },
    soft: {
      title: "布",
      frame: "钉木生白胚",
      cotton: "贴棉造型",
      sew: "扪工"
    },
    metal: {
      title: "金",
      frame: "焊接组成",
      surface: "表面工艺处理"
    },
    wood: {
      title: "木",
      frame: "白胚组装",
      surface: "打磨处理",
      decor: "涂饰工艺"
    },
    rock: {
      title: "石",
      material: "选料",
      frame: "底座处理"
    }
  },
  orders: {
    working_on_orders: "进行中的订单",
    about: "订单信息",
    payments_details: "支付信息",
    empty_list_with_state: "没有{state}的订单",
    empty_list: "还没有下单",
    empty_dashboard: "暂无信息",
    order_with_customer_service: "联系客服下单",
    deal_to_cart: "全部加入购物车",
    add_to_cart: "加入购物车",
    empty_cart: "购物车是空的",
    go_shopping: "去选购产品",
    following: "产品进度跟踪",
    products: "产品清单",
    deals: "报价单列表",
    buyer: "买家",
    seller: "厂家",
    create: "创建订单",
    publish: "下单",
    submit: "提交订单",
    tender: "招标",
    pay: "立即付款",
    pay_seller: "付款给厂家",
    distribute: "确认拆单",
    accept: "接单",
    payments_due: "有1笔待付款",
    payments_verifying: "有1笔确认中的付款",
    new_message: "有新消息",
    shipping_info: {
      info: "收货人信息",
      name: "收货人",
      address: "收货地址",
      phone: "联系电话"
    },
    short_states: {
      All: "所有订单",
      Idle: "无状态",
      Created: "编辑中",
      Edited: "编辑中",
      Sealed: "已下单",
      Ready: "招标中",
      PaymentDue: "待付款",
      PaymentClaimed: "付款确认中",
      PaymentConfirmed: "买家已付款",
      Partitioned: "已拆单",
      Bidding: "待接单",
      Bid: "已接单",
      Blueprinting: "技术图",
      Prepared: "技术图已确认",
      Preparing: "准备制作",
      Manufacturing: "制作中",
      Manufactured: "制作完成",
      Shipping: "已发货",
      Received: "已收货",
      AfterSales: "售后中",
      Rated: "已评价",
      Done: "制作完成"
    },
    states: {
      All: "所有订单",
      Idle: "无状态",
      Created: "编辑中",
      Edited: "编辑中",
      Sealed: "已下单",
      Ready: "招标中",
      PaymentDue: "待付款",
      PaymentClaimed: "付款确认中",
      PaymentFailed: "付款失败",
      PaymentConfirmed: "买家已付款",
      Partitioned: "已拆单",
      Bidding: "等待厂家接单",
      Bid: "厂家已接单",
      PlatformPaymentClaimed: "平台已付款",
      PlatformPaymentConfirmed: "厂家已收款",
      PlatformPaymentFailed: "平台付款失败",
      Blueprinting: "技术图确认中",
      Prepared: "技术图已确认",
      Preparing: "准备制作",
      Manufacturing: "制作中",
      Manufactured: "制作完成",
      Shipping: "已发货",
      Received: "已收货",
      AfterSales: "售后中",
      Rated: "已评价",
      Done: "制作完成"
    },
    product_states: {
      Idle: "等待接单",
      Sealed: "已下单",
      Bidding: "等待厂家接单",
      Bid: "已接单",
      Blueprinting: "技术图确认中",
      Prepared: "技术图已确认",
      Preparing: "准备制作",
      Manufacturing: "制作中",
      Manufactured: "制作完成",
      Shipping: "已发货",
      Received: "已收货",
      AfterSales: "售后中",
      Rated: "已评价",
      Done: "已关闭",
      Partitioned: "已拆单"
    },
    alert: {
      verified: "确认收款 {a} 元",
      verified_info: "请确保在财务已确认收到金额为 {a}, 付款信息为 {o} 的付款流水的情况下再确认收款",
      verified_without_info: "请确保在财务已确认收到金额为 {a} 的付款流水的情况下再确认收款",
      confirm: "确认已收款",
      confirm_accept: "确认接单",
      confirm_accept_info: "共 {c} 件产品, 总金额 {a} 元",
      accepted: "确认厂家已接单",
      confirm_accepted: "确认",
      request_payment: "发起尾款支付请求",
      notice: "提示",
      states_change_alert: "确认将订单中所有产品的状态修改为 {s} 吗?"
    },
    payment: {
      pay_seller: "付款给厂家",
      pay_all: "支付尾款",
      payment_requested: "尾款支付请求已发送",
      due: "您有一笔金额为 {c} 的待付款",
      verifying: "您有一笔金额为 {c} 的待付款, 尚在确认中",
      seller_payee: "厂家有1笔待收款",
      seller_payee_verifying: "厂家有1笔收款待确认",
      seller_payer: "厂家有1笔待付款",
      you_payee: "有1笔待收款",
      you_payee_verifying: "有1笔收款待确认",
      you_payer: "有1笔待付款",
      paying: "等待收款 {c}",
      user_to_pay: "等待客户付款 {c}",
      pay: "立即付款",
      info: "付款信息",
      copy: "复制付款信息",
      copied: "付款信息已复制到剪切板",
      receiver_bank: "开户银行",
      receiver_num: "收款账号",
      receiver_name: "单位名称",
      receiver_amount: "付款金额",
      after_transfer: "转账完成后, 可在下方提交对公转账付款信息: ",
      external_order_id: "付款信息",
      external_tranaction_info: "可选填, 转账银行账号/金额/流水号/备注等",
      repay: "重新付款",
      verified: "确认收款",
      distribute: "分发订单"
    },

    units: "选择报价厂商",
    all: "订单列表",
    id: "订单号",
    address: "收货地址",
    create_at: "下单时间",
    update_at: "更新时间",
    state: "状态",
    currency: "货币",
    quantity: "数量",
    price: "价格",
    price1: "出厂价",
    price2: "指导零售价",

    external: "产品名",
    edit_external: "编辑产品名",
    identifier: "订单编号",
    title: "标题",
    media: "图片",
    type: "类型",
    item_type: "类型",
    item_designer: "设计师",
    item_style: "风格",
    item_space: "空间",
    item_brand: "品牌",
    item_manufacturing_time: "生产周期",
    designer: "设计师",
    style: "风格",
    space: "空间",
    brand: "品牌",
    manufacturing_time: "生产周期",
    model: "模型",
    item_model: "型号",
    sku: "SKU",
    size: "尺寸",
    material: "材质",
    tags: "标签",
    q: "数量",
    unit: "件",
    download: "下载报价单",
    contact: "联系客服",
    total_items: "共 {c} 件",
    total_count: "共{c}件产品",
    recommended: "AI推荐",

    service_desc: "服务费 = 产品总价 x {s}",
    tax_desc: "税费 = (产品总价 + 运费 + 安装费 + 杂项费用 + 服务费) x {s}",
    miscellaneous_desc: "杂项费用包括包装费、运输保险费等;\n进口产品杂项费用还包括清关/报关费用、港杂费、九立服务费等",
    memo: "备注",
    desc: "简介",
    costs: "费用",
    subtotal: "产品总价",
    tax_rate: "税率",
    taxed: "税后总价",
    tax: "税费",
    service: "服务费",
    shipping: "运费",
    install: "安装费",
    miscellaneous: "杂项费用",
    total: "总计",
    paid: "已付款",
    received: "已收款",
    seller_received: "厂家已收款",
    remove: "删除",
    save: "保存",
    calculate_service: "10%服务费"
  },
  chats: {
    staff: "小潮",
    user: "用户",
    submit: "发送",
    msg: "100字以内",
    play: "点击播放视频"
  },
  currency: {
    CNY: "人民币(¥)",
    EUR: "欧元(€)",
    USD: "美元($)"
  },
  quality: {
    desc: "产品等级说明",
    100: "进口",
    200: "A+",
    300: "A",
    400: "B"
  },
  trackers: {
    submit: "提交",
    upload: "图片上传",
    n_images: "共{c}张图片",
    user_memo: "买家备注",
    seller_memo: "厂家备注",
    my_memo: "我的备注",
    no_tracker: "厂家还没有提交产品制作进度, 请稍候",
    new_tracker_now: "您还没有上传产品制作进度, 点击上传",
    new_message: "{c}条新消息",
    new_actions: "{c}个待确认",
    new_feedback: "备注已更新",
    feedback: "备注",
    feedback_to_seller: "向厂家反馈",
    orthographic_views_needed: "上传三视图并由客户确认无问题后才能修改产品状态",
    ov_now: "立即上传三视图",
    accepted: "已确认",
    to_be_accept: "待对方确认",
    to_accept: "需您确认",
    accept: "确认无问题",
    accept_alert: "确认产品进度",
    accept_confirm: "确认该产品生产节点追踪无问题, 可以继续下一步",
    accept_specs: "确认技术图",
    accept_confirm_specs: "该操作无法撤销, 确认后厂家将按照技术图中所示的规格开始制作, 请确保在所有技术细节均无问题后再确认",
    stage: "制作阶段",
    stages: {
      Blueprinting: "三视图",
      Manufacturing: "制作跟踪",
      Shipping: "发货前确认",
      Received: "收货前确认",
      AfterSales: "售后跟踪",
      Rated: "评价"
    }
  },
  service: {
    pc: "PC端使用请登录 ",
    wechat: "微信",
    wechat_id: "微信号",
    customer_service: "客服",
    customer_service_wechat: "客服微信号",
    copy_on_click: "点击复制客服微信号",
    contact_in_wechat: "在微信里加{app}客服为好友了解更多",
    contact_for_invitation: "在微信里加{app}客服为好友获取邀请码",
    with_plan: "会员等级银卡及以上团队专属特权\n",
    invitation_desc: "将这个专属邀请码发送给团队或公司的同事\n使用此邀请码注册{app}账号时将直接加入这个团队\n你们可以共享团队内的所有数据和权益",
    copy_invitation: "复制邀请码",
    copied: "已复制到剪切板",

    phone: "{app}客服电话",
    call_now: "立即拨打"
  },
  app: {
    or: "或",
    iOS: "苹果 iOS 客户端",
    iOS_search: "在 App Store 中搜索「{app}」",
    iOS_search_seller: "在 App Store 中搜索「{app}厂家版」",
    iOS_link: "点击跳转至 App Store 安装",
    iOS_qr: "或使用手机扫描二维码",

    android: "安卓手机客户端",
    android_link: "点击下载 APK 安装",
    android_qr: "或使用手机扫描二维码"
  }
});