/**
 * Created by milan on 2019/7/18.
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/styles/withStyles';
import T from 'i18n-react';
import { Mode, Event, DataManager } from '../models/';
import Image from '../common/Image';
import InfoButton from '../common/InfoButton';
import { priceDisplay } from '../common/Constants';
import ImagesDialog from '../dialogs/ImagesDialog';
import AlertDialog from '../dialogs/AlertDialog';
import QuoteHistory from '../Quote/QuoteHistory';
import ProgressDialog from '../dialogs/ProgressDialog';
import ProductDialog from '../Product/ProductDialog';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';

import Button from '@material-ui/core/Button';
import ListSubheader from '@material-ui/core/ListSubheader';
import CircularProgress from '@material-ui/core/CircularProgress';

import IconButton from '@material-ui/core/IconButton';
import ViewIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ButtonBase from '@material-ui/core/ButtonBase';

import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Divider from '@material-ui/core/Divider';

import Popover from '@material-ui/core/Popover';
import SellerBoard from '../Unit/SellerBoard';
import AddIcon from '@material-ui/icons/AddBox';
import MinusIcon from '@material-ui/icons/IndeterminateCheckBox';
import Switch from '@material-ui/core/Switch';


const staff = [
  {
    label: '扫描二维码联系住潮小助手',
    description: '或微信内搜索 zhuchaox 添加',
    imgPath:
      'https://aifa.oss-cn-qingdao.aliyuncs.com/wechat.jpg'
  }
];


const styles = theme => ({
  root: {
    //position: 'relative'
    marginBottom: 100
  },
  box: {
    paddingBottom: 160
  },
  header: {
    border: `0.5px solid ${theme.palette.primary.main}`,
    position: 'fixed',
    padding: theme.spacing(1, 2, 1, 2),
    height: 50,
    bottom: 6,
    zIndex: 101,
    width: '100%',
    maxWidth: 'inherit',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      height: 100,
      bottom: 6
    }
  },
  headerRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  paper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0)
  },
  list: {
    width: '100%'
    //marginTop: theme.spacing(4),
    //backgroundColor: theme.palette.background.paper
  },
  costBlock: {
    padding: theme.spacing(0, 2, 0, 2)
  },
  leading: {
    width: 54,
    minWidth: 54,
    maxWidth: 54,
    margin: theme.spacing(1)
  },
  id: {
    display: 'block',
    fontSize: 24,
    width: '100%'
  },
  label: {
    lineHeight: 2,
    fontSize: 14,
    marginLeft: theme.spacing(1)
  },
  costLabel: {
    lineHeight: 1.5,
    fontSize: 14,
    whiteSpace: 'pre-line',
    margin: theme.spacing(0.5)
  },
  info: {
    lineHeight: 1.5,
    fontSize: 14
  },
  importantInfo: {
    lineHeight: 2,
    fontSize: 18
  },
  cell: {
    backgroundColor: 'white',
    padding: theme.spacing(2, 1, 2, 1)
  },
  subheader: {
    fontSize: 12,
    lineHeight: 2,
    paddingLeft: theme.spacing(2.5),
    color: '#4A90E2',
    backgroundColor: '#ebf3fd'
  },
  costsHeader: {
    fontSize: 12,
    lineHeight: 2,
    padding: theme.spacing(0, 2.5, 0, 2.5),
    color: '#4A90E2',
    backgroundColor: '#ebf3fd',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  textBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  state_new: {
    fontSize: 11,
    backgroundColor: 'red',
    color: 'white',
    borderRadius: 30,
    marginBottom: 3
  },
  state_wait: {
    fontSize: 11,
    backgroundColor: 'green',
    color: 'white',
    borderRadius: 30,
    marginBottom: 3
  },
  state_read: {
    fontSize: 11,
    backgroundColor: 'orange',
    color: 'white',
    borderRadius: 30,
    marginBottom: 3
  },

  titleBlock: {
    padding: theme.spacing(0, 0, 0, 1),
    minHeight: 110,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'space-between',
    justifyContent: 'space-between'
  },
  rightBlock: {
    padding: theme.spacing(0, 0, 0, 1),
    minHeight: 110,
    minWidth: 110,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
    justifyContent: 'space-between'
  },
});


class Show extends Component {
  id = -1;
  deal = null;
  rates = null;
  alerted = false;
  keys = ['subtotal', 'shipping', 'install', 'miscellaneous', 'service', 'total'];
  desc = ['miscellaneous', 'service', 'tax'];
  unit_histories = {};

  constructor(props) {
    super(props);

    this.state = {
      show: false,
      expanded: true,
      requesting: false,

      history: -1,
      selected: -1,
      contact: false,
      no_order: false,
      exceling: false
    };

    let id = props.match.params.id;
    if (id) this.id = parseInt(id);
    if (this.id > 0) {
      this.show();
    }
  }

  componentDidMount() { 
    this._ismounted = true;
  }
  
  componentWillUnmount() {
     this._ismounted = false;
  }

  detail_mode() {
     return this.deal && this.deal.cfg && this.deal.cfg.d === 1;
  }
  
  config_deal = (e) => {
    this.deal.cfg.d = e.target.checked ? 1 : 0;
    let self = this;
    DataManager.getInstance().config_deal(this.id, {cfg: this.deal.cfg}).then(function (res) {
      if (res.c !== 0) {
        DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'error', m: T.translate('notification.failed')});
      }
      self.on_select_unit(self.state.unit_id, true);
    });
  };
  
  show = () => {
    let self = this;
    DataManager.getInstance().show_deal(this.id, {d: 0}).then(function (res) {
      if (res.c === 0) {
        const deal = res.d.d;
        deal.items.sort((l, r) => (l.category >= r.category) ? -1 : 1);
        self.deal = deal;
        self.rates = res.d.rates;
        self.quotes = res.d.quotes;
        let i;
        for (i = 0; i < res.d.units.length; ++i) {
          const unit = res.d.units[i];
          if (!(unit.fee >= 0)) unit.fee = 0.05;
          self.unit_histories[unit.id] = {};
        }
        self.units = res.d.units;

        const items = self.deal.items;
        for (i = 0; i < items.length; ++i) {
          const item = items[i];
          item.qty = item.quantity;
          item.priceDisplay = item.price;
        }
        self.on_select_unit(res.d.unit_id, true);
        // self.calculate();
        // self.setState({show: true, unit_id: res.d.unit_id});
      }
    });
  };

  on_select_unit = (unit_id, firstRun) => {
    let i, j;
    let unit;
    if (this.units && this.units.length > 0) {
      for (i = 0; i < this.units.length; ++i) {
        const u = this.units[i];
        if (u.id === unit_id) {
          unit = u;
          break;
        }
      }
      if (!unit)
        unit = this.units[0];
    }
    this.unit = unit;

    const items = this.deal.items;
    for (i = 0; i < items.length; ++i) {
      const item = items[i];
      item.price = '';
      item.memoranda = undefined;
      const quotes = this.quotes[item.item_id];
      if (quotes && quotes.length > 0) {
        for (j = 0; j < quotes.length; ++j) {
          const quoted = quotes[j];
          if (quoted.unit_id === unit_id) {
            let price = quoted.price;
            item.priceDisplay = price;
            if (!this.detail_mode() && price) {
              price = parseFloat(price);
              const rate = this.rates[item.currency];
              price = rate * price;
              price += price * unit.fee;
              price += parseInt(item.shipping || 0);
              price += parseInt(item.install || 0);
              price += parseInt(item.miscellaneous || 0);
              item.priceDisplay = Math.round(price);
            }
            item.price = price;
            // if (unit.id > 0 && unit.type === 2)
              item.memoranda = quoted.memo;
            break;
          }
        }
      }
    }
    this.calculate(firstRun);
    this.setState({show: true, unit_id: unit_id, selected: -1});
  }

  calculate = (firstRun) => {
    let chosen = 0;
    let count = 0;
    let q = 0;
    let quantity = 0;
    let subtotal = 0;
    let shipping = 0;
    let install = 0;
    let miscellaneous = 0;
    const summary = {};

    const items = this.deal.items;
    let i, qty;
    for (i = 0; i < items.length; ++i) {
      const item = items[i];
      qty = item.qty;
      quantity += qty;
      count++;

      const price = parseFloat(item.price || -1);
      if (item.price && price >= 0 && item.qty > 0 && item.quantity >= 0){
        const rate = this.detail_mode() ? this.rates[item.currency] : 1;
        const t = rate * price * qty;

        chosen++;
        q += qty;
        subtotal += t;
        shipping += (item.shipping || 0) * qty;
        install += (item.install || 0) * qty;
        miscellaneous += (item.miscellaneous || 0) * qty;
      }
    }
    this.deal.shipping = shipping;
    this.deal.install = install;
    this.deal.miscellaneous = miscellaneous;

    this.deal.quantity = quantity;
    this.deal.subtotal = subtotal;

    this.deal.service = subtotal * this.unit.fee;
    if (this.detail_mode()) {
      subtotal += shipping + install + miscellaneous;
      subtotal += this.deal.service;
    }
    this.deal.total = subtotal;
    // this.deal.tax = this.deal.tax_rate * 0.01 * subtotal;
    // this.deal.total = subtotal + this.deal.tax;

    for (i = 0; i < this.keys.length; ++i) {
      const key = this.keys[i];
      const value = Math.round(this.deal[key]);
      this.deal[key] = value > 0 ? value : '0';
      //this.deal[key] = value > 0 ? priceDisplay(value, this.deal.currency) : `${symbol}0`;
    }
    this.deal.chosen = chosen;
    this.deal.count = count;
    this.deal.cq = q;
    this.summary = summary;
    if (!firstRun && !this.alerted) {
      this.alerted = true;
      DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'info', m: T.translate('dialogs.lack_of_accuracy')});
    }
    this.setState({updated: this.state.updated + 1});
  };

  item_checked = (e) => {
    const name = e.target.name;
    const index = parseInt(name);
    this.deal.items[index].chosen = e.target.checked;
    this.calculate();
    if (!this.alerted) {
      this.alerted = true;
      DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'info', m: T.translate('dialogs.lack_of_accuracy')});
      // this.setState({alert: true});
    }
    //this.setState({show: true, requesting: false});
  };

  get_excel = (e) => {
    // if (this.state.excel_url) {
    //   window.open(this.state.excel_url, '_self');
    //   return;
    // }
    this.setState({exceling: true});
    this.request_excel({f: ''});
  };

  request_excel = (params) => {
    let self = this;
    DataManager.getInstance().request_deal_excel(this.id, params).then(function (res) {
      if (res.c === 0) {
        if (res.d.url) {
          const url = `${res.d.url}?id=${DataManager.getInstance().storage.token}&m=0`;
          self.setState({excel_url: url, exceling: false});
          window.open(url, '_self');
          //const link = document.createElement('a');
          //link.href = inquiry.req_file;
          //document.body.appendChild(link);
          //link.click();
          //document.body.removeChild(link);
          return;
        }
        const state = res.d.state;
        if (self._ismounted && state < 10) {
          setTimeout(function () {
            self.request_excel({f: res.d.f});
          }, 5000);
        }
      }else{
        DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'failed', m: T.translate('errors.500')});
      }
    });
  };

  get_excel_legacy = (e) => {
    if (this.state.requesting) return;
    if (this.deal.external) {
      window.open(this.deal.external, '_self');
      return;
    }
    this.setState({requesting: true});
    let self = this;
    DataManager.getInstance().show_deal_with_excel(this.id, null).then(function (res) {
      if (res.c === 0) {
        self.deal.external = res.d.url;
        self.setState({requesting: false});
        window.open(self.deal.external, '_self');
      }
    });
  };

  as_order = (e) => {
    const deal = this.deal;
    if (deal.state < 10) {
      this.setState({no_order: true});
    }else{
      DataManager.getInstance().pub(Event.REDIRECT, `/cart/checkout/?${this.id}`);
    }
  };

  tender = (e) => {
    DataManager.getInstance().pub(Event.REDIRECT, `/deals/t/${this.id}`);
  };

  expand = (e) => {
    this.setState({expanded: !this.state.expanded});
  };

  selected = (id, index) => {
    this.setState({selected: index});
    //DataManager.getInstance().pub(Event.REDIRECT, `/products/i/${id}`);
  };

  deselect = () => {
    const item = this.deal.items[this.state.selected];
    const quotes = this.quotes[item.item_id];
    if (quotes && quotes.length > 0) {
      for (var j = 0; j < quotes.length; ++j) {
        const quoted = quotes[j];
        if (quoted.unit_id === 0) {
          quoted.memo = item.memo;
          break;
        }
      }
      this.on_select_unit(this.state.unit_id, true);
    }else{
      this.show();
    }
    // this.setState({selected: -1});
  };

  show_contact = (e) => {
    this.setState({contact: true});
  };

  close_contact = (e) => {
    this.setState({contact: false});
  };

  quote_history = (e) => {
    const index = parseInt(e.currentTarget.dataset.tag);
    const item = this.deal.items[index];
    const uid = this.unit.id;
    const key = `${item.product_id}.${item.item_id}`;
    const self = this;
    if (self.unit_histories[uid][key]) {
      self.setState({history: index});
      return;
    }
    const params = {uid: uid, did: this.deal.id, pid: item.product_id, iid: item.item_id};
    DataManager.getInstance().show_quote_history(params).then(function (res) {
      if (res.c === 0) {
        const items = res.d.items;
        let i;
        for (i = 0; i < items.length; ++i) {
          const item = items[i];
          if (item.deal_id && item.deal_id === params.did) item.chosen = true;
        }
        self.unit_histories[uid][key] = items;
        self.setState({history: index});
      }
    });
  }

  render() {
    if (!this.state.show) return <React.Fragment/>;
    const self = this;

    return (
      <React.Fragment>
        {this.table()}
        <ProductDialog
          customizable={true}
          deal_id={this.deal.id}
          open={this.state.selected >= 0}
          onClose={this.deselect}
          product={this.state.selected >= 0 ? this.deal.items[this.state.selected] : undefined}
        />
        <ProgressDialog open={this.state.exceling} text="生成 Excel 文件可能需要数秒到几分钟不等的时间, 请不要离开页面, 耐心等待" />

        <QuoteHistory
          open={this.state.history >= 0}
          mode={0}
          unit={this.unit}
          product={this.state.history >= 0 ? this.deal.items[this.state.history] : undefined}
          history={this.state.history >= 0 ? this.unit_histories[this.unit.id] : undefined}
          onClose={() => self.setState({history: -1})}
        />
        <AlertDialog
          open={this.state.no_order}
          onClose={e => {
            self.setState({no_order: false, contact: e.currentTarget.dataset.tag === '0'});
          } }
          title={T.translate('dialogs.alert')}
          desc={'报价单尚处于估价阶段, 确定所有产品的材质和尺寸并由厂家确认报价后方可下单.\n如已确定所有产品数据, 请在微信中联系「住潮小助手」, 客服确认后即可下单.'}
          left={T.translate('orders.contact')}
          right={T.translate('buttons.i_know')}
        />
      </React.Fragment>
    )
  }

  item_cell(item, index, displayIndex, divider) {
    const { classes } = this.props;

    // const badges = [];
    // if (item.currency !== 'CNY') {
    //   badges.push(
    //     <Typography key={`bc_${displayIndex}`} component="p" align="center" className={classes.state_read}>
    //       {T.translate('inquiries.t3')}
    //     </Typography>
    //   );
    // }
    // if (item.quantity <= 0) {
    //   badges.push(
    //     <Typography key={`bq_${displayIndex}`} component="p" align="center" className={classes.state_new}>
    //       {T.translate('orders.recommended')}
    //     </Typography>
    //   );
    // }
    const label = this.unit.id <= 0 || this.unit.type !== 2 ? T.translate('orders.memo') : T.translate('trackers.seller_memo');

    const self = this;
    return (
      <React.Fragment>
        <ListItem className={classes.cell} key={item.id} divider={divider} alignItems="center" >
          <ListItemAvatar className={classes.leading}>
            <React.Fragment>
              <Typography component="p" align="center" className={classes.id}>{displayIndex < 10 ? '0' : ''}{displayIndex}</Typography>
              {/* {badges} */}
            </React.Fragment>
          </ListItemAvatar>
          <Image
            style={{ height: 130, width: 130, maxWidth: 130, maxHeight: 130, minWidth: 130, minHeight: 130 }}
            alt={item.media[0]}
            src={item.media[0]}
            onClick={() => self.selected(item.id, index)}
          />
          <ListItemText
            primary={
              <Box className={classes.titleBlock}>
                <Box>
                  <Typography component="span" className={classes.label}>{item.title} / {item.item_type}</Typography>
                </Box>
                <Box>
                  <Typography component="span" className={classes.label}>{item.material}</Typography>
                </Box>
                <Box>
                  <Typography component="span" className={classes.label}>{item.size}</Typography>
                </Box>
              </Box>
              }
          />
          <ListItemSecondaryAction>
            <Box className={classes.rightBlock}>
              <Box style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', verticalAlign: 'bottom'}}>
                <Typography component="span" className={classes.label}>{T.translate('orders.quantity')}: &nbsp;</Typography>
                <QuantityEditor item={item} onChange={this.calculate} />
              </Box>
              <Box>
                <Typography style={item.quality === '100' ? {color: 'orange'} : undefined} component="span" className={classes.label}>{T.translate(`quality.${item.quality}`)}</Typography>
              </Box>
              <Box style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', verticalAlign: 'bottom'}}>
                <Typography component="span" className={classes.label}>{T.translate('quotation.unit_price')}: &nbsp;</Typography>
                <Typography data-tag={index} component={ButtonBase} className={classes.boldLabel} onClick={item.price ? this.quote_history : undefined}>
                  {item.price ? priceDisplay(item.priceDisplay, this.detail_mode() ? item.currency : 'CNY') : T.translate('quotation.unquoted')}
                </Typography>
              </Box>
            </Box>
          </ListItemSecondaryAction>
        </ListItem>
        {/* {item.memo &&
        <Box className={classes.cell}>
          <Typography component="p" variant="caption" >{T.translate('orders.memo')}: {item.memo}</Typography>
        </Box>
        } */}
        {item.memoranda &&
        <Box className={classes.cell}>
          <Typography component="p" variant="caption" >
            {label}: {item.memoranda}
            </Typography>
        </Box>
        }
      </React.Fragment>
    )
  }

  table() {
    const { classes } = this.props;
    const deal = this.deal;
    const unit = this.unit;
    const fee = unit ? `${unit.fee * 100}%` : '';
    const keys = this.detail_mode() ? this.keys : ['total'];

    const self = this;
    return (
      <Grid alignItems="center" justify="center" container>
        <Grid item xs={12} sm={8} md={8} lg={6} xl={6} className={classes.root}>
          <React.Fragment>
            <Box style={{paddingBottom: this.state.expanded ? 130 : 0}}>
              <List className={classes.list}>
                {/* <ListSubheader>{deal.items.length} {T.translate('deals.unit')}, {T.translate('orders.total_items', {c: deal.quantity})}</ListSubheader> */}
                <ListSubheader>
                  <Box className={classes.headerRow} style={{paddingTop: 24, paddingBottom: 24}}>
                    <Box className={classes.textBox}>
                      <UnitDisplay unit={unit} />
                      <Typography component="pre" className={classes.label}>
                        {T.translate('quotation.quoted')} {unit.quoted} / {deal.count} {T.translate('deals.unit')}
                      </Typography>
                    </Box>
                    <Box className={classes.textBox}>
                      <UnitsButton units={this.units} chosen={this.state.unit_id} onSelect={this.on_select_unit} />
                    </Box>
                  </Box>
                </ListSubheader>
                {deal.items.map((item, index) => {
                  return <React.Fragment key={item.id}>
                    {index === 0 || item.category !== deal.items[index - 1].category ?
                      <ListSubheader className={classes.subheader}>{item.category}</ListSubheader>
                      :
                      <Divider component="li" />
                    }
                    {self.item_cell(item, index, deal.items.length - index, false)}
                  </React.Fragment>;
                })}
                <ListSubheader className={classes.costsHeader}>
                  {T.translate('orders.costs')}
                  <Box className={classes.textBox}>
                    <Switch
                      checked={deal.cfg.d === 1}
                      onChange={this.config_deal}
                      color="primary"
                    />
                    {T.translate('deals.detail_mode')}
                  </Box>
                </ListSubheader>
                <ListItem className={classes.cell} alignItems="center" >
                  <ListItemText
                    primary={
                      <Box className={classes.costBlock}>
                      {keys.map((key, index) => (
                        <Box key={key} className={classes.textBox}>
                          <Typography component="span" className={classes.costLabel}>{T.translate(`orders.${key}`)}
                          {this.desc.indexOf(key) >= 0 &&
                          <InfoButton text={T.translate(`orders.${key}_desc`, {s: key === 'service' ? fee : `${deal.tax_rate}%`})} />
                          }
                          </Typography>
                          <Typography component="span" className={key === 'subtotal' || key === 'total' ? classes.importantInfo : classes.info}>{priceDisplay(deal[key], deal.currency)}</Typography>
                        </Box>
                      ))}
                      </Box>
                      }
                  />
                </ListItem>
                <ListSubheader className={classes.subheader}>{T.translate('orders.memo')}</ListSubheader>
                <ListItem className={classes.cell} alignItems="center" >
                  <ListItemText
                    primary={
                      <Box className={classes.costBlock}>
                        <Typography className={classes.costLabel}>{unit && unit.desc ? unit.desc : deal.memo}</Typography>
                      </Box>
                    }
                  />
                </ListItem>
              </List>
            </Box>

            <Paper className={classes.header}>
              {/* <Box className={classes.headerRow}>
                <Box className={classes.textBox}>
                  <UnitDisplay unit={unit} />
                  <Typography component="pre" className={classes.label}>
                    {T.translate('quotation.quoted')} {unit.quoted} / {deal.count} {T.translate('deals.unit')}
                  </Typography>
                </Box>
                <Box className={classes.textBox}>
                  <UnitsButton units={this.units} chosen={this.state.unit_id} onSelect={this.on_select_unit} />
                </Box>
              </Box> */}
              <Box className={classes.headerRow}>
                <Box className={classes.textBox}>
                  <Typography component="span" className={classes.label}>
                  {T.translate('deals.chosen')}: {deal.chosen} / {deal.count} {T.translate('deals.unit')}, {T.translate('orders.total_items', {c: deal.cq})},
                  </Typography>
                  <Typography component="span" className={classes.label}>{T.translate('orders.total')}: &nbsp;</Typography>
                  <Typography component="span" className={classes.importantInfo}>{priceDisplay(deal.total, deal.currency)}</Typography>
                </Box>
                <Box className={classes.textBox}>
                  {DataManager.getInstance().permit('Price') &&
                  <Button variant={deal.state >= 10 ? 'contained' : 'outlined'} color="primary" size="small" onClick={this.as_order} disabled={this.state.requesting} >
                    {T.translate('orders.publish')}
                  </Button>
                  }
                  {this.state.requesting && <CircularProgress className={classes.progress} size={24} />}
                </Box>
                <DropdownButton id={this.id}>
                  <MenuItem onClick={this.show_contact}>{T.translate('orders.contact')}</MenuItem>
                  <MenuItem onClick={this.get_excel} disabled={this.state.requesting}>{T.translate('orders.download')}</MenuItem>
                  {DataManager.getInstance().mode >= Mode.ADMIN &&
                    <React.Fragment>
                      <MenuItem onClick={this.tender}>{T.translate('orders.tender')}</MenuItem>
                    </React.Fragment>
                  }
                </DropdownButton>
              </Box>
            </Paper>

            <ImagesDialog
              images={staff}
              title={staff[0].label}
              desc={staff[0].description}
              open={this.state.contact}
              onClose={this.close_contact}
            />
          </React.Fragment>
        </Grid>
      </Grid>
    )
  }
}


function UnitDisplay(props) {
  const { unit } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const handleClick = event => {
    if (unit.type !== 2) return;
    if (anchorEl) setAnchorEl(null);
    else setAnchorEl(event.currentTarget);
  };
  const open = Boolean(anchorEl);


  return (
    <div style={{display: 'inline-block'}}>
      <Button color="primary" size="large" onClick={handleClick} >
        {unit.display}
      </Button>
      {open &&
      <Popover
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <SellerBoard unit={unit} />
      </Popover>
      }
    </div>

  );
}


function UnitsButton(props) {
  const { units, onSelect } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [chosen, setChosen] = React.useState(props.chosen || 0);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (e) => {
    const tag = parseInt(e.currentTarget.dataset.tag);
    setAnchorEl(null);
    setChosen(tag);
    if (onSelect)
      onSelect(tag);
  };

  return (
    <React.Fragment>
      <Button variant="outlined" color="primary" size="small" onClick={handleClick} disabled={units.length <= 1} >
        {T.translate(units.length <= 1 ? 'inquiries.only_quote' : 'inquiries.n_quotes', {c: units.length})}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {units && units.map((unit, index) => (
          <MenuItem selected={chosen === unit.id} key={unit.id} data-tag={unit.id} onClick={handleSelect}>{unit.display}</MenuItem>
        ))}
        {props.children}
      </Menu>
    </React.Fragment>
  );
}


function DropdownButton(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <IconButton
        color="primary"
        aria-haspopup="true"
        aria-label={props.id}
        data-tag={props.id}
        onClick={handleClick}
      >
        <ViewIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <ButtonBase style={{display: 'flex', flexDirection: 'column'}} onClick={handleClose}>{props.children}</ButtonBase>
      </Menu>
    </React.Fragment>
  );
}


function QuantityEditor(props) {
  const { item, maxQuantity, onChange } = props;
  const m = maxQuantity && maxQuantity > 0 ? maxQuantity : 99;
  const active = !!item.price;

  const [quantity, setQuantity] = React.useState(item.qty);

  const handleClick = (e) => {
    item.qty = quantity + parseInt(e.currentTarget.dataset.tag);
    setQuantity(item.qty);
    if (onChange)
      onChange();
  };
  const ds = {display: 'inline-flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', verticalAlign: 'bottom'};
  return (
    <React.Fragment>
      <div style={ds} onClick={e => e.stopPropagation()}>
        <IconButton style={{padding: 0, paddingRight: 6}} color="primary" size="medium" data-tag={-1} onClick={handleClick} disabled={!active || quantity <= 0}>
          <MinusIcon/>
        </IconButton>
        <Typography component="span" variant="subtitle1" style={{textAlign: 'center'}} >{active ? quantity : 0}</Typography>
        <IconButton style={{padding: 0, paddingLeft: 6}} color="primary" size="medium" data-tag={1} onClick={handleClick} disabled={!active || quantity >= m}>
          <AddIcon/>
        </IconButton>
      </div>
    </React.Fragment>
  );
}


export default withRouter(withStyles(styles)(Show));