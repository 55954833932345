/**
 * Created by milan on 2019/7/18.
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/styles/withStyles';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
//import TextField from '@material-ui/core/TextField';
import { TextField } from 'formik-material-ui';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Topbar from '../Topbar';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { Formik, Form, Field } from 'formik';
import T from 'i18n-react';
import { Mode, Event, DataManager } from '../models';
import Menu from '../Menu';


const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white
    }
  },
  paper: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2)
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
});

const validate = values => {
  let errors = {};
  if (!values.name) {
    errors.name = T.translate('errors.required');
  } else if (!/^[1]([3-9])[0-9]{9}$/.test(values.name)) {
    errors.name = T.translate('errors.invalid_phone');
  //} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.name)) {
  //  errors.name = T.translate('errors.invalid_email');
  }
  if (!values.password) {
    errors.password = T.translate('errors.required');
  } else if (values.password.length < 8) {
    errors.password = T.translate('errors.min_length', {length: 8});
  }
  if (Object.keys(errors).length)
    return errors;
};

class SignIn extends Component {
  state = {
    debug: false
  };

  componentDidMount() {}

  action = (values, actions) => {
    return DataManager.getInstance().sign_in(values).then(function (res) {
      let errors = {};
      if (res.c !== 0) {
        errors.name = T.translate('errors.' + res.c);
        actions.setSubmitting(false);
        if (Object.keys(errors).length) {
          actions.setErrors(errors);
          //actions.setStatus(errors);
          return;
        }
      }
      let primary = Menu.primary(DataManager.getInstance().mode);
      const to = primary.items[0].pathname;

      if (DataManager.getInstance().mode >= Mode.ADMIN && window.location.pathname !== '/a/') {
        window.location = `/a/#${to}`;
      }else if (DataManager.getInstance().mode === Mode.BUSINESS && window.location.pathname !== '/s/') {
        window.location = `/s/#${to}`;
      }else if (DataManager.getInstance().mode < Mode.BUSINESS && window.location.pathname !== '/') {
        window.location = `/#${to}`;
      }
      DataManager.getInstance().pub(Event.REDIRECT, to);
    });
  };

  render() {
    const { classes } = this.props;
    const currentPath = this.props.location.pathname;
    return (
      <React.Fragment>
        <CssBaseline />
        <Topbar currentPath={currentPath} />
        <Container component="main" maxWidth="xs">
          <Paper className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              {T.translate("signin")}
            </Typography>
            <Formik
              initialValues={{ name: '', password: '' }}
              validate={validate}
              validateOnChange={true}
              onSubmit={this.action}
            >
              {props => {
                return (
                  <Form className={classes.form} noValidate>
                    <Field
                      component={TextField}
                      error={!!props.errors.name}
                      helperText={props.errors.name}
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="name"
                      label={T.translate("form.phone")}
                      name="name"
                      autoComplete="username"
                    />
                    <Field
                      component={TextField}
                      error={!!props.errors.password}
                      helperText={props.errors.password}
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      name="password"
                      label={T.translate("form.password")}
                      type="password"
                      id="password"
                      autoComplete="password"
                    />
                    <FormControlLabel
                      control={<Checkbox value="remember" color="primary" />}
                      label={T.translate("remember_me")}
                    />
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      disabled={props.isSubmitting}
                    >
                      {T.translate("buttons.signin")}
                    </Button>
                    <Grid container>
                      <Grid item xs>
                        <Link href="#/reset" variant="body2">
                          {T.translate("forgot_password")}
                        </Link>
                      </Grid>
                      <Grid item>
                        <Link href="#/signup" variant="body2">
                          {T.translate("no_account")}
                        </Link>
                      </Grid>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </Paper>
        </Container>
      </React.Fragment>
    )
  }
}

export default withRouter(withStyles(styles)(SignIn));