import React, {Component} from 'react';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/styles/withStyles';
import Container from '@material-ui/core/Container';
import { Link } from 'react-router-dom';
import T from 'i18n-react';
import Hidden from '@material-ui/core/Hidden';
import { Link as ExternalLink } from '@material-ui/core';


const styles = theme => ({
  footer: {
    padding: theme.spacing(2),
    textAlign: 'center',
    position: 'absolute',
    bottom: -200,
    width: '100%',
    height: 200,
    backgroundColor: 'transparent'
  },
  footerContainer: {
    textAlign: 'center',
    height: 64
  },
  links: {
    padding: theme.spacing(2)
  }
});


class Footer extends Component {

  render() {
    const { classes } = this.props;
    const ba = window.location.hostname.toLowerCase().indexOf('chaocasa') >= 0 ? '3' : '2';

    return (
      <footer className={classes.footer}>
        <Hidden smDown>
        <Container maxWidth="lg" className={classes.footerContainer}>
          <Link className={classes.links} to="/docs/contact/">{T.translate('title.contact')}</Link>
          <Link className={classes.links} to="/docs/about/">{T.translate('title.about_us')}</Link>
          <Link className={classes.links} to="/docs/policy/">{T.translate('title.policy')}</Link>
          <Link className={classes.links} to="/docs/terms/">{T.translate('title.terms')}</Link>
          <Typography variant="body1">
            Copyright © 2019 zhuchaox.com. All rights reserved. <ExternalLink href="https://beian.miit.gov.cn/">京ICP备案18040431号-{ba}</ExternalLink> | 京公网安备 11010502033607
          </Typography>
        </Container>
        </Hidden>
      </footer>
    )
  }
}

export default withStyles(styles)(Footer);