/**
 * Created by milan on 2019/7/18.
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/styles/withStyles';
import T from 'i18n-react';
import { Event, DataManager } from '../models/';
import { priceDisplay } from '../common/Constants';

import MaterialTable from 'material-table';
import { tableIcons } from '../common/Constants';


const styles = theme => ({
  root: {

  }
});


class List extends Component {
  productColumns = [
    { title: T.translate('orders.id'), field: 'id', editable: 'never' },
    { title: T.translate('orders.quantity'), field: 'quantity', editable: 'never' },
    { title: T.translate('orders.subtotal'), field: 'subtotal', editable: 'never' },
    { title: T.translate('orders.total'), field: 'total', editable: 'never' },
    { title: T.translate('orders.memo'), field: 'memo', editable: 'never' },
    { title: T.translate('orders.create_at'), field: 'create_at', editable: 'never' },
    { title: T.translate('orders.update_at'), field: 'update_at', editable: 'never' }
  ];
  state = {
    total: 1, page: 1, per: 0, data: []
  };
  tableRef = React.createRef();

  list = (query) => {
    //const self = this;
    let p = query.page > 0 ? query.page + 1 : (parseInt(this.props.location.search.substring(1)) || 1);
    if (p < 0) p = 0;
    return DataManager.getInstance().list_deals({page: p}).then(function (res) {
      if (res.c === 0) {
        let d = res.d;
        const total = d.t;
        const max_page = Math.ceil(total / d.p);
        if (max_page > 0 && p > max_page) p = max_page;
        if (p < 1) p = 1;
        const deals = d.deals;
        for (var i = 0; i < deals.length; ++i) {
          const deal = deals[i];
          deal.subtotal = priceDisplay(deal.subtotal, deal.currency);
          deal.total = priceDisplay(deal.total, deal.currency);
        }
        return {
          data: deals,
          page: p - 1,
          totalCount: total
        };
      }
      return {data: [], page: 0, totalCount: 0};
    })
  };

  new_deal = () => {
    const self = this;
    return DataManager.getInstance().create_deal(null).then(function (res) {
      if (res.c !== 0) {
        self.tableRef.current.onQueryChange();
        return;
      }
      const path = '/' + self.props.match.path.split('/')[1];
      DataManager.getInstance().pub(Event.REDIRECT, `${path}/e/${res.d.id}`);
      //DataManager.getInstance().pub(Event.REDIRECT, `/products/deal/${res.d.id}`);
      //self.tableRef.current.onQueryChange();
    })
  };

  render() {
    if (!this.state.data || this.state.data === 0) return <div></div>;
    const path = '/' + this.props.match.path.split('/')[1];

    const self = this;
    return (
      <React.Fragment>
        <MaterialTable
          tableRef={this.tableRef}
          icons={tableIcons}
          title={T.translate('orders.deals')}
          options={{search: false, pageSize: 20, pageSizeOptions: [20], detailPanelType: 'single'}}
          columns={self.productColumns}
          //actions={[{
          //    icon: () => <tableIcons.Add />,
          //    tooltip: 'New Deal',
          //    isFreeAction: true,
          //    onClick: this.new_deal
          //  }, {
          //    icon: () => <tableIcons.Edit />,
          //    tooltip: 'Edit',
          //    onClick: (event, rowData) => DataManager.getInstance().pub(Event.REDIRECT, `${path}/e/${rowData.id}`)
          //  }
          //]}
          onRowClick={(event, rowData, togglePanel) => DataManager.getInstance().pub(Event.REDIRECT, `${path}/s/${rowData.id}`)}
          //onRowClick={(event, rowData, togglePanel) => togglePanel()}
          //detailPanel={rowData => {
          //  return (
          //    <DenseTable product={rowData} />
          //  )
          //}}
          data={query => self.list(query)}
        />
      </React.Fragment>
    )
  }
}


export default withRouter(withStyles(styles)(List));