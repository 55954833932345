import React,  { Component } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { Event, Mode, DataManager } from '../models/';
import T from 'i18n-react';
import { SellerRoles, CustomerRoles } from '../common/Constants';

import CircularProgress from '@material-ui/core/CircularProgress';


const styles = theme => ({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
    marginTop: theme.spacing(1),
    padding: theme.spacing(1)
  },
  paper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
});


class Privilege extends Component {
  state = {requesting: false};

  constructor(props) {
    super(props);
    this.state.requesting = true;
    this.show();
  }

  closeAlert = (e) => {
    this.setState({selected: -1});
  };

  render() {
    if (!this.roles) {
      this.roles = {};
      this.keys = [];
      const is_seller = DataManager.getInstance().mode >= Mode.BUSINESS;
      const roles = is_seller ? SellerRoles : CustomerRoles;
      for (var k in roles) {
        if (roles.hasOwnProperty(k)) {
          k = parseInt(k);
          this.roles[k] = T.translate(`users.role_${roles[k]}`);
          if (k >= 10)
            this.keys.push(k);
        }
      }
    }
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Grid item xs={12} sm={8} md={8} lg={6} xl={6}>
          <div className={classes.root}>
            <Paper className={classes.paper}>
              {this.state.requesting ?
                  <CircularProgress size={48} />
                :
                this.table()
              }
            </Paper>
          </div>
        </Grid>
      </React.Fragment>
    );
  }

  table = () => {
    // const { classes } = this.props;
    const teams = this.users;
    const user_privileges = this.privileges.u;
    const role_privileges = this.privileges.r;
    const self = this;

    return (
      <React.Fragment>
        {this.keys.map((role, index) => {
          const is_owner = role === '30';
          const role_checked = is_owner || role_privileges.indexOf(role) >= 0;
          const users = teams[role];
          const items = [];
          if (users) {
            let i;
            for (i = 0; i < users.length; ++i) {
              const user = users[i];
              const item = <FormControlLabel
                key={i}
                control={
                  <Checkbox
                    value={user.user_id} 
                    disabled={role_checked}
                    color="primary" 
                    checked={role_checked || user_privileges.indexOf(user.user_id) >= 0}
                    onChange={e => {
                      const index = user_privileges.indexOf(user.user_id);
                      if (index >= 0) {
                        user_privileges.splice(index, 1);
                      }else{
                        user_privileges.push(user.user_id);
                      }
                      self.setState({update: self.state.update + 1});
                    }}
                  />
                }
                label={user.display}
              />;
              items.push(item);
            }
          }
          
          return <React.Fragment key={role}>
            <Box>
              {/* <Typography component="span" variant="h6">{this.roles[role]}</Typography> */}
              <FormControlLabel
                control={
                  <Checkbox
                    value={role}
                    color="primary"
                    disabled={is_owner}
                    checked={role_checked}
                    onChange={e => {
                      const index = role_privileges.indexOf(role);
                      if (index >= 0) {
                        role_privileges.splice(index, 1);
                      }else{
                        role_privileges.push(role);
                      }
                      self.setState({update: self.state.update + 1});
                    }}
                  />
                }
                label={`${T.translate('users.all_has_role')}${this.roles[role]}`}
              />
            </Box>
            <Box>
              {items}
            </Box>
          </React.Fragment>;
        })}
        <Button variant="outlined" color="primary" onClick={this.save}>
          {T.translate('buttons.save')}
        </Button>
      </React.Fragment>
    );
  };

  save = () => {
    const self = this;
    const pid = this.props.match.params.pid;
    const params = {project_id: pid, privileges: this.privileges};
    return DataManager.getInstance().update_privileges(params).then(function (res) {
      if (res.c === 0) {
        const d = res.d;
        self.privileges = d.privileges;
        self.setState({requesting: false});
        DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'success', m: T.translate('notification.success')});
      }else{
        DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'error', m: T.translate('notification.failed')});
      }
    })
  };

  show = () => {
    const self = this;
    const pid = this.props.match.params.pid;
    const params = {project_id: pid};
    return DataManager.getInstance().show_privileges(params).then(function (res) {
      if (res.c === 0) {
        const d = res.d;
        self.privileges = d.privileges;
        self.users = d.users;
        self.setState({requesting: false});
      }
    })
  };
}


export default withStyles(styles)(Privilege);