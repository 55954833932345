import React,  { Component } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import Paper from '@material-ui/core/Paper';

import Cell from '../Order/ItemCell';
import { Event, DataManager } from '../models/';
import { priceDisplay } from '../common/Constants';
import ProductDialog from '../Product/ProductDialog';

import T from 'i18n-react';
import CircularProgress from '@material-ui/core/CircularProgress';
import ListSubheader from '@material-ui/core/ListSubheader';
import Seller from '../common/Seller';


const styles = theme => ({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
    marginTop: theme.spacing(1),
    padding: theme.spacing(1)
  },
  paper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  list: {
    width: '100%'
  },
  content: {
    fontSize: 24,
    margin: theme.spacing(2)
  },
  subheader: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: 36,
    paddingLeft: theme.spacing(2.5),
    color: '#4A90E2',
    backgroundColor: '#ebf3fd'
  }
});


class Products extends Component {
  state = {total: 1, page: 1, per: 0, selected: -1, requesting: false};
  items = null;
  groups = null;
  keys = null;

  constructor(props) {
    super(props);
    this.state.requesting = true;
    this.list(this.state.page);
  }

  displayIndex = (index) => {
    const base = (this.state.page - 1) * this.state.per;
    const display = this.state.total - base - index;
    return display < 10 ? `0${display}` : display;
  };

  selected = (item) => {
    //this.setState({selected: index});
    const pid = this.props.match.params.id;
    DataManager.getInstance().pub(Event.REDIRECT, `/projects/s/${pid}/${this.props.match.params.track}/trackers/${item.order_id}/${item.id}`);
  };

  deselect = () => {
    this.setState({selected: -1});
  };

  grouping = (e) => {
    const by = this.props.match.params.track;
    if (by !== 'tse' && by !== 'tsp') {
      //this.items.sort((l, r) => (l.ui.ua + l.ui.um + l.ui.uc > r.ui.ua + r.ui.um + r.ui.uc) ? -1 : 1);
      this.items.sort((l, r) => (l.update_at > r.update_at) ? -1 : 1);
      return;
    }
    const by_seller = by === 'tse';

    const keys = [];
    const groups = {};
    const items = this.items;
    let i;
    for (i = 0; i < items.length; ++i) {
      const item = items[i];
      const key = by_seller ? item.seller_id : item.category;
      if (!groups[key]) {
        groups[key] = [];
        keys.push(key);
      }
      groups[key].push(i);
    }
    keys.sort();
    this.keys = keys;
    this.groups = groups;
  };

  render() {
    if (!this.items)
      return <div></div>;

    const { classes } = this.props;
    //const pid = this.props.match.params.id;
    //const track = this.props.match.params.track;
    this.grouping();

    return (
      <React.Fragment>
        <ProductDialog open={this.state.selected >= 0} onClose={this.deselect} product={this.state.selected >= 0 ? this.items[this.state.selected] : undefined} />
        <Grid item xs={12} sm={8} md={8} lg={6} xl={6}>
          <div className={classes.root}>
            {this.table()}
          </div>
        </Grid>
      </React.Fragment>
    );
  }

  cell = (index) => {
    const item = this.items[index];
    return <Cell
      key={item.id}
      item={item}
      divider={true}
      hidePrice={true}
      tracking={true}
      onClick={() => this.selected(item)}
    />;
  };

  table = () => {
    const { classes } = this.props;
    const items = this.items;
    const track = this.props.match.params.track || 'tm';
    const by_seller = track === 'tse';

    return (
      <Paper className={classes.paper}>
        {this.state.requesting ?
          <CircularProgress size={48} />
          :
          (items.length === 0 ?
            <Typography className={classes.content}>{T.translate('projects.empty_items')}</Typography>
          :
            <List className={classes.list}>
              {track === 'tm' ?
              this.items.map((item, index) => this.cell(index))
                :
              this.keys.map((key, _) => {
                const group = this.groups[key];
                const item = this.items[group[0]];
                return <React.Fragment key={key}>
                  <ListSubheader className={classes.subheader}>
                    {by_seller ? <Seller seller_id={item.seller_id} title={item.seller} /> : item.category}
                  </ListSubheader>
                  {group.map((index, idx) => this.cell(index))}
                </React.Fragment>
              })}
            </List>
          )
        }
      </Paper>
    );
    //{items.map((item, index) => (
    //  <Cell
    //    key={item.id}
    //    item={item}
    //    index={index}
    //    displayIndex={self.displayIndex(index)}
    //    divider={index !== items.length - 1}
    //    onClick={() => self.selected(item)}
    //  />
    //))}
  };

  list = (page) => {
    const self = this;
    const pid = this.props.match.params.id;
    const params = {page: page};
    return DataManager.getInstance().list_project_products(pid, params).then(function (res) {
      if (res.c === 0) {
        let d = res.d;
        const items = d.items;
        let i;
        for (i = 0; i < items.length; ++i) {
          const item = items[i];
          if (!(item.ui.uc >= 0)) item.ui.uc = 0;
          if (!(item.ui.um >= 0)) item.ui.um = 0;
          if (!(item.ui.ua >= 0)) item.ui.ua = 0;
          item.p1 = priceDisplay(item.price1, item.currency);
          item.p2 = priceDisplay(item.price2, item.currency);
        }
        self.items = items;
        self.setState({requesting: false});
      }
    })
  };
}


export default withStyles(styles)(Products);