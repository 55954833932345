/**
 * Created by milan on 2019/7/18.
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/styles/withStyles';
import T from 'i18n-react';
import { Event, DataManager } from '../models';

import Box from '@material-ui/core/Box';
import MaterialTable from 'material-table';
import { tableIcons, M_VISIBLE, M_ITEMS } from '../common/Constants';
import Papa from "papaparse";

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
// import FormControl from '@material-ui/core/FormControl';


const styles = theme => ({
  root: {

  }
});


class Batch extends Component {
  productColumns = [
    // { title: T.translate('materials.color'), field: 0 }
  ];
  state = {
    total: 1, page: 1, per: 0, data: []
  };
  tableRef = React.createRef();
  file_ref = null;
  csv = [];
  presets = {kts: {}, tks: {}, chosen: {}};

  constructor(props) {
    super(props);
    let i;
    for (i = 0; i < M_VISIBLE.length; ++i) {
      const k = M_VISIBLE[i];
      const t = T.translate(`materials.${k}`);
      this.presets.kts[k] = t;
      this.presets.tks[t] = k;
    }
    for (i = 0; i < M_ITEMS.length; ++i) {
      const k = M_ITEMS[i];
      const t = T.translate(`materials.${k}`);
      this.presets.kts[k] = t;
      this.presets.tks[t] = k;
    }
  }

  save_materials = (params) => {
    const self = this;
    return DataManager.getInstance().save_materials(params).then(function (res) {
      if (res.c !== 0) {
        DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'error', m: T.translate('notification.failed')});
        return;
      }
      DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'success', m: T.translate('notification.success')});
      self.presets.chosen = {};
      self.csv = [];
      self.setState({data: []});
    })
  };

  onClick = (e) => {
    this.file_ref.click();
  }

  submit = (e) => {
    const materials = [];
    for (var i = 0; i < this.state.data.length; ++i) {
      const row = this.state.data[i];
      const material = {};
      for (var idx in this.presets.chosen) {
        if (this.presets.chosen.hasOwnProperty(idx)) {
          const key = this.presets.chosen[idx];
          material[key] = row[idx];
        }
      }
      materials.push(material);
    }
    this.save_materials({'mats': materials});
  };

  updateTable = (csv) => {
    if (!csv || csv.length < 1) return;
    this.csv = csv;
    this.presets.chosen = {};
    const columns = [];
    const rows = [];
    const header = csv[0];
    let i;
    for (i = 0; i < header.length; ++i) {
      const title = header[i];
      const column = {title: title, field: `${i}`};
      columns.push(column);
      const key = this.presets.tks[title];
      if (key) {
        this.presets.chosen[column.field] = key;
      }
    }

    let f;
    for (i = 1; i < csv.length; ++i) {
      const line = csv[i];
      const row = {};
      f = false;
      for (var j = 0; j < line.length; ++j) {
        const column = line[j];
        row[`${j}`] = column;
        if (column && column.length > 0) f = true;
      }
      if (f)
        rows.push(row);
    }
    this.productColumns = columns;
    this.setState({data: rows});
  }

  onUpload = (e) => {
    const self = this;
    const files = e.currentTarget.files;
    // const reader = new FileReader();
    // reader.onload = function() {
    //   const text = reader.result;
    //   console.log(text.substring(0, 200));
    // };
    for (var i = 0; i < files.length; ++i) {
      const f = files[i];
      // if (!f.type.match('image.*')) {
      //   continue;
      // }
      // reader.readAsText(f);
      Papa.parse(f, {complete: function(results) {
        self.updateTable(results.data);
      }});
    }
  }

  render() {
    const self = this;
    return (
      <React.Fragment>
        <Box style={{display: this.csv && this.csv.length > 0 ? 'inherit' : 'none'}}>
          {this.productColumns.map((column) => {
            return <KeySelector 
              key={column.field} 
              k={column.field} 
              presets={this.presets}
              title={column.title}
            // className={classes.prop}
            />;
          })}
          <Button color="primary" variant="outlined" onClick={this.submit} >
            {T.translate('buttons.submit')}
          </Button>
        </Box>
        <MaterialTable
          // tableRef={this.tableRef}
          icons={tableIcons}
          title={T.translate('materials.upload')}
          options={{search: false, pageSize: 20, pageSizeOptions: [20, 50, 100]}}
          columns={this.productColumns}
          actions={[{
            icon: () => <tableIcons.Add />,
            tooltip: 'Upload',
            isFreeAction: true,
            onClick: this.onClick
          }]}
          // editable={{
          //   onRowAdd: self.new_material,
          //   onRowUpdate: self.update_material
          // }}
          // onRowClick={(event, rowData, togglePanel) => DataManager.getInstance().pub(Event.REDIRECT, `${path}/e/${rowData.id}`)}
          data={this.state.data}
        />
        <input
          ref={fileInput => self.file_ref = fileInput}
          style={{display: 'none'}}
          accept=".csv"
          type="file"
          name="file"
          // multiple="multiple"
          onChange={this.onUpload}
        />
      </React.Fragment>
    )
  }
}


function KeySelector(props) {
  const { k, title, presets } = props;
  const [, forceUpdate] = React.useReducer(x => x + 1, 0);

  const handleChange = e => {
    e.preventDefault();
    e.stopPropagation();
    const zc_key = e.target.value;
    presets.chosen[k] = zc_key;
    forceUpdate();
  };

  return (
    <React.Fragment>
      <Box style={{display: 'inline-block', margin: 10}}>
        <InputLabel>{title}</InputLabel>
        <Select
          label={title}
          // labelId={k}
          name={k}
          value={presets.chosen[k] || ''}
          onChange={handleChange}
        >
          {M_VISIBLE.map((key, _) => {
            return <MenuItem key={key} value={key}>{presets.kts[key]}</MenuItem>
          })}
          {M_ITEMS.map((key, _) => {
            return <MenuItem key={key} value={key}>{presets.kts[key]}</MenuItem>
          })}
        </Select>
      </Box>
    </React.Fragment>
  );
}


export default withRouter(withStyles(styles)(Batch));