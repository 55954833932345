import React, { Component } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import { Event, DataManager } from '../models/';
import T from 'i18n-react';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import AddIcon from '@material-ui/icons/AddCircleOutline';

import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { MaterialPicker } from '../Material/Picker';


const styles = theme => ({
  root: {
    backgroundColor: 'white'
  },
  box: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    padding: theme.spacing(0.5)
  },
  title: {
    width: '100%',
    textAlign: 'center'
  },
  chip: {
    margin: theme.spacing(0.5),
    maxWidth: 320
  },
  icon_primary: {
    borderRadius: '50%',
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    height: 20,
    width: 20,
    textAlign: 'center'
  },
  icon: {
    borderRadius: '50%',
    border: `1px solid ${theme.palette.secondary.main}`,
    color: theme.palette.secondary.main,
    height: 20,
    width: 20,
    textAlign: 'center'
  }
});

const types = ['自定义', '沙发', '扶手椅', '餐椅', '床', '柜子', '桌子'];
const defaults = [
  [{k: '自定义', s: undefined}],
  [{k: '表面', s: {kingdom: ['布料', '皮料']}}, {k: '腿部', s: {kingdom: ['木材', '金属']}}],
  [{k: '表面', s: {kingdom: ['布料', '皮料']}}, {k: '腿部', s: {kingdom: ['木材', '金属']}}],
  [{k: '表面', s: {kingdom: ['布料', '皮料']}}, {k: '腿部', s: {kingdom: ['木材', '金属']}}],
  [{k: '床头', s: {kingdom: ['布料', '皮料']}}, {k: '框架', s: {kingdom: ['木材', '金属']}}],
  [{k: '表面', s: {kingdom: ['石材', '木材', '辅材']}}, {k: '顶面', s: {kingdom: ['石材', '木材', '辅材']}}, {k: '腿部', s: {kingdom: ['木材', '金属']}}],
  [{k: '表面', s:{kingdom: ['石材', '木材', '辅材']}}, {k: '腿部', s: {kingdom: ['木材', '金属']}}],
];
// const required = [{k: '模型', s: undefined}];
const required = [];

class MaterialsEditor extends Component {
  source = [];
  chipData = [];
  dragging = -1;
  adding = -1;
  state = {picker: -1};

  constructor(props) {
    super(props);
    this.source = props.labels ? props.labels : [];
    this.chipData = this.source.slice();
    // if (this.chipData[0] !== required[0]) this.chipData.splice(0, 0, required[0]);
  }

  handleDelete = (index) => {
    this.chipData.splice(index, 1);
    this.forceUpdate();
  };

  handleClose = (e) => {
    const tag = e.currentTarget.dataset.tag;
    if (tag === '1') {
      this.chipData.push({k: this.state.value, v: undefined});
    }else{
    }
    this.setState({alert: false, value: ''});
  };

  onBatch = (e) => {
    const index = parseInt(e.currentTarget.dataset.tag);
    if (index === 0) {
      this.setState({alert: true});
      return;
    }

    // const type = types[index];
    const def = defaults[index];
    this.chipData = this.chipData.concat(def);
    this.forceUpdate();
  };

  onDrag = (e) => {
    const tag = e.currentTarget.dataset.tag;
    this.dragging = parseInt(tag);
    this.adding = -1;
  };

  onDrop = (e) => {
    const drop = parseInt(e.currentTarget.dataset.tag);
    const locked = required.indexOf(this.chipData[drop]) >= 0;
    if (this.dragging >= 0 && !locked) {
      const drag = this.chipData[this.dragging];
      this.chipData[this.dragging] = this.chipData[drop];
      this.chipData[drop] = drag;
    // }else if (this.adding >= 0) {
    //   const drag = options[this.adding];
    //   if (this.chipData.indexOf(drag) >= 0) return;
    //   this.chipData.splice(drop + 1, 0, drag);
    }
    this.forceUpdate();
  };

  onSave = (e) => {
    const self = this;
    const product = this.props.product;
    return DataManager.getInstance().save_product_materials(product.id, {mats: this.chipData}).then(function (res) {
      if (res.c !== 0) {
        DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'error', m: T.translate('notification.failed')});
        return;
      }
      product.ctmz = self.chipData;
      DataManager.getInstance().pub(Event.NOTIFICATION, {t: 'success', m: T.translate('notification.success')});
    });
  };

  render() {
    const { classes } = this.props;
    const self = this;
    const chips = this.chipData;
    const picker = self.state.picker >= 0;
    // let savable = chips.length > 0;
    let savable = true;
    const chip_labels = [];
    for (var i = 0; i < chips.length; ++i) {
      const chip = chips[i];
      const labels = [];
      if (chip.s) {
        let k;
        for (k in chip.s) {
          if (chip.s.hasOwnProperty(k) && chip.s[k]) {
            const s = chip.s[k].join('|');
            labels.push(`${T.translate(`materials.${k}`)}:${s}`);
          }
        }
      }
      else if (chip.v) {
        let k;
        for (k in chip.v) {
          if (chip.v.hasOwnProperty(k) && chip.v[k]) {
            labels.push(`${T.translate(`materials.${k}`)}|${chip.v[k]}`);
          }
        }
      }
      if (labels.length === 0) savable = false;
      const label = labels.length > 0 ? (chip.k + ': ' + labels.join(', ')) : chip.k;
      chip_labels.push(label);
    }

    return (
      <Box className={classes.root}>
        <Dialog
          open={!!this.state.alert}
          onClose={e => self.setState({alert: false})}
          aria-labelledby="form-dialog-title"
          maxWidth='xs'
          fullWidth
        >
          <DialogTitle id="form-dialog-title">{T.translate('materials.new')}</DialogTitle>
          <DialogContent>
            <TextField
              margin="dense"
              multiline={true}
              inputProps={{
                maxLength: 75
              }}
              type="text"
              name="title"
              defaultValue={this.state.value}
              onChange={e => self.setState({value: e.target.value})}
              label={T.translate('materials.title')}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button data-tag={0} onClick={this.handleClose}>
              {T.translate('buttons.cancel')}
            </Button>
            <Button data-tag={1} onClick={this.handleClose} color="primary" disabled={!this.state.value}>
              {T.translate('buttons.submit')}
            </Button>
          </DialogActions>
        </Dialog>
        
        {picker &&
          <MaterialPicker
            key={picker ? self.chipData[self.state.picker].k : ''}
            materials={[]}
            titles={[]}
            filters={picker ? self.chipData[self.state.picker].v : undefined}
            screener={self.chipData[self.state.picker].s}
            dialog={true}
            open={picker}
            onClose={e => self.setState({picker: -1})}
            onFilter={f => {
              self.chipData[self.state.picker].s = f;
              self.setState({picker: -1});
            }}
          />
        }

        <Typography className={classes.title} variant="subtitle1">{T.translate('materials.presets')}</Typography>
        <Box className={classes.box}>
          {types.map((label, index) => {
            return (
              <Chip
                key={index}
                data-tag={index}
                icon={<AddIcon/>}
                label={label}
                className={classes.chip}
                variant="outlined"
                color="primary"
                clickable={true}
                onClick={this.onBatch}
              />
            );
          })}
        </Box>

        <Typography className={classes.title} variant="subtitle1">{T.translate('materials.customizable')}</Typography>
        <Box className={classes.box}>
          {chips.map((chip, index) => {
            const locked = required.indexOf(chip) >= 0;
            const added = self.source.indexOf(chip) < 0;
            const color = added ? 'primary' : 'secondary';
            const icon =
              <Typography
                className={locked || !added ? classes.icon : classes.icon_primary}
                variant="caption">
                {chip.k[0]}
              </Typography>;
            return (
              <Chip
                key={index}
                data-tag={index}
                icon={icon}
                label={chip_labels[index]}
                className={classes.chip}
                variant="outlined"
                color={locked ? 'default' : color}
                //disabled={locked}

                draggable={!locked}
                onDelete={locked ? undefined : () => {self.handleDelete(index);}}
                onDragOver={(e) => {
                  //e.stopPropagation();
                  e.preventDefault();
                }}
                onDragStart={locked ? undefined : this.onDrag}
                onDrop={this.onDrop}
                onClick={e => self.setState({picker: index})}
              />
            );
          })}
        </Box>
        <Box className={classes.box}>

        <Button data-tag={1} onClick={this.onSave} variant="outlined" color="primary" disabled={!savable}>
          {T.translate('buttons.save')}
        </Button>
        </Box>
      </Box>
    )
  }
}

export default withStyles(styles)(MaterialsEditor);
